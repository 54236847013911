import { routes } from '@semios/app-platform-banyan-route-definitions'
import { ChillTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/ChillTitleChildren/ChillTitleChildren'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { StackedChartSection, TChartSeries } from 'components/StackedChart/types'
import { colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { detailsPanelStore, TChillMarVsMay, TChillSepVsNov } from 'stores/detailsPanelStore'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { propertyIsSouthernHemisphere } from 'utils/propertyIsSouthernHemisphere'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { EAggregationInterval } from 'App/Map/types'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import moment from 'moment-timezone'
import { getTimezoneForSelectedProperty } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedProperty'

const pointCategory: TPointCategory = 'weatherPoint'
const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_CHILL_DETAILS' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const getValuesRequested = (isSouthernHemisphere: boolean | undefined) => {
  const valuesRequested: Record<string, typeof preferredAggregationInterval> = isSouthernHemisphere
    ? {
        chillCumulativeHoursBetween32and45FMAR: preferredAggregationInterval,
        chillCumulativeHoursBelow45FMAR: preferredAggregationInterval,
        chillCumulativePortionsMAR: preferredAggregationInterval,
        chillCumulativeHoursBetween32and45FMAY: preferredAggregationInterval,
        chillCumulativeHoursBelow45FMAY: preferredAggregationInterval,
        chillCumulativePortionsMAY: preferredAggregationInterval,
      }
    : {
        chillCumulativeHoursBetween32and45FSEP: preferredAggregationInterval,
        chillCumulativeHoursBelow45FSEP: preferredAggregationInterval,
        chillCumulativePortionsSEP: preferredAggregationInterval,
        chillCumulativeHoursBetween32and45FNOV: preferredAggregationInterval,
        chillCumulativeHoursBelow45FNOV: preferredAggregationInterval,
        chillCumulativePortionsNOV: preferredAggregationInterval,
      }

  return valuesRequested
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.chill || !selectedFieldAssets[pointCategory]) return {}

  const isSouthernHemisphere = propertyIsSouthernHemisphere()
  const valuesRequested = getValuesRequested(isSouthernHemisphere)

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const isSouthernHemisphere = propertyIsSouthernHemisphere()
  const timezone = getTimezoneForSelectedProperty()

  const commonReturnItems = {
    title: unitConverter.chillCumulativeHoursBelow45FSEP().categoryTitleWithoutUnit(),
    titleChildren: (
      <ChillTitleChildren
        valuesTimeseriesToFilterOn={Object.keys(getValuesRequested(isSouthernHemisphere))}
        isSouthernHemisphere={!!isSouthernHemisphere}
        showMonthsToggle={false}
      />
    ),
    id: 'stackem-chill',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { chillMarVsMay, chillSepVsNov, compareSeasonsInterval } = detailsPanelStore.getState()
  const stationLngLat = String(selectedFieldAssetsStore.getState()[pointCategory])

  const chillMonthToUse: TChillMarVsMay | TChillSepVsNov = (() => {
    if (isSouthernHemisphere && chillMarVsMay === 'MAR') return 'MAR'

    if (isSouthernHemisphere && chillMarVsMay === 'MAY') return 'MAY'

    if (!isSouthernHemisphere && chillSepVsNov === 'SEP') return 'SEP'

    return 'NOV'
  })()

  const chillCumulativeHoursBelow45FToUse:
    | 'chillCumulativeHoursBelow45FSEP'
    | 'chillCumulativeHoursBelow45FNOV'
    | 'chillCumulativeHoursBelow45FMAR'
    | 'chillCumulativeHoursBelow45FMAY' = `chillCumulativeHoursBelow45F${chillMonthToUse}`

  const chillCumulativeHoursBetween32and45FToUse:
    | 'chillCumulativeHoursBetween32and45FSEP'
    | 'chillCumulativeHoursBetween32and45FNOV'
    | 'chillCumulativeHoursBetween32and45FMAR'
    | 'chillCumulativeHoursBetween32and45FMAY' = `chillCumulativeHoursBetween32and45F${chillMonthToUse}`

  const chillCumulativePortionsToUse:
    | 'chillCumulativePortionsSEP'
    | 'chillCumulativePortionsNOV'
    | 'chillCumulativePortionsMAR'
    | 'chillCumulativePortionsMAY' = `chillCumulativePortions${chillMonthToUse}`

  const unitConvertersToUse: Record<
    'chillCumulativeHoursBetween32and45F' | 'chillCumulativeHoursBelow45F' | 'chillCumulativePortions',
    typeof unitConverter.chillCumulativeHoursBelow45FMAR
  > = {
    chillCumulativeHoursBetween32and45F: unitConverter?.[chillCumulativeHoursBetween32and45FToUse],
    chillCumulativeHoursBelow45F: unitConverter?.[chillCumulativeHoursBelow45FToUse],
    chillCumulativePortions: unitConverter?.[chillCumulativePortionsToUse],
  }

  const chillHoursSeries: TChartSeries[] = []

  const chillHoursSeriesBelow45: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.aboveCanopy,
    name: unitConvertersToUse.chillCumulativeHoursBelow45F().shortTitle(),
    tooltip: {
      valueDecimals: unitConvertersToUse.chillCumulativeHoursBelow45F().defaultNumberOfDecimalPlaces(),
    },
    yAxis: 0,
    data: (
      data?.points?.[stationLngLat]?.values?.[chillCumulativeHoursBelow45FToUse]?.[0]?.timeseries ?? []
    ).map((d) => [+new Date(d.timestamp), unitConvertersToUse.chillCumulativeHoursBelow45F(d.value).value()]),
    type: 'line',
    id: 'chillHours_below45',
  }

  chillHoursSeries.push(chillHoursSeriesBelow45)

  if (compareSeasonsInterval) {
    chillHoursSeries.push(
      makeCompareSeasonsSeriesFromRegularSeries(chillHoursSeriesBelow45, {
        data: (
          compareSeasonsData?.points?.[stationLngLat]?.values?.[chillCumulativeHoursBelow45FToUse]?.[0]
            ?.timeseries ?? []
        ).map((d) => [
          +new Date(d.timestamp),
          unitConvertersToUse.chillCumulativeHoursBelow45F(d.value).value(),
        ]),
      }),
    )
  }

  const chillHoursSeriesBetween32and45: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.chocolate,
    name: unitConvertersToUse.chillCumulativeHoursBetween32and45F().shortTitle(),
    tooltip: {
      valueDecimals: unitConvertersToUse.chillCumulativeHoursBetween32and45F().defaultNumberOfDecimalPlaces(),
    },
    yAxis: 0,
    data: (
      data?.points?.[stationLngLat]?.values?.[chillCumulativeHoursBetween32and45FToUse]?.[0]?.timeseries ?? []
    ).map((d) => [
      +new Date(d.timestamp),
      unitConvertersToUse.chillCumulativeHoursBetween32and45F(d.value).value(),
    ]),
    type: 'line',
    id: 'chillHours_between32and45',
  }

  chillHoursSeries.push(chillHoursSeriesBetween32and45)

  if (compareSeasonsInterval) {
    chillHoursSeries.push(
      makeCompareSeasonsSeriesFromRegularSeries(chillHoursSeriesBetween32and45, {
        data: (
          compareSeasonsData?.points?.[stationLngLat]?.values?.[chillCumulativeHoursBetween32and45FToUse]?.[0]
            ?.timeseries ?? []
        ).map((d) => [
          +new Date(d.timestamp),
          unitConvertersToUse.chillCumulativeHoursBetween32and45F(d.value).value(),
        ]),
      }),
    )
  }

  const chillPortionsSeries: TChartSeries[] = []

  const chillPortionsCumulativeSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.aboveCanopy,
    name: unitConvertersToUse.chillCumulativePortions().shortTitle(),
    tooltip: {
      valueDecimals: unitConvertersToUse.chillCumulativePortions().defaultNumberOfDecimalPlaces(),
    },
    yAxis: 0,
    data: (data?.points?.[stationLngLat]?.values?.[chillCumulativePortionsToUse]?.[0]?.timeseries ?? []).map(
      (d) => [+new Date(d.timestamp), unitConvertersToUse.chillCumulativePortions(d.value).value()],
    ),
    type: 'line',
    id: 'chillPortions',
  }

  chillPortionsSeries.push(chillPortionsCumulativeSeries)

  if (compareSeasonsInterval) {
    chillPortionsSeries.push(
      makeCompareSeasonsSeriesFromRegularSeries(chillPortionsCumulativeSeries, {
        data: (
          compareSeasonsData?.points?.[stationLngLat]?.values?.[chillCumulativePortionsToUse]?.[0]
            ?.timeseries ?? []
        ).map((d) => [+new Date(d.timestamp), unitConvertersToUse.chillCumulativePortions(d.value).value()]),
      }),
    )
  }

  const xDateFormat = getXDateFormat()

  const forecastStartsAt =
    data?.points?.[stationLngLat]?.values?.[chillCumulativePortionsToUse]?.[0]?.metadata?.forecastStartsAt

  let firstForecastTimestamp: number = +new Date()

  if (forecastStartsAt) {
    firstForecastTimestamp = +moment.tz(forecastStartsAt, timezone).toDate()
  }

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          yAxis: { min: 0 },
          semiosHighchartsAdditions: {
            id: `${commonReturnItems.id}_hours`,
            firstForecastTimestamp,
          },
          chart: {
            type: 'line',
          },
          series: chillHoursSeries,
          tooltip: {
            xDateFormat,
          },
        },
      },
      {
        chartConfig: {
          yAxis: { min: 0 },
          semiosHighchartsAdditions: {
            id: `${commonReturnItems.id}_portions`,
            firstForecastTimestamp,
          },
          chart: {
            type: 'line',
          },
          series: chillPortionsSeries,
          tooltip: {
            xDateFormat,
          },
        },
      },
    ],
    titleChildren: (
      <ChillTitleChildren
        isSouthernHemisphere={!!isSouthernHemisphere}
        showMonthsToggle
        valuesTimeseriesToFilterOn={Object.keys(getValuesRequested(isSouthernHemisphere))}
      />
    ),
  }
}
