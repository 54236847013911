import { Dispatch, SetStateAction } from 'react'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { Select } from '@mantine/core'
import {
  TSelectedKcConfiguration,
  TSelectedKcConfigurationMap,
  TOrganizationKcModel,
  TSemiosKcModel,
  TKcOverrideType,
} from 'App/Map/UserSettingsMenu/Shared/EvapotranspirationSettings/types'

export const KcModelSelect = ({
  blockId,
  loading,
  selectedValue,
  semiosKcModels,
  setSelectedValueMap,
  setDirtyBlockIds,
  organizationModelsData,
}: {
  blockId: number
  blockIdsOfOtherValidCropBlocks: number[]
  cropName: string
  isDirty: boolean
  loading: boolean
  organizationModelsData: TOrganizationKcModel[]
  selectedValue: TSelectedKcConfiguration
  semiosKcModels: TSemiosKcModel[]
  setDirtyBlockIds: Dispatch<SetStateAction<number[]>>
  setSelectedValueMap: Dispatch<SetStateAction<TSelectedKcConfigurationMap>>
}) => {
  const modelTypeMap = [...organizationModelsData, ...semiosKcModels].reduce<Record<string, TKcOverrideType>>(
    (acc, curr) => {
      return {
        ...acc,
        [String(curr.modelId)]: curr.modelType,
      }
    },
    {},
  )

  const selectData = organizationModelsData
    .map(({ modelId, modelName }: TOrganizationKcModel) => {
      return {
        value: modelId,
        label: modelName,
        group: translate.phrases.banyanApp('Organization Defined Models'),
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
    .concat(
      // semios defined
      semiosKcModels
        .filter(({ modelName }) => {
          return modelName.toLowerCase().startsWith('semios')
        })
        .map(({ modelId, modelName }) => {
          return {
            value: modelId,
            label: modelName,
            group: translate.phrases.banyanApp('Semios Defined Models'),
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    )
    .concat(
      // other
      semiosKcModels
        .filter(({ modelName }) => {
          return !modelName.toLowerCase().startsWith('semios')
        })
        .map(({ modelId, modelName }) => {
          return {
            value: modelId,
            label: modelName,
            group: translate.phrases.banyanApp('Other Models'),
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    )

  const uniqueGroups = Array.from(new Set(selectData.map((x) => x.group))).length

  return (
    <div css={{ minWidth: 160 }}>
      <Select
        onClick={(e) => {
          e.stopPropagation()
        }}
        css={{ width: 'auto !important', maxWidth: '100%' }}
        disabled={loading || semiosKcModels.length === 0}
        value={selectedValue?.modelId}
        onChange={async (modelId) => {
          if (isNil(modelId)) return

          setDirtyBlockIds((prev) => [...prev, blockId])

          setSelectedValueMap((prev) => {
            const foundModelType = modelTypeMap[modelId]

            return {
              ...prev,
              [blockId]: {
                ...prev[blockId],
                modelId,
                modelType: foundModelType,
              },
            }
          })
        }}
        data={uniqueGroups > 1 ? selectData : selectData.map((x) => ({ ...x, group: undefined }))} // If there is only one group, don't show the group label
      />
    </div>
  )
}
