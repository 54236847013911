import type { TPhrases } from '@semios/app-platform-i18n-core'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'

export const getCropNameFromId = (cropId?: TFieldAssetKeyTypes.TCropId) => {
  const fallBackName = translate.phrases.banyanApp('Unknown Crop')

  if (!cropId) return fallBackName

  const dbCropName = fieldAssetStore.getState()?.crops?.[cropId]?.name

  if (!dbCropName) return fallBackName

  return translate.phrases.dbCropsName(dbCropName as TPhrases['dbCropsName'])
}
