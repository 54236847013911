import { translate } from 'i18n/i18n'
import { SetStateAction, useEffect, useState } from 'react'
import { Input } from '@mantine/core'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { useErrorMessage } from '../../../ErrorMessage/useErrorMessage'
import { ErrorMessage } from '../../../ErrorMessage/ErrorMessage'
import type { Zone } from './ZonesTab'
import type { TFieldAssetValueTypes } from 'App/Map/types'

export const EditZoneModal = ({
  selectedZone,
  setSelectedZone,
}: {
  selectedZone: (Zone & { propertyId: number }) | null
  setSelectedZone: React.Dispatch<SetStateAction<(Zone & { propertyId: number }) | null>>
}) => {
  const [zoneName, setZoneName] = useState<string>('')
  const { showErrorMessage, triggerErrorMessage, onCloseButtonPress } = useErrorMessage()
  const closeModal = () => setSelectedZone(null)

  useEffect(() => {
    if (selectedZone) {
      setZoneName(selectedZone.zoneName)
    }
  }, [selectedZone])

  return (
    <ModalDrawer
      title={translate.phrases.banyanApp('Edit Zone')}
      primaryButtonDisabled={zoneName === ''}
      primaryButtonOnPress={async () => {
        if (!selectedZone) return

        // check if zone name is blank
        if (!zoneName || zoneName.trim() === '') {
          triggerErrorMessage(
            translate.phrases.validation('{{label}} is required', {
              label: translate.phrases.banyanApp('Zone Name'),
            }),
          )

          return
        }

        if (zoneName === selectedZone.zoneName) {
          // no changes were made, don't make an api call
          closeModal()

          return
        }

        const uniqueZoneNames = Object.values(
          fieldAssetStore.getState().properties?.[selectedZone.propertyId]?.irrigationZoneEmitters ?? {},
        )
          // filter out the zone we are editing so we don't get a false positive
          .filter((g) => g.irrigationZoneId !== selectedZone.irrigationZoneId)
          .map((z) => z.name.trim().toLowerCase())

        if (uniqueZoneNames.includes(zoneName.trim().toLowerCase())) {
          triggerErrorMessage(
            translate.phrases.validation('{{label}} already exists', {
              label: translate.phrases.banyanApp('Zone Name'),
            }),
          )

          return
        }

        await apiFetch<routes.IrrigationZoneUpdate.Request, routes.IrrigationZoneUpdate.Response>({
          url: 'irrigation-zone-update',
          body: {
            irrigationZoneId: selectedZone.irrigationZoneId,
            zoneName,
          },
        })

        fieldAssetStore.setState((s) => {
          if (!s.properties || !selectedZone?.propertyId) return { ...s }

          const updatedIrrigationZoneEmitters = Object.entries(
            s.properties[selectedZone.propertyId]?.irrigationZoneEmitters ?? {},
          ).reduce<Record<string, TFieldAssetValueTypes.TIrrigationZoneEmitter>>(
            (acc, [irrigationZoneEmitterId, zone]) => {
              if (zone.irrigationZoneId === selectedZone.irrigationZoneId) {
                acc[irrigationZoneEmitterId] = {
                  ...zone,
                  name: zoneName,
                }
              } else {
                acc[irrigationZoneEmitterId] = {
                  ...zone,
                }
              }

              return acc
            },
            {},
          )

          return {
            ...s,
            properties: {
              ...s.properties,
              [selectedZone.propertyId]: {
                ...s.properties[selectedZone.propertyId],
                irrigationZoneEmitters: updatedIrrigationZoneEmitters,
              },
            },
          }
        })

        closeModal()
      }}
      primaryButtonText={translate.phrases.banyanApp('Apply')}
      opened={!!selectedZone}
      onClose={closeModal}
    >
      <ErrorMessage
        message={showErrorMessage}
        onCloseButtonPress={onCloseButtonPress}
        showErrorMessage={!!showErrorMessage}
      />
      <div css={{ margin: 30 }}>
        <div css={{ marginBottom: 10 }}>{translate.phrases.banyanApp('Zone Name')}</div>
        <Input
          css={{ maxWidth: 300 }}
          maxLength={40}
          value={zoneName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setZoneName(e.currentTarget.value)}
        />
      </div>
    </ModalDrawer>
  )
}
