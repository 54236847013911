import { routes } from '@semios/app-platform-banyan-route-definitions'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { StackedChartSection, TChartSeries } from 'components/StackedChart/types'
import { SeriesOptionsType } from 'highcharts'
import { translate } from 'i18n/i18n'
import { colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { getCropNameFromId } from 'utils/getCropNameFromId'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { getXDateFormat } from '../../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { checkPermission, defaultValuesRequested, pointCategory } from '../plantStress'

export const trunkDisplacement = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.trunkDisplacement().categoryTitleWithoutUnit(),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={Object.keys(defaultValuesRequested)}
      />
    ),
    id: 'stackem-trunk-displacement',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const stationLngLat = selectedFieldAssets[pointCategory] as TFieldAssetKeyTypes.TLngLat
  const series: SeriesOptionsType[] = []

  const cropId =
    fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.points?.[
      stationLngLat as TFieldAssetKeyTypes.TLngLat
    ]?.configuration.treeDendrometerCropId ?? null

  const firstForecastTimestamp = +new Date()

  const trunkDisplacementSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.midnight,
    name: unitConverter.trunkDisplacement().shortTitle(),
    tooltip: {
      valueDecimals: unitConverter.trunkDisplacement().defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${unitConverter.trunkDisplacement().suffix()}`,
    },
    data: (data?.points?.[stationLngLat]?.values?.trunkDisplacement?.[0]?.timeseries ?? []).map((d) => [
      +new Date(d.timestamp),
      unitConverter.trunkDisplacement(d.value).value(),
    ]),
    type: 'line',
    id: 'trunkDisplacement',
  }

  series.push(trunkDisplacementSeries)

  if (compareSeasonsInterval) {
    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(trunkDisplacementSeries, {
        data: (
          compareSeasonsData?.points?.[stationLngLat]?.values?.trunkDisplacement?.[0]?.timeseries ?? []
        ).flatMap((d) => {
          const timestamp = +new Date(d.timestamp)
          const value = unitConverter.trunkDisplacement(d.value).value()

          return [[+timestamp, value]]
        }),
      }),
    )
  }

  const isDaily =
    data?.points?.[stationLngLat]?.values?.trunkDisplacement?.[0]?.metadata?.aggregationInterval === 'DAILY'

  return {
    ...commonReturnItems,
    title: translate.phrases.templates('{{labelA}} - {{labelB}}', {
      labelA: `${unitConverter.trunkDisplacement().shortTitle()}`,
      labelB: getCropNameFromId(Number(cropId)),
    }),
    items: [
      {
        chartConfig: {
          tooltip: {
            xDateFormat: getXDateFormat(isDaily),
          },
          semiosHighchartsAdditions: {
            id: 'trunkDisplacement',
            firstForecastTimestamp,
          },
          chart: {
            type: 'line',
          },
          series,
        },
      },
    ],
  }
}
