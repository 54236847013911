import React from 'react'
import { useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { IconRightArrowCircle } from 'components/icons/IconRightArrowCircle'
import { colors } from 'settings/colors'
import { TActiveNode, TNodeServiceIssueSummary } from '../../types'
import { getServiceIssueTitle } from '../../utils/getServiceIssueTitle'

interface ServiceIssueListProps {
  node: TActiveNode
  serviceIssues: TNodeServiceIssueSummary[]

  onSelectIssue(serviceIssueId: number): void
}

export const ServiceIssueList: React.FC<ServiceIssueListProps> = ({ node, serviceIssues, onSelectIssue }) => {
  const theme = useMantineTheme()

  return (
    <div
      css={{
        padding: '20px 10px',
      }}
    >
      {serviceIssues.map((serviceIssue) => (
        <button
          key={serviceIssue.serviceIssueId}
          css={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            border: `1px solid ${theme.colors.grey[1]}`,
            backgroundColor: 'transparent',
            padding: '16px 20px',
            marginBottom: 10,
            textAlign: 'left',
          }}
          onClick={() => onSelectIssue(serviceIssue.serviceIssueId)}
        >
          <span
            css={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingRight: 20,
            }}
          >
            {getServiceIssueTitle(node, serviceIssue)}
          </span>
          <span css={{ fontSize: '20px', color: colors.primary }}>
            <IconRightArrowCircle />
          </span>
        </button>
      ))}

      {!serviceIssues.length && (
        <div
          css={{
            color: theme.colors.grey[2],
            fontSize: '24px',
            fontWeight: 300,
            textAlign: 'center',
            margin: 'auto',
            position: 'absolute',
            top: 'calc(50% - 50px)',
            width: '100%',
          }}
        >
          {translate.phrases.placeholder('No issue detected')}
        </div>
      )}
    </div>
  )
}
