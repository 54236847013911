import { routes } from '@semios/app-platform-banyan-route-definitions'

export type M_ITEM =
  | 'M_Block'
  | 'M_Zone' // Not in csv... but I'm thinking we'll need it
  | 'M_SCD'
  | 'M_InBlockPoint'
  | 'M_OutOfBlockPoint'
  | 'M_Trap'
  | 'M_CurrentLocation'
  | 'M_Property'

export type ITEM = {
  MapItem: M_ITEM
  Section: string
  valueGroup: string
  valueType: string
  childItems?: string[]
}

export enum MAP_VISUAL {
  BLOCK = 'BLOCK',
  POINT = 'POINT',
  PROPERTY = 'PROPERTY',
  SCD = 'SCD',
  TRAP = 'TRAP',
  ZONE = 'ZONE',
}

export type TValuesCurrentPropertiesValueTypes = keyof NonNullable<
  routes.ValuesCurrent.Response['properties']
>[keyof NonNullable<routes.ValuesCurrent.Response['properties']>]

export type TValuesCurrentBlocksValueTypes = keyof NonNullable<
  routes.ValuesCurrent.Response['blocks']
>[keyof NonNullable<routes.ValuesCurrent.Response['blocks']>]

export type TValuesCurrentPointsValueTypes = keyof NonNullable<
  routes.ValuesCurrent.Response['points']
>[keyof NonNullable<routes.ValuesCurrent.Response['points']>]

export type TValuesCurrentHeatmapPointsValueTypes = Exclude<
  keyof NonNullable<routes.ValuesCurrent.Response['heatmap_points']>[keyof Omit<
    NonNullable<routes.ValuesCurrent.Response['heatmap_points']>,
    'blockIds'
  >],
  'blockIds'
>
