import { colors } from 'settings/colors'
import { ReactNode } from 'react'
import { useViewportSizeWithCorrectInitialState } from 'utils/useViewportSizeWithCorrectInitialState'
import { SettingsEditButton } from 'App/Map/UserSettingsMenu/components/SettingsEditButton/SettingsEditButton'
import { SettingsDeleteButton } from 'App/Map/UserSettingsMenu/components/SettingsDeleteButton/SettingsDeleteButton'
import { isNil } from '@semios/app-platform-common'

type ContactsAndGroupsRowProps = {
  id: string
  icon: ReactNode
  name: string
  badgeNumber?: number
  onEdit: () => void
  onDelete: (groupId: string, groupName: string) => void
}

export const ContactsAndGroupsRow = ({
  id,
  icon,
  name,
  badgeNumber,
  onEdit,
  onDelete,
}: ContactsAndGroupsRowProps) => {
  const { width } = useViewportSizeWithCorrectInitialState()
  const nameWidth = badgeNumber ? width - 280 : width - 250

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        borderBottom: `1px solid ${colors.grey200}`,
        padding: '5px 0',
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div css={{ color: 'white', display: 'flex', alignItems: 'center', fontSize: 35 }}>{icon}</div>
        <div
          css={{
            marginLeft: 8,
            display: 'flex',
            fontSize: 14,
            maxWidth: nameWidth,
            padding: '19px 0px',
          }}
        >
          {name}
        </div>
        {!isNil(badgeNumber) && (
          <div
            css={{
              height: 18,
              borderRadius: 16,
              padding: '3px 8px 3px 8px',
              color: 'white',
              backgroundColor: colors.grey500,
              fontSize: 12,
              marginLeft: 10,
            }}
          >
            {badgeNumber}
          </div>
        )}
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <SettingsDeleteButton
          onClick={() => {
            onDelete(id, name)
          }}
        />
        <SettingsEditButton onClick={onEdit} />
      </div>
    </div>
  )
}
