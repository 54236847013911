import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { ImperialXorMetric } from 'stores/fieldAssetStore'

export enum EnumIrrigationActivityStatus {
  on = 'on',
  off = 'off',
  noData = 'noData',
}

export type TIrrigationActivitySettings = Record<
  EnumIrrigationActivityStatus | 'psi',
  {
    color: string
    text: string
    border?: string
  }
>

const getPressureUnit = (pressureUnitFromUserSetting: ImperialXorMetric) => {
  const isImperial = pressureUnitFromUserSetting === 'IMPERIAL'

  return isImperial
    ? translate.measurements.poundsPerSquareInch.unit()
    : translate.measurements.kilopascals.unit()
}

export const getIrrigationActivitySettings = ({
  pressureUnitFromUserSetting,
}: {
  pressureUnitFromUserSetting: ImperialXorMetric
}): TIrrigationActivitySettings => ({
  on: { color: colors.green, text: translate.phrases.banyanApp('On') },
  off: { color: colors.midnight, text: translate.phrases.banyanApp('Off') },
  noData: { color: colors.gray, text: translate.phrases.banyanApp('No Data') },
  psi: { color: colors.black, text: getPressureUnit(pressureUnitFromUserSetting) },
})
