import type { CSSObject } from '@emotion/css'
import { Card } from 'components/MapCard/components/Card'
import { OverlayPopover } from 'components/MapCard/components/OverlayPopover'
import { ReactNode } from 'react'
import { useScreenSize } from 'utils/useScreenSize'

export const MapCard = (props: {
  children: ReactNode
  footer: ReactNode
  latLng: google.maps.LatLng | google.maps.LatLngLiteral
  onClose: () => void
  title: string
  targetCSS?: CSSObject
  cardCSS?: CSSObject
}) => {
  const { isWideScreen } = useScreenSize()

  if (isWideScreen) {
    return (
      <OverlayPopover
        title={props.title}
        latLng={props.latLng}
        onClose={props.onClose}
        footer={props.footer}
        targetCSS={props.targetCSS}
      >
        {props.children}
      </OverlayPopover>
    )
  }

  return (
    <Card title={props.title} onClose={props.onClose} footer={props.footer} cardCSS={props.cardCSS}>
      {props.children}
    </Card>
  )
}
