import { Block } from 'App/Map/CurrentValuesMap/components/SelectedMapCard/components/Block'
import { Station } from 'App/Map/CurrentValuesMap/components/SelectedMapCard/components/Station'
import { Trap } from 'App/Map/CurrentValuesMap/components/SelectedMapCard/components/Trap'
import { useCallback } from 'react'
import { mapSelectedCardStore } from 'stores/mapSelectedCardStore'

const ENABLE_WIP_CARDS = false

export const SelectedMapCard: React.FC = () => {
  const mapSelectedCardStoreState = mapSelectedCardStore.useSelector((s) => s)
  const visible = Object.keys(mapSelectedCardStoreState).length > 0
  const onClose = useCallback(() => mapSelectedCardStore.actions.hide(), [])

  if (!visible) return null

  if (ENABLE_WIP_CARDS && mapSelectedCardStoreState.metaBlock) {
    return <Block {...mapSelectedCardStoreState.metaBlock} onClose={onClose} />
  }

  if (ENABLE_WIP_CARDS && mapSelectedCardStoreState.metaStation) {
    return <Station {...mapSelectedCardStoreState.metaStation} onClose={onClose} />
  }

  if (mapSelectedCardStoreState.metaTrap) {
    return (
      <Trap
        {...mapSelectedCardStoreState.metaTrap}
        key={mapSelectedCardStoreState.metaTrap.lngLat}
        onClose={onClose}
      />
    )
  }

  return null
}
