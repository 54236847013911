import { IconUser } from 'components/icons/IconUser'
import { UserAvatar } from '../../../../MyDetails/UserAvatar'
import { colors } from 'settings/colors'

const NUMBER_OF_CONTACTS_TO_DISPLAY = 2

export const ContactsRow = ({ contactNameList }: { contactNameList: string[] }) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6 }}>
      <IconUser />
      {contactNameList[0] && (
        <UserAvatar
          backgroundColor={colors.grey800}
          textColor={colors.white}
          name={contactNameList[0]}
          border="1px solid white"
        />
      )}
      {contactNameList[1] && (
        <div css={{ marginLeft: -15 }}>
          <UserAvatar
            backgroundColor={colors.grey800}
            textColor={colors.white}
            name={contactNameList[1]}
            border="1px solid white"
          />
        </div>
      )}
      {contactNameList.length > NUMBER_OF_CONTACTS_TO_DISPLAY && (
        <div>{`+${contactNameList.length - NUMBER_OF_CONTACTS_TO_DISPLAY}`}</div>
      )}
    </div>
  )
}
