import { MaintenanceBanner } from 'components/HubMaintenance/components/MaintenanceBanner'
import { useCheckForHubMaintenance } from 'components/HubMaintenance/utils/useCheckForHubMaintenance'
import React, { FC } from 'react'

export const HubMaintenance: FC<{ children: React.ReactNode }> = (props) => {
  const maintenanceMessage = useCheckForHubMaintenance()

  return (
    <>
      {maintenanceMessage && <MaintenanceBanner>{maintenanceMessage}</MaintenanceBanner>}
      {props.children}
    </>
  )
}
