import React, { useMemo } from 'react'
import { translate } from 'i18n/i18n'
import { TEquipmentType, TNodeDeviceType } from '../store/serviceCenterStore'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { DEVICE_TYPES, getDeviceTypeLabel } from '../Map/_utils/deviceTypes'
import { DEVICEABLE_EQUIPMENTS, EQUIPMENT_TYPES, getEquipmentTypeLabel } from '../Map/_utils/equipmentTypes'
import { sortByKey } from 'utils/sortByKey'

interface Props {
  selectedEquipmentTypes: TEquipmentType[]
  selectedEquipmentDevices: TNodeDeviceType[]
  dropdownPosition?: 'top' | 'bottom'
  onChange: (selectedEquipmentTypes: TEquipmentType[], selectedEquipmentDevices: TNodeDeviceType[]) => void
}

export const EquipmentTypesAndDevicesMultiSelect: React.FC<Props> = ({
  selectedEquipmentTypes,
  selectedEquipmentDevices,
  dropdownPosition,
  onChange,
}) => {
  const NODES_GROUP_LABEL = translate.phrases.placeholder('Equipment')
  const DEVICES_GROUP_LABEL = translate.phrases.placeholder('Sensors')

  const handleSelectionChange = (selectedOptions: TOptions[]) => {
    const selectedEquipmentTypes = selectedOptions
      .filter((option) => option.group === NODES_GROUP_LABEL)
      .map((option) => option.value) as TEquipmentType[]

    const selectedDevices = selectedOptions
      .filter((option) => option.group === DEVICES_GROUP_LABEL)
      .map((option) => option.value) as TNodeDeviceType[]

    onChange(selectedEquipmentTypes, selectedDevices)
  }

  const selectedOptions = useMemo(
    () => [
      ...selectedEquipmentTypes.map(
        (value) =>
          ({ value, label: getEquipmentTypeLabel(value, true), group: NODES_GROUP_LABEL } as TOptions),
      ),
      ...selectedEquipmentDevices.map(
        (value) => ({ value, label: getDeviceTypeLabel(value), group: DEVICES_GROUP_LABEL } as TOptions),
      ),
    ],
    [selectedEquipmentTypes, selectedEquipmentDevices],
  )

  const data: { value: string; label: string }[] = useMemo(() => {
    let options: { label: string; value: TEquipmentType | TNodeDeviceType; group: string }[] = [
      ...EQUIPMENT_TYPES.map((equipmentType) => ({
        label: getEquipmentTypeLabel(equipmentType as TEquipmentType, true),
        value: equipmentType,
        group: NODES_GROUP_LABEL,
      })).sort(sortByKey('label')),
    ]

    if (selectedEquipmentTypes.some((type) => DEVICEABLE_EQUIPMENTS.includes(type))) {
      options = options.concat([
        ...DEVICE_TYPES.map((deviceType) => ({
          label: getDeviceTypeLabel(deviceType as TNodeDeviceType),
          value: deviceType,
          group: DEVICES_GROUP_LABEL,
        })).sort(sortByKey('label')),
      ])
    }

    return options
  }, [selectedEquipmentTypes])

  return (
    <MultiSelect
      data={data}
      dropdownPosition={dropdownPosition}
      groupOptions={true}
      onChange={handleSelectionChange}
      placeholder={translate.phrases.placeholder('Filter by types')}
      selectedData={selectedOptions}
    />
  )
}
