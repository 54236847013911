import { FC, lazy, Suspense } from 'react'
import { MyDetails } from '../../Shared/MyDetails'
import { Presets } from '../../Shared/Presets/Presets'
import { UnitSettings } from '../../Shared/UnitSettings'
import { SettingsItemProps } from '../../types'
import { IrrigationSchedulerSettings } from '../../Shared/IrrigationSchedulerSettings/IrrigationSchedulerSettings'
import { EvapotranspirationSettings } from '../../Shared/EvapotranspirationSettings/EvapotranspirationSettings'
import { ContactsAndGroups } from '../../Shared/ContactsAndGroups/ContactsAndGroups'
import { Alerts } from '../../Shared/Alerts/Alerts'
import { ReportsMain } from '../../Shared/ManageReports/ReportsMain'

const SemiosAPI = lazy(() =>
  import('../../Shared/SemiosAPI/SemiosAPI').then((m) => ({ default: m.SemiosAPI })),
)

export const SettingsItems: FC<SettingsItemProps> = ({ currentTab, durationMillisecond }) => {
  return (
    <div
      css={{
        marginLeft: 400,
        backgroundColor: 'white',
        height: '100%',
        width: '100%',
      }}
    >
      {(() => {
        if (currentTab === 'mainMenu' || currentTab === 'myDetails') {
          return <MyDetails durationMillisecond={durationMillisecond} />
        }

        if (currentTab === 'contactsAndGroups') return <ContactsAndGroups />

        if (currentTab === 'unitSettings') return <UnitSettings durationMillisecond={durationMillisecond} />

        if (currentTab === 'presets') return <Presets />

        if (currentTab === 'semiosApi')
          return (
            <Suspense fallback={null}>
              <SemiosAPI />
            </Suspense>
          )

        if (currentTab === 'irrigationScheduler') return <IrrigationSchedulerSettings />

        if (currentTab === 'evapotranspiration') return <EvapotranspirationSettings />

        if (currentTab === 'alerts') return <Alerts />

        if (currentTab === 'reports') return <ReportsMain />

        return null
      })()}
    </div>
  )
}
