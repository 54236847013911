import React from 'react'
import {
  TActiveGateway,
  TActiveNode,
  TDnNodeLog,
  TLnNodeLog,
  TNodeLog,
  TNodeStatus,
  TPlannedGateway,
  TPlannedNode,
  TSnNodeLog,
} from '../../../types'
import { ActionIcon, Badge, useMantineTheme } from '@mantine/core'
import { getNodeStatusLabel, NODE_STATUS_COLORS } from '../../../Map/_utils/getActiveNodeStatus'
import { getEquipmentTypeLabel } from '../../../Map/_utils/equipmentTypes'
import { getNodeEquipmentType } from '../../../Map/_utils/getNodeEquipmentType'
import { SnNodeLog } from '../../../SelectedEquipmentCard/ActiveNodeDetail/log/SnNodeLog'
import { DnNodeLog } from '../../../SelectedEquipmentCard/ActiveNodeDetail/log/DnNodeLog'
import { LnNodeLog } from '../../../SelectedEquipmentCard/ActiveNodeDetail/log/LnNodeLog'
import { getNodeNetworkType } from '../../../utils/getNodeNetworkType'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import { Devices } from '../Devices/Devices'
import { IconHorizontalDotMenu } from 'components/icons/IconHorizontalDotMenu'
import { colors } from 'settings/colors'
import { useScreenSize } from 'utils/useScreenSize'
import { openNodeMaintenanceModal } from '../../../NodeMaintenanceModal/NodeMaintenanceModal'
import { getNodeActions } from '../../../utils/getNodeActions'
import { openActionMenu } from '../../../ActionMenu/ActionMenu'
import { isPlannedNode } from '../../../utils/isPlannedNode'
import { Authorization } from '../../../../../components/Authorization/Authorization'

interface NodeRowProps {
  node: TActiveNode | TActiveGateway | TPlannedNode | TPlannedGateway
  status: TNodeStatus
  lastLog?: TNodeLog
}

export const NodeRow: React.FC<NodeRowProps> = ({ node, status, lastLog }) => {
  const theme = useMantineTheme()
  const { isWideScreen } = useScreenSize()
  const nodeStatusText = getNodeStatusLabel(status)
  const nodeStatusColor = NODE_STATUS_COLORS[status]
  const nodeType = (node as TActiveNode).nodeType || (node as TActiveGateway).gatewayType
  const networkType = getNodeNetworkType(node)
  const nodeTypeText = nodeType.toUpperCase().replace('_', '-')
  const equipmentType = getNodeEquipmentType(nodeType)
  const equipmentTypeText = getEquipmentTypeLabel(equipmentType)
  const hasServiceIssue = status === TNodeStatus.ERROR

  const narrowScreenStyles = {
    'dt': {
      width: 'auto !important',
      marginRight: 5,
      lineHeight: 2,
    },
    'dt::after': {
      content: '":"',
    },
    'dd': {
      width: 'auto !important',
      marginRight: 20,
      lineHeight: 2,
    },
    '.SignalStrength': {
      top: 3,
    },
  }

  const wideScreenStyles = {
    'dt': {
      display: 'block !important',
      width: 'auto !important',
      lineHeight: 1,
    },
    'dd': {
      display: 'block !important',
      width: 'auto !important',
      lineHeight: 2,
    },
    '.SignalStrength': {
      top: 5,
    },
  }

  const handleOpenActionMenu = () => {
    const actions = getNodeActions(node)

    openActionMenu(actions)
  }

  const nodeDevices = (node as TActiveNode).devices

  return (
    <div css={{ borderBottom: `1px solid ${theme.colors.grey[1]}`, padding: '15px 0' }}>
      <div
        className="header"
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: isWideScreen ? 'center' : 'flex-start',
        }}
      >
        <div className="left" css={{ flex: '1 1 auto' }}>
          <h3 css={{ margin: 0 }}>
            {node.name || equipmentTypeText}
            <Badge
              css={{
                color: theme.colors.midnight,
                backgroundColor: nodeStatusColor,
                marginLeft: 10,
                verticalAlign: 'middle',
              }}
            >
              {nodeStatusText}
            </Badge>
          </h3>

          {!isPlannedNode(node) && (
            <div css={{ fontSize: '10px', fontWeight: 500, marginTop: '3px', marginBottom: '2px' }}>
              {(node as TActiveNode).nodeIdentifier || (node as TActiveGateway).gatewayIdentifier}
            </div>
          )}

          <div css={{ fontSize: '10px', fontWeight: 500 }}>{nodeTypeText}</div>

          {!isPlannedNode(node) && (
            <Authorization requires={{ permission: 'VIEW_SSC_NODE_LAST_LOG', entity: 'FOR_ANY_ENTITY' }}>
              <div
                css={{
                  'fontSize': '12px',
                  'marginTop': 10,
                  'dl': {
                    display: 'flex',
                    flexDirection: isWideScreen ? 'row' : 'column',
                  },
                  '.row': {
                    marginRight: 20,
                  },
                  ...(isWideScreen ? wideScreenStyles : narrowScreenStyles),
                }}
              >
                {networkType === 'SN' && <SnNodeLog log={lastLog as TSnNodeLog} nodeType={nodeType} />}

                {!!lastLog && networkType === 'DN' && (
                  <DnNodeLog log={lastLog as TDnNodeLog} nodeType={nodeType} />
                )}

                {!!lastLog && networkType === 'LN' && (
                  <LnNodeLog log={lastLog as TLnNodeLog} nodeType={nodeType} />
                )}
              </div>
            </Authorization>
          )}
        </div>

        <div className="right" css={{ display: 'flex', alignItems: 'center' }}>
          {isWideScreen && hasServiceIssue && (
            <Button variant="tertiary" onClick={() => openNodeMaintenanceModal(node as TActiveNode)}>
              {translate.phrases.placeholder('Maintenance')}
            </Button>
          )}

          <ActionIcon color={colors.primary} css={{ marginLeft: 20 }} onClick={handleOpenActionMenu}>
            <span css={{ transform: 'translateX(2px) rotate(90deg)', fontSize: '22px' }}>
              <IconHorizontalDotMenu />
            </span>
          </ActionIcon>
        </div>
      </div>

      {!isWideScreen && hasServiceIssue && (
        <Button
          variant="tertiary"
          css={{ marginTop: 5 }}
          fullWidth
          size="lg"
          onClick={() => openNodeMaintenanceModal(node as TActiveNode)}
        >
          {translate.phrases.placeholder('Maintenance')}
        </Button>
      )}

      {!!nodeDevices && (
        <Devices
          configuredDevices={nodeDevices}
          deviceLogs={(lastLog as TSnNodeLog)?.devices}
          nodeType={nodeType}
        />
      )}
    </div>
  )
}
