import { TFieldAssetKeyTypes } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'

export const propertyIsSouthernHemisphere = (
  propertyId: TFieldAssetKeyTypes.TPropertyId = Number(selectedFieldAssetsStore.getState().property),
) => {
  try {
    return fieldAssetStore.getState()?.properties?.[propertyId]?.isSouthernHemisphere
  } catch (err) {
    // TODO: errorLogger
    return false
  }
}
