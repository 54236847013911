import { translate } from 'i18n/i18n'
import * as Sentry from '@sentry/react'

export function getDeviceInstallationHeightLabel(height: string): string {
  switch (height) {
    case 'fan':
      return translate.phrases.placeholder('Fan')

    case 'above':
      return translate.phrases.placeholder('Above canopy')

    case 'in':
      return translate.phrases.placeholder('In canopy')

    case 'sci':
      return translate.phrases.placeholder('Sci')

    case '16':
      return translate.phrases.placeholder('16 ft')

    case '3.0':
      return translate.phrases.placeholder('3 ft')

    case '2.5':
      return translate.phrases.placeholder('2.5 ft')

    case '1.0':
      return translate.phrases.placeholder('1 ft')

    case '0.0':
      return translate.phrases.placeholder('0 ft')

    case '-0.5':
      return translate.phrases.placeholder('-0.5 ft')

    case '-1.0':
      return translate.phrases.placeholder('-1 ft')

    case '-1.5':
      return translate.phrases.placeholder('-1.5 ft')

    case '-2.0':
      return translate.phrases.placeholder('-2 ft')

    case '-2.5':
      return translate.phrases.placeholder('-2.5 ft')

    case '-3.0':
      return translate.phrases.placeholder('-3 ft')

    case '-3.5':
      return translate.phrases.placeholder('-3.5 ft')

    case '-4.0':
      return translate.phrases.placeholder('-4 ft')

    case '-4.5':
      return translate.phrases.placeholder('-4.5 ft')

    case '-5.0':
      return translate.phrases.placeholder('-5 ft')

    case 'out':
      return translate.phrases.placeholder('Outside block')

    case 'ground':
      return translate.phrases.placeholder('Ground')

    case 'emitter-buried-drip-1':
      return translate.phrases.placeholder('Emitter - Buried Drip 1')

    case 'emitter-buried-drip-2':
      return translate.phrases.placeholder('Emitter - Buried Drip 2')

    case 'emitter-drip-1':
      return translate.phrases.placeholder('Emitter - Drip 1')

    case 'emitter-drip-2':
      return translate.phrases.placeholder('Emitter - Drip 2')

    case 'emitter-microspray':
      return translate.phrases.placeholder('Emitter - Microspray')

    case 'emitter-sprinkler':
      return translate.phrases.placeholder('Emitter - Sprinkler')

    case 'emitter-flood-furrow':
      return translate.phrases.placeholder('Emitter - Flood/Furrow')

    case 'emitter-overhead-cooling-microspray':
      return translate.phrases.placeholder('Emitter - Overhead Cooling Microspray')

    case 'emitter-overhead-cooling-sprinkler':
      return translate.phrases.placeholder('Emitter - Overhead Cooling Sprinkler')

    case 'emitter-other':
      return translate.phrases.placeholder('Emitter - Other')

    case 'pump':
      return translate.phrases.placeholder('Pump')

    default: {
      Sentry.captureException(`Device installation height "${height}" has not been implemented`)

      return height
    }
  }
}
