import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'
import { TUnitConverterOptionsWithUnits, TUnitConverterTypicalReturn } from '../types'

const DEFAULT_DECIMAL_PLACES = 1
const DEFAULT_DECIMAL_PLACES_VOLTAGE = 1
const DEFAULT_DECIMAL_PLACES_RPM = 0

type TAirTemperatureUnit = 'c' | 'f'

type TVoltageUnit = 'V'

type TRPMUnit = 'RPM'

const temperatureFunctionMaker = (
  value: number | null = null,
  {
    decimalPlaces = DEFAULT_DECIMAL_PLACES,
    inputUnit = 'c',
    outputUnit = userDetailsStore.getState().temperature === 'IMPERIAL' ? 'f' : 'c',
  }: TUnitConverterOptionsWithUnits<TAirTemperatureUnit> = {},
) => {
  let convertedValue: number | null

  const shouldConvertFromCToF = inputUnit === 'c' && outputUnit === 'f'
  const shouldConvertFromFToC = inputUnit === 'f' && outputUnit === 'c'

  if (isNil(value)) {
    convertedValue = null
  } else if (shouldConvertFromCToF) {
    convertedValue = value * 1.8 + 32
  } else if (shouldConvertFromFToC) {
    convertedValue = (value - 32) / 1.8
  } else {
    // assume there is no conversion required
    convertedValue = Number(value)
  }

  const unitSymbol =
    outputUnit === 'c'
      ? translate.measurements.degreesCelsius.unit()
      : translate.measurements.degreesFahrenheit.unit()

  return {
    categoryTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Temperature'),
        unitSymbol,
      }),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Temperature'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES,
    suffix: () => unitSymbol,
    shortTitle: () => {
      return translate.phrases.banyanApp('Wind Machine')
    },
    title: () => {
      return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
        labelA: translate.phrases.banyanApp('Wind Machine'),
        labelB: translate.phrases.banyanApp('Temperature'),
        unitSymbol,
      })
    },
    titleWithoutUnit: () => {
      return translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.banyanApp('Wind Machine'),
        labelB: translate.phrases.banyanApp('Temperature'),
      })
    },
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => {
      return outputUnit === 'c'
        ? translate.measurements.degreesCelsius.value(convertedValue, decimalPlaces)
        : translate.measurements.degreesFahrenheit.value(convertedValue, decimalPlaces)
    },
    valueWithSuffix: () => {
      return outputUnit === 'c'
        ? translate.measurements.degreesCelsius.valueWithUnit(convertedValue, decimalPlaces)
        : translate.measurements.degreesFahrenheit.valueWithUnit(convertedValue, decimalPlaces)
    },
    valueWithNoRounding: () => convertedValue,
  }
}

const voltageFunctionMaker = (
  value: number | null = null,
  { decimalPlaces = DEFAULT_DECIMAL_PLACES_VOLTAGE }: TUnitConverterOptionsWithUnits<TVoltageUnit> = {},
) => {
  let convertedValue: number | null

  if (isNil(value)) {
    convertedValue = null
  } else {
    // assume there is no conversion required
    convertedValue = Number(value)
  }

  const unitSymbol = translate.measurements.voltage.unit()

  return {
    categoryTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Voltage'),
        unitSymbol,
      }),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Voltage'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_VOLTAGE,
    suffix: () => unitSymbol,
    shortTitle: () => {
      return translate.phrases.banyanApp('Wind Machine')
    },
    title: () => {
      return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
        labelA: translate.phrases.banyanApp('Wind Machine'),
        labelB: translate.phrases.banyanApp('Voltage'),
        unitSymbol,
      })
    },
    titleWithoutUnit: () => {
      return translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.banyanApp('Wind Machine'),
        labelB: translate.phrases.banyanApp('Voltage'),
      })
    },
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => {
      return translate.measurements.voltage.value(convertedValue, decimalPlaces)
    },
    valueWithSuffix: () => {
      return translate.measurements.voltage.valueWithUnit(convertedValue, decimalPlaces)
    },
    valueWithNoRounding: () => convertedValue,
  }
}

const RPMFunctionMaker = (
  value: number | null = null,
  { decimalPlaces = DEFAULT_DECIMAL_PLACES_RPM }: TUnitConverterOptionsWithUnits<TRPMUnit> = {},
) => {
  let convertedValue: number | null

  if (isNil(value)) {
    convertedValue = null
  } else {
    // assume there is no conversion required
    convertedValue = Number(value)
  }

  const unitSymbol = translate.measurements.RPM.unit()

  return {
    categoryTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('RPM'),
        unitSymbol,
      }),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('RPM'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_VOLTAGE,
    suffix: () => unitSymbol,
    shortTitle: () => {
      return translate.phrases.banyanApp('Wind Machine')
    },
    title: () => {
      return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
        labelA: translate.phrases.banyanApp('Wind Machine'),
        labelB: translate.phrases.banyanApp('RPM'),
        unitSymbol,
      })
    },
    titleWithoutUnit: () => {
      return translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.banyanApp('Wind Machine'),
        labelB: translate.phrases.banyanApp('RPM'),
      })
    },
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => {
      return translate.measurements.RPM.value(convertedValue, decimalPlaces)
    },
    valueWithSuffix: () => {
      return translate.measurements.RPM.valueWithUnit(convertedValue, decimalPlaces)
    },
    valueWithNoRounding: () => convertedValue,
  }
}

type TTemperatureConversionFunction = (
  value?: number | null,
  options?: TUnitConverterOptionsWithUnits<TAirTemperatureUnit>,
) => TUnitConverterTypicalReturn

type TVoltageConversionFunction = (
  value?: number | null,
  options?: TUnitConverterOptionsWithUnits<TVoltageUnit>,
) => TUnitConverterTypicalReturn

type TRPMConversionFunction = (
  value?: number | null,
  options?: TUnitConverterOptionsWithUnits<TRPMUnit>,
) => TUnitConverterTypicalReturn

export const windMachine: {
  windMachineTemperature: TTemperatureConversionFunction
  windMachineVoltage: TVoltageConversionFunction
  windMachineRPM: TRPMConversionFunction
} = {
  windMachineTemperature: temperatureFunctionMaker,
  windMachineVoltage: voltageFunctionMaker,
  windMachineRPM: RPMFunctionMaker,
}
