import { TValuesCurrent, TValuesTimeseries } from 'App/Map/types'

type TGenericFieldAssetRecord = unknown & {
  valuesCurrent: TValuesCurrent[]
  valuesTimeseries: TValuesTimeseries[]
}

/**
 * Returns field assets that have ANY of the provided value types
 *
 */
export const filterFieldAssetsByValueTypes = <T extends TGenericFieldAssetRecord[]>({
  fieldAssets,
  valuesCurrent,
  valuesTimeseries,
}: {
  fieldAssets: T
  valuesCurrent?: TValuesCurrent[]
  valuesTimeseries?: TValuesTimeseries[]
}): T => {
  if (valuesCurrent && valuesCurrent.length) {
    return fieldAssets.filter((fa) => valuesCurrent.some((vc) => fa.valuesCurrent.includes(vc))) as T
  }

  if (valuesTimeseries && valuesTimeseries.length) {
    return fieldAssets.filter((fa) => valuesTimeseries.some((vc) => fa.valuesTimeseries.includes(vc))) as T
  }

  return fieldAssets
}
