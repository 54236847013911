import moment from 'moment-timezone'
import { TIrrigationSchedulerStoreState } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { TFieldAssetStoreState } from 'stores/fieldAssetStore'

export const getSelectedPropertyTimezoneForIrrigationScheduler = (
  fass: TFieldAssetStoreState,
  isss: TIrrigationSchedulerStoreState,
) => {
  if (isss.selectedPropertyId) {
    const property = fass.properties?.[Number(isss.selectedPropertyId)]

    if (property) {
      return property.timezone
    }
  }

  return moment.tz.guess()
}
