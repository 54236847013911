import { CSSObject } from '@emotion/css'
import { baseZIndex } from 'components/GridTable/consts'
import { GridTableColumn, GridTableContentSectionItem } from 'components/GridTable/types'
import { colors } from 'settings/colors'

export const ValueCell = ({
  column,
  render,
  valueCellCSS,
  values,
  valueWidth,
}: {
  column: GridTableColumn
  render: GridTableContentSectionItem['render']
  values: Parameters<GridTableContentSectionItem['render']>[2]
  valueCellCSS?: CSSObject
  valueWidth: number
}) => {
  return (
    <div
      css={{
        'width': valueWidth,
        'height': '100%',
        'boxSizing': 'border-box',
        '& > *': {
          height: '100%',
        },
        'border': `1px solid ${colors.grey200}`,
        'borderCollapse': 'collapse',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'columnGap': '17px',
        'fontWeight': 500,
        'fontSize': '14px',
        'zIndex': baseZIndex - 3,
        ...valueCellCSS,
        ...column.valueCellCSS,
      }}
    >
      {render(values[column.dataIndex], column, values)}
    </div>
  )
}
