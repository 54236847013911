import { TFieldAssetKeyTypes } from 'App/Map/types'

export const lngLatStringToLngLatObject = (input: TFieldAssetKeyTypes.TLngLat) => {
  const [lng, lat] = input
    .replace(/[^\d.-\s]/g, '')
    .split(' ')
    .map(Number)

  return { lng, lat }
}
