import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { MetaWithNodeIdentifier } from './queue'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { getIdentifier } from '../getIdentifier'
import { TNodeDevice, TNodeDevices } from 'App/ServiceCenter/types'

export type NodeAttachDeviceMeta = routes.ServiceCenterNodeAttachDevices.Request & MetaWithNodeIdentifier

const request = async (meta: NodeAttachDeviceMeta): Promise<NodeAttachDeviceMeta> => {
  await apiFetch<
    routes.ServiceCenterNodeAttachDevices.Request,
    routes.ServiceCenterNodeAttachDevices.Response
  >({
    url: routes.ServiceCenterNodeAttachDevices.path,
    body: meta,
    params: {
      method: 'POST',
    },
  })

  return meta
}

const callback = (meta: NodeAttachDeviceMeta) => {
  const { nodeIdentifier, devices } = meta
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())
  const existingActiveNodes = [...activeNodes].filter((node) => getIdentifier(node) !== nodeIdentifier)
  const updatingActiveNode = activeNodes.find((node) => getIdentifier(node) === nodeIdentifier)

  if (updatingActiveNode) {
    if (!updatingActiveNode.devices) {
      updatingActiveNode.devices = devices
    } else if (updatingActiveNode.devices) {
      let newDevices: TNodeDevices = { ...devices }

      //@ts-ignore
      const deviceEntries: [keyof TNodeDevices, { [channel: number]: TNodeDevice }][] = Object.entries(
        updatingActiveNode.devices,
      )

      // if new device doesn't have existing devices, add them
      for (const [connector, devicesByChannel] of deviceEntries) {
        if (!newDevices[connector]) newDevices[connector] = devicesByChannel

        if (newDevices[connector]) {
          const channelEntries: [string, TNodeDevice][] = Object.entries(devicesByChannel)

          for (const [channel, device] of channelEntries) {
            const parsedChannel = Number(channel)

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (!newDevices[connector]![parsedChannel]) newDevices[connector]![parsedChannel] = device
          }
        }
      }

      updatingActiveNode.devices = newDevices
    }

    serviceCenterStore.actions.setActiveNodes([...existingActiveNodes, updatingActiveNode])
  }
}

export const serviceCenterNodeAttachDevices = {
  request,
  callback,
}
