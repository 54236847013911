import { ClassNamesArg, css, cx } from '@emotion/css'
import { HighchartsOptionsWithSemiosAdditions } from 'components/StackedChart/types'
import type { StockChart } from 'highcharts'
import { MouseEventHandler, TouchEventHandler, useEffect, useRef } from 'react'
import { Highcharts } from './_utils/highcharts-init'

export { Highcharts }

export const HighchartsChart = ({
  chartOptions,
  id,
  height,
  className,
  onMouseMove,
  onMouseLeave,
  onTouchMove,
}: {
  id?: string
  className?: ClassNamesArg
  chartOptions: HighchartsOptionsWithSemiosAdditions
  height: number
  onMouseMove?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
  onTouchMove?: TouchEventHandler
}) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const chartInstanceRef = useRef<StockChart | null>(null)

  useEffect(() => {
    if (!chartRef.current) return

    if (!chartInstanceRef.current) {
      Highcharts.setOptions({
        lang: {
          thousandsSep: ',',
        },
      })

      chartInstanceRef.current = Highcharts.stockChart(chartRef.current, chartOptions)
    } else if (chartOptions) {
      chartInstanceRef.current.update(chartOptions, true, true)
    }
  }, [JSON.stringify(chartOptions), chartRef.current, chartInstanceRef.current])

  useEffect(() => {
    // tear down/clean up
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()

        chartInstanceRef.current = null
      }
    }
  }, [])

  return (
    <div
      className={cx([
        css`
          height: ${height}px;
          width: 100%;
        `,
        className,
      ])}
      id={id}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onTouchMove={onTouchMove}
      ref={chartRef}
    />
  )
}
