import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'
import { TUnitConverterOptionsWithUnits, TUnitConverterTypicalReturn } from '../types'

type TPrecipitationUnit = 'mm' | 'in'

export const precipitation: Record<
  'precipitation',
  (
    precipitationAmount?: number | null,
    options?: TUnitConverterOptionsWithUnits<TPrecipitationUnit>,
  ) => TUnitConverterTypicalReturn
> = {
  precipitation: (
    precipitationAmount: number | null = null,
    {
      decimalPlaces,
      inputUnit = 'mm',
      outputUnit = userDetailsStore.getState().rain === 'IMPERIAL' ? 'in' : 'mm',
    }: {
      decimalPlaces?: number
      inputUnit?: TPrecipitationUnit
      outputUnit?: TPrecipitationUnit
    } = {},
  ) => {
    const defaultNumberOfDecimalPlaces = outputUnit === 'in' ? 2 : 1
    const shouldConvertFromMMToIN = inputUnit === 'mm' && outputUnit === 'in'
    const shouldConvertFromINToMM = inputUnit === 'in' && outputUnit === 'mm'

    let convertedValue: number | null

    if (isNil(precipitationAmount)) {
      convertedValue = null
    } else if (shouldConvertFromMMToIN) {
      convertedValue = precipitationAmount / 25.4
    } else if (shouldConvertFromINToMM) {
      convertedValue = precipitationAmount * 25.4
    } else {
      convertedValue = Number(precipitationAmount)
    }

    const decimalPlacesToUse = decimalPlaces ?? defaultNumberOfDecimalPlaces

    const unitSymbol =
      outputUnit === 'mm' ? translate.measurements.millimeters.unit() : translate.measurements.inches.unit()

    const label = translate.phrases.banyanApp('Rain')

    return {
      categoryTitle: () => translate.phrases.templates('{{label}} ({{unitSymbol}})', { label, unitSymbol }),
      categoryTitleWithoutUnit: () => label,
      defaultNumberOfDecimalPlaces: () => defaultNumberOfDecimalPlaces,
      suffix: () => unitSymbol,
      shortTitle: () => label,
      title: () => translate.phrases.templates('{{label}} ({{unitSymbol}})', { label, unitSymbol }),
      titleWithoutUnit: () => label,
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlacesToUse)
      },
      valueAsString: () => {
        return outputUnit === 'mm'
          ? translate.measurements.millimeters.value(convertedValue, decimalPlacesToUse)
          : translate.measurements.inches.value(convertedValue, decimalPlacesToUse)
      },
      valueWithSuffix: () => {
        return outputUnit === 'mm'
          ? translate.measurements.millimeters.valueWithUnit(convertedValue, decimalPlacesToUse)
          : translate.measurements.inches.valueWithUnit(convertedValue, decimalPlacesToUse)
      },
      valueWithNoRounding: () => convertedValue,
    }
  },
}
