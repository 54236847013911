import { routes } from '@semios/app-platform-banyan-route-definitions'
import { useMemo } from 'react'

export const useConvertCompareSeasonsData = (
  compareSeasonsData: routes.Values.Response,
  compareSeasonsInterval: number,
) => {
  const convertedData = useMemo(() => {
    if (!compareSeasonsData || compareSeasonsInterval === 0) return {}

    const fieldAssetTypes = Object.keys(compareSeasonsData)

    const convertedSeasonValues = fieldAssetTypes.reduce((acc, fieldAssetType) => {
      // @ts-ignore
      const fieldAssetData = compareSeasonsData[fieldAssetType]
      const locationKeys = Object.keys(fieldAssetData)

      const convertedLocationData = locationKeys.reduce((accumulatedLocations, locationKey) => {
        const locationData = fieldAssetData[locationKey]
        const valueKeys = Object.keys(locationData.values)

        const updatedValues = valueKeys.reduce((accumulatedValues, valueKey) => {
          const valueData = locationData.values[valueKey]

          // @ts-ignore
          const updatedValueData = valueData.map((valueItem) => {
            const updatedTimeSeries =
              // @ts-ignore
              valueItem.timeseries?.reduce((acc, timeSeriesItem) => {
                // there are cases where instead of timestamp key, we have intervals
                // not displaying compare seasons data for them yet.
                if (timeSeriesItem.timestamp) {
                  const compareSeasonsYear = new Date(timeSeriesItem.timestamp).getFullYear()

                  acc.push({
                    ...timeSeriesItem,
                    compareSeasonsYear: compareSeasonsYear.toString(),
                    timestamp: new Date(
                      new Date(timeSeriesItem.timestamp).setFullYear(
                        compareSeasonsYear + compareSeasonsInterval,
                      ),
                    ).toISOString(),
                  })
                }

                return acc
              }, []) || []

            return {
              ...valueItem,
              timeseries: updatedTimeSeries,
            }
          })

          return {
            ...accumulatedValues,
            [valueKey]: updatedValueData,
          }
        }, {})

        return {
          ...accumulatedLocations,
          [locationKey]: {
            ...locationData,
            values: updatedValues,
          },
        }
      }, {})

      return {
        ...acc,
        [fieldAssetType]: convertedLocationData,
      }
    }, {})

    return convertedSeasonValues
    // update only when compareSeasonsData is ready
  }, [JSON.stringify(compareSeasonsData)])

  return convertedData
}
