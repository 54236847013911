import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'

export const PropertyNameHeader = () => {
  const propertyId = Number(selectedFieldAssetsStore.useSelector((s) => s.property))

  const propertyName = fieldAssetStore.useSelector(
    (s) => s.properties?.[propertyId]?.propertyName ?? translate.phrases.banyanApp('Unknown Property'),
  )

  return (
    <div css={{ color: colors.midnight, fontSize: 18, fontWeight: 'bold', paddingBottom: 10, paddingTop: 5 }}>
      {propertyName}
    </div>
  )
}
