import moment from 'moment-timezone'
import { upperYAxisTitleMaker } from 'utils/upperYAxisTitleMaker'
import { merge } from 'lodash'
import { HighchartsChart } from 'components/HighchartsChart/HighchartsChart'
import { baseChartOptions } from 'components/StackedChart/_utils/baseChartOptions'
import { baseLineChartOptions } from 'components/StackedChart/_utils/baseLineChartOptions'
import { getKcForDay } from './_utils/getKcForDay'
import { TSemiosCustomHighchartsDateFormats } from 'components/HighchartsChart/_utils/setCustomHighchartsDateFormats'
import { translate } from 'i18n/i18n'
import { TKcDateRange } from 'App/Map/UserSettingsMenu/Shared/EvapotranspirationSettings/types'
import { colors } from 'settings/colors'

export const KcLineChart = ({
  kcRangesData,
  dateFrom,
  dateTo,
  kcMultiplier,
  noDataMessage,
  timezone = moment.tz.guess(),
}: {
  kcRangesData: TKcDateRange[]
  dateFrom?: string | null
  dateTo?: string | null
  kcMultiplier: number
  noDataMessage?: string
  timezone?: string
}) => {
  const dateFromMoment = moment.tz(dateFrom, timezone)
  const dateToMoment = moment.tz(dateTo, timezone)

  const data = Array.from({
    length: dateToMoment.diff(dateFromMoment, 'days') + 1,
  }).map((_, i) => {
    const dateToPush = dateFromMoment.clone().add(i, 'days')

    const kcForDay = getKcForDay({
      date: dateToPush,
      kcRangesData,
      timezone,
      kcMultiplier,
    })

    return [+dateToPush, kcForDay]
  })

  const noData = !data.some(([, kc]) => kc !== null)

  const chartOptions = merge(baseChartOptions(timezone), baseLineChartOptions(), {
    semiosHighchartsAdditions: {
      id: 'kc-line-chart',
      firstForecastTimestamp: +new Date(),
    },
    chart: {
      backgroundColor: kcRangesData.length ? 'transparent' : colors.grey50,
    },
    timezone,
    lang: {
      noData: noDataMessage,
    },
    tooltip: {
      xDateFormat: '%D' as TSemiosCustomHighchartsDateFormats,
    },
    yAxis: {
      title: upperYAxisTitleMaker(translate.phrases.unitSymbols('Kc')),
      visible: kcRangesData.length,
      min: 0,
    },
    xAxis: {
      plotBands: null, // prevent forecast from showing up
      plotLines: [
        {
          color: 'orange',
          value: +moment.tz(timezone).startOf('day'),
          width: 2,
          label: {
            text: translate.phrases.banyanApp('Today'),
          },
        },
      ],
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        color: colors.wmGreen,
        data: noData ? [] : data,
        dragDrop: { draggableX: false, draggableY: false },
        name: translate.phrases.unitSymbols('Kc'),
        type: 'spline',
        visible: true,
        yAxis: 0,
        connectNulls: false,
      },
    ],
  })

  return <HighchartsChart height={300} chartOptions={chartOptions} />
}
