import React, { useEffect } from 'react'
import { Global } from '@emotion/react'
import { useIntercom } from 'react-use-intercom'
import { Capacitor } from '@capacitor/core'
import { StatusBarArea } from '../StatusBarArea/StatusBarArea'
import { userDetailsStore } from '../../stores/userDetailsStore'

export const IntercomMessenger = () => {
  const intercom = useIntercom()
  const platform = Capacitor.getPlatform()

  const userDetailsSelection = userDetailsStore.useSelector((s) => ({
    firstName: s.firstName,
    email: s.email,
    intercomToken: s.intercomToken,
  }))

  useEffect(() => {
    if (userDetailsSelection.intercomToken) {
      intercom.boot({
        name: userDetailsSelection.firstName,
        email: userDetailsSelection.email,
        userHash: userDetailsSelection.intercomToken,
      })
    }
  }, [userDetailsSelection])

  if (platform !== 'ios' || !intercom.isOpen) return null

  /**
   * This black modal layer is used to cover other buttons on notch-screen mobile views when the Intercom Messenger is open.
   * Since we can't edit the content of Intercom's iFrame, we have to modify its height to 90% and apply this layer
   * to hide the top and bottom screen regions until they support Capacitor.
   */
  return (
    <>
      <Global
        styles={{
          '.intercom-messenger-frame': {
            height: '90% !important',
            marginTop: '50px !important',
          },
        }}
      />
      <StatusBarArea darkContent={true} />
      <div
        css={{
          position: 'fixed',
          height: '100%',
          width: '100%',
          left: 0,
          top: 0,
          overflow: 'hidden',
          zIndex: 8,
          backgroundColor: '#000',
          opacity: 1,
        }}
      />
    </>
  )
}
