import { TFieldAssetKeyTypes } from 'App/Map/types'
import { MapItemsCache } from 'components/GoogleMap/MapItemsCache'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'

type BLOCK_META = {
  blockId: TFieldAssetKeyTypes.TBlockId
  bounds: google.maps.LatLngBounds
  latLngs: google.maps.LatLng[]
}

export class BlocksDefaultPolygonsCache extends MapItemsCache<BLOCK_META, null, google.maps.Polygon> {
  constructor(options: { maps: typeof google.maps; map: google.maps.Map }) {
    super({
      createOverlay: (o) => {
        const polygon = new o.maps.Polygon({ paths: o.meta.latLngs, map: o.map })

        polygon.addListener('click', () => {
          setSelectedFieldAsset({ block: Number(o.meta.blockId) })
        })

        return polygon
      },
      renderOverlay: (o) => {
        const isSelected = selectedFieldAssetsStore.getState().block === o.meta.blockId

        o.overlay.setOptions({
          map: o.map,
          strokeColor: isSelected ? 'yellow' : 'white',
          strokeWeight: isSelected ? 4 : 2,
          strokePosition: o.maps.StrokePosition.CENTER,
        })
      },
      hideOverlay: (overlay) => {
        overlay.setMap(null)
      },
      maps: options.maps,
      map: options.map,
    })
  }
}
