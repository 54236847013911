import { TFieldAssetKeyTypes, TFieldAssetType } from 'App/Map/types'

export const getIdForLngLat = ({
  fieldAssetType,
  lngLat,
}: {
  fieldAssetType: TFieldAssetType
  lngLat: TFieldAssetKeyTypes.TLngLat // TODO: should be TFieldAssetKeyTypes.TLngLat
}) => {
  return `${fieldAssetType}-${lngLat}`
}
