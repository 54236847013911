import { IconInfo } from 'components/icons/IconInfo'
import { colors } from 'settings/colors'

export const InfoMessageBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      css={{
        display: 'flex',
        background: colors.grey50,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
        borderRadius: 3,
        padding: '12px 26px',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <span css={{ color: colors.primary, marginRight: 12, fontSize: 20, marginTop: '0.2em' }}>
        <IconInfo />
      </span>
      <span css={{ fontWeight: 500 }}>{children}</span>
    </div>
  )
}
