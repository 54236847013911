import React from 'react'
import { css } from '@emotion/css'
import { useMantineTheme } from '@mantine/core'

export const ActiveTabHighlightBorder = ({ width, left }: { width: number; left: number }) => {
  const theme = useMantineTheme()

  return (
    <span
      className={css`
        position: absolute;
        height: 4px;
        width: ${width}px;
        bottom: 0;
        left: ${left}px;
        z-index: 2;
        transition: all 0.3s ease-out 0s;
        background-color: ${theme.colors.white[0]};
      `}
    />
  )
}
