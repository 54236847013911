import { VC } from '@semios/app-platform-value-type-definitions'
import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'

type TAlterariaCurrentBlocksValueTypes =
  keyof VC.DomainTypes.Alternaria.TCurrentValuesReturnIgnoringKeying['blocks']

const blockValueTypeToPropertyValueType: Record<
  TAlterariaCurrentBlocksValueTypes,
  keyof VC.DomainTypes.Alternaria.TCurrentValuesReturnIgnoringKeying['properties']
> = {
  alternaria_next7DaysMax_dsv: 'alternaria_next7DaysMax_dsv',
  alternaria_next14DaysMax_dsv: 'alternaria_next14DaysMax_dsv',
  alternaria_today_dsv: 'alternaria_todayMinMedMax_dsv',
  alternaria_tomorrow_dsv: 'alternaria_tomorrowMinMedMax_dsv',
  alternaria_yesterday_dsv: 'alternaria_yesterdayMinMedMax_dsv',
  alternariaCumulativeRolling7Days_next7DaysMax_dsv: 'alternariaCumulativeRolling7Days_next7DaysMax_dsv',
  alternariaCumulativeRolling7Days_next14DaysMax_dsv: 'alternariaCumulativeRolling7Days_next14DaysMax_dsv',
  alternariaCumulativeRolling7Days_today_dsv: 'alternariaCumulativeRolling7DaysMinMedMax_today_dsv',
  alternariaCumulativeRolling7Days_tomorrow_dsv: 'alternariaCumulativeRolling7DaysMinMedMax_tomorrow_dsv',
  alternariaCumulativeRolling7Days_yesterday_dsv: 'alternariaCumulativeRolling7DaysMinMedMax_yesterday_dsv',
}

const updateMapVisuals = (newValueType: TAlterariaCurrentBlocksValueTypes) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.BLOCK]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.BLOCK],
        alternaria: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.BLOCK].alternaria ?? { mItem: 'M_Block' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.PROPERTY]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.PROPERTY],
        alternaria: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.PROPERTY].alternaria ?? { mItem: 'M_Property' }),
          valueType: blockValueTypeToPropertyValueType[newValueType],
        },
      },
    },
  }))
}

export const AlternariaSecondaryDropdown = () => {
  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.BLOCK]?.alternaria?.valueType,
  )

  const properties = fieldAssetStore.useSelector((s) => s.properties)

  const data = useMemo(() => {
    const returner: { value: TAlterariaCurrentBlocksValueTypes; label: string }[] = [
      // 7-day rolling cumulative
      {
        value: 'alternariaCumulativeRolling7Days_yesterday_dsv',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('7 Day DSV'),
          labelB: translate.phrases.banyanApp('Yesterday'),
        }),
      },
      {
        value: 'alternariaCumulativeRolling7Days_today_dsv',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('7 Day DSV'),
          labelB: translate.phrases.banyanApp('Today'),
        }),
      },
      {
        value: 'alternariaCumulativeRolling7Days_tomorrow_dsv',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('7 Day DSV'),
          labelB: translate.phrases.banyanApp('Tomorrow'),
        }),
      },
      {
        value: 'alternariaCumulativeRolling7Days_next7DaysMax_dsv',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Max 7 Day DSV'),
          labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 7 }),
        }),
      },
      {
        value: 'alternariaCumulativeRolling7Days_next14DaysMax_dsv',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Max 7 Day DSV'),
          labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 14 }),
        }),
      },
    ]

    return returner
  }, [properties, value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
