import React from 'react'
import { css, cx } from '@emotion/css'
import { useMantineTheme } from '@mantine/core'
import { useTabsContext } from 'components/Tabs/Tabs.context'

export const TabHeader = ({
  children,
  value,
  onClick,
}: {
  children: React.ReactNode
  value: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}) => {
  const ctx = useTabsContext()
  const { collapsed, activeTab, setActiveTab, setSelectedPosition, setShowTabs } = ctx
  const theme = useMantineTheme()

  const stylesForCollapsedView = css`
    white-space: nowrap;
    text-align: left;
    padding: 10px 20px;
    cursor: pointer;
    &::before {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: ${value === activeTab ? theme.colors.white[0] : theme.colors.grey[2]};
    }
  `

  const stylesForExpandedView = css`
    white-space: nowrap;
    text-align: center;
    padding: 10px 20px;
    cursor: pointer;
  `

  const styles: string = cx({ [stylesForCollapsedView]: collapsed }, { [stylesForExpandedView]: !collapsed })

  return (
    <>
      <div
        className={styles}
        data-tabvalue={value}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault()

          const element = event?.target as HTMLDivElement
          const selectedTab: string = element?.getAttribute('data-tabvalue') || activeTab

          setActiveTab(selectedTab)

          if (collapsed) {
            setShowTabs((oldValue: boolean) => !oldValue)
          } else {
            setSelectedPosition({ width: element.offsetWidth, left: element.offsetLeft })
          }

          onClick && onClick(event)
        }}
      >
        {children}
      </div>
    </>
  )
}
