import { EAggregationInterval } from 'App/Map/types'
import moment from 'moment-timezone'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { hourThresholdForAggregations } from '@semios/app-platform-common'

/**
 * TODO: choosing an aggregation interval dynamically
 * should ultimately be up to the API, but we can
 * work around it on the front-end for now via this
 */
export const chooseAmongstUnAggHourlyAndDaily = ():
  | EAggregationInterval.HOURLY
  | EAggregationInterval.DAILY
  | EAggregationInterval.UNAGGREGATED => {
  const { dateFrom, dateTo } = detailsPanelStore.getState()
  const difference = moment.tz(dateTo, 'utc').diff(moment.tz(dateFrom, 'utc'), 'hours')

  // 3 days should always be covered
  if (difference < hourThresholdForAggregations.UNAGGREGATED) return EAggregationInterval.UNAGGREGATED

  // 31 days should always be covered
  if (difference < hourThresholdForAggregations.HOURLY) return EAggregationInterval.HOURLY

  return EAggregationInterval.DAILY
}
