import { translate } from 'i18n/i18n'
import { TFieldAssetValueTypes } from 'App/Map/types'

type TBlock = TFieldAssetValueTypes.TBlock

export const SummaryTableContent = ({
  dataPoints,
  block,
}: {
  dataPoints: { timestamp: string; value: number | null; name: string }[]
  block: TBlock
}) => {
  return (
    <div
      css={{
        alignItems: 'center',
        justifyContent: 'center',
        width: 'max-content',
        minWidth: 74,
        maxWidth: '100%',
        height: 'max-content !important',
        padding: '30px 15px',
      }}
    >
      <div
        css={{
          width: '100%',
          margin: '0 0 10px 0',
          display: 'flex',
          fontSize: '14px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p
          css={{
            margin: '0px',
            fontWeight: 'bold',
            paddingRight: '20px',
            visibility: 'hidden',
          }}
        >
          {block.name}
        </p>
      </div>
      <div
        css={{
          paddingLeft: '10px',
        }}
      >
        {dataPoints.map((point, index) => {
          return (
            <div
              css={{
                position: 'relative',
              }}
              key={index}
            >
              <div
                css={{
                  width: '100%',
                  margin: '0 0 10px 0',
                  fontSize: '14px',
                  visibility: 'hidden',
                }}
              >
                {point.name}
              </div>
              <div
                css={{
                  position: 'absolute',
                  top: 0,
                  left: '-10px',
                  right: 0,
                  bottom: 0,
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {point.value === null ? translate.phrases.templates('-') : point.value}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
