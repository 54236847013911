import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loader } from '@mantine/core'
import { MonthPickerInput } from '@mantine/dates'
import { TKcDateRange } from 'App/Map/UserSettingsMenu/Shared/EvapotranspirationSettings/types'
import { translate } from 'i18n/i18n'
import { Dispatch, SetStateAction } from 'react'
import { colors } from 'settings/colors'
import { KcLineChart } from '../../../../../KcLineChart/KcLineChart'
import moment from 'moment-timezone'

export const ExpandedRow = ({
  dates,
  setDates,
  loading,
  data,
  kcMultiplier,
}: {
  dates: [Date | null, Date | null]
  setDates: Dispatch<SetStateAction<[Date | null, Date | null]>>
  loading: boolean
  data: TKcDateRange[]
  kcMultiplier: number
}) => {
  const timezone = moment.tz.guess()

  return (
    <tr>
      <td
        colSpan={4}
        css={{
          margin: '0 !important',
          padding: '0 !important',
          borderTop: 'none !important;',
          borderBottom: 'none !important;',
        }}
      >
        <div css={{ display: 'flex', flexDirection: 'column', padding: 12, width: '100%', minWidth: 240 }}>
          <MonthPickerInput
            type="range"
            value={dates}
            label={translate.phrases.banyanApp('Select Date Range')}
            placeholder={translate.phrases.banyanApp('Select Date Range')}
            rightSection={<FontAwesomeIcon css={{ color: colors.grey800, fontSize: 16 }} icon={faCalendar} />}
            disabled={loading}
            onChange={(e) => {
              const startOfMonthDateFrom = e[0] ? moment.tz(e[0], timezone).startOf('month').toDate() : null
              const endOfMonthDateTo = e[1] ? moment.tz(e[1], timezone).endOf('month').toDate() : null

              return setDates([startOfMonthDateFrom, endOfMonthDateTo])
            }}
            mx="auto"
            miw={240}
            valueFormat={translate.dates.getMomentFormat('MMM D, YYYY')}
            allowSingleDateInRange
          />
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 12,
              width: '100%',
              height: 300,
            }}
          >
            {loading ? (
              <Loader />
            ) : (
              <KcLineChart
                kcRangesData={data}
                dateFrom={dates[0] ? moment.tz(dates[0], timezone).format('YYYY-MM-DD') : null}
                dateTo={dates[1] ? moment.tz(dates[1], timezone).format('YYYY-MM-DD') : null}
                kcMultiplier={kcMultiplier}
                noDataMessage={translate.phrases.banyanApp('No Data Available for Selected Date Range')}
              />
            )}
          </div>
        </div>
      </td>
    </tr>
  )
}
