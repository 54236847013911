import { routes } from '@semios/app-platform-banyan-route-definitions'
import { RangeSlider } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { Authorization } from 'components/Authorization/Authorization'
import { Button } from 'components/Button/Button'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { apiFetch } from 'utils/apiFetch'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { showNotification } from 'utils/showNotification'
import { z } from 'zod'
import { defaultValuesRequested, pointCategory } from '../../plantStress'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { PlantWaterStressLegend } from '../PlantWaterStressLegend/PlantWaterStressLegend'
import { IconPause } from 'components/icons/IconPause'
import { roundToDecimalPlaces } from '@semios/app-platform-common'
import { DEFAULT_MAX_LOW_STRESS, DEFAULT_MIN_HIGH_STRESS } from '../plantWaterStress'
import { ConfirmationSettingsModal } from 'components/ConfirmationSettingsModal/ConfirmationSettingsModal'
import { getPrimaryValueGroup } from 'stores/selectedValueGroupsStore/getPrimaryValueGroup'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'

type TPlantStressSettings = {
  crop: string
  defaultMaxLowStress: number
  defaultMinHighStress: number
  handleAfterSave: (
    selectedTreeDendrometerStation: string,
    maxLowStress: number,
    minHighStress: number,
  ) => void
}

const refreshMapData = () => {
  const primaryValueGroup = getPrimaryValueGroup() as TValueGroup

  if (primaryValueGroup === 'plant_stress') {
    mapControlsStore.setState((s) => ({ ...s, keyForRefreshingMap: new Date().toISOString() }))
  }
}

const MAX = 4
const MIN = 0
const STEP = 0.01

const refreshSummaryTab = () =>
  detailsPanelStore.setState((s) => ({ ...s, keyForRefreshingSummaryTab: new Date().toISOString() }))

export const PlantStressSettings = ({
  crop,
  defaultMaxLowStress,
  defaultMinHighStress,
  handleAfterSave,
}: TPlantStressSettings) => {
  const [plantStressSettingsOpened, setPlantStressSettingsOpened] = useState(false)
  const [confirmModalOpened, setConfirmModalOpened] = useState(false)

  const { selectedTreeDendrometerStation, propertyId } = selectedFieldAssetsStore.useSelector((s) => ({
    propertyId: s.property,
    selectedTreeDendrometerStation: s[pointCategory],
  }))

  const selectedDendrometerName = fieldAssetStore.useSelector(
    (s) =>
      s?.properties?.[Number(propertyId)]?.points?.[
        selectedTreeDendrometerStation as TFieldAssetKeyTypes.TLngLat
      ]?.name ?? translate.phrases.banyanApp('Unnamed Station'),
  )

  if (
    !selectedTreeDendrometerStation ||
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(defaultValuesRequested),
      pointCategory,
    })
  )
    return null

  const schema = z.object({
    maxLowStress: z.number().min(0),
    minHighStress: z.number().min(0),
  })

  const form = useForm({
    validate: zodResolver(schema),
  })

  useEffect(() => {
    form.setValues({
      maxLowStress: defaultMaxLowStress,
      minHighStress: defaultMinHighStress,
    })
  }, [plantStressSettingsOpened, defaultMaxLowStress, defaultMinHighStress])

  const handleUpdate = async () => {
    const { maxLowStress, minHighStress } = form.values

    const queryParams = {
      url: routes.FieldAssetSettingsSet.path,
      body: {
        plantStressUpdate: [
          {
            plantStressLngLat: selectedTreeDendrometerStation,
            maxLowStress,
            minHighStress,
          },
        ],
      },
    }

    const response = await apiFetch<
      routes.FieldAssetSettingsSet.Request,
      routes.FieldAssetSettingsSet.Response
    >(queryParams)

    if (response?.plantStressUpdate) {
      showNotification({
        message: translate.phrases.banyanApp('Successfully updated settings'),
        type: 'success',
      })

      setPlantStressSettingsOpened(false)

      handleAfterSave(selectedTreeDendrometerStation, maxLowStress, minHighStress)

      refreshMapData()

      refreshSummaryTab()
    } else {
      showNotification({
        message: translate.phrases.banyanApp('Failed to update plant stress setting'),
        type: 'error',
      })
    }
  }

  const formUnchanged =
    form.values.maxLowStress === defaultMaxLowStress && form.values.minHighStress === defaultMinHighStress

  const updateButtonDisabled = formUnchanged
  const maxLowPercentage = (form.values.maxLowStress / MAX) * 100
  const maxMediumPercentage = (form.values.minHighStress / MAX) * 100

  const showResetButton =
    form.values.maxLowStress !== DEFAULT_MAX_LOW_STRESS ||
    form.values.minHighStress !== DEFAULT_MIN_HIGH_STRESS

  const handleResetAndClose = () => {
    setPlantStressSettingsOpened(false)
  }

  const handleCloseWithoutSaving = () => {
    if (!formUnchanged) {
      setConfirmModalOpened(true)
    } else {
      handleResetAndClose()
    }
  }

  return (
    <Authorization requires={{ permission: 'EDIT_TREE_DENDROMETER_SETTINGS', entity: Number(propertyId) }}>
      <>
        <ModalDrawer
          title={translate.phrases.banyanApp('Manage Water Stress Level Settings')}
          opened={plantStressSettingsOpened}
          primaryButtonText={translate.phrases.banyanApp('Save Changes')}
          primaryButtonOnPress={handleUpdate}
          primaryButtonDisabled={updateButtonDisabled}
          secondaryButtonText={translate.phrases.banyanApp('Cancel')}
          secondaryButtonOnPress={handleCloseWithoutSaving}
          onClose={handleCloseWithoutSaving}
        >
          <div css={{ display: 'flex', gap: 10, flexDirection: 'column', padding: '0 60px' }}>
            <div css={{ fontWeight: 700, marginTop: 10, fontSize: 16, padding: '10px 0' }}>
              {translate.phrases.banyanApp('{{selectedDendrometerName}} - {{crop}}', {
                selectedDendrometerName,
                crop,
              })}
            </div>

            <div css={{ marginTop: 20, padding: '20px 0' }}>
              <RangeSlider
                min={MIN}
                max={MAX}
                minRange={STEP}
                value={[form.values.maxLowStress, form.values.minHighStress]}
                step={STEP}
                onChange={(value) => {
                  form.setValues({
                    minHighStress: roundToDecimalPlaces(value[1], 2),
                    maxLowStress: roundToDecimalPlaces(value[0], 2),
                  })
                }}
                css={{
                  '.mantine-Slider-track': {
                    '&::before': {
                      backgroundColor: 'transparent',
                      background: `linear-gradient(to right, ${colors.plantStressLow} ${maxLowPercentage}%, ${colors.plantStressMedium} ${maxLowPercentage}% ${maxMediumPercentage}%, ${colors.plantStressHigh} ${maxMediumPercentage}% 100%)`,
                    },
                  },
                  '.mantine-Slider-bar': {
                    backgroundColor: colors.plantStressMedium,
                  },
                  '.mantine-Slider-thumb': {
                    borderWidth: 1,
                  },
                }}
                color={colors.primary}
                labelAlwaysOn={true}
                thumbChildren={<IconPause />}
                thumbSize={22}
              />
            </div>
            <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <PlantWaterStressLegend />
            </div>
            {showResetButton && (
              <div css={{ display: 'flex', marginTop: 20 }}>
                <Button
                  variant="tertiary"
                  onClick={() => {
                    form.setValues({
                      minHighStress: roundToDecimalPlaces(DEFAULT_MIN_HIGH_STRESS, 2),
                      maxLowStress: roundToDecimalPlaces(DEFAULT_MAX_LOW_STRESS, 2),
                    })
                  }}
                >
                  {translate.phrases.banyanApp('Reset to Default')}
                </Button>
              </div>
            )}
          </div>
          <ConfirmationSettingsModal
            confirmModalOpened={confirmModalOpened}
            setConfirmModalOpened={setConfirmModalOpened}
            handleResetAndClose={handleResetAndClose}
            handleUpdate={handleUpdate}
          />
        </ModalDrawer>
        <Button variant="link" onClick={() => setPlantStressSettingsOpened(true)} css={{ paddingRight: 25 }}>
          {translate.phrases.banyanApp('Edit Settings')}
        </Button>
      </>
    </Authorization>
  )
}
