import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker'
import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import {
  selectedValueGroupsStore,
  TValueGroup,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { getIsoWithoutTheZ } from 'utils/getIsoWithoutTheZ'
import { getMaxDateForValueGroup } from './getMaxDateForValueGroup'

export const DetailsPanelDateRange = () => {
  const { dateFrom, dateTo } = detailsPanelStore.useSelector(({ dateFrom, dateTo }) => ({ dateFrom, dateTo }))
  const timezone = moment.tz.guess()
  const selectedValueGroups = selectedValueGroupsStore.useSelector((s) => s.selectedValueGroups)

  const maxDate = useMemo(() => {
    const maxDatesForSelectedValueGroups: moment.Moment[] = []

    Object.entries(selectedValueGroups).forEach(([valueGroup, selectionTime]) => {
      if (selectionTime) {
        maxDatesForSelectedValueGroups.push(
          getMaxDateForValueGroup({ timezone, valueGroup: valueGroup as TValueGroup }),
        )
      }
    })

    return moment.max(maxDatesForSelectedValueGroups)
  }, [selectedValueGroups])

  const handleDateChange = useCallback(
    (args: {
      handleValueChange: (range: [Date, Date]) => void
      setDropdownOpened: React.Dispatch<React.SetStateAction<boolean>>
      value: [Date | null, Date | null] | null
    }) => {
      if (args.value === null) return

      if (args.value[0] === null || args.value[1] === null) return

      const [newDateFrom, newDateTo] = args.value

      detailsPanelStore.setState((s) => ({
        ...s,
        compareSeasonsInterval: 0, // temporary to fix bug in useApiValuesTimeseries
        dateFrom: getIsoWithoutTheZ(moment.tz(newDateFrom, timezone).startOf('day')),
        dateTo: getIsoWithoutTheZ(moment.tz(newDateTo, timezone).endOf('day')),
      }))
    },
    [],
  )

  return (
    <DateRangePicker
      dateFrom={moment.tz(dateFrom, timezone)}
      dateTo={moment.tz(dateTo, timezone)}
      handleApply={handleDateChange}
      height={42}
      maxDate={maxDate}
      timezone={timezone}
      width={240} // 300 pixels is the same width of the calendar picker div when the dropdown is open :D
    />
  )
}
