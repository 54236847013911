import { EWindmachineActivity } from 'App/Map/types'
import { getWindMachineActivityTitleAndColor } from 'App/Map/_utils/getWindMachineActivityTitleAndColor'
import { useMemo } from 'react'
import { makeIntervals } from '../makeIntervals'

const LegendItem = (props: { windMachineActivity: EWindmachineActivity | null }) => {
  const { color, name } = getWindMachineActivityTitleAndColor(props.windMachineActivity)

  return (
    <div css={{ display: 'flex', gap: 6, justifyContent: 'center', alignItems: 'center' }}>
      <div css={{ width: 12, height: 12, background: color }} />
      <div>{name}</div>
    </div>
  )
}

export const Legend = (props: { intervals: ReturnType<typeof makeIntervals> }) => {
  const { includeOff, includeFullRun, includeNoConnection, includeUnknown } = useMemo(() => {
    let includeOff = true
    let includeFullRun = true
    let includeNoConnection = true
    let includeUnknown = true

    props.intervals.forEach((i) => {
      if (i.value === 'OFF') includeOff = true
      else if (i.value === 'ON') includeFullRun = true
      else if (i.value === 'NO CONNECTION') includeNoConnection = true
      else includeUnknown = true
    })

    return { includeOff, includeFullRun, includeNoConnection, includeUnknown }
  }, [props.intervals])

  return (
    <div
      css={{
        fontSize: 14,
        fontWeight: 'bold',
        padding: '20px 18px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 22,
      }}
    >
      {!!includeOff && <LegendItem windMachineActivity={'OFF' as EWindmachineActivity} />}
      {!!includeFullRun && <LegendItem windMachineActivity={'ON' as EWindmachineActivity} />}
      {!!includeNoConnection && <LegendItem windMachineActivity={'NO CONNECTION' as EWindmachineActivity} />}
      {!!includeUnknown && <LegendItem windMachineActivity={null} />}
    </div>
  )
}
