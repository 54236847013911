import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import {
  MAP_VISUAL,
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentPointsValueTypes,
} from 'stores/mapControlsStore/types'

const updateMapVisuals = (newValueType: TValuesCurrentBlocksValueTypes & TValuesCurrentPointsValueTypes) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.BLOCK]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.BLOCK],
        almond_bloom: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.BLOCK].almond_bloom ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.POINT]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.POINT],
        almond_bloom: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.POINT].almond_bloom ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
    },
  }))
}

export const AlmondBloomSecondaryDropdown = () => {
  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.BLOCK]?.almond_bloom?.valueType,
  )

  const properties = fieldAssetStore.useSelector((s) => s.properties)
  const cultivar = translate.phrases.banyanApp('Nonpareil')

  const data = useMemo(() => {
    const returner: { value: TValuesCurrentBlocksValueTypes; label: string }[] = [
      {
        value: 'almondBloom_today_pct',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Today'),
          labelB: cultivar,
        }),
      },
      {
        value: 'almondBloom_next7Days_pct',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Forecast {{count}} Days', { count: 7 }),
          labelB: cultivar,
        }),
      },
      {
        value: 'almondBloom_next14Days_pct',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Forecast {{count}} Days', { count: 14 }),
          labelB: cultivar,
        }),
      },
    ]

    return returner
  }, [properties, value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
