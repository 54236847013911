import React from 'react'
import { TActiveGateway, TActiveNode, TPlannedGateway, TPlannedNode } from '../types'
import { translate } from 'i18n/i18n'
import { openHelpGuideModal } from '../HelpGuideModal/HelpGuideModal'
import { getNodeTypeLabel } from './getNodeTypeLabel'
import { openNodeMaintenanceModal } from '../NodeMaintenanceModal/NodeMaintenanceModal'
import { MenuButton } from '../ActionMenu/MenuButton/MenuButton'
import { isPlannedNode } from './isPlannedNode'
import { getIntercomPrePopulatedMessage } from 'utils/getIntercomPrePopulatedMessage'
import { openRepositionPlannedNodeModal } from './openRepositionPseudonodeModal'
import { selectedPropertyHasPermission } from '../../Map/PanelDetails/_utils/selectedPropertyHasPermission'

export function getNodeActions(node: TActiveNode | TActiveGateway | TPlannedNode | TPlannedGateway) {
  const equipmentTypeLabel = getNodeTypeLabel(
    (node as TActiveNode).nodeType || (node as TActiveGateway).gatewayType,
  )

  const prePopulatedMessage = getIntercomPrePopulatedMessage({ node: node }) ?? ''

  return (
    <>
      {!isPlannedNode(node) && (
        <MenuButton
          label={translate.phrases.placeholder('{{equipmentType}} Maintenance', {
            equipmentType: equipmentTypeLabel,
          })}
          description={translate.phrases.placeholder('View maintenance actions')}
          onClick={() => openNodeMaintenanceModal(node as TActiveNode)}
        />
      )}

      {isPlannedNode(node) && selectedPropertyHasPermission({ permission: 'EDIT_SSC_INSTALL_PLANNED' }) && (
        <MenuButton
          label={translate.phrases.placeholder('Reposition', {
            equipmentType: equipmentTypeLabel,
          })}
          description={translate.phrases.placeholder('Reposition planned equipment')}
          onClick={() => openRepositionPlannedNodeModal(node)}
        />
      )}

      <MenuButton
        label={translate.phrases.placeholder('Guides and Resources')}
        description={translate.phrases.placeholder('Access step-by-step instructions')}
        onClick={() => {
          openHelpGuideModal({
            type: 'node',
            equipmentType: (node as TActiveNode).nodeType.toUpperCase(),
          })
        }}
      />

      <MenuButton
        label={translate.phrases.placeholder('Contact Support')}
        description={translate.phrases.placeholder('Reach out to our team for help')}
        onClick={() => {
          // @ts-ignore
          window.Intercom('showNewMessage', prePopulatedMessage)
        }}
      />
    </>
  )
}
