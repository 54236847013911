import { TFieldAssetValueTypes } from 'App/Map/types'

type TPoint = TFieldAssetValueTypes.TPoint

type TBlock = TFieldAssetValueTypes.TBlock

export const SummaryTableLabel = ({
  block,
  associatedPoints,
}: {
  block: TBlock
  associatedPoints: TPoint[]
}) => {
  return (
    <div
      css={{
        padding: '30px 0',
      }}
    >
      <div
        css={{
          width: '100%',
          margin: '0 0 10px 0',
          display: 'flex',
          fontSize: '14px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p
          css={{
            margin: '0px',
            fontWeight: 'bold',
            paddingRight: '20px',
          }}
        >
          {block.name}
        </p>
      </div>
      <div
        css={{
          paddingLeft: '10px',
        }}
      >
        {associatedPoints.map((point) => (
          <p
            css={{
              width: '100%',
              margin: '0 0 10px 0',
              fontSize: '14px',
            }}
            key={point.lngLat}
          >
            {point.name}
          </p>
        ))}
      </div>
    </div>
  )
}
