import { css } from '@emotion/css'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import {
  DropdownSelectorProperty,
  TDropdownSelectorPropertyProps,
} from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { translate } from 'i18n/i18n'
import { detailsPanelStore, TSoilAverage } from 'stores/detailsPanelStore'

export const SoilTitleChildren = (props: TDropdownSelectorPropertyProps & { showAverageToggle: boolean }) => {
  const soilDepthsToAverage = detailsPanelStore.useSelector((s) => s.soilDepthsToAverage)
  const { showAverageToggle, ...rest } = props

  return (
    <div css={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '4px 24px' }}>
      <DropdownSelectorProperty {...rest} />
      {showAverageToggle && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '4px 24px',
          }}
        >
          <div css={{ fontWeight: 'normal' }}>{translate.phrases.banyanApp('Average Depths')}</div>
          <ToggleButton
            buttonProps={{ size: 'xs', className: css({ fontSize: 13 }) }}
            data={[
              { label: translate.phrases.banyanApp('Default'), value: 'DEFAULT' },
              { label: translate.phrases.banyanApp('All'), value: 'ALL_EXCEPT_SHALLOWEST' },
            ]}
            onChange={(newValue: TSoilAverage) =>
              detailsPanelStore.setState((s) => ({ ...s, soilDepthsToAverage: newValue }))
            }
            value={soilDepthsToAverage}
          />
        </div>
      )}
    </div>
  )
}
