import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { TUnitConverterOptionsWithUnits, TUnitConverterTypicalReturn } from '../types'

type TPlantStressUnit = 'µm'

type TPlantStressOptions = 'plantWaterStress' | 'trunkDisplacement'

const plantStressFunctionMaker =
  (option: TPlantStressOptions) =>
  (
    plantStressAmount: number | null = null,
    {
      decimalPlaces,
    }: {
      decimalPlaces?: number
    } = {},
  ) => {
    const defaultNumberOfDecimalPlaces = option === 'plantWaterStress' ? 2 : 0
    const decimalPlacesToUse = decimalPlaces ?? defaultNumberOfDecimalPlaces
    const convertedValue = plantStressAmount
    const unitSymbol = option === 'plantWaterStress' ? '' : translate.measurements.micrometers.unit()

    return {
      categoryTitle: () => {
        return translate.phrases.templates('{{label}} ({{unitSymbol}})', {
          label: translate.phrases.banyanApp('Plant Stress'),
          unitSymbol,
        })
      },
      categoryTitleWithoutUnit: () => {
        if (option === 'plantWaterStress') {
          return translate.phrases.banyanApp('Plant Water Stress')
        }

        if (option === 'trunkDisplacement') {
          return translate.phrases.banyanApp('Trunk Displacement')
        }

        return ''
      },
      defaultNumberOfDecimalPlaces: () => defaultNumberOfDecimalPlaces,
      suffix: () => unitSymbol,
      shortTitle: () => {
        if (option === 'plantWaterStress') {
          return translate.phrases.banyanApp('Plant Water Stress')
        }

        if (option === 'trunkDisplacement') {
          return translate.phrases.banyanApp('Trunk Displacement')
        }

        return ''
      },
      title: () => {
        if (option === 'plantWaterStress') {
          return translate.phrases.templates('{{label}} ({{unitSymbol}})', {
            label: translate.phrases.banyanApp('Plant Water Stress'),
            unitSymbol,
          })
        }

        if (option === 'trunkDisplacement') {
          return translate.phrases.templates('{{label}} ({{unitSymbol}})', {
            label: translate.phrases.banyanApp('Trunk Displacement'),
            unitSymbol,
          })
        }

        return ''
      },
      titleWithoutUnit: () => {
        if (option === 'plantWaterStress') {
          return translate.phrases.banyanApp('Plant Water Stress')
        }

        if (option === 'trunkDisplacement') {
          return translate.phrases.banyanApp('Trunk Displacement')
        }

        return ''
      },
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlacesToUse)
      },
      valueAsString: () => {
        return translate.measurements.micrometers.value(convertedValue, decimalPlacesToUse)
      },
      valueWithSuffix: () => {
        return translate.measurements.micrometers.valueWithUnit(convertedValue, decimalPlacesToUse)
      },
      valueWithNoRounding: () => convertedValue,
    }
  }

export const plantStress: Record<
  TPlantStressOptions,
  (
    fruitGrowthAmount?: number | null,
    option?: TUnitConverterOptionsWithUnits<TPlantStressUnit>,
  ) => TUnitConverterTypicalReturn
> = {
  plantWaterStress: plantStressFunctionMaker('plantWaterStress'),
  trunkDisplacement: plantStressFunctionMaker('trunkDisplacement'),
}
