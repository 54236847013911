import { Tooltip } from '@mantine/core'
import React, { useRef } from 'react'
import { alphaColorReplacer, colors } from 'settings/colors'
import { mapSelectedCardStore } from 'stores/mapSelectedCardStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { useSetMapOverlayZIndex } from 'utils/useSetMapOverlayZIndex'
import { TRAP_META, TTrapsValueTypesToPropsDictionary } from '../TrapValuesCache'
import { TrapMapIcon } from './TrapMapIcon/TrapMapIcon'
import type { VV } from '@semios/app-platform-value-type-definitions'

export type TTrapBadgeComponentProps = {
  meta: TRAP_META
  valueTypesToPropsDictionary: TTrapsValueTypesToPropsDictionary
}

export type TTimeseries = NonNullable<
  VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['points']
>['insectTrapCatches_1'][number]['timeseries'][number]

export const TrapBadgeComponent = (props: TTrapBadgeComponentProps) => {
  const primaryValueGroup = usePrimaryValueGroup()

  const { showMapBadgeNames, valueType } = mapControlsStore.useSelector((s) => ({
    showMapBadgeNames: s.showMapBadgeNames,
    valueType: !primaryValueGroup ? null : s.mapVisualValueGroup.TRAP?.[primaryValueGroup]?.valueType,
    otherThingsWeWantToTriggerARefreshOn: [s.heatmapColoring, s.heatmapExtremes],
  }))

  const isSelected = selectedFieldAssetsStore.useSelector((s) => s.trapPoint === props.meta.lngLat)
  const isSelectedByBlock = selectedFieldAssetsStore.useSelector((s) => s.block === props.meta.blockId)
  const ref = useRef<HTMLDivElement>(null)

  useSetMapOverlayZIndex({ ref, mItem: 'M_Trap', isSelected: isSelected })

  const containerCSS = props.valueTypesToPropsDictionary?.[String(valueType)]?.getContainerCSS() ?? {}

  // TODO: We're using Mantine's Tooltip here because it does some nice placement on the map... Should update other map icons to use it
  return (
    <Tooltip
      styles={() => ({
        tooltip: {
          fontSize: 14,
          borderRadius: '10px',
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 8,
          paddingRight: 8,
          backgroundColor: alphaColorReplacer(colors.midnight, 0.8),
          color: 'white',
        },
      })}
      label={props.meta.name}
      opened={showMapBadgeNames ? true : undefined}
    >
      <div>
        <TrapMapIcon
          fillColor={containerCSS.backgroundColor ? String(containerCSS.backgroundColor) : undefined}
          fontColor={containerCSS.color ? String(containerCSS.color) : undefined}
          innerText={props.valueTypesToPropsDictionary?.[String(valueType)]?.children}
          onClick={() => {
            setSelectedFieldAsset({ lngLat: props.meta.lngLat })

            mapSelectedCardStore.actions.showTrap(props.meta)
          }}
          strokeColor={isSelectedByBlock ? 'yellow' : 'white'}
        />
      </div>
    </Tooltip>
  )
}
