import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { MetaWithNodeIdentifier } from './queue'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { getIdentifier } from '../getIdentifier'
import { GeoJSONPoint, parse } from 'wellknown'
import { TNodeType } from '@semios/app-platform-banyan-route-definitions/src/shared-types'

export type NodeDropInstallMeta = routes.ServiceCenterNodeDropInstall.Request & MetaWithNodeIdentifier

const request = async (meta: NodeDropInstallMeta): Promise<NodeDropInstallMeta> => {
  await apiFetch<routes.ServiceCenterNodeDropInstall.Request, routes.ServiceCenterNodeDropInstall.Response>({
    url: routes.ServiceCenterNodeDropInstall.path,
    body: meta,
    params: {
      method: 'POST',
    },
  })

  return meta
}

const callback = (meta: NodeDropInstallMeta): void => {
  const { nodeIdentifier, nodeType, propertyId, maintenanceOwnerId, location } = meta
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())
  const updatedActiveNodes = [...activeNodes].filter((node) => getIdentifier(node) !== nodeIdentifier)
  const parsedLocation = parse(location) as GeoJSONPoint

  parsedLocation.coordinates[0] = parseFloat(parsedLocation.coordinates[0].toFixed(6))

  parsedLocation.coordinates[1] = parseFloat(parsedLocation.coordinates[1].toFixed(6))

  const newActiveNode = {
    nodeIdentifier,
    location: JSON.stringify(parsedLocation),
    nodeType: nodeType.toLowerCase() as TNodeType,
    propertyId,
    maintenanceOwnerId,
    name: null,
  }

  serviceCenterStore.actions.setActiveNodes([...updatedActiveNodes, newActiveNode])
}

export const serviceCenterNodeDropInstall = {
  request,
  callback,
}
