import { routes } from '@semios/app-platform-banyan-route-definitions'
import { Group, ChevronIcon } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  TBlockETData,
  TSelectedKcConfiguration,
  TSelectedKcConfigurationMap,
  TKcDateRange,
} from 'App/Map/UserSettingsMenu/Shared/EvapotranspirationSettings/types'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { Dispatch, SetStateAction, Fragment, useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { useApiREST } from 'utils/useApiREST'
import { ExpandedRow } from './ExpandedRow/ExpandedRow'
import { KcModelSelect } from './KcModelSelect/KcModelSelect'
import { KcMultiplierSliderOrInput } from './KcMultiplierSliderOrInput/KcMultiplierSliderOrInput'

export const Row = ({
  block,
  blockIdsOfOtherValidCropBlocks,
  selectedValue,
  setSelectedValueMap,
  isDirty,
  setDirtyBlockIds,
}: {
  block: TBlockETData
  blockIdsOfOtherValidCropBlocks: number[]
  selectedValue: TSelectedKcConfiguration
  setSelectedValueMap: Dispatch<SetStateAction<TSelectedKcConfigurationMap>>
  isDirty: boolean
  setDirtyBlockIds: Dispatch<SetStateAction<number[]>>
}) => {
  const defaultDateFrom = moment.tz().subtract(1, 'year').toDate()
  const defaultDateTo = moment.tz().add(1, 'year').toDate()
  const [dates, setDates] = useState<[Date | null, Date | null]>([defaultDateFrom, defaultDateTo])
  const [rowIsExpanded, { toggle }] = useDisclosure(false)
  const [fetchedSemiosModels, setFetchedSemiosModels] = useState({} as Record<string, TKcDateRange[]>)

  const selectedCustomModel = block.organizationKcModels?.find(
    ({ modelId }) => modelId === selectedValue?.modelId,
  )

  const args = {
    // adding a year to the dates because some date ranges fall outside the range of the dates.
    // This is to ensure we get all the data we need
    dateFrom: moment.tz(dates[0], 'utc').subtract(1, 'year').toDate(),
    dateTo: moment.tz(dates[1], 'utc').add(1, 'year').toDate(),
    kcModelId: selectedValue?.modelId,
  }

  const { loading } = useApiREST({
    url: routes.FieldAssetSettingsGet.path,
    preventFetch:
      !block.blockId ||
      !rowIsExpanded ||
      !!selectedCustomModel ||
      !!(selectedValue.modelId && fetchedSemiosModels[selectedValue.modelId]) ||
      !dates[0] ||
      !dates[1],
    body: {
      kcSemiosModel: args,
    },
    shaper: (unshapedData: { kcSemiosModel: TKcDateRange[] }) => {
      // we'll store the fetched models so we don't have to keep refetching them unnecessarily
      setFetchedSemiosModels((prev) => {
        if (!selectedValue?.modelId) return prev

        const prevCopy = { ...prev }

        prevCopy[selectedValue?.modelId] = unshapedData.kcSemiosModel

        return prevCopy
      })
    },
    watchers: [JSON.stringify(args), rowIsExpanded],
  })

  // refetch data when dates change
  useEffect(() => {
    setFetchedSemiosModels((prev) => {
      const prevCopy = { ...prev }

      if (!selectedValue?.modelId) return prevCopy

      delete prevCopy[selectedValue?.modelId]

      return prevCopy
    })
  }, [dates[0], dates[1]])

  return (
    <Fragment key={block.blockId}>
      <tr key={block.blockId}>
        <td css={{ maxWidth: 120 }}>
          <Group noWrap css={{ minWidth: 100 }}>
            <span onClick={toggle} css={{ cursor: 'pointer' }}>
              <ChevronIcon
                css={{
                  transform: rowIsExpanded ? 'rotate(-180deg)' : undefined,
                  transition: 'transform 200ms',
                }}
              />
            </span>
            <div>
              <div>
                <b>{block.blockName}</b>
              </div>
              <div css={{ fontSize: 12 }}>{block.cropName}</div>
            </div>
          </Group>
        </td>
        <td css={{ maxWidth: 160 }}>
          <KcModelSelect
            blockIdsOfOtherValidCropBlocks={blockIdsOfOtherValidCropBlocks}
            blockId={Number(block.blockId)}
            cropName={block.cropName}
            organizationModelsData={block.organizationKcModels ?? []}
            semiosKcModels={block.semiosKcModels ?? []}
            loading={loading}
            selectedValue={selectedValue}
            setSelectedValueMap={setSelectedValueMap}
            isDirty={isDirty}
            setDirtyBlockIds={setDirtyBlockIds}
          />
        </td>
        <td css={{ maxWidth: 60 }}>
          {/* organization kc models don't have kc multipliers */}
          {selectedValue?.modelType !== 'ORGANIZATION_KC_MODEL' && selectedValue?.modelId && (
            <KcMultiplierSliderOrInput
              selectedValue={selectedValue}
              setSelectedValueMap={setSelectedValueMap}
              blockId={Number(block.blockId)}
              setDirtyBlockIds={setDirtyBlockIds}
            />
          )}
        </td>
      </tr>
      <tr>
        <td
          colSpan={4}
          css={{
            textAlign: 'center',
            height: 10,
            margin: '0 !important',
            padding: '0 !important',
            borderTop: 'none !important;',
            borderBottom: 'none !important;',
          }}
        >
          {isDirty && (
            <Button
              variant="link"
              css={{
                color: colors.primary,
                border: 'none',
                fontSize: 11,
                lineHeight: '18px',
                marginRight: 20,
                height: 'unset',
              }}
              onClick={async (e) => {
                e.stopPropagation()

                if (!selectedValue || !selectedValue.modelType) {
                  return
                }

                setSelectedValueMap((prev) => {
                  const prevCopy = { ...prev }

                  return blockIdsOfOtherValidCropBlocks.reduce((acc, curr) => {
                    return {
                      ...acc,
                      [curr]: selectedValue,
                    }
                  }, prevCopy)
                })
              }}
            >
              {translate.phrases.banyanApp('Apply Settings to All {{cropName}} Blocks', {
                cropName: block.cropName,
              })}
            </Button>
          )}
        </td>
      </tr>
      {rowIsExpanded && selectedValue && (
        <ExpandedRow
          loading={loading}
          data={
            !selectedCustomModel && !(selectedValue.modelId && fetchedSemiosModels[selectedValue.modelId])
              ? []
              : (selectedValue?.modelId && fetchedSemiosModels?.[selectedValue.modelId]) ||
                (selectedCustomModel?.dateRanges ?? []).map((range) => {
                  return {
                    ...range,
                    kcStart: range.kc,
                    kcEnd: range.kc,
                    linearInterpolation: false,
                  }
                })
          }
          dates={dates}
          setDates={setDates}
          kcMultiplier={selectedValue?.kcMultiplier ?? 1}
        />
      )}
    </Fragment>
  )
}
