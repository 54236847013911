import { routes } from '@semios/app-platform-banyan-route-definitions'
import { VV } from '@semios/app-platform-value-type-definitions'
import { blockLacksCropSectionMaker } from 'App/Map/PanelDetails/_utils/blockLacksCropSectionMaker'
import { AlmondBloomSettings } from 'App/Map/PanelDetails/_utils/by-domain/almondBloom/AlmondBloomSettings/AlmondBloomSettings'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { DropdownSelectorBlock } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorBlock/DropdownSelectorBlock'
import { EAggregationInterval } from 'App/Map/types'
import { StackedChartSection, TChartSeries } from 'components/StackedChart/types'
import { SeriesOptionsType } from 'highcharts'
import { translate } from 'i18n/i18n'
import { colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_BLOOM_PREDICTION' })

export const defaultValuesRequested: Partial<
  Record<
    VV.DomainTypes.AlmondBloom.TTimeseriesValueTypeKeysMerged,
    { preferredAggregationInterval: ReturnType<typeof chooseAmongstUnAggHourlyAndDaily> }
  >
> = {
  almondBloom: { preferredAggregationInterval: EAggregationInterval.DAILY },
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.almond_bloom || !selectedFieldAssets.block) return {}

  const valuesRequested = {
    ...defaultValuesRequested,
  }

  if (
    !doesSelectedBlockHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
    })
  )
    return {}

  return {
    blocks: {
      blockIds: [selectedFieldAssets.block],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.almondBloom().categoryTitleWithoutUnit(),
    titleChildren: (
      <>
        <DropdownSelectorBlock
          showAssociatedWeatherStationName
          valuesTimeseriesToFilterOn={Object.keys(defaultValuesRequested)}
        />
        <div>
          <AlmondBloomSettings />
        </div>
      </>
    ),
    id: 'stackem-bloom',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  if (
    !doesSelectedBlockHaveValueTypes({
      valuesTimeseries: Object.keys(defaultValuesRequested),
    })
  ) {
    return blockLacksCropSectionMaker({
      ...commonReturnItems,
      translatedCropName: translate.phrases.banyanApp('Almonds'),
    })
  }

  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const blockId = Number(selectedFieldAssets.block)
  const series: SeriesOptionsType[] = []
  const { forecastStartsAt } = data?.blocks?.[blockId]?.values?.almondBloom?.[0]?.metadata ?? {}
  const firstForecastTimestamp = forecastStartsAt ? +new Date(forecastStartsAt) : +new Date()

  const almondBloomSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.blue600,
    name: unitConverter.almondBloom().shortTitle(),
    tooltip: {
      valueDecimals: unitConverter.almondBloom(null).defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${unitConverter.almondBloom(null).suffix()}`,
    },
    data: (data?.blocks?.[blockId]?.values?.almondBloom?.[0]?.timeseries ?? []).map((d) => [
      +new Date(d.timestamp),
      unitConverter.almondBloom(d.value).value(),
    ]),
    type: 'line',
    id: 'almondBLoom',
  }

  series.push(almondBloomSeries)

  if (compareSeasonsInterval) {
    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(almondBloomSeries, {
        data: (compareSeasonsData?.blocks?.[blockId]?.values?.almondBloom?.[0]?.timeseries ?? []).flatMap(
          (d) => {
            const timestamp = +new Date(d.timestamp)
            const value = unitConverter.almondBloom(d.value).value()

            return [[+timestamp, value]]
          },
        ),
      }),
    )
  }

  const isDaily = true

  return {
    ...commonReturnItems,
    title: unitConverter.almondBloom().categoryTitleWithoutUnit(),
    items: [
      {
        chartConfig: {
          tooltip: {
            xDateFormat: getXDateFormat(isDaily),
          },
          semiosHighchartsAdditions: {
            id: 'FruitGrowth',
            firstForecastTimestamp,
          },
          chart: {
            type: 'line',
          },
          series,
        },
      },
    ],
  }
}
