import { TSearchResultsItem } from 'App/Map/MapSearch/types'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'

const storeName = 'mapSearchStore'

export const mapSearchStore = persistentInit<{
  highlightedPropertyIds: Record<TFieldAssetKeyTypes.TPropertyId, true>
  previousSearches: TSearchResultsItem[]
}>({
  initialState: {
    highlightedPropertyIds: {},
    previousSearches: [],
  },
  keysToPutInPersistentStorage: {
    highlightedPropertyIds: true,
    previousSearches: true,
  },
  keysToPutInURL: {
    highlightedPropertyIds: false,
    previousSearches: false,
  },
  storeName,
})
