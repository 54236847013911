import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import {
  MAP_VISUAL,
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentHeatmapPointsValueTypes,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
} from 'stores/mapControlsStore/types'
import { unitConverter } from 'utils/unitConverter/unitConverter'

const updateMapVisuals = (
  newValueType: TValuesCurrentPointsValueTypes & TValuesCurrentHeatmapPointsValueTypes,
) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.POINT]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.POINT],
        chill: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.POINT].chill ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.PROPERTY]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.PROPERTY],
        chill: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.PROPERTY].chill ?? { mItem: 'M_Property' }),
          valueType: newValueType.replace('Sum_', 'SumMinMedianMax_') as TValuesCurrentPropertiesValueTypes,
        },
      },
      [MAP_VISUAL.SCD]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.SCD],
        chill: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.SCD].chill ?? { mItem: 'M_SCD' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.BLOCK]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.BLOCK],
        chill: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.BLOCK].chill ?? { mItem: 'M_SCD' }),
          valueType: newValueType.replace('Sum_', 'SumMinMedianMax_') as TValuesCurrentBlocksValueTypes,
        },
      },
    },
  }))
}

export const ChillSecondaryDropdown = () => {
  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.POINT]?.chill?.valueType,
  )

  const properties = fieldAssetStore.useSelector((s) => s.properties)
  const hasSouthernHemisphereProperties = Object.values(properties ?? {}).some((p) => p.isSouthernHemisphere)
  const hasNorthernHemisphereProperties = Object.values(properties ?? {}).some((p) => !p.isSouthernHemisphere)

  const data: { value: TValuesCurrentPointsValueTypes; label: string; group: string }[] = useMemo(() => {
    const returner: { value: TValuesCurrentPointsValueTypes; label: string; group: string }[] = []

    if (hasSouthernHemisphereProperties) {
      returner.push({
        value: 'chillHoursLessThan45_sinceMar1Sum_hours',
        label: unitConverter.chillCumulativeHoursBelow45FMAR().titleWithoutUnit(),
        group: translate.dates.monthLabel('MAR'),
      })

      returner.push({
        value: 'chillHoursBetween32And45_sinceMar1Sum_hours',
        label: unitConverter.chillCumulativeHoursBetween32and45FMAR().titleWithoutUnit(),
        group: translate.dates.monthLabel('MAR'),
      })

      returner.push({
        value: 'chillPortions_sinceMar1Sum_portions',
        label: unitConverter.chillCumulativePortionsMAR().titleWithoutUnit(),
        group: translate.dates.monthLabel('MAR'),
      })

      returner.push({
        value: 'chillHoursLessThan45_sinceMay1Sum_hours',
        label: unitConverter.chillCumulativeHoursBelow45FMAY().titleWithoutUnit(),
        group: translate.dates.monthLabel('MAY'),
      })

      returner.push({
        value: 'chillHoursBetween32And45_sinceMay1Sum_hours',
        label: unitConverter.chillCumulativeHoursBetween32and45FMAY().titleWithoutUnit(),
        group: translate.dates.monthLabel('MAY'),
      })

      returner.push({
        value: 'chillPortions_sinceMay1Sum_portions',
        label: unitConverter.chillCumulativePortionsMAY().titleWithoutUnit(),
        group: translate.dates.monthLabel('MAY'),
      })
    }

    if (hasNorthernHemisphereProperties) {
      returner.push({
        value: 'chillHoursLessThan45_sinceSep1Sum_hours',
        label: unitConverter.chillCumulativeHoursBelow45FSEP().titleWithoutUnit(),
        group: translate.dates.monthLabel('SEP'),
      })

      returner.push({
        value: 'chillHoursBetween32And45_sinceSep1Sum_hours',
        label: unitConverter.chillCumulativeHoursBetween32and45FSEP().titleWithoutUnit(),
        group: translate.dates.monthLabel('SEP'),
      })

      returner.push({
        value: 'chillPortions_sinceSep1Sum_portions',
        label: unitConverter.chillCumulativePortionsSEP().titleWithoutUnit(),
        group: translate.dates.monthLabel('SEP'),
      })

      returner.push({
        value: 'chillHoursLessThan45_sinceNov1Sum_hours',
        label: unitConverter.chillCumulativeHoursBelow45FNOV().titleWithoutUnit(),
        group: translate.dates.monthLabel('NOV'),
      })

      returner.push({
        value: 'chillHoursBetween32And45_sinceNov1Sum_hours',
        label: unitConverter.chillCumulativeHoursBetween32and45FNOV().titleWithoutUnit(),
        group: translate.dates.monthLabel('NOV'),
      })

      returner.push({
        value: 'chillPortions_sinceNov1Sum_portions',
        label: unitConverter.chillCumulativePortionsNOV().titleWithoutUnit(),
        group: translate.dates.monthLabel('NOV'),
      })
    }

    return returner
  }, [hasNorthernHemisphereProperties, hasSouthernHemisphereProperties, value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
