import { routes } from '@semios/app-platform-banyan-route-definitions'
import { Text } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { FC, useState } from 'react'
import { apiFetch } from 'utils/apiFetch'
import { setTokenAndPushHome } from 'utils/setTokenAndPushHome'
import { z } from 'zod'
import { AuthenticationForm, InputPropsType } from './AuthenticationForm'
import { useToast } from './AuthenticationForm/Toast/useToast'
import { appMetaDataMaker } from './_utils/appMetaMaker'

const getValidationSchema = () =>
  z
    .object({
      newPassword: z.string().min(6, {
        message: translate.phrases.validation('{{label}} must be at least {{count}} characters', {
          label: translate.phrases.banyanApp('Password'),
          count: 6,
        }),
      }),
      confirmNewPassword: z.string(),
    })
    .refine((data) => data.newPassword === data.confirmNewPassword, {
      message: translate.phrases.banyanApp('Passwords did not match'),
      path: ['confirmNewPassword'], // path of error
    })

export const ResetPassword: FC = () => {
  const path = window.location.pathname
  const forgotCode = path.match(/\/reset\/(.*)/)?.[1] ?? ''
  const [loading, setLoading] = useState<boolean>(false)

  const { showToast: showErrorToast, Toast: ErrorToast } = useToast({
    durationMillisecond: 3000,
    type: 'error',
  })

  const { showToast: showSuccessToast, Toast: SuccessToast } = useToast({
    durationMillisecond: 3000,
    type: 'success',
  })

  const inputProps: InputPropsType[] = [
    {
      placeholder: translate.phrases.banyanApp('New Password'),
      key: 'newPassword',
      initialValue: '',
      isPasswordInput: true,
    },
    {
      placeholder: translate.phrases.banyanApp('Confirm New Password'),
      key: 'confirmNewPassword',
      initialValue: '',
      isPasswordInput: true,
    },
  ]

  const handleSubmit = async (values: { [k: string]: string }) => {
    setLoading(true)

    try {
      const response = await apiFetch<routes.ResetPassword.Request, routes.ResetPassword.Response>({
        url: routes.ResetPassword.path,
        body: {
          forgotCode,
          password: values.newPassword,
          appMeta: appMetaDataMaker(),
        },
      })

      showSuccessToast(translate.phrases.banyanApp('Successfully set your new password'))

      const token = response['x-token']

      if (token) setTokenAndPushHome(token)
    } catch (error) {
      showErrorToast(translate.phrases.banyanApp('Failed to set new password'))
    }

    setLoading(false)
  }

  return (
    <>
      <div css={{ marginTop: 20 }}>{SuccessToast}</div>

      <AuthenticationForm
        header={
          <>
            {ErrorToast}
            <Text size={24} weight={300}>
              {translate.phrases.banyanApp('Reset Password')}
            </Text>
            <Text size={14} weight={400}>
              {translate.phrases.validation('{{label}} must be at least {{count}} characters', {
                label: translate.phrases.banyanApp('Password'),
                count: 6,
              })}
            </Text>
          </>
        }
        inputProps={inputProps}
        validationSchema={getValidationSchema()}
        buttonText={translate.phrases.banyanApp('Reset Password')}
        onSubmit={handleSubmit}
        submitLoading={loading}
      />
    </>
  )
}
