import { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorBlock } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorBlock/DropdownSelectorBlock'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { GridTableContentSection, GridTableContentSectionItem } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { BadgeCell } from '../../../BadgeCell/BadgeCell'
import { blockTimestampToValuesDictionaryMaker, defaultValuesRowHeight } from '../_utils'
import { getWalnutBlightStatus } from './getWalnutBlightStatus'
import { EAggregationInterval } from 'App/Map/types'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_WALNUT_BLIGHT_DETAILS' })

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.walnut_blight || !selectedFieldAssets.block) return {}

  const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

  const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
    walnutBlightRPV: preferredAggregationInterval,
    walnutBlightRPVCumulativeRolling7Days: preferredAggregationInterval,
  }

  if (!doesSelectedBlockHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })) return {}

  return {
    blocks: {
      blockIds: [selectedFieldAssets.block],
      valuesRequested,
    },
  }
}

const rpvValueType = 'walnutBlightRPV'
const rpv7DaysValueType = 'walnutBlightRPVCumulativeRolling7Days'

const generateItem = ({
  id,
  values,
  valueType,
  spayThreshold,
}: {
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any
  valueType: keyof typeof unitConverter
  spayThreshold?: number
}): GridTableContentSectionItem => ({
  id,
  label: unitConverter[valueType]().shortTitle(),
  height: defaultValuesRowHeight,
  labelCellCSS: { zIndex: 1 },
  labelMinWidth: 160,
  valueMinWidth: 120,
  valueCellCSS: {
    '& > *': {
      height: 'max-content',
    },
  },
  render: (dataPoint: { timestamp: string; value: number | null } | undefined) => {
    if (dataPoint === undefined || dataPoint === null || dataPoint.value === null)
      return translate.phrases.templates('-')

    const valueToUse = unitConverter[valueType](dataPoint.value).value()

    if (valueToUse === null || valueToUse === undefined) return translate.phrases.templates('-')

    if (valueType === rpvValueType) return valueToUse

    if (!spayThreshold) return translate.phrases.templates('-')

    const backgroundColor = getWalnutBlightStatus(valueToUse, spayThreshold)

    return (
      <BadgeCell backgroundColor={backgroundColor}>
        {unitConverter[valueType](dataPoint.value).value()}
      </BadgeCell>
    )
  },
  values,
})

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.walnutBlightRPV().categoryTitleWithoutUnit(),
    titleChildren: <DropdownSelectorBlock />,
    id: 'summary-grid-walnut-blight',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const blockId = String(selectedFieldAssetsStore.getState().block)

  const spayThreshold = data?.blocks?.[blockId]?.values?.[rpv7DaysValueType]?.[0].metadata
    .blockRPVSprayThreshold as number

  const items = [
    generateItem({
      id: 'walnut-blight-cumulative-dsv',
      valueType: rpv7DaysValueType,
      values: blockTimestampToValuesDictionaryMaker(data, 'walnutBlightRPVCumulativeRolling7Days', blockId),
      spayThreshold,
    }),
  ].filter(Boolean) as GridTableContentSectionItem[]

  return {
    ...commonReturnItems,
    items,
  }
}
