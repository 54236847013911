import { TAvailablePreset } from 'App/Map/types'
import { useMemo } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import { sortByKey } from 'utils/sortByKey'
import { ItemRow } from './ItemRow/ItemRow'

export const SemiosDefinedPresets = ({
  duplicatePreset,
}: {
  duplicatePreset: (preset: TAvailablePreset) => void
}) => {
  const availableSemiosDefinedPresets = userDetailsStore.useSelector((s) => s.availableSemiosDefinedPresets)

  const presetsToMap = useMemo(() => {
    return Object.values(availableSemiosDefinedPresets ?? {}).sort(sortByKey('name'))
  }, [availableSemiosDefinedPresets])

  return (
    <>
      {presetsToMap.map((preset) => (
        <ItemRow key={preset.idHash} duplicatePreset={duplicatePreset} preset={preset} />
      ))}
    </>
  )
}
