import { PreUploadImageMeta } from './serviceCenterImagePreUpload'
import { readPhoto } from 'utils/readPhoto'
import { ApiRequestQueue, MetaWithImageUrls, MetaWithNodeIdentifier } from './queue'

export type UploadImageMeta = PreUploadImageMeta & {
  uploadUrl: string
  imageUrl: string
  linkedRequestId: string
} & MetaWithNodeIdentifier

export type UploadImageResult = void

const request = async (meta: UploadImageMeta): Promise<UploadImageResult> => {
  const { content } = await readPhoto(meta)

  // Check if the pre-signed url has been requested before the upload to S3
  if (!meta.uploadUrl) throw new Error('Missing uploadUrl for image upload')

  await fetch(meta.uploadUrl, { body: content, method: 'PUT' })
}

const callback = (_: void, meta: UploadImageMeta) => {
  if (meta.linkedRequestId) {
    // Look for an incomplete request linked to this image upload
    const linkedRequest = ApiRequestQueue.getRequest(meta.linkedRequestId)

    // Add uploaded image url to linked request meta
    if (linkedRequest) {
      ApiRequestQueue.updateRequestMeta(linkedRequest.id, {
        imageUrls: [...((linkedRequest.meta as MetaWithImageUrls).imageUrls || []), meta.imageUrl],
      })
    }
  }
}

export const serviceCenterImageUpload = {
  request,
  callback,
}
