import { useState, useEffect } from 'react'

/**
 * Custom hook that tracks the scroll position of a given element.
 *
 * @param elementRef - Reference to the element whose scroll position needs to be tracked.
 * @returns The current scroll position as an object with `x` and `y` properties.
 */
export const useScrollPosition = (elementRef: React.RefObject<HTMLElement>) => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current) {
        const { scrollLeft, scrollTop } = elementRef.current

        setScrollPosition({ x: scrollLeft, y: scrollTop })
      }
    }

    if (elementRef.current) {
      elementRef.current.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (elementRef.current) {
        elementRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [elementRef])

  return scrollPosition
}
