import { MapContext } from 'App/Map/MapContext/MapContext'
import { PREVENT } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import React, { useContext, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

interface OverlayViewProps {
  position: google.maps.LatLng | google.maps.LatLngLiteral
  children: React.ReactNode
  containerStyle?: React.CSSProperties
  layer?: keyof google.maps.MapPanes
  prevent?: PREVENT
}

export const OverlayView: React.FC<OverlayViewProps> = ({
  children,
  position,
  containerStyle,
  layer = 'overlayLayer',
  prevent,
}) => {
  const { map } = useContext(MapContext)
  // Overlay container (managed by Google Maps)
  const containerRef = useRef<HTMLDivElement>(document.createElement('div'))
  // OverlayView instance (managed by Google Maps)
  const overlayViewRef = useRef<google.maps.OverlayView>(new google.maps.OverlayView())
  // LatLng copy (used by OverlayView.draw() to update the overlay position)
  const positionRef = useRef<google.maps.LatLng | google.maps.LatLngLiteral>(position)

  useEffect(() => {
    if (!map) return

    overlayViewRef.current.setMap(map)

    overlayViewRef.current.onAdd = () => {
      containerRef.current.style.position = 'absolute'

      if (prevent === 'MAP_HITS_AND_GESTURES') {
        google.maps.OverlayView.preventMapHitsAndGesturesFrom(containerRef.current)
      } else if (prevent === 'MAP_HITS') {
        google.maps.OverlayView.preventMapHitsFrom(containerRef.current)
      }

      if (containerStyle) {
        Object.keys(containerStyle).forEach((styleProperty) => {
          // @ts-ignore
          containerRef.current.style[styleProperty] = containerStyle[styleProperty]
        })
      }

      createPortal(children, containerRef.current)

      overlayViewRef.current.getPanes()?.[layer].appendChild(containerRef.current)
    }

    overlayViewRef.current.draw = () => {
      const divPixel = overlayViewRef.current.getProjection()?.fromLatLngToDivPixel(positionRef.current)

      // console.log('draw', positionRef.current)
      if (divPixel) {
        containerRef.current.style.left = `${divPixel.x}px`

        containerRef.current.style.top = `${divPixel.y}px`
      }
    }

    overlayViewRef.current.onRemove = () => {
      containerRef.current.parentNode?.removeChild(containerRef.current)
    }

    return function cleanup() {
      overlayViewRef.current.setMap(null)
    }
  }, [map])

  useEffect(() => {
    // Redraw overlay when position changes
    positionRef.current = position

    overlayViewRef.current.draw()
  }, [position])

  // Use Portal to render toolbar content which lives inside Mapbox GL container DOM element
  return createPortal(children, containerRef.current)
}
