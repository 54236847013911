import React, { useEffect, useState } from 'react'
import { TNodeServiceIssueSummary, TServiceIssueResolutionAction } from '../../types'
import { LoadingOverlay, useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { serviceCenterServiceIssueResolutionActionsGet } from '../../utils/api/serviceCenterServiceIssueResolutionActionsGet'
import * as Sentry from '@sentry/react'
import { showNotification } from 'utils/showNotification'
import { Button } from 'components/Button/Button'
import { useIntercom } from 'react-use-intercom'
import { getIntercomPrePopulatedMessage } from 'utils/getIntercomPrePopulatedMessage'

interface ServiceIssueResolutionProps {
  serviceIssue: TNodeServiceIssueSummary
}

export const ServiceIssueResolution: React.FC<ServiceIssueResolutionProps> = ({ serviceIssue }) => {
  const theme = useMantineTheme()
  const [resolutionActions, setResolutionActions] = useState<TServiceIssueResolutionAction[]>([])
  const [loading, setLoading] = useState(false)
  const intercom = useIntercom()
  const prePopulatedMessage = getIntercomPrePopulatedMessage({ serviceIssue: serviceIssue })

  useEffect(() => {
    const fetchResolutionActions = async () => {
      setLoading(true)

      try {
        const resolutionActions = await serviceCenterServiceIssueResolutionActionsGet(
          serviceIssue.nodeServiceIssueTypeId,
        )

        setResolutionActions(resolutionActions)
      } catch (error) {
        Sentry.captureException(error)

        showNotification({
          type: 'error',
          message: translate.phrases.placeholder('Error: could not load resolution actions!'),
        })
      } finally {
        setLoading(false)
      }
    }

    fetchResolutionActions()
  }, [serviceIssue])

  const handleShowPrePopulatedMessage = () => {
    if (prePopulatedMessage) {
      intercom.showNewMessage(prePopulatedMessage)
    }
  }

  return (
    <div
      css={{
        padding: '20px 10px',
      }}
    >
      <LoadingOverlay visible={loading} />

      <h4 css={{ margin: '0 0 20px' }}>{translate.phrases.placeholder('Suggested Actions')}</h4>

      {resolutionActions.map((resolutionAction) => (
        <div
          key={resolutionAction.issueResolutionActionId}
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: 10,
            border: 'none',
            borderBottom: `1px solid ${theme.colors.grey[1]}`,
            cursor: 'default',
            backgroundColor: 'transparent',
            textAlign: 'left',
          }}
        >
          <div>
            <div css={{ fontSize: '14px', fontWeight: 600 }}>{resolutionAction.resolutionTitle}</div>

            {resolutionAction.resolutionDescription && (
              <div css={{ fontSize: 12, marginTop: 10, color: theme.colors.grey[2] }}>
                {resolutionAction.resolutionDescription}
              </div>
            )}
          </div>
        </div>
      ))}

      <Button
        variant="link"
        css={{
          marginTop: 20,
          padding: 0,
        }}
        onClick={() => handleShowPrePopulatedMessage()}
      >
        {translate.phrases.placeholder('Contact Support')}
      </Button>
    </div>
  )
}
