import { MantineTheme } from '@mantine/core'
import { ButtonProps } from '../../Button'
import { STATE } from './useAsyncButtonProps'
import { Interpolation, Theme } from '@emotion/react'

export const useCssOverrides = (o: {
  variant: ButtonProps['variant']
  state: STATE
  theme: MantineTheme
}): Interpolation<Theme> => {
  const theme = o.theme

  switch (o.variant) {
    case 'primary':
      return {
        '&[disabled],&[data-loading="true"]': {
          color: ['done', 'error'].includes(o.state) ? theme.colors.midnight[0] : theme.colors.white[0],
        },
      }

    case 'secondary':
      return {
        '&[disabled],&[data-loading="true"]': {
          color: ['done', 'error'].includes(o.state) ? theme.colors.midnight[0] : theme.colors.grey[2],
        },
      }

    case 'tertiary':
      return {
        '&[disabled],&[data-loading="true"]': {
          color: ['done', 'error'].includes(o.state) ? theme.colors.midnight[0] : theme.colors.grey[2],
        },
      }

    case 'negative':
      return {
        '&[disabled],&[data-loading="true"]': {
          color: ['done', 'error'].includes(o.state) ? theme.colors.midnight[0] : theme.colors.grey[2],
        },
      }

    case 'link':
      return {
        '&[disabled],&[data-loading="true"],&[data-loading="true"]::before': {
          color: ['done', 'error'].includes(o.state) ? theme.colors.midnight[0] : theme.colors.grey[2],
        },
      }

    case 'link-negative':
      return {
        '&[disabled],&[data-loading="true"],&[data-loading="true"]::before': {
          color: ['done', 'error'].includes(o.state) ? theme.colors.midnight[0] : theme.colors.grey[2],
        },
      }

    default:
      return {
        '&[disabled],&[data-loading="true"]': {
          color: ['done', 'error'].includes(o.state) ? theme.colors.midnight[0] : theme.colors.white[0],
        },
      }
  }
}
