import { routes } from '@semios/app-platform-banyan-route-definitions'
import { detailsPanelStore } from 'stores/detailsPanelStore'

const SOFT_AWC_MIN = -10
const HARD_AWC_MIN = -50
const SOFT_AWC_MAX = 150
const HARD_AWC_MAX = 175

const getMinAndMaxForTimeseriesAcrossAllDepths = (
  soilMoistureValueType: NonNullable<routes.Values.Response['points']>[string]['values']['soilMoisture'],
) => {
  return (soilMoistureValueType ?? []).reduce(
    (a: { min: number; max: number }, moistureForDepth) => {
      const mappedValues = moistureForDepth.timeseries.flatMap((ts) => {
        if (!ts.value && ts.value !== 0) return []

        if (typeof ts.value === 'number') return ts.value

        return ts.value.average as number
      })

      return {
        min: Math.min(a.min, ...mappedValues),
        max: Math.max(a.max, ...mappedValues),
      }
    },
    { min: SOFT_AWC_MIN, max: SOFT_AWC_MAX },
  )
}

export const getAwcChartMinMax = ({
  compareSeasonsData,
  data,
  soilStationLngLat,
}: {
  compareSeasonsData: routes.Values.Response
  data: routes.Values.Response
  soilStationLngLat: string | null
}) => {
  const { compareSeasonsInterval } = detailsPanelStore.getState()

  const evaluatedData = getMinAndMaxForTimeseriesAcrossAllDepths(
    data?.points?.[String(soilStationLngLat)]?.values?.soilMoisture ?? [],
  )

  if (!compareSeasonsInterval) {
    return {
      yAxisMaxToUse: Math.min(Math.max(evaluatedData.max, SOFT_AWC_MAX), HARD_AWC_MAX),
      yAxisMinToUse: Math.max(Math.min(evaluatedData.min, SOFT_AWC_MIN), HARD_AWC_MIN),
    }
  }

  const evaluatedCompareSeasonsData = getMinAndMaxForTimeseriesAcrossAllDepths(
    compareSeasonsData?.points?.[String(soilStationLngLat)]?.values?.soilMoisture ?? [],
  )

  return {
    yAxisMaxToUse: Math.min(
      Math.max(evaluatedData.max, evaluatedCompareSeasonsData.max, SOFT_AWC_MAX),
      HARD_AWC_MAX,
    ),
    yAxisMinToUse: Math.max(
      Math.min(evaluatedData.min, evaluatedCompareSeasonsData.min, SOFT_AWC_MIN),
      HARD_AWC_MIN,
    ),
  }
}
