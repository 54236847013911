// Transform RSSI (-XX dBm) to a signal level (1-5)
export function getRSSILevel(rssi: number): number {
  if (rssi > 0) throw new Error('RSSI must be negative')

  if (rssi > -60) {
    // Great signal
    return 5
  } else if (rssi > -70) {
    // Good signal
    return 4
  } else if (rssi > -80) {
    // Okay signal
    return 3
  } else if (rssi > -90) {
    // Poor signal
    return 2
  } else if (rssi === -255) {
    // No signal/unknown
    return 0
  } else {
    // Bad signal
    return 1
  }
}

export const SIGNAL_STRENGTH_COLORS: { [key: number]: string } = {
  1: '#EB4C4C',
  2: '#F5A623',
  3: '#F5A623',
  4: '#29CD6B',
  5: '#29CD6B',
}
