import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { TUnitConverterOptionsWithUnits, TUnitConverterTypicalReturn } from '../types'

type TAlmondBloomUnits = '%'

export const almondBloom: Record<
  'almondBloom',
  (
    almondBloomAmount?: number | null,
    options?: TUnitConverterOptionsWithUnits<TAlmondBloomUnits>,
  ) => TUnitConverterTypicalReturn
> = {
  almondBloom: (
    almondBloom: number | null = null,
    {
      decimalPlaces,
    }: {
      decimalPlaces?: number
    } = {},
  ) => {
    const defaultNumberOfDecimalPlaces = 0

    let convertedValue: number | null

    if (isNil(almondBloom)) {
      convertedValue = null
    } else {
      convertedValue = Number(almondBloom)
    }

    const decimalPlacesToUse = decimalPlaces ?? defaultNumberOfDecimalPlaces
    const label = translate.phrases.banyanApp('Almond Bloom')
    const unitSymbol = translate.measurements.percentage.unit()
    const labelAndUnit = translate.phrases.templates('{{label}} ({{unitSymbol}})', { label, unitSymbol })

    return {
      categoryTitle: () => labelAndUnit,
      categoryTitleWithoutUnit: () =>
        translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: label,
          labelB: translate.phrases.banyanApp('Nonpareil'),
        }),
      defaultNumberOfDecimalPlaces: () => defaultNumberOfDecimalPlaces,
      suffix: () => unitSymbol,
      shortTitle: () => label,
      title: () => labelAndUnit,
      titleWithoutUnit: () => label,
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlacesToUse)
      },
      valueAsString: () => translate.measurements.percentage.value(convertedValue, decimalPlacesToUse),
      valueWithSuffix: () =>
        translate.measurements.percentage.valueWithUnit(convertedValue, decimalPlacesToUse),
      valueWithNoRounding: () => convertedValue,
    }
  },
}
