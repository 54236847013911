import { css } from '@emotion/css'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { translate } from 'i18n/i18n'
import { detailsPanelStore, TPrecipitationAggregation } from 'stores/detailsPanelStore'
import { EAggregationInterval } from 'App/Map/types'
import {
  DropdownSelectorPoint,
  TDropdownSelectorPointProps,
} from '../DropdownSelectorPoint/DropdownSelectorPoint'
import type { TPointCategory } from 'stores/selectedFieldAssetsStore'

const pointCategory: TPointCategory = 'outOfBlockPoint'

export const PrecipitationTitleChildren = (
  props: TDropdownSelectorPointProps & { showAggregationToggle: boolean },
) => {
  const { showAggregationToggle, ...rest } = props
  const precipitationAggregation = detailsPanelStore.useSelector((s) => s?.precipitationAggregation)

  // we can define it here in case the type changes for TPrecipitationAggregation
  const dropDownData: { label: React.ReactNode; value: TPrecipitationAggregation }[] = [
    { label: translate.phrases.banyanApp('Hourly'), value: EAggregationInterval.HOURLY },
    { label: translate.phrases.banyanApp('Daily'), value: EAggregationInterval.DAILY },
  ]

  return (
    <>
      <div css={{ marginRight: 12 }}>
        <DropdownSelectorPoint pointCategory={pointCategory} {...rest} />
      </div>
      {!!showAggregationToggle && (
        <ToggleButton
          buttonProps={{ size: 'xs', className: css({ fontSize: 13 }) }}
          data={dropDownData}
          onChange={(newValue) =>
            detailsPanelStore.setState((s) => ({ ...s, precipitationAggregation: newValue }))
          }
          value={precipitationAggregation}
        />
      )}
    </>
  )
}
