import { routes } from '@semios/app-platform-banyan-route-definitions'
import { StackedChartPestSection } from 'components/StackedChart/types'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import * as insectTrapCatchesPropertyLevel from './by-scope/insectTrapCatchesPropertyLevel'
import * as insectTrapCatchesBlockLevel from './by-scope/insectTrapCatchesBlockLevel'
import { mergeApiArgs } from 'App/Map/PanelDetails/_utils/mergeApiArgs'

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const propertyLevelValues = insectTrapCatchesPropertyLevel.apiArgs({
    selectedValueGroups,
    selectedFieldAssets,
  })

  const blockLevelValues = insectTrapCatchesBlockLevel.apiArgs({ selectedValueGroups, selectedFieldAssets })

  return mergeApiArgs(propertyLevelValues, blockLevelValues)
}

export const content = ({
  data,
  selectedValueGroups,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}): StackedChartPestSection[] => {
  const propertyLevelContent = insectTrapCatchesPropertyLevel.content({
    data,
    selectedValueGroups,
    compareSeasonsData,
  })

  const blockLevelContent = insectTrapCatchesBlockLevel.content({
    data,
    selectedValueGroups,
    compareSeasonsData,
  })

  return [...propertyLevelContent, ...blockLevelContent]
}
