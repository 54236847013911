import '@fortawesome/fontawesome-svg-core/styles.css'
import { Select } from '@mantine/core'
import { TPresetId } from 'App/Map/types'
import { IconStar } from 'components/icons/IconStar'
import { IconAndTitle } from 'components/SideNav/IconAndTitle/IconAndTitle'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { layersNavigationMenuStore } from 'stores/layersNavigationMenuStore'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { blurOnEnterKeyUp } from 'utils/blurOnEnterKeyUp'
import { setSelectedPreset } from 'utils/setSelectedPreset/setSelectedPreset'
import { sortByKey } from 'utils/sortByKey'
import { useScreenSize } from 'utils/useScreenSize'
import { PresetDropdownContainer } from './PresetDropdownContainer/PresetDropdownContainer'
import './_utils/storeSubscriptions'

export const PresetLayers = () => {
  const selectedPreset = smallStore.useSelector((s) => s.selectedPreset)

  const allPresetsObject = userDetailsStore.useSelector((s) => ({
    ...s.availableSemiosDefinedPresets,
    ...s.availableUserDefinedPresets,
  }))

  const { isWideScreen } = useScreenSize()

  const selectData = useMemo(() => {
    return Object.values(allPresetsObject)
      .sort(sortByKey('name'))
      .flatMap((preset) => (preset.ignored ? [] : { label: preset.name, value: preset.idHash }))
  }, [allPresetsObject])

  // we can hide the presets section when none are present (e.g. all are ignored)
  if (!selectData.length) return null

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <IconAndTitle icon={<IconStar />} title={translate.phrases.banyanApp('Views')} />
      {/* TODO: replace this with MultiSelect when it's more complete */}
      <Select
        dropdownComponent={PresetDropdownContainer}
        css={{ margin: '0px 0px 10px 38px' }}
        data={selectData}
        dropdownPosition="bottom"
        onChange={(idHash: TPresetId) => {
          // Close layer menu on mobile view
          !isWideScreen && layersNavigationMenuStore.setState((s) => ({ ...s, menuCollapsed: true }))

          setSelectedPreset(idHash)
        }}
        placeholder={translate.phrases.banyanApp('Select a view')}
        size="sm"
        spellCheck="false"
        styles={{
          ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER,
          dropdown: { width: 220, marginTop: -7 },
        }}
        value={selectedPreset}
        onKeyUp={blurOnEnterKeyUp}
      />
    </div>
  )
}
