import { Link } from '../_variants/Link/Link'
import { LinkNegative } from '../_variants/LinkNegative/LinkNegative'
import { Negative } from '../_variants/Negative/Negative'
import { Primary } from '../_variants/Primary/Primary'
import { Secondary } from '../_variants/Secondary/Secondary'
import { Tertiary } from '../_variants/Tertiary/Tertiary'
import { List } from '../_variants/List/List'
import { Positive } from '../_variants/Positive/Positive'
import { ButtonProps } from '../Button'

export const getButtonComponent = (variant: ButtonProps['variant']) => {
  switch (variant) {
    case 'primary':
      return Primary

    case 'secondary':
      return Secondary

    case 'tertiary':
      return Tertiary

    case 'positive':
      return Positive

    case 'negative':
      return Negative

    case 'link':
      return Link

    case 'link-negative':
      return LinkNegative

    case 'list':
      return List

    default:
      return Primary
  }
}
