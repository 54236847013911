import CssType from 'csstype'
import { MOVE_POINT_STATE } from '../useMoveTracker'
import { useEffect } from 'react'

const createCursorMaskDiv = (cursor: CssType.Property.Cursor) => {
  const div = document.createElement('div')

  div.style.position = 'fixed'

  div.style.top = '0'

  div.style.right = '0'

  div.style.bottom = '0'

  div.style.left = '0'

  div.style.cursor = cursor

  // div.style.backgroundColor = 'rgba(255,55,55,0.58)' // TODO: just for debugging

  return div
}

export const useCursorMaskDivIfNecessary = (
  pointerType: MOVE_POINT_STATE['pointerType'],
  tracking: MOVE_POINT_STATE['tracking'],
  cursorX1: CssType.Property.Cursor,
  cursorX2: CssType.Property.Cursor,
  cursorY1: CssType.Property.Cursor,
  cursorY2: CssType.Property.Cursor,
) => {
  useEffect(() => {
    let cursorMaskDiv: HTMLDivElement | null = null

    if (pointerType === 'mouse' && tracking) {
      if (tracking === 'X1') cursorMaskDiv = createCursorMaskDiv(cursorX1)
      else if (tracking === 'X2') cursorMaskDiv = createCursorMaskDiv(cursorX2)
      else if (tracking === 'Y1') cursorMaskDiv = createCursorMaskDiv(cursorY1)
      else if (tracking === 'Y2') cursorMaskDiv = createCursorMaskDiv(cursorY2)

      if (cursorMaskDiv) document.body.append(cursorMaskDiv)
    }

    return () => {
      if (cursorMaskDiv) cursorMaskDiv.remove()
    }
  }, [pointerType, tracking, cursorX1, cursorX2, cursorY1, cursorY2])
}
