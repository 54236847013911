/* eslint-disable react/prop-types */
import { Geolocation } from '@capacitor/geolocation'
import {
  makeReactComponentOverlayView,
  ReactComponentOverlayView,
} from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import { translate } from 'i18n/i18n'
import { useEffect, useState } from 'react'
import { layersNavigationMenuStore } from 'stores/layersNavigationMenuStore'
import { useScreenOrientation } from 'stores/smallStore'
import { showNotification } from 'utils/showNotification'
import { usePreventPanelDetailsFromBeingOpen } from 'utils/usePreventPanelDetailsFromBeingOpen'
import { useScreenSize } from 'utils/useScreenSize'
import { CurrentLocationMarker } from './CurrentLocationMarker'
import { ControlButton } from './ControlButton'
import { Interpolation, Theme } from '@emotion/react'

type CurrentLocationProps = {
  latLng?: google.maps.LatLngLiteral
  map?: google.maps.Map
  style?: Interpolation<Theme>
}

type OverlayProps = {
  latLng?: google.maps.LatLngLiteral
  map?: google.maps.Map
  maps?: typeof google.maps
}

export type StatusType = 'inactive' | 'watching' | 'following'

const options = { maximumAge: 16000, timeout: 8000, enableHighAccuracy: false }

export const CurrentLocation: React.FC<CurrentLocationProps> = ({ map, style }) => {
  const [watcherID, setWatcherID] = useState('')
  const [dragendListenerID, setDragendListenerID] = useState<google.maps.MapsEventListener>()
  const [zoomListenerID, setZoomListenerID] = useState<google.maps.MapsEventListener>()
  const [status, setStatus] = useState<StatusType>('inactive')
  const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral | null>(null)
  const menuCollapsed = layersNavigationMenuStore.useSelector((s) => s.menuCollapsed)
  const maps = window.google?.maps
  const { isWideScreen } = useScreenSize()
  const screenOrientation = useScreenOrientation()
  const preventPanelDetailsFromBeingOpen = usePreventPanelDetailsFromBeingOpen()

  let currentLocationOverlay: ReactComponentOverlayView<OverlayProps> | null = null

  const startPositionWatcher = async ({ skipPermissions }: { skipPermissions?: boolean } = {}) => {
    if (!skipPermissions) {
      await Geolocation.checkPermissions().then(({ location }) => {
        if (location === 'prompt' && window.Capacitor.getPlatform() !== 'web') {
          Geolocation.requestPermissions()
        } else if (location === 'denied') {
          showNotification({
            id: 'GEO_LOCATION_PERMISSION_DENIED_NOTIFICATION', // [APPS-7641] Gave this a static id as a quick fix to prevent the notification being shown multiple times
            type: 'error',
            message: translate.phrases.banyanApp(
              'Please enable location permissions in your device settings.',
            ),
          })

          setStatus('inactive')
        }
      })
    }

    Geolocation.watchPosition(options, (position) => {
      if (!position) {
        setStatus('inactive')

        return
      }

      const latLng = { lat: position.coords.latitude, lng: position.coords.longitude }

      setCurrentLocation(latLng)

      if (status === 'inactive') {
        setStatusWatching()
      } else if (status === 'following') {
        if (map) map.setCenter(latLng)
      }

      // kill the previous location component
      currentLocationOverlay?.setMap(null)

      // Make a new one...
      if (map && maps) {
        currentLocationOverlay = makeReactComponentOverlayView<OverlayProps>({
          component: CurrentLocationMarker,
          latLng: latLng,
          map: map,
          maps: maps,
        })

        // ...and render it
        currentLocationOverlay?.render({ latLng })
      }
    }).then((id) => {
      setWatcherID(id)
    })
  }

  const setStatusWatching = () => {
    setStatus('watching')

    dragendListenerID?.remove()

    zoomListenerID?.remove()
  }

  const setStatusFollowing = () => {
    setStatus('following')

    if (map) {
      setDragendListenerID(
        map.addListener('dragend', () => {
          setStatusWatching()
        }),
      )

      setZoomListenerID(
        map.addListener('zoom_changed', () => {
          setStatusWatching()
        }),
      )
    }
  }

  useEffect(() => {
    if (map && maps) {
      startPositionWatcher()
    }

    return () => {
      // stop listening for location changes and map events
      if (watcherID) Geolocation.clearWatch({ id: watcherID })

      dragendListenerID?.remove()

      zoomListenerID?.remove()
    }
  }, [map, maps])

  const toggleCurrentLocationWatch = () => {
    switch (status) {
      case 'inactive': // Start watching location, ask permissions
        startPositionWatcher()

        break

      case 'watching': // Start following
        setStatusFollowing()

        if (currentLocation) map?.setCenter(currentLocation)

        break

      case 'following': // If following, back to watching
        setStatusWatching()

        break

      default:
        break
    }
  }

  const getStyle = (): Interpolation<Theme> => {
    if (style) return style

    // return default style
    return isWideScreen
      ? {
          position: 'relative',
          left: menuCollapsed ? '10px' : '320px',
          bottom: '70px',
          margin:
            'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        }
      : {
          position: 'absolute',
          top: '80px',
          right: !preventPanelDetailsFromBeingOpen && screenOrientation === 'landscape' ? '75px' : '12px',
          margin:
            'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        }
  }

  return <ControlButton css={getStyle()} status={status} onClick={toggleCurrentLocationWatch} />
}
