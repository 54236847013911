import type { TWindMachineDetailsElement } from '@semios/app-platform-banyan-route-definitions/dist/routes/windMachine'
import { colors } from 'settings/colors'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { DeviceInfoBlock } from './DeviceInfoBlock/DeviceInfoBlock'
import { translate } from 'i18n/i18n'

export const WindMachineInfo = ({
  content,
}: {
  content: TWindMachineDetailsElement['windMachineDetails'][0]
}) => {
  const batteryData = content.equipmentStatus.batteryExternalV
  const fuelData = content.equipmentStatus.fuelLevel
  const rpmData = content.equipmentStatus.rpm
  const tempData = content.equipmentStatus.temperature

  const infoObjects = [
    {
      data: `${unitConverter.windMachineTemperature(tempData).valueWithSuffix() || 'N/A'}`,
      icon: 'temperature',
    },
    {
      data: unitConverter.windMachineRPM(rpmData).valueWithSuffix() || 'N/A',
      icon: 'rpm',
    },
    {
      data: `${
        fuelData === 'Not Connected'
          ? translate.phrases.placeholder('Not Connected')
          : (fuelData || '- ') + '%'
      }`,
      icon: 'fuel',
    },
    {
      data: unitConverter.windMachineVoltage(batteryData).valueWithSuffix(),
      icon: 'battery',
    },
  ]

  return (
    <div
      css={{
        'display': 'flex',
        'flexDirection': 'row',
        'justifyContent': 'space-between',
        'alignItems': 'center',
        'padding': '10px',
        'backgroundColor': colors.grey50,
        'borderRadius': '3px',
        'marginTop': '10px',
        'flexWrap': 'wrap',
        '@media (max-width: 600px)': {
          justifyContent: 'center',
        },
      }}
    >
      {infoObjects.map((item, index) => {
        return <DeviceInfoBlock key={index} data={item.data} icon={item.icon} />
      })}
    </div>
  )
}
