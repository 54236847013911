import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'
import { TEquipmentInstallationStep } from '../types'
import { actions } from './actions/installationWorkflow'
import { selectors } from './selectors/installationWorkflow'

export type InstallationWorkflowStore = {
  currentStepNumber: number | null
  steps: TEquipmentInstallationStep[]
  dropInstallLocation: google.maps.LatLngLiteral | 'INSTALL_COMPLETED' | null
}

const store = persistentInit<InstallationWorkflowStore>({
  initialState: {
    currentStepNumber: null,
    steps: [],
    dropInstallLocation: null,
  },
  keysToPutInPersistentStorage: {
    currentStepNumber: false,
    steps: false,
    dropInstallLocation: false,
  },
  keysToPutInURL: {
    currentStepNumber: false,
    steps: false,
    dropInstallLocation: false,
  },
  storeName: 'installationWorkflowStore',
})

export const installationWorkflowStore = {
  ...store,
  actions,
  selectors,
}
