import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput'
import { FC } from 'react'
import { colors } from 'settings/colors'
import { SettingsMenuSidePanelProps } from '../../types'
import { SettingItem } from './SettingItem'
import { AcceptancePackageLinks } from '../../Shared/AcceptancePackageLinks/AcceptancePackageLinks'

export const SettingsMenuSidePanel: FC<SettingsMenuSidePanelProps> = ({
  currentTab,
  setCurrentTab,
  searchText,
  setSearchText,
  filteredSettingItems,
}) => {
  let wideScreenCurrentTab = currentTab

  if (wideScreenCurrentTab === 'mainMenu') wideScreenCurrentTab = 'myDetails' // Set my details as default page on widescreen

  return (
    <div css={{ height: '92vh', width: 400, borderRight: `1px solid ${colors.grey200}`, position: 'fixed' }}>
      <div css={{ padding: 30 }}>
        <SearchTextInput searchText={searchText} setSearchText={setSearchText} />
      </div>

      <div>
        {filteredSettingItems?.map((item) => {
          const { tabKey, label } = item

          return (
            <SettingItem
              key={tabKey}
              setCurrentTab={setCurrentTab}
              tabKey={tabKey}
              label={label}
              isSelected={tabKey === wideScreenCurrentTab}
            />
          )
        })}
      </div>
      <AcceptancePackageLinks />
    </div>
  )
}
