import { TPresetId } from 'App/Map/types'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { setDateFromAndDateTo } from './setDateFromAndDateTo'
import { setSelectedValueGroupsAndMapPrimaryMeasure } from './setSelectedValueGroupsAndMapPrimaryMeasure'

export const setSelectedPreset = (idHash: TPresetId) => {
  const { availableSemiosDefinedPresets, availableUserDefinedPresets } = userDetailsStore.getState()
  const allPresetsObject = { ...availableSemiosDefinedPresets, ...availableUserDefinedPresets }
  const foundPreset = allPresetsObject[idHash]

  if (!foundPreset) return

  setDateFromAndDateTo(foundPreset)

  setSelectedValueGroupsAndMapPrimaryMeasure(foundPreset)

  /**
   * note that since there are store subscriptions
   * that will set selectedPreset to null, this should
   * be done after the other side-effects
   */
  smallStore.setState((s) => ({ ...s, selectedPreset: idHash }))
}
