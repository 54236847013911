import { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import { GridTableContentSection } from 'components/GridTable/types'
import { WeatherCondition } from 'components/WeatherCondition/WeatherCondition'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { EnumWeatherCondition } from 'utils/weather-conditions/types'
import { defaultValuesRowHeight, weatherTimestampToValuesDictionaryMaker } from '../_utils'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'

type CommonValueType =
  VV.DomainTypes.Weather.TValuesReturnWithMetaIgnoringKeying['points']['weatherIcon'][number]['timeseries'][number]

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_WEATHER_DETAILS' })
const pointCategory: TPointCategory = 'weatherPoint'

const possibleValueTypesToRequest: VV.DomainTypes.Weather.TTimeseriesValueTypeKeysMerged[] = [
  'weatherIcon',
  'sunrise',
  'sunset',
]

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.conditions || !selectedFieldAssets[pointCategory]) return {}

  const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

  const valuesRequested: Partial<
    Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>
  > = {
    weatherIcon: preferredAggregationInterval,
    sunrise: preferredAggregationInterval,
    sunset: preferredAggregationInterval,
  }

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: translate.phrases.banyanApp('Conditions'),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
    id: 'summary-grid-conditions',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const stationLngLat = String(selectedFieldAssetsStore.getState()[pointCategory])
  const weatherIconValues = weatherTimestampToValuesDictionaryMaker(data, 'weatherIcon', stationLngLat)
  const sunriseValues = weatherTimestampToValuesDictionaryMaker(data, 'sunrise', stationLngLat)
  const sunsetValues = weatherTimestampToValuesDictionaryMaker(data, 'sunset', stationLngLat)

  const timezone =
    fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssetsStore.getState()?.property)]
      ?.timezone ?? moment.tz.guess()

  const items = [
    {
      id: 'weather-icon',
      label: translate.phrases.banyanApp('Conditions'),
      height: defaultValuesRowHeight,
      labelMinWidth: 120,
      valueMinWidth: 120,
      render: (dataPoint: { value: CommonValueType }) => {
        if (dataPoint !== undefined && typeof dataPoint.value === 'string') {
          return (
            <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 40 }}>
              <WeatherCondition weatherCondition={dataPoint.value as EnumWeatherCondition} />
            </div>
          )
        }

        return translate.phrases.templates('-')
      },
      values: weatherIconValues,
    },
    {
      id: 'sunrise',
      label: translate.phrases.banyanApp('Sunrise'),
      height: defaultValuesRowHeight,
      labelMinWidth: 120,
      valueMinWidth: 120,
      render: (dataPoint: { value: CommonValueType }) => {
        if (dataPoint !== undefined && typeof dataPoint.value === 'string') {
          return translate.dates.format(moment.tz(dataPoint.value, timezone), 'h:mm a')
        }

        return translate.phrases.templates('-')
      },
      values: sunriseValues,
    },
    {
      id: 'sunset',
      label: translate.phrases.banyanApp('Sunset'),
      height: defaultValuesRowHeight,
      labelMinWidth: 120,
      valueMinWidth: 120,
      render: (dataPoint: { value: CommonValueType }) => {
        if (dataPoint !== undefined && typeof dataPoint.value === 'string') {
          return translate.dates.format(moment.tz(dataPoint.value, timezone), 'h:mm a')
        }

        return translate.phrases.templates('-')
      },
      values: sunsetValues,
    },
  ]

  return {
    ...commonReturnItems,
    items,
  }
}
