import { routes } from '@semios/app-platform-banyan-route-definitions'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { StackedChartSection } from 'components/StackedChart/types'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { awcHeatmap } from './awcHeatmap'
import { awcLine } from './awcLine'
import { ionicContent } from './ionicContent'
import { soilTemperature } from './soilTemperature'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'

const pointCategory: TPointCategory = 'soilPoint'

export type SoilValueType = VV.DomainTypes.Soil.TSoil | VV.DomainTypes.Soil.TSoilAggregated

export const checkPermission = () =>
  selectedPropertyHasPermission({ permission: 'VIEW_SOIL_MOISTURE_DETAILS' })

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.soil || !selectedFieldAssets[pointCategory]) return {}

  const preferredAggregationInterval = {
    preferredAggregationInterval: chooseAmongstUnAggHourlyAndDaily(),
  }

  const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
    soilMoisture: preferredAggregationInterval,
    soilTemperature: preferredAggregationInterval,
    soilIonicContent: preferredAggregationInterval,
  }

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

// TODO: At the moment we are only making line series with the average value. We are not yet making area series out of the min/max values.
// TODO: We should probably have selectedDepths as checkboxes (outside of Highcharts) instead of as a Highcharts legend
export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection[] => {
  const soilStationLngLat = selectedFieldAssetsStore.getState()[pointCategory]
  const soilCharts = []

  soilCharts.push(awcLine({ data, soilStationLngLat, compareSeasonsData }))

  soilCharts.push(awcHeatmap({ data, soilStationLngLat }))

  soilCharts.push(ionicContent({ data, soilStationLngLat }))

  soilCharts.push(soilTemperature({ data, soilStationLngLat, compareSeasonsData }))

  return soilCharts
}
