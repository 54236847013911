import { routes } from '@semios/app-platform-banyan-route-definitions'
import { PrecipitationTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/PrecipitationTitleChildren/PrecipitationTitleChildren'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import { GridTableContentSection } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { defaultValuesRowHeight, weatherTimestampToValuesDictionaryMaker } from '../_utils'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_WEATHER_DETAILS' })
const pointCategory: TPointCategory = 'outOfBlockPoint'
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  precipitation: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.precipitation || !selectedFieldAssets[pointCategory]) return {}

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.precipitation().categoryTitleWithoutUnit(),
    titleChildren: (
      <PrecipitationTitleChildren
        showAggregationToggle={false}
        valuesTimeseriesToFilterOn={Object.keys(valuesRequested)}
      />
    ),
    id: 'summary-grid-precipitation',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const stationLngLat = String(selectedFieldAssetsStore.getState()[pointCategory])
  const values = weatherTimestampToValuesDictionaryMaker(data, 'precipitation', stationLngLat)

  const items = [
    {
      id: 'rain',
      label: unitConverter.precipitation().shortTitle(),
      height: defaultValuesRowHeight,
      labelMinWidth: 90,
      valueMinWidth: 120,
      render: (dataPoint: { value: { sum: number } }) => {
        if (typeof dataPoint?.value?.sum !== 'number') {
          return translate.phrases.templates('-')
        }

        return unitConverter.precipitation(dataPoint?.value?.sum).valueWithSuffix()
      },
      values,
    },
  ]

  return {
    ...commonReturnItems,
    items,
  }
}
