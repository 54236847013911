import { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import { GridTableContentSection, GridTableContentSectionItem } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { getCropNameFromId } from 'utils/getCropNameFromId'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { BadgeCell } from '../../../BadgeCell/BadgeCell'
import {
  DEFAULT_MAX_LOW_STRESS,
  DEFAULT_MIN_HIGH_STRESS,
} from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/plantStress/PlantWaterStress/plantWaterStress'

type CommonValueType = {
  timestamp: string
  trunkDisplacement?: number | null
  signalTS5?: number | null
  metadata?: {
    maxLowStress: number
    minHighStress: number
    aggregationInterval: string | null
  }
}

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_PLANT_STRESS_DETAILS' })

const preferredAggregationInterval = {
  preferredAggregationInterval: EAggregationInterval.DAILY,
}

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  trunkDisplacement: preferredAggregationInterval,
  signalTS5: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  const points = fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.points

  if (!points) return {}

  const treeDendrometerStationsForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(points),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  if (!selectedValueGroups.plant_stress || isEmpty(treeDendrometerStationsForProperty)) return {}

  const lngLats = treeDendrometerStationsForProperty.map((p) => p.lngLat)

  return {
    points: {
      lngLats,
      valuesRequested,
    },
  }
}

export const getPlantWaterStressColorAndLabel = (
  signalTS5Value: number | null | undefined,
  maxLowStress: number,
  minHighStress: number,
): { color: string; name: string } => {
  if (signalTS5Value === null || signalTS5Value === undefined) {
    return {
      color: 'transparent',
      name: '-',
    }
  }

  if (signalTS5Value < maxLowStress) {
    return {
      color: colors.plantStressLow,
      name: translate.phrases.banyanApp('Low'),
    }
  } else if (signalTS5Value > minHighStress) {
    return {
      color: colors.plantStressHigh,
      name: translate.phrases.banyanApp('High'),
    }
  } else {
    return {
      color: colors.plantStressMedium,
      name: translate.phrases.banyanApp('Medium'),
    }
  }
}

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.plantWaterStress().categoryTitleWithoutUnit(),
    titleChildren: <DropdownSelectorProperty valuesTimeseriesToFilterOn={Object.keys(valuesRequested)} />,
    id: 'summary-grid-plant-stress',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const points = fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.points ?? {}

  const treeDendrometerStationsForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(points),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  const items: GridTableContentSectionItem[] = treeDendrometerStationsForProperty
    .map((tds) => {
      const values: Record<string, CommonValueType> = {}
      const deviceName = tds.name || translate.phrases.banyanApp('Unnamed Station')
      const cropName = getCropNameFromId(Number(tds.configuration.treeDendrometerCropId))
      // we won't use this in the rendering, but we want its translated values for sorting
      const stringToSortOn = `${cropName} - ${deviceName}`
      const { trunkDisplacement, signalTS5 } = data?.points?.[tds.lngLat]?.values ?? {}

      trunkDisplacement?.[0]?.timeseries?.forEach((ts) => {
        values[ts.timestamp] = values[ts.timestamp] ?? { timestamp: ts.timestamp }

        values[ts.timestamp].trunkDisplacement = ts.value
      })

      signalTS5?.[0]?.timeseries?.forEach((ts) => {
        values[ts.timestamp] = values[ts.timestamp] ?? { timestamp: ts.timestamp }

        values[ts.timestamp].signalTS5 = ts.value

        values[ts.timestamp].metadata = signalTS5?.[0]?.metadata
      })

      return {
        stringToSortOn,
        id: tds.lngLat,
        deviceName,
        cropName,
        height: 178,
        labelMinWidth: 190,
        valueMinWidth: 120,
        values,
        label: (
          <div css={{ display: 'flex', flexDirection: 'column', padding: '20px 0' }}>
            <div css={{ fontWeight: '700', fontSize: 14, height: 30 }}>
              {translate.phrases.templates('{{labelA}} - {{labelB}}', {
                labelA: deviceName,
                labelB: cropName,
              })}
            </div>
            <div
              css={{
                paddingLeft: 10,
                fontSize: 14,
                fontWeight: '400',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                marginTop: 10,
              }}
            >
              <p>{unitConverter.plantWaterStress().shortTitle()}</p>
              <p>{unitConverter.trunkDisplacement().shortTitle()}</p>
            </div>
          </div>
        ),
        render: (dataPoint: typeof values[keyof typeof values]) => {
          const trunkDisplacement = dataPoint?.trunkDisplacement
            ? `${unitConverter.trunkDisplacement(dataPoint.trunkDisplacement).value()} ${unitConverter
                .trunkDisplacement()
                .suffix()}`
            : '-'

          const minHighStress = dataPoint?.metadata?.minHighStress ?? DEFAULT_MIN_HIGH_STRESS
          const maxLowStress = dataPoint?.metadata?.maxLowStress ?? DEFAULT_MAX_LOW_STRESS

          const { color, name } = getPlantWaterStressColorAndLabel(
            dataPoint?.signalTS5,
            maxLowStress,
            minHighStress,
          )

          return (
            <div css={{ display: 'flex', flexDirection: 'column', padding: '20px 0' }}>
              <div
                css={{
                  paddingLeft: 10,
                  fontSize: 14,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginTop: 40,
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                <p>
                  <BadgeCell backgroundColor={color}>{name}</BadgeCell>
                </p>
                <p>{trunkDisplacement}</p>
              </div>
            </div>
          )
        },
      }
    })
    .sort(sortByKey('stringToSortOn'))
    .map(({ stringToSortOn, ...rest }) => rest)

  return {
    ...commonReturnItems,
    items,
  }
}
