import { Avatar } from '@mantine/core'
import { colors } from '../../../settings/colors'

export const UnreadMessagesCountBadge = ({ count }: { count?: number }) => {
  return (
    <Avatar
      variant="filled"
      color="red"
      styles={{ root: { padding: 2, marginLeft: 6, alignSelf: 'center', backgroundColor: 'red' } }}
      radius="xl"
      size="sm"
    >
      <span css={{ color: colors.white, fontSize: 14, fontWeight: 500 }}>{count ?? ''}</span>
    </Avatar>
  )
}
