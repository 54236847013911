import { translate } from 'i18n/i18n'

export const getTooltipForPressureSensors = ({
  currentTimeStamp,
  sensorName,
  appliedWater,
  showPressure,
  pressureValue,
  statusColor,
  statusText,
  statusBorderColor,
  periodDurationText,
  periodFromDateText,
  periodToDateText,
}: {
  currentTimeStamp: string
  sensorName: string
  appliedWater: string
  showPressure: boolean
  pressureValue: string
  statusColor: string
  statusText: string
  statusBorderColor: string
  periodDurationText: string
  periodFromDateText: string
  periodToDateText: string
}) =>
  `<div style="padding-bottom: 4px;">${showPressure ? currentTimeStamp : ''}</div>
   <div style="padding-bottom: 4px;">${sensorName}</div>
   <table>

     <tr>
       <td style="padding-right: 8px;"><div style="width: 16px; height: 16px; border-radius: 2px; border: 2px solid ${statusBorderColor}; background-color: ${statusColor};"></div></td>
       <td style="padding-right: 8px;">${statusText}</td>
       <td><b>${periodDurationText}</b></td>
     </tr>
     <tr>
       <td style="padding-right: 8px;">&nbsp;</td>
       <td style="padding-right: 8px;">${translate.phrases.banyanApp('From')}</td>
       <td><b>${periodFromDateText}</b></td>
     </tr>
     <tr>
       <td style="padding-right: 8px;">&nbsp;</td>
       <td style="padding-right: 8px;">${translate.phrases.banyanApp('To')}</td>
       <td><b>${periodToDateText}</b></td>
     </tr>
     <tr>
     <td style="padding-right: 8px;">&nbsp;</td>
     <td style="padding-right: 8px;">${translate.phrases.banyanApp('Applied Water')}</td>
     <td><b>${appliedWater}</b></td>
   </tr>
     ${
       showPressure
         ? `<tr>
      <td style="padding-right: 8px;"><div style="width: 16px; height: 3px; background-color: black;"></div></td>
      <td style="padding-right: 8px;">${translate.phrases.banyanApp('Pressure')}</td>
      <td><b>${pressureValue}</b></td>
    </tr>`
         : ''
     }
   </table>`
