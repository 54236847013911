import {
  selectedValueGroupsStore,
  TSection,
  TValueGroup,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { sortByKey } from 'utils/sortByKey'
import { leadingInsectKey, TNavigationMenuItem } from './generateFlatNavigationList'
import { generateNavigationMenuGroupedBySection } from './generateNavigationMenuGroupedBySection'

type TChildrenCheckedOptions = 'ALL' | 'SOME' | 'NONE'

const sectionSortingOrder: Record<TSection, number> = {
  'environment': 1,
  'water-management': 2,
  'sprays': 3,
  'insects': 4,
  'diseases': 5,
  'crop-development': 6,
  'equipment': 7,
}

export const sortSections = (a: TSection, b: TSection) => {
  return sectionSortingOrder[a] - sectionSortingOrder[b]
}

export const generateNavigationMenu = ({
  selectedValueGroups,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}) => {
  const sections = generateNavigationMenuGroupedBySection()

  const navigationMenu: (Omit<
    ReturnType<typeof generateNavigationMenuGroupedBySection>[number],
    'childrenObject'
  > & {
    childrenChecked?: TChildrenCheckedOptions
    childrenCheckedCount?: number
    children: (TNavigationMenuItem & { checked: boolean })[]
  })[] = []

  sections.forEach(({ childrenObject, ...section }) => {
    const sectionChildren = Object.values(childrenObject)
      .map((sc) => {
        const isInsect = sc.key.startsWith(leadingInsectKey)

        if (!isInsect) {
          return { ...sc, checked: !!selectedValueGroups[sc.key as TValueGroup] }
        }

        const insectId = sc.key.slice(leadingInsectKey.length)

        const checked = Object.entries(selectedValueGroups).some(
          ([valueGroup, isChecked]) => valueGroup.endsWith(`_insect_id_${insectId}`) && !!isChecked,
        )

        return { ...sc, checked }
      })
      .sort(sortByKey('translatedTitle'))

    const countOfChecked = sectionChildren.filter((sc) => sc.checked).length

    let childrenChecked: TChildrenCheckedOptions = 'NONE'

    if (sectionChildren.length === countOfChecked) childrenChecked = 'ALL'
    else if (countOfChecked > 0) childrenChecked = 'SOME'

    navigationMenu.push({
      ...section,
      children: sectionChildren,
      childrenChecked,
      childrenCheckedCount: countOfChecked,
    })
  })

  return navigationMenu
}
