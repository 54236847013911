import { parse as parseWKT } from 'wellknown'
import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'

export const serviceCenterPlannedNodesGet = async (propertyIds: number[]) => {
  const plannedNodes = await apiFetch<
    routes.ServiceCenterPlannedNodesGet.Request,
    routes.ServiceCenterPlannedNodesGet.Response
  >({
    url: routes.ServiceCenterPlannedNodesGet.path,
    body: {
      propertyIds,
    },
    params: {
      method: 'POST',
    },
  })

  const shapedPlannedNodes = plannedNodes.map((node) => ({
    ...node,
    // Transform string WKT to serialized GeoJSON
    location: JSON.stringify(parseWKT(node.location as string)),
  })) as routes.ServiceCenterPlannedNodesGet.Response

  return shapedPlannedNodes
}
