import { serviceCenterStore } from '../store/serviceCenterStore'
import { isSemiosGatewayType } from './getNodeNetworkType'
import { TActiveNode, TPlannedNode } from '../types'
import { getIdentifier } from './getIdentifier'
import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import { OfflineRequestError } from 'utils/errorCodes'
import { PlannedNodeInstallMeta } from './api/serviceCenterPlannedNodeInstall'
import { GeoJSONPoint, stringify as stringifyWKT } from 'wellknown'

export async function installPlannedNode(
  node: Omit<TPlannedNode, 'location'>,
  location: GeoJSONPoint,
  nodeIdentifier: string,
): Promise<TActiveNode> {
  const { id: pseudonodeId, nodeType, maintenanceOwnerId, name } = node

  let unsynced = true

  // Install planned node
  try {
    if (isSemiosGatewayType(nodeType)) throw new Error('Gateway installation is not supported yet!')

    // Request may or may not have been completed by the time the promise resolves
    const { status } = await ApiRequestQueue.createRequest(ApiRequestType.INSTALL_NODE, {
      pseudonodeId: +pseudonodeId,
      nodeIdentifier,
      name,
      location: stringifyWKT(location),
      maintenanceOwnerId,
      gpsAccuracy: undefined, // TODO: Add GPS accuracy
    } as PlannedNodeInstallMeta)

    if (status === ApiRequestStatus.COMPLETED) unsynced = false
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) throw error
  }

  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())

  let activeNode: TActiveNode

  if (unsynced) {
    // If the request has not been completed yet, update the local state
    const updatedActiveNodes = [...activeNodes].filter((node) => getIdentifier(node) !== nodeIdentifier)

    activeNode = {
      nodeIdentifier,
      location: JSON.stringify(location),
      name: node.name,
      nodeType,
      propertyId: node.propertyId,
      maintenanceOwnerId,
      devices: node.devices,
    }

    updatedActiveNodes.push(activeNode)

    serviceCenterStore.actions.setActiveNodes(updatedActiveNodes)
  } else {
    // If request has been completed, find the newly installed active node
    activeNode = activeNodes.find((node) => getIdentifier(node) === nodeIdentifier) as TActiveNode

    if (!activeNode) throw new Error('Could not find newly installed active node!')
  }

  // Select newly installed active node
  serviceCenterStore.actions.selectEquipment(nodeIdentifier, 'active')

  // Remove installed planned node from list
  serviceCenterStore.actions.removePlannedNodes([pseudonodeId])

  return activeNode
}
