import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import {
  MAP_VISUAL,
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentHeatmapPointsValueTypes,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
} from 'stores/mapControlsStore/types'
import { getPrimaryValueGroup } from 'stores/selectedValueGroupsStore/getPrimaryValueGroup'
import { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'

const updateMapVisuals = (
  newValueType: TValuesCurrentPointsValueTypes & TValuesCurrentHeatmapPointsValueTypes,
) => {
  const primaryValueGroup = getPrimaryValueGroup() as TValueGroup

  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.POINT]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.POINT],
        [primaryValueGroup]: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.POINT][primaryValueGroup] ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.PROPERTY]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.PROPERTY],
        [primaryValueGroup]: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.PROPERTY][primaryValueGroup] ?? { mItem: 'M_Property' }),
          valueType: newValueType.replace('Sum_', 'SumMinMedianMax_') as TValuesCurrentPropertiesValueTypes,
        },
      },
      [MAP_VISUAL.SCD]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.SCD],
        [primaryValueGroup]: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.SCD][primaryValueGroup] ?? { mItem: 'M_SCD' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.BLOCK]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.BLOCK],
        [primaryValueGroup]: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.BLOCK][primaryValueGroup] ?? { mItem: 'M_SCD' }),
          valueType: newValueType.replace('Sum_', 'SumMinMedianMax_') as TValuesCurrentBlocksValueTypes,
        },
      },
    },
  }))
}

export const InsectDegreeDaysSecondaryDropdown = () => {
  const primaryValueGroup = usePrimaryValueGroup()

  const { insectId, value } = mapControlsStore.useSelector((s) => ({
    insectId: !primaryValueGroup ? null : Number(primaryValueGroup.replace('degree_days_insect_id_', '')),
    value: !primaryValueGroup
      ? null
      : s.mapVisualValueGroup?.[MAP_VISUAL.POINT]?.[primaryValueGroup]?.valueType,
  }))

  const data: { value: TValuesCurrentPointsValueTypes; label: string; group: string }[] = useMemo(() => {
    const returner: { value: TValuesCurrentPointsValueTypes; label: string; group: string }[] = [
      {
        value:
          `insectDegreeDays_sinceBiofixSum_insectId${insectId}_degreeDaysC` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('Current'),
        group: translate.phrases.banyanApp('Since Biofix'),
      },
      {
        value:
          `insectDegreeDays7DaysFromNow_sinceBiofixSum_insectId${insectId}_degreeDaysC` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('{{count}} Days From Now', { count: 7 }),
        group: translate.phrases.banyanApp('Since Biofix'),
      },
      {
        value:
          `insectDegreeDays14DaysFromNow_sinceBiofixSum_insectId${insectId}_degreeDaysC` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('{{count}} Days From Now', { count: 14 }),
        group: translate.phrases.banyanApp('Since Biofix'),
      },
    ]

    return returner
  }, [insectId, value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
