import { MapContext } from 'App/Map/MapContext/MapContext'
import React, { DependencyList, useContext, useEffect, useRef } from 'react'
import { Polygon } from '@turf/helpers'
import turfBBox from '@turf/bbox'
import { mapStore } from 'stores/mapStore'
import { transformCoordsToLatLng } from '../../_utils/transformCoordsToLatLng'

interface Block {
  id: string
  geometry: Polygon
}

type MAP_ITEM = Block & { overlay?: google.maps.Polygon; bounds: google.maps.LatLngBounds }

interface BlockRendererProps {
  blocks: Block[]
  // Any dependencies change will trigger a render of the map overlays (map bounds and blocks array are already included)
  dependencies?: DependencyList
}

export const BlockRenderer: React.FC<BlockRendererProps> = ({ blocks, dependencies }) => {
  const { map } = useContext(MapContext)
  const mapBounds = mapStore.useSelector((s) => s.bounds)
  const mapItemsCache = useRef<Record<string, MAP_ITEM>>({})

  const isBlockVisible = (item: MAP_ITEM, mapBounds: google.maps.LatLngBoundsLiteral): boolean => {
    return item.bounds.intersects(mapBounds)
  }

  const renderItems = (mapBounds: google.maps.LatLngBoundsLiteral) => {
    Object.values(mapItemsCache.current).forEach((item) => {
      if (isBlockVisible(item, mapBounds)) {
        if (!item.overlay) {
          // Create marker overlay, if not already existing
          item.overlay = new google.maps.Polygon({
            paths: transformCoordsToLatLng(item.geometry),
            clickable: false,
            map,
          })
        }

        item.overlay.setOptions({
          map,
          strokeColor: 'white',
          strokeWeight: 2,
          strokePosition: google.maps.StrokePosition.CENTER,
        })
      } else if (item.overlay) item.overlay.setMap(null)
    })
  }

  const clearItems = () => {
    Object.values(mapItemsCache.current).forEach((item) => {
      if (item.overlay) {
        item.overlay.setMap(null)

        delete item.overlay
      }
    })
  }

  // Remove items from map when component unmount
  useEffect(() => {
    return function cleanup() {
      clearItems()
    }
  }, [])

  // Update cache when blocks list change
  useEffect(() => {
    if (!blocks) return

    Object.values(blocks).forEach((block) => {
      const bbox = turfBBox(block.geometry)

      const blockBounds = new google.maps.LatLngBounds({
        east: bbox[2], // maxX
        north: bbox[3], // maxY
        south: bbox[1], // minY
        west: bbox[0], // minX
      })

      mapItemsCache.current[block.id] = {
        ...block,
        bounds: blockBounds,
      }
    })
  }, [blocks])

  // Update nodes rendering on map when dependencies change
  useEffect(() => {
    if (mapBounds) renderItems(mapBounds)
  }, [mapBounds, blocks, ...(dependencies || [])])

  return null
}
