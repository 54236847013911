import React from 'react'
import { translate } from 'i18n/i18n'
import { Footer } from '../components/Footer/Footer'
import { installationWorkflowStore } from '../../store/installationWorkflowStore'

interface ChecklistProps {
  checklist: string[]
  onClose: () => void
}

export const Checklist: React.FC<ChecklistProps> = ({ checklist, onClose }) => {
  const handleNext = () => {
    installationWorkflowStore.actions.showNextStep()
  }

  return (
    <div css={{ padding: 10 }}>
      <h3 css={{ margin: 0 }}>{translate.phrases.placeholder('Checklist')}</h3>

      <ul css={{ lineHeight: '36px' }}>
        {checklist.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>

      <Footer onPrevious={onClose} onNext={handleNext} />
    </div>
  )
}
