import { AlertSection } from '../../../components/AlertSection'
import { translate } from 'i18n/i18n'
import { AlertTypeId, Rule } from '../../../settings/alertSettings'
import { AlternariaThreshold } from './AlternariaThreshold/AlternariaThreshold'
import { Dispatch, SetStateAction } from 'react'
import { FireBlightThreshold } from './FireBlightThreshold/FireBlightThreshold'
import { FrostThreshold } from './FrostThreshold/FrostThreshold'
import { LeafWetnessThreshold } from './LeafWetnessThreshold/LeafWetnessThreshold'
import { TemperatureThreshold } from './TemperatureThreshold/TemperatureThreshold'
import { WetBulbThreshold } from './WetBulbThreshold/WetBulbThreshold'
import { TrapCatchesThreshold } from './TrapCatchesThreshold/TrapCatchesThreshold'
import { DegreeDaysThreshold } from './DegreeDaysThreshold/DegreeDaysThreshold'
import { AlmondHullSplitThreshold } from './AlmondHullSplitThreshold/AlmondHullSplitThreshold'
import { WeatherForecastType } from '../../ModalDrawerCreateOrEditAlert'
import { WeatherForecastThreshold } from './WeatherForecastThreshold/WeatherForecastThreshold'

export const Thresholds = ({
  alertTypeId,
  rules,
  setRules,
  setThresholdIsValid,
  triedToSubmit,
  insectId,
  setWeatherForecastThresholdType,
}: {
  alertTypeId: AlertTypeId
  rules: Rule[]
  setRules: Dispatch<SetStateAction<Rule[]>>
  setThresholdIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
  insectId: number | null
  setWeatherForecastThresholdType: Dispatch<SetStateAction<WeatherForecastType>>
}) => {
  return (
    <AlertSection title={translate.phrases.banyanApp('Threshold')}>
      {alertTypeId === 'alternaria' && (
        <AlternariaThreshold
          rules={rules}
          setRules={setRules}
          setThresholdIsValid={setThresholdIsValid}
          triedToSubmit={triedToSubmit}
        />
      )}
      {alertTypeId === 'almondHullSplit' && (
        <AlmondHullSplitThreshold
          rules={rules}
          setRules={setRules}
          setThresholdIsValid={setThresholdIsValid}
          triedToSubmit={triedToSubmit}
        />
      )}
      {alertTypeId === 'degreeDays' && (
        <DegreeDaysThreshold
          rules={rules}
          insectId={insectId}
          setRules={setRules}
          setThresholdIsValid={setThresholdIsValid}
          triedToSubmit={triedToSubmit}
        />
      )}
      {alertTypeId === 'fireBlight' && (
        <FireBlightThreshold
          rules={rules}
          setRules={setRules}
          setThresholdIsValid={setThresholdIsValid}
          triedToSubmit={triedToSubmit}
        />
      )}
      {alertTypeId === 'frost' && (
        <FrostThreshold
          rules={rules}
          setRules={setRules}
          setThresholdIsValid={setThresholdIsValid}
          triedToSubmit={triedToSubmit}
        />
      )}
      {alertTypeId === 'leafWetness' && (
        <LeafWetnessThreshold
          rules={rules}
          setRules={setRules}
          setThresholdIsValid={setThresholdIsValid}
          triedToSubmit={triedToSubmit}
        />
      )}
      {alertTypeId === 'temperature' && (
        <TemperatureThreshold
          rules={rules}
          setRules={setRules}
          setThresholdIsValid={setThresholdIsValid}
          triedToSubmit={triedToSubmit}
        />
      )}
      {alertTypeId === 'trapCatches' && (
        <TrapCatchesThreshold
          rules={rules}
          setRules={setRules}
          setThresholdIsValid={setThresholdIsValid}
          triedToSubmit={triedToSubmit}
        />
      )}
      {alertTypeId === 'weatherForecastAlert' && (
        <WeatherForecastThreshold
          rules={rules}
          setRules={setRules}
          setThresholdIsValid={setThresholdIsValid}
          setWeatherForecastThresholdType={setWeatherForecastThresholdType}
          triedToSubmit={triedToSubmit}
        />
      )}
      {alertTypeId === 'wetBulb' && (
        <WetBulbThreshold
          rules={rules}
          setRules={setRules}
          setThresholdIsValid={setThresholdIsValid}
          triedToSubmit={triedToSubmit}
        />
      )}
    </AlertSection>
  )
}
