import moment from 'moment-timezone'
import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapApiScheduledEventStatusToTScheduledEventEnum } from '../../mapApiScheduledEventStatusToTScheduledEventEnum'
import { irrigationSchedulerStore } from '../irrigationSchedulerStore'
import { getSelectedPropertyTimezoneForIrrigationScheduler } from '../selectors/getSelectedPropertyTimezoneForIrrigationScheduler'

export const eventsUpdateAndOrDelete = (
  apiEvents: Array<
    Omit<SharedTypes.IrrigationScheduledEventOutput, 'status'> & {
      status?: SharedTypes.IrrigationScheduledEventOutput['status']
    }
  >,
) => {
  irrigationSchedulerStore.setState((isss) => {
    const scheduledEvents = { ...isss.scheduledEvents }
    const timezone = getSelectedPropertyTimezoneForIrrigationScheduler(fieldAssetStore.getState(), isss)

    apiEvents.forEach((apiEvent) => {
      if (!apiEvent.status) {
        delete scheduledEvents[apiEvent.eventId]
      } else {
        scheduledEvents[apiEvent.eventId] = {
          ...scheduledEvents[apiEvent.eventId],
          dateFrom: moment.tz(apiEvent.dateFrom, timezone),
          dateTo: moment.tz(apiEvent.dateTo, timezone),
          type: mapApiScheduledEventStatusToTScheduledEventEnum(apiEvent.status),
        }
      }
    })

    return {
      ...isss,
      scheduledEvents,
    }
  })
}
