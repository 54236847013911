import { IRRIGATION_SCHEDULER_FIRST_DAY_OF_WEEK } from '@semios/app-platform-common'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import {
  TIrrigationSchedulerStoreState,
  irrigationSchedulerStore,
  TDateViewEnum,
} from '../irrigationSchedulerStore'
import { getDateRange } from '../selectors/getDateRange'
import { getSelectedPropertyTimezoneForIrrigationScheduler } from '../selectors/getSelectedPropertyTimezoneForIrrigationScheduler'

export const setDateView = (dateView: TIrrigationSchedulerStoreState['dateView']) => {
  irrigationSchedulerStore.setState((isss) => {
    const timezone = getSelectedPropertyTimezoneForIrrigationScheduler(fieldAssetStore.getState(), isss)

    // switching from week to day
    if (isss.dateView === TDateViewEnum.WEEK && dateView === TDateViewEnum.DAY) {
      const dateRange = getDateRange(fieldAssetStore.getState(), isss)
      const mNow = moment.tz(timezone)

      // if today is already in view, change the dateFrom to today, otherwise keep it as the start of the week
      const dateFrom = mNow.isBetween(dateRange.mDateFrom, dateRange.mDateTo)
        ? mNow.clone().startOf('day').toISOString()
        : isss.dateFrom

      return {
        ...isss,
        dateView,
        dateFrom,
      }
    }

    // switching from day to week
    if (isss.dateView === TDateViewEnum.DAY && dateView === TDateViewEnum.WEEK) {
      return {
        ...isss,
        dateView,
        dateFrom: moment
          .tz(isss.dateFrom, timezone)
          .day(IRRIGATION_SCHEDULER_FIRST_DAY_OF_WEEK)
          .startOf('day')
          .toISOString(),
      }
    }

    // shouldn't ever get here...
    return isss
  })
}
