import { pick } from 'lodash'
import { init } from 'stores/_utils/simple-store'
import { setSearchParams } from './setSearchParams'

export const subscribeToStoreForURL = <TPersistentInitState extends Record<string, unknown>>({
  keysToPutInURL,
  store,
  storeName,
}: {
  keysToPutInURL: Record<keyof TPersistentInitState, boolean>
  store: ReturnType<typeof init<TPersistentInitState>>
  storeName: string
}) => {
  const keysToListenTo: (keyof TPersistentInitState)[] = Object.keys(keysToPutInURL).filter(
    (key) => keysToPutInURL[key],
  )

  store.subscribeToChanges(
    (s) => pick(s, keysToListenTo),
    (state) => {
      const searchParams: Partial<Record<keyof TPersistentInitState, unknown>> = {}

      Object.entries(state).forEach(([key, value]) => {
        searchParams[key as keyof TPersistentInitState] = value
      })

      setSearchParams(storeName, searchParams)
    },
  )
}
