import { isNil } from '@semios/app-platform-common'
import { useMantineTheme } from '@mantine/core'
import { useDebouncedValue, usePrevious } from '@mantine/hooks'
import { StatusBarArea } from 'components/StatusBarArea/StatusBarArea'
import { Tabs } from 'components/Tabs/Tabs'
import { useContext, useEffect } from 'react'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { isLandscapeMode } from 'utils/isLandscapeMode'
import { StackemCharts } from './StackemCharts/StackemCharts'
import { SummaryGridTable } from './SummaryGridTable/SummaryGridTable'
import { MapContext } from '../MapContext/MapContext'

export const PanelDetails = ({
  panelWidth,
  panelHeight,
  isPanelExpanded,
}: {
  panelWidth: number
  panelHeight: number
  isPanelExpanded: boolean
}) => {
  const [containerWidth] = useDebouncedValue(panelWidth, 125)
  const [containerHeight] = useDebouncedValue(panelHeight, 125)
  const theme = useMantineTheme()
  const { map } = useContext(MapContext)
  const currentSelectedTab = detailsPanelStore.useSelector((s) => s.detailsTab)
  const oldContainerWidth = usePrevious(containerWidth)
  // TODO: The container height isnt changing when panel is opened on non widescreen
  const oldContainerHeight = usePrevious(containerHeight)
  const previousIsLandscape = usePrevious(isLandscapeMode())

  useEffect(() => {
    if (!isNil(oldContainerWidth) && !isNil(oldContainerHeight)) {
      if (!(previousIsLandscape && !isLandscapeMode())) {
        map?.panBy((containerWidth - oldContainerWidth) / 2, (containerHeight - oldContainerHeight) / 2)
      }
    }
  }, [containerWidth, containerHeight])

  /*
    On first render, when no tab is selected yet, and the user drag the panel, we don't want to show an empty panel.
    Instead, we show the summary page.
   **/
  useEffect(() => {
    if (isPanelExpanded && currentSelectedTab === '') {
      detailsPanelStore.setState((s) => ({ ...s, detailsTab: 'SUMMARY' }))
    }
  }, [isPanelExpanded])

  return (
    <>
      <StatusBarArea backgroundColor={theme.colors.midnight[0]} darkContent={true} />
      <Tabs defaultTab={currentSelectedTab} containerWidth={panelWidth}>
        <div style={{ display: currentSelectedTab === 'SUMMARY' ? 'block' : 'none' }}>
          <SummaryGridTable
            preventFetch={!isPanelExpanded || currentSelectedTab !== 'SUMMARY'}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
          />
        </div>
        <div style={{ display: currentSelectedTab === 'DETAILS' ? 'block' : 'none' }}>
          <StackemCharts
            preventFetch={!isPanelExpanded || currentSelectedTab !== 'DETAILS'}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
          />
        </div>
      </Tabs>
    </>
  )
}
