import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput'
import { translate } from 'i18n/i18n'
import { FC, lazy, Suspense } from 'react'
import { EvapotranspirationSettings } from '../Shared/EvapotranspirationSettings/EvapotranspirationSettings'
import { IrrigationSchedulerSettings } from '../Shared/IrrigationSchedulerSettings/IrrigationSchedulerSettings'
import { MyDetails } from '../Shared/MyDetails'
import { Presets } from '../Shared/Presets/Presets'
import { UnitSettings } from '../Shared/UnitSettings'
import { UserSettingsMenuScreenProps } from '../types'
import { SettingItem } from './SettingItem'
import { ContactsAndGroups } from '../Shared/ContactsAndGroups/ContactsAndGroups'
import { Alerts } from '../Shared/Alerts/Alerts'
import { ReportsMain } from '../Shared/ManageReports/ReportsMain'
import { AcceptancePackageLinks } from '../Shared/AcceptancePackageLinks/AcceptancePackageLinks'

const SemiosAPI = lazy(() => import('../Shared/SemiosAPI/SemiosAPI').then((m) => ({ default: m.SemiosAPI })))

export const UserSettingsMenuNarrowScreen: FC<UserSettingsMenuScreenProps> = ({
  currentTab,
  setCurrentTab,
  durationMillisecond,
  searchText,
  setSearchText,
  filteredSettingItems,
}) => {
  const getCurrentSelectionTitle = () => {
    switch (currentTab) {
      case 'myDetails':
        return translate.phrases.banyanApp('Account Details')

      case 'contactsAndGroups':
        return translate.phrases.banyanApp('Contacts')

      case 'unitSettings':
        return translate.phrases.banyanApp('Unit Settings')

      case 'presets':
        return translate.phrases.banyanApp('Views')

      case 'semiosApi':
        return translate.phrases.banyanApp('Semios API')

      case 'alerts':
        return translate.phrases.banyanApp('Alerts')

      case 'reports':
        return translate.phrases.banyanApp('Reports')

      case 'irrigationScheduler':
        return translate.phrases.banyanApp('Irrigation Scheduler')

      case 'evapotranspiration':
        return translate.phrases.banyanApp('Evapotranspiration')

      default:
        break
    }
  }

  return (
    <div css={{ paddingBottom: 100 }}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: 20,
        }}
      >
        <div css={{ width: '85%' }}>
          <SearchTextInput searchText={searchText} setSearchText={setSearchText} />
        </div>
        <div css={{ width: '85%', marginTop: 20 }}>
          {filteredSettingItems?.map((item) => {
            const { label, tabKey } = item

            return <SettingItem setCurrentTab={setCurrentTab} tabKey={tabKey} label={label} key={tabKey} />
          })}
        </div>

        <ModalDrawer
          title={getCurrentSelectionTitle()}
          opened={currentTab !== 'mainMenu'}
          onClose={() => setCurrentTab('mainMenu')}
          isSecondaryModal={true}
        >
          <div
            css={{
              overflowY: 'auto',
              height: 'calc(100% - env(safe-area-inset-bottom))',
            }}
          >
            {currentTab === 'unitSettings' && <UnitSettings durationMillisecond={durationMillisecond} />}
            {currentTab === 'contactsAndGroups' && <ContactsAndGroups />}
            {currentTab === 'myDetails' && <MyDetails durationMillisecond={durationMillisecond} />}
            {currentTab === 'presets' && <Presets />}
            {currentTab === 'semiosApi' && (
              <Suspense fallback={null}>
                <SemiosAPI />
              </Suspense>
            )}
            {currentTab === 'irrigationScheduler' && <IrrigationSchedulerSettings />}
            {currentTab === 'evapotranspiration' && <EvapotranspirationSettings />}
            {currentTab === 'alerts' && <Alerts />}
            {currentTab === 'reports' && <ReportsMain />}
          </div>
        </ModalDrawer>
      </div>
      <AcceptancePackageLinks />
    </div>
  )
}
