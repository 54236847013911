import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import {
  MAP_VISUAL,
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentHeatmapPointsValueTypes,
  TValuesCurrentPointsValueTypes,
} from 'stores/mapControlsStore/types'

const updateMapVisuals = (
  newValueType: TValuesCurrentPointsValueTypes & TValuesCurrentHeatmapPointsValueTypes,
) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.SCD]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.SCD],
        evapotranspiration: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.SCD].evapotranspiration ?? { mItem: 'M_SCD' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.BLOCK]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.BLOCK],
        evapotranspiration: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.BLOCK].evapotranspiration ?? { mItem: 'M_Block' }),
          valueType: newValueType as TValuesCurrentBlocksValueTypes,
        },
      },
    },
  }))
}

export const EvapotranspirationSecondaryDropdown = () => {
  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.BLOCK]?.evapotranspiration?.valueType,
  )

  const data: { value: TValuesCurrentBlocksValueTypes; label: string; group: string }[] = useMemo(() => {
    const returner: { value: TValuesCurrentBlocksValueTypes; label: string; group: string }[] = []

    returner.push({
      value: 'evapotranspirationETo_previous30DaysSum_mm',
      label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.unitSymbols('ETo'),
        labelB: translate.phrases.banyanApp('Last {{count}} Days', { count: 30 }),
      }),
      group: translate.phrases.unitSymbols('ETo'),
    })

    returner.push({
      value: 'evapotranspirationETo_previous14DaysSum_mm',
      label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.unitSymbols('ETo'),
        labelB: translate.phrases.banyanApp('Last {{count}} Days', { count: 14 }),
      }),
      group: translate.phrases.unitSymbols('ETo'),
    })

    returner.push({
      value: 'evapotranspirationETo_previous7DaysSum_mm',
      label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.unitSymbols('ETo'),
        labelB: translate.phrases.banyanApp('Last {{count}} Days', { count: 7 }),
      }),
      group: translate.phrases.unitSymbols('ETo'),
    })

    returner.push({
      value: 'evapotranspirationETo_next7DaysSum_mm',
      label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.unitSymbols('ETo'),
        labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 7 }),
      }),
      group: translate.phrases.unitSymbols('ETo'),
    })

    returner.push({
      value: 'evapotranspirationETo_next14DaysSum_mm',
      label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.unitSymbols('ETo'),
        labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 14 }),
      }),
      group: translate.phrases.unitSymbols('ETo'),
    })

    returner.push({
      value: 'evapotranspirationETc_previous30DaysSum_mm',
      label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.unitSymbols('ETc'),
        labelB: translate.phrases.banyanApp('Last {{count}} Days', { count: 30 }),
      }),
      group: translate.phrases.unitSymbols('ETc'),
    })

    returner.push({
      value: 'evapotranspirationETc_previous14DaysSum_mm',
      label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.unitSymbols('ETc'),
        labelB: translate.phrases.banyanApp('Last {{count}} Days', { count: 14 }),
      }),
      group: translate.phrases.unitSymbols('ETc'),
    })

    returner.push({
      value: 'evapotranspirationETc_previous7DaysSum_mm',
      label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.unitSymbols('ETc'),
        labelB: translate.phrases.banyanApp('Last {{count}} Days', { count: 7 }),
      }),
      group: translate.phrases.unitSymbols('ETc'),
    })

    returner.push({
      value: 'evapotranspirationETc_next7DaysSum_mm',
      label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.unitSymbols('ETc'),
        labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 7 }),
      }),
      group: translate.phrases.unitSymbols('ETc'),
    })

    returner.push({
      value: 'evapotranspirationETc_next14DaysSum_mm',
      label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.unitSymbols('ETc'),
        labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 14 }),
      }),
      group: translate.phrases.unitSymbols('ETc'),
    })

    return returner
  }, [value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
