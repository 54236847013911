import { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { extractTextFromReactNode } from './extractTextFromReactNode'
import { quickStringMatch } from 'utils/searchStringFromStringWithSpaces'

export const matchValuesForMultiSelect = (value: TOptions, search: string, caseSensitiveSearch?: boolean) => {
  let formattedLabel = ''
  let formattedGroup = ''
  let matches = 0

  if (typeof value.label === 'object') {
    // Let's be a bit cute here and try to extract the text if the label is a react node
    formattedLabel = extractTextFromReactNode(value.label)
  } else {
    formattedLabel = String(value.label)
  }

  if (value.group) formattedGroup = String(value.group)

  const searchTarget = `${formattedLabel} ${formattedGroup}`.trim()
  const searchInputs = search.split(' ').filter((input) => input)

  for (const input of searchInputs) {
    const formattedSearchinput = caseSensitiveSearch ? input : input.toLowerCase()
    const match = quickStringMatch(searchTarget, formattedSearchinput)

    if (match > 0) {
      matches += 1
    }
  }

  return matches === searchInputs.length
}
