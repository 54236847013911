import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { translate } from 'i18n/i18n'
import { mapApiEmitterTypeToLabel } from 'utils/mapApiEmitterTypeToLabel'

//  TODO: should this exist?
export const getIdAndEmitterTypeFromZoneEmitterTypeKey = (
  irrigationZoneEmitterId: SharedTypes.TIrrigationZoneEmitterId,
) => {
  const splittedLabel = irrigationZoneEmitterId.split('|||')

  return {
    irrigationZoneId: splittedLabel[0],
    emitterType: splittedLabel[1] as SharedTypes.TEmitterType,
  }
}

// TODO: should this exist?
export const makeDeviceNameAndEmitterTypeLabel = ({
  deviceName,
  emitterType,
}: {
  deviceName: string | null
  emitterType: SharedTypes.TEmitterType | null
}) => {
  const nameLabel = deviceName || translate.phrases.banyanApp('Unnamed Device')

  if (emitterType) {
    const emitterTypeLabel = mapApiEmitterTypeToLabel(emitterType)

    return translate.phrases.templates('{{labelA}} ({{labelB}})', {
      labelA: nameLabel,
      labelB: emitterTypeLabel,
    })
  }

  return nameLabel
}
