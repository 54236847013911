import { YAxisTitleOptions } from 'highcharts'

export const upperYAxisTitleMaker = (text: YAxisTitleOptions['text']): YAxisTitleOptions => {
  return {
    text,
    align: 'high',
    offset: 0,
    rotation: 0,
    y: -5,
    x: 0,
    textAlign: 'left',
  }
}
