import { Loader, LoadingOverlay } from '@mantine/core'
import { CurrentLocation } from 'App/Map/_utils/CurrentLocation/CurrentLocation'
import { MapContext } from 'App/Map/MapContext/MapContext'
import { NetworkStatusBanner } from 'App/ServiceCenter/NetworkStatusBanner/NetworkStatusBanner'
import { GoogleMap, GoogleMapProps } from 'components/GoogleMap/GoogleMap'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import React, { useCallback, useContext, useMemo } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapStore } from 'stores/mapStore'
import { useMapCenteredOnProperties } from 'utils/useMapCenteredOnProperties'

export const MapBase = (props: {
  enableCurrentLocation: boolean // TODO: We're only enabling the current location feature for service center. When we enable it for the whole app, we should revisit the tickets linked here https://semios.atlassian.net/browse/APPS-6378
  hasAppStartupData: boolean // TODO: Move...
  loadingData: boolean
  children: React.ReactNode
  onInit?: GoogleMapProps['onInit']
}) => {
  const properties = fieldAssetStore.useSelector((s) => s.properties)
  const { setMap, map } = useContext(MapContext)

  const mapOptions = useMemo<google.maps.MapOptions>(() => {
    const { center, zoom } = mapStore.getState()

    return {
      center,
      mapTypeId: 'satellite',
      disableDefaultUI: true,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      minZoom: 3, // looks not great, but also allows whole world at once in search results
      zoom,
      tilt: 0,
    }
  }, [])

  const onInit = useCallback(
    (map: google.maps.Map, maps: typeof google.maps) => {
      setMap(map)

      const idleEventListenerForMapStore = map.addListener('idle', () => {
        const currentZoom = map.getZoom()
        const currentBounds = map.getBounds()
        const center = map.getCenter()?.toJSON()

        if (currentZoom === undefined || currentBounds === undefined) return

        mapStore.setState((s) => ({
          ...s,
          bounds: currentBounds.toJSON(),
          center,
          zoom: currentZoom,
        }))
      })

      const destructor = props.onInit?.(map, maps)

      return () => {
        idleEventListenerForMapStore.remove()

        destructor?.()
      }
    },
    [props.onInit],
  )

  useMapCenteredOnProperties()

  return (
    <>
      {props.loadingData && (
        // This loader is from service center... it's a loader that doesn't block the user interactions... just a spinner on the map
        <Loader
          css={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}
        />
      )}
      {(props.hasAppStartupData || !isEmpty(properties)) && (
        <>
          <div css={{ flex: '1 1 auto', position: 'relative', height: '100%' }}>
            {/*TODO: ^ Why is this div wrapper needed? (was previously only on Service Center map)*/}
            <GoogleMap
              width={'100%'}
              onInit={onInit}
              height={'100%'}
              mapOptions={mapOptions}
              // TODO: errorLogger
              // eslint-disable-next-line no-console
              onError={(err) => console.log(err)}
              defaultErrorMessage={translate.phrases.banyanApp('Error loading map.')}
            />
            {props.children}
            {props.enableCurrentLocation && map && <CurrentLocation map={map} />}
          </div>
          <NetworkStatusBanner /> {/* TODO: Does this need to be outside the div wrapper? */}
        </>
      )}
      {!props.hasAppStartupData && isEmpty(properties) && (
        <div css={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }}>
          <LoadingOverlay visible={true} />
        </div>
      )}
    </>
  )
}
