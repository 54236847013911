import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { IrrigationSchedulerSettingsTabs } from './Tabs/IrrigationSchedulerSettingsTabs'
import { ItemHeader } from '../../WideScreen/ItemHeader/ItemHeader'
import { NewEmitterGroupModal } from './NewEmitterGroupModal/NewEmitterGroupModal'
import { irrigationSchedulerStore } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { SettingsCreateNewButton } from '../../components/SettingsCreateNewButton/SettingsCreateNewButton'

export const IrrigationSchedulerSettings = () => {
  const issSelectedPropertyId = irrigationSchedulerStore.useSelector((s) => s.selectedPropertyId)
  const [modalDrawerOpen, setModalDrawerOpen] = useState(false)
  const [selectedPropertyId, setSelectedPropertyId] = useState<null | number>(issSelectedPropertyId)

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ItemHeader>{translate.phrases.banyanApp('Irrigation Scheduler')}</ItemHeader>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 50,
          padding: 10,
        }}
      >
        <div>
          {translate.phrases.banyanApp(
            'All groups are attached to a specific property and are visible to everyone who has access to that property.',
          )}
        </div>
        <SettingsCreateNewButton onClick={() => setModalDrawerOpen(true)} />
      </div>
      <IrrigationSchedulerSettingsTabs
        selectedPropertyId={selectedPropertyId}
        setSelectedPropertyId={setSelectedPropertyId}
        openNewGroupModal={() => setModalDrawerOpen(true)}
      />
      <NewEmitterGroupModal
        title={translate.phrases.banyanApp('New Group')}
        primaryButtonText={translate.phrases.banyanApp('Create Group')}
        opened={modalDrawerOpen}
        onClose={() => setModalDrawerOpen(false)}
        selectedPropertyId={selectedPropertyId}
        setSelectedPropertyId={setSelectedPropertyId}
      />
    </div>
  )
}
