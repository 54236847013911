import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import { OfflineRequestError } from 'utils/errorCodes'

export interface RepositionPlannedNodesMeta {
  pseudonodes: { [plannedNodeId: number]: string }
}

export async function repositionPlannedNodes(param: { [plannedNodeId: number]: string }) {
  try {
    // Request may or may not have been completed by the time the promise resolves
    const { status } = await ApiRequestQueue.createRequest(ApiRequestType.REPOSITION_PSEUDO_NODES, {
      pseudonodes: param,
    })

    if (status === ApiRequestStatus.COMPLETED) return
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) {
      throw error
    }
  }
}
