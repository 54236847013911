import { TRGBAColorWith1AtTheEnd, colors } from 'settings/colors'

const { idealBadge, moderateDeficitBadge, extremeDeficitBadge } = colors

export const getAlternariaColorForValueAndThreshold = (
  dsv7Days: number,
  threshold: number,
): TRGBAColorWith1AtTheEnd => {
  let fillColor = null

  const riskPercentage = dsv7Days / threshold

  if (riskPercentage < 0.2) {
    fillColor = idealBadge
  } else if (riskPercentage >= 0.2 && riskPercentage < 0.8) {
    fillColor = moderateDeficitBadge
  } else {
    fillColor = extremeDeficitBadge
  }

  return fillColor
}
