import { routes } from '@semios/app-platform-banyan-route-definitions'
import { getVolumeUnit } from 'App/Map/PanelDetails/_utils/getVolumeUnit'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval, TFieldAssetKeyTypes } from 'App/Map/types'
import {
  GridTableContentSection,
  GridTableContentSectionItem,
  PossibleValuesRecord,
} from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { isEmpty, isNil } from 'lodash'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { mapApiEmitterTypeToLabel } from 'utils/mapApiEmitterTypeToLabel'
import { minutesToHoursAndMinutes } from 'utils/minutesToHoursAndMinutes'
import { sortByKey } from 'utils/sortByKey'
import { pressureSensorTimestampToValuesDictionaryMaker } from '../_utils'
import { PressureSensorsActivityTitleBarChildren } from './PressureSensorsActivityTitleBarChildren/PressureSensorsActivityTitleBarChildren'
import { getRowHeightForLabelCell } from './_utils/getRowHeightForLabelCell'
import { IRRIGATION_VOLUME_VALUE_TYPE_PREFIX, getValueTypeFromEmitterType } from '@semios/app-platform-common'
import { TFieldAssetValueTypes } from 'App/Map/types'
import { VV } from '@semios/app-platform-value-type-definitions'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_IRRIGATION_DETAILS' })

type TGenericValueType =
  VV.DomainTypes.Irrigation.TValuesReturnWithMetaIgnoringKeying['points']['irrigationVolumeDurationSemios_emitterBuriedDrip1'][number]

type TValueTypeResponse = {
  metadata: TGenericValueType['metadata']
  timeseries: Record<string, TGenericValueType['timeseries'][number]>
}

type TShapedValuesType = Record<string, TValueTypeResponse>

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!selectedValueGroups.irrigation_activity || !selectedFieldAssets.property) return {}

  const preferredAggregationInterval = {
    preferredAggregationInterval: 'DAILY' as EAggregationInterval.DAILY,
  }

  const property = fieldAssetStore.getState().properties?.[selectedFieldAssets.property]

  if (!property) return {}

  const lngLats: TFieldAssetKeyTypes.TLngLat[] = []
  const valuesRequested: Record<string, typeof preferredAggregationInterval> = {}

  Object.values(property.points || {}).forEach((point) => {
    const valueTypesForPressure = point.valuesTimeseries.filter((vt) =>
      vt.includes(IRRIGATION_VOLUME_VALUE_TYPE_PREFIX),
    )

    if (valueTypesForPressure.length > 0) {
      lngLats.push(point.lngLat)

      valueTypesForPressure.forEach((vt) => (valuesRequested[vt] = preferredAggregationInterval))
    }
  })

  if (isEmpty(lngLats) || isEmpty(valuesRequested)) return {}

  return {
    points: {
      lngLats: lngLats,
      valuesRequested,
    },
  }
}

const generateItem = ({
  id,
  values,
}: {
  id: string
  values: TValueTypeResponse
}): {
  height: GridTableContentSectionItem['height']
  id: string
  label: React.ReactNode
  labelMinWidth: GridTableContentSectionItem['labelMinWidth']
  render: GridTableContentSectionItem['render']
  valueMinWidth: GridTableContentSectionItem['valueMinWidth']
  values: Record<string, PossibleValuesRecord>
} => {
  const { metadata } = values
  const pressureSensorName = metadata.name as string
  const emitterType = metadata.emitterType
  const label = `${pressureSensorName} | ${mapApiEmitterTypeToLabel(emitterType)}`
  const height = getRowHeightForLabelCell({ label })
  const { appliedIrrigationActivityDisplay } = smallStore.getState()
  const isDuration = appliedIrrigationActivityDisplay === 'DURATION'
  const selectedFieldAssetsStorePropertyId = selectedFieldAssetsStore.getState().property
  const propertyData = fieldAssetStore.getState()?.properties?.[selectedFieldAssetsStorePropertyId as number]
  const waterDepthUnit = propertyData?.propertySettings?.waterDepthUnit
  const { rain: rainUnitFromUserSetting } = userDetailsStore.getState()
  const volumeUnit = getVolumeUnit({ waterDepthUnit, rainUnitFromUserSetting })
  const isImperial = volumeUnit === 'INCH'

  return {
    id,
    label,
    height,
    labelMinWidth: 160,
    valueMinWidth: 120,
    render: (dataPoint: { value: typeof values[keyof typeof values] }) => {
      if (!dataPoint?.value) return translate.phrases.templates('-')

      //@ts-ignore - will be resolved with better PossibleValuesRecord from API
      const { duration, volume_mm } = dataPoint.value
      const noValueDefaultReturn = translate.phrases.templates('-')

      if (isDuration) {
        if (isNil(duration)) return noValueDefaultReturn

        const durationMinutes = duration ? duration / 60000 : 0

        return minutesToHoursAndMinutes(durationMinutes)
      } else {
        const millimeters = volume_mm

        if (isNil(millimeters)) return noValueDefaultReturn

        if (isImperial) {
          const inches = millimeters / 25.4

          return translate.measurements.inches.valueWithUnit(inches, 2)
        }

        return translate.measurements.millimeters.valueWithUnit(millimeters, 2)
      }
    },
    // @ts-ignore
    values: values?.timeseries || {},
  }
}

export const content = ({
  data,
  selectedFieldAssets,
}: {
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): GridTableContentSection => {
  const title = translate.phrases.templates('{{labelA}} - {{labelB}}', {
    labelA: translate.phrases.banyanApp('Irrigation Activity by Pressure Sensors'),
    labelB: translate.phrases.banyanApp('Semios'),
  })

  const commonReturnItems = {
    title,
    titleChildren: <PressureSensorsActivityTitleBarChildren showToggle={false} />,
    id: 'summary-grid-pressureSensors',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const property = fieldAssetStore.getState().properties?.[Number(selectedFieldAssets.property)]
  const allPoints: TFieldAssetValueTypes.TPoint[] = Object.values(property?.points || {})
  const pointsForPressure = allPoints.filter((p) => !isEmpty(p.configuration.irrigationEmitterTypesAvailable))

  const values = pointsForPressure.reduce((all, point) => {
    point.configuration.irrigationEmitterTypesAvailable?.forEach((emitterType) => {
      all.push(
        pressureSensorTimestampToValuesDictionaryMaker(
          data,
          getValueTypeFromEmitterType(
            IRRIGATION_VOLUME_VALUE_TYPE_PREFIX,
            emitterType,
          ) as VV.DomainTypes.Irrigation.TTimeseriesValueTypeKeysMerged,
          point.lngLat,
        ),
      )
    })

    return all
  }, [] as ReturnType<typeof pressureSensorTimestampToValuesDictionaryMaker>[])

  const flattenedValues: TShapedValuesType = values?.flat().reduce((acc, v) => {
    if (v?.metadata?.name && v?.metadata?.emitterType) {
      acc[`${v?.metadata?.name}|||${v?.metadata?.emitterType}`] = v as unknown as TValueTypeResponse
    }

    return acc
  }, {} as TShapedValuesType)

  const gridItems = Object.entries(flattenedValues).map(([key, v]) =>
    generateItem({
      id: key,
      values: v,
    }),
  )

  return {
    title,
    titleChildren: <PressureSensorsActivityTitleBarChildren showToggle={true} />,
    id: 'summary-grid-pressureSensors',
    items: (gridItems || []).sort(sortByKey('label')),
  }
}
