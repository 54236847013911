import { translate } from 'i18n/i18n'
import { colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { ESprayRecommendation } from '../types'

export const getSprayConditionsTitleAndColor = (
  sprayCondition: ESprayRecommendation | null,
): { color: TRGBAColorWith1AtTheEnd; name: string } => {
  if (sprayCondition === 'IDEAL') {
    return {
      color: colors.idealBadge,
      name: translate.phrases.banyanApp('Ideal'),
    }
  }

  if (sprayCondition === 'AVOID') {
    return {
      color: colors.moderateDeficitBadge,
      name: translate.phrases.banyanApp('Avoid'),
    }
  }

  if (sprayCondition === 'DO_NOT') {
    return {
      color: colors.extremeDeficitBadge,
      name: translate.phrases.banyanApp('Do Not Spray'),
    }
  }

  return {
    color: colors.grey50,
    name: translate.phrases.banyanApp('Unknown'),
  }
}
