import { Switch } from '@mantine/core'
import { translate } from 'i18n/i18n'
import React, { Dispatch, SetStateAction } from 'react'
import { colors } from 'settings/colors'
import { DatePickerInput } from '@mantine/dates'
import moment from 'moment-timezone'
import { TReportStartEnd } from '../../../_utils/formTypesAndStyles'
import { checkAuthorization } from 'utils/checkAuthorization'
import { TPermission } from '@semios/app-platform-value-type-definitions'
import { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { Authorization } from 'components/Authorization/Authorization'
import { PropertiesSubtitle } from './PropertiesSubtitle'
import { getDateFromReport } from '../../../_utils/getDateFromReport'

export const CropDevelopment = ({
  chill,
  setChill,
  chillStartEnd,
  setChillStartEnd,
  pollination,
  setPollination,
  pollinationStartEnd,
  setPollinationStartEnd,
  growDegree,
  setGrowDegree,
  growDegreeStartEnd,
  setGrowDegreeStartEnd,
  selectedReportProperties,
}: {
  chill: boolean
  setChill: Dispatch<SetStateAction<boolean>>
  chillStartEnd: TReportStartEnd | null
  setChillStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  pollination: boolean
  setPollination: Dispatch<SetStateAction<boolean>>
  pollinationStartEnd: TReportStartEnd | null
  setPollinationStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  growDegree: boolean
  setGrowDegree: Dispatch<SetStateAction<boolean>>
  growDegreeStartEnd: TReportStartEnd | null
  setGrowDegreeStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  selectedReportProperties: TOptions[]
}) => {
  const propertyIds = selectedReportProperties
    ? Object.values(selectedReportProperties).map((obj) => obj.value)
    : null

  const shouldShowChill = checkAuthorization(
    (propertyIds || []).map((entity: string) => ({
      entity: Number(entity),
      permission: 'CREATE_REPORT_CHILL' as TPermission,
    })),
  )

  const shouldShowPollination = checkAuthorization(
    (propertyIds || []).map((entity: string) => ({
      entity: Number(entity),
      permission: 'CREATE_REPORT_POLLINATION' as TPermission,
    })),
  )

  const shouldShowGrowingDegreeDays = checkAuthorization(
    (propertyIds || []).map((entity: string) => ({
      entity: Number(entity),
      permission: 'CREATE_REPORT_GROWING_DEGREE_DAYS' as TPermission,
    })),
  )

  return (
    <Authorization requires={shouldShowChill || shouldShowPollination || shouldShowGrowingDegreeDays}>
      <div css={{ paddingBottom: '25px', borderBottom: `1px solid ${colors.grey200}` }}>
        <h3 css={{ fontWeight: '500' }}>{translate.phrases.banyanApp('Crop Development')}</h3>
        {shouldShowChill ? (
          <div>
            <h4>
              {translate.phrases.banyanApp('Chill')}
              {PropertiesSubtitle({
                permission: 'CREATE_REPORT_CHILL' as TPermission,
                propertyIds: propertyIds,
              })}
            </h4>
            <Switch
              size="md"
              color="lime"
              label={translate.phrases.banyanApp('Include chill summary')}
              styles={{
                label: { cursor: 'unset', fontSize: 16 },
                track: {
                  width: 36,
                  cursor: 'unset',
                  backgroundColor: chill ? `${colors.green} !important` : undefined,
                  borderColor: chill ? `${colors.green} !important` : undefined,
                },
              }}
              checked={chill}
              onChange={() => setChill(!chill)}
            />

            <div css={{ marginTop: '15px', display: 'flex' }}>
              <DatePickerInput
                valueFormat={translate.dates.getMomentFormat('MMM D')}
                clearable={false}
                placeholder={translate.phrases.banyanApp('Select start date')}
                monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                value={getDateFromReport(chillStartEnd?.startDate)}
                onChange={(e) =>
                  setChillStartEnd((prevState) => ({
                    ...prevState,
                    startDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                  }))
                }
                css={{ maxWidth: '100%', width: '240px', marginRight: '15px' }}
                disabled={!chill}
              />

              <DatePickerInput
                valueFormat={translate.dates.getMomentFormat('MMM D')}
                clearable={false}
                placeholder={translate.phrases.banyanApp('Select end date')}
                monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                value={getDateFromReport(chillStartEnd?.endDate)}
                onChange={(e) =>
                  setChillStartEnd((prevState) => ({
                    ...prevState,
                    endDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                  }))
                }
                css={{ maxWidth: '100%', width: '240px' }}
                disabled={!chill}
              />
            </div>
          </div>
        ) : null}
        {shouldShowPollination ? (
          <div>
            <h4>
              {translate.phrases.banyanApp('Pollination')}
              {PropertiesSubtitle({
                permission: 'CREATE_REPORT_POLLINATION' as TPermission,
                propertyIds: propertyIds,
              })}
            </h4>
            <Switch
              size="md"
              color="lime"
              label={translate.phrases.banyanApp('Include bee hours summary')}
              styles={{
                label: { cursor: 'unset', fontSize: 16 },
                track: {
                  width: 36,
                  cursor: 'unset',
                  backgroundColor: pollination ? `${colors.green} !important` : undefined,
                  borderColor: pollination ? `${colors.green} !important` : undefined,
                },
              }}
              checked={pollination}
              onChange={() => setPollination(!pollination)}
            />

            <div css={{ marginTop: '15px', display: 'flex' }}>
              <DatePickerInput
                valueFormat={translate.dates.getMomentFormat('MMM D')}
                clearable={false}
                placeholder={translate.phrases.banyanApp('Select start date')}
                monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                value={getDateFromReport(pollinationStartEnd?.startDate)}
                onChange={(e) =>
                  setPollinationStartEnd((prevState) => ({
                    ...prevState,
                    startDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                  }))
                }
                css={{ maxWidth: '100%', width: '240px', marginRight: '15px' }}
                disabled={!pollination}
              />

              <DatePickerInput
                valueFormat={translate.dates.getMomentFormat('MMM D')}
                clearable={false}
                placeholder={translate.phrases.banyanApp('Select end date')}
                monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                value={getDateFromReport(pollinationStartEnd?.endDate)}
                onChange={(e) =>
                  setPollinationStartEnd((prevState) => ({
                    ...prevState,
                    endDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                  }))
                }
                css={{ maxWidth: '100%', width: '240px' }}
                disabled={!pollination}
              />
            </div>
          </div>
        ) : null}
        {shouldShowGrowingDegreeDays ? (
          <div>
            <h4>
              {translate.phrases.banyanApp('Growing Degree Days')}
              {PropertiesSubtitle({
                permission: 'CREATE_REPORT_GROWING_DEGREE_DAYS' as TPermission,
                propertyIds: propertyIds,
              })}
            </h4>
            <Switch
              size="md"
              color="lime"
              label={translate.phrases.banyanApp('Include the latest data for growing degree days')}
              styles={{
                label: { cursor: 'unset', fontSize: 16 },
                track: {
                  width: 36,
                  cursor: 'unset',
                  backgroundColor: growDegree ? `${colors.green} !important` : undefined,
                  borderColor: growDegree ? `${colors.green} !important` : undefined,
                },
              }}
              checked={growDegree}
              onChange={() => setGrowDegree(!growDegree)}
            />

            <div css={{ marginTop: '15px', display: 'flex' }}>
              <DatePickerInput
                valueFormat={translate.dates.getMomentFormat('MMM D')}
                clearable={false}
                placeholder={translate.phrases.banyanApp('Select start date')}
                monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                value={getDateFromReport(growDegreeStartEnd?.startDate)}
                onChange={(e) =>
                  setGrowDegreeStartEnd((prevState) => ({
                    ...prevState,
                    startDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                  }))
                }
                css={{ maxWidth: '100%', width: '240px', marginRight: '15px' }}
                disabled={!growDegree}
              />

              <DatePickerInput
                valueFormat={translate.dates.getMomentFormat('MMM D')}
                clearable={false}
                placeholder={translate.phrases.banyanApp('Select end date')}
                monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                value={getDateFromReport(growDegreeStartEnd?.endDate)}
                onChange={(e) =>
                  setGrowDegreeStartEnd((prevState) => ({
                    ...prevState,
                    endDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                  }))
                }
                css={{ maxWidth: '100%', width: '240px' }}
                disabled={!growDegree}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Authorization>
  )
}
