import { css } from '@emotion/css'
import React, { PointerEvent } from 'react'
import CssType from 'csstype'

export default function Handle(props: {
  left: number
  top: number
  height: number
  width: number
  onPointerDown: (e: PointerEvent) => void
  style?: React.CSSProperties
  handleColor?: CssType.Property.BackgroundColor
  handleHeight: number
  handleWidth: number
}) {
  const handleColor = props.handleColor ?? '#363F4F' // '#D4D4D4',

  return (
    <div
      className={css`
        touch-action: none;
        user-select: none;
      `}
      style={{
        ...props.style,
        position: 'fixed',
        top: props.top,
        left: props.left,
        width: props.width,
        height: props.height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onPointerDown={props.onPointerDown}
    >
      <div
        className={css`
          touch-action: none;
          user-select: none;
        `}
        style={{
          width: props.handleWidth,
          height: props.handleHeight,
          backgroundColor: handleColor,
          borderRadius: 3,
        }}
      />
    </div>
  )
}
