import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Input, Select, TextInput, useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { Button } from 'components/Button/Button'
import { Footer } from '../components/Footer/Footer'
import { validateNodeIdentifier } from '../../utils/validateNodeIdentifier'
import { IconErrorCircle } from 'components/icons/IconErrorCircle'
import { TPlannedNode } from '../../types'
import { NfcScanner, isNfcSupported } from 'components/NfcScanner/NfcScanner'
import { QrScanner, isQrSupported } from 'components/QrCodeScanner/QrCodeScanner'
import { SN_NODE_TYPES } from 'App/ServiceCenter/Map/_utils/getNodeEquipmentType'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TNodeType } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { REGEXES } from 'App/ServiceCenter/utils/regexes'
import { getNodeTypeLabel } from 'App/ServiceCenter/utils/getNodeTypeLabel'
import { notifications } from '@mantine/notifications'
import { checkAuthorization } from 'utils/checkAuthorization'
import { PropertySelect } from 'components/PropertySelect/PropertySelect'
import { SharedSettings } from 'settings/SharedSettings'
import { sortByKey } from '@semios/app-platform-common'

interface ActivateNodeProps {
  onClose: () => void
  onSubmit: (param: { nodeIdentifier: string; propertyId?: string; nodeType?: TNodeType }) => Promise<void>
  pnode?: TPlannedNode
}

const SnNodeTypeSelect: React.FC<{
  nodeType: string
  setNodeType: (value: string) => void
  setError: (message: string | null) => void
}> = ({ nodeType, setNodeType, setError }) => {
  const handleNodeTypeChange = (value: string) => {
    setError(null)

    setNodeType(value)
  }

  return (
    <Input.Wrapper id="nodeType" label={translate.phrases.placeholder('Equipment Type')}>
      <Select
        value={nodeType}
        onChange={handleNodeTypeChange}
        placeholder={translate.phrases.placeholder('Equipment Type')}
        data={SN_NODE_TYPES.map((nodeType) => ({
          label: getNodeTypeLabel(nodeType.toLowerCase() as TNodeType),
          value: nodeType,
        }))}
        styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      />
    </Input.Wrapper>
  )
}

const NodeTypeField: React.FC<{
  nodeIdentifier: string
  nodeType: string
  setNodeType: (value: string) => void
  setError: (error: string | null) => void
}> = ({ nodeIdentifier, nodeType, setNodeType, setError }) => {
  const theme = useMantineTheme()

  const getNodeTypeFromNodeIdentifier = (nodeIdentifier: string) => {
    if (!nodeIdentifier) return ''

    const nodeTypeNumber = nodeIdentifier.substring(1, 3)

    let nodeType: null | TNodeType = null

    if (nodeIdentifier.startsWith('A')) {
      if (nodeTypeNumber === '01') nodeType = 'dn_trp'

      if (nodeTypeNumber === '02') nodeType = 'dn_y'
    }

    if (nodeIdentifier.startsWith('B')) {
      if (nodeTypeNumber === '03') nodeType = 'ln_scd'

      if (nodeTypeNumber === '04') nodeType = 'ln_gtwy'

      if (nodeTypeNumber === '05') {
        notifications.show({
          id: 'node-identifier-error',
          withCloseButton: true,
          autoClose: 3000,
          title: 'Error',
          message: 'This node type is not supported yet',
        })

        return null
      }

      if (nodeTypeNumber === '09') nodeType = 'ln_r'
    }

    if (nodeType) {
      setNodeType(nodeType.toUpperCase())

      return getNodeTypeLabel(nodeType)
    }

    return 'SN'
  }

  if (!nodeIdentifier) return null

  if (!REGEXES.nodeIdentifier.test(nodeIdentifier)) {
    return (
      <div css={{ color: theme.colors.red[6] }}>
        {translate.phrases.placeholder('Invalid Node Identifier')}
      </div>
    )
  }

  const nodeTypeLabel = getNodeTypeFromNodeIdentifier(nodeIdentifier)

  if (nodeTypeLabel === 'SN') {
    return <SnNodeTypeSelect setError={setError} setNodeType={setNodeType} nodeType={nodeType} />
  } else {
    return (
      <Input.Wrapper css={{ marginTop: '16px' }} id="nodeType">
        <div css={{ fontWeight: '500', marginBottom: 16 }}>
          {translate.phrases.placeholder('Equipment Type')}
        </div>
        <div>{nodeTypeLabel}</div>
      </Input.Wrapper>
    )
  }
}

const PropertyField: React.FC<{
  setPropertyId: (value: string) => void
  propertyId?: string
  propertiesSelectOptions: { label: string; value: string; disabled: boolean }[]
}> = ({ setPropertyId, propertyId, propertiesSelectOptions }) => {
  return (
    <div style={{ position: 'relative', marginBottom: '24px' }}>
      {propertiesSelectOptions.length === 1 ? (
        <Input.Wrapper>
          <span css={{ display: 'inline-block', marginRight: 8 }}>
            {translate.phrases.placeholder('Property -')}
          </span>
          {propertiesSelectOptions[0].label}
        </Input.Wrapper>
      ) : (
        <Input.Wrapper id="property" label={translate.phrases.placeholder('Property')}>
          <PropertySelect
            clearable={false}
            data={propertiesSelectOptions}
            onChange={(value) => setPropertyId(value.toString())}
            selectedPropertyId={propertyId ? parseInt(propertyId) : null}
          />
        </Input.Wrapper>
      )}
    </div>
  )
}

export const ActivateNode: React.FC<ActivateNodeProps> = ({ onClose, onSubmit, pnode }) => {
  const theme = useMantineTheme()
  const selectedProperty = selectedFieldAssetsStore.getState().property
  const [nodeIdentifier, setNodeIdentifier] = useState('')
  const [nodeType, setNodeType] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [shouldScanQr, setShouldScanQr] = useState(false)
  const [isSupportScan, setIsSupportScan] = useState(false)
  const [shouldScanNfc, setShouldScanNfc] = useState(false)
  const [isSupportNfc, setIsSupportNfc] = useState(false)

  const properties: { [propertyId: number]: { propertyName: string; propertyId: number } } =
    fieldAssetStore.useSelector((s) => s?.properties ?? {})

  const propertiesSelectOptions = Object.values(properties)
    .map(({ propertyName, propertyId }) => {
      const isInstallableProperty = checkAuthorization({
        permission: 'EDIT_SSC_INSTALL_NODE',
        entity: propertyId,
      })

      return {
        label: propertyName,
        value: propertyId.toString(),
        disabled: !isInstallableProperty,
      }
    })
    .sort(sortByKey('label'))

  const defaultSelectedPropertyId = useMemo(() => {
    let defaultSelectedPropertyId = null

    // if selected proerty is installable, set it to default value
    propertiesSelectOptions.forEach(({ value, disabled }) => {
      if (selectedProperty && selectedProperty.toString() === value && !disabled) {
        defaultSelectedPropertyId = value
      }
    })

    if (defaultSelectedPropertyId) return defaultSelectedPropertyId

    // if selected property is not installable, set first installable property to default
    return propertiesSelectOptions.find(({ disabled }) => !disabled)?.value
  }, [selectedProperty])

  const [propertyId, setPropertyId] = useState(defaultSelectedPropertyId)

  const handleScanNfc = (args: { value?: string; error?: string }) => {
    setShouldScanNfc(false)

    if (args.error) {
      setError(args.error)
    } else if (args.value) {
      setNodeIdentifier(args.value)

      setError(null)
    }
  }

  const handleScanQr = (args: { value?: string; error?: string }) => {
    setShouldScanQr(false)

    if (args.error) {
      setError(args.error)
    } else if (args.value) {
      setNodeIdentifier(args.value)

      setError(null)
    }
  }

  const handleNodeIdentifierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setError(null)

    setNodeType('')

    setNodeIdentifier(value.toUpperCase())
  }

  const handleNodeTypeChange = (value: string) => {
    setNodeType(value)
  }

  const handleSubmit = async () => {
    setError(null)

    if (!nodeIdentifier) {
      setError(translate.phrases.placeholder('Please enter a node identifier'))

      return
    }

    if (!pnode && !nodeType) {
      setError(translate.phrases.placeholder('Please select equipment type'))

      return
    }

    if (!nodeIdentifier || (pnode && !validateNodeIdentifier(pnode?.nodeType, nodeIdentifier))) {
      setError(
        translate.phrases.placeholder(
          'The node identifier that you have entered is invalid. Please try again.',
        ),
      )

      return
    }

    try {
      setIsSaving(true)

      await onSubmit({ nodeIdentifier, nodeType: nodeType as TNodeType, propertyId })

      onClose()
    } catch (error) {
      setIsSaving(false)

      setError((error as Error).message)
    }
  }

  useEffect(() => {
    const checkSupportScan = async () => {
      setIsSupportScan(await isQrSupported())

      setIsSupportNfc(await isNfcSupported())
    }

    checkSupportScan()
  }, [])

  return (
    <div css={{ padding: 10 }}>
      <h3 css={{ margin: 0, marginBottom: 16 }}>{translate.phrases.placeholder('Scan Equipment')}</h3>
      {!pnode && (
        <PropertyField
          propertyId={propertyId}
          setPropertyId={setPropertyId}
          propertiesSelectOptions={propertiesSelectOptions}
        />
      )}

      {isSupportNfc && (
        <>
          <p css={{ lineHeight: '24px', marginTop: 10, marginBottom: 20 }}>
            {translate.phrases.placeholder(
              'Scan the NFC tag located on the bottom of the node or manually enter the serial number below.',
            )}
          </p>
          {shouldScanNfc && (
            <NfcScanner
              onScan={(prop: { value?: string; error?: string }) => {
                handleScanNfc(prop)
              }}
            ></NfcScanner>
          )}
          <Button
            variant="primary"
            css={{ margin: '10px 0', display: 'block', width: '100%' }}
            onClick={() => setShouldScanNfc(true)}
          >
            {shouldScanNfc
              ? translate.phrases.placeholder('Scanning...')
              : translate.phrases.placeholder('Scan Node')}
          </Button>
        </>
      )}

      {isSupportScan && (
        <>
          <p css={{ lineHeight: '24px', marginBottom: 20 }}>
            {translate.phrases.placeholder(
              'Scan the QR Code located on the bottom of the node or manually enter the serial number below.',
            )}
          </p>
          {shouldScanQr && (
            <QrScanner
              onScan={(prop: { value?: string; error?: string }) => {
                handleScanQr(prop)
              }}
            />
          )}
          <Button
            variant="primary"
            css={{ margin: '10px auto', display: 'block', width: '100%' }}
            onClick={() => {
              setShouldScanQr(true)
            }}
          >
            {translate.phrases.placeholder('Scan QR code')}
          </Button>
        </>
      )}

      {!!error && (
        <Alert
          icon={
            <span css={{ color: theme.colors.red[0] }}>
              <IconErrorCircle />
            </span>
          }
          styles={{
            root: { backgroundColor: theme.colors.midnight[0] },
            message: { color: theme.colors.white[0] },
          }}
          css={{ marginTop: 20 }}
        >
          {error}
        </Alert>
      )}

      <Input.Wrapper
        id="nodeIdentifier"
        label={translate.phrases.placeholder('Node Identifier')}
        css={{ marginTop: 20 }}
      >
        <TextInput
          css={{ margin: '10px auto', display: 'block' }}
          onChange={handleNodeIdentifierChange}
          value={nodeIdentifier}
          placeholder={translate.phrases.placeholder('Enter Node Identifier')}
        />
      </Input.Wrapper>

      {!pnode && (
        <NodeTypeField
          setError={setError}
          setNodeType={handleNodeTypeChange}
          nodeIdentifier={nodeIdentifier}
          nodeType={nodeType}
        />
      )}

      <Footer
        onNext={handleSubmit}
        loading={isSaving}
        nextButtonLabel={translate.phrases.placeholder('Submit')}
        disableNextButton={!pnode && !nodeIdentifier && !nodeType}
      />
    </div>
  )
}
