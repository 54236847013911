import { css } from '@emotion/css'
import { Anchor, useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { FC, useState } from 'react'
import { ChangePassword } from './updateUserDetailsRelatedForms/ChangePassword'

export const ChangePasswordSection: FC = () => {
  const [showChangePassword, setShowChangePassword] = useState(false)
  const theme = useMantineTheme()

  return (
    <div
      className={css`
        margin: 20px 0;
        padding-bottom: 40px;
        z-index: 1;
      `}
    >
      <div
        className={css`
          font-weight: 700;
        `}
      >
        {translate.phrases.banyanApp('Change Password')}
      </div>
      {showChangePassword ? (
        <ChangePassword />
      ) : (
        <Anchor>
          <div
            className={css`
              margin: 20px 0 0 5px;
              color: ${theme.colors.primary[0]};
              font-size: 14px;
              text-decoration: underline;
              font-weight: 700;
            `}
            onClick={() => {
              if (setShowChangePassword) setShowChangePassword(true)
            }}
          >
            {translate.phrases.banyanApp('Update Password')}
          </div>
        </Anchor>
      )}
    </div>
  )
}
