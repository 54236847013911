import { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import type { StackedChartSection, StackedChartSectionItem } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { upperYAxisTitleMaker } from 'utils/upperYAxisTitleMaker'
import { checkPermission, SoilValueType } from './soil'
import { getColSize } from './_utils/getColSize'
import { makeHeatmapSeries } from './_utils/makeHeatmapSeries'
import { soilIonicContentStops } from './_utils/soilIonicContentStops'
import { vicHeatmapColorAxisFormatter } from './_utils/vicHeatmapColorAxisFormatter'
import { VV } from '@semios/app-platform-value-type-definitions'

const possibleValueTypes: VV.DomainTypes.Soil.TTimeseriesValueTypeKeysMerged[] = [
  'soilMoisture',
  'soilIonicContent',
]

const pointCategory = 'soilPoint'

export const ionicContent = ({
  data,
  soilStationLngLat,
}: {
  data: routes.Values.Response
  soilStationLngLat: string | null
}): StackedChartSection => {
  const commonReturnItems = {
    title: translate.phrases.banyanApp('Salt Map'),
    titleChildren: (
      <DropdownSelectorPoint pointCategory={pointCategory} valuesTimeseriesToFilterOn={possibleValueTypes} />
    ),
    id: 'stackem-soil-ionic-content',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { soilMoisture, soilIonicContent } = data?.points?.[String(soilStationLngLat)]?.values || {}

  const mappedCategories = soilMoisture
    ?.sort((a, b) => Number(a.metadata.depth) - Number(b.metadata.depth))
    .map(({ metadata: { depth } }) => unitConverter.soilDepth(Number(depth)).value() || 0)

  const mappedIonicContent = (
    soilIonicContent?.flatMap(
      ({ timeseries }: { timeseries: { timestamp: string; value?: SoilValueType }[] }) =>
        timeseries.map(({ value }) => {
          if (isNil(value)) {
            return null
          } else if (typeof value === 'number') {
            return value
          } else if (typeof value === 'object') {
            return value?.average
          } else {
            return null
          }
        }),
    ) ?? []
  ).filter((value) => !!value) as number[]

  const soilIonicContentData =
    makeHeatmapSeries({ data, valueType: 'soilIonicContent', soilStationLngLat }) ?? []

  // @ts-ignore TS is angry at my colorAxis formatter
  const soilIonicContentHeatmapChart = {
    chartConfig: {
      semiosHighchartsAdditions: {
        id: commonReturnItems.id,
        firstForecastTimestamp: +new Date(),
      },
      colorAxis: {
        labels: { formatter: vicHeatmapColorAxisFormatter },
        max: mappedIonicContent ? Math.max(...mappedIonicContent) : 0,
        min: mappedIonicContent ? Math.min(...mappedIonicContent) : 0,
        stops: soilIonicContentStops,
      },
      chart: {
        type: 'heatmap',
      },
      series: [
        {
          id: 'Soil-VIC-Heatmap-series',
          data: soilIonicContentData,
          colsize: getColSize(),
          type: 'heatmap',
          visible: true,
        },
      ],
      tooltip: {
        enabled: false,
      },
      yAxis: {
        categories: mappedCategories,
        min: 0,
        max: mappedCategories && mappedCategories.length - 1,
        title: upperYAxisTitleMaker(unitConverter.soilDepth().categoryTitle()),
      },
    },
  } as StackedChartSectionItem

  return {
    ...commonReturnItems,
    items: [soilIonicContentHeatmapChart],
  }
}
