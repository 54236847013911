import React, { useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import { HelpGuideCard } from '../Card/Card'
import { getNodeTypeLabel } from '../../utils/getNodeTypeLabel'
import { TNodeType, HelpGuideSection } from '../../types'

interface HelpGuideListProps {
  expandedCard?: string
  onSelectGuide(type: 'node' | 'device', equipmentType: string, section: HelpGuideSection): void
}

export const HelpGuideList: React.FC<HelpGuideListProps> = ({
  expandedCard: initialExpandedCard = null,
  onSelectGuide,
}) => {
  const [expandedCard, setExpandedCard] = useState<string | null>(initialExpandedCard)
  const allDevices = fieldAssetStore.useSelector((s) => s.devices)
  const helpGuides = serviceCenterStore.useSelector(serviceCenterStore.selectors.getHelpGuides)

  const toggleCard = (guideKey: string) => {
    if (expandedCard === guideKey) {
      setExpandedCard(null)
    } else {
      setExpandedCard(guideKey)
    }
  }

  return (
    <div css={{ padding: 10 }}>
      {Object.keys(helpGuides.nodes).map((nodeType) => {
        const guideKey = 'node-' + nodeType

        return (
          <HelpGuideCard
            key={guideKey}
            title={getNodeTypeLabel(nodeType as TNodeType)}
            sections={Object.keys(helpGuides.nodes[nodeType]) as HelpGuideSection[]}
            expanded={expandedCard === guideKey}
            onToggleExpand={() => toggleCard(guideKey)}
            onSelectGuide={(section) => onSelectGuide('node', nodeType, section)}
          />
        )
      })}

      {Object.keys(helpGuides.devices).map((deviceSource) => {
        const guideKey = 'device-' + deviceSource
        const device = allDevices[deviceSource]

        return (
          <HelpGuideCard
            key={guideKey}
            title={device?.name || deviceSource}
            sections={Object.keys(helpGuides.devices[deviceSource]) as HelpGuideSection[]}
            expanded={expandedCard === guideKey}
            onToggleExpand={() => toggleCard(guideKey)}
            onSelectGuide={(section) => onSelectGuide('device', deviceSource, section)}
          />
        )
      })}
    </div>
  )
}
