import { TNodeDevices, TNodeServiceIssueSummary, TNodeType } from '../types'
import { getNodeTypeLabel } from './getNodeTypeLabel'

export function getServiceIssueTitle(
  node: { nodeType: TNodeType; devices?: TNodeDevices },
  serviceIssue: TNodeServiceIssueSummary,
): string {
  if (serviceIssue.device) {
    const deviceInfo = serviceIssue.device.split('|').map((item) => item.trim())
    const source = deviceInfo[0].replace('_', '-')

    return `${source} - ${serviceIssue.nodeServiceIssueTypeTitle}`
  } else {
    const nodeTypeLabel = getNodeTypeLabel(node.nodeType)

    return `${nodeTypeLabel} - ${serviceIssue.nodeServiceIssueTypeTitle}`
  }
}
