import React from 'react'
import { useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TNodeDevice, TNodeType } from 'App/ServiceCenter/types'

export const InstallOverview: React.FC<{ nodeDevices?: TNodeDevice[]; nodeType: TNodeType | null }> = ({
  nodeDevices,
  nodeType,
}) => {
  const theme = useMantineTheme()
  const allDevices = fieldAssetStore.useSelector((s) => s.devices)

  return (
    <div
      css={{
        backgroundColor: theme.colors.grey[0],
        border: `1px solid ${theme.colors.grey[1]}`,
        borderRadius: 3,
        padding: 20,
      }}
    >
      <h3 css={{ margin: '0 0 20px' }}>{translate.phrases.placeholder('Overview')}</h3>

      <h4 css={{ margin: '0 0 10px' }}>{translate.phrases.placeholder('Equipment Type')}</h4>
      <p css={{ margin: 0 }}>{nodeType ? nodeType : translate.phrases.placeholder('N/A')}</p>

      {!!nodeDevices?.length && (
        <>
          <h4 css={{ marginBottom: 10, fontWeight: 500 }}>{translate.phrases.placeholder('Devices')}</h4>
          <div css={{ lineHeight: '24px' }}>
            {nodeDevices.map((device) => (
              <div key={`${device.connector}-${device.channel}`}>
                {allDevices[device.source]?.name || device.source}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
