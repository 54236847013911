import { routes } from '@semios/app-platform-banyan-route-definitions'
import { VIEW_PESTS_DEGREE_DAYS_ID_insectId } from '@semios/app-platform-value-type-definitions'
import { DropdownSelectorBlock } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorBlock/DropdownSelectorBlock'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { StackedChartPestSection, TChartSeries } from 'components/StackedChart/types'
import moment from 'moment-timezone'
import { colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import {
  selectedValueGroupsStore,
  TValueGroup,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { generateBiofixPlotLinesAndSeries } from './generateBiofixPlotLinesAndSeries'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { EAggregationInterval } from 'App/Map/types'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { INSECT_DEGREE_DAY_VALUE_KEY_PREFIX } from 'utils/insectRequestValueKeyPrefix'
import type { TPestSectionCategory } from 'App/Map/PanelDetails/_utils/sortPestSections'

const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY } as const

const getValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_DEGREE_DAY_VALUE_KEY_PREFIX}${insectId}` as VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}) => {
  if (!selectedFieldAssets.block) return {}

  const valuesRequested: Partial<
    Record<
      VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  > = {}

  Object.entries(selectedValueGroups).forEach(([valueGroup, isActive]) => {
    if (!!isActive && valueGroup.includes('degree_days_insect_id_')) {
      const insectId = Number(valueGroup.split('_').slice(-1)[0])

      if (!selectedPropertyHasPermission({ permission: VIEW_PESTS_DEGREE_DAYS_ID_insectId(insectId) })) return

      valuesRequested[getValueTypeIdForInsectId(insectId)] = preferredAggregationInterval
    }
  })

  if (Object.keys(valuesRequested).length === 0) return {}

  const valuesToBeRequested: Partial<routes.Values.Request> = {}

  if (
    Object.keys(valuesRequested).length &&
    doesSelectedBlockHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })
  )
    valuesToBeRequested.blocks = {
      blockIds: [selectedFieldAssets.block],
      valuesRequested,
    }

  return valuesToBeRequested
}

export const content = ({
  data,
  selectedValueGroups,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}): StackedChartPestSection[] => {
  const insects = fieldAssetStore.getState()?.insects

  if (!insects) return []

  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const blockId = String(selectedFieldAssetsStore.getState().block)

  const timezone =
    fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssetsStore.getState()?.property)]
      ?.timezone ?? moment.tz.guess()

  const { dateFrom, dateTo } = detailsPanelStore.getState()

  const degreeDaysContent: StackedChartPestSection[] = Object.values(insects)
    .filter((insect) => selectedValueGroups[`degree_days_insect_id_${insect.insectId}` as TValueGroup])
    // TODO: sort based on t() of insect
    // TODO: rename to insectName
    .sort(sortByKey('name'))
    .map(({ insectId }) => {
      const hasPermission = selectedPropertyHasPermission({
        permission: VIEW_PESTS_DEGREE_DAYS_ID_insectId(insectId),
      })

      const valueTypeKey = getValueTypeIdForInsectId(insectId)
      const pestSectionCategory: TPestSectionCategory = 'degree-days'

      const commonReturnItems = {
        title: unitConverter.insectDegreeDaysCumulative(null, { insectId }).titleWithoutUnit(),
        titleChildren: <DropdownSelectorBlock valuesTimeseriesToFilterOn={[valueTypeKey]} />,
        id: `insect-degree-days-insect-id-${insectId}-${blockId}`,
        pestSectionCategory,
        insectId,
      }

      if (!hasPermission) {
        return { ...propertyLacksPermissionSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      if (!doesSelectedBlockHaveValueTypes({ valuesTimeseries: [valueTypeKey] })) {
        return { ...commonReturnItems, defaultCollapsed: true, items: [] }
      }

      const series: TChartSeries[] = []

      const insectDegreeDaysCumulativeSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
        color: colors.idealSaturatedBoundary,
        name: unitConverter.insectDegreeDaysCumulative().categoryTitleWithoutUnit(),
        id: `${INSECT_DEGREE_DAY_VALUE_KEY_PREFIX}${insectId}`,
        tooltip: {
          valueDecimals: unitConverter.insectDegreeDaysCumulative().defaultNumberOfDecimalPlaces(),
          valueSuffix: ` ${unitConverter.insectDegreeDaysCumulative().suffix()}`,
        },
        yAxis: 0,
        data: (data?.blocks?.[blockId]?.values?.[valueTypeKey]?.[0]?.timeseries ?? []).map(
          (d: { timestamp: string; value: number | null }) => [
            +new Date(d.timestamp),
            unitConverter.insectDegreeDaysCumulative(d.value).value(),
          ],
        ),
        type: 'line',
        marker: {
          enabled: false,
        },
      }

      series.push(insectDegreeDaysCumulativeSeries)

      if (compareSeasonsInterval) {
        series.push(
          makeCompareSeasonsSeriesFromRegularSeries(insectDegreeDaysCumulativeSeries, {
            data: (compareSeasonsData?.blocks?.[blockId]?.values?.[valueTypeKey]?.[0]?.timeseries ?? []).map(
              (d: { timestamp: string; value: number | null }) => [
                +new Date(d.timestamp),
                unitConverter.insectDegreeDaysCumulative(d.value).value(),
              ],
            ),
          }),
        )
      }

      const { biofixSeriesForAddingToggle, biofixPlotLines } = generateBiofixPlotLinesAndSeries({
        biofixDates: {
          baseSeason: data?.blocks?.[blockId]?.values?.[valueTypeKey]?.[0]?.metadata?.biofixDates ?? [],
          comparisonSeason: !compareSeasonsInterval
            ? []
            : compareSeasonsData?.blocks?.[blockId]?.values?.[valueTypeKey]?.[0]?.metadata?.biofixDates ?? [],
        },
        dateFrom,
        dateTo,
        intervalForCompareSeasonsOffset: compareSeasonsInterval,
        timezone,
      })

      if (biofixSeriesForAddingToggle) series.push(biofixSeriesForAddingToggle)

      const forecastStartsAt =
        data?.blocks?.[blockId]?.values?.[valueTypeKey]?.[0]?.metadata?.forecastStartsAt

      let firstForecastTimestamp: number = +new Date()

      if (forecastStartsAt) {
        firstForecastTimestamp = +moment.tz(forecastStartsAt, timezone).startOf('day')
      }

      return {
        ...commonReturnItems,
        items: [
          {
            chartConfig: {
              semiosHighchartsAdditions: {
                id: unitConverter.insectDegreeDaysCumulative(null, { insectId }).titleWithoutUnit(),
                firstForecastTimestamp,
              },
              chart: {
                type: 'line',
              },
              tooltip: {
                xDateFormat: getXDateFormat(),
              },
              xAxis: {
                plotLines: biofixPlotLines,
              },
              series,
            },
          },
        ],
      }
    })

  return degreeDaysContent
}
