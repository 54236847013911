import { routes } from '@semios/app-platform-banyan-route-definitions'
import { SoilAverageDepthsTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/SoilAverageDepthsTitleChildren/SoilAverageDepthsTitleChildren'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import type { StackedChartSection, TChartSeries } from 'components/StackedChart/types'
import { AxisLabelsFormatterContextObject } from 'highcharts'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { checkPermission } from './soil'
import { soilPlotOptions } from './soilPlotOptions'
import { AwcLegendItems } from './_utils/AwcLegendItems'
import { makeAWCZones } from './_utils/awcZones'
import { checkIfSoilChartHasData } from './_utils/checkIfSoilChartHasData'
import { getAwcChartMinMax } from './_utils/getAwcChartMinMax'
import { makeSoilSeries, SOIL_MOISTURE_COLORS_ARRAY } from './_utils/makeSoilSeries'
import { EAggregationInterval } from 'App/Map/types'
import { VV } from '@semios/app-platform-value-type-definitions'

const possibleValueTypes: VV.DomainTypes.Soil.TTimeseriesValueTypeKeysMerged[] = ['soilMoisture']

// TODO: At the moment we are only making line series with the average value. We are not yet making area series out of the min/max values.
export const awcLine = ({
  data,
  soilStationLngLat,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  soilStationLngLat: string | null
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.soilAwc().categoryTitleWithoutUnit(),
    titleChildren: (
      <SoilAverageDepthsTitleChildren
        showAverageToggle={false}
        valuesTimeseriesToFilterOn={possibleValueTypes}
      />
    ),
    id: 'stackem-soil-awc-line',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { soilMoisture } = data?.points?.[String(soilStationLngLat)]?.values ?? {}

  const chartHasSomeData =
    checkIfSoilChartHasData(soilMoisture) ||
    checkIfSoilChartHasData(compareSeasonsData?.points?.[String(soilStationLngLat)]?.values?.soilMoisture)

  const aggregationInterval = soilMoisture?.[0]?.metadata?.aggregationInterval
  const { compareSeasonsInterval } = detailsPanelStore.getState()

  const awcZones =
    compareSeasonsInterval === 0 && soilMoisture?.[0]
      ? makeAWCZones({ awcData: soilMoisture })
      : {
          zonesAWCValues: [],
          linesAWCValues: [],
        }

  const soilAwcLineData = makeSoilSeries({
    data,
    compareSeasonsData,
    soilStationLngLat,
    valueType: 'soilMoisture',
  })

  const { yAxisMinToUse, yAxisMaxToUse } = getAwcChartMinMax({
    compareSeasonsData,
    data,
    soilStationLngLat,
  })

  const soilAwcLineSeries =
    awcZones?.zonesAWCValues && soilAwcLineData.length > 0
      ? ([
          ...soilAwcLineData,
          ...Object.values(awcZones.zonesAWCValues),
          ...Object.values(awcZones.linesAWCValues),
        ] as TChartSeries[])
      : []

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp: +new Date(),
          },
          chart: {
            type: 'line',
            height: 380,
          },
          colors: SOIL_MOISTURE_COLORS_ARRAY,
          series: chartHasSomeData ? soilAwcLineSeries : [],
          plotOptions: soilPlotOptions,
          tooltip: { xDateFormat: getXDateFormat(aggregationInterval === EAggregationInterval.DAILY) },
          yAxis: {
            min: yAxisMinToUse,
            max: yAxisMaxToUse,
            tickInterval: 10,
            labels: {
              formatter: function (this: AxisLabelsFormatterContextObject) {
                if (this.value === 0 || this.value === 50 || this.value === 100 || this.value === 150) {
                  return `${this.value}`
                } else {
                  return ''
                }
              },
            },
            startOnTick: false,
            endOnTick: false,
          },
        },
        childrenLower: awcZones?.zonesAWCValues && (
          <AwcLegendItems legendItems={Object.entries(awcZones.zonesAWCValues)} />
        ),
      },
    ],
    titleChildren: (
      <SoilAverageDepthsTitleChildren showAverageToggle valuesTimeseriesToFilterOn={possibleValueTypes} />
    ),
  }
}
