import React, { Dispatch, FC, SetStateAction } from 'react'
import { ExpandableChevronIndicator } from 'components/ExpandableChevronIndicator/ExpandableChevronIndicator'
import { IconMapControls } from 'components/icons/IconMapControls'
import { alphaColorReplacer, colors } from 'settings/colors'
import { CSSObject } from '@emotion/css'
import { useViewportSizeWithCorrectInitialState } from '../../utils/useViewportSizeWithCorrectInitialState'
import { useKeyboardVisible } from 'utils/useKeyboardVisible'

interface NarrowScreenPanelProps {
  children?: React.ReactNode
  expandedContent?: React.ReactNode
  isExpanded: boolean
  setIsExpanded: Dispatch<SetStateAction<boolean>>
  style?: React.CSSProperties | CSSObject
}

export const NarrowScreenPanel: FC<NarrowScreenPanelProps> = ({
  children,
  expandedContent,
  isExpanded,
  setIsExpanded,
  style: panelStyle,
}) => {
  const viewportSize = useViewportSizeWithCorrectInitialState()
  const { isKeyboardVisible } = useKeyboardVisible()

  const visibleHeight = isKeyboardVisible
    ? viewportSize.height
    : `calc(${viewportSize.height}px - env(safe-area-inset-bottom))`

  return (
    <div
      css={{
        width: viewportSize.width,
        height: visibleHeight,
        position: 'fixed',
        top: 0,
        pointerEvents: 'none',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          height: 'fit-content',
          position: 'absolute',
          bottom: 0,
          zIndex: 2,
          pointerEvents: 'auto',
          ...panelStyle,
        }}
      >
        <div
          css={{
            backgroundColor: alphaColorReplacer(colors.midnight, 0.65),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '14px 7px 14px 10px',
            minWidth: 0,
            position: 'relative',
          }}
        >
          <div css={{ minWidth: 0 }}>
            {!isExpanded ? (
              <div
                css={{
                  color: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  fontSize: 14,
                }}
                onClick={() => setIsExpanded((s) => !s)}
              >
                <div css={{ fontSize: 15 }}>
                  <IconMapControls />
                </div>
                <div css={{ marginLeft: 12, fontSize: 14 }}>
                  <ExpandableChevronIndicator expanded={isExpanded} expandDirection="expand-rightwards" />
                </div>
              </div>
            ) : (
              <>
                {children}
                {expandedContent}
              </>
            )}
          </div>
        </div>
        {isExpanded && (
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: '0 0 auto',
              width: 40,
              backgroundColor: colors.midnight,
              color: 'white',
              cursor: 'pointer',
            }}
            onClick={() => setIsExpanded((s) => !s)}
          >
            <ExpandableChevronIndicator expanded={isExpanded} expandDirection="expand-rightwards" />
          </div>
        )}
      </div>
    </div>
  )
}
