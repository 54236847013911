import { TPhrases } from '@semios/app-platform-i18n-core'
import { almondCultivarsSelector } from 'App/Map/_utils/almondCultivarsSelector'
import { translate } from 'i18n/i18n'
import { Fragment } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { useScreenSize } from 'utils/useScreenSize'
import {
  AlertTypeId,
  Rule,
  TAlertRuleKey,
  unitTempDisplay,
  unitTempDisplayDD,
} from '../../../settings/alertSettings'

const ruleLabel = (alertRuleKey: TAlertRuleKey) => {
  switch (alertRuleKey) {
    case 'alternaria':
      return translate.phrases.banyanApp('Alternaria DSV')

    case 'aboveTemperature':
      return translate.phrases.banyanApp('Above Canopy Temperature')

    case 'belowTemperature':
      return translate.phrases.banyanApp('Ground Temperature')

    case 'degreeDays':
      return translate.phrases.banyanApp('Degree Days')

    case 'degreeDaysForecast':
      return translate.phrases.banyanApp('Degree Days Forecast')

    case 'dewPoint':
      return translate.phrases.banyanApp('Dew Point')

    case 'fireBlight':
      return translate.phrases.banyanApp('Fire Blight TRV')

    case 'fireBlightForecast':
      return translate.phrases.banyanApp('Fire Blight TRV Forecast')

    case 'cultivarId':
      return translate.phrases.banyanApp('Variety')

    case 'daysBefore':
      return translate.phrases.banyanApp('Days Before')

    case 'hours':
      return translate.phrases.banyanApp('Hours')

    case 'includeAllDevices':
      return translate.phrases.banyanApp('Include all devices')

    case 'inversion':
      return translate.phrases.banyanApp('Inversion')

    case 'leafWetness':
      return translate.phrases.banyanApp('Leaf Wetness %')

    case 'numberOfDays':
      return translate.phrases.banyanApp('Within Number of Days')

    case 'pestType':
      return translate.phrases.banyanApp('Pest Type')

    case 'startDate':
      return translate.phrases.banyanApp('Starting On Date')

    case 'temperature':
      return translate.phrases.banyanApp('Temperature')

    case 'trapCatches':
      return translate.phrases.banyanApp('Cumulative Trap Catches')

    case 'wetBulb':
      return translate.phrases.banyanApp('Wet Bulb')

    default:
      return ''
  }
}

export const Separator = () => <div css={{ borderLeft: '1px solid black', margin: '0 5px' }} />

export const RulesRow = ({
  rules,
  alertTypeId,
  insectId,
}: {
  rules: Rule[]
  alertTypeId: AlertTypeId
  insectId?: number
}) => {
  const pestTypes = fieldAssetStore.useSelector((s) => s.insects)
  const almondCultivars = fieldAssetStore.useSelector(almondCultivarsSelector)
  const { isWideScreen } = useScreenSize()

  const rulesRow = rules
    .map((rule, index) => {
      let extraOperator = ''

      // don't render the windSpeed or windDirection in frost alerts—they're not configurable settings
      if (['frost', 'wetBulb'].includes(alertTypeId) && ['windSpeed', 'windDirection'].includes(rule.key))
        return null

      if (rule.unit && ['temperature', 'inversion'].includes(rule.unit))
        extraOperator = ` ${unitTempDisplay()}`

      if (['degreeDays', 'degreeDaysForecast'].includes(rule.key)) extraOperator = ` ${unitTempDisplayDD()}`

      // almondHullSplit cultivars have to have their names looked up
      if (rule.key === 'cultivarId') {
        return (
          <div key={rule.key} css={{ margin: 0, textAlign: 'left' }}>
            {ruleLabel(rule.key)}&nbsp;
            {rule.operator}&nbsp;
            {typeof rule.value === 'string' &&
              rule.value.split(',').map((cultivarId, i) => {
                // split the string of cultivars on commas, since there can be multiple
                const foundCultivar = almondCultivars.find((c) => c.id === cultivarId)

                if (foundCultivar)
                  return (
                    <Fragment key={foundCultivar.id}>
                      {i ? ', ' : null}
                      {foundCultivar.name}
                    </Fragment>
                  )

                return null
              })}
          </div>
        )
      }

      return (
        <div
          key={rule.key}
          css={{
            margin: 0,
            textAlign: 'left',
            display: 'flex',
            flexDirection: isWideScreen ? 'row' : 'column',
          }}
        >
          {index > 0 && isWideScreen && <Separator />}
          {ruleLabel(rule.key)}&nbsp;
          {rule.operator}&nbsp;
          {rule.value}
          {extraOperator}
        </div>
      )
    })
    .filter((r) => r)

  /**
   * insectId is essentially a rule, though it's a top-level attribute
   * of an alert, so we need to add a rule into the list to help us out
   */
  let extraStuff = null

  if (['degreeDays', 'trapCatches'].includes(alertTypeId) && insectId && pestTypes?.[insectId]) {
    extraStuff = (
      <div css={{ textAlign: 'left', display: 'flex', flexDirection: isWideScreen ? 'row' : 'column' }}>
        {isWideScreen && <Separator />}
        {translate.phrases.banyanApp('For the pest {{pestType}}', {
          pestType: translate.phrases.dbInsectName(pestTypes[insectId].name as TPhrases['dbInsectName']),
        })}
      </div>
    )
  }

  return (
    <div css={{ display: 'flex', flexDirection: isWideScreen ? 'row' : 'column', flexWrap: 'wrap' }}>
      {rulesRow}
      {extraStuff}
    </div>
  )
}
