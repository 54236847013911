import moment from 'moment-timezone'
import { SharedSettings } from 'settings/SharedSettings'
import { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'

export const getMaxDateForValueGroup = ({
  timezone,
  valueGroup,
}: {
  timezone: string
  valueGroup: TValueGroup
}): moment.Moment => {
  if (valueGroup === 'fruit_growth') {
    // fruit growth potentially has long-range forecasts
    return moment.tz(timezone).add(365, 'days').endOf('day')
  }

  if (valueGroup === 'soil') {
    // at present, soil value types have no forecast values
    return moment.tz(timezone).add(0, 'days').endOf('day')
  }

  // otherwise return our default forecast range
  return moment.tz(timezone).add(SharedSettings.DEFAULT_FORECAST_DAYS, 'days').endOf('day')
}
