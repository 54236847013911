import { FC } from 'react'
import { Checkbox as MantineCheckbox, CheckboxProps, MantineTheme } from '@mantine/core'

export const Checkbox: FC<CheckboxProps> = (props) => {
  return (
    <MantineCheckbox
      size="sm"
      styles={(theme: MantineTheme) => {
        return {
          root: {
            input: {
              // TODO: this might not be necessary after IrrigationScheduler's Mantine fixings
              'cursor': 'pointer',
              'backgroundColor': theme.colors.grey[1],
              'borderColor': theme.colors.grey[1],
              'boxShadow': 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
              '&:checked': {
                backgroundColor: theme.colors.primary[0],
                borderColor: theme.colors.primary[0],
              },
              '&:disabled': {
                backgroundColor: theme.colors.grey[1],
                borderColor: theme.colors.grey[1],
              },
            },
          },
          label: {
            cursor: 'pointer',
            color: theme.colors.midnight[0],
          },
        }
      }}
      {...props}
    />
  )
}
