import React, { useState } from 'react'
import { css } from '@emotion/css'
import { Radio } from 'components/Radio/Radio'

export const RadioExamples = () => {
  const radioLabelText = 'Radio Button Label'
  const [radioValue, setRadioValue] = useState('1')

  return (
    <>
      {/* Radio Component Examples */}

      <div
        className={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 2rem 1rem;
        `}
      >
        <Radio label={radioLabelText} value={radioValue} onChange={() => setRadioValue('1')} />
        <Radio label={radioLabelText} value={radioValue} onChange={() => setRadioValue('2')} checked />
        <Radio label={radioLabelText} value={radioValue} onChange={() => setRadioValue('3')} disabled />
      </div>
    </>
  )
}
