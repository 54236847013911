import React from 'react'
import { translate } from 'i18n/i18n'
import { closeModal, openModal } from '@mantine/modals'
import { useMantineTheme } from '@mantine/core'
import { serviceCenterStore } from '../store/serviceCenterStore'
import { HelpGuideSection, InstallationStep, TActiveNode, TNodeType, TPlannedNode } from '../types'
import { openNodeInstallationModal } from './InstallationModal'
import { installationWorkflowStore } from '../store/installationWorkflowStore'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { Button } from 'components/Button/Button'
import { IconHelpCircle } from 'components/icons/IconHelpCircle'
import { openHelpGuideModal } from '../HelpGuideModal/HelpGuideModal'
import { IconSuccess } from '../../../components/icons/IconSuccess'
import { Footer } from './components/Footer/Footer'
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary'
import { InstallOverview } from './components/Overview/InstallOverview'
import { AddDeviceButton } from './components/AddDeviceButton/AddDeviceButton'

export const INSTALLATION_OVERVIEW_MODAL_ID = 'node-installation-overview'

export function openNodeInstallationOverviewModal() {
  openModal({
    modalId: INSTALLATION_OVERVIEW_MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: (
      <ErrorBoundary>
        <InstallationOverviewModal />
      </ErrorBoundary>
    ),
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

export const InstallationOverviewModal: React.FC = () => {
  const theme = useMantineTheme()
  const selectedNode = serviceCenterStore.useSelector(serviceCenterStore.selectors.getSelectedEquipmentNode)

  const nodeDevices = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentNodeDevicesAsArray,
  )

  const nodeType = (selectedNode as TActiveNode | TPlannedNode)?.nodeType.toUpperCase() as TNodeType

  const installationSteps = installationWorkflowStore.useSelector(
    installationWorkflowStore.selectors.getSteps,
  )

  const handleShowInstallationStep = (stepNumber: number) => {
    installationWorkflowStore.actions.setCurrentStep(stepNumber)

    openNodeInstallationModal()
  }

  const handleClose = () => {
    closeModal(INSTALLATION_OVERVIEW_MODAL_ID)

    installationWorkflowStore.actions.reset()
  }

  const reviewStep = installationSteps.find(
    ({ type, completed }) =>
      [InstallationStep.CHECKLIST, InstallationStep.REVIEW].includes(type) && !completed,
  )

  const nextStep = installationSteps.find(({ completed }) => !completed)

  return (
    <>
      <WideHeader
        title={translate.phrases.placeholder('Install Station')}
        rightIconButton={
          nodeType && (
            <button
              onClick={() =>
                openHelpGuideModal({
                  type: 'node',
                  equipmentType: nodeType.toUpperCase(),
                  section: HelpGuideSection.INSTALLATION,
                })
              }
              css={{
                width: 30,
                height: 30,
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                backgroundColor: 'transparent',
                border: 'none',
              }}
            >
              <span css={{ fontSize: 20, color: theme.colors.midnight[0] }}>
                <IconHelpCircle />
              </span>
            </button>
          )
        }
        onClose={handleClose}
        isSecondaryModal={false}
      />

      <div css={{ padding: 10, paddingBottom: 0, marginBottom: 62, overflowY: 'auto' }}>
        <InstallOverview nodeType={nodeType} nodeDevices={nodeDevices} />

        <h3 css={{ margin: '20px 10px', display: 'flex', justifyContent: 'space-between' }}>
          {translate.phrases.placeholder('Installation Steps')}
          {selectedNode && <AddDeviceButton nodeType={nodeType} selectedNode={selectedNode} />}
        </h3>

        {installationSteps
          .filter(({ type }) => ![InstallationStep.CHECKLIST, InstallationStep.REVIEW].includes(type))
          .map((step, index) => (
            <Button
              key={step.title}
              variant="tertiary"
              size="lg"
              css={{ display: 'block', width: '100%', marginBottom: 10 }}
              onClick={() => handleShowInstallationStep(index)}
              disabled={!step.enabled}
              rightIcon={
                step.completed && (
                  <span css={{ color: theme.colors.green[0] }}>
                    <IconSuccess />
                  </span>
                )
              }
            >
              {step.title}
            </Button>
          ))}

        {!!reviewStep && (
          <Button
            key="review"
            variant="secondary"
            size="lg"
            css={{ display: 'block', width: '100%', marginBottom: 10 }}
            onClick={() => handleShowInstallationStep(installationSteps.indexOf(reviewStep))}
            disabled={!reviewStep.enabled}
          >
            {translate.phrases.placeholder('Review')}
          </Button>
        )}
      </div>

      <Footer
        onPrevious={handleClose}
        onNext={() => nextStep && handleShowInstallationStep(installationSteps.indexOf(nextStep))}
        nextButtonLabel={translate.phrases.placeholder('Continue')}
        showPreviousButton={true}
        disableNextButton={!nextStep}
      />
    </>
  )
}
