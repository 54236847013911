import * as Sentry from '@sentry/react'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { translate } from 'i18n/i18n'

export const mapApiEmitterTypeToLabel = (emitterType: TFieldAssetKeyTypes.TEmitterType) => {
  switch (emitterType) {
    case 'emitter-buried-drip-1':
      return translate.phrases.emitterTypes('Buried Drip 1')

    case 'emitter-buried-drip-2':
      return translate.phrases.emitterTypes('Buried Drip 2')

    case 'emitter-drip-1':
      return translate.phrases.emitterTypes('Drip 1')

    case 'emitter-drip-2':
      return translate.phrases.emitterTypes('Drip 2')

    case 'emitter-microspray':
      return translate.phrases.emitterTypes('Microspray')

    case 'emitter-sprinkler':
      return translate.phrases.emitterTypes('Sprinkler')

    case 'emitter-flood-furrow':
      return translate.phrases.emitterTypes('Flood Furrow')

    case 'emitter-overhead-cooling-microspray':
      return translate.phrases.emitterTypes('Overhead Cooling Microspray')

    case 'emitter-overhead-cooling-sprinkler':
      return translate.phrases.emitterTypes('Overhead Cooling Sprinkler')

    case 'emitter-other':
      return translate.phrases.emitterTypes('Other')

    case 'pump':
      return translate.phrases.emitterTypes('Pump')

    default: {
      Sentry.captureException(`${JSON.stringify({ emitterType })} has not been implemented`)

      return emitterType
    }
  }
}
