import { TFieldAssetKeyTypes, TFieldAssetValueTypes } from 'App/Map/types'
import polylabel from 'polylabel'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { getArgsForAnchorCoordinates } from '../getArgsForAnchorCoordinates'

export type TSetByArg = { block: TFieldAssetKeyTypes.TBlockId }

export const getAnchorCoordinatesForBlock = (
  block: TFieldAssetKeyTypes.TBlockId,
  property: TFieldAssetValueTypes.TProperty,
) => {
  if (!property || !property?.blocks?.[block]) {
    return null
  }

  const { coordinates } = JSON.parse(property.blocks[block].geometry)
  const [lng, lat] = polylabel([coordinates[0]], 1.0)
  const anchorCoordinates = { lng, lat }

  return anchorCoordinates
}

export const setBy = ({ block }: TSetByArg): TSelectedFieldAssetsStoreState | null => {
  const allProperties = fieldAssetStore.getState()?.properties

  const property = Object.values(allProperties ?? {}).find((propertyData) => {
    return !!propertyData?.blocks?.[block]
  })

  if (!property) return null

  const anchorCoordinates = getAnchorCoordinatesForBlock(block, property)

  if (!anchorCoordinates) return null

  return getArgsForAnchorCoordinates({ anchorCoordinates, property, args: { block } })
}
