import { routeRequiresAuth } from './routeRequiresAuth'
import { unauthenticateAppState } from './unauthenaticateAppState'

export enum FailedRequestReason {
  OFFLINE = 'OFFLINE',
  TIMEOUT = 'TIMEOUT',
  SERVER_ERROR = 'SERVER_ERROR',
  UNKNOWN = 'UNKNOWN',
}

export class OfflineRequestError extends Error {
  constructor(reason: FailedRequestReason) {
    super(reason)

    this.name = 'OfflineRequestError'
  }
}

export class ApiResponseError extends Error {
  statusCode: number
  retryable: boolean

  constructor(message: string, statusCode: number, retryable: boolean) {
    super(message)

    this.statusCode = statusCode

    this.retryable = retryable
  }
}

export const errorCodes = async (res: Response) => {
  const { status } = res

  let error

  switch (true) {
    case status === 204:
      return

    case status >= 200 && status <= 399:
      return await res.json()

    case status === 401:
      if (routeRequiresAuth()) await unauthenticateAppState()

      error = await res.json()

      throw new ApiResponseError(error?.message, status, true)

    case status === 403:
      error = await res.json()

      throw new ApiResponseError(error.message || error.error, status, false)

    case status === 404:
      throw new ApiResponseError('Not Found', status, false)

    case status === 409:
      error = await res.json()

      throw new ApiResponseError(error.message, status, false)

    case status >= 400 || status < 500: {
      error = await res.json()

      const message = error?.message || 'Bad request'
      const retryable = error?.retryable || false

      throw new ApiResponseError(message, status, retryable)
    }

    case status >= 500: {
      error = await res.json()

      const message = error?.message || 'Server Error. Try Again Later'
      const retryable = error?.retryable || true

      throw new ApiResponseError(message, status, retryable)
    }

    default:
      // eslint-disable-next-line no-console
      console.log('Unknown error', res)

      throw new ApiResponseError('Network Error. Try Again Later', status, true)
  }
}
