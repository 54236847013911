import { MapContext } from 'App/Map/MapContext/MapContext'
import React, { useContext, useEffect, useMemo } from 'react'
import turfBBox from '@turf/bbox'
import { Feature, featureCollection, Polygon } from '@turf/helpers'
import { TFieldAssetValueTypes } from '../../../Map/types'
import { mapStore } from 'stores/mapStore'
import { BlockRenderer } from './renderers/BlockRenderer'

interface BlockLayerProps {
  blocks: TFieldAssetValueTypes.TBlock[]
}

export const BlockLayer: React.FC<BlockLayerProps> = ({ blocks }) => {
  const { map } = useContext(MapContext)

  const shapedBlocks = useMemo(() => {
    if (!blocks) return []

    return blocks.map((block) => ({
      id: block.blockId.toString(),
      geometry: JSON.parse(block.geometry) as Polygon,
    }))
  }, [blocks])

  // Fit map to blocks if bounds are not set yet
  useEffect(() => {
    if (mapStore.getState().bounds) return

    const bbox = turfBBox(
      featureCollection(
        shapedBlocks.map((block) => ({ type: 'Feature', geometry: block.geometry } as Feature<Polygon>)),
      ),
    )

    const propertyBounds = new google.maps.LatLngBounds({
      east: bbox[2], // maxX
      north: bbox[3], // maxY
      south: bbox[1], // minY
      west: bbox[0], // minX
    })

    map?.fitBounds(propertyBounds)
  }, [shapedBlocks])

  return <BlockRenderer blocks={shapedBlocks} />
}
