import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapApiScheduledEventStatusToTScheduledEventEnum } from '../../mapApiScheduledEventStatusToTScheduledEventEnum'
import { irrigationSchedulerStore } from '../irrigationSchedulerStore'
import { getSelectedPropertyTimezoneForIrrigationScheduler } from '../selectors/getSelectedPropertyTimezoneForIrrigationScheduler'

export const eventsCreate = (apiEvents: Array<SharedTypes.IrrigationScheduledEventOutput>) => {
  irrigationSchedulerStore.setState((isss) => {
    const updatedScheduledEvents = apiEvents.reduce(
      (acc, apiEvent) => {
        // TODO: this type assertion is because of zod z.string()
        const irrigationZoneEmitterId =
          apiEvent.irrigationZoneEmitterId as TFieldAssetKeyTypes.TIrrigationZoneEmitterId

        const eventId = apiEvent.eventId.toString()
        const timezone = getSelectedPropertyTimezoneForIrrigationScheduler(fieldAssetStore.getState(), isss)

        return {
          ...acc,
          [eventId]: {
            dateFrom: moment.tz(apiEvent.dateFrom, timezone),
            dateTo: moment.tz(apiEvent.dateTo, timezone),
            emitterType: apiEvent.emitterType,
            irrigationZoneEmitterId,
            irrigationZoneId: apiEvent.irrigationZoneId.toString(),
            eventId: eventId,
            type: mapApiScheduledEventStatusToTScheduledEventEnum(apiEvent.status),
          },
        }
      },
      { ...isss.scheduledEvents },
    )

    return {
      ...isss,
      scheduledEvents: updatedScheduledEvents,
    }
  })
}
