import React, { ReactNode } from 'react'

export const ChildContent = (props: {
  leftIcon: ReactNode
  content: ReactNode
  rightIcon: ReactNode
  fullWidth?: boolean
}) => {
  return (
    <div
      css={{
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        flex: '1 1 auto',
        justifyContent: props.fullWidth ? 'space-between' : 'flex-start',
      }}
    >
      {props.leftIcon ? <div>{props.leftIcon}</div> : null}
      <div>{props.content}</div>
      {props.rightIcon ? <div>{props.rightIcon}</div> : null}
    </div>
  )
}
