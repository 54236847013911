import { isNil } from 'lodash'
import { colors } from 'settings/colors'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { TBlockSCDsValueTypesToPropsDictionary } from '../BlockScdsValuesCache'
import { DataSourceOverlay } from '../../DataSourceOverlay/DataSourceOverlay'

export type TBlockSCDComponentProps = {
  blockId: number
  valueTypesToPropsDictionary: TBlockSCDsValueTypesToPropsDictionary
}

export const BlockSCDComponent = (props: TBlockSCDComponentProps) => {
  const primaryValueGroup = usePrimaryValueGroup()

  const { vt } = mapControlsStore.useSelector(({ mapVisualValueGroup, ...rest }) => ({
    vt: !primaryValueGroup ? null : mapVisualValueGroup.SCD?.[primaryValueGroup]?.valueType,
    rest,
  }))

  if (isNil(vt)) return null

  // don't bother rendering SCDs without values
  if (isNil(props.valueTypesToPropsDictionary?.[vt])) return null

  return (
    <div
      css={{
        fontSize: 12,
        fontWeight: 'bold',
        width: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        border: '1px solid white',
        color: colors.midnight,
        ...props.valueTypesToPropsDictionary?.[vt]?.getContainerCSS?.(),
      }}
      onClick={() => setSelectedFieldAsset({ block: props.blockId })}
    >
      {props.valueTypesToPropsDictionary?.[vt]?.shouldAddDataSourceOverlay && (
        <DataSourceOverlay overlayCSS={{ width: '100%', height: '100%', borderRadius: '50%' }} />
      )}
      {props.valueTypesToPropsDictionary?.[vt]?.children}
    </div>
  )
}
