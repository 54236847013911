import { ReactNode } from 'react'
import { colors } from 'settings/colors'

export const SettingsFooterContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      css={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: 8,
        borderTop: `1px solid ${colors.grey200}`,
        backgroundColor: colors.grey50,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  )
}
