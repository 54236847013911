import { GridTableColumn, GridTableContentSection } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'

export const createColumns = ({
  content,
  dateFrom,
  dateTo,
  idForTodayColumn,
  timezone,
}: {
  content: GridTableContentSection[]
  dateFrom: string
  dateTo: string
  idForTodayColumn: string
  timezone: string
}): GridTableColumn[] => {
  if (!content.some((s) => !!s.items?.length)) return []

  const dateFromMoment = moment.tz(dateFrom, timezone)
  const dateToMoment = moment.tz(dateTo, timezone)
  const todayForTimezone = moment.tz(timezone)
  const totalDays = dateToMoment.diff(dateFromMoment, 'days') + 1

  return Array.from({ length: totalDays }, (__, i) => dateFromMoment.clone().add(i, 'days')).map((d) => {
    const isoStringForDay = d.toISOString()
    const isToday = d.isSame(todayForTimezone, 'day')
    const isAfterToday = d.isAfter(todayForTimezone, 'day')

    let valueBackgroundColor = '#ffffff'

    let title: React.ReactNode = (
      <span css={{ lineHeight: '22px' }}>
        <span css={{ fontSize: 12 }}>{translate.dates.format(d, 'ddd').toUpperCase()}</span>
        <br />
        <span css={{ fontSize: 14 }}>{translate.dates.format(d, 'MMM D')}</span>
      </span>
    )

    if (isToday) {
      title = (
        <strong id={idForTodayColumn} css={{ lineHeight: '22px' }}>
          <span css={{ fontSize: 12 }}>{translate.phrases.banyanApp('TODAY')}</span>
          <br />
          <span css={{ fontSize: 14 }}>{translate.dates.format(d, 'MMM D')}</span>
        </strong>
      )

      valueBackgroundColor = '#e5f6fa'
    }

    if (isAfterToday) valueBackgroundColor = '#f3f3f3'

    return {
      dataIndex: isoStringForDay,
      key: isoStringForDay,
      title,
      minWidth: 130,
      valueCellCSS: { backgroundColor: valueBackgroundColor },
    }
  })
}
