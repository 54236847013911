import React, { useEffect, useState } from 'react'
import { GalleryPhoto, Photo } from '@capacitor/camera'
import { IconCamera } from 'components/icons/IconCamera'
import { useMantineTheme } from '@mantine/core'

interface ImageWithPlaceholderProps {
  image?: Photo | GalleryPhoto | string | null
  icon?: React.ReactNode
}

const HEADER_FOOTER_HEIGHT = 380
const HORIZONTAL_PADDING = 30

export const ImageWithPlaceholder: React.FC<ImageWithPlaceholderProps> = ({ image, icon }) => {
  const theme = useMantineTheme()

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const { height: displayHeight, width: displayWidth } = windowSize
  const imageMaximumHeight = displayHeight - HEADER_FOOTER_HEIGHT

  let imageSquareSize = `${imageMaximumHeight}px`

  if (imageMaximumHeight > displayWidth - HORIZONTAL_PADDING) imageSquareSize = '100%'

  return (
    <div
      css={{
        aspectRatio: '1 / 1',
        backgroundColor: image ? 'transparent' : theme.colors.grey[1],
        position: 'relative',
        width: imageSquareSize,
        height: imageSquareSize,
        marginRight: '10%',
        marginLeft: '10%',
        margin: 'auto',
      }}
    >
      {image ? (
        <img
          src={typeof image === 'string' ? image : (image as Photo).dataUrl || image.webPath}
          css={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
        />
      ) : (
        <div
          css={{
            width: 150,
            height: 150,
            fontSize: '150px',
            transform: 'translate(-50%, -50%)',
            margin: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        >
          {icon || <IconCamera />}
        </div>
      )}
    </div>
  )
}
