import { Capacitor } from '@capacitor/core'
import { ScreenOrientation, ScreenOrientationResult } from '@capacitor/screen-orientation'
import { CurrentTabSelectionType } from 'App/Map/UserSettingsMenu/types'
import { SafeAreaInsets } from 'capacitor-plugin-safe-area/dist/esm/definitions'
import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'
import { useScreenSize } from 'utils/useScreenSize'

const storeName = 'smallStore'

export type TAppliedIrrigationActivityDisplayOption = 'DURATION' | 'VOLUME'

export type TMobileScreenOrientationType = 'landscape' | 'portrait' | null

export type TSmallStore = {
  appliedIrrigationActivityDisplay: TAppliedIrrigationActivityDisplayOption
  enableAdvancedMapControls: boolean
  safeArea: SafeAreaInsets
  mobileScreenOrientation: TMobileScreenOrientationType //notApplicable is for web
  selectedPreset: string | null
  showSettingsModal: boolean
  showViewDetails: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  windMachineDetailsData: any
  showWmDetailsModal: boolean
  showWeatherWidgetModal: boolean
  settingsModalSelectedTab: CurrentTabSelectionType
  showDataSource: boolean
  showDataSourceTooltip: boolean
  showQRScannerModal: boolean
}

export const smallStore = persistentInit<TSmallStore>({
  initialState: {
    appliedIrrigationActivityDisplay: 'DURATION',
    enableAdvancedMapControls: false,
    safeArea: { insets: { top: 0, left: 0, bottom: 0, right: 0 } },
    mobileScreenOrientation: null,
    selectedPreset: null,
    settingsModalSelectedTab: 'mainMenu',
    showSettingsModal: false,
    showViewDetails: false,
    showWmDetailsModal: false,
    windMachineDetailsData: {},
    showWeatherWidgetModal: false,
    showDataSource: true,
    showDataSourceTooltip: false,
    showQRScannerModal: false,
  },
  keysToPutInPersistentStorage: {
    appliedIrrigationActivityDisplay: true,
    enableAdvancedMapControls: true,
    safeArea: false,
    mobileScreenOrientation: false,
    selectedPreset: true,
    settingsModalSelectedTab: false,
    showSettingsModal: true,
    showWmDetailsModal: true,
    showViewDetails: true,
    windMachineDetailsData: true,
    showWeatherWidgetModal: true,
    showDataSource: true,
    showDataSourceTooltip: false,
    showQRScannerModal: false,
  },
  keysToPutInURL: {
    appliedIrrigationActivityDisplay: false,
    enableAdvancedMapControls: false,
    safeArea: false,
    mobileScreenOrientation: false,
    selectedPreset: true,
    settingsModalSelectedTab: false,
    showSettingsModal: false,
    showWmDetailsModal: false,
    showViewDetails: false,
    windMachineDetailsData: false,
    showWeatherWidgetModal: false,
    showDataSource: false,
    showDataSourceTooltip: false,
    showQRScannerModal: false,
  },
  storeName,
})

function getDeviceScreenOrientationType(orientation: ScreenOrientationResult): TMobileScreenOrientationType {
  if (!Capacitor.isNativePlatform()) return null
  else if (orientation.type.match(/landscape/)) return 'landscape'
  else if (orientation.type.match(/portrait/)) return 'portrait'
  else return null
}

ScreenOrientation.addListener('screenOrientationChange', (orientation: ScreenOrientationResult) => {
  smallStore.setState((s) => ({
    ...s,
    mobileScreenOrientation: getDeviceScreenOrientationType(orientation),
  }))
})

export const initDeviceScreenOrientation = async () => {
  const orientation = await ScreenOrientation.orientation()

  smallStore.setState((s) => ({
    ...s,
    mobileScreenOrientation: getDeviceScreenOrientationType(orientation),
  }))
}

/**
 * Hook to get the screen orientation, if it's a mobile device, it will return the device's screen orientation
 * @returns 'landscape' | 'portrait'
 */
export const useScreenOrientation = () => {
  const mobileScreenOrientation = smallStore.useSelector((s) => s.mobileScreenOrientation)
  const screenSize = useScreenSize()

  if (mobileScreenOrientation === null) {
    //web or desktop
    return screenSize.screenWidth > screenSize.screenHeight ? 'landscape' : 'portrait'
  } else {
    return mobileScreenOrientation
  }
}
