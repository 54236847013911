import { Point } from '@turf/helpers'
import { TActiveGateway, TActiveNode, TDnNodeLog, TNodeLog, TLnSCDLog } from '../../types'
import { getNodeNetworkType } from '../../utils/getNodeNetworkType'
import { parse as parseWKT } from 'wellknown'

// Returns the actual location of a node (DN & LN), based on its last log GPS coordinates (or install location if no log is available)
export function getNodeActualLocation(node: TActiveNode | TActiveGateway, lastLog?: TNodeLog | null): Point {
  const networkType = getNodeNetworkType(node)

  if (lastLog) {
    if (networkType === 'DN') {
      const { gpsCoordinates } = lastLog as TDnNodeLog

      if (gpsCoordinates) return parseWKT(gpsCoordinates) as Point
    } else if (networkType === 'LN') {
      const { geom: gpsCoordinates } = lastLog as TLnSCDLog

      if (gpsCoordinates) return parseWKT(gpsCoordinates) as Point
    }
  }

  return JSON.parse(node.location as string) as Point
}
