import { Switch } from '@mantine/core'
import { getZoomThresholds, ZOOM_VISIBILITY } from 'App/Map/CurrentValuesMap/_utils/zoomVisibility'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { mapStore } from 'stores/mapStore'
import { fadeInOutStylesMaker } from 'utils/fadeInOutStylesMaker'

export const AggregateSwitch = ({
  includeAggregateSwitch,
  displayAsHeatmapSwitch,
}: {
  includeAggregateSwitch: boolean
  displayAsHeatmapSwitch?: boolean
}) => {
  const zoom = mapStore.useSelector((s) => s.zoom)

  const isChecked = mapControlsStore.useSelector((s) =>
    displayAsHeatmapSwitch ? !s.useAggregation : s.useAggregation,
  )

  const { SCD } = getZoomThresholds(zoom)
  const somethingAggregatableInView = SCD === ZOOM_VISIBILITY.OK

  const label = displayAsHeatmapSwitch
    ? translate.phrases.banyanApp('Heatmap Points')
    : translate.phrases.banyanApp('Aggregate')

  return (
    <Switch
      css={fadeInOutStylesMaker(includeAggregateSwitch && somethingAggregatableInView)}
      label={label}
      checked={isChecked}
      onChange={(e) =>
        mapControlsStore.setState((s) => ({
          ...s,
          useAggregation: displayAsHeatmapSwitch ? !e.currentTarget.checked : e.currentTarget.checked,
        }))
      }
      size="xs"
      styles={{
        label: {
          color: 'white',
          cursor: somethingAggregatableInView ? 'pointer' : 'unset',
          fontSize: 13,
          paddingLeft: '0.5rem',
        },
        track: {
          cursor: somethingAggregatableInView ? 'pointer' : 'unset',
          backgroundColor: isChecked ? `${colors.green} !important` : undefined,
          borderColor: isChecked ? `${colors.green} !important` : undefined,
        },
      }}
    />
  )
}
