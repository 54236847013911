import { fieldAssetStore } from 'stores/fieldAssetStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { TScheduledEventEnum, irrigationSchedulerStore } from '../irrigationSchedulerStore'
import { getDeletableEvents } from '../selectors/getDeletableEvents'

export const selectScheduledEventsForBatchDelete = (
  scheduledEventType: TScheduledEventEnum.SCHEDULED | TScheduledEventEnum.DRAFT_CREATE,
) => {
  irrigationSchedulerStore.setState((isss) => {
    const events = { ...isss.batchDelete.events }

    const { deletableEvents, deletablePulseEvents } = getDeletableEvents(
      fieldAssetStore.getState(),
      isss,
      userDetailsStore.getState(),
    )

    deletableEvents.forEach((event) => {
      if (event.type === scheduledEventType) {
        events[event.eventId] = { ...event, type: scheduledEventType }
      }
    })

    deletablePulseEvents.forEach((event) => {
      if (event.type === scheduledEventType) {
        events[event.pulseEventId] = { ...event, type: scheduledEventType }
      }
    })

    return {
      ...isss,
      batchDelete: {
        ...isss.batchDelete,
        events: events,
      },
    }
  })
}
