import { TNodeType } from 'App/ServiceCenter/types'

export const BATTERY_THRESHOLDS: { [key in TNodeType]?: number } = {
  trp: 2.6,
  scd: 2.3,
  rpt: 2.5,
  y: 2.5,
  dn_trp: 2.7,
  dn_y: 2.7,
  // ln_r: 2.7,
}
