import { IconAndTitle } from 'components/SideNav/IconAndTitle/IconAndTitle'
import { generateNavigationMenu } from '../../generateNavigationMenu/generateNavigationMenu'
import { Checkbox } from './Checkbox/Checkbox'

export const SectionLayerItem = ({
  item,
}: {
  item: ReturnType<typeof generateNavigationMenu>[number]['children'][number]
}) => {
  const hasToggle = 'onToggleOn' in item
  const checked = hasToggle && !!item.checked

  return (
    <IconAndTitle
      icon={<Checkbox checked={checked} disabled={!hasToggle} />}
      key={item.key}
      onClick={() => {
        if (!hasToggle) return null

        if (item.checked) return item.onToggleOff()

        return item.onToggleOn()
      }}
      subtitle
      title={item.label ?? item.translatedTitle}
    />
  )
}
