import { Select } from '@mantine/core'
import {
  TSoilCurrentValueMapOption,
  TSoilCurrentValueMapOptionSingleDepth,
} from 'App/Map/CurrentValuesMap/_utils/by-domain/soil'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL, TValuesCurrentPointsValueTypes } from 'stores/mapControlsStore/types'
import { userDetailsStore } from 'stores/userDetailsStore'
import { alphabeticalSort } from 'utils/alphabeticalSort'
import { removeCmOrInFromSoilProbeDepths } from 'utils/removeCmOrInFromSoilProbeDepths'
import { unitConverter } from 'utils/unitConverter/unitConverter'

const updateMapVisuals = (newValueType: TValuesCurrentPointsValueTypes) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.POINT]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.POINT],
        soil: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.POINT].soil ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
    },
  }))
}

export const SoilSecondaryDropdown = () => {
  const depthUnit = userDetailsStore.useSelector((s) => s.depth)

  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.POINT]?.soil?.valueType,
  )

  const properties = fieldAssetStore.useSelector((s) => s.properties)

  const data: { value: TSoilCurrentValueMapOption; label: string; group: string }[] = useMemo(() => {
    const allAvailableProbeDepths = new Set<TSoilCurrentValueMapOptionSingleDepth>()

    Object.values(properties ?? {}).forEach((p) => {
      if (!p.points) return

      Object.values(p.points).forEach((ss) => {
        if (!ss.configuration.soilProbeDepthsAvailable) return

        ss.configuration.soilProbeDepthsAvailable.forEach((pd) => allAvailableProbeDepths.add(pd))
      })
    })

    const returner: { value: TSoilCurrentValueMapOption; label: string; group: string }[] = [
      {
        value: 'DEFAULT',
        label: translate.phrases.banyanApp('Default Depths'),
        group: translate.phrases.banyanApp('Multiple Depths'),
      },
      {
        value: 'ALL_EXCEPT_SHALLOWEST',
        label: translate.phrases.banyanApp('All Depths'),
        group: translate.phrases.banyanApp('Multiple Depths'),
      },
    ]

    const probeDepthsArray = [...allAvailableProbeDepths].sort(alphabeticalSort)

    probeDepthsArray.forEach((pd) => {
      returner.push({
        value: pd,
        label: unitConverter.soilDepth(removeCmOrInFromSoilProbeDepths(pd)).valueWithSuffix(),
        group: translate.phrases.banyanApp('Individual Depths (Where Available)'),
      })
    })

    return returner
  }, [depthUnit, properties, value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
