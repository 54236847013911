import { routes } from '@semios/app-platform-banyan-route-definitions'
import { generateDefaultHeatmapHighLowColors } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateUsualStyleAPIArgs } from './_utils/generateUsualStyleAPIArgs'
import { generateUsualStyleGetCacheUpdatesFromResponse } from './_utils/generateUsualStyleGetCacheUpdatesFromResponse'
import { getValueType } from './_utils/getValueType'
import { isEmpty } from '@semios/app-platform-common'
import { apiFetch } from 'utils/apiFetch'

const valueGroup = 'chill'
const blockValueKey = 'median_value'
const heatmapExtremesForAllPropertiesInVisibleRegions = true

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  return generateUsualStyleAPIArgs({
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
    },
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.POINT, valueGroup),
    },
    property: {
      valueType: getValueType(MAP_VISUAL.PROPERTY, valueGroup),
    },
  })
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const response = await apiFetch<routes.ValuesCurrent.Request, routes.ValuesCurrent.Response>({
    url: routes.ValuesCurrent.path,
    body: args,
  })

  return generateUsualStyleGetCacheUpdatesFromResponse({
    cacheKeys,
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    response,
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
      unitConverterFunction: String(getValueType(MAP_VISUAL.BLOCK, valueGroup))
        .toLowerCase()
        .includes('portions')
        ? unitConverter.chillCumulativePortionsSEP
        : unitConverter.chillCumulativeHoursBelow45FSEP,
      valueKey: blockValueKey,
    },
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.POINT, valueGroup),
      unitConverterFunction: String(getValueType(MAP_VISUAL.POINT, valueGroup))
        .toLowerCase()
        .includes('portions')
        ? unitConverter.chillCumulativePortionsSEP
        : unitConverter.chillCumulativeHoursBelow45FSEP,
    },
    property: {
      valueType: getValueType(MAP_VISUAL.PROPERTY, valueGroup),
      unitConverterFunction: String(getValueType(MAP_VISUAL.PROPERTY, valueGroup))
        .toLowerCase()
        .includes('portions')
        ? unitConverter.chillCumulativePortionsSEP
        : unitConverter.chillCumulativeHoursBelow45FSEP,
    },
    heatmapColoring: generateDefaultHeatmapHighLowColors().reverse(),
  })
}
