import { TServiceCenterStore } from '../serviceCenterStore'
import { AnyNode, TActiveGateway, TActiveNode, TNodeLog, TPlannedNode } from '../../types'
import { flatNodeDevices } from 'App/ServiceCenter/utils/flatNodeDevices'

export const selectors = {
  getMapEquipmentStatuses: (s: TServiceCenterStore) => s.mapEquipmentStatuses,
  getMapEquipmentTypes: (s: TServiceCenterStore) => s.mapEquipmentTypes,
  getMapEquipmentDevices: (s: TServiceCenterStore) => s.mapEquipmentDevices,
  getCombinedMapEquipmentTypesAndDevices: (s: TServiceCenterStore) => [
    ...s.mapEquipmentTypes,
    ...s.mapEquipmentDevices,
  ],
  getPlannedNodes: (s: TServiceCenterStore) => s.plannedNodes,
  getActiveNodes: (s: TServiceCenterStore) => s.activeNodes,
  getPlannedAndActiveNodes: (s: TServiceCenterStore) => [...s.plannedNodes, ...s.activeNodes],
  getSelectedEquipmentId: (s: TServiceCenterStore) =>
    s.selectedPlannedEquipmentIds[0] || s.selectedActiveEquipmentIds[0],
  getSelectedEquipmentNode: (s: TServiceCenterStore): AnyNode | null => {
    if (s.selectedPlannedEquipmentIds[0]) {
      return (
        s.plannedNodes.find((node) => (node as TPlannedNode).id === s.selectedPlannedEquipmentIds[0]) || null
      )
    } else if (s.selectedActiveEquipmentIds[0]) {
      return (
        s.activeNodes.find(
          (node) =>
            ((node as TActiveNode).nodeIdentifier || (node as TActiveGateway).gatewayIdentifier) ===
            s.selectedActiveEquipmentIds[0],
        ) || null
      )
    } else return null
  },
  getSelectedEquipmentNodeDevicesAsArray: (s: TServiceCenterStore) => {
    const selectedNode = selectors.getSelectedEquipmentNode(s) as TActiveNode

    return selectedNode && selectedNode.devices ? flatNodeDevices(selectedNode.devices) : []
  },
  getNodeLogs: (s: TServiceCenterStore) => s.nodeLogs,
  getNodeStatuses: (s: TServiceCenterStore) => s.nodeStatuses,
  getServiceIssues: (s: TServiceCenterStore) => s.serviceIssues,
  getSelectedEquipmentLastLog: (s: TServiceCenterStore): TNodeLog | null => {
    if (s.selectedActiveEquipmentIds[0]) {
      return s.nodeLogs[s.selectedActiveEquipmentIds[0]] || null
    } else return null
  },
  getSelectedEquipmentServiceIssues: (s: TServiceCenterStore) => {
    if (s.selectedActiveEquipmentIds[0]) {
      return s.serviceIssues[s.selectedActiveEquipmentIds[0]] || []
    } else return []
  },
  getMostRecentLogTime: (s: TServiceCenterStore) => s.mostRecentLogTime,
  getHelpGuides: (s: TServiceCenterStore) => s.helpGuides,
  getInstallationChecklists: (s: TServiceCenterStore) => s.installationChecklists,
  getLastTimeDownloaded: (s: TServiceCenterStore) => s.offlineContents.lastTimeDownloaded,
}
