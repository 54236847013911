import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { MetaWithImageUrls, MetaWithNodeIdentifier } from './queue'

export type AddNodeImagesMeta = MetaWithImageUrls & {
  nodeAction: string
  utcDate: string
} & MetaWithNodeIdentifier

export type AddNodeImagesResult = void

const preRequest = (meta: AddNodeImagesMeta): boolean | Error => {
  if (!meta.imageUrls || meta.imageUrls.length < meta.imagesCount) {
    const missingImages = meta.imagesCount - (meta.imageUrls?.length || 0)

    return new Error(`Waiting for ${missingImages} images to upload`)
  }

  return true
}

const request = async (meta: AddNodeImagesMeta): Promise<AddNodeImagesResult> => {
  await apiFetch<routes.ServiceCenterNodeImagesAdd.Request, routes.ServiceCenterNodeImagesAdd.Response>({
    url: routes.ServiceCenterNodeImagesAdd.path,
    body: meta,
    params: {
      method: 'POST',
    },
  })
}

export const serviceCenterNodeImagesAdd = {
  preRequest,
  request,
}
