import { routes } from '@semios/app-platform-banyan-route-definitions'
import { blockLacksCropSectionMaker } from 'App/Map/PanelDetails/_utils/blockLacksCropSectionMaker'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import { StackedChartSection, TChartSeries } from 'components/StackedChart/types'
import { SeriesOptionsType } from 'highcharts'
import { translate } from 'i18n/i18n'
import { colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { getCropNameFromId } from 'utils/getCropNameFromId'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { DropdownSelectorEvapotranspiration } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorEvapotranspiration/DropdownSelectorEvapotranspiration'
import { KcBlockSettingsModalButton } from 'components/KcBlockSettingsModalButton/KcBlockSettingsModalButton'
import { renderETCaption } from './renderETCaption'
import moment from 'moment-timezone'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_EVAPOTRANSPIRATION_DETAILS' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const valuesRequested = {
  evapotranspirationETc: preferredAggregationInterval,
  evapotranspirationETo: preferredAggregationInterval,
  evapotranspirationKc: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.evapotranspiration || !selectedFieldAssets.block) return {}

  if (
    !doesSelectedBlockHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
    })
  )
    return {}

  return {
    blocks: {
      blockIds: [selectedFieldAssets.block],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.evapotranspiration().categoryTitleWithoutUnit(),
    titleChildren: (
      <span css={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '4px 12px' }}>
        <DropdownSelectorEvapotranspiration valuesTimeseriesToFilterOn={Object.keys(valuesRequested)} />
        <KcBlockSettingsModalButton />
      </span>
    ),
    id: 'stackem-evapotranspiration',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const { block, property } = selectedFieldAssetsStore.getState()
  const blockId = Number(block)
  const blockEto = data?.blocks?.[blockId]?.values?.evapotranspirationETo?.[0]
  const blockEtc = data?.blocks?.[blockId]?.values?.evapotranspirationETc?.[0]
  const propertyObj = fieldAssetStore.getState().properties?.[Number(property)]
  const cropIdFromFieldAssets = propertyObj?.blocks?.[blockId].cropIds?.[0]

  if (
    !doesSelectedBlockHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
    })
  ) {
    return blockLacksCropSectionMaker({
      ...commonReturnItems,
      translatedCropName: getCropNameFromId(cropIdFromFieldAssets || undefined),
    })
  }

  const series: SeriesOptionsType[] = []

  const etoSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.lineChartSeriesOne,
    name: translate.phrases.unitSymbols('ETo'),
    tooltip: {
      valueDecimals: unitConverter.evapotranspiration(null).defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${unitConverter.evapotranspiration(null).suffix()}`,
    },
    data: (blockEto?.timeseries ?? []).map((d) => [
      +new Date(d.timestamp),
      unitConverter.evapotranspiration(d.value).value(),
    ]),
    type: 'line',
    id: 'evapotranspiration-eto',
  }

  const etcSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.lineChartSeriesTwo,
    name: translate.phrases.unitSymbols('ETc'),
    tooltip: {
      valueDecimals: unitConverter.evapotranspiration(null).defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${unitConverter.evapotranspiration(null).suffix()}`,
    },
    data: (blockEtc?.timeseries ?? []).map((d) => [
      +new Date(d.timestamp),
      unitConverter.evapotranspiration(d.value).value(),
    ]),
    type: 'line',
    id: 'evapotranspiration-etc',
  }

  series.push(etoSeries)

  const etcSeriesHasData = etcSeries.data?.some((d) => Array.isArray(d) && d.length > 1 && d[1] !== null)

  etcSeriesHasData && series.push(etcSeries)

  if (compareSeasonsInterval) {
    const blockEtoCompareSeasons = compareSeasonsData?.blocks?.[blockId]?.values?.evapotranspirationETo?.[0]
    const blockEtcCompareSeasons = compareSeasonsData?.blocks?.[blockId]?.values?.evapotranspirationETc?.[0]

    const etoCompareSeasonsSeries = makeCompareSeasonsSeriesFromRegularSeries(etoSeries, {
      data: (blockEtoCompareSeasons?.timeseries ?? []).flatMap((d) => {
        const timestamp = +new Date(d.timestamp)
        const value = unitConverter.evapotranspiration(d.value).value()

        return [[+timestamp, value]]
      }),
    })

    series.push(etoCompareSeasonsSeries)

    if (etcSeriesHasData) {
      const etcCompareSeasonsSeries = makeCompareSeasonsSeriesFromRegularSeries(etcSeries, {
        data: (blockEtcCompareSeasons?.timeseries ?? []).flatMap((d) => {
          const timestamp = +new Date(d.timestamp)
          const value = unitConverter.evapotranspiration(d.value).value()

          return [[+timestamp, value]]
        }),
      })

      series.push(etcCompareSeasonsSeries)
    }
  }

  const isDaily = true
  const firstForecastTimestamp = +moment.tz(propertyObj?.timezone ?? moment.tz.guess()).startOf('day')

  return {
    ...commonReturnItems,
    title: translate.phrases.templates('{{labelA}} - {{labelB}}', {
      labelA: unitConverter.evapotranspiration().categoryTitleWithoutUnit(),
      labelB: getCropNameFromId(cropIdFromFieldAssets || undefined),
    }),
    items: [
      {
        chartConfig: {
          tooltip: {
            xDateFormat: getXDateFormat(isDaily),
          },
          semiosHighchartsAdditions: {
            id: 'evapotranspiration',
            firstForecastTimestamp,
          },
          caption: {
            align: 'right',
            useHTML: true,
            margin: 0,
            style: {
              fontSize: '14px',
            },
          },
          chart: {
            type: 'line',
            events: {
              render: function (this) {
                renderETCaption(this, firstForecastTimestamp)
              },
            },
          },
          series,
        },
      },
    ],
  }
}
