import { Dispatch, SetStateAction, useEffect } from 'react'
import { AlertsSettings, Rule } from '../../../../settings/alertSettings'
import { alertRulesObjectFromArray } from '../../_utils/alertRulesObjectFromArray'
import { AlertsValidators, isNumberOrNumericString } from '../../../../AlertValidators/AlertValidators'
import { helpTextErrorHelper } from '../../_utils/helpTextErrorHelper'
import { validateStatusErrorHelper } from '../../../../../_utils/validateStatusErrorHelper'
import { updateAlertRules } from '../../_utils/updateAlertRules'
import { translate } from 'i18n/i18n'
import { NumberInput } from '@mantine/core'
import { TranslatePhraseWrapper } from '../../../../components/TranslatePhraseWrapper'
import { getErrorStyle } from '../../../../_utils/getErrorStyle'
import { ErrorTextWrapper } from '../../../../components/ErrorTextWrapper'
import { Radio } from 'components/Radio/Radio'
import { TranslateComponentWrapper } from '../../../../components/TranslateComponentWrapper'

export const FireBlightThreshold = ({
  rules,
  setRules,
  setThresholdIsValid,
  triedToSubmit,
}: {
  rules: Rule[]
  setRules: Dispatch<SetStateAction<Rule[]>>
  setThresholdIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  useEffect(() => {
    if (!rules || !rules.length) setRules(AlertsSettings.emptyRules.fireBlight)
  })

  const { fireBlight: fireBlightRule, fireBlightForecast: fireBlightForecastRule } =
    alertRulesObjectFromArray(rules)

  let fireBlight = null

  if (fireBlightRule) fireBlight = fireBlightRule.value

  if (fireBlightForecastRule) fireBlight = fireBlightForecastRule.value

  const isForecast = !!fireBlightForecastRule
  // check if user-enterable fields are dirty
  const fireBlightIsDirty = fireBlight !== null || triedToSubmit
  // validate the user-entered values
  const fireBlightError = AlertsValidators.fireBlight({ fireBlight })
  // attach a status
  const fireBlightValidateStatus = validateStatusErrorHelper(fireBlightIsDirty && !!fireBlightError)
  // attach an error message
  const fireBlightHelp = helpTextErrorHelper(fireBlightIsDirty && fireBlightError)
  const errorStyle = getErrorStyle()

  // update the rules upon changes
  useEffect(() => {
    setThresholdIsValid(!fireBlightError)
  }, [fireBlight, isForecast])

  const setIsForecast = (truthVal: boolean) => {
    updateAlertRules({
      rules,
      setRules,
      rulesToChange: {
        [truthVal ? 'fireBlightForecast' : 'fireBlight']: {
          ...(fireBlightRule || {}),
          ...(fireBlightForecastRule || {}),
        }, // basically we just want to flip the keys but keep the values
        [truthVal ? 'fireBlight' : 'fireBlightForecast']: null,
      },
    })
  }

  return (
    <div>
      <span>{translate.phrases.banyanApp('Send alert for:')}</span>
      <div css={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 7,
            padding: '15px 15px 15px 0',
          }}
        >
          <Radio
            checked={!isForecast}
            onClick={() => setIsForecast(false)}
            onChange={() => setIsForecast(false)}
          />
          <span css={{ cursor: 'pointer' }} onClick={() => setIsForecast(false)}>
            {translate.phrases.banyanApp('Current Temperature Risk Value')}
          </span>
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 7,
            padding: '15px 15px 15px 0',
          }}
        >
          <Radio
            checked={isForecast}
            onClick={() => setIsForecast(true)}
            onChange={() => setIsForecast(true)}
          />
          <span css={{ cursor: 'pointer' }} onClick={() => setIsForecast(true)}>
            {translate.phrases.banyanApp('Forecast Temperature Risk Value (7-Day Forecast)')}
          </span>
        </div>
      </div>
      <TranslatePhraseWrapper>
        <translate.Phrases.banyanApp
          k="Send alert when fire blight risk value is greater than or equal to <numberInput/> TRV"
          c={{
            numberInput: (
              <TranslateComponentWrapper>
                <NumberInput
                  css={{ maxWidth: 80, margin: '0 5px' }}
                  onChange={(value) =>
                    updateAlertRules({
                      rules,
                      setRules,
                      rulesToChange: { [isForecast ? 'fireBlightForecast' : 'fireBlight']: { value } },
                    })
                  }
                  type="number"
                  classNames={{ input: fireBlightValidateStatus ? errorStyle : undefined }}
                  value={isNumberOrNumericString(fireBlight) ? Number(fireBlight) : ''}
                  precision={10}
                  removeTrailingZeros={true}
                />
                <div />
              </TranslateComponentWrapper>
            ),
          }}
        />
      </TranslatePhraseWrapper>
      {fireBlightHelp && <ErrorTextWrapper>{fireBlightHelp}</ErrorTextWrapper>}
    </div>
  )
}
