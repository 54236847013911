import { Button } from 'components/Button/Button'
import { FC } from 'react'
import { colors } from 'settings/colors'

type WideFooterProps = {
  primaryButtonDisabled?: boolean
  primaryButtonText?: string
  primaryButtonOnPress?: () => void
  secondaryButtonDisabled?: boolean
  secondaryButtonText?: string
  secondaryButtonOnPress?: () => void
}

export const WideFooter: FC<WideFooterProps> = ({
  primaryButtonDisabled,
  primaryButtonText,
  primaryButtonOnPress,
  secondaryButtonDisabled,
  secondaryButtonText,
  secondaryButtonOnPress,
}) => {
  const noPrimaryAndSecondaryTextPassed = !primaryButtonText && !secondaryButtonText

  if (noPrimaryAndSecondaryTextPassed) return <div />

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: `1px solid ${colors.grey200}`,
        backgroundColor: colors.grey50,
        padding: '8px',
        alignItems: 'center',
      }}
    >
      {secondaryButtonText ? (
        <Button disabled={!!secondaryButtonDisabled} variant="tertiary" onClick={secondaryButtonOnPress}>
          {secondaryButtonText}
        </Button>
      ) : (
        <div />
      )}
      {primaryButtonText ? (
        <Button disabled={!!primaryButtonDisabled} variant="primary" onClick={primaryButtonOnPress}>
          {primaryButtonText}
        </Button>
      ) : (
        <div />
      )}
    </div>
  )
}
