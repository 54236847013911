import { FC, useState } from 'react'
import { CSSObject } from '@emotion/css'
import { useScreenSize } from 'utils/useScreenSize'
import { WideScreenPanel } from './WideScreenPanel'
import { NarrowScreenPanel } from './NarrowScreenPanel'

interface MapControlsPanelProps {
  children?: React.ReactNode
  expandedContent?: React.ReactNode
  style?: React.CSSProperties | CSSObject
}

export const MapControlsPanel: FC<MapControlsPanelProps> = ({
  children,
  expandedContent,
  style: panelStyle,
}) => {
  const { isWideScreen } = useScreenSize()
  const [isExpanded, setIsExpanded] = useState(true)

  return isWideScreen ? (
    <WideScreenPanel
      expandedContent={expandedContent}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      style={panelStyle}
    >
      {children}
    </WideScreenPanel>
  ) : (
    <NarrowScreenPanel
      expandedContent={expandedContent}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      style={panelStyle}
    >
      {children}
    </NarrowScreenPanel>
  )
}
