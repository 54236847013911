import React, { useState } from 'react'
import { TabHeader, TabHeaderList } from './TabHeaderList'
import { TabPanel, TabPanelList } from './TabPanelList'
import { TabsContextProvider } from './Tabs.context'
import { SelectedPosition } from './Tabs.types'

export const Tabs = ({
  containerWidth,
  defaultTab,
  children,
  styles,
  stickyHeader = true,
}: {
  containerWidth: number
  defaultTab: string
  children: React.ReactNode
  styles?: { list?: Record<string, string | number> }
  stickyHeader?: boolean
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab)
  const [collapsed, setCollapsed] = useState(false)
  const [selectedPosition, setSelectedPosition] = useState<SelectedPosition>({ width: 0, left: 0 })
  const [showTabs, setShowTabs] = useState(false)

  return (
    <TabsContextProvider
      value={{
        collapsed,
        setCollapsed,
        containerWidth,
        activeTab,
        setActiveTab,
        showTabs,
        setShowTabs,
        selectedPosition,
        setSelectedPosition,
        children,
        styles,
        stickyHeader,
      }}
    >
      {children}
    </TabsContextProvider>
  )
}

Tabs.TabHeaderList = TabHeaderList

Tabs.TabHeader = TabHeader

Tabs.TabPanelList = TabPanelList

Tabs.TabPanel = TabPanel
