import { Button } from 'components/Button/Button'
import { IconRightArrowCircle } from 'components/icons/IconRightArrowCircle'
import { useScreenSize } from 'utils/useScreenSize'
import { SettingsFooterContainer } from 'App/Map/UserSettingsMenu/components/SettingsFooterContainer/SettingsFooterContainer'

export const ContactAndGroupSubmitButton = ({ label, disabled }: { label: string; disabled?: boolean }) => {
  const { isWideScreen } = useScreenSize()

  return (
    <SettingsFooterContainer>
      <Button
        variant="primary"
        type="submit"
        rightIcon={
          <div
            css={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconRightArrowCircle />
          </div>
        }
        css={{ width: isWideScreen ? undefined : '100%' }}
        disabled={disabled}
      >
        {label}
      </Button>
    </SettingsFooterContainer>
  )
}
