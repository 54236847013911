import React from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import { checkAuthorization, RequiresArg } from 'utils/checkAuthorization'

export const Authorization = ({
  children,
  fallback = null,
  requires,
}: {
  children: React.ReactElement
  fallback?: React.ReactElement | null
  requires: RequiresArg
}) => {
  const { permissions } = userDetailsStore.useSelector((s) => ({
    permissions: s.permissions,
  }))

  if (checkAuthorization(requires, permissions)) {
    return children
  }

  return fallback
}
