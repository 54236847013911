import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'
import { EnumWeatherCondition } from 'utils/weather-conditions/types'

const mapWeatherConditionToLabel: Record<EnumWeatherCondition, () => string> = {
  'wi-cloudy': () => translate.phrases.weatherConditions('Cloudy'),
  'wi-day-cloudy': () => translate.phrases.weatherConditions('Cloudy'),
  'wi-day-fog': () => translate.phrases.weatherConditions('Fog'),
  'wi-day-hail': () => translate.phrases.weatherConditions('Hail'),
  'wi-day-haze': () => translate.phrases.weatherConditions('Haze'),
  'wi-day-rain': () => translate.phrases.weatherConditions('Rain'),
  'wi-day-snow': () => translate.phrases.weatherConditions('Snow'),
  'wi-day-sunny': () => translate.phrases.weatherConditions('Sunny'),
  'wi-day-thunderstorm': () => translate.phrases.weatherConditions('Thunderstorm'),
  'wi-day-windy': () => translate.phrases.weatherConditions('Windy'),
  'wi-fog': () => translate.phrases.weatherConditions('Fog'),
  'wi-hail': () => translate.phrases.weatherConditions('Hail'),
  'wi-hot': () => translate.phrases.weatherConditions('Hot'),
  'wi-hurricane': () => translate.phrases.weatherConditions('Hurricane'),
  'wi-na': () => translate.phrases.weatherConditions('Not Available'),
  'wi-night-clear': () => translate.phrases.weatherConditions('Clear Night'),
  'wi-night-cloudy': () => translate.phrases.weatherConditions('Cloudy'),
  'wi-night-showers': () => translate.phrases.weatherConditions('Showers'),
  'wi-night-snow': () => translate.phrases.weatherConditions('Snow Night'),
  'wi-night-thunderstorm': () => translate.phrases.weatherConditions('Thunderstorm'),
  'wi-rain': () => translate.phrases.weatherConditions('Rain'),
  'wi-rain-mix': () => translate.phrases.weatherConditions('Mixed Rain'),
  'wi-sandstorm': () => translate.phrases.weatherConditions('Sandstorm'),
  'wi-showers': () => translate.phrases.weatherConditions('Showers'),
  'wi-sleet': () => translate.phrases.weatherConditions('Sleet'),
  'wi-smoke': () => translate.phrases.weatherConditions('Smoke'),
  'wi-snow': () => translate.phrases.weatherConditions('Snow'),
  'wi-snow-wind': () => translate.phrases.weatherConditions('Snow and Wind'),
  'wi-snowflake-cold': () => translate.phrases.weatherConditions('Cold'),
  'wi-sunrise': () => translate.phrases.weatherConditions('Sunrise'),
  'wi-sunset': () => translate.phrases.weatherConditions('Sunset'),
  'wi-thunderstorm': () => translate.phrases.weatherConditions('Thunderstorm'),
  'wi-tornado': () => translate.phrases.weatherConditions('Tornado'),
  'wi-windy': () => translate.phrases.weatherConditions('Windy'),
}

export const weatherConditionToLabel = (weatherCondition?: EnumWeatherCondition): string => {
  if (!weatherCondition) {
    Sentry.captureException(`weatherCondition "${weatherCondition}" has not been implemented`)

    return translate.phrases.weatherConditions('Not Available')
  }

  return mapWeatherConditionToLabel[weatherCondition]()
}
