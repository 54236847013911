import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import * as Sentry from '@sentry/react'
import { IconClearDay } from 'components/icons/IconClearDay'
import { IconClearNight } from 'components/icons/IconClearNight'
import { IconCloudy } from 'components/icons/IconCloudy'
import { IconDash } from 'components/icons/IconDash'
import { IconDrizzle } from 'components/icons/IconDrizzle'
import { IconFog } from 'components/icons/IconFog'
import { IconHazeDay } from 'components/icons/IconHazeDay'
import { IconHurricane } from 'components/icons/IconHurricane'
import { IconOvercastHail } from 'components/icons/IconOvercastHail'
import { IconOvercastNight } from 'components/icons/IconOvercastNight'
import { IconOvercastNightDrizzle } from 'components/icons/IconOvercastNightDrizzle'
import { IconOvercastNightSnow } from 'components/icons/IconOvercastNightSnow'
import { IconRain } from 'components/icons/IconRain'
import { IconSleet } from 'components/icons/IconSleet'
import { IconSmoke } from 'components/icons/IconSmoke'
import { IconSnow } from 'components/icons/IconSnow'
import { IconSnowflake } from 'components/icons/IconSnowflake'
import { IconSunHot } from 'components/icons/IconSunHot'
import { IconSunrise } from 'components/icons/IconSunrise'
import { IconSunset } from 'components/icons/IconSunset'
import { IconThunderstorms } from 'components/icons/IconThunderstorms'
import { IconThunderstormsDay } from 'components/icons/IconThunderstormsDay'
import { IconThunderstormsNightExtreme } from 'components/icons/IconThunderstormsNightExtreme'
import { IconTornado } from 'components/icons/IconTornado'
import { IconWind } from 'components/icons/IconWind'
import { IconWindSnow } from 'components/icons/IconWindSnow'
import { EnumWeatherCondition } from 'utils/weather-conditions/types'

const mapWeatherConditionToIcon: Record<EnumWeatherCondition, () => EmotionJSX.Element> = {
  'wi-cloudy': IconCloudy,
  'wi-day-cloudy': IconCloudy,
  'wi-day-fog': IconFog,
  'wi-day-hail': IconOvercastHail,
  'wi-day-haze': IconHazeDay,
  'wi-day-rain': IconRain,
  'wi-day-snow': IconSnow,
  'wi-day-sunny': IconClearDay,
  'wi-day-thunderstorm': IconThunderstormsDay,
  'wi-day-windy': IconWind,
  'wi-fog': IconFog,
  'wi-hail': IconOvercastHail,
  'wi-hot': IconSunHot,
  'wi-hurricane': IconHurricane,
  'wi-na': IconDash,
  'wi-night-clear': IconClearNight,
  'wi-night-cloudy': IconOvercastNight,
  'wi-night-showers': IconOvercastNightDrizzle,
  'wi-night-snow': IconOvercastNightSnow,
  'wi-night-thunderstorm': IconThunderstormsNightExtreme,
  'wi-rain': IconRain,
  'wi-rain-mix': IconRain,
  'wi-sandstorm': IconHurricane,
  'wi-showers': IconDrizzle,
  'wi-sleet': IconSleet,
  'wi-smoke': IconSmoke,
  'wi-snow': IconSnow,
  'wi-snow-wind': IconWindSnow,
  'wi-snowflake-cold': IconSnowflake,
  'wi-sunrise': IconSunrise,
  'wi-sunset': IconSunset,
  'wi-thunderstorm': IconThunderstorms,
  'wi-tornado': IconTornado,
  'wi-windy': IconWind,
}

export const weatherConditionToIcon = (
  weatherCondition?: EnumWeatherCondition,
): (() => EmotionJSX.Element) => {
  if (!weatherCondition) {
    Sentry.captureException(`weatherCondition "${weatherCondition}" has not been implemented`)

    return IconDash
  }

  return mapWeatherConditionToIcon[weatherCondition]
}
