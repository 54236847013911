import { routes } from '@semios/app-platform-banyan-route-definitions'
import { ChillTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/ChillTitleChildren/ChillTitleChildren'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import {
  GridTableContentSection,
  GridTableContentSectionItem,
  PossibleValuesRecord,
} from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { detailsPanelStore, TChillMarVsMay, TChillSepVsNov } from 'stores/detailsPanelStore'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { propertyIsSouthernHemisphere } from 'utils/propertyIsSouthernHemisphere'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { defaultValuesRowHeight, weatherTimestampToValuesDictionaryMaker } from '../_utils'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'

const pointCategory: TPointCategory = 'weatherPoint'
const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_CHILL_DETAILS' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const getValuesRequested = (isSouthernHemisphere: boolean | undefined) => {
  const valuesRequested: Record<string, typeof preferredAggregationInterval> = isSouthernHemisphere
    ? {
        chillCumulativeHoursBetween32and45FMAR: preferredAggregationInterval,
        chillCumulativeHoursBelow45FMAR: preferredAggregationInterval,
        chillCumulativePortionsMAR: preferredAggregationInterval,
        chillCumulativeHoursBetween32and45FMAY: preferredAggregationInterval,
        chillCumulativeHoursBelow45FMAY: preferredAggregationInterval,
        chillCumulativePortionsMAY: preferredAggregationInterval,
      }
    : {
        chillCumulativeHoursBetween32and45FSEP: preferredAggregationInterval,
        chillCumulativeHoursBelow45FSEP: preferredAggregationInterval,
        chillCumulativePortionsSEP: preferredAggregationInterval,
        chillCumulativeHoursBetween32and45FNOV: preferredAggregationInterval,
        chillCumulativeHoursBelow45FNOV: preferredAggregationInterval,
        chillCumulativePortionsNOV: preferredAggregationInterval,
      }

  return valuesRequested
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.chill || !selectedFieldAssets[pointCategory]) return {}

  const isSouthernHemisphere = propertyIsSouthernHemisphere()
  const valuesRequested = getValuesRequested(isSouthernHemisphere)

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

type TUnitConverterToUse = Record<
  'chillCumulativeHoursBetween32and45F' | 'chillCumulativeHoursBelow45F' | 'chillCumulativePortions',
  typeof unitConverter.chillCumulativeHoursBelow45FMAR
>

const generateItem = ({
  id,
  unitConvertersToUse,
  values,
}: {
  id: keyof TUnitConverterToUse
  unitConvertersToUse: TUnitConverterToUse
  values: Record<string, PossibleValuesRecord>
}): {
  height: GridTableContentSectionItem['height']
  id: string
  label: React.ReactNode
  labelMinWidth: GridTableContentSectionItem['labelMinWidth']
  render: GridTableContentSectionItem['render']
  valueMinWidth: GridTableContentSectionItem['valueMinWidth']
  values: Record<string, PossibleValuesRecord>
} => ({
  id,
  label: unitConvertersToUse[id]().shortTitle(),
  height: defaultValuesRowHeight,
  labelMinWidth: 210,
  valueMinWidth: 120,
  render: (dataPoint: { value: typeof values[keyof typeof values] }) => {
    if (typeof dataPoint?.value !== 'number') {
      return translate.phrases.templates('-')
    }

    return unitConvertersToUse[id](dataPoint?.value).value()
  },
  values,
})

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const isSouthernHemisphere = propertyIsSouthernHemisphere()

  const commonReturnItems = {
    titleChildren: (
      <ChillTitleChildren isSouthernHemisphere={!!isSouthernHemisphere} showMonthsToggle={false} />
    ),
    title: unitConverter.chillCumulativeHoursBelow45FSEP().categoryTitleWithoutUnit(),
    id: 'summary-grid-chill',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { chillMarVsMay, chillSepVsNov } = detailsPanelStore.getState()

  const chillMonthToUse: TChillMarVsMay | TChillSepVsNov = (() => {
    if (isSouthernHemisphere && chillMarVsMay === 'MAR') return 'MAR'

    if (isSouthernHemisphere && chillMarVsMay === 'MAY') return 'MAY'

    if (!isSouthernHemisphere && chillSepVsNov === 'SEP') return 'SEP'

    return 'NOV'
  })()

  const chillCumulativeHoursBetween32and45FToUse:
    | 'chillCumulativeHoursBetween32and45FSEP'
    | 'chillCumulativeHoursBetween32and45FNOV'
    | 'chillCumulativeHoursBetween32and45FMAR'
    | 'chillCumulativeHoursBetween32and45FMAY' = `chillCumulativeHoursBetween32and45F${chillMonthToUse}`

  const chillCumulativeHoursBelow45FToUse:
    | 'chillCumulativeHoursBelow45FSEP'
    | 'chillCumulativeHoursBelow45FNOV'
    | 'chillCumulativeHoursBelow45FMAR'
    | 'chillCumulativeHoursBelow45FMAY' = `chillCumulativeHoursBelow45F${chillMonthToUse}`

  const chillCumulativePortionsToUse:
    | 'chillCumulativePortionsSEP'
    | 'chillCumulativePortionsNOV'
    | 'chillCumulativePortionsMAR'
    | 'chillCumulativePortionsMAY' = `chillCumulativePortions${chillMonthToUse}`

  const unitConvertersToUse: Record<
    'chillCumulativeHoursBetween32and45F' | 'chillCumulativeHoursBelow45F' | 'chillCumulativePortions',
    typeof unitConverter.chillCumulativeHoursBelow45FMAR
  > = {
    chillCumulativeHoursBetween32and45F: unitConverter?.[chillCumulativeHoursBetween32and45FToUse],
    chillCumulativeHoursBelow45F: unitConverter?.[chillCumulativeHoursBelow45FToUse],
    chillCumulativePortions: unitConverter?.[chillCumulativePortionsToUse],
  }

  const stationLngLat = String(selectedFieldAssetsStore.getState()[pointCategory])

  const items = [
    generateItem({
      id: 'chillCumulativeHoursBetween32and45F',
      unitConvertersToUse,
      values: weatherTimestampToValuesDictionaryMaker(
        data,
        chillCumulativeHoursBetween32and45FToUse,
        stationLngLat,
      ),
    }),
    generateItem({
      id: 'chillCumulativeHoursBelow45F',
      unitConvertersToUse,
      values: weatherTimestampToValuesDictionaryMaker(data, chillCumulativeHoursBelow45FToUse, stationLngLat),
    }),
    generateItem({
      id: 'chillCumulativePortions',
      unitConvertersToUse,
      values: weatherTimestampToValuesDictionaryMaker(data, chillCumulativePortionsToUse, stationLngLat),
    }),
  ]

  return {
    ...commonReturnItems,
    items,
    titleChildren: (
      <ChillTitleChildren
        valuesTimeseriesToFilterOn={Object.keys(getValuesRequested(isSouthernHemisphere))}
        isSouthernHemisphere={!!isSouthernHemisphere}
        showMonthsToggle
      />
    ),
  }
}
