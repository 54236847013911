import React from 'react'
import { ActiveTab } from './ActiveTab/ActiveTab'
import { css } from '@emotion/css'
import { useMantineTheme } from '@mantine/core'
import { AngleDownIcon } from './AngleDownIcon/AngleDownIcon'

export const Collapsed = ({
  children,
  activeTab,
  showTabs,
  onActiveTabClick,
}: {
  children: React.ReactNode
  activeTab: string
  showTabs: boolean
  onActiveTabClick: () => void
}) => {
  const theme = useMantineTheme()

  return (
    <>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        `}
      >
        <ActiveTab onClick={onActiveTabClick}>
          {activeTab}
          <AngleDownIcon showTabs={showTabs} />
        </ActiveTab>
      </div>

      {showTabs && (
        <div
          className={css`
            position: absolute;
            width: 100%;
            top: 100%;
            left: 0;
            z-index: 4;
            background-color: ${theme.colors.midnight[0]};
            padding: 10px 10px 15px;
          `}
        >
          {children}
        </div>
      )}
    </>
  )
}
