import {
  TDateViewEnum,
  TIrrigationSchedulerStoreState,
} from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { getSelectedPropertyTimezoneForIrrigationScheduler } from 'App/irrigation-scheduler/utils/store/selectors/getSelectedPropertyTimezoneForIrrigationScheduler'
import moment from 'moment-timezone'
import { TFieldAssetStoreState } from 'stores/fieldAssetStore'

export const getDateRange = (fass: TFieldAssetStoreState, isss: TIrrigationSchedulerStoreState) => {
  const timezone = getSelectedPropertyTimezoneForIrrigationScheduler(fass, isss)
  const days = isss.dateView === TDateViewEnum.WEEK ? 7 : 1
  const mDateFrom = moment.tz(isss.dateFrom, timezone)
  const mDateTo = moment.tz(isss.dateFrom, timezone).add(days, 'days')

  return {
    mDateFrom,
    mDateTo,
  }
}
