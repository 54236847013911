import { colors } from 'settings/colors'

export const SharedSettings = {
  FIRST_DAY_OF_DATA_FOR_MANY_THINGS: '2016-01-01',
  HELP_CENTER_URL: 'https://hub-support.semios.com/en/collections/4030060-semios-hub',
  SEND_FEEDBACK_URL: 'https://www.surveymonkey.com/r/5Y6WM87',
  S3_PUBLIC_BUCKET_URL: 'https://customer-apps-general-public-bucket.s3.us-west-2.amazonaws.com',
  LOCAL_STORAGE_KEYS: {
    AUTH_TOKEN: 'token',
  },
  /**
   * the default Mantine <Select> component has a bi-directional
   * arrow, which is pretty weird. Adding this to the `styles` prop
   * modifies that arrow by clipping only the bottom half, shifting
   * it up a few pixels, and scaling it bigger
   */
  MANTINE_SELECT_RIGHT_ICON_CHANGER: {
    input: { cursor: 'pointer', paddingRight: 27 },
    root: {
      '& [aria-expanded="true"]': {
        '& .mantine-Select-rightSection': {
          transform: 'rotate(180deg)',
        },
      },
    },
    rightSection: {
      transition: '0.3s ease all',
      transform: 'rotate(0deg)',
      marginRight: -3,
      svg: {
        color: `${colors.midnight} !important`,
        transform: 'translateY(-3px)',
        scale: '200%',
        clipPath: 'polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%)',
      },
    },
  },
  MANTINE_SELECT_UNDERLINE_SEPARATOR_STYLES: {
    itemsWrapper: {
      padding: '0px 12px',
    },
    separator: {
      padding: '4px 0',
      borderBottom: `1px solid ${colors.grey200}`,
    },
    separatorLabel: {
      ':after': {
        display: 'none',
      },
    },
  },
  DEFAULT_FORECAST_DAYS: 14,
  MINIMUM_WIDTH_WIDE_SCREEN: 1113,
  SPACING_FOR_THINGS_THAT_FLOAT_OVER_LEFT_SIDE_OF_MAP: {
    SPACING_WHEN_MENU_COLLAPSED: 10,
    SPACING_WHEN_MENU_OPEN: 315,
  },
  // TODO: we should just do this trapimage URL building on the backend
  TRAP_IMAGE_PREFIX_URL: 'https://s3-us-west-2.amazonaws.com/semiostrapimages/',
  TRAP_IMAGE_FILE_SUFFIX: 'jpg', // note: no dot in this value
  INTERCOM_APP_ID: 'xsy9y46t', // TODO: Blair: Think about if we want this as an env var. Also think about fullstory, hubspot, etc and their ids.
}
