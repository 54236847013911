import { css } from '@emotion/css'
import { Select } from '@mantine/core'
import { useTabsContext } from 'components/Tabs/Tabs.context'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { mantineSelectSearchFilterProp } from 'utils/mantineSelectSearchFilterProp'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { sortByKey } from 'utils/sortByKey'
import { TDropdownSelectorProps } from '../_types'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'

type SelectDataItem = { value: string; label: string }

export type TDropdownSelectorPropertyProps = TDropdownSelectorProps

export const DropdownSelectorProperty = ({ valuesTimeseriesToFilterOn }: TDropdownSelectorPropertyProps) => {
  const { selectedProperty } = selectedFieldAssetsStore.useSelector((s) => ({
    selectedProperty: s.property,
  }))

  const ctx = useTabsContext()

  const { properties } = fieldAssetStore.useSelector((s) => ({
    properties: s?.properties ?? [],
  }))

  const data = useMemo<SelectDataItem[]>(() => {
    return Object.values(properties ?? {})
      .sort(sortByKey('propertyName'))
      .flatMap((p) => {
        if (valuesTimeseriesToFilterOn && !isEmpty(valuesTimeseriesToFilterOn)) {
          const propertyHasValueTypes = valuesTimeseriesToFilterOn.some((vt) =>
            p.valuesTimeseries.includes(vt),
          )

          const pointsWithValues = filterFieldAssetsByValueTypes({
            fieldAssets: Object.values(p.points || []),
            valuesTimeseries: valuesTimeseriesToFilterOn,
          })

          const blocksWithValues = filterFieldAssetsByValueTypes({
            fieldAssets: Object.values(p.blocks || []),
            valuesTimeseries: valuesTimeseriesToFilterOn,
          })

          const zonesWithValues = filterFieldAssetsByValueTypes({
            fieldAssets: Object.values(p.irrigationZoneEmitters || []),
            valuesTimeseries: valuesTimeseriesToFilterOn,
          })

          if (
            !propertyHasValueTypes &&
            isEmpty(pointsWithValues) &&
            isEmpty(blocksWithValues) &&
            isEmpty(zonesWithValues)
          )
            return []
        }

        return {
          value: String(p.propertyId),
          label: p.propertyName,
        }
      })
  }, [selectedProperty, properties])

  return (
    // can prevent the section from collapsing by passing stopPropagation
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        className={css({
          width: ctx.containerWidth < 600 ? 200 : Math.min(280, Math.round(ctx.containerWidth / 3)),
          border: `1px solid ${colors.grey500}`,
          color: colors.grey800,
          borderRadius: 3,
        })}
        clearable={false}
        data={data}
        filter={mantineSelectSearchFilterProp}
        onChange={(newProperty: string) => setSelectedFieldAsset({ property: Number(newProperty) })}
        placeholder={translate.phrases.banyanApp('Select a Property')}
        searchable
        size="xs"
        spellCheck="false"
        styles={{
          ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER,
          dropdown: { marginTop: -6, fontWeight: 'normal' },
          input: { ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER.input, paddingRight: 28 },
        }}
        value={String(selectedProperty)}
        withinPortal
        zIndex={1}
      />
    </div>
  )
}
