import { useState } from 'react'
import { EditZoneModal } from './EditZoneModal'
import { PropertySection } from './PropertySection'
import { sortByKey } from 'utils/sortByKey'
import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'

export type Zone = {
  zoneName: string
  irrigationZoneId: number
}

export const ZonesTab = ({
  filterPropertiesText,
  properties,
}: {
  filterPropertiesText: string
  properties: SharedTypes.TProperty[]
}) => {
  const [selectedZone, setSelectedZone] = useState<(Zone & { propertyId: number }) | null>(null)

  return (
    <div>
      {properties.map((property) => {
        const zones = Object.values(
          Object.values(property.irrigationZoneEmitters ?? {}).reduce<
            Record<number, { zoneName: string; irrigationZoneId: number }>
          >((acc, { irrigationZoneId, name }) => {
            if (acc[irrigationZoneId]) return acc

            acc[irrigationZoneId] = {
              zoneName: name,
              irrigationZoneId,
            }

            return acc
          }, {}),
        )

        return (
          zones.length && (
            <PropertySection
              key={property.propertyId}
              filterPropertiesText={filterPropertiesText}
              propertyId={property.propertyId}
              propertyName={property.propertyName}
              setSelectedZone={setSelectedZone}
              zones={zones.sort(sortByKey('zoneName'))}
            />
          )
        )
      })}
      <EditZoneModal setSelectedZone={setSelectedZone} selectedZone={selectedZone} />
    </div>
  )
}
