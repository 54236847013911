import { css, CSSObject } from '@emotion/css'
import {
  GridTableColumn,
  GridTableContentSectionItem,
  PossibleValuesRecord,
} from 'components/GridTable/types'
import { LabelCell } from './LabelCell/LabelCell'
import { ValueCell } from './ValueCell/ValueCell'

export const ItemRow = ({
  columns,
  height,
  label,
  labelCellCSS,
  render,
  valueCellCSS,
  values,
  widthForLabelColumn,
  widthForValueColumns,
}: {
  columns: GridTableColumn[]
  height: number
  label: React.ReactNode
  labelCellCSS?: GridTableContentSectionItem['labelCellCSS']
  render: GridTableContentSectionItem['render']
  valueCellCSS?: CSSObject
  valueWidth: number
  // TODO: use the generic to get the columns' keys
  values: Record<string, PossibleValuesRecord>
  widthForLabelColumn: number
  widthForValueColumns: number
}) => {
  return (
    <>
      <div
        className={css({
          height,
          display: 'flex',
          transition: 'height 200ms, opacity 200ms',
        })}
      >
        <LabelCell cellCSS={labelCellCSS} height={height} width={widthForLabelColumn}>
          {label}
        </LabelCell>
        {columns.map((column) => (
          <ValueCell
            key={column.key}
            column={column}
            render={render}
            valueCellCSS={valueCellCSS}
            values={values}
            valueWidth={widthForValueColumns}
          />
        ))}
      </div>
    </>
  )
}
