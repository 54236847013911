import { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import { GridTableContentSection, GridTableContentSectionItem } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { MinMaxValueCell } from '../../../MinMaxValueCell/MinMaxValueCell'
import { defaultValuesRowHeight, weatherTimestampToValuesDictionaryMaker } from '../_utils'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'

type CommonValueType =
  VV.DomainTypes.Weather.TValuesReturnWithMetaIgnoringKeying['points']['wetBulb_IN'][number]['timeseries'][number]

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_WEATHER_DETAILS' })
const pointCategory: TPointCategory = 'weatherPoint'

const possibleValueTypesToRequest: VV.DomainTypes.Weather.TTimeseriesValueTypeKeysMerged[] = [
  'wetBulb_ABOVE',
  'wetBulb_IN',
  'wetBulb_BELOW',
]

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.wet_bulb || !selectedFieldAssets[pointCategory] || !selectedFieldAssets.property)
    return {}

  const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

  const valuesForPoint =
    fieldAssetStore.getState().properties?.[selectedFieldAssets.property]?.points?.[
      selectedFieldAssets[pointCategory]
    ]?.valuesTimeseries || []

  const valuesToRequest = possibleValueTypesToRequest.filter((v) => valuesForPoint.includes(v))

  const valuesRequested = valuesToRequest.reduce((request, valueType) => {
    request[valueType] = preferredAggregationInterval

    return request
  }, {} as Partial<Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>>)

  if (isEmpty(valuesRequested)) return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

const generateItem = ({
  id,
  values,
  valueType,
}: {
  id: string
  // values: Record<string, CommonValueType> todo: fix ts-ignore in GetTimeseriesType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any
  valueType: keyof typeof unitConverter
}): {
  height: GridTableContentSectionItem['height']
  id: string
  label: React.ReactNode
  labelMinWidth: GridTableContentSectionItem['labelMinWidth']
  render: GridTableContentSectionItem['render']
  valueMinWidth: GridTableContentSectionItem['valueMinWidth']
  values: Record<string, CommonValueType>
} => ({
  id,
  label: unitConverter[valueType]().shortTitle(),
  height: defaultValuesRowHeight,
  labelMinWidth: 160,
  valueMinWidth: 120,
  render: (dataPoint: { value: { max: number | null | undefined; min: number | null | undefined } }) => {
    // TODO: maybe the route definitions can be more sophisticated in its return?
    if (dataPoint === undefined || typeof dataPoint?.value === 'number' || dataPoint === null) {
      return translate.phrases.templates('-')
    }

    const { max, min } = dataPoint.value

    const maxWithSuffix =
      max !== null && max !== undefined ? unitConverter[valueType](max).valueWithSuffix() : null

    const minWithSuffix =
      min !== null && min !== undefined ? unitConverter[valueType](min).valueWithSuffix() : null

    return <MinMaxValueCell min={minWithSuffix} max={maxWithSuffix} />
  },
  values,
})

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.wetBulbInCanopy().categoryTitleWithoutUnit(),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
    id: 'summary-grid-wet-bulb',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const stationLngLat = String(selectedFieldAssetsStore.getState()[pointCategory])

  const hasAboveCanopySensor = doesSelectedPointHaveValueTypes({
    valuesTimeseries: ['wetBulb_ABOVE'],
    pointCategory,
  })

  const hasInCanopySensor = doesSelectedPointHaveValueTypes({
    valuesTimeseries: ['wetBulb_IN'],
    pointCategory,
  })

  const hasBelowCanopySensor = doesSelectedPointHaveValueTypes({
    valuesTimeseries: ['wetBulb_BELOW'],
    pointCategory,
  })

  const items = [
    hasAboveCanopySensor &&
      generateItem({
        id: 'wet-bulb-above',
        values: weatherTimestampToValuesDictionaryMaker(data, 'wetBulb_ABOVE', stationLngLat),
        valueType: 'temperatureAboveCanopy',
      }),
    hasInCanopySensor &&
      generateItem({
        id: 'wet-bulb-in',
        values: weatherTimestampToValuesDictionaryMaker(data, 'wetBulb_IN', stationLngLat),
        valueType: 'temperatureInCanopy',
      }),
    hasBelowCanopySensor &&
      generateItem({
        id: 'wet-bulb-below',
        values: weatherTimestampToValuesDictionaryMaker(data, 'wetBulb_BELOW', stationLngLat),
        valueType: 'temperatureBelowCanopy',
      }),
  ].filter(Boolean) as GridTableContentSectionItem[]

  return {
    ...commonReturnItems,
    items,
  }
}
