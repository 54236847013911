import { GalleryPhoto, Photo } from '@capacitor/camera'
import { Filesystem } from '@capacitor/filesystem'
import md5 from 'md5'

function b64toBlob(base64: string, type: string) {
  let byteString = atob(base64) //Don't use Buffer.from(base64, 'base64') as it's only supported in Node.js
  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: `image/${type}` })
}

export async function readPhoto(photo: Photo | GalleryPhoto): Promise<{
  filename: string
  size: number
  content: Blob
}> {
  if ((photo as Photo).dataUrl) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const filename = md5((photo as Photo).dataUrl!)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = await fetch((photo as Photo).dataUrl!)
    const blob = await data.blob()

    return { filename, size: blob.size, content: blob }
  } else {
    const filename = md5((photo as GalleryPhoto).webPath)
    // Fetch the photo, read as a blob, then convert to base64 format

    try {
      const data = await fetch((photo as GalleryPhoto).webPath)
      const blob = await data.blob()

      return { filename, size: blob.size, content: blob }
    } catch (e) {
      // If the webPath fails, try the path instead (due to iOS policy)
      const path = (photo as GalleryPhoto).path

      if (!path) {
        throw new Error('No path found for photo')
      }

      const f = await Filesystem.readFile({
        path: path,
      })

      return {
        filename,
        size: f.data.length,
        content: b64toBlob(f.data, photo.format),
      }
    }
  }
}
