import { DatesProvider } from '@mantine/dates'
import { translate, globalLanguageStore } from 'i18n/i18n'
import React from 'react'

export const I18nWrapper = (props: { children: React.ReactNode }) => {
  const globalLanguage = globalLanguageStore.useSelector((lng) => lng)

  return (
    <React.Fragment key={globalLanguage}>
      <DatesProvider settings={{ locale: globalLanguage, firstDayOfWeek: translate.dates.firstDayOfWeek() }}>
        {props.children}
      </DatesProvider>
    </React.Fragment>
  )
}
