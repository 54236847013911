import { css } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMantineTheme } from '@mantine/core'
import { FC } from 'react'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { SettingItemNarrowProps } from '../types'

export const SettingItem: FC<SettingItemNarrowProps> = ({ setCurrentTab, tabKey, label }) => {
  const theme = useMantineTheme()

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid ${theme.colors.grey[1]};
        padding: 18px 0;
        cursor: pointer;
      `}
      onClick={() => setCurrentTab(tabKey)}
    >
      <div
        className={css`
          font-weight: 600;
          display: flex;
          align-items: center;
        `}
      >
        {label}
      </div>
      <div>
        <FontAwesomeIcon icon={faAngleRight} size="lg" color={theme.colors.grey[3]} />
      </div>
    </div>
  )
}
