import { useMantineTheme } from '@mantine/core'
import { FC } from 'react'
import { colors } from 'settings/colors'
import { userDetailsStore } from 'stores/userDetailsStore'
import { UserAvatar } from './UserAvatar'

export const Header: FC = () => {
  const { firstName, lastName } = userDetailsStore.getState()
  const theme = useMantineTheme()

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        borderBottom: `1px solid ${colors.grey200}`,
        paddingBottom: 25,
      }}
    >
      <UserAvatar backgroundColor={theme.colors.grey[3]} textColor="#ffffff" />
      <div css={{ fontSize: 20, fontWeight: 700, display: 'flex', alignItems: 'center', marginLeft: 20 }}>
        {firstName} {lastName}
      </div>
    </div>
  )
}
