import { MapContext } from 'App/Map/MapContext/MapContext'
import React, { DependencyList, useCallback, useContext, useEffect, useRef } from 'react'
import { Point } from '@turf/helpers'
import { serviceCenterStore, TNodeDeviceType } from '../../../store/serviceCenterStore'
import * as Sentry from '@sentry/react'
import { mapStore } from 'stores/mapStore'
import { getNodeEquipmentType } from '../../_utils/getNodeEquipmentType'
import { transformCoordsToLatLng } from '../../_utils/transformCoordsToLatLng'
import { getNodeMapMarker } from '../../_utils/getNodeMapMarker'
import { TNodeStatus, TNodeType } from '../../../types'
import { DEVICE_TYPES } from '../../_utils/deviceTypes'

interface Node {
  id: string
  geometry: Point
  nodeType: TNodeType
  deviceTypes?: TNodeDeviceType[]
  hasIssue?: boolean
  status: TNodeStatus
}

type MAP_ITEM = Node & { overlay?: google.maps.Marker; bounds: google.maps.LatLngBounds }

interface NodeRendererProps {
  nodes: Node[]
  selectedNodeId?: string | null
  // Any dependencies change will trigger a render of the map overlays (map bounds and nodes array are already included)
  dependencies?: DependencyList
}

export const NodeRenderer: React.FC<NodeRendererProps> = ({ nodes, selectedNodeId, dependencies }) => {
  const { map } = useContext(MapContext)
  const mapBounds = mapStore.useSelector((s) => s.bounds)
  const mapItemsCache = useRef<Record<string, MAP_ITEM>>({})

  const handleClick = useCallback((nodeId: string) => {
    const nodeStatus = mapItemsCache.current[nodeId].status

    const selectedEquipmentId = serviceCenterStore.selectors.getSelectedEquipmentId(
      serviceCenterStore.getState(),
    )

    const clickedNodeCoords = transformCoordsToLatLng(mapItemsCache.current[nodeId].geometry)
    const zoomLevel = map?.getZoom()

    let latAdjustment

    if (zoomLevel && zoomLevel > 17) {
      latAdjustment = 0.0001
    } else if (zoomLevel && zoomLevel > 16) {
      latAdjustment = 0.001
    } else {
      latAdjustment = 0.003
    }

    // set map center slightly below the clicked node to give space for the selected equipment card
    map?.panTo({ ...clickedNodeCoords, lat: clickedNodeCoords.lat - latAdjustment })

    const listner = map?.addListener('idle', () => {
      // set selected equipment after the map has finished panning

      if (selectedEquipmentId === nodeId) {
        // Unselect the node if already selected
        serviceCenterStore.actions.selectEquipment(
          null,
          nodeStatus === TNodeStatus.PLANNED ? 'planned' : 'active',
        )
      } else {
        // Select the node
        serviceCenterStore.actions.selectEquipment(
          nodeId,
          nodeStatus === TNodeStatus.PLANNED ? 'planned' : 'active',
        )
      }

      listner?.remove()
    })
  }, [])

  const isNodeVisible = (item: MAP_ITEM, mapBounds: google.maps.LatLngBoundsLiteral): boolean => {
    // Selected node is always visible
    if (selectedNodeId && selectedNodeId === item.id) return true

    // Hide nodes outside map viewport
    if (!item.bounds.intersects(mapBounds)) return false

    const { mapEquipmentStatuses, mapEquipmentTypes, mapEquipmentDevices } = serviceCenterStore.getState()
    const equipmentType = getNodeEquipmentType(item.nodeType)

    // Filter out nodes that are not in the list of selected equipment types
    if (mapEquipmentTypes.length && !mapEquipmentTypes.includes(equipmentType)) return false

    // Filter out nodes that are not in the list of selected equipment statuses
    if (
      (mapEquipmentStatuses.length && item.status && !mapEquipmentStatuses.includes(item.status)) ||
      (!item.status && !mapEquipmentStatuses.includes(TNodeStatus.PENDING))
    ) {
      return false
    }

    if (mapEquipmentDevices.length && mapEquipmentDevices.length !== DEVICE_TYPES.length) {
      // Filter out nodes that do not contain one of the selected devices
      // Nodes without devices will be hidden if at least one or more device type is selected
      if (!item.deviceTypes?.some((deviceType) => mapEquipmentDevices.includes(deviceType))) {
        return false
      }
    }

    return true
  }

  const renderItems = (mapBounds: google.maps.LatLngBoundsLiteral) => {
    Object.values(mapItemsCache.current).forEach((item) => {
      if (isNodeVisible(item, mapBounds)) {
        let icon

        try {
          icon = getNodeMapMarker(item.nodeType, item.status, {
            deviceTypes: item.deviceTypes,
            selected: selectedNodeId === item.id,
            showBubble: item.hasIssue,
          })
        } catch (e) {
          Sentry.captureException(e)

          // Skip rendering the node if the icon is not available
          return
        }

        if (!item.overlay) {
          // Create marker overlay, if not already existing
          item.overlay = new google.maps.Marker({
            position: transformCoordsToLatLng(item.geometry),
            icon,
            map,
            zIndex: 100000,
          })

          // Add click listener
          ;(item.overlay as google.maps.MVCObject).addListener('click', handleClick.bind(null, item.id))
        } else {
          item.overlay.setOptions({
            position: transformCoordsToLatLng(item.geometry),
            map,
            icon,
          })
        }
      } else if (item.overlay) item.overlay.setMap(null)
    })
  }

  const clearItems = () => {
    Object.values(mapItemsCache.current).forEach((item) => {
      if (item.overlay) {
        google.maps.event.clearListeners(item.overlay, 'click')

        item.overlay.setVisible(false)

        item.overlay.setMap(null)
      }
    })

    mapItemsCache.current = {}
  }

  // Remove items from map when component unmount
  useEffect(() => {
    return function cleanup() {
      clearItems()
    }
  }, [])

  // Update cache when nodes list change
  useEffect(() => {
    nodes.forEach((node) => {
      const nodeBounds = new google.maps.LatLngBounds({
        lat: node.geometry.coordinates[1],
        lng: node.geometry.coordinates[0],
      })

      // Keep existing item data (overlay, etc.)
      const existingItem = mapItemsCache.current[node.id]

      mapItemsCache.current[node.id] = {
        ...existingItem,
        ...node,
        bounds: nodeBounds,
      }
    })

    const updatedNodeIds = nodes.map((node) => node.id)

    // Remove nodes that are not in the list anymore
    Object.keys(mapItemsCache.current).forEach((nodeId) => {
      if (!updatedNodeIds.includes(nodeId)) {
        const { overlay } = mapItemsCache.current[nodeId]

        // Remove node from map
        if (overlay) overlay.setMap(null)

        delete mapItemsCache.current[nodeId]
      }
    })
  }, [nodes])

  // Update nodes rendering on map when dependencies change
  useEffect(() => {
    if (mapBounds) renderItems(mapBounds)
  }, [mapBounds, nodes, ...(dependencies || [])])

  return null
}
