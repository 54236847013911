import { TNavigationMenuItem } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateFlatNavigationList'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { Dispatch, SetStateAction } from 'react'
import { TLayer } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { TNewPresetState } from '../../Presets'

export const ValueGroupCheckbox = ({
  childLayers,
  label,
  newPresetState,
  setNewPresetState,
  value,
}: {
  childLayers?: TNavigationMenuItem['childLayers']
  label: string
  newPresetState: TNewPresetState
  setNewPresetState: Dispatch<SetStateAction<TNewPresetState>>
  value: string
}) => {
  const checked = childLayers
    ? Object.values(childLayers).every((cl) => !!newPresetState?.selectedValueGroups?.includes(cl.key))
    : !!newPresetState?.selectedValueGroups?.includes(value)

  return (
    <Checkbox
      css={{ marginBottom: 12 }}
      size="md"
      checked={checked}
      label={label}
      onChange={() => {
        let newValueGroupsSetting = newPresetState?.selectedValueGroups?.slice() ?? []

        if (childLayers) {
          if (checked) {
            newValueGroupsSetting = newValueGroupsSetting.filter(
              (valueGroup) => !childLayers[valueGroup as TLayer],
            )
          } else {
            newValueGroupsSetting = newValueGroupsSetting.concat(Object.keys(childLayers))
          }

          setNewPresetState((s) => ({ ...s, selectedValueGroups: newValueGroupsSetting }))

          return
        }

        if (checked) {
          newValueGroupsSetting = newValueGroupsSetting.filter((valueGroup) => valueGroup !== value)
        } else {
          newValueGroupsSetting = newValueGroupsSetting.concat(value)
        }

        setNewPresetState((s) => ({ ...s, selectedValueGroups: newValueGroupsSetting }))
      }}
    />
  )
}
