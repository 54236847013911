// TODO: move to @semios/app-platform-common
import { isEmpty, isNil } from 'lodash'

// a fun and efficient alternative to .match()
const quickStringMatch = (input?: string, searchForThis?: string) => {
  if (isNil(input) || isNil(searchForThis)) return false

  const inputFormatted = input?.toString().toLowerCase()

  // will ne a number>= 1 if a match, and 0 if no match, so we can just cast it to a boolean
  return !!(inputFormatted.length - inputFormatted.replace(searchForThis.toString().toLowerCase(), '').length)
}

// arrayOfObjectsSearch is a function called with something like this:
//
// arrayOfObjectsSearch(dataArray, 'scott 3033', ['id', 'first_name'])
//
// it returns the input array but filtered where at least one key for each
// array-element-object matches all the search terms.
export const arrayOfObjectsSearch = <T extends Record<string, unknown>>(
  inputArray: T[],
  searchTerms: string | string[],
  objectKeysToSearchThrough: keyof T | (keyof T)[],
): typeof inputArray => {
  if (!searchTerms || isEmpty(searchTerms) || !inputArray || isEmpty(inputArray)) return inputArray

  // allow the programmer to give a string or an array for searchTerms
  const searchTermsArray = Array.isArray(searchTerms)
    ? searchTerms
    : searchTerms.split(' ').filter((term) => term !== '')

  const objectKeysToSearchThroughArray = Array.isArray(objectKeysToSearchThrough)
    ? objectKeysToSearchThrough
    : objectKeysToSearchThrough.toString().split(' ')

  // return the filtered array (which also has a reduce chained on)
  return inputArray.filter((row) => {
    // go through every search term given
    return (
      searchTermsArray
        .map((term: string) => {
          // go through every property listed
          return objectKeysToSearchThroughArray
            .map((col) => {
              if (row[col]) {
                // do a check to see if the search term is included. Cast it to a number, since we'll add it up later
                return +quickStringMatch(String(row[col]), term)
              }

              return 0
            })
            .reduce((a: number, b) => a + b, 0)
        })
        // since we need ALL search terms to be matched on,
        // and since quickStringMatch returns a number > 0
        // when there is a match and a non-match is a 0, we
        // can multiply in our reduce so that way non-matches
        // will be excluded (since anything multiplied by zero
        // is also zero, which is a falsy value), since
        // filters, eliminate falsy values
        .reduce((a, b) => a * b, 1)
    )
  })
}
