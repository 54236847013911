import { css } from '@emotion/css'
import { Tabs } from 'components/Tabs'

export const TabsExamples2 = () => {
  return (
    <Tabs containerWidth={900} defaultTab={'Dashboard'}>
      <Tabs.TabHeaderList>
        <Tabs.TabHeader
          value={'Dashboard'}
          onClick={() => {
            // eslint-disable-next-line no-console
            console.log('clicked')
          }}
        >
          {'Dashboard'}
        </Tabs.TabHeader>
        <Tabs.TabHeader
          value={'Summary'}
          onClick={() => {
            // eslint-disable-next-line no-console
            console.log('clicked')
          }}
        >
          {'Summary'}
        </Tabs.TabHeader>
      </Tabs.TabHeaderList>
      <Tabs.TabPanel value={'Dashboard'}>
        <h3>{'Dashboard'}</h3>
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only five centuries, but also the
          leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
          with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
          publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
      </Tabs.TabPanel>
      <Tabs.TabPanel value={'Summary'}>
        <div
          className={css`
            margin: 15px 0;
          `}
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only five centuries, but also the
          leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
          with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
          publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
      </Tabs.TabPanel>
    </Tabs>
  )
}
