import { routes } from '@semios/app-platform-banyan-route-definitions'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import { sortByKey } from 'utils/sortByKey'
import { TSearchResultsItem } from '../types'

export const getSearchResultsOrganizations = ({
  properties,
  searchText,
}: {
  properties: NonNullable<routes.UserAppStartup.Response['properties']>
  searchText: string
}): TSearchResultsItem[] => {
  const organizationsPossibilities = Object.values(properties)
    .sort(sortByKey('propertyName'))
    .reduce((a: Record<string, TFieldAssetKeyTypes.TPropertyId[]>, b) => {
      a[b.organizationName] = a[b.organizationName] ?? []

      a[b.organizationName].push(b.propertyId)

      return a
    }, {})

  const organizationResults: {
    organizationName: string
    propertyIds: TFieldAssetKeyTypes.TPropertyId[]
  }[] = []

  Object.entries(organizationsPossibilities).forEach(([organizationName, propertyIds]) => {
    if (searchStringFromStringWithSpaces(searchText, organizationName)) {
      organizationResults.push({ organizationName, propertyIds })
    }
  })

  return organizationResults.sort(sortByKey('organizationName')).map((o) => ({
    type: 'ORGANIZATION',
    label: o.organizationName,
    propertyIds: o.propertyIds,
    value: `ORGANIZATION-${o.organizationName}`,
    group: translate.phrases.templates('{{label}} ({{count}})', {
      label: translate.phrases.banyanApp('Organization'),
      count: organizationResults.length,
    }),
  }))
}
