import type { CSSObject } from '@emotion/react'
import { faAngleLeft, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Modal, useMantineTheme } from '@mantine/core'
import { Dispatch, FC, ReactNode, SetStateAction } from 'react'
import { colors } from 'settings/colors'
import { useScreenOrientation } from 'stores/smallStore'
import { usePreventPanelDetailsFromBeingOpen } from 'utils/usePreventPanelDetailsFromBeingOpen'

export const NarrowScreenSearch: FC<{
  children: ReactNode
  opened: boolean
  open: () => void
  close: () => void
  setSearchText: Dispatch<SetStateAction<string>>
}> = (props) => {
  const theme = useMantineTheme()
  const screenOrientation = useScreenOrientation()
  const preventPanelDetailsFromBeingOpen = usePreventPanelDetailsFromBeingOpen()

  const narrowScreenContainerStyles: CSSObject = {
    position: 'fixed',
    top: 12,
    margin:
      'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
    zIndex: screenOrientation === 'landscape' ? 3 : 4,
    right: !preventPanelDetailsFromBeingOpen && screenOrientation === 'landscape' ? 75 : 12,
  }

  const handleOnClose = () => {
    props.setSearchText('')

    props.close()
  }

  return (
    <div css={narrowScreenContainerStyles}>
      <ActionIcon
        css={{
          width: 45,
          height: 45,
          backgroundColor: 'white',
          borderRadius: 3,
        }}
        onClick={() => {
          props.setSearchText('')

          props.open()
        }}
      >
        <FontAwesomeIcon icon={faSearch} size="lg" color={theme.colors.grey[3]} />
      </ActionIcon>
      <Modal
        transitionProps={{ duration: 0 }} // to reduce some stutter
        opened={props.opened}
        fullScreen
        withCloseButton={false}
        onClose={handleOnClose}
        styles={() => ({
          body: {
            padding: '0px !important',
          },
        })}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))',
            margin:
              'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
          }}
        >
          <ActionIcon onClick={handleOnClose} css={{ position: 'absolute', zIndex: 10 }}>
            <FontAwesomeIcon icon={faAngleLeft} size="lg" color={colors.grey800} />
          </ActionIcon>
          {props.children}
        </div>
      </Modal>
    </div>
  )
}
