import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'
import { appInfo } from '../appInfo'
import { isSemiosEmployeeOrTester } from 'utils/isSemiosEmployeeOrTester'

declare global {
  interface Window {
    _hsq?: Array<['identify', { email: string; id: string }]> // hubspot... just making it specific to how we use it for now.
  }
}

export const updateUserTrackers = (o: { email: string; userId: number }) => {
  const userIdForTrackers = isSemiosEmployeeOrTester() ? `employee-${o.userId}` : `customer-${o.userId}`

  if (appInfo.useSentry) {
    Sentry.configureScope((scope) => {
      scope.setUser({ id: userIdForTrackers })
    })
  }

  if (appInfo.useFullStory) {
    FullStory.identify(userIdForTrackers, { displayName: userIdForTrackers })
  }

  if (appInfo.useHubspot && Array.isArray(window._hsq)) {
    window._hsq.push(['identify', { id: userIdForTrackers, email: o.email }])
  }
}
