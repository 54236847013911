import { alertRulesObjectFromArray } from '../../../ModalDrawerCreateOrEditAlert/AlertForm/_utils/alertRulesObjectFromArray'
import { Rule, unitSpeedDisplay, unitTempDisplay } from '../../../settings/alertSettings'
import { translate } from 'i18n/i18n'
import { Separator } from '../RulesRow/RulesRow'
import { useScreenSize } from 'utils/useScreenSize'

const ruleLabel = ({
  alertRuleKey,
  params,
  rulesObject,
}: {
  alertRuleKey: string
  params: {
    value: Rule['value']
    windUnit: string
    tempUnit: string
  }
  rulesObject: { [key: string]: Rule }
}) => {
  const { value, windUnit, tempUnit } = params
  const { measurementHeights } = rulesObject

  let label = ''

  switch (alertRuleKey) {
    case 'hasRain':
      return [translate.phrases.banyanApp('Rain is forecast in the next 7 days')]

    case 'windGreaterThanOrEqualToKph':
      return [
        translate.phrases.banyanApp('Wind Speed >= {{ windSpeed }} {{ unit }} in the next 7 days', {
          windSpeed: value,
          unit: windUnit,
        }),
      ]

    case 'temperatureGreaterThanOrEqualToCelsius':
      return ((measurementHeights?.value as string[]) || []).map((m) => {
        if (m === 'aboveCanopyTemperature') label = 'Above Canopy Temperature'

        if (m === 'inCanopyTemperature') label = 'In Canopy Temperature'

        return translate.phrases.banyanApp('{{ label }} >= {{ temperature }} {{ unit }} in the next 7 days', {
          label,
          temperature: value,
          unit: tempUnit,
        })
      })

    case 'temperatureLessThanOrEqualToCelsius':
      return ((measurementHeights?.value as string[]) || []).map((m) => {
        if (m === 'aboveCanopyTemperature') label = 'Above Canopy Temperature'

        if (m === 'inCanopyTemperature') label = 'In Canopy Temperature'

        return translate.phrases.banyanApp('{{ label }} <= {{ temperature }} {{ unit }} in the next 7 days', {
          label,
          temperature: value,
          unit: tempUnit,
        })
      })

    case 'includeAllDevices':
      return [translate.phrases.banyanApp('Include all devices = {{ value }}', { value: value })]

    default:
      return ['']
  }
}

export const WeatherForecastRulesRow = ({ rules }: { rules: Rule[] }) => {
  const rulesObject = alertRulesObjectFromArray(rules)
  const windUnit = unitSpeedDisplay()
  const tempUnit = unitTempDisplay()
  const { isWideScreen } = useScreenSize()

  let rulesRow = rules
    .filter((r) => {
      // weatherForecastAlert has these keys in rules

      if (
        [
          'startDate',
          'endDate',
          'propertyIds',
          'stationGeoms',
          'blockIds',
          'hourOfDay',
          'measurementHeights',
        ].includes(r.key)
      )
        return false

      return true
    })
    .map((r, index) => {
      return (
        <div
          key={r.key}
          css={{
            margin: 0,
            textAlign: 'left',
            display: 'flex',
            flexDirection: isWideScreen ? 'row' : 'column',
          }}
        >
          {index > 0 && isWideScreen && <Separator />}
          {ruleLabel({
            alertRuleKey: r.key,
            params: { value: r.value, windUnit, tempUnit: tempUnit },
            rulesObject,
          }).map((label, i) => (
            <div key={label} css={{ display: 'flex', flexDirection: isWideScreen ? 'row' : 'column' }}>
              {i > 0 && isWideScreen && <Separator />}
              {label}
            </div>
          ))}
        </div>
      )
    })

  return (
    <div css={{ display: 'flex', flexDirection: isWideScreen ? 'row' : 'column', flexWrap: 'wrap' }}>
      {rulesRow}
    </div>
  )
}
