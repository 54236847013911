import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'

export const serviceCenterServiceIssueResolutionActionsGet = async (nodeIssueTypeId: number) => {
  try {
    const resolutionActions = await apiFetch<
      routes.ServiceCenterServiceIssueResolutionActionsGet.Request,
      routes.ServiceCenterServiceIssueResolutionActionsGet.Response
    >({
      url: routes.ServiceCenterServiceIssueResolutionActionsGet.path,
      body: {
        nodeIssueTypeId,
      },
      params: {
        method: 'POST',
      },
    })

    return resolutionActions
  } catch (error) {
    throw new Error(`Error fetching list of resolution actions: ${(error as Error).message}`)
  }
}
