import { AlertSection } from '../../../components/AlertSection'
import { translate } from 'i18n/i18n'
import { AlertTypeId, AlertsSettings } from '../../../settings/alertSettings'
import { Dispatch, SetStateAction } from 'react'
import { SelectDropdown } from 'components/SelectDropdown/SelectDropdown'

const AlertFrequencyWording = ({ minutes }: { minutes: number }) => {
  switch (minutes) {
    case 10:
      return translate.phrases.banyanApp('Real-time (every 10 minutes)')

    case 30:
      return translate.phrases.banyanApp('30-min summary')

    case 60:
      return translate.phrases.banyanApp('1-hour summary')

    case 180:
      return translate.phrases.banyanApp('3-hour summary')

    case 360:
      return translate.phrases.banyanApp('6-hour summary')

    case 1440:
      return translate.phrases.banyanApp('Every day')

    default:
      return translate.phrases.banyanApp('Every {{minutes}} minutes', { minutes })
  }
}

export const Frequency = ({
  alertTypeId,
  frequencyMinutes,
  setFrequencyMinutes,
}: {
  alertTypeId: AlertTypeId
  frequencyMinutes: number | null
  setFrequencyMinutes: Dispatch<SetStateAction<number | null>>
}) => {
  const frequencyMinutesOptions = AlertsSettings.frequencyMinutesOptions[alertTypeId]

  if (!(frequencyMinutesOptions && frequencyMinutesOptions.length)) return null

  const data = frequencyMinutesOptions.map((minutes) => ({
    value: minutes.toString(),
    label: AlertFrequencyWording({ minutes }),
  }))

  return (
    <AlertSection title={translate.phrases.banyanApp('Frequency')}>
      <SelectDropdown
        data={data}
        value={frequencyMinutes?.toString()}
        onChange={(newFrequency) => {
          if (newFrequency) {
            setFrequencyMinutes(Number(newFrequency))
          }
        }}
      />
    </AlertSection>
  )
}
