import { TFieldAssetKeyTypes } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'

export const getTimezoneForSelectedProperty = (
  {
    properties,
    propertyId,
  }: {
    properties: ReturnType<typeof fieldAssetStore.getState>['properties']
    propertyId: TFieldAssetKeyTypes.TPropertyId | null
  } = {
    properties: fieldAssetStore.getState().properties,
    propertyId: selectedFieldAssetsStore.getState().property,
  },
) => {
  return properties?.[propertyId as number]?.timezone ?? 'America/Los_Angeles'
}
