import { SettingsEditButton } from 'App/Map/UserSettingsMenu/components/SettingsEditButton/SettingsEditButton'
import { TAvailablePreset } from 'App/Map/types'
import { translate } from 'i18n/i18n'

export const DuplicatePresetButton = ({
  duplicatePreset,
  preset,
}: {
  duplicatePreset: (a: TAvailablePreset) => void
  preset: TAvailablePreset
}) => {
  return (
    <SettingsEditButton
      label={translate.phrases.banyanApp('Duplicate')}
      onClick={() => {
        duplicatePreset(preset)
      }}
    />
  )
}
