import UAParser from 'ua-parser-js'
import { APP_PLATFORM_VERSION } from '@semios/app-platform-version'

/**
 * appMetaDataMaker
 *
 * A function to generate an object compatible with the Semios app/device
 * analytics data for the Semios authentication calls
 *
 * @returns {Object} appMeta object
 */
export const appMetaDataMaker = () => {
  let appMeta = {}

  try {
    const parser = new UAParser()
    const { browser, device, os } = parser.setUA(window.navigator.userAgent).getResult() || {}
    const { name: browserName = null, version: browserVersion = null } = browser || {}
    const { type: deviceType = null, vendor: deviceVendor = null } = device || {} // maybe will never give us anything useful
    const { name: osName = null, version: osVersion = null } = os || {}

    appMeta = {
      appName: 'semios-hub',
      appVersion: APP_PLATFORM_VERSION || null,
      browser: !browserName ? null : `${browserName} - ${browserVersion}`,
      deviceDimensions: `${window.innerWidth}x${window.innerHeight}`,
      deviceType,
      deviceVendor,
      operatingSystem: !osName ? null : `${osName} - ${osVersion}`,
    }
  } catch (err) {}

  return appMeta
}
