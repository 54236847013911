import { Dispatch, SetStateAction, useEffect } from 'react'
import { AlertsSettings, Rule, unitTempDisplay } from '../../../../settings/alertSettings'
import { alertRulesObjectFromArray } from '../../_utils/alertRulesObjectFromArray'
import { AlertsValidators, isNumberOrNumericString } from '../../../../AlertValidators/AlertValidators'
import { validateStatusErrorHelper } from '../../../../../_utils/validateStatusErrorHelper'
import { helpTextErrorHelper } from '../../_utils/helpTextErrorHelper'
import { updateAlertRules } from '../../_utils/updateAlertRules'
import { TranslatePhraseWrapper } from '../../../../components/TranslatePhraseWrapper'
import { translate } from 'i18n/i18n'
import { NumberInput } from '@mantine/core'
import { getErrorStyle } from '../../../../_utils/getErrorStyle'
import { ErrorTextWrapper } from '../../../../components/ErrorTextWrapper'
import { TranslateComponentWrapper } from '../../../../components/TranslateComponentWrapper'

export const WetBulbThreshold = ({
  rules,
  setRules,
  setThresholdIsValid,
  triedToSubmit,
}: {
  rules: Rule[]
  setRules: Dispatch<SetStateAction<Rule[]>>
  setThresholdIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  useEffect(() => {
    if (!rules || !rules.length) setRules(AlertsSettings.emptyRules.wetBulb)
  })

  const { wetBulb: wetBulbRule } = alertRulesObjectFromArray(rules)
  const wetBulbTemperature = wetBulbRule ? wetBulbRule.value : null
  // check if user-enterable fields are dirty
  const wetBulbTemperatureIsDirty = wetBulbTemperature !== null || triedToSubmit
  // validate the user-entered values
  const wetBulbTemperatureError = AlertsValidators.temperatureWetBulb({ temperature: wetBulbTemperature })

  // attach a status
  const wetBulbTemperatureValidateStatus = validateStatusErrorHelper(
    wetBulbTemperatureIsDirty && !!wetBulbTemperatureError,
  )

  // attach an error message
  const wetBulbTemperatureHelp = helpTextErrorHelper(wetBulbTemperatureIsDirty && wetBulbTemperatureError)
  const errorStyle = getErrorStyle()

  // update the rules upon changes
  useEffect(() => {
    setThresholdIsValid(!wetBulbTemperatureError)
  }, [wetBulbTemperature])

  const unitTemp = unitTempDisplay()

  return (
    <>
      <TranslatePhraseWrapper>
        <translate.Phrases.banyanApp
          k="Send alert when wet bulb temperature is less than or equal to <numberInput/> {{unitTemp}}"
          v={{ unitTemp }}
          c={{
            numberInput: (
              <TranslateComponentWrapper>
                <NumberInput
                  onChange={(value) =>
                    updateAlertRules({ rules, setRules, rulesToChange: { wetBulb: { value } } })
                  }
                  css={{ width: 80, margin: '0 5px' }}
                  type="number"
                  classNames={{ input: wetBulbTemperatureValidateStatus ? errorStyle : undefined }}
                  value={isNumberOrNumericString(wetBulbTemperature) ? Number(wetBulbTemperature) : ''}
                  precision={10}
                  removeTrailingZeros={true}
                />
                <div />
              </TranslateComponentWrapper>
            ),
          }}
        />
      </TranslatePhraseWrapper>
      {wetBulbTemperatureHelp && <ErrorTextWrapper>{wetBulbTemperatureHelp}</ErrorTextWrapper>}
    </>
  )
}
