import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput'
import { useState } from 'react'
import { Alert } from 'stores/userDetailsStore'
import { mapAlertIdTypeToLabel } from '../mapAlertIdTypeToLabel'
import { sortByKey } from '@semios/app-platform-common'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'

export const useSearchAlerts = ({
  initialList,
  placeholder,
}: {
  initialList: Alert[]
  placeholder: string
}) => {
  const [searchText, setSearchText] = useState('')

  const filteredList = initialList.filter((item: Alert) => {
    return (
      searchStringFromStringWithSpaces(searchText, mapAlertIdTypeToLabel(item.type.id)) ||
      searchStringFromStringWithSpaces(searchText, item.name)
    )
  })

  const filteredAndSortedList = filteredList.sort(sortByKey('createdAt', -1))

  return {
    SearchInputComponent: (
      <div css={{ margin: '10px 0px' }}>
        <SearchTextInput placeholder={placeholder} searchText={searchText} setSearchText={setSearchText} />
      </div>
    ),
    filteredAndSortedList,
  }
}
