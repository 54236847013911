import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from '@emotion/css'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

export const AngleDownIcon = ({ showTabs }: { showTabs: boolean }) => {
  return (
    <FontAwesomeIcon
      className={css({
        marginLeft: 12,
        transform: showTabs ? 'rotate(180deg)' : 'unset',
        transition: 'transform 0.3s ease',
      })}
      icon={faAngleDown}
    />
  )
}
