import { routes } from '@semios/app-platform-banyan-route-definitions'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { isNil } from 'lodash'
import moment from 'moment-timezone'
import { Dispatch, SetStateAction } from 'react'
import { useApiREST } from 'utils/useApiREST'
import { TTimeseries } from '../TrapBadgeComponent'

export const useFetchMostRecentImageAndAddToState = ({
  insectId,
  lngLat,
  preventFetch,
  setTimeseriesTrapCatchDataObject,
  setSelectedDate,
  timezone,
}: {
  insectId?: TFieldAssetKeyTypes.TInsectId
  lngLat: TFieldAssetKeyTypes.TLngLat
  preventFetch: boolean
  setTimeseriesTrapCatchDataObject: Dispatch<SetStateAction<Record<string, TTimeseries>>>
  setSelectedDate: Dispatch<SetStateAction<string>>
  timezone: string
}) => {
  const mostRecentValueType =
    `insectTrapCatches_mostRecentImage_insectId${insectId}` as 'insectTrapCatches_mostRecentImage_insectId1' // a typescript hack until we unify our types

  const { loading: mostRecentImageLoading } = useApiREST<
    routes.ValuesCurrent.Request,
    routes.ValuesCurrent.Response,
    null
  >({
    url: routes.ValuesCurrent.path,
    body: {
      points: {
        lngLats: [lngLat],
        values: {
          [mostRecentValueType]: true,
        },
      },
    },
    initialState: null,
    preventFetch,
    watchers: [preventFetch],
    shaper: (data) => {
      const dataForDay = data?.points?.[lngLat]?.[mostRecentValueType]?.value

      if (!isNil(dataForDay)) {
        const formattedTimeStamp = moment.tz(dataForDay.timestamp, timezone).startOf('day').toISOString()

        const dataObjectForMostRecent: Record<string, TTimeseries> = {
          [formattedTimeStamp]: {
            imageHash: dataForDay.hash,
            value: Number(dataForDay.count),
            imageTakenOn: dataForDay.takenOn,
            timestamp: formattedTimeStamp,
          },
        }

        setTimeseriesTrapCatchDataObject((prevState) => ({ ...prevState, ...dataObjectForMostRecent }))

        setSelectedDate((prevSelectedDate) => {
          if (!!prevSelectedDate) return prevSelectedDate

          return formattedTimeStamp
        })
      }

      return null
    },
  })

  return { mostRecentImageLoading }
}
