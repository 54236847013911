import React from 'react'
import { translate } from 'i18n/i18n'
import { closeModal, openModal } from '@mantine/modals'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'

export const ACTION_MENU_MODAL_ID = 'action-menu'

export function openActionMenu(actions: React.ReactNode) {
  openModal({
    modalId: ACTION_MENU_MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: <ActionMenuModal>{actions}</ActionMenuModal>,
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

interface ActionMenuModalProps {
  children: React.ReactNode
  title?: string
}

export const ActionMenuModal: React.FC<ActionMenuModalProps> = ({ children, title }) => {
  const handleClose = () => {
    closeModal(ACTION_MENU_MODAL_ID)
  }

  return (
    <>
      <WideHeader
        title={title || translate.phrases.placeholder('More Options')}
        onClose={handleClose}
        isSecondaryModal={false}
      />

      <div css={{ padding: 10, paddingTop: 0 }}>{children}</div>
    </>
  )
}
