import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from 'lodash'
import { unitConverter } from 'utils/unitConverter/unitConverter'

export const makeHeatmapSeries = ({
  data,
  limitValuesTo100,
  valueType,
  soilStationLngLat,
}: {
  data: routes.Values.Response
  limitValuesTo100?: boolean
  valueType: 'soilMoisture' | 'soilIonicContent'
  soilStationLngLat: string | null
}) => {
  const soilData = data?.points?.[String(soilStationLngLat)]?.values?.[valueType]

  if (!soilData) return []

  const depthUnitSuffix = unitConverter.soilDepth().suffix()
  const unitConverterToUse = valueType === 'soilMoisture' ? 'soilAwc' : 'soilIonicContent'
  const allDepths = soilData.map(({ metadata: { depth } }) => Number(depth)).sort((a, b) => a - b)

  const heatmapSeries = soilData.flatMap(({ metadata: { depth }, timeseries }) => {
    const foundYIndex = allDepths.indexOf(Number(depth))
    const convertedDepth = unitConverter.soilDepth(Number(depth)).value() || 0
    const yAxisLabel = `${convertedDepth} ${depthUnitSuffix}`

    return timeseries.map(({ timestamp, value }) => {
      const unixTimestamp = +new Date(timestamp)

      let fixedYValue = null

      if (isNil(value)) {
        fixedYValue = null
      } else if (typeof value === 'number') {
        fixedYValue = unitConverter[unitConverterToUse](value).value()
      } else if (typeof value === 'object') {
        fixedYValue = unitConverter[unitConverterToUse](value?.average).value()
      }

      const limitedYValue = fixedYValue && Math.max(0, Math.min(fixedYValue, 100))

      return {
        x: unixTimestamp,
        y: foundYIndex,
        value: limitValuesTo100 ? limitedYValue : fixedYValue,
        // if we're limiting the values to 100, we need to set the trueValue to null so that the tooltip shows the correct value
        trueValue: limitValuesTo100 ? fixedYValue : null,
        name: yAxisLabel,
        type: 'heatmap',
      }
    })
  })

  const heatmapSeriesHasData = heatmapSeries.some(({ value }) => value !== null)

  return heatmapSeriesHasData ? heatmapSeries : []
}
