import { routes } from '@semios/app-platform-banyan-route-definitions'
import type {
  TChartSeries,
  TChartSeriesWithTRGBAColorWith1AtTheEndColor,
} from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { sortNumber } from 'utils/sortNumber'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { isAggregatedType } from '../../_utils/isAggregatedValueType'
import { roundToDecimalPlaces } from '@semios/app-platform-common'

export const SOIL_MOISTURE_COLORS_ARRAY = [
  colors.awcGreen,
  colors.awcBlue,
  colors.awcPurple,
  colors.awcRed,
  colors.awcGreen800,
  colors.awcOrange800,
  colors.awcBlue800,
  colors.awcPink800,
  colors.awcPurple800,
  colors.awcYellow800,
  colors.awcRed800,
  colors.awcGreen500,
  colors.awcOrange500,
  colors.awcBlue600,
  colors.awcPink500,
  colors.awcPurple300,
]

const soilMoistureHeightColorMapper: { [key: string]: string } = {
  '5': SOIL_MOISTURE_COLORS_ARRAY[0],
  '15': SOIL_MOISTURE_COLORS_ARRAY[1],
  '25': SOIL_MOISTURE_COLORS_ARRAY[2],
  '35': SOIL_MOISTURE_COLORS_ARRAY[3],
  '45': SOIL_MOISTURE_COLORS_ARRAY[4],
  '55': SOIL_MOISTURE_COLORS_ARRAY[5],
  '65': SOIL_MOISTURE_COLORS_ARRAY[6],
  '75': SOIL_MOISTURE_COLORS_ARRAY[7],
  '85': SOIL_MOISTURE_COLORS_ARRAY[8],
  '95': SOIL_MOISTURE_COLORS_ARRAY[9],
  '105': SOIL_MOISTURE_COLORS_ARRAY[10],
  '115': SOIL_MOISTURE_COLORS_ARRAY[11],
  '125': SOIL_MOISTURE_COLORS_ARRAY[12],
  '135': SOIL_MOISTURE_COLORS_ARRAY[13],
  '145': SOIL_MOISTURE_COLORS_ARRAY[14],
  '155': SOIL_MOISTURE_COLORS_ARRAY[15],
}

export const getSoilMoistureHeightColor = (height: string) => {
  return soilMoistureHeightColorMapper[height] || 'black'
}

export const makeSoilSeries: ({
  data,
  compareSeasonsData,
  soilStationLngLat,
  valueType,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  soilStationLngLat: string | null
  valueType: 'soilMoisture' | 'soilTemperature'
}) => TChartSeries[] = ({ data, compareSeasonsData, soilStationLngLat, valueType }) => {
  const soilData = data?.points?.[String(soilStationLngLat)]?.values?.[valueType]

  if (!soilData || !soilData.length) return []

  const { averageSelectedSoilDepths, compareSeasonsInterval, soilVisibility } = detailsPanelStore.getState()
  const depthUnitType = unitConverter.soilDepth().suffix()
  const unitConverterToUse = valueType === 'soilMoisture' ? 'soilAwc' : 'soilTemperature'
  const seriesZIndex = 100
  const series: TChartSeries[] = []
  const averageRegularSeriesDataDictionary: Record<number, number[]> = {}
  const averageCompareSeasonsSeriesDataDictionary: Record<number, number[]> = {}

  soilData.forEach(({ metadata, timeseries }) => {
    const formattedDepthValue = Math.ceil(unitConverter.soilDepth(Number(metadata.depth)).value() || 0)
    const fixedDepth = Math.abs(Number(metadata.depth))
    const visible = !!soilVisibility[fixedDepth]
    const seriesName = `${formattedDepthValue} ${depthUnitType}`

    const regularSeries: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
      // @ts-ignore
      color: averageSelectedSoilDepths ? 'transparent' : getSoilMoistureHeightColor(`${fixedDepth}`),
      type: 'line',
      data: timeseries.map((d) => {
        const epoch = +new Date(d.timestamp)

        const value = unitConverter[unitConverterToUse](
          isAggregatedType(d.value) ? d.value?.average : d.value,
        ).value()

        if (visible) {
          averageRegularSeriesDataDictionary[epoch] = averageRegularSeriesDataDictionary[epoch] ?? []

          if (!isNil(value)) averageRegularSeriesDataDictionary[epoch].push(value)
        }

        return [epoch, value]
      }),
      lineWidth: averageSelectedSoilDepths ? 0 : 2,
      name: seriesName,
      enableMouseTracking: !averageSelectedSoilDepths,
      hideFromTooltip: averageSelectedSoilDepths || !visible, // hide from tooltip when average selected is on
      tooltip: {
        valueDecimals: unitConverter[unitConverterToUse]?.().defaultNumberOfDecimalPlaces(),
        valueSuffix: unitConverter[unitConverterToUse]?.().suffix(),
      },
      visible,
      yAxis: 0,
      zIndex: seriesZIndex,
      states: {
        inactive: {
          opacity: averageSelectedSoilDepths ? 0 : 1,
        },
      },
      id: `${seriesName}-${valueType}`,
    }

    series.push(regularSeries)

    if (compareSeasonsInterval) {
      const compareSeasonsTimeseriesForThisDepth =
        compareSeasonsData?.points?.[String(soilStationLngLat)]?.values?.[valueType]?.find(
          (s) => s.metadata.depth === metadata.depth,
        )?.timeseries ?? []

      series.push(
        makeCompareSeasonsSeriesFromRegularSeries(regularSeries, {
          data: compareSeasonsTimeseriesForThisDepth.map(
            (d: typeof soilData[number]['timeseries'][number]) => {
              const epoch = +new Date(d.timestamp)

              const value = unitConverter[unitConverterToUse](
                isAggregatedType(d.value) ? d.value?.average : d.value,
              ).value()

              if (visible) {
                averageCompareSeasonsSeriesDataDictionary[epoch] =
                  averageCompareSeasonsSeriesDataDictionary[epoch] ?? []

                if (!isNil(value)) averageCompareSeasonsSeriesDataDictionary[epoch].push(value)
              }

              return [epoch, value]
            },
          ),
        }),
      )
    }
  })

  series.sort((a, b) => {
    const aDepth = a.name?.split(' ')[0]
    const bDepth = b.name?.split(' ')[0]

    return Number(aDepth) - Number(bDepth)
  })

  if (averageSelectedSoilDepths) {
    const averageRegularSeriesData = Object.entries(averageRegularSeriesDataDictionary)
      .map(([epoch, values]) => {
        const averageForThisEpoch = roundToDecimalPlaces(
          values.reduce((a, b) => a + b, 0) / values.length,
          unitConverter[unitConverterToUse]().defaultNumberOfDecimalPlaces(),
        )

        return [+epoch, averageForThisEpoch]
      })
      .sort(([a], [b]) => sortNumber(a, b))

    const averageRegularSeries: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
      data: averageRegularSeriesData,
      // @ts-ignore
      includeInLegendCheckboxes: false,
      isAverage: true,
      name: translate.phrases.banyanApp('Average Selected'),
      type: 'line',
      yAxis: 0,
      zoneAxis: 'x',
      showInLegend: false,
      tooltip: {
        valueDecimals: unitConverter[unitConverterToUse]?.().defaultNumberOfDecimalPlaces(),
        valueSuffix: unitConverter[unitConverterToUse]?.().suffix(),
      },
      visible: averageSelectedSoilDepths,
      zIndex: seriesZIndex,
      color: colors.midnight,
      compareSeasonsColor: colors.firebrick,
      chartType: 'line',
      enableMouseTracking: true,
      showInNavigator: false,
      lineWidth: 2,
      id: `averageSelected-${valueType}`,
      states: {
        // don't fade out these series when hovering over the chart
        inactive: {
          opacity: 1,
        },
      },
    }

    series.push(averageRegularSeries)

    if (!compareSeasonsInterval) {
      const averageRegularSeriesSelectedBorder: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
        ...averageRegularSeries,
        zIndex: seriesZIndex - 1,
        color: colors.white,
        enableMouseTracking: false,
        // @ts-ignore
        lineWidth: 4,
        id: `averageSelectedBorder-${valueType}`,
        hideFromTooltip: true,
      }

      series.push(averageRegularSeriesSelectedBorder)
    }

    if (compareSeasonsInterval) {
      const compareSeasonSeriesData = Object.entries(averageCompareSeasonsSeriesDataDictionary)
        .map(([epoch, values]) => {
          const averageForThisEpoch = roundToDecimalPlaces(
            values.reduce((a, b) => a + b, 0) / values.length,
            unitConverter[unitConverterToUse]().defaultNumberOfDecimalPlaces(),
          )

          return [+epoch, averageForThisEpoch]
        })
        .sort(([a], [b]) => sortNumber(a, b))

      series.push(
        makeCompareSeasonsSeriesFromRegularSeries(averageRegularSeries, {
          data: compareSeasonSeriesData,
        }),
      )
    }
  }

  return series
}
