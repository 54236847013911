import { ItemHeader } from '../../WideScreen/ItemHeader/ItemHeader'
import { SettingsCreateNewButton } from '../../components/SettingsCreateNewButton/SettingsCreateNewButton'
import { useScreenSize } from 'utils/useScreenSize'
import { useState } from 'react'
import { Alert, userDetailsStore } from 'stores/userDetailsStore'
import { AlertCard } from './components/AlertCard/AlertCard'
import { translate } from 'i18n/i18n'
import { ModalDrawerCreateOrEditAlert } from './ModalDrawerCreateOrEditAlert/ModalDrawerCreateOrEditAlert'
import { SettingsFooterContainer } from '../../components/SettingsFooterContainer/SettingsFooterContainer'
import { colors } from 'settings/colors'
import { useSearchAlerts } from './components/useSearchAlerts'

export type TModalDrawerForm = 'createAlert' | 'editAlert' | null

export const Alerts = () => {
  const [modalDrawerForm, setModalDrawerForm] = useState<TModalDrawerForm>(null)
  const { isWideScreen } = useScreenSize()
  const [selectedAlert, setSelectedAlert] = useState<Alert | null>(null)
  const alerts = userDetailsStore.useSelector((s) => s.alerts)

  const { SearchInputComponent, filteredAndSortedList } = useSearchAlerts({
    initialList: alerts,
    placeholder: translate.phrases.banyanApp('Search Alerts'),
  })

  return (
    <>
      <ItemHeader>{translate.phrases.banyanApp('Alerts')}</ItemHeader>
      <div css={{ margin: '0px 20px', paddingBottom: 60 }}>
        {isWideScreen && (
          <div css={{ textAlign: 'right', marginBottom: 20 }}>
            <SettingsCreateNewButton
              label={translate.phrases.banyanApp('New Alert')}
              onClick={() => {
                setModalDrawerForm('createAlert')
              }}
            />
          </div>
        )}
        {alerts.length === 0 && (
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 20,
              borderTop: `1px solid ${colors.grey200}`,
              padding: 20,
              marginBottom: 5,
            }}
          >
            {translate.phrases.banyanApp('No alerts are added yet.')}
          </div>
        )}
        {alerts.length > 0 && SearchInputComponent}
        {filteredAndSortedList.map((alert) => (
          <AlertCard
            key={alert.id}
            alert={alert}
            setSelectedAlert={setSelectedAlert}
            setModalDrawerForm={setModalDrawerForm}
          />
        ))}
      </div>
      {!isWideScreen && (
        <SettingsFooterContainer>
          <SettingsCreateNewButton
            label={translate.phrases.banyanApp('New Alert')}
            onClick={() => {
              setModalDrawerForm('createAlert')
            }}
            css={{ width: '100%' }}
          />
        </SettingsFooterContainer>
      )}
      <ModalDrawerCreateOrEditAlert
        opened={modalDrawerForm !== null}
        onClose={() => {
          setModalDrawerForm(null)

          setSelectedAlert(null)
        }}
        alertId={selectedAlert ? selectedAlert.id : null}
        selectedAlert={selectedAlert}
      />
    </>
  )
}
