import { translate } from 'i18n/i18n'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { colors } from 'settings/colors'
import { IconInfo } from 'components/icons/IconInfo'
import { CloseIcon } from 'components/ModalDrawer/CloseIcon/CloseIcon'
import { ActionIcon } from '@mantine/core'

export const DateRangeDoesntApply = () => {
  const shown = detailsPanelStore.useSelector((s) => s.showDateRangeDoesntApply)

  if (!shown) return <></>

  return (
    <div
      css={{
        display: 'flex',
        background: colors.grey50,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
        borderRadius: 3,
        padding: '12px 26px',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 16,
      }}
    >
      <span css={{ color: colors.primary, marginRight: 12, marginTop: '0.2em' }}>
        <IconInfo />
      </span>
      <span css={{ fontWeight: 500, flex: 'auto' }}>
        {translate.phrases.banyanApp('Hull Split not affected by selected date range')}
      </span>
      <ActionIcon
        onClick={() => detailsPanelStore.setState((prev) => ({ ...prev, showDateRangeDoesntApply: false }))}
      >
        <CloseIcon />
      </ActionIcon>
    </div>
  )
}
