import { translate } from 'i18n/i18n'
import { UnreadMessagesCountBadge } from '../../../App/ServiceCenter/UnreadMessagesCountBadge/UnreadMessagesCountBadge'
import { IconAndTitle } from '../IconAndTitle/IconAndTitle'
import { useIntercom } from 'react-use-intercom'
import { intercomStore } from '../../../stores/intercomStore'
import { IconHelp } from '../../icons/IconHelp'

export const HelpCenter = () => {
  const intercom = useIntercom()
  const unreadMessagesCount = intercomStore.useSelector((s) => s.unreadMessagesCount)
  const showUnreadMessagesBadge = unreadMessagesCount > 0

  return (
    <IconAndTitle
      icon={<IconHelp />}
      onClick={() => intercom.show()}
      right={showUnreadMessagesBadge && <UnreadMessagesCountBadge count={unreadMessagesCount} />}
      title={translate.phrases.banyanApp('Help')}
    />
  )
}
