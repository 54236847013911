import { APP_PLATFORM_VERSION } from '@semios/app-platform-version'
import { ionicStore } from '../ionicStorage'
import { userDetailsStore, storeName as userDetailsStoreName } from 'stores/userDetailsStore'

export const clearIonicStoreButKeepUserLanguage = async () => {
  const { language } = userDetailsStore.getState()

  await ionicStore.clear()

  await ionicStore.set(userDetailsStoreName, {
    language,
    storeVersion: APP_PLATFORM_VERSION,
    timestamp: Date.now(),
  })
}
