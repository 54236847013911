export const REGEXES = {
  dnNodeId: /^([AT]{1})(0[0-3]{1})([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})$/,
  dnTrpId: /^([AT]{1})01([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})$/,
  dnNodeYId: /^([AT]{1})02([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})$/,
  dnScdId: /^([AT]{1})03([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})$/,
  mac: /([0-9A-F]{16})/i,
  nodeIdentifier: /([ABT]{1})(0[0-9]{1})([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})|([0-9A-F]{16})/i,
  lnGtwyId: /^([B]{1})04([0-9]{2})([5-9]{1})([0-9A-Z]{8})$/,
  lnGMNId: /^([B]{1})05([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})$/,
  lnSCDId: /^([B]{1})03([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})$/,
  lnRId: /^([B]{1})09([0-9]{2})([0-9A-Z]{1})([0-9A-Z]{8})$/,
}
