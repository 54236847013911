import { TPointCategory } from 'stores/selectedFieldAssetsStore'
import { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import {
  M_ITEM,
  MAP_VISUAL,
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentHeatmapPointsValueTypes,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
} from './types'

const emptyInsectDegreeDays = {
  degree_days_insect_id_1: null,
  degree_days_insect_id_10: null,
  degree_days_insect_id_11: null,
  degree_days_insect_id_12: null,
  degree_days_insect_id_13: null,
  degree_days_insect_id_14: null,
  degree_days_insect_id_15: null,
  degree_days_insect_id_16: null,
  degree_days_insect_id_17: null,
  degree_days_insect_id_18: null,
  degree_days_insect_id_19: null,
  degree_days_insect_id_20: null,
  degree_days_insect_id_2: null,
  degree_days_insect_id_23: null,
  degree_days_insect_id_24: null,
  degree_days_insect_id_25: null,
  degree_days_insect_id_26: null,
  degree_days_insect_id_27: null,
  degree_days_insect_id_28: null,
  degree_days_insect_id_29: null,
  degree_days_insect_id_30: null,
  degree_days_insect_id_3: null,
  degree_days_insect_id_4: null,
  degree_days_insect_id_6: null,
  degree_days_insect_id_7: null,
  degree_days_insect_id_8: null,
  degree_days_insect_id_9: null,
}

const emptyInsectLarvalTrend = {
  larval_trend_insect_id_1: null,
  larval_trend_insect_id_11: null,
  larval_trend_insect_id_20: null,
  larval_trend_insect_id_3: null,
  larval_trend_insect_id_6: null,
  larval_trend_insect_id_7: null,
  larval_trend_insect_id_9: null,
}

const emptyInsectTrapCatches = {
  trap_catches_insect_id_1: null,
  trap_catches_insect_id_10: null,
  trap_catches_insect_id_11: null,
  trap_catches_insect_id_12: null,
  trap_catches_insect_id_15: null,
  trap_catches_insect_id_16: null,
  trap_catches_insect_id_17: null,
  trap_catches_insect_id_19: null,
  trap_catches_insect_id_2: null,
  trap_catches_insect_id_20: null,
  trap_catches_insect_id_25: null,
  trap_catches_insect_id_27: null,
  trap_catches_insect_id_28: null,
  trap_catches_insect_id_29: null,
  trap_catches_insect_id_30: null,
  trap_catches_insect_id_6: null,
  trap_catches_insect_id_7: null,
  trap_catches_insect_id_9: null,
}

const emptyNonInsects = {
  air_temperature: null,
  almond_bloom: null,
  almond_hull_split: null,
  alternaria: null,
  atmospheric_pressure: null,
  bee_hours: null,
  chill: null,
  conditions: null,
  dew_point: null,
  equipment_status: null,
  evapotranspiration: null,
  fruit_growth: null,
  plant_stress: null,
  humidity: null,
  irrigation_activity: null,
  leaf_wetness: null,
  precipitation: null,
  soil: null,
  spray_conditions: null,
  walnut_blight: null,
  wet_bulb: null,
  wind: null,
  wind_machine: null,
}

const initialStateDegreeDays = (insectId: number, mItem: M_ITEM) => ({
  valueType:
    `insectDegreeDays_sinceBiofixSum_insectId${insectId}_degreeDaysC` as TValuesCurrentPointsValueTypes &
      TValuesCurrentHeatmapPointsValueTypes,
  mItem,
  pointCategory: 'weatherPoint' as TPointCategory,
})

const initialStateTrapCatches = (insectId: number, mItem: M_ITEM) => ({
  valueType: `insectTrapCatches_todaySum_insectId${insectId}` as TValuesCurrentPointsValueTypes &
    TValuesCurrentBlocksValueTypes,
  mItem,
  pointCategory: 'trapPoint' as TPointCategory,
})

const initialStateTrapCatchesProperty = (insectId: number, mItem: M_ITEM) => ({
  valueType: `insectTrapCatches_todaySum_insectId${insectId}` as TValuesCurrentPropertiesValueTypes,
  mItem,
  pointCategory: 'trapPoint' as TPointCategory,
})

const initialStateDegreeDaysSpatial = (insectId: number, mItem: M_ITEM) => ({
  valueType:
    `insectDegreeDays_sinceBiofixSumMinMedianMax_insectId${insectId}_degreeDaysC` as TValuesCurrentBlocksValueTypes &
      TValuesCurrentPropertiesValueTypes,
  mItem,
})

const initialStateDegreeDaysSpatialProperty = (insectId: number, mItem: M_ITEM) => ({
  valueType:
    `insectDegreeDays_sinceBiofixSumMinMedianMax_insectId${insectId}_degreeDaysC` as TValuesCurrentPropertiesValueTypes,
  mItem,
})

type TVisGroup<TValueType> = Record<
  TValueGroup,
  {
    valueType: TValueType | null
    mItem: M_ITEM
    pointCategory?: TPointCategory
    outOfBlockValueType?: TValueType | null
  } | null
>

export type TMapVisualValueGroup = {
  [MAP_VISUAL.BLOCK]: TVisGroup<TValuesCurrentBlocksValueTypes>
  [MAP_VISUAL.PROPERTY]: TVisGroup<TValuesCurrentPropertiesValueTypes>
  [MAP_VISUAL.POINT]: TVisGroup<TValuesCurrentPointsValueTypes>
  [MAP_VISUAL.SCD]: TVisGroup<TValuesCurrentHeatmapPointsValueTypes>
  [MAP_VISUAL.TRAP]: TVisGroup<TValuesCurrentPointsValueTypes>
  [MAP_VISUAL.ZONE]: TVisGroup<TValuesCurrentPointsValueTypes>
}

export const defaultMapVisualValueGroup: TMapVisualValueGroup = {
  [MAP_VISUAL.POINT]: {
    ...emptyNonInsects,
    ...emptyInsectDegreeDays,
    ...emptyInsectLarvalTrend,
    ...emptyInsectTrapCatches,
    air_temperature: {
      valueType: 'temperature_in_c',
      mItem: 'M_InBlockPoint',
      pointCategory: 'weatherPoint',
      outOfBlockValueType: 'temperature_out_c',
    },
    almond_hull_split: {
      valueType: 'almondHullSplit_date',
      mItem: 'M_InBlockPoint',
      pointCategory: 'weatherPoint',
    },
    bee_hours: {
      valueType: 'beeHours_last24hoursSum_hours',
      mItem: 'M_InBlockPoint',
      pointCategory: 'weatherPoint',
    },
    chill: {
      valueType: 'chillPortions_sinceNov1Sum_portions',
      mItem: 'M_InBlockPoint',
      pointCategory: 'weatherPoint',
    },
    dew_point: {
      valueType: 'dewPoint_in_c',
      mItem: 'M_InBlockPoint',
      pointCategory: 'weatherPoint',
      outOfBlockValueType: 'dewPoint_out_c',
    },
    humidity: {
      valueType: 'humidity_in_pct',
      mItem: 'M_InBlockPoint',
      pointCategory: 'weatherPoint',
      outOfBlockValueType: 'humidity_out_pct',
    },
    wet_bulb: {
      valueType: 'wetBulb_in_c',
      mItem: 'M_InBlockPoint',
      pointCategory: 'weatherPoint',
      outOfBlockValueType: 'wetBulb_out_c',
    },
    fruit_growth: { valueType: 'fruitSize_um', mItem: 'M_InBlockPoint', pointCategory: 'fruitGrowthPoint' },
    plant_stress: {
      valueType: 'plantStress_yesterday_signalTS5',
      mItem: 'M_InBlockPoint',
      pointCategory: 'plantStressPoint',
    },
    // DEGREE DAYS
    degree_days_insect_id_1: initialStateDegreeDays(1, 'M_Block'),
    degree_days_insect_id_10: initialStateDegreeDays(10, 'M_Block'),
    degree_days_insect_id_11: initialStateDegreeDays(11, 'M_Block'),
    degree_days_insect_id_12: initialStateDegreeDays(12, 'M_Block'),
    degree_days_insect_id_13: initialStateDegreeDays(13, 'M_Block'),
    degree_days_insect_id_14: initialStateDegreeDays(14, 'M_Block'),
    degree_days_insect_id_15: initialStateDegreeDays(15, 'M_Block'),
    degree_days_insect_id_16: initialStateDegreeDays(16, 'M_Block'),
    degree_days_insect_id_17: initialStateDegreeDays(17, 'M_Block'),
    degree_days_insect_id_18: initialStateDegreeDays(18, 'M_Block'),
    degree_days_insect_id_19: initialStateDegreeDays(19, 'M_Block'),
    degree_days_insect_id_20: initialStateDegreeDays(20, 'M_Block'),
    degree_days_insect_id_2: initialStateDegreeDays(2, 'M_Block'),
    degree_days_insect_id_23: initialStateDegreeDays(23, 'M_Block'),
    degree_days_insect_id_24: initialStateDegreeDays(24, 'M_Block'),
    degree_days_insect_id_25: initialStateDegreeDays(25, 'M_Block'),
    degree_days_insect_id_26: initialStateDegreeDays(26, 'M_Block'),
    degree_days_insect_id_27: initialStateDegreeDays(27, 'M_Block'),
    degree_days_insect_id_28: initialStateDegreeDays(28, 'M_Block'),
    degree_days_insect_id_29: initialStateDegreeDays(29, 'M_Block'),
    degree_days_insect_id_30: initialStateDegreeDays(30, 'M_Block'),
    degree_days_insect_id_3: initialStateDegreeDays(3, 'M_Block'),
    degree_days_insect_id_4: initialStateDegreeDays(4, 'M_Block'),
    degree_days_insect_id_6: initialStateDegreeDays(6, 'M_Block'),
    degree_days_insect_id_7: initialStateDegreeDays(7, 'M_Block'),
    degree_days_insect_id_8: initialStateDegreeDays(8, 'M_Block'),
    degree_days_insect_id_9: initialStateDegreeDays(9, 'M_Block'),
    // @ts-ignore // TODO: how should we reconcile these worlds?
    soil: { valueType: 'DEFAULT', mItem: 'M_InBlockPoint', pointCategory: 'soilPoint' },
    irrigation_activity: {
      valueType: 'irrigationIsOn_status',
      mItem: 'M_InBlockPoint',
      pointCategory: 'pressureTransducerPoint',
    },
    precipitation: {
      valueType: 'precipitation_next24HoursSum_mm',
      mItem: 'M_OutOfBlockPoint',
      pointCategory: 'outOfBlockPoint',
    },
    wind: { valueType: 'windSpeed_mps', mItem: 'M_OutOfBlockPoint', pointCategory: 'outOfBlockPoint' },
    wind_machine: {
      valueType: 'windMachine_temperature_c',
      mItem: 'M_InBlockPoint',
      pointCategory: 'windMachinePoint',
    },
  },
  [MAP_VISUAL.BLOCK]: {
    ...emptyNonInsects,
    ...emptyInsectLarvalTrend,
    air_temperature: { valueType: 'temperature_minMedianMax_in_c', mItem: 'M_Block' },
    almond_bloom: { valueType: 'almondBloom_today_pct', mItem: 'M_Block' },
    almond_hull_split: { valueType: 'almondHullSplit_date', mItem: 'M_Block' },
    bee_hours: { valueType: 'beeHours_last24hoursSum_hours', mItem: 'M_Block' },
    chill: { valueType: 'chillPortions_sinceNov1SumMinMedianMax_portions', mItem: 'M_Block' },
    dew_point: { valueType: 'dewPoint_minMedianMax_in_c', mItem: 'M_Block' },
    evapotranspiration: { valueType: 'evapotranspirationETc_next7DaysSum_mm', mItem: 'M_Block' },
    humidity: { valueType: 'humidity_minMedianMax_in_pct', mItem: 'M_Block' },
    spray_conditions: { valueType: 'sprayConditions_currentHour', mItem: 'M_Property' },
    wet_bulb: { valueType: 'wetBulb_minMedianMax_in_c', mItem: 'M_Block' },
    // DEGREE DAYS
    degree_days_insect_id_1: initialStateDegreeDaysSpatial(1, 'M_Block'),
    degree_days_insect_id_10: initialStateDegreeDaysSpatial(10, 'M_Block'),
    degree_days_insect_id_11: initialStateDegreeDaysSpatial(11, 'M_Block'),
    degree_days_insect_id_12: initialStateDegreeDaysSpatial(12, 'M_Block'),
    degree_days_insect_id_13: initialStateDegreeDaysSpatial(13, 'M_Block'),
    degree_days_insect_id_14: initialStateDegreeDaysSpatial(14, 'M_Block'),
    degree_days_insect_id_15: initialStateDegreeDaysSpatial(15, 'M_Block'),
    degree_days_insect_id_16: initialStateDegreeDaysSpatial(16, 'M_Block'),
    degree_days_insect_id_17: initialStateDegreeDaysSpatial(17, 'M_Block'),
    degree_days_insect_id_18: initialStateDegreeDaysSpatial(18, 'M_Block'),
    degree_days_insect_id_19: initialStateDegreeDaysSpatial(19, 'M_Block'),
    degree_days_insect_id_20: initialStateDegreeDaysSpatial(20, 'M_Block'),
    degree_days_insect_id_2: initialStateDegreeDaysSpatial(2, 'M_Block'),
    degree_days_insect_id_23: initialStateDegreeDaysSpatial(23, 'M_Block'),
    degree_days_insect_id_24: initialStateDegreeDaysSpatial(24, 'M_Block'),
    degree_days_insect_id_25: initialStateDegreeDaysSpatial(25, 'M_Block'),
    degree_days_insect_id_26: initialStateDegreeDaysSpatial(26, 'M_Block'),
    degree_days_insect_id_27: initialStateDegreeDaysSpatial(27, 'M_Block'),
    degree_days_insect_id_28: initialStateDegreeDaysSpatial(28, 'M_Block'),
    degree_days_insect_id_29: initialStateDegreeDaysSpatial(29, 'M_Block'),
    degree_days_insect_id_30: initialStateDegreeDaysSpatial(30, 'M_Block'),
    degree_days_insect_id_3: initialStateDegreeDaysSpatial(3, 'M_Block'),
    degree_days_insect_id_4: initialStateDegreeDaysSpatial(4, 'M_Block'),
    degree_days_insect_id_6: initialStateDegreeDaysSpatial(6, 'M_Block'),
    degree_days_insect_id_7: initialStateDegreeDaysSpatial(7, 'M_Block'),
    degree_days_insect_id_8: initialStateDegreeDaysSpatial(8, 'M_Block'),
    degree_days_insect_id_9: initialStateDegreeDaysSpatial(9, 'M_Block'),
    // TRAP CATCHES
    trap_catches_insect_id_1: initialStateTrapCatches(1, 'M_Trap'),
    trap_catches_insect_id_10: initialStateTrapCatches(10, 'M_Trap'),
    trap_catches_insect_id_11: initialStateTrapCatches(11, 'M_Trap'),
    trap_catches_insect_id_12: initialStateTrapCatches(12, 'M_Trap'),
    trap_catches_insect_id_15: initialStateTrapCatches(15, 'M_Trap'),
    trap_catches_insect_id_16: initialStateTrapCatches(16, 'M_Trap'),
    trap_catches_insect_id_17: initialStateTrapCatches(17, 'M_Trap'),
    trap_catches_insect_id_19: initialStateTrapCatches(19, 'M_Trap'),
    trap_catches_insect_id_2: initialStateTrapCatches(2, 'M_Trap'),
    trap_catches_insect_id_20: initialStateTrapCatches(20, 'M_Trap'),
    trap_catches_insect_id_25: initialStateTrapCatches(25, 'M_Trap'),
    trap_catches_insect_id_27: initialStateTrapCatches(27, 'M_Trap'),
    trap_catches_insect_id_28: initialStateTrapCatches(28, 'M_Trap'),
    trap_catches_insect_id_29: initialStateTrapCatches(29, 'M_Trap'),
    trap_catches_insect_id_30: initialStateTrapCatches(30, 'M_Trap'),
    trap_catches_insect_id_6: initialStateTrapCatches(6, 'M_Trap'),
    trap_catches_insect_id_7: initialStateTrapCatches(7, 'M_Trap'),
    trap_catches_insect_id_9: initialStateTrapCatches(9, 'M_Trap'),
    // DISEASES
    alternaria: { valueType: 'alternariaCumulativeRolling7Days_tomorrow_dsv', mItem: 'M_Block' },
  },
  [MAP_VISUAL.PROPERTY]: {
    ...emptyNonInsects,
    ...emptyInsectTrapCatches,
    ...emptyInsectLarvalTrend,
    air_temperature: { valueType: 'temperature_minMedianMax_in_c', mItem: 'M_Property' },
    almond_hull_split: { valueType: 'almondHullSplitMedian_date', mItem: 'M_Property' },
    bee_hours: { valueType: 'beeHours_last24hoursSumMinMedianMax_hours', mItem: 'M_Property' },
    chill: { valueType: 'chillPortions_sinceNov1SumMinMedianMax_portions', mItem: 'M_Property' },
    dew_point: { valueType: 'dewPoint_minMedianMax_in_c', mItem: 'M_Property' },
    humidity: { valueType: 'humidity_minMedianMax_in_pct', mItem: 'M_Property' },
    irrigation_activity: { valueType: 'irrigationIsOn_status', mItem: 'M_Property' },
    precipitation: { valueType: 'precipitation_next24HoursSumMinMedianMax_mm', mItem: 'M_Property' },
    spray_conditions: { valueType: 'sprayConditions_currentHour', mItem: 'M_Property' },
    wet_bulb: { valueType: 'wetBulb_minMedianMax_in_c', mItem: 'M_Property' },
    wind: { valueType: 'windSpeed_minMedianMax_mps', mItem: 'M_Property' },
    // DEGREE DAYS
    degree_days_insect_id_1: initialStateDegreeDaysSpatialProperty(1, 'M_Property'),
    degree_days_insect_id_10: initialStateDegreeDaysSpatialProperty(10, 'M_Property'),
    degree_days_insect_id_11: initialStateDegreeDaysSpatialProperty(11, 'M_Property'),
    degree_days_insect_id_12: initialStateDegreeDaysSpatialProperty(12, 'M_Property'),
    degree_days_insect_id_13: initialStateDegreeDaysSpatialProperty(13, 'M_Property'),
    degree_days_insect_id_14: initialStateDegreeDaysSpatialProperty(14, 'M_Property'),
    degree_days_insect_id_15: initialStateDegreeDaysSpatialProperty(15, 'M_Property'),
    degree_days_insect_id_16: initialStateDegreeDaysSpatialProperty(16, 'M_Property'),
    degree_days_insect_id_17: initialStateDegreeDaysSpatialProperty(17, 'M_Property'),
    degree_days_insect_id_18: initialStateDegreeDaysSpatialProperty(18, 'M_Property'),
    degree_days_insect_id_19: initialStateDegreeDaysSpatialProperty(19, 'M_Property'),
    degree_days_insect_id_20: initialStateDegreeDaysSpatialProperty(20, 'M_Property'),
    degree_days_insect_id_2: initialStateDegreeDaysSpatialProperty(2, 'M_Property'),
    degree_days_insect_id_23: initialStateDegreeDaysSpatialProperty(23, 'M_Property'),
    degree_days_insect_id_24: initialStateDegreeDaysSpatialProperty(24, 'M_Property'),
    degree_days_insect_id_25: initialStateDegreeDaysSpatialProperty(25, 'M_Property'),
    degree_days_insect_id_26: initialStateDegreeDaysSpatialProperty(26, 'M_Property'),
    degree_days_insect_id_27: initialStateDegreeDaysSpatialProperty(27, 'M_Property'),
    degree_days_insect_id_28: initialStateDegreeDaysSpatialProperty(28, 'M_Property'),
    degree_days_insect_id_29: initialStateDegreeDaysSpatialProperty(29, 'M_Property'),
    degree_days_insect_id_30: initialStateDegreeDaysSpatialProperty(30, 'M_Property'),
    degree_days_insect_id_3: initialStateDegreeDaysSpatialProperty(3, 'M_Property'),
    degree_days_insect_id_4: initialStateDegreeDaysSpatialProperty(4, 'M_Property'),
    degree_days_insect_id_6: initialStateDegreeDaysSpatialProperty(6, 'M_Property'),
    degree_days_insect_id_7: initialStateDegreeDaysSpatialProperty(7, 'M_Property'),
    degree_days_insect_id_8: initialStateDegreeDaysSpatialProperty(8, 'M_Property'),
    degree_days_insect_id_9: initialStateDegreeDaysSpatialProperty(9, 'M_Property'),
    // TRAP CATCHES
    trap_catches_insect_id_1: initialStateTrapCatchesProperty(1, 'M_Property'),
    trap_catches_insect_id_10: initialStateTrapCatchesProperty(10, 'M_Property'),
    trap_catches_insect_id_11: initialStateTrapCatchesProperty(11, 'M_Property'),
    trap_catches_insect_id_15: initialStateTrapCatchesProperty(15, 'M_Property'),
    trap_catches_insect_id_16: initialStateTrapCatchesProperty(16, 'M_Property'),
    trap_catches_insect_id_17: initialStateTrapCatchesProperty(17, 'M_Property'),
    trap_catches_insect_id_19: initialStateTrapCatchesProperty(19, 'M_Property'),
    trap_catches_insect_id_2: initialStateTrapCatchesProperty(2, 'M_Property'),
    trap_catches_insect_id_20: initialStateTrapCatchesProperty(20, 'M_Property'),
    trap_catches_insect_id_25: initialStateTrapCatchesProperty(25, 'M_Property'),
    trap_catches_insect_id_27: initialStateTrapCatchesProperty(27, 'M_Property'),
    trap_catches_insect_id_28: initialStateTrapCatchesProperty(28, 'M_Property'),
    trap_catches_insect_id_29: initialStateTrapCatchesProperty(29, 'M_Property'),
    trap_catches_insect_id_30: initialStateTrapCatchesProperty(30, 'M_Property'),
    trap_catches_insect_id_6: initialStateTrapCatchesProperty(6, 'M_Property'),
    trap_catches_insect_id_7: initialStateTrapCatchesProperty(7, 'M_Property'),
    trap_catches_insect_id_9: initialStateTrapCatchesProperty(9, 'M_Property'),
    // DISEASES
    alternaria: { valueType: 'alternariaCumulativeRolling7DaysMinMedMax_tomorrow_dsv', mItem: 'M_Property' },
  },
  [MAP_VISUAL.SCD]: {
    ...emptyNonInsects,
    ...emptyInsectLarvalTrend,
    ...emptyInsectTrapCatches,
    air_temperature: { valueType: 'temperature_in_c', mItem: 'M_SCD' },
    almond_hull_split: { valueType: 'almondHullSplit_date', mItem: 'M_SCD' },
    chill: { valueType: 'chillPortions_sinceNov1Sum_portions', mItem: 'M_SCD' },
    dew_point: { valueType: 'dewPoint_in_c', mItem: 'M_SCD' },
    evapotranspiration: { valueType: 'evapotranspirationETc_next7DaysSum_mm', mItem: 'M_SCD' },
    humidity: { valueType: 'humidity_in_pct', mItem: 'M_SCD' },
    wet_bulb: { valueType: 'wetBulb_in_c', mItem: 'M_SCD' },
    // DEGREE DAYS
    degree_days_insect_id_1: initialStateDegreeDays(1, 'M_SCD'),
    degree_days_insect_id_10: initialStateDegreeDays(10, 'M_SCD'),
    degree_days_insect_id_11: initialStateDegreeDays(11, 'M_SCD'),
    degree_days_insect_id_12: initialStateDegreeDays(12, 'M_SCD'),
    degree_days_insect_id_13: initialStateDegreeDays(13, 'M_SCD'),
    degree_days_insect_id_14: initialStateDegreeDays(14, 'M_SCD'),
    degree_days_insect_id_15: initialStateDegreeDays(15, 'M_SCD'),
    degree_days_insect_id_16: initialStateDegreeDays(16, 'M_SCD'),
    degree_days_insect_id_17: initialStateDegreeDays(17, 'M_SCD'),
    degree_days_insect_id_18: initialStateDegreeDays(18, 'M_SCD'),
    degree_days_insect_id_19: initialStateDegreeDays(19, 'M_SCD'),
    degree_days_insect_id_20: initialStateDegreeDays(20, 'M_SCD'),
    degree_days_insect_id_2: initialStateDegreeDays(2, 'M_SCD'),
    degree_days_insect_id_23: initialStateDegreeDays(23, 'M_SCD'),
    degree_days_insect_id_24: initialStateDegreeDays(24, 'M_SCD'),
    degree_days_insect_id_25: initialStateDegreeDays(25, 'M_SCD'),
    degree_days_insect_id_26: initialStateDegreeDays(26, 'M_SCD'),
    degree_days_insect_id_27: initialStateDegreeDays(27, 'M_SCD'),
    degree_days_insect_id_28: initialStateDegreeDays(28, 'M_SCD'),
    degree_days_insect_id_29: initialStateDegreeDays(29, 'M_SCD'),
    degree_days_insect_id_30: initialStateDegreeDays(30, 'M_SCD'),
    degree_days_insect_id_3: initialStateDegreeDays(3, 'M_SCD'),
    degree_days_insect_id_4: initialStateDegreeDays(4, 'M_SCD'),
    degree_days_insect_id_6: initialStateDegreeDays(6, 'M_SCD'),
    degree_days_insect_id_7: initialStateDegreeDays(7, 'M_SCD'),
    degree_days_insect_id_8: initialStateDegreeDays(8, 'M_SCD'),
    degree_days_insect_id_9: initialStateDegreeDays(9, 'M_SCD'),
  },
  [MAP_VISUAL.TRAP]: {
    ...emptyNonInsects,
    ...emptyInsectLarvalTrend,
    ...emptyInsectDegreeDays,
    trap_catches_insect_id_1: initialStateTrapCatches(1, 'M_Trap'),
    trap_catches_insect_id_10: initialStateTrapCatches(10, 'M_Trap'),
    trap_catches_insect_id_11: initialStateTrapCatches(11, 'M_Trap'),
    trap_catches_insect_id_12: initialStateTrapCatches(12, 'M_Trap'),
    trap_catches_insect_id_15: initialStateTrapCatches(15, 'M_Trap'),
    trap_catches_insect_id_16: initialStateTrapCatches(16, 'M_Trap'),
    trap_catches_insect_id_17: initialStateTrapCatches(17, 'M_Trap'),
    trap_catches_insect_id_19: initialStateTrapCatches(19, 'M_Trap'),
    trap_catches_insect_id_2: initialStateTrapCatches(2, 'M_Trap'),
    trap_catches_insect_id_20: initialStateTrapCatches(20, 'M_Trap'),
    trap_catches_insect_id_25: initialStateTrapCatches(25, 'M_Trap'),
    trap_catches_insect_id_27: initialStateTrapCatches(27, 'M_Trap'),
    trap_catches_insect_id_28: initialStateTrapCatches(28, 'M_Trap'),
    trap_catches_insect_id_29: initialStateTrapCatches(29, 'M_Trap'),
    trap_catches_insect_id_30: initialStateTrapCatches(30, 'M_Trap'),
    trap_catches_insect_id_6: initialStateTrapCatches(6, 'M_Trap'),
    trap_catches_insect_id_7: initialStateTrapCatches(7, 'M_Trap'),
    trap_catches_insect_id_9: initialStateTrapCatches(9, 'M_Trap'),
  },
  [MAP_VISUAL.ZONE]: {
    ...emptyNonInsects,
    ...emptyInsectDegreeDays,
    ...emptyInsectLarvalTrend,
    ...emptyInsectTrapCatches,
  },
}
