import { routes } from '@semios/app-platform-banyan-route-definitions'
import { colors } from 'settings/colors'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateUsualStyleAPIArgs } from './_utils/generateUsualStyleAPIArgs'
import { generateUsualStyleGetCacheUpdatesFromResponse } from './_utils/generateUsualStyleGetCacheUpdatesFromResponse'
import { getValueType } from './_utils/getValueType'
import { isEmpty } from '@semios/app-platform-common'
import { apiFetch } from 'utils/apiFetch'

const heatmapExtremesForAllPropertiesInVisibleRegions = false
const valueGroup = 'evapotranspiration'
const valueKey = 'value'

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  return generateUsualStyleAPIArgs({
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
    },
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.SCD, valueGroup),
    },
  })
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const response = await apiFetch<routes.ValuesCurrent.Request, routes.ValuesCurrent.Response>({
    url: routes.ValuesCurrent.path,
    body: args,
  })

  return generateUsualStyleGetCacheUpdatesFromResponse({
    cacheKeys,
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    response,
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
      unitConverterFunction: (val?: number | null) => {
        return unitConverter.evapotranspiration(val, {
          decimalPlaces: unitConverter.evapotranspiration().defaultNumberOfDecimalPlaces() - 1,
        })
      },
      valueKey,
    },
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.SCD, valueGroup),
      unitConverterFunction: (val?: number | null) => {
        // if the value is too long it doesn't fit in the heatmap points nicely, so as a workaround we remove a decimal place
        const lengthOfValue = unitConverter
          .evapotranspiration(val, {
            decimalPlaces: unitConverter.evapotranspiration().defaultNumberOfDecimalPlaces() - 1,
          })
          .valueAsString().length

        return unitConverter.evapotranspiration(val, {
          decimalPlaces:
            lengthOfValue >= 5
              ? unitConverter.evapotranspiration().defaultNumberOfDecimalPlaces() - 2
              : unitConverter.evapotranspiration().defaultNumberOfDecimalPlaces() - 1,
        })
      },
    },
    heatmapColoring: [
      colors.irrigationHeatmapSoftBlue,
      colors.irrigationHeatmapMediumBlue,
      colors.irrigationHeatmapStrongBlue,
    ],
  })
}
