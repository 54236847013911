import { routes } from '@semios/app-platform-banyan-route-definitions'
import { detailsPanelStore } from 'stores/detailsPanelStore'

const getMinAndMaxForTimeseriesAcrossAllSignalTS5 = (
  soilMoistureValueType: NonNullable<routes.Values.Response['points']>[string]['values']['signalTS5'],
) => {
  return (soilMoistureValueType ?? []).reduce(
    (a: { min: number; max: number }, moistureForDepth) => {
      const mappedValues = moistureForDepth.timeseries.flatMap((ts) => {
        if (!ts.value && ts.value !== 0) return []

        return ts.value
      })

      return {
        min: Math.min(a.min, ...mappedValues),
        max: Math.max(a.max, ...mappedValues),
      }
    },
    { min: 0, max: 0 },
  )
}

export const getPwsChartMinMax = ({
  compareSeasonsData,
  data,
  treeDendrometerLngLat,
}: {
  compareSeasonsData: routes.Values.Response
  data: routes.Values.Response
  treeDendrometerLngLat: string | null
}) => {
  const { compareSeasonsInterval } = detailsPanelStore.getState()

  const evaluatedData = getMinAndMaxForTimeseriesAcrossAllSignalTS5(
    data?.points?.[String(treeDendrometerLngLat)]?.values?.signalTS5 ?? [],
  )

  if (!compareSeasonsInterval) {
    return {
      yAxisMaxToUse: Math.max(evaluatedData.max),
      yAxisMinToUse: Math.min(evaluatedData.min),
    }
  }

  const evaluatedCompareSeasonsData = getMinAndMaxForTimeseriesAcrossAllSignalTS5(
    compareSeasonsData?.points?.[String(treeDendrometerLngLat)]?.values?.signalTS5 ?? [],
  )

  return {
    yAxisMaxToUse: Math.max(evaluatedData.max, evaluatedCompareSeasonsData.max),
    yAxisMinToUse: Math.min(evaluatedData.min, evaluatedCompareSeasonsData.min),
  }
}
