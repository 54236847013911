import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import moment from 'moment-timezone'
import { TOrganizationKcDateRange } from '../../types'

const MAX_KC_VALUE = 3

export const validateKcRanges = (
  kcRanges: TOrganizationKcDateRange[],
  timezone: string,
  triggerErrorMessage: (x: string) => void,
): boolean => {
  for (let i = 0; i < kcRanges.length; i++) {
    const kcRange = kcRanges[i]

    if (isNil(kcRange.dateRangeStart) || isNil(kcRange.dateRangeEnd)) {
      triggerErrorMessage(
        translate.phrases.banyanApp('Missing Date Ranges: Ensure all Date Ranges are filled in'),
      )

      return false
    }
  }

  for (let i = 0; i < kcRanges.length; i++) {
    const kcRange = kcRanges[i]

    if (isNil(kcRange.kc)) {
      triggerErrorMessage(
        translate.phrases.banyanApp('Missing Kc Values: Ensure all Kc Values are filled in'),
      )

      return false
    }
  }

  for (let i = 0; i < kcRanges.length; i++) {
    const kcRange = kcRanges[i]

    if (kcRange.kc && kcRange.kc > MAX_KC_VALUE) {
      triggerErrorMessage(
        translate.phrases.validation('{{label}} must be <= {{value}}', {
          label: translate.phrases.banyanApp('Kc Value'),
          value: MAX_KC_VALUE,
        }),
      )

      return false
    }
  }

  for (let i = 0; i < kcRanges.length - 1; i++) {
    const kcRange = kcRanges[i]
    const nextKcRange = kcRanges[i + 1]

    if (
      moment.tz(kcRange.dateRangeEnd, timezone).isSameOrAfter(moment.tz(nextKcRange.dateRangeStart, timezone))
    ) {
      triggerErrorMessage(
        translate.phrases.banyanApp('Overlapping Date Ranges: Ensure date ranges do not overlap'),
      )

      return false
    }
  }

  for (let i = 0; i < kcRanges.length - 1; i++) {
    const kcRange = kcRanges[i]
    const nextKcRange = kcRanges[i + 1]

    if (
      moment
        .tz(kcRange.dateRangeEnd, timezone)
        .clone()
        .add(1, 'day')
        .isBefore(moment.tz(nextKcRange.dateRangeStart, timezone))
    ) {
      triggerErrorMessage(
        translate.phrases.banyanApp('Incomplete Date Ranges: Ensure there are no gaps between date ranges'),
      )

      return false
    }
  }

  return true
}
