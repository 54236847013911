import { translate } from 'i18n/i18n'
import { Ref, forwardRef } from 'react'
import { TextInput, useMantineTheme } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useMultiSelectContext } from '../MultiSelect.context'

export const MultiSelectListSearchInput = forwardRef((_, ref: Ref<HTMLInputElement>) => {
  const { inputValue, onArrowKeyNavigation, setInputValue } = useMultiSelectContext()
  const theme = useMantineTheme()

  return (
    <TextInput
      type="text"
      ref={ref}
      onChange={(e) => setInputValue(e.target.value)}
      value={inputValue}
      placeholder={translate.phrases.banyanApp('Search')}
      onKeyDown={onArrowKeyNavigation}
      icon={<FontAwesomeIcon css={{ fontSize: 16, color: theme.colors.grey[3] }} icon={faSearch} />}
    />
  )
})

MultiSelectListSearchInput.displayName = 'MultiSelectListSearchInput'
