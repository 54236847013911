import { InstallationStep, TEquipmentInstallationStep } from '../types'
import { serviceCenterStore } from '../store/serviceCenterStore'
import { fieldAssetStore } from '../../../stores/fieldAssetStore'
import { translate } from 'i18n/i18n'
import { getNodeTypeLabel } from './getNodeTypeLabel'
import { isPlannedNode } from './isPlannedNode'
import { flatNodeDevices } from './flatNodeDevices'
import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { installationWorkflowStore } from '../store/installationWorkflowStore'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { DEVICE_GROUPS_PERMISSIONS } from '../Map/_utils/getNodeDeviceTypes'

const checkPermission = (deviceGroupName: string, propertyId: number) =>
  selectedPropertyHasPermission({ permission: DEVICE_GROUPS_PERMISSIONS[deviceGroupName], propertyId })

export function getInstallationSteps(): TEquipmentInstallationStep[] {
  const allInstallationChecklists = serviceCenterStore.selectors.getInstallationChecklists(
    serviceCenterStore.getState(),
  )

  const selectedEquipment = serviceCenterStore.selectors.getSelectedEquipmentNode(
    serviceCenterStore.getState(),
  ) as SharedTypes.TActiveNode | SharedTypes.TPlannedNode

  const isDropInstall = installationWorkflowStore.getState().dropInstallLocation
  const allDevices = fieldAssetStore.getState().devices
  const nodeActivationTitle = isDropInstall ? 'Scan Equipment' : 'Activate {{nodeLabel}}'

  let steps: TEquipmentInstallationStep[] = []

  steps.push({
    type: InstallationStep.CONFIRM_LOCATION,
    title: translate.phrases.placeholder('Confirm Location'),
    nodeType: selectedEquipment?.nodeType,
    enabled: isPlannedNode(selectedEquipment),
    completed: !isPlannedNode(selectedEquipment) || !!isDropInstall,
    required: true,
  })

  steps.push({
    type: InstallationStep.ACTIVATE_NODE,
    title: translate.phrases.placeholder(nodeActivationTitle, {
      nodeLabel: selectedEquipment && getNodeTypeLabel(selectedEquipment?.nodeType),
    }),
    nodeType: selectedEquipment?.nodeType,
    enabled: (isDropInstall && isDropInstall !== 'INSTALL_COMPLETED') || false,
    completed: (selectedEquipment && !isPlannedNode(selectedEquipment)) || false,
    required: true,
  })

  const nodeDevices = flatNodeDevices((selectedEquipment as SharedTypes.TPlannedNode)?.devices)

  if (nodeDevices.length) {
    nodeDevices
      .filter((device) =>
        checkPermission(allDevices[device.source]?.deviceGroupName, selectedEquipment.propertyId),
      )
      .forEach((device) => {
        steps.push({
          type: InstallationStep.CONFIGURE_DEVICE,
          title: allDevices[device.source]?.name || device.source,
          device,
          enabled: !isPlannedNode(selectedEquipment),
          completed: device.installationStatus === SharedTypes.NodeDeviceInstallationStatus.INSTALLED,
          required: true,
        })
      })
  }

  const allNodeAndDevicesInstalled =
    selectedEquipment &&
    !isPlannedNode(selectedEquipment) &&
    nodeDevices.every((d) => d.installationStatus === SharedTypes.NodeDeviceInstallationStatus.INSTALLED)

  const selectedNodeInstructions =
    selectedEquipment && allInstallationChecklists.nodes[selectedEquipment.nodeType.toUpperCase()]

  if (selectedNodeInstructions?.checklist) {
    steps.push({
      type: InstallationStep.CHECKLIST,
      title: translate.phrases.placeholder('Checklist'),
      checklist: selectedNodeInstructions.checklist.map(({ title }) => title),
      enabled: allNodeAndDevicesInstalled || false,
      completed: false,
      required: false,
    })
  }

  steps.push({
    type: InstallationStep.REVIEW,
    title: translate.phrases.placeholder('Review'),
    image: 'null',
    enabled: allNodeAndDevicesInstalled || false,
    completed: false,
    required: false,
  })

  return steps
}
