import React, { useEffect, useState } from 'react'
import { closeModal, openModal } from '@mantine/modals'
import { LoadingOverlay } from '@mantine/core'
import { translate } from 'i18n/i18n'
import * as Sentry from '@sentry/react'
import { HelpGuideList } from './List/List'
import { HelpGuideSection, THelpGuideStep, TNodeType } from '../types'
import { serviceCenterStore } from '../store/serviceCenterStore'
import { HelpGuideStep } from './Step/Step'
import { Footer } from './Footer/Footer'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { getNodeTypeLabel } from '../utils/getNodeTypeLabel'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { getHelpGuideSectionTitle } from '../utils/getHelpGuideSectionTitle'
import { serviceCenterHelpGuidesGet } from '../utils/api/serviceCenterHelpGuidesGet'
import { showNotification } from '../../../utils/showNotification'
import { ApiResponseError, OfflineRequestError } from 'utils/errorCodes'

const MODAL_ID = 'help-guides'

type SelectedGuideAlias = {
  type: 'node' | 'device'
  equipmentType: string
  section?: HelpGuideSection
}

export function openHelpGuideModal(guide?: SelectedGuideAlias) {
  openModal({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: <HelpGuideModal guide={guide} />,
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

type SelectedGuide = {
  title: string
  steps: THelpGuideStep[]
}

interface HelpGuideModalProps {
  guide?: SelectedGuideAlias
}

export const HelpGuideModal: React.FC<HelpGuideModalProps> = ({ guide: initialGuide }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedGuide, setSelectedGuide] = useState<SelectedGuide | null>(null)
  const [currentStep, setCurrentStep] = useState<number>(0)

  useEffect(() => {
    if (initialGuide && initialGuide.section) {
      handleSelectGuide(initialGuide.type, initialGuide.equipmentType, initialGuide.section)
    }
  }, [initialGuide])

  useEffect(() => {
    async function loadHelpGuides() {
      try {
        setIsLoading(true)

        const helpGuides = await serviceCenterHelpGuidesGet()

        serviceCenterStore.actions.setHelpGuides(helpGuides)
      } catch (error) {
        if (error instanceof ApiResponseError && error.statusCode === 403) {
          showNotification({
            type: 'error',
            message: translate.phrases.placeholder('You do not have permission to view help guides.'),
          })
        } else if (!(error instanceof OfflineRequestError)) {
          Sentry.captureException(error)

          showNotification({
            type: 'error',
            message: translate.phrases.placeholder('Error: could not load help guides!'),
          })
        }
      } finally {
        setIsLoading(false)
      }
    }

    loadHelpGuides()
  }, [])

  const handleSelectGuide = (type: 'node' | 'device', equipmentType: string, section: HelpGuideSection) => {
    const guides = serviceCenterStore.selectors.getHelpGuides(serviceCenterStore.getState())
    const steps = guides?.[type === 'node' ? 'nodes' : 'devices'][equipmentType]?.[section] || []

    if (!steps.length) return

    let equipmentTypeLabel: string

    if (type === 'node') {
      equipmentTypeLabel = getNodeTypeLabel(equipmentType as TNodeType)
    } else {
      const device = fieldAssetStore.getState().devices[equipmentType]

      equipmentTypeLabel = device?.name || equipmentType
    }

    const sectionLabel = getHelpGuideSectionTitle(section)
    const title = `${equipmentTypeLabel} - ${sectionLabel}`

    setSelectedGuide({ title, steps })
  }

  const handleOnClose = () => {
    if (selectedGuide) {
      setSelectedGuide(null)

      setCurrentStep(0)

      return
    }

    closeModal(MODAL_ID)
  }

  const showPreviousStep = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    } else {
      setSelectedGuide(null)
    }
  }

  const showNextStep = () => {
    if (selectedGuide?.steps && currentStep !== selectedGuide.steps.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }

  const currentGuideStep = selectedGuide?.steps[currentStep]

  return (
    <>
      <WideHeader
        title={
          selectedGuide
            ? selectedGuide.title
            : translate.phrases.placeholder('Assembly and Installation Guides')
        }
        onClose={handleOnClose}
        isSecondaryModal={!!selectedGuide}
      />

      <LoadingOverlay visible={isLoading} />

      {currentGuideStep ? (
        <HelpGuideStep
          title={currentGuideStep.title}
          content={currentGuideStep.content}
          image={currentGuideStep.image}
          stepNumber={currentStep + 1}
          totalSteps={selectedGuide.steps.length}
        />
      ) : (
        <HelpGuideList
          expandedCard={initialGuide ? `${initialGuide?.type}-${initialGuide.equipmentType}` : undefined}
          onSelectGuide={handleSelectGuide}
        />
      )}

      {selectedGuide && (
        <Footer
          onPrevious={showPreviousStep}
          onNext={showNextStep}
          disableNextButton={currentStep === selectedGuide.steps.length - 1}
        />
      )}
    </>
  )
}
