import { Switch } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { Dispatch, SetStateAction } from 'react'
import { colors } from 'settings/colors'
import { DatePickerInput } from '@mantine/dates'
import moment from 'moment-timezone'
import { TPestSelected } from '../../../_utils/formTypesAndStyles'
import { checkAuthorization } from 'utils/checkAuthorization'
import { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { TPermission } from '@semios/app-platform-value-type-definitions'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import {
  TInsect,
  TProperty,
} from '@semios/app-platform-banyan-route-definitions/src/routes/userAppStartup/fieldAssetValueTypes'
import { PropertiesSubtitle } from './PropertiesSubtitle'
import { Authorization } from 'components/Authorization/Authorization'
import React from 'react'
import { getDateFromReport } from '../../../_utils/getDateFromReport'
import { defaultDates } from '../../../_utils/defaultDates'
import { TPhrases } from '@semios/app-platform-i18n-core'

export const PestControl = ({
  pestsSelected,
  setPestsSelected,
  selectedReportProperties,
  reportProperties,
}: {
  pestsSelected: TPestSelected | null
  setPestsSelected: Dispatch<SetStateAction<TPestSelected | null>>
  selectedReportProperties: TOptions[]
  reportProperties: TProperty[]
}) => {
  const { pestTypes } = fieldAssetStore.useSelector((s) => ({
    pestTypes: s.insects,
  }))

  const propertyIds = selectedReportProperties
    ? Object.values(selectedReportProperties).map((obj) => obj.value)
    : null

  const pestReportPermissions = pestTypes
    ? Object.values(pestTypes).map((item: TInsect) => {
        const filteredPropertiesByPermission = Object.values(selectedReportProperties).filter(
          (property: TOptions) => {
            return checkAuthorization({
              permission: `CREATE_REPORT_PESTS_DEGREE_DAYS_ID_${item.insectId}` as TPermission,
              entity: Number(property.value),
            })
          },
        )

        const filteredPropertiesByTrapCatchPermission = Object.values(selectedReportProperties).filter(
          (property: TOptions) => {
            return checkAuthorization({
              permission: `CREATE_REPORT_PESTS_TRAP_CATCHES_ID_${item.insectId}` as TPermission,
              entity: Number(property.value),
            })
          },
        )

        return {
          name: item.name,
          insect: item.insectId,
          properties: filteredPropertiesByPermission,
          trapCatchProperties: filteredPropertiesByTrapCatchPermission,
        }
      })
    : null

  const hasProperties = pestReportPermissions
    ? pestReportPermissions.some((permission) => permission.properties.length > 0)
    : false

  const hasSouthernHemisphereProperties = Object.values(reportProperties ?? {}).some(
    (p) => p.isSouthernHemisphere,
  )

  const hasNorthernHemisphereProperties = Object.values(reportProperties ?? {}).some(
    (p) => !p.isSouthernHemisphere,
  )

  return (
    <Authorization requires={hasProperties}>
      <div css={{ paddingBottom: '25px', borderBottom: `1px solid ${colors.grey200}` }}>
        <h3 css={{ fontWeight: '500' }}>{translate.phrases.banyanApp('Pest Control')}</h3>
        {pestReportPermissions
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((pest) => {
            if (pest.properties.length < 1) {
              return null
            }

            const currentSelection = pestsSelected
              ? Object.values(pestsSelected)?.filter((selectedPests) => {
                  return selectedPests ? selectedPests.insectId === pest.insect.toString() : null
                })
              : null

            const selectValue = !currentSelection?.[0]?.degreeDays || false
            const selectTrapValue = !currentSelection?.[0]?.trapCatches || false
            const hasTrapCatch = pest?.trapCatchProperties.length > 0

            const defaultStart =
              hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
                ? defaultDates?.southernHemisphere?.pestStartDate
                : defaultDates?.northernHemisphere?.pestStartDate

            const defaultEnd =
              hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
                ? defaultDates?.southernHemisphere?.pestEndDate
                : defaultDates?.northernHemisphere?.pestEndDate

            return (
              <div key={pest.name}>
                <h4>
                  {translate.phrases.dbInsectName(pest.name as TPhrases['dbInsectName'])}
                  {PropertiesSubtitle({
                    permission: `CREATE_REPORT_PESTS_DEGREE_DAYS_ID_${pest.insect}` as TPermission,
                    propertyIds: propertyIds,
                  })}
                </h4>

                {hasTrapCatch ? (
                  <div css={{ display: 'flex', flexWrap: 'nowrap', marginBottom: '10px' }}>
                    <Switch
                      size="md"
                      styles={{
                        label: { cursor: 'unset', fontSize: 16 },
                        track: {
                          width: 36,
                          cursor: 'pointer',
                          backgroundColor: currentSelection?.[0]?.trapCatches
                            ? `${colors.green} !important`
                            : undefined,
                          borderColor: currentSelection?.[0]?.trapCatches
                            ? `${colors.green} !important`
                            : undefined,
                        },
                      }}
                      checked={currentSelection?.[0]?.trapCatches}
                      onChange={() =>
                        setPestsSelected((prevState) => ({
                          ...prevState,
                          [pest.insect]: {
                            insectId: pest.insect.toString(),
                            degreeDays: prevState?.[pest.insect]?.degreeDays
                              ? prevState?.[pest.insect]?.degreeDays
                              : false,
                            trapCatches: selectTrapValue,
                            startDate:
                              hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
                                ? defaultDates?.southernHemisphere?.pestStartDate
                                : defaultDates?.northernHemisphere?.pestStartDate,
                            endDate:
                              hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
                                ? defaultDates?.southernHemisphere?.pestEndDate
                                : defaultDates?.northernHemisphere?.pestEndDate,
                          },
                        }))
                      }
                    />
                    <div css={{ marginLeft: '10px' }}>
                      <translate.Phrases.templates
                        k={'<label>{{label}}</label> <value>{{value}}</value>'}
                        c={{ label: <React.Fragment />, value: <strong /> }}
                        v={{
                          label: translate.phrases.banyanApp('Include trap catches for the last week for'),
                          value: translate.phrases.dbInsectName(pest.name as TPhrases['dbInsectName']),
                        }}
                      />
                    </div>
                  </div>
                ) : null}

                <div css={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <Switch
                    size="md"
                    styles={{
                      label: { cursor: 'unset', fontSize: 16 },
                      track: {
                        width: 36,
                        cursor: 'pointer',
                        backgroundColor: currentSelection?.[0]?.degreeDays
                          ? `${colors.green} !important`
                          : undefined,
                        borderColor: currentSelection?.[0]?.degreeDays
                          ? `${colors.green} !important`
                          : undefined,
                      },
                    }}
                    checked={currentSelection?.[0]?.degreeDays}
                    onChange={() =>
                      setPestsSelected((prevState) => ({
                        ...prevState,
                        [pest.insect]: {
                          insectId: pest.insect.toString(),
                          degreeDays: selectValue,
                          trapCatches: prevState?.[pest.insect]?.trapCatches
                            ? prevState?.[pest.insect]?.trapCatches
                            : false,
                          startDate:
                            hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
                              ? defaultDates?.southernHemisphere?.pestStartDate
                              : defaultDates?.northernHemisphere?.pestStartDate,
                          endDate:
                            hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
                              ? defaultDates?.southernHemisphere?.pestEndDate
                              : defaultDates?.northernHemisphere?.pestEndDate,
                        },
                      }))
                    }
                  />
                  <div css={{ marginLeft: '10px' }}>
                    <translate.Phrases.templates
                      k={'<label>{{label}}</label> <value>{{value}}</value>'}
                      c={{ label: <React.Fragment />, value: <strong /> }}
                      v={{
                        label: translate.phrases.banyanApp(
                          'Include degree days forecast for the upcoming week for',
                        ),
                        value: translate.phrases.dbInsectName(pest.name as TPhrases['dbInsectName']),
                      }}
                    />
                  </div>
                </div>

                <div css={{ marginTop: '15px', display: 'flex' }}>
                  <DatePickerInput
                    valueFormat={translate.dates.getMomentFormat('MMM D')}
                    clearable={false}
                    placeholder={translate.phrases.banyanApp('Select start date')}
                    monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                    value={getDateFromReport(
                      currentSelection?.[0]?.startDate ? currentSelection?.[0]?.startDate : defaultStart,
                    )}
                    onChange={(e) =>
                      setPestsSelected((prevState) => ({
                        ...prevState,
                        [pest.insect]: {
                          ...currentSelection?.[0],
                          startDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                        },
                      }))
                    }
                    css={{ maxWidth: '100%', width: '240px', marginRight: '15px' }}
                    disabled={!currentSelection?.[0]?.degreeDays}
                  />

                  <DatePickerInput
                    valueFormat={translate.dates.getMomentFormat('MMM D')}
                    clearable={false}
                    placeholder={translate.phrases.banyanApp('Select end date')}
                    value={getDateFromReport(
                      currentSelection?.[0]?.endDate ? currentSelection?.[0]?.endDate : defaultEnd,
                    )}
                    monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                    onChange={(e) =>
                      setPestsSelected((prevState) => ({
                        ...prevState,
                        [pest.insect]: {
                          ...currentSelection?.[0],
                          endDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                        },
                      }))
                    }
                    css={{ maxWidth: '100%', width: '240px' }}
                    disabled={!currentSelection?.[0]?.degreeDays}
                  />
                </div>
              </div>
            )
          })}
      </div>
    </Authorization>
  )
}
