import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from '@semios/app-platform-common'
import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import { GridTableContentSection, GridTableContentSectionItem } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { getCropNameFromId } from 'utils/getCropNameFromId'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { defaultValuesRowHeight } from '../_utils'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'

type CommonValueType = { timestamp: string; fruitGrowth?: number | null; fruitSize?: number | null }

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_FRUIT_GROWTH_DETAILS' })

const preferredAggregationInterval = {
  preferredAggregationInterval: EAggregationInterval.DAILY,
}

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  fruitSize: preferredAggregationInterval,
  fruitGrowth: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  const points = fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.points

  if (!points) return {}

  const fruitDendrometerStationsForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(points),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  if (!selectedValueGroups.fruit_growth || isEmpty(fruitDendrometerStationsForProperty)) return {}

  const lngLats = fruitDendrometerStationsForProperty.map((p) => p.lngLat)

  return {
    points: {
      lngLats,
      valuesRequested,
    },
  }
}

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.fruitGrowth().categoryTitleWithoutUnit(),
    titleChildren: <DropdownSelectorProperty valuesTimeseriesToFilterOn={Object.keys(valuesRequested)} />,
    id: 'summary-grid-fruit-growth',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const points = fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.points ?? {}

  const fruitDendrometerStationsForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(points),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  const items: GridTableContentSectionItem[] = fruitDendrometerStationsForProperty
    .map((fds) => {
      const values: Record<string, CommonValueType> = {}
      const { fruitGrowth, fruitSize } = data?.points?.[fds.lngLat]?.values ?? {}

      const targetSizeUnitForConverter =
        fruitSize?.[0]?.metadata?.targetSizeUnit === 'INCH' ? 'in' : undefined

      fruitGrowth?.[0]?.timeseries?.forEach((ts) => {
        values[ts.timestamp] = values[ts.timestamp] ?? { timestamp: ts.timestamp }

        values[ts.timestamp].fruitGrowth = ts.value
      })

      fruitSize?.[0]?.timeseries?.forEach((ts) => {
        values[ts.timestamp] = values[ts.timestamp] ?? { timestamp: ts.timestamp }

        values[ts.timestamp].fruitSize = ts.value
      })

      const deviceName = fds.name || translate.phrases.banyanApp('Unnamed Station')
      const cropName = getCropNameFromId(Number(fds.configuration.fruitDendrometerCropId))
      // we won't use this in the rendering, but we want its translated values for sorting
      const stringToSortOn = `${cropName} - ${deviceName}`

      return {
        stringToSortOn,
        id: fds.lngLat,
        height: defaultValuesRowHeight,
        deviceName,
        cropName,
        labelMinWidth: 190,
        valueMinWidth: 120,
        values,
        label: translate.phrases.templates('{{labelA}} - {{labelB}}', {
          labelA: deviceName,
          labelB: cropName,
        }),
        render: (dataPoint: typeof values[keyof typeof values]) => {
          if (isNil(dataPoint) || isNil(dataPoint.fruitSize)) {
            return translate.phrases.templates('-')
          }

          return (
            <div css={{ display: 'flex', flexDirection: 'column', height: 'unset', textAlign: 'center' }}>
              <div>
                {unitConverter
                  .fruitSize(dataPoint.fruitSize, { outputUnit: targetSizeUnitForConverter })
                  .valueWithSuffix()}
              </div>
              <div>
                {isNil(dataPoint.fruitGrowth) ? (
                  translate.phrases.templates('-')
                ) : (
                  <span css={{ color: dataPoint.fruitGrowth > 0 ? colors.green : colors.red }}>
                    {dataPoint.fruitGrowth > 0 ? '+' : ''}
                    {unitConverter
                      .fruitGrowth(dataPoint.fruitGrowth, { outputUnit: targetSizeUnitForConverter })
                      .valueWithSuffix()}
                  </span>
                )}
              </div>
            </div>
          )
        },
      }
    })
    .sort(sortByKey('stringToSortOn'))
    .map(({ stringToSortOn, ...rest }) => rest)

  return {
    ...commonReturnItems,
    items,
  }
}
