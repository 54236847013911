import { routes } from '@semios/app-platform-banyan-route-definitions'
import { AlertTypeId } from 'App/Map/UserSettingsMenu/Shared/Alerts/settings/alertSettings'
import { userDetailsStore } from 'stores/userDetailsStore'

export const getAvailablePestTypes = ({
  permissions = userDetailsStore.getState().permissions,
  alertTypeId,
}: {
  permissions?: routes.UserAppStartup.Response['user']['permissions']
  alertTypeId: AlertTypeId | null
}) => {
  let pestsPermission: string | null = null

  if (alertTypeId === 'trapCatches') pestsPermission = 'VIEW_PESTS_TRAP_CATCHES_ID_'

  if (alertTypeId === 'degreeDays') pestsPermission = 'VIEW_PESTS_DEGREE_DAYS_ID_'

  const pestTypesToReturn = new Set()

  if (pestsPermission !== null) {
    Object.keys(permissions || {}).forEach((permission) => {
      if (permission.includes(pestsPermission as string)) {
        const insectId = permission.split(pestsPermission as string)[1]

        if (insectId) {
          pestTypesToReturn.add(Number(insectId))
        }
      }
    })
  }

  return [...pestTypesToReturn]
}
