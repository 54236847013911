import { CSSObject } from '@emotion/react'
import { TFieldAssetKeyTypes, TFieldAssetType } from 'App/Map/types'
import { MapItemsCache } from 'components/GoogleMap/MapItemsCache'
import {
  makeReactComponentOverlayView,
  ReactComponentOverlayView,
} from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import { TrapBadgeComponent, TTrapBadgeComponentProps } from './TrapBadgeComponent/TrapBadgeComponent'

export type TRAP_META = {
  type: TFieldAssetType
  propertyId: string
  blockId: TFieldAssetKeyTypes.TBlockId
  lngLat: TFieldAssetKeyTypes.TLngLat
  name: string
  insectId?: number
  latLng: google.maps.LatLng
}

export type TTrapsValueTypesToPropsDictionary = Record<
  string,
  {
    children: string // normally we'd use ReactNode, but we're going to render to an svg
    getContainerCSS: () => CSSObject
  }
>

export class TrapValuesCache extends MapItemsCache<
  TRAP_META,
  TTrapsValueTypesToPropsDictionary,
  ReactComponentOverlayView<TTrapBadgeComponentProps>
> {
  constructor(options: { maps: typeof google.maps; map: google.maps.Map }) {
    super({
      createOverlay: (o) => {
        return makeReactComponentOverlayView({
          component: TrapBadgeComponent,
          latLng: o.meta.latLng,
          maps: o.maps,
          map: o.map,
          transform: 'translate(-50%, 0%)',
        })
      },
      renderOverlay: (o) => {
        o.overlay.render({ ...o, valueTypesToPropsDictionary: o.value })
      },
      hideOverlay: (overlay) => {
        overlay.setMap(null)
      },
      maps: options.maps,
      map: options.map,
    })
  }
}
