import { css } from '@emotion/css'
import { KcBlockSettingsModalButton } from 'components/KcBlockSettingsModalButton/KcBlockSettingsModalButton'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { translate } from 'i18n/i18n'
import { detailsPanelStore, TEvapotranspirationValueToShow } from 'stores/detailsPanelStore'
import { TDropdownSelectorBlockProps } from '../DropdownSelectorBlock/DropdownSelectorBlock'
import { DropdownSelectorProperty } from '../DropdownSelectorProperty/DropdownSelectorProperty'

export const EvapotranspirationTitleChildren = (
  props: TDropdownSelectorBlockProps & {
    showMonthsToggle: boolean
  },
) => {
  const { showMonthsToggle, ...rest } = props

  const { evapotranspirationValueToShow } = detailsPanelStore.useSelector((s) => ({
    evapotranspirationValueToShow: s.evapotranspirationValueToShow,
  }))

  return (
    <div css={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '4px 12px' }}>
      <DropdownSelectorProperty {...rest} />
      {showMonthsToggle && (
        <ToggleButton
          buttonProps={{ size: 'xs', className: css({ fontSize: 13 }) }}
          data={[
            { label: translate.phrases.unitSymbols('ETc'), value: 'ETC' },
            { label: translate.phrases.unitSymbols('ETo'), value: 'ETO' },
          ]}
          onChange={(newValue) =>
            detailsPanelStore.setState((s) => ({
              ...s,
              evapotranspirationValueToShow: newValue as TEvapotranspirationValueToShow,
            }))
          }
          value={evapotranspirationValueToShow}
        />
      )}
      <KcBlockSettingsModalButton />
    </div>
  )
}
