import { TFieldAssetKeyTypes, TFieldAssetValueTypes } from 'App/Map/types'
import { lngLatStringToLngLatObject } from 'utils/lngLatStringToLngLatObject'
import { sortByClosestPoints } from 'utils/sortByClosestPoints'

export const getClosestLngLatBasedFieldAssetInPropertyForAPoint = ({
  anchorCoordinates,
  fieldAssetValues,
  block,
}: {
  anchorCoordinates: { lng: number; lat: number }
  block?: TFieldAssetKeyTypes.TBlockId | null
  fieldAssetValues: TFieldAssetValueTypes.TPoint[]
}): TFieldAssetKeyTypes.TLngLat | null => {
  if (block) {
    const [closestInBlock] = fieldAssetValues
      .filter((p) => p?.blockIds?.includes(block))
      .map((p) => {
        return {
          ...lngLatStringToLngLatObject(p.lngLat),
          lngLat: p.lngLat,
        }
      })
      .sort(sortByClosestPoints(anchorCoordinates))

    if (closestInBlock) return closestInBlock.lngLat
  }

  const [closest] = fieldAssetValues
    .map((f) => {
      return {
        ...lngLatStringToLngLatObject(f.lngLat),
        lngLat: f.lngLat,
      }
    })
    .sort(sortByClosestPoints(anchorCoordinates))

  if (closest) return closest.lngLat

  return null
}
