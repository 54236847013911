import { css } from '@emotion/css'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { translate } from 'i18n/i18n'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { isSemiosEmployeeOrTester } from 'utils/isSemiosEmployeeOrTester'
import {
  DropdownSelectorBlock,
  TDropdownSelectorBlockProps,
} from '../../../../../SectionTitleBars/DropdownSelectorBlock/DropdownSelectorBlock'

export const LarvalTrendTitleChildren = (props: TDropdownSelectorBlockProps & { showToggle: boolean }) => {
  const { showToggle, ...rest } = props

  const { larvalTrendShowValues } = detailsPanelStore.useSelector((s) => ({
    larvalTrendShowValues: s?.larvalTrendShowValues,
  }))

  return (
    <div css={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '4px 24px' }}>
      <div css={{ alignSelf: 'center' }}>
        <DropdownSelectorBlock {...rest} />
      </div>
      {showToggle && isSemiosEmployeeOrTester() && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '4px 24px',
          }}
        >
          <div css={{ fontWeight: 'normal' }}>{translate.phrases.banyanApp('Show Larval Trend Values')}</div>
          <ToggleButton
            buttonProps={{ size: 'xs', className: css({ fontSize: 13 }) }}
            data={[
              { label: translate.phrases.banyanApp('On'), value: true },
              { label: translate.phrases.banyanApp('Off'), value: false },
            ]}
            onChange={(newValue) =>
              detailsPanelStore.setState((s) => ({ ...s, larvalTrendShowValues: newValue }))
            }
            value={larvalTrendShowValues}
          />
        </div>
      )}
    </div>
  )
}
