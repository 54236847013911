import { Popover } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconInfo } from 'components/icons/IconInfo'
import { ReactNode } from 'react'
import { colors } from 'settings/colors'

const darkModeStyle = {
  backgroundColor: colors.midnight,
  color: colors.white,
}

export const InfoPopover = ({
  content,
  width = 350,
  darkMode = true,
}: {
  content: ReactNode
  width?: number
  darkMode?: boolean
}) => {
  const [opened, { close, open }] = useDisclosure(false)

  return (
    <Popover withArrow shadow="md" radius={5} width={width} opened={opened}>
      <Popover.Target>
        <div onMouseEnter={open} onMouseLeave={close} css={{ marginLeft: 5 }}>
          <IconInfo />
        </div>
      </Popover.Target>
      <Popover.Dropdown css={[{ pointerEvents: 'none' }, darkMode && darkModeStyle]}>
        {content}
      </Popover.Dropdown>
    </Popover>
  )
}
