import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty, sortByKey } from '@semios/app-platform-common'
import { Group } from '@mantine/core'
import { EvapotranspirationTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/EvapotranspirationTitleChildren/EvapotranspirationTitleChildren'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import { GridTableContentSection } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { getCropNameFromId } from 'utils/getCropNameFromId'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { BlockWithWeatherStationLabel } from '../../../BlockWithWeatherStationLabel/BlockWithWeatherStationLabel'
import {
  defaultValuesRowHeight,
  evapotranspirationTimestampToValuesDictionaryMaker,
  timestampToValuesDictionaryMaker,
} from '../_utils'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_EVAPOTRANSPIRATION_DETAILS' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  evapotranspirationETo: preferredAggregationInterval,
  evapotranspirationETc: preferredAggregationInterval,
  evapotranspirationKc: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  const blocks = fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.blocks

  if (!blocks) return {}

  const relevantBlocksForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(blocks),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  if (!selectedValueGroups.evapotranspiration || isEmpty(relevantBlocksForProperty)) return {}

  const blockIds = relevantBlocksForProperty.map((block) => block.blockId)

  return {
    blocks: {
      blockIds,
      valuesRequested,
    },
  }
}

const generateItem = ({
  block,
  label,
  values,
  weatherStationLabel,
}: {
  block: string
  label: string
  values: ReturnType<
    typeof timestampToValuesDictionaryMaker | typeof evapotranspirationTimestampToValuesDictionaryMaker
  >
  weatherStationLabel: string
}) => ({
  id: 'evapotranspiration' + block,
  label: <BlockWithWeatherStationLabel label={label} weatherStationLabel={weatherStationLabel} />,
  height: defaultValuesRowHeight + 45, // some extra space for the weather station label
  labelMinWidth: 160,
  valueMinWidth: 120,
  render: (dataPoint: { value: number | null; kc?: number | null }) => {
    if (typeof dataPoint?.value !== 'number') {
      return translate.phrases.templates('-')
    }

    return (
      <Group css={{ flexDirection: 'column', justifyContent: 'center' }}>
        <div>{unitConverter.evapotranspiration(dataPoint?.value).valueWithSuffix()}</div>
        {dataPoint.kc && (
          <div css={{ opacity: 0.4 }}>
            {translate.phrases.templates('{{label}}: {{value}}', {
              label: translate.phrases.unitSymbols('Kc'),
              value: dataPoint.kc,
            })}
          </div>
        )}
      </Group>
    )
  },
  values,
})

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.evapotranspiration().categoryTitleWithoutUnit(),
    titleChildren: (
      <EvapotranspirationTitleChildren
        showMonthsToggle
        valuesTimeseriesToFilterOn={Object.keys(valuesRequested)}
      />
    ),
    id: 'summary-grid-evapotranspiration',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const selectedProperty =
    fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssetsStore.getState().property)]

  const blocks = selectedProperty?.blocks ?? {}
  const points = selectedProperty?.points ?? {}
  const { evapotranspirationValueToShow } = detailsPanelStore.getState()

  const items = Object.values(blocks)
    .sort(sortByKey('name'))
    .map((block) => {
      const { blockId, cropIds, name } = block
      const etValues = data?.blocks?.[blockId]?.values

      const weatherStationLabel = block.associatedWeatherStation
        ? points[block.associatedWeatherStation].name
        : ''

      const values =
        evapotranspirationValueToShow === 'ETO'
          ? timestampToValuesDictionaryMaker(etValues?.evapotranspirationETo?.[0]?.timeseries ?? [])
          : evapotranspirationTimestampToValuesDictionaryMaker(
              etValues?.evapotranspirationETc?.[0]?.timeseries ?? [],
              etValues?.evapotranspirationKc?.[0]?.timeseries ?? [],
            )

      const cropName = getCropNameFromId(cropIds?.[0])

      const label =
        translate.phrases.templates('{{labelA}} - {{labelB}}', {
          labelA: name,
          labelB: cropName,
        }) || translate.phrases.banyanApp('Unnamed Block')

      return generateItem({ block: blockId.toString(), label, values, weatherStationLabel })
    })
    .filter(Boolean)

  return {
    ...commonReturnItems,
    items,
  }
}
