import { Geometry, MultiPolygon, Point, Polygon } from '@turf/helpers'

export function transformCoordsToLatLng(geometry: Point): google.maps.LatLngLiteral

// eslint-disable-next-line no-redeclare
export function transformCoordsToLatLng(geometry: Polygon): google.maps.LatLngLiteral[][]

// eslint-disable-next-line no-redeclare
export function transformCoordsToLatLng(geometry: MultiPolygon): google.maps.LatLngLiteral[][][]

// Transform GeoJSON coordinates into LatLng literals
// eslint-disable-next-line no-redeclare
export function transformCoordsToLatLng(geometry: Point | Polygon | MultiPolygon) {
  if (geometry.type === 'Point') {
    const [lng, lat] = (geometry as Point).coordinates

    return { lat, lng }
  } else if (geometry.type === 'Polygon') {
    return (geometry as Polygon).coordinates.map((ring) => ring.map(([lng, lat]) => ({ lat, lng })))
  } else if (geometry.type === 'MultiPolygon') {
    return (geometry as MultiPolygon).coordinates.map((polygon) =>
      polygon.map((ring) => ring.map(([lng, lat]) => ({ lat, lng }))),
    )
  } else {
    throw new Error(`Unsupported geometry type: ${(geometry as Geometry).type}`)
  }
}
