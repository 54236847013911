import { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorIrrigationZone } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorIrrigationZone/DropdownSelectorIrrigationZone'
import { getTimezoneForSelectedProperty } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedProperty'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { StackedChartSection } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { getChartDataWithPressureAndActivity } from './_utils/getChartDataWithPressureAndActivity'
import { chooseAmongstUnAggHourlyAndDaily } from '../../../chooseAmongstUnAggHourlyAndDaily'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_IRRIGATION_DETAILS' })

export const pressureSensorsChart = ({
  data,
  selectedFieldAssets,
}: {
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): StackedChartSection | null => {
  const title = translate.phrases.templates('{{labelA}} - {{labelB}}', {
    labelA: translate.phrases.banyanApp('Irrigation Activity by Pressure Sensors'),
    labelB: translate.phrases.banyanApp('Semios'),
  })

  const commonReturnItems = {
    title,
    titleChildren: <DropdownSelectorIrrigationZone key={'irrigationZonesDropdown'} />,
    id: 'stackem-pressure-sensors-chart',
  }

  if (!checkPermission() || !selectedFieldAssets.irrigationZoneEmitter)
    return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { property } = selectedFieldAssets
  const requestedAggregationInterval = chooseAmongstUnAggHourlyAndDaily()

  const timezone = getTimezoneForSelectedProperty({
    properties: fieldAssetStore.getState()?.properties,
    propertyId: property,
  })

  const showPressure = requestedAggregationInterval !== 'DAILY'

  const pressureSensorsChart = getChartDataWithPressureAndActivity({
    property,
    data,
    selectedFieldAssets,
    timezone,
    showPressure,
    title,
  })

  //@ts-ignore
  return pressureSensorsChart
}
