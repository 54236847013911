import { cloneDeep, isEqual } from 'lodash'
import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'
import { ApiRequestStatus, MetaWithNodeIdentifier, TApiRequest } from './types'

export type TApiRequestQueueStore = {
  queuedRequests: TApiRequest[]
}

const store = persistentInit<TApiRequestQueueStore>({
  initialState: {
    queuedRequests: [],
  },
  keysToPutInPersistentStorage: {
    queuedRequests: true,
  },
  keysToPutInURL: {
    queuedRequests: false,
  },
  storeName: 'apiRequestQueueStore',
})

export const apiRequestQueueStore = {
  ...store,
  actions: {
    queueRequest: (request: TApiRequest) => {
      apiRequestQueueStore.setState((s) => ({
        ...s,
        queuedRequests: [...s.queuedRequests, request],
      }))
    },
    unqueueRequest: (id: string) => {
      apiRequestQueueStore.setState((s) => ({
        ...s,
        queuedRequests: s.queuedRequests.filter((r) => r.id !== id),
      }))
    },
    updateRequestMeta: (id: string, meta: Partial<TApiRequest['meta']>) => {
      apiRequestQueueStore.setState((s) => {
        const existingRequest = s.queuedRequests.find((r) => r.id === id)

        if (!existingRequest) return s

        const updatedRequest = cloneDeep(existingRequest)

        updatedRequest.meta = {
          ...updatedRequest.meta,
          ...meta,
        }

        // If updated request is the same as existing request, don't update state
        if (isEqual(existingRequest, updatedRequest)) return s

        return {
          ...s,
          queuedRequests: s.queuedRequests.map((r) => {
            if (r.id !== id) return r

            return updatedRequest
          }),
        }
      })
    },
    setPreRequestError: (id: string, error?: string | boolean) => {
      apiRequestQueueStore.setState((s) => {
        const existingRequest = s.queuedRequests.find((r) => r.id === id)

        if (!existingRequest) return s

        const updatedRequest = cloneDeep(existingRequest)

        updatedRequest.preRequestError = error

        // If updated request is the same as existing request, don't update state
        if (isEqual(existingRequest, updatedRequest)) return s

        return {
          ...s,
          queuedRequests: s.queuedRequests.map((r) => {
            if (r.id !== id) return r

            return updatedRequest
          }),
        }
      })
    },
    setRequestStatus: (id: string, status: ApiRequestStatus, requestData?: Partial<TApiRequest>) => {
      apiRequestQueueStore.setState((s) => {
        const existingRequest = s.queuedRequests.find((r) => r.id === id)

        if (!existingRequest) return s

        let updatedRequest = cloneDeep(existingRequest)

        updatedRequest.status = status

        updatedRequest = {
          ...updatedRequest,
          ...(requestData || {}),
        }

        if (status === ApiRequestStatus.PROCESSING && updatedRequest.preRequestError) {
          updatedRequest.preRequestError = false
        }

        // If updated request is the same as existing request, don't update state
        if (isEqual(existingRequest, updatedRequest)) return s

        return {
          ...s,
          queuedRequests: s.queuedRequests.map((r) => {
            if (r.id !== id) return r

            return updatedRequest
          }),
        }
      })
    },
  },
  selectors: {
    getRequests: (s: TApiRequestQueueStore) => s.queuedRequests,
    getUncompletedRequests: (s: TApiRequestQueueStore) => {
      const { queuedRequests } = s

      return queuedRequests.filter(
        (r) => r.status !== ApiRequestStatus.COMPLETED && r.status !== ApiRequestStatus.ERROR,
      )
    },
    getFailedRequests: (s: TApiRequestQueueStore) => {
      const { queuedRequests } = s

      return queuedRequests.filter((r) => r.status === ApiRequestStatus.ERROR)
    },
    getUnsyncedNodeIdentifiers: (s: TApiRequestQueueStore) => {
      const { queuedRequests } = s

      const uncompletedRequests = queuedRequests.filter(
        (r) => r.status !== ApiRequestStatus.COMPLETED && r.status !== ApiRequestStatus.ERROR,
      )

      // Extract node identifiers from uncompleted requests
      const nodeIdentifiers = uncompletedRequests
        .map((r) => {
          const { nodeIdentifier } = r.meta as Partial<MetaWithNodeIdentifier>

          return nodeIdentifier
        })
        .filter(Boolean)

      // Remove duplicates
      const uniqueNodeIdentifiers = [...new Set(nodeIdentifiers)]

      return uniqueNodeIdentifiers
    },
  },
}
