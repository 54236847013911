import { isNil } from 'lodash'
import { TOrganizationKcDateRange, TValidOrganizationKcDateRange } from '../../types'

export const filterValidKcRanges = (
  kcRanges: TOrganizationKcDateRange[],
): TValidOrganizationKcDateRange[] => {
  return kcRanges.filter(
    (range): range is TValidOrganizationKcDateRange =>
      range !== null && range.dateRangeStart !== null && range.dateRangeEnd !== null && !isNil(range.kc),
  )
}
