import { routes } from '@semios/app-platform-banyan-route-definitions'
import { openConfirmModal } from '@mantine/modals'
import { SettingsDeleteButton } from 'App/Map/UserSettingsMenu/components/SettingsDeleteButton/SettingsDeleteButton'
import { TAvailablePreset } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { apiFetch } from 'utils/apiFetch'

export const DeletePresetButton = ({ idHash }: { idHash: TAvailablePreset['idHash'] }) => {
  return (
    <SettingsDeleteButton
      onClick={() => {
        openConfirmModal({
          title: <strong>{translate.phrases.banyanApp('Are you sure you want to delete this view?')}</strong>,
          labels: {
            confirm: translate.phrases.banyanApp('Confirm'),
            cancel: translate.phrases.banyanApp('Cancel'),
          },
          children: translate.phrases.banyanApp(
            'The view "{{presetName}}" will be permanently deleted. If you would instead just like it hidden from your views menu, you can uncheck it in the list of custom views',
            {
              presetName:
                userDetailsStore.getState().availableUserDefinedPresets?.[idHash]?.name ??
                translate.phrases.banyanApp('Unnamed View'),
            },
          ),
          onCancel: () => null,
          onConfirm: () => {
            userDetailsStore.setState((s) => {
              const { [idHash]: oneToDiscard, ...restOfTheAvailableUserDefinedPresets } =
                s.availableUserDefinedPresets ?? {}

              return {
                ...s,
                availableUserDefinedPresets: restOfTheAvailableUserDefinedPresets,
              }
            })

            smallStore.setState((s) => ({
              ...s,
              selectedPreset: s.selectedPreset === idHash ? null : s.selectedPreset,
            }))

            apiFetch<routes.AppPresetsManagement.Request, routes.AppPresetsManagement.Response>({
              url: routes.AppPresetsManagement.path,
              body: { userDefinedPresetsToDelete: [idHash] },
            }).catch(() => {
              // TODO: errorLogger
            })
          },
        })
      }}
    />
  )
}
