import { translate } from 'i18n/i18n'
import { chartZoneColors, colors } from 'settings/colors'

const commonProperties = {
  enableMouseTracking: false,
  includeInLegendCheckboxes: false,
  name: '',
  visible: true,
  yAxis: 0,
  isADepthSeries: false,
  rainSeries: false,
  showInLegend: false,
  hideFromTooltip: true,
  states: {
    // don't fade out these series when hovering over the chart
    inactive: {
      opacity: 1,
    },
  },
}

export const getPwsZones = ({
  timeseries,
  maxLowStress,
  minHighStress,
}: {
  timeseries: { timestamp: string }[]
  maxLowStress: number
  minHighStress: number
}) => {
  const pwsZones = {
    low: {
      ...commonProperties,
      name: translate.phrases.banyanApp('Low'),
      color: colors.zoneBorderLine,
      fillColor: chartZoneColors.plantStressZoneLow,
      dashStyle: 'solid',
      data: timeseries.map((data) => ({
        x: +new Date(data.timestamp),
        y: maxLowStress,
        type: 'area',
      })),
      lineWidth: 0.25,
      threshold: -Infinity,
      type: 'area',
      zIndex: 0,
      zoneAxis: 'x',
      id: 'lowZonePWS',
    },
    medium: {
      ...commonProperties,
      name: translate.phrases.banyanApp('Medium'),
      color: colors.zoneBorderLine,
      fillColor: chartZoneColors.plantStressZoneMedium,
      data: timeseries.map((data) => ({
        x: +new Date(data.timestamp),
        low: maxLowStress,
        high: minHighStress,
        type: 'area',
      })),
      lineWidth: 0.25,
      type: 'arearange',
      zIndex: 0,
      id: 'mediumZonePWS',
    },
    high: {
      ...commonProperties,
      name: translate.phrases.banyanApp('High'),
      color: colors.zoneBorderLine,
      fillColor: chartZoneColors.plantStressZoneHigh,
      data: timeseries.map((data) => ({
        x: +new Date(data.timestamp),
        y: minHighStress,
        type: 'area',
      })),
      lineWidth: 0.25,
      threshold: Infinity,
      type: 'area',
      zIndex: 0,
      zoneAxis: 'x',
      id: 'highZonePWS',
    },
  }

  return Object.values(pwsZones)
}
