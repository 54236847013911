import { routes } from '@semios/app-platform-banyan-route-definitions'
import { TPublishedIrrigationEvent } from 'App/irrigation-scheduler/components/Grid/components/Chart/utils/makeSeries'
import { getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents } from 'App/irrigation-scheduler/components/right-panels/utils/getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents'
import { groupBy } from 'lodash'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapApiScheduledEventStatusToTScheduledEventEnum } from '../../mapApiScheduledEventStatusToTScheduledEventEnum'
import {
  irrigationSchedulerStore,
  APPLIED_EVENT,
  SCHEDULED_CONTINUOUS_EVENT,
  SCHEDULED_PULSE_EVENT,
} from '../irrigationSchedulerStore'
import { getSelectedPropertyTimezoneForIrrigationScheduler } from '../selectors/getSelectedPropertyTimezoneForIrrigationScheduler'

export const eventsGet = (apiEvents: routes.IrrigationSchedulerEventsGet.Response) => {
  irrigationSchedulerStore.setState((isss) => {
    const appliedEvents: APPLIED_EVENT[] = []
    const debugPublishedEvents: TPublishedIrrigationEvent[] = []
    const debugPublishedPulseEvents: TPublishedIrrigationEvent[] = []
    const scheduledEvents: SCHEDULED_CONTINUOUS_EVENT[] = []
    const scheduledPulseEvents: SCHEDULED_PULSE_EVENT[] = []
    const timezone = getSelectedPropertyTimezoneForIrrigationScheduler(fieldAssetStore.getState(), isss)
    const hasPendingDeletionEvents = apiEvents.some((apiEvent) => apiEvent.hasPendingDeletionEvents)

    apiEvents.forEach((apiEvent) => {
      const { irrigationZoneEmitterId } = apiEvent

      apiEvent.actualIrrigationEvents.forEach((appliedEvent) => {
        const isRunning = appliedEvent.dateTo === null && appliedEvent.status === 'on'

        appliedEvents.push({
          dateFrom: moment.tz(appliedEvent.dateFrom, timezone),
          dateTo: isRunning ? moment.tz(new Date(), timezone) : moment.tz(appliedEvent.dateTo, timezone),
          irrigationZoneId: apiEvent.irrigationZoneId.toString(),
          irrigationZoneEmitterId,
          status: appliedEvent.status,
          emitterType: apiEvent.emitterType,
        })
      })

      apiEvent.publishedIrrigationEvents.forEach((publishedEvent) => {
        if (publishedEvent.pulseEventId) {
          publishedEvent.pulseIrrigationEvents?.forEach((pulseEvent) => {
            debugPublishedPulseEvents.push({
              ...pulseEvent,
              irrigationZoneEmitterId,
              dateFrom: moment.tz(pulseEvent.dateFrom, timezone),
              dateTo: moment.tz(pulseEvent.dateTo, timezone),
            })
          })
        } else if (publishedEvent.externalEventId) {
          debugPublishedEvents.push({
            ...publishedEvent,
            irrigationZoneEmitterId,
            dateFrom: moment.tz(publishedEvent.dateFrom, timezone),
            dateTo: moment.tz(publishedEvent.dateTo, timezone),
          })
        }
      })

      apiEvent.scheduledIrrigationEvents.forEach((scheduledEvent) => {
        if (scheduledEvent.eventId) {
          scheduledEvents.push({
            dateFrom: moment.tz(scheduledEvent.dateFrom, timezone),
            dateTo: moment.tz(scheduledEvent.dateTo, timezone),
            emitterType: apiEvent.emitterType,
            irrigationZoneEmitterId,
            eventId: scheduledEvent.eventId.toString(),
            type: mapApiScheduledEventStatusToTScheduledEventEnum(scheduledEvent.status),
            irrigationZoneId: apiEvent.irrigationZoneId.toString(),
          })
        } else if (
          scheduledEvent.pulseEventId &&
          scheduledEvent.pulseIrrigationEvents &&
          scheduledEvent.pulseIrrigationEvents.length > 0 &&
          scheduledEvent.pulseIrrigationConfig
        ) {
          const { earliestDateFrom, latestDateTo } =
            getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents(
              scheduledEvent.pulseIrrigationEvents,
              timezone,
            )

          scheduledPulseEvents.push({
            dateFrom: moment.tz(earliestDateFrom, timezone),
            dateTo: moment.tz(latestDateTo, timezone),
            emitterType: apiEvent.emitterType,
            irrigationZoneEmitterId,
            pulseEventId: scheduledEvent.pulseEventId?.toString(),
            pulseIrrigationConfig: scheduledEvent.pulseIrrigationConfig,
            pulseIrrigationEvents: scheduledEvent.pulseIrrigationEvents.map((event) => {
              return {
                dateFrom: moment.tz(event.dateFrom, timezone),
                dateTo: moment.tz(event.dateTo, timezone),
                eventId: event.eventId.toString(),
                externalEventId: event.externalEventId?.toString(),
                status: mapApiScheduledEventStatusToTScheduledEventEnum(event.status),
              }
            }),
            type: mapApiScheduledEventStatusToTScheduledEventEnum(scheduledEvent.status),
            irrigationZoneId: apiEvent.irrigationZoneId.toString(),
            externalEventId: scheduledEvent?.externalEventId?.toString(),
          })
        }
      })
    })

    return {
      ...isss,
      hasPendingDeletionEvents,
      appliedEventsByIrrigationZoneEmitterId: groupBy(
        appliedEvents,
        (event) => event.irrigationZoneEmitterId,
      ),
      debugPublishedEventsByIrrigationZoneEmitterId: groupBy(
        debugPublishedEvents,
        (event) => event.irrigationZoneEmitterId,
      ),
      debugPublishedPulseIrrigationEventsByIrrigationZoneEmitterId: groupBy(
        debugPublishedPulseEvents,
        (event) => event.irrigationZoneEmitterId,
      ),
      scheduledEvents: scheduledEvents.reduce<{ [key: string]: SCHEDULED_CONTINUOUS_EVENT }>((a, b) => {
        a[b.eventId] = { ...b }

        return a
      }, {}),
      scheduledPulseIrrigationEvents: scheduledPulseEvents.reduce<{
        [key: string]: SCHEDULED_PULSE_EVENT
      }>((a, b) => {
        a[b.pulseEventId] = { ...b }

        return a
      }, {}),
    }
  })
}
