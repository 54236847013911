import { TPoint } from '@semios/app-platform-banyan-route-definitions/src/routes/userAppStartup/fieldAssetValueTypes'
import { routes } from '@semios/app-platform-banyan-route-definitions'

type TPointToReturn = TPoint & { propertyId: number; propertyName: string }

export const getPointsByValuesCurrentKey = ({
  properties,
  key,
}: {
  properties: routes.UserAppStartup.Response['properties']
  key: string
}) => {
  const pointsToReturn: TPointToReturn[] = []

  Object.values(properties || {}).forEach((property) => {
    Object.values(property.points || {}).forEach((point) => {
      point.valuesCurrent.forEach((value) => {
        if (value.includes(key)) {
          if (!pointsToReturn.find((p) => p.lngLat === point.lngLat)) {
            pointsToReturn.push({
              ...point,
              propertyId: property.propertyId,
              propertyName: property.propertyName,
            })
          }
        }
      })
    })
  })

  return pointsToReturn
}
