import { useMemo, useState } from 'react'
import { colors } from 'settings/colors'
import { SectionTitleBar } from 'components/SectionTitleBar/SectionTitleBar'
import { arrayOfObjectsSearch } from 'utils/arrayOfObjectsSearch'
import type { Zone } from './ZonesTab'
import { getScreenSize } from 'utils/getScreenSize'
import { SettingsEditButton } from 'App/Map/UserSettingsMenu/components/SettingsEditButton/SettingsEditButton'

export const PropertySection = ({
  filterPropertiesText,
  propertyId,
  propertyName,
  setSelectedZone,
  zones,
}: {
  filterPropertiesText: string
  propertyId: number
  propertyName: string
  setSelectedZone: React.Dispatch<React.SetStateAction<(Zone & { propertyId: number }) | null>>
  zones: Zone[]
}) => {
  const filteredSettingItems = useMemo(() => {
    return arrayOfObjectsSearch(zones, filterPropertiesText, ['zoneName'])
  }, [filterPropertiesText, zones])

  const [collapsed, setCollapsed] = useState(false)

  // hide property titles if there are no zones and we are filtering
  if (filterPropertiesText && filteredSettingItems.length === 0) return null

  const maxWidth = getScreenSize().screenWidth - 40

  return (
    <>
      <SectionTitleBar
        collapsed={collapsed}
        onCollapse={() => setCollapsed((prev) => !prev)}
        title={propertyName}
        containerWidth={maxWidth}
      />
      <div
        css={{
          borderCollapse: 'collapse',
          boxSizing: 'border-box',
          height: collapsed ? 0 : '100%',
          opacity: collapsed ? 0 : 1,
          overflow: collapsed ? 'hidden' : 'unset',
          transition: 'height 80ms, opacity 80ms',
        }}
      >
        {filteredSettingItems.map((zone, i) => {
          return (
            <div
              css={{
                borderBottom: i === filteredSettingItems.length - 1 ? '' : `0.5px solid ${colors.grey200}`,
                display: 'flex',
                justifyContent: 'space-between',
                margin: 10,
              }}
              key={zone.irrigationZoneId}
            >
              <div>{zone.zoneName}</div>
              <SettingsEditButton
                onClick={() => {
                  setSelectedZone({ ...zone, propertyId: Number(propertyId) })
                }}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}
