import { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'

// TODO: we'll be able to change this to the value-type definitions things in the future
export const valueGroupsToUnitConverterFunctionForRendering: Record<
  TValueGroup,
  typeof unitConverter[keyof typeof unitConverter] | undefined // better to put undefined than to make this whole thing a partial
> = {
  air_temperature: unitConverter.temperatureInCanopy,
  almond_bloom: unitConverter.almondBloom,
  almond_hull_split: undefined,
  alternaria: undefined,
  atmospheric_pressure: undefined,
  bee_hours: unitConverter.beeHours,
  chill: unitConverter.chillCumulativeHoursBelow45FSEP, // feels weird, since this'll change, but we'll change to using value-type definitions in the future
  conditions: undefined,
  degree_days_insect_id_1: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_10: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_11: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_12: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_13: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_14: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_15: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_16: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_17: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_18: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_19: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_2: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_20: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_23: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_24: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_25: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_26: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_27: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_28: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_29: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_30: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_3: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_4: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_6: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_7: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_8: unitConverter.insectDegreeDaysCumulative,
  degree_days_insect_id_9: unitConverter.insectDegreeDaysCumulative,
  dew_point: unitConverter.dewPointInCanopy,
  equipment_status: undefined,
  evapotranspiration: unitConverter.evapotranspiration,
  fruit_growth: unitConverter.fruitGrowth,
  plant_stress: unitConverter.plantWaterStress,
  humidity: unitConverter.humidityInCanopy,
  irrigation_activity: undefined,
  larval_trend_insect_id_1: undefined,
  larval_trend_insect_id_11: undefined,
  larval_trend_insect_id_20: undefined,
  larval_trend_insect_id_3: undefined,
  larval_trend_insect_id_6: undefined,
  larval_trend_insect_id_7: undefined,
  larval_trend_insect_id_9: undefined,
  leaf_wetness: undefined,
  precipitation: unitConverter.precipitation,
  soil: unitConverter.soilAwc,
  spray_conditions: undefined,
  trap_catches_insect_id_1: undefined,
  trap_catches_insect_id_10: undefined,
  trap_catches_insect_id_11: undefined,
  trap_catches_insect_id_12: undefined,
  trap_catches_insect_id_15: undefined,
  trap_catches_insect_id_16: undefined,
  trap_catches_insect_id_17: undefined,
  trap_catches_insect_id_19: undefined,
  trap_catches_insect_id_2: undefined,
  trap_catches_insect_id_20: undefined,
  trap_catches_insect_id_25: undefined,
  trap_catches_insect_id_27: undefined,
  trap_catches_insect_id_28: undefined,
  trap_catches_insect_id_29: undefined,
  trap_catches_insect_id_30: undefined,
  trap_catches_insect_id_6: undefined,
  trap_catches_insect_id_7: undefined,
  trap_catches_insect_id_9: undefined,
  walnut_blight: undefined,
  wet_bulb: unitConverter.wetBulbInCanopy,
  wind: unitConverter.windSpeed,
  wind_machine: unitConverter.windMachineTemperature,
}
