import React from 'react'
import { ButtonVariantSection } from './ButtonVariantSection/ButtonVariantSection'

export const ButtonExamples = () => {
  return (
    <>
      <ButtonVariantSection variant={'primary'} />
      <ButtonVariantSection variant={'secondary'} />
      <ButtonVariantSection variant={'tertiary'} />
      <ButtonVariantSection variant={'positive'} />
      <ButtonVariantSection variant={'negative'} />
      <ButtonVariantSection variant={'link'} />
      <ButtonVariantSection variant={'link-negative'} />
    </>
  )
}
