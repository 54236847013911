import { translate } from 'i18n/i18n'
import { Dispatch, SetStateAction } from 'react'
import { TPestSelected, TReportStartEnd } from '../../_utils/formTypesAndStyles'
import { Weather } from './components/Weather'
import { PestControl } from './components/PestControl'
import { WaterManagement } from './components/WaterManagement'
import { CropDevelopment } from './components/CropDevelopment'
import { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { ErrorTextWrapper } from '../../components/ErrorTextWrapper'
import { colors } from 'settings/colors'
import { FireBlight } from './components/FireBlight'
import { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'

export const ReportContent = ({
  weather,
  setWeather,
  weatherStartEnd,
  setWeatherStartEnd,
  fireBlight,
  setFireBlight,
  fireBlightStartEnd,
  setFireBlightStartEnd,
  pestsSelected,
  setPestsSelected,
  evapotranspiration,
  setEvapotranspiration,
  evapotranspirationStartEnd,
  setEvapotranspirationBorerStartEnd,
  chill,
  setChill,
  chillStartEnd,
  setChillStartEnd,
  pollination,
  setPollination,
  pollinationStartEnd,
  setPollinationStartEnd,
  growDegree,
  setGrowDegree,
  growDegreeStartEnd,
  setGrowDegreeStartEnd,
  selectedReportProperties,
  reportProperties,
  irrigationActivity,
  setIrrigationActivity,
  irrigationActivityStartEnd,
  setIrrigationActivityStartEnd,
  soilMoisture,
  setSoilMoisture,
  soilMoistureStartEnd,
  setSoilMoistureStartEnd,
  triedToSubmit,
  showContentError,
}: {
  weather: boolean
  setWeather: Dispatch<SetStateAction<boolean>>
  weatherStartEnd: TReportStartEnd | null
  setWeatherStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  fireBlight: boolean
  setFireBlight: Dispatch<SetStateAction<boolean>>
  fireBlightStartEnd: TReportStartEnd | null
  setFireBlightStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  pestsSelected: TPestSelected | null
  setPestsSelected: Dispatch<SetStateAction<TPestSelected | null>>
  evapotranspiration: boolean
  setEvapotranspiration: Dispatch<SetStateAction<boolean>>
  evapotranspirationStartEnd: TReportStartEnd | null
  setEvapotranspirationBorerStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  chill: boolean
  setChill: Dispatch<SetStateAction<boolean>>
  chillStartEnd: TReportStartEnd | null
  setChillStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  pollination: boolean
  setPollination: Dispatch<SetStateAction<boolean>>
  pollinationStartEnd: TReportStartEnd | null
  setPollinationStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  growDegree: boolean
  setGrowDegree: Dispatch<SetStateAction<boolean>>
  growDegreeStartEnd: TReportStartEnd | null
  setGrowDegreeStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  selectedReportProperties: TOptions[]
  reportProperties: TProperty[]
  irrigationActivity: boolean
  setIrrigationActivity: Dispatch<SetStateAction<boolean>>
  irrigationActivityStartEnd: TReportStartEnd | null
  setIrrigationActivityStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  soilMoisture: boolean
  setSoilMoisture: Dispatch<SetStateAction<boolean>>
  soilMoistureStartEnd: TReportStartEnd | null
  setSoilMoistureStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  triedToSubmit: boolean
  showContentError: boolean
}) => {
  let isValid = false
  let helpText = ''

  if (!showContentError) {
    isValid = true
  } else if (showContentError && triedToSubmit) {
    helpText = translate.phrases.banyanApp('At least one report content type must be selected')

    isValid = false
  }

  return (
    <div css={{ paddingBottom: '25px', borderBottom: `1px solid ${colors.grey200}` }}>
      <div>
        <Weather
          weather={weather}
          setWeather={setWeather}
          weatherStartEnd={weatherStartEnd}
          setWeatherStartEnd={setWeatherStartEnd}
          selectedReportProperties={selectedReportProperties}
        />
        {/* Fire Blight */}
        <FireBlight
          fireBlight={fireBlight}
          setFireBlight={setFireBlight}
          fireBlightStartEnd={fireBlightStartEnd}
          setFireBlightStartEnd={setFireBlightStartEnd}
          selectedReportProperties={selectedReportProperties}
        />
        {/* PEST CONTROL */}
        <PestControl
          pestsSelected={pestsSelected}
          setPestsSelected={setPestsSelected}
          selectedReportProperties={selectedReportProperties}
          reportProperties={reportProperties}
        />
        {/* WATER MANAGEMENT */}
        <WaterManagement
          evapotranspiration={evapotranspiration}
          setEvapotranspiration={setEvapotranspiration}
          evapotranspirationStartEnd={evapotranspirationStartEnd}
          setEvapotranspirationBorerStartEnd={setEvapotranspirationBorerStartEnd}
          selectedReportProperties={selectedReportProperties}
          irrigationActivity={irrigationActivity}
          setIrrigationActivity={setIrrigationActivity}
          irrigationActivityStartEnd={irrigationActivityStartEnd}
          setIrrigationActivityStartEnd={setIrrigationActivityStartEnd}
          soilMoisture={soilMoisture}
          setSoilMoisture={setSoilMoisture}
          soilMoistureStartEnd={soilMoistureStartEnd}
          setSoilMoistureStartEnd={setSoilMoistureStartEnd}
        />
        {/* CROP DEVELOPMENT */}
        <CropDevelopment
          chill={chill}
          setChill={setChill}
          chillStartEnd={chillStartEnd}
          setChillStartEnd={setChillStartEnd}
          pollination={pollination}
          setPollination={setPollination}
          pollinationStartEnd={pollinationStartEnd}
          setPollinationStartEnd={setPollinationStartEnd}
          growDegree={growDegree}
          setGrowDegree={setGrowDegree}
          growDegreeStartEnd={growDegreeStartEnd}
          setGrowDegreeStartEnd={setGrowDegreeStartEnd}
          selectedReportProperties={selectedReportProperties}
        />
      </div>
      {helpText && !isValid ? <ErrorTextWrapper>{helpText}</ErrorTextWrapper> : null}
    </div>
  )
}
