import { routes } from '@semios/app-platform-banyan-route-definitions'
import { TAvailablePreset, TPresetId } from 'App/Map/types'
import { userDetailsStore } from 'stores/userDetailsStore'
import { apiFetch } from 'utils/apiFetch'
import { TNewPresetState } from '../Presets'

export const submitNewPreset = async ({ newPresetState }: { newPresetState: TNewPresetState }) => {
  const newPhoneyTemporaryHash = String(Math.random())

  const optimisticUpdate: Record<TPresetId, TAvailablePreset> = {
    [newPhoneyTemporaryHash]: {
      ...(newPresetState as Pick<
        TAvailablePreset,
        'name' | 'dayOffsetDateFrom' | 'dayOffsetDateTo' | 'selectedValueGroups' | 'valueGroupForMap'
      >),
      idHash: newPhoneyTemporaryHash,
      ignored: false,
    },
  }

  userDetailsStore.setState((s) => ({
    ...s,
    availableUserDefinedPresets: {
      ...s.availableUserDefinedPresets,
      ...optimisticUpdate,
    },
  }))

  const newPresetToSend = {
    name: newPresetState.name as string,
    dayOffsetDateFrom: newPresetState.dayOffsetDateFrom as number,
    dayOffsetDateTo: newPresetState.dayOffsetDateTo as number,
    selectedValueGroups: newPresetState.selectedValueGroups as string[],
    valueGroupForMap: newPresetState.valueGroupForMap as string,
  }

  return apiFetch<routes.AppPresetsManagement.Request, routes.AppPresetsManagement.Response>({
    url: routes.AppPresetsManagement.path,
    body: { newUserDefinedPresets: [newPresetToSend] },
  })
    .then((res) => {
      userDetailsStore.setState((s) => ({
        ...s,
        availableUserDefinedPresets: {
          ...s.availableUserDefinedPresets,
          ...optimisticUpdate,
        },
      }))

      const newPreset = res.newUserDefinedPresets?.[0] as TAvailablePreset

      if (!newPreset) return

      userDetailsStore.setState((s) => {
        const {
          [newPhoneyTemporaryHash]: optimisticUpdateToNotIncludeInNewList,
          ...nonOptimisticUpdateUserDefinedPresets
        } = s.availableUserDefinedPresets ?? {}

        return {
          ...s,
          availableUserDefinedPresets: {
            ...nonOptimisticUpdateUserDefinedPresets,
            [newPreset.idHash]: newPreset,
          },
        }
      })
    })
    .catch(() => {
      // TODO: errorLogger
    })
}
