import { translate } from 'i18n/i18n'

const CONVERSION_RATE_FOR_MM_AND_INCHES = 25.4

const convertInchesToMM = (waterAppliedInInches: number) =>
  waterAppliedInInches * CONVERSION_RATE_FOR_MM_AND_INCHES

const convertMMToInches = (waterAppliedInMM: number) => waterAppliedInMM / CONVERSION_RATE_FOR_MM_AND_INCHES

export const getWaterVolume = ({
  waterApplied,
  volumeUnitToDisplay,
  flowUnitPerHour,
}: {
  waterApplied: number
  volumeUnitToDisplay: 'INCH' | 'MILLIMETER'
  flowUnitPerHour: string
}): string => {
  switch (volumeUnitToDisplay) {
    case 'MILLIMETER': {
      const waterAppliedInMM = flowUnitPerHour === 'INCH' ? convertInchesToMM(waterApplied) : waterApplied

      return translate.measurements.millimeters.valueWithUnit(waterAppliedInMM, 2)
    }

    case 'INCH': {
      const waterAppliedInInches = flowUnitPerHour === 'INCH' ? waterApplied : convertMMToInches(waterApplied)

      return translate.measurements.inches.valueWithUnit(waterAppliedInInches, 2)
    }

    default: {
      throw new Error('Only Inch and Millimeter are impplemented')
    }
  }
}
