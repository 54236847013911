import { routes, SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorIrrigationZone } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorIrrigationZone/DropdownSelectorIrrigationZone'
import {
  getIdAndEmitterTypeFromZoneEmitterTypeKey,
  makeDeviceNameAndEmitterTypeLabel,
} from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'
import { TooltipFormatterContextObject } from 'highcharts'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { minutesToHoursAndMinutes } from 'utils/minutesToHoursAndMinutes'
import { sortByKey } from 'utils/sortByKey'
import { getVolumeUnit } from '../../../../../../_utils/getVolumeUnit'
import { convertPressureFromPSIToKPa } from '../../_utils/convertPressureFromPSIToKPa'
import { getIntervalForCurrentStamp } from '../../_utils/getIntervalForCurrentStamp'
import {
  EnumIrrigationActivityStatus,
  getIrrigationActivitySettings,
} from '../../_utils/irrigationActivitySettings'
import { PressureSensorsLegend } from '../PressureSensorsLegend'
import { getActivityIntervalsFromData } from './getActivityIntervalsFromData'
import { getAppliedWater } from './getAppliedWater'
import { getTooltipForPressureSensors } from './getTooltipForPressureSensors'
import { getWaterVolume } from './unitConversion'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { getPointLngLatsForIrrigationEmitterZoneId } from 'utils/getPointLngLatsForIrrigationEmitterZoneId'
import {
  IRRIGATION_PRESSURE_VALUE_TYPE_PREFIX,
  getValueTypeFromEmitterType,
} from '@semios/app-platform-common'
import { VV } from '@semios/app-platform-value-type-definitions'

type TSplineSeriesData = { x: number; y: number | null }

export type TPossibleResponseType = {
  metadata: {
    aggregationInterval: string | null
    flowRate: number
    flowUnitPerHour: string
    flowRateIsDefault: boolean
  }
  timeseries: {
    startTime: string
    endTime: string
    status: string | null
  }[]
}

interface TXRangeSeriesType {
  color: string
  key: number
  status: string | null
  x: number
  x2: number
  y: number
}

type TShapedDataForChart = {
  deviceName: string
  onDurationMinutes: number
  pressureData: TSplineSeriesData[] | null
  intervalsForDevice: TPossibleResponseType
  intervalsForChart: TXRangeSeriesType[]
  totalWaterApplied: number
}

export const getChartDataWithPressureAndActivity = ({
  property,
  timezone,
  selectedFieldAssets,
  data,
  showPressure,
  title,
}: {
  property: number | null
  timezone: string
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  showPressure: boolean
  title: string
}) => {
  const { dateFrom, dateTo } = detailsPanelStore.getState()
  const blankSeries: TSplineSeriesData[] = [] // an invisible series so that we get a tooltip even when no data

  let stamp = +new Date(dateFrom)

  while (stamp <= +new Date(dateTo)) {
    blankSeries.push({ x: stamp, y: 10 })

    stamp = stamp + 60 * 60 * 1000
  }

  const propertyData = fieldAssetStore.getState()?.properties?.[property as number]
  const points = propertyData?.points || {}
  const waterDepthUnit = propertyData?.propertySettings?.waterDepthUnit
  const { rain: rainUnitFromUserSetting, pressure: pressureUnitFromUserSetting } = userDetailsStore.getState()
  const volumeUnitToDisplay = getVolumeUnit({ waterDepthUnit, rainUnitFromUserSetting })

  let emitterType: SharedTypes.TEmitterType | null = null

  if (selectedFieldAssets.irrigationZoneEmitter)
    emitterType = getIdAndEmitterTypeFromZoneEmitterTypeKey(
      selectedFieldAssets.irrigationZoneEmitter,
    ).emitterType

  const activityIntervals = getActivityIntervalsFromData({
    data,
    selectedFieldAssets,
  })

  const lngLatsForZone =
    selectedFieldAssets?.property && selectedFieldAssets?.irrigationZoneEmitter
      ? getPointLngLatsForIrrigationEmitterZoneId({
          propertyId: selectedFieldAssets.property,
          irrigationEmitterZoneId: selectedFieldAssets.irrigationZoneEmitter,
        })
      : []

  const valueType = getValueTypeFromEmitterType(
    IRRIGATION_PRESSURE_VALUE_TYPE_PREFIX,
    emitterType as SharedTypes.TEmitterType,
  ) as VV.DomainTypes.Irrigation.TTimeseriesValueTypeKeysMerged

  const pressureDataForPoints: NonNullable<
    NonNullable<VV.Response['points']>[string]['values']['irrigationPressureSemios_pump']
  > = []

  lngLatsForZone.forEach((lngLat) => {
    const pressureDataForPoint = data?.points?.[lngLat]?.values?.[valueType]

    if (pressureDataForPoint) {
      const data = pressureDataForPoint as typeof pressureDataForPoints

      data.forEach((row) => {
        pressureDataForPoints.push(row)
      })
    }
  })

  const isPressureMetric = pressureUnitFromUserSetting === 'METRIC'
  const showPressureInMetric = showPressure && isPressureMetric
  const irrigationActivitySettings = getIrrigationActivitySettings({ pressureUnitFromUserSetting })

  const shapedDataForCharts = Object.entries(activityIntervals || {}).reduce(
    (acc: TShapedDataForChart[], [lngLat, intervals]) => {
      intervals.forEach((interval) => {
        const matchingPT = Object.values(points).find(
          (d) =>
            d.lngLat === lngLat &&
            emitterType &&
            d.configuration.irrigationEmitterTypesAvailable?.includes(emitterType),
        )

        if (!matchingPT) return

        const deviceName = matchingPT.name
        const intervalSeries = interval.timeseries

        let onDurationMinutes = 0

        const flowRate = interval.metadata.flowRate

        const intervalsForChart: TXRangeSeriesType[] =
          intervalSeries.map((s, index) => {
            if (s.status === 'on') {
              const periodMinutes = moment.tz(s.endTime, timezone).diff(s.startTime, 'minutes')

              onDurationMinutes += periodMinutes
            }

            return {
              key: index,
              x: +new Date(s.startTime),
              x2: s.endTime ? +new Date(s.endTime) : +new Date(),
              y: showPressureInMetric ? 300 : 50,
              color: irrigationActivitySettings[(s.status || 'noData') as EnumIrrigationActivityStatus].color,
              status: s.status,
              pointWidth: s.status === 'on' ? 24 : 14,
            }
          }) || []

        const totalWaterApplied = onDurationMinutes ? +((onDurationMinutes / 60) * flowRate) : 0

        const shapedData: TShapedDataForChart = {
          deviceName,
          onDurationMinutes,
          intervalsForDevice: interval,
          intervalsForChart,
          pressureData: null,
          totalWaterApplied,
        }

        if (showPressure) {
          pressureDataForPoints.forEach((p) => {
            shapedData.pressureData = p?.timeseries
              ? p.timeseries.map((d) => {
                  const average = d.value?.average

                  const value =
                    average && average % 1
                      ? convertPressureFromPSIToKPa({
                          desiredUnitIMPERIALxorMETRIC: pressureUnitFromUserSetting,
                          psiAmount: average,
                        })
                      : average

                  return { x: +new Date(d.timestamp), y: isNil(average) ? null : Number(value) }
                })
              : null
          })
        }

        acc.push(shapedData)
      })

      return acc
    },
    [],
  )

  const sortedChartData = shapedDataForCharts.sort(sortByKey('deviceName'))
  const pressureLabel = translate.phrases.banyanApp('Pressure')
  const irrigationActivityLabel = translate.phrases.banyanApp('Irrigation Activity')

  const psCharts = sortedChartData.map((chartRow) => {
    const {
      totalWaterApplied,
      intervalsForChart,
      deviceName,
      pressureData,
      onDurationMinutes,
      intervalsForDevice,
    } = chartRow

    const allChartSeries: {
      type: string
      name: string
      showInLegend: boolean
      data: TXRangeSeriesType[] | TSplineSeriesData[]
      enableMouseTracking?: boolean
      color?: string
      pointWidth?: number
      minPointLength?: number
      id: string
      marker?: {
        enabled: boolean
      }
    }[] = [
      {
        color: 'rgba(0, 0, 0, 0)',
        type: 'spline',
        name: 'invisible',
        showInLegend: false,
        data: blankSeries,
        enableMouseTracking: true,
        id: 'blank',
        marker: {
          enabled: false,
        },
      },
      {
        type: 'xrange',
        name: irrigationActivityLabel,
        showInLegend: false,
        minPointLength: 3,
        data: intervalsForChart,
        enableMouseTracking: true,
        id: 'irrigationIntervals',
      },
    ]

    if (pressureData)
      allChartSeries.push({
        color: irrigationActivitySettings.psi.color,
        type: 'spline',
        name: pressureLabel,
        showInLegend: false,
        data: pressureData,
        enableMouseTracking: true,
        id: 'pressure',
        marker: {
          enabled: false,
        },
      })

    const maxTickForYAxis = showPressureInMetric ? 360 : 60
    const tickIntervalForyAxis = showPressureInMetric ? 90 : 20

    const activityChart = {
      chartConfig: {
        semiosHighchartsAdditions: {
          id: `Pressure Sensors - ${deviceName}`,
          firstForecastTimestamp: +new Date(),
        },
        chart: {
          type: 'spline',
          height: 200,
        },
        tooltip: {
          xDateFormat: '%Z',
          formatter: function (this: TooltipFormatterContextObject) {
            const timestamp = this.x

            const { start, end, status } = getIntervalForCurrentStamp({
              currentTimeStamp: timestamp as number,
              activityIntervals: intervalsForDevice,
              timezone,
            })

            if (!start) return false

            const {
              color: statusColor,
              text: statusText,
              border: statusBorderColor,
            } = irrigationActivitySettings[(status || 'noData') as EnumIrrigationActivityStatus]

            if (!start) return false

            const intervalData: {
              startTime: string
              endTime: string | null
              status: string
            } = {
              startTime: start.toISOString(),
              endTime: end?.toISOString() || null,
              status,
            }

            // TODO: Finding by the series name is risky because it's a translated label. Refactor this to find by series id or index.
            const pressureRow = this?.points?.find((pData) => pData?.series.name === pressureLabel)

            const toolTipParams = {
              currentTimeStamp: translate.dates.format(
                moment.tz(timestamp, timezone),
                'ddd, MMM D, YYYY h:mm a (z)',
              ),
              sensorName: makeDeviceNameAndEmitterTypeLabel({
                deviceName,
                emitterType,
              }),
              appliedWater: getAppliedWater({
                flowRatePerHour: intervalsForDevice.metadata.flowRate,
                flowUnitPerHour: intervalsForDevice.metadata.flowUnitPerHour,
                intervalData,
                timezone,
                volumeUnitToDisplay,
              }),
              showPressure,
              pressureValue:
                status !== EnumIrrigationActivityStatus.noData && !isNil(pressureRow?.y)
                  ? `${pressureRow?.y} ${irrigationActivitySettings.psi.text}`
                  : translate.phrases.templates('-'),
              statusColor,
              statusText,
              statusBorderColor: statusBorderColor || statusColor,
              periodDurationText: end
                ? minutesToHoursAndMinutes(moment.tz(end, timezone).diff(start, 'minutes'))
                : translate.phrases.templates('-'),
              periodFromDateText: translate.dates.format(start, 'ddd, MMM D, YYYY h:mm a (z)'),
              periodToDateText: end
                ? translate.dates.format(end, 'ddd, MMM D, YYYY h:mm a (z)')
                : translate.phrases.templates('-'),
            }

            return getTooltipForPressureSensors(toolTipParams)
          },
        },
        yAxis: {
          min: 0,
          max: maxTickForYAxis,
          tickInterval: tickIntervalForyAxis,
          visible: showPressure,
          labels: {
            style: {
              fontSize: '14px',
            },
          },
        },
        plotOptions: {
          series: {
            groupPadding: 0,
            pointPadding: 0,
            states: {
              // don't fade out these series when hovering over the chart
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        series: allChartSeries,
      },

      childrenLower: (
        <PressureSensorsLegend
          showPressure={showPressure}
          pressureUnitFromUserSetting={pressureUnitFromUserSetting}
        />
      ),
      childrenUpper: (
        <div css={{ display: 'flex', padding: '20px 30px 0 30px', justifyContent: 'space-between' }}>
          <div css={{ fontWeight: 'bold' }}>{deviceName}</div>
          <div>
            <div>
              {translate.phrases.templates('{{label}}: {{value}}', {
                label: translate.phrases.banyanApp('On Duration'),
                value: minutesToHoursAndMinutes(onDurationMinutes),
              })}
            </div>
            <div>
              {translate.phrases.templates('{{label}}: {{value}}', {
                label: translate.phrases.banyanApp('Total Volume'),
                value: getWaterVolume({
                  waterApplied: totalWaterApplied,
                  volumeUnitToDisplay,
                  flowUnitPerHour: intervalsForDevice.metadata.flowUnitPerHour,
                }),
              })}
            </div>
          </div>
        </div>
      ),
    }

    return activityChart
  })

  return {
    title,
    titleChildren: [<DropdownSelectorIrrigationZone key={'irrigationZonesDropdown'} />],
    id: 'stackem-pressure-sensors-chart',
    items: psCharts,
  }
}
