import { PointerEvent, useCallback, useEffect, useState } from 'react'
import CssType from 'csstype'
import updateMovedPoint from './_utils/updateMovedPoint'
import handleBlurAndPointerUp from './_utils/handleBlurAndPointerUp'
import handlePointerMove from './_utils/handlePointerMove'
import { useDidUpdate } from '@mantine/hooks'
import { useCursorMaskDivIfNecessary } from './_utils/useCursorMaskDivIfNecessary'

export type MOVE_POINT_STATE = {
  tracking: 'X1' | 'X2' | 'Y1' | 'Y2' | null
  pointerType: 'mouse' | 'touch' | 'pen' | null
  downPoint: number | null
  point: number | null
  x1Start: number
  x2Start: number
  y1Start: number
  y2Start: number
  maxX1: number
  maxX2: number
  maxY1: number
  maxY2: number
  minX1: number
  minX2: number
  minY1: number
  minY2: number
  x1: number
  x2: number
  y1: number
  y2: number
  scrolledStart: number
  scrolled: number
}

export type MOVE_COORDS_NULLABLE = {
  x1?: MOVE_POINT_STATE['x1']
  x2?: MOVE_POINT_STATE['x2']
  y1?: MOVE_POINT_STATE['y1']
  y2?: MOVE_POINT_STATE['y2']
}

export type MOVE_COORDS = {
  x1: MOVE_POINT_STATE['x1']
  x2: MOVE_POINT_STATE['x2']
  y1: MOVE_POINT_STATE['y1']
  y2: MOVE_POINT_STATE['y2']
}

export type SIZES = {
  y1: number | ReadonlyArray<number> // TODO later figure out a nice way for Infinity / non snap values
  y2: number | ReadonlyArray<number> // TODO later figure out a nice way for Infinity / non snap values
  x1: number | ReadonlyArray<number> // TODO later figure out a nice way for Infinity / non snap values
  x2: number | ReadonlyArray<number> // TODO later figure out a nice way for Infinity / non snap values
}

export type SIZES_SNAPPING = {
  y1: boolean
  y2: boolean
  x1: boolean
  x2: boolean
}

export const getStuffFromSizes = (sizes: SIZES) => {
  const y1Sizes: ReadonlyArray<number> = Array.isArray(sizes.y1)
    ? [...sizes.y1].sort((a, b) => a - b)
    : [sizes.y1, sizes.y1, sizes.y1]

  const y2Sizes: ReadonlyArray<number> = Array.isArray(sizes.y2)
    ? [...sizes.y2].sort((a, b) => a - b)
    : [sizes.y2, sizes.y2, sizes.y2]

  const x1Sizes: ReadonlyArray<number> = Array.isArray(sizes.x1)
    ? [...sizes.x1].sort((a, b) => a - b)
    : [sizes.x1, sizes.x1, sizes.x1]

  const x2Sizes: ReadonlyArray<number> = Array.isArray(sizes.x2)
    ? [...sizes.x2].sort((a, b) => a - b)
    : [sizes.x2, sizes.x2, sizes.x2]

  const y1Min = y1Sizes[0]
  const y1Middles = y1Sizes.slice(1, y1Sizes.length - 1)
  const y1Max = y1Sizes[y1Sizes.length - 1]
  const y2Min = y2Sizes[0]
  const y2Middles = y2Sizes.slice(1, y2Sizes.length - 1)
  const y2Max = y2Sizes[y2Sizes.length - 1]
  const x1Min = x1Sizes[0]
  const x1Middles = x1Sizes.slice(1, x1Sizes.length - 1)
  const x1Max = x1Sizes[x1Sizes.length - 1]
  const x2Min = x2Sizes[0]
  const x2Middles = x2Sizes.slice(1, x2Sizes.length - 1)
  const x2Max = x2Sizes[x2Sizes.length - 1]

  return {
    y1Min,
    y1Middles,
    y1Max,
    y2Min,
    y2Middles,
    y2Max,
    x1Min,
    x1Middles,
    x1Max,
    x2Min,
    x2Middles,
    x2Max,
  }
}

export function useMoveTracker(options: {
  x1Start: number
  x2Start: number
  y1Start: number
  y2Start: number
  cursorX1?: CssType.Property.Cursor
  cursorX2?: CssType.Property.Cursor
  cursorY1?: CssType.Property.Cursor
  cursorY2?: CssType.Property.Cursor
  sizes: SIZES
  sizesSnapping: SIZES_SNAPPING
  onAfterResize?: (info: MOVE_COORDS) => void // TODO: Maybe not needed...
  allowDragContentForTouch?: boolean
}) {
  const stuff = getStuffFromSizes(options.sizes)
  const cursorX1 = options.cursorX1 ?? 'ew-resize'
  const cursorX2 = options.cursorX2 ?? 'ew-resize'
  const cursorY1 = options.cursorY1 ?? 'ns-resize'
  const cursorY2 = options.cursorY2 ?? 'ns-resize'
  const snapEndY1 = Array.isArray(options.sizes.y1) ? options.sizes.y1 : 1 // TODO default this to something?
  const snapEndY2 = Array.isArray(options.sizes.y2) ? options.sizes.y2 : 1 // TODO default this to something?
  const snapEndX1 = Array.isArray(options.sizes.x1) ? options.sizes.x1 : 1 // TODO default this to something?
  const snapEndX2 = Array.isArray(options.sizes.x2) ? options.sizes.x2 : 1 // TODO default this to something?
  const allowDragContentForTouch = options.allowDragContentForTouch ?? false

  const [state, setState] = useState<MOVE_POINT_STATE>({
    tracking: null,
    pointerType: null,
    downPoint: null,
    point: null,
    x1Start: options.x1Start,
    x2Start: options.x2Start,
    y1Start: options.y1Start,
    y2Start: options.y2Start,
    maxX1: stuff.x1Max,
    maxX2: stuff.x2Max,
    maxY1: stuff.y1Max,
    maxY2: stuff.y2Max,
    minX1: stuff.x1Min,
    minX2: stuff.x2Min,
    minY1: stuff.y1Min,
    minY2: stuff.y2Min,
    x1: options.x1Start,
    x2: options.x2Start,
    y1: options.y1Start,
    y2: options.y2Start,
    scrolledStart: 0,
    scrolled: 0,
  })

  const { tracking, pointerType, point, x1, x2, x1Start, x2Start, y1, y2, y1Start, y2Start, maxX2, maxY2 } =
    state

  useEffect(
    () => setState((s) => ({ ...s, x1Start: options.x1Start, x1: options.x1Start })),
    [options.x1Start],
  )

  useEffect(
    () => setState((s) => ({ ...s, x2Start: options.x2Start, x2: options.x2Start })),
    [options.x2Start],
  )

  useEffect(
    () => setState((s) => ({ ...s, y1Start: options.y1Start, y1: options.y1Start })),
    [options.y1Start],
  )

  useEffect(
    () => setState((s) => ({ ...s, y2Start: options.y2Start, y2: options.y2Start })),
    [options.y2Start],
  )

  useEffect(() => setState((s) => ({ ...s, maxX1: stuff.x1Max })), [stuff.x1Max])

  useEffect(() => setState((s) => ({ ...s, maxX2: stuff.x2Max })), [stuff.x2Max])

  useEffect(() => setState((s) => ({ ...s, maxY1: stuff.y1Max })), [stuff.y1Max])

  useEffect(() => setState((s) => ({ ...s, maxY2: stuff.y2Max })), [stuff.y2Max])

  useEffect(() => setState((s) => ({ ...s, minX1: stuff.x1Min })), [stuff.x1Min])

  useEffect(() => setState((s) => ({ ...s, minX2: stuff.x2Min })), [stuff.x2Min])

  useEffect(() => setState((s) => ({ ...s, minY1: stuff.y1Min })), [stuff.y1Min])

  useEffect(() => setState((s) => ({ ...s, minY2: stuff.y2Min })), [stuff.y2Min])

  useDidUpdate(() => {
    setState((s) => {
      // TODO: Need to consider x1 and y1 max/min?

      const newX2 = Math.min(s.x2, s.maxX2)
      const newY2 = Math.min(s.y2, s.maxY2)

      options.onAfterResize &&
        options.onAfterResize({
          x1: s.x1,
          x2: newX2,
          y1: s.y1,
          y2: newY2,
        })

      return {
        ...s,
        x2Start: newX2,
        x2: newX2,
        y2Start: newY2,
        y2: newY2,
      }
    })
  }, [maxX2, maxY2, options.onAfterResize])

  handlePointerMove(setState, tracking)

  useCursorMaskDivIfNecessary(pointerType, tracking, cursorX1, cursorX2, cursorY1, cursorY2)

  // TODO: can combine all the pointerdown handlers into one

  const onPointerDownX1 = useCallback(<T>(event: PointerEvent<T>) => {
    if (!event.isPrimary) return

    setState((s) => ({
      ...s,
      tracking: 'X1',
      pointerType: event.pointerType,
      downPoint: event.clientX,
    }))
  }, [])

  const onPointerDownX2 = useCallback(<T>(event: PointerEvent<T>) => {
    if (!event.isPrimary) return

    setState((s) => ({
      ...s,
      tracking: 'X2',
      pointerType: event.pointerType,
      downPoint: event.clientX,
    }))
  }, [])

  const onPointerDownY1 = useCallback(<T>(event: PointerEvent<T>) => {
    if (!event.isPrimary) return

    setState((s) => ({
      ...s,
      tracking: 'Y1',
      pointerType: event.pointerType,
      downPoint: event.clientY,
    }))
  }, [])

  const onPointerDownY2 = useCallback(<T>(event: PointerEvent<T>) => {
    if (!event.isPrimary) return

    setState((s) => ({
      ...s,
      tracking: 'Y2',
      pointerType: event.pointerType,
      downPoint: event.clientY,
    }))
  }, [])

  // TODO: needed?
  const move = useCallback((coords: MOVE_COORDS_NULLABLE) => {
    const update: MOVE_COORDS_NULLABLE & {
      x1Start?: MOVE_POINT_STATE['x1Start']
      x2Start?: MOVE_POINT_STATE['x2Start']
      y1Start?: MOVE_POINT_STATE['y1Start']
      y2Start?: MOVE_POINT_STATE['y2Start']
    } = {}

    if (coords.x1 !== null && coords.x1 !== undefined) {
      update.x1 = coords.x1

      update.x1Start = coords.x1
    }

    if (coords.x2 !== null && coords.x2 !== undefined) {
      update.x2 = coords.x2

      update.x2Start = coords.x2
    }

    if (coords.y1 !== null && coords.y1 !== undefined) {
      update.y1 = coords.y1

      update.y1Start = coords.y1
    }

    if (coords.y2 !== null && coords.y2 !== undefined) {
      update.y2 = coords.y2

      update.y2Start = coords.y2
    }

    setState((s) => ({
      ...s,
      ...update,
    }))
  }, [])

  // TODO: really only needed if allowDragContentForTouch = true
  const setScrolledStart = useCallback((n: number) => setState((s) => ({ ...s, scrolledStart: n })), [])

  updateMovedPoint(setState, point, x1Start, x2Start, y1Start, y2Start, allowDragContentForTouch)

  handleBlurAndPointerUp(
    setState,
    tracking,
    snapEndY1,
    snapEndY2,
    snapEndX1,
    snapEndX2,
    options.sizesSnapping,
    options.onAfterResize,
  )

  return {
    setScrolledStart,
    scrolled: state.scrolled,
    onPointerDownX1,
    onPointerDownX2,
    onPointerDownY1,
    onPointerDownY2,
    x1,
    x2,
    y1,
    y2,
    cursorX1,
    cursorX2,
    cursorY1,
    cursorY2,
    move,
    tracking,
    pointerType,
  }
}
