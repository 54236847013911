import { routes } from '@semios/app-platform-banyan-route-definitions'
import { css } from '@emotion/css'
import { LoadingOverlay } from '@mantine/core'
import { GridTable } from 'components/GridTable/GridTable'
import { translate } from 'i18n/i18n'
import { useLayoutEffect, useMemo, useState } from 'react'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { smallStore } from 'stores/smallStore'
import { mergeApiArgs } from '../_utils/mergeApiArgs'
import { PropertyNameHeader } from '../_utils/PropertyNameHeader'
import { useApiValuesTimeseries } from '../_utils/useApiValuesTimeseries/useApiValuesTimeseries'
import { useTimezoneForSelectedProperty } from '../_utils/useTimezoneForSelectedProperty'
import { DetailsPanelDateRange } from '../DetailsPanelDateRange/DetailsPanelDateRange'
import * as almondBloom from './_utils/by-domain/almondBloom/almondBloom'
import * as almondHullSplit from './_utils/by-domain/almondHullSplit/almondHullSplit'
import * as alternaria from './_utils/by-domain/alternaria/alternaria'
import * as beeHours from './_utils/by-domain/beeHours/beeHours'
import * as chill from './_utils/by-domain/chill/chill'
import * as conditions from './_utils/by-domain/conditions/conditions'
import * as dewPoint from './_utils/by-domain/dewPoint/dewPoint'
import * as evapotranspiration from './_utils/by-domain/evapotranspiration/evapotranspiration'
import * as fruitGrowth from './_utils/by-domain/fruitGrowth/fruitGrowth'
import * as plantStress from './_utils/by-domain/plantStress/plantStress'
import * as humidity from './_utils/by-domain/humidity/humidity'
import * as insectDegreeDays from './_utils/by-domain/insectDegreeDays/insectDegreeDays'
import * as insectTrapCatches from './_utils/by-domain/insectTrapCatches/insectTrapCatches'
import * as precipitation from './_utils/by-domain/precipitation/precipitation'
import * as pressureSensors from './_utils/by-domain/pressureSensors/pressureSensors'
import * as soil from './_utils/by-domain/soil/soil'
import * as sprayConditions from './_utils/by-domain/sprayConditions/sprayConditions'
import * as temperature from './_utils/by-domain/temperature/temperature'
import * as walnutBlight from './_utils/by-domain/walnutBlight/walnutBlight'
import * as wetBulb from './_utils/by-domain/wetBulb/wetBulb'
import * as wind from './_utils/by-domain/wind/wind'
import * as windmachine from './_utils/by-domain/windMachine/windMachine'
import { createColumns } from './_utils/createColumns'
import { createContent } from './_utils/createContent'
import { useScreenSize } from 'utils/useScreenSize'

export const SummaryGridTable = ({
  containerHeight,
  containerWidth,
  preventFetch,
}: {
  containerHeight: number
  containerWidth: number
  preventFetch: boolean
}) => {
  const [idForTodayColumn] = useState(`SummaryGrid-${Math.random()}`)
  const { isWideScreen } = useScreenSize()

  const { selectedValueGroups } = selectedValueGroupsStore.useSelector((s) => ({
    selectedValueGroups: s.selectedValueGroups,
  }))

  const selectedFieldAssets = selectedFieldAssetsStore.useSelector((s) => s)
  const timezone = useTimezoneForSelectedProperty()

  const { dateFrom, dateTo, detailsPanelStoreContents, detailsPanelStoreContentsThatShouldTriggerARefetch } =
    detailsPanelStore.useSelector((s) => ({
      dateFrom: s.dateFrom,
      dateTo: s.dateTo,
      detailsPanelStoreContents: s,
      detailsPanelStoreContentsThatShouldTriggerARefetch: {
        keyForRefreshing: s.keyForRefreshing,
        keyForRefreshingSummaryTab: s.keyForRefreshingSummaryTab,
      },
    }))

  const { appliedIrrigationActivityDisplay } = smallStore.useSelector((s) => ({
    appliedIrrigationActivityDisplay: s.appliedIrrigationActivityDisplay,
  }))

  const commonApiParams = {
    selectedValueGroups,
    selectedFieldAssets,
  }

  const mergedApiArgs = mergeApiArgs(
    almondBloom.apiArgs(commonApiParams),
    almondHullSplit.apiArgs(commonApiParams),
    alternaria.apiArgs(commonApiParams),
    beeHours.apiArgs(commonApiParams),
    chill.apiArgs(commonApiParams),
    conditions.apiArgs(commonApiParams),
    dewPoint.apiArgs(commonApiParams),
    evapotranspiration.apiArgs(commonApiParams),
    humidity.apiArgs(commonApiParams),
    insectDegreeDays.apiArgs(commonApiParams),
    insectTrapCatches.apiArgs(commonApiParams),
    precipitation.apiArgs(commonApiParams),
    pressureSensors.apiArgs(commonApiParams),
    soil.apiArgs(commonApiParams),
    sprayConditions.apiArgs(commonApiParams),
    temperature.apiArgs(commonApiParams),
    walnutBlight.apiArgs(commonApiParams),
    wetBulb.apiArgs(commonApiParams),
    wind.apiArgs(commonApiParams),
    fruitGrowth.apiArgs(commonApiParams),
    plantStress.apiArgs(commonApiParams),
    windmachine.apiArgs(commonApiParams),
  )

  const { data, loading, updateData } = useApiValuesTimeseries({
    args: { dateFrom, dateTo, ...mergedApiArgs },
    preventFetch,
    watchers: [
      JSON.stringify(detailsPanelStoreContentsThatShouldTriggerARefetch),
      JSON.stringify(mergedApiArgs),
    ],
  })

  const content = useMemo(
    () =>
      createContent({
        data: data as routes.Values.Response,
        selectedValueGroups,
        selectedFieldAssets,
        updateData,
        isWideScreen,
      }),
    [
      data,
      selectedFieldAssets,
      selectedValueGroups,
      JSON.stringify(detailsPanelStoreContents),
      appliedIrrigationActivityDisplay,
    ],
  )

  const columns = useMemo(
    () => createColumns({ content, dateFrom, dateTo, idForTodayColumn, timezone }),
    [content, dateFrom, dateTo, idForTodayColumn, timezone],
  )

  useLayoutEffect(() => {
    const todayColumn = document.getElementById(idForTodayColumn)

    if (todayColumn) {
      // scroll it into view, inline: center keeps it centred in the div
      todayColumn?.scrollIntoView?.({ behavior: 'smooth', inline: 'center' })
    }
  }, [columns])

  return (
    <div
      className={css({
        width: containerWidth,
        height: containerHeight,
        maxHeight: containerHeight,
        overflow: 'auto',
        touchAction: 'pan-x pan-y', //disable pinch to zoom to avoid mobile view bug with the map
      })}
    >
      <LoadingOverlay visible={loading} />
      <GridTable
        childrenUpper={
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <PropertyNameHeader />
            <DetailsPanelDateRange />
          </div>
        }
        columns={columns}
        containerWidth={containerWidth}
        content={content}
        key={selectedFieldAssets.property}
        topLeftCell={{ title: translate.phrases.banyanApp('Scroll horizontally to see more dates') }}
      />
    </div>
  )
}
