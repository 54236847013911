import { BadgeCell } from 'App/Map/PanelDetails/SummaryGridTable/BadgeCell/BadgeCell'
import { ESprayRecommendation } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { alphaColorReplacer, colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import {
  TPossibleEnvironmentalMeasurementType,
  TSprayConditionsRulesForARegion,
} from '../SprayConditionsTitleChildren'

const measurementHelper = (measurementString: TPossibleEnvironmentalMeasurementType) => {
  if (measurementString === 'humidityAverage')
    return {
      name: translate.phrases.banyanApp('Humidity'),
      unitConverterFunction: unitConverter.humidityInCanopy,
    }

  if (measurementString === 'precipitation')
    return { name: translate.phrases.banyanApp('Rain'), unitConverterFunction: unitConverter.precipitation }

  if (measurementString === 'temperatureAverage')
    return {
      name: translate.phrases.banyanApp('Temperature'),
      unitConverterFunction: unitConverter.temperatureInCanopy,
    }

  if (measurementString === 'windSpeedAverage')
    return { name: translate.phrases.banyanApp('Wind Speed'), unitConverterFunction: unitConverter.windSpeed }

  return { name: translate.phrases.banyanApp('Unknown'), unitConverterFunction: null }
}

export const SprayConditionRow = (props: {
  backgroundColor: TRGBAColorWith1AtTheEnd
  rules: TSprayConditionsRulesForARegion[ESprayRecommendation]
  title: string
}) => {
  return (
    <>
      <BadgeCell backgroundColor={props.backgroundColor}>{props.title}</BadgeCell>
      <table cellPadding={6} cellSpacing={0} css={{ margin: '6px 12px 0px 12px' }}>
        <thead>
          <tr css={{ th: { textAlign: 'left', fontWeight: 'bold' } }}>
            <th>{translate.phrases.banyanApp('Criteria')}</th>
            <th>{translate.phrases.banyanApp('Rule')}</th>
          </tr>
        </thead>
        <tbody css={{ fontWeight: 'normal' }}>
          {Object.entries(props.rules)
            .filter(
              ([, rulesForMeasurement]) => !rulesForMeasurement.some((r) => r.min === null && r.max === null),
            )
            .map(([measurement, rulesForMeasurement], i) => {
              const { name, unitConverterFunction } = measurementHelper(
                measurement as TPossibleEnvironmentalMeasurementType,
              )

              return (
                <tr
                  css={{ background: i % 2 === 0 ? alphaColorReplacer(colors.grey200, 0.5) : 'unset' }}
                  key={measurement}
                >
                  <td css={{ width: 130 }}>{name}</td>
                  <td>
                    {rulesForMeasurement.map(({ min, max }, ii) => {
                      let innerText = ''

                      // TODO: might look nicer to have the {{minValue}} and the {{maxValue}} wrapped in a <T /> to make the values bold
                      if (max === null) {
                        innerText = translate.phrases.banyanApp('Above {{value}}', {
                          value: unitConverterFunction?.(min).valueWithSuffix() ?? String(min),
                        })
                      } else if (min === null) {
                        innerText = translate.phrases.banyanApp('Below {{value}}', {
                          value: unitConverterFunction?.(max).valueWithSuffix() ?? String(max),
                        })
                      } else
                        innerText = translate.phrases.banyanApp('Between {{valueA}} and {{valueB}}', {
                          valueA: unitConverterFunction?.(min).valueWithSuffix() ?? String(min),
                          valueB: unitConverterFunction?.(max).valueWithSuffix() ?? String(max),
                        })

                      return (
                        <p css={{ margin: 0, padding: 0 }} key={ii}>
                          {innerText}
                        </p>
                      )
                    })}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </>
  )
}
