import { Chart, Point } from 'highcharts'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'

const calculateVisibleSum = (chart: Chart, firstForecastTimestamp: number) => {
  const forecastTimestamp = firstForecastTimestamp || +new Date()
  const xAxisMin: number = chart.xAxis[0].min || 0
  const xAxisMax: number = chart.xAxis[0].max || 0

  return chart.series.map((s) => {
    const filteredPoints = s.points.reduce<Record<number, Point>>((acc, point) => {
      if (!acc[point.x]) {
        acc[point.x] = point
      }

      return acc
    }, {})

    let sum = 0
    let forecastedSum = 0

    Object.values(filteredPoints).forEach(function (p) {
      if (p.x >= xAxisMin && p.x <= xAxisMax && !isNil(p.y)) {
        if (p.x < forecastTimestamp) {
          sum += p.y
        } else {
          forecastedSum += p.y
        }
      }
    })

    return {
      sum: sum === 0 ? null : sum,
      forecastedSum: forecastedSum === 0 ? null : forecastedSum,
    }
  })
}

export const renderETCaption = (chart: Chart, firstForecastTimestamp: number) => {
  const visualSums = calculateVisibleSum(chart, firstForecastTimestamp)
  const etoSum = visualSums?.[0]?.sum
  const etcSum = visualSums?.[1]?.sum
  const etoForecastedSum = visualSums?.[0]?.forecastedSum
  const etcForecastedSum = visualSums?.[1]?.forecastedSum
  // et values have already been converted to the correct unit
  const inputAndOutputUnit = userDetailsStore.getState().rain === 'IMPERIAL' ? 'in' : 'mm'

  const evapotranspirationConverter = (sum: number | null): string => {
    return unitConverter
      .evapotranspiration(sum, {
        inputUnit: inputAndOutputUnit,
        outputUnit: inputAndOutputUnit,
      })
      .valueWithSuffix()
  }

  const etoSumString = translate.phrases.templates('{{label}}: {{value}}', {
    label: translate.phrases.banyanApp('Historic'),
    value: evapotranspirationConverter(etoSum),
  })

  const etcSumString = translate.phrases.templates('{{label}}: {{value}}', {
    label: translate.phrases.banyanApp('Historic'),
    value: evapotranspirationConverter(etcSum),
  })

  const forecastedEtoSumString = translate.phrases.templates('{{label}}: {{value}}', {
    label: translate.phrases.banyanApp('Forecasted'),
    value: evapotranspirationConverter(etoForecastedSum),
  })

  const forecastedEtcSumString = translate.phrases.templates('{{label}}: {{value}}', {
    label: translate.phrases.banyanApp('Forecasted'),
    value: evapotranspirationConverter(etcForecastedSum),
  })

  const valueNamesTemplate =
    etoSum || etoForecastedSum
      ? `<div style="margin-right: 5px; font-weight: bold;">
      <div>${translate.phrases.unitSymbols('ETo')}</div>
      ${etcSum || etcForecastedSum ? `<div>${translate.phrases.unitSymbols('ETc')}</div>` : ''}
      </div>`
      : ''

  const pastValuesTemplate = etoSum
    ? `
    <div>
      <div>${etoSumString}</div>
      <div>${etcSum ? etcSumString : ''}</div>
    </div>
  `
    : ''

  const forecastedEtoDelimiter =
    etoSum && etoForecastedSum
      ? `<div style="margin-right: 7px; margin-left: 7px;"><div>|</div>${
          etcForecastedSum ? '<div>|</div>' : ''
        }</div>`
      : ''

  const forecastedValuesTemplate = etoForecastedSum
    ? `
    <div>
      <div>${forecastedEtoSumString}</div>
      <div>${etcForecastedSum ? forecastedEtcSumString : ''}</div>
    </div>
  `
    : ''

  const template =
    !etoSum && !etoForecastedSum
      ? ''
      : `<div style="display: flex;">${valueNamesTemplate}${pastValuesTemplate}${forecastedEtoDelimiter}${forecastedValuesTemplate}</div>`

  chart.setCaption({ text: template })
}
