import { LoadingOverlay, Table } from '@mantine/core'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { ErrorMessage } from 'App/Map/UserSettingsMenu/Shared/ErrorMessage/ErrorMessage'
import { useErrorMessage } from 'App/Map/UserSettingsMenu/Shared/ErrorMessage/useErrorMessage'
import {
  TBlockETData,
  TKcOverrideType,
  TSelectedKcConfigurationMap,
} from 'App/Map/UserSettingsMenu/Shared/EvapotranspirationSettings/types'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { getCropNameFromId } from 'utils/getCropNameFromId'
import { useApiREST } from 'utils/useApiREST'
import { Rows } from './Rows/Rows'

export const KcConfigurationTable = ({
  dirtyBlockIds,
  setDirtyBlockIds,
  selectedValueMap,
  setSelectedValueMap,
  setInitialBlockSettings,
}: {
  closeConfirmationModal: () => void
  closeModal: () => void
  confirmationModalIsOpen: boolean
  dirtyBlockIds: number[]
  setDirtyBlockIds: Dispatch<SetStateAction<number[]>>
  selectedValueMap: TSelectedKcConfigurationMap
  setSelectedValueMap: Dispatch<SetStateAction<TSelectedKcConfigurationMap>>
  setInitialBlockSettings: Dispatch<SetStateAction<TSelectedKcConfigurationMap>>
}) => {
  const { showErrorMessage, triggerErrorMessage, onCloseButtonPress } = useErrorMessage()

  const { propertyId } = selectedFieldAssetsStore.useSelector((s) => ({
    propertyId: s.property,
  }))

  const property = fieldAssetStore.useSelector((s) => s?.properties?.[Number(propertyId)])

  const columns = [
    {
      title: '',
      key: 'blockName',
    },
    {
      title: translate.phrases.banyanApp('Kc Model'),
      key: 'kcModelName',
    },
    {
      title: translate.phrases.banyanApp('Kc Multiplier'),
      key: 'kcMultiplier',
    },
  ]

  const {
    data: dataForAllBlocks,
    loading,
    error,
  } = useApiREST<routes.FieldAssetSettingsGet.Request, routes.FieldAssetSettingsGet.Response, TBlockETData[]>(
    {
      url: routes.FieldAssetSettingsGet.path,
      body: {
        kcBlockSettings: {
          dateFrom: moment.tz('utc').toISOString().split('T')[0],
          dateTo: moment.tz('utc').toISOString().split('T')[0],
          propertyIds: propertyId ? [propertyId] : [],
          blockIds: [],
        },
      },
      preventFetch: !propertyId || !property,
      initialState: [],
      shaper: (data) => {
        if (!Array.isArray(data?.kcBlockSettings)) return []

        const allBlocksData = (data?.kcBlockSettings ?? [])
          .map((block) => {
            const { blockId } = block

            if (!property?.blocks?.[blockId]) return null

            const blockName = property.blocks[blockId].name
            const cropId = property?.blocks?.[blockId]?.cropIds?.[0]
            const cropName = getCropNameFromId(cropId)

            return {
              ...block,
              blockName,
              cropName,
            }
          })
          .filter(Boolean) as TBlockETData[]

        const initialValues = (allBlocksData ?? []).reduce<TSelectedKcConfigurationMap>((acc, curr) => {
          const { modelType, modelId, kcMultiplier } = curr.currentBlockSettings

          return {
            ...acc,
            [curr.blockId]: {
              modelType: modelType as TKcOverrideType,
              modelId,
              kcMultiplier,
            },
          }
        }, {})

        setInitialBlockSettings(initialValues)

        setSelectedValueMap(initialValues)

        return allBlocksData
      },
      watchers: [Number(propertyId)],
    },
  )

  useEffect(() => {
    if (error) {
      const errorMessage = translate.phrases.banyanApp(
        'There was an error retrieving the Kc Model data for this property',
      )

      triggerErrorMessage(errorMessage)
    }
  }, [error])

  if (loading) {
    return <LoadingOverlay visible={true} />
  }

  if (error) {
    return (
      <ErrorMessage
        message={showErrorMessage}
        onCloseButtonPress={onCloseButtonPress}
        showErrorMessage={!!showErrorMessage}
      />
    )
  }

  return (
    <>
      <Table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key}>{column.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <Rows
            data={dataForAllBlocks}
            dirtyBlockIds={dirtyBlockIds}
            setDirtyBlockIds={setDirtyBlockIds}
            selectedValueMap={selectedValueMap}
            setSelectedValueMap={setSelectedValueMap}
          />
        </tbody>
      </Table>
    </>
  )
}
