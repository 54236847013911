import React from 'react'
import {
  serviceCenterStore,
  TEquipmentType,
  TNodeDeviceType,
} from '../../../../ServiceCenter/store/serviceCenterStore'
import { EquipmentTypesAndDevicesMultiSelect } from '../../../../ServiceCenter/EquipmentTypesAndDevicesMultiSelect/EquipmentTypesAndDevicesMultiSelect'
import { useScreenSize } from '../../../../../utils/useScreenSize'

export const EquipmentStatusSecondaryDropdown = () => {
  const { isWideScreen } = useScreenSize()

  const selectedEquipmentTypes = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getMapEquipmentTypes,
  )

  const selectedEquipmentDevices = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getMapEquipmentDevices,
  )

  const handleSelectionChange = (
    selectedEquipmentTypes: TEquipmentType[],
    selectedEquipmentDevices: TNodeDeviceType[],
  ) => {
    serviceCenterStore.actions.setMapEquipmentTypes(selectedEquipmentTypes)

    serviceCenterStore.actions.setMapEquipmentDevices(selectedEquipmentDevices)
  }

  return (
    <EquipmentTypesAndDevicesMultiSelect
      selectedEquipmentTypes={selectedEquipmentTypes}
      selectedEquipmentDevices={selectedEquipmentDevices}
      dropdownPosition={isWideScreen ? 'bottom' : 'top'}
      onChange={handleSelectionChange}
    />
  )
}
