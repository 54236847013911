import { PreUploadImageMeta } from '../serviceCenterImagePreUpload'
import { UploadImageMeta } from '../serviceCenterImageUpload'
import { NodeDeviceInstallMeta } from '../serviceCenterNodeDeviceInstall'
import { AddNodeImagesMeta } from '../serviceCenterNodeImagesAdd'
import { PlannedNodeInstallMeta } from '../serviceCenterPlannedNodeInstall'
import { PlannedGatewayRepositionMeta } from '../serviceCenterPlannedGatewayReposition'
import { PlannedNodesRepositionMeta } from '../serviceCenterPlannedNodesReposition'
import { NodeDropInstallMeta } from '../serviceCenterNodeDropInstall'
import { NodeAttachDeviceMeta } from '../serviceCenterNodeAttachDevices'

type ApiRequestMeta =
  | PreUploadImageMeta
  | UploadImageMeta
  | NodeDeviceInstallMeta
  | AddNodeImagesMeta
  | PlannedNodeInstallMeta
  | PlannedNodesRepositionMeta
  | PlannedGatewayRepositionMeta
  | NodeDropInstallMeta
  | NodeAttachDeviceMeta

export interface TApiRequest {
  // Unique identifier for the request
  id: string
  // Hash of the request meta (used to identify duplicate requests)
  hash: string
  type: ApiRequestType
  status: ApiRequestStatus
  createdAt: string
  meta: ApiRequestMeta
  preRequestError?: string | boolean
  error?: Error
  retryCount?: number
  lastRetryAt?: string
  nextRetryAt?: string | null
}

export type MetaWithNodeIdentifier = {
  nodeIdentifier: string
}

export type MetaWithImageUrls = {
  // Total number of images to upload
  imagesCount: number
  // Uploaded image urls
  imageUrls: string[]
}

export enum ApiRequestType {
  ATTACH_NODE_DEVICES = 'ATTACH_NODE_DEVICES',
  DROP_INSTALL_NODE = 'DROP_INSTALL_NODE',
  INSTALL_NODE = 'INSTALL_NODE',
  INSTALL_NODE_DEVICE = 'INSTALL_NODE_DEVICE',
  PRE_UPLOAD_IMAGE = 'PRE_UPLOAD_IMAGE',
  UPLOAD_IMAGE = 'UPLOAD_IMAGE',
  ADD_NODE_IMAGES = 'ADD_NODE_IMAGES',
  REPOSITION_PSEUDO_NODES = 'REPOSITION_PSEUDO_NODES',
  REPOSITION_PSEUDO_GATEWAY = 'REPOSITION_PSEUDO_GATEWAY',
}

export enum ApiRequestStatus {
  PARTIAL = 'PARTIAL', // Missing information (eg. image urls)
  PENDING = 'PENDING', // Waiting to be processed
  PROCESSING = 'PROCESSING', // Being processed
  COMPLETED = 'COMPLETED', // Successfully processed
  ERROR = 'ERROR', // Failed request
}
