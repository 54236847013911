import { Point } from '@turf/helpers'
import { transformCoordsToLatLng } from 'App/ServiceCenter/Map/_utils/transformCoordsToLatLng'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { IconWarning } from 'components/icons/IconWarning'
import { MapCard } from 'components/MapCard/MapCard'
import React, { useMemo } from 'react'
import { translate } from 'i18n/i18n'
import { Alert, Badge, useMantineTheme } from '@mantine/core'
import { Button } from 'components/Button/Button'
import { getPlannedDevices } from '../../utils/flatNodeDevices'
import { installationWorkflowStore } from '../../store/installationWorkflowStore'
import { DeviceList } from '../DeviceList/DeviceList'
import { openNodeInstallationOverviewModal } from '../../NodeInstallation/InstallationOverviewModal'
import { TActiveGateway, TActiveNode, TDnNodeLog, TLnNodeLog, TNodeStatus, TSnNodeLog } from '../../types'
import { apiRequestQueueStore } from '../../utils/api/queue/apiRequestQueueStore'
import { getIdentifier } from '../../utils/getIdentifier'
import moment from 'moment-timezone'
import {
  getActiveNodeStatus,
  getNodeStatusLabel,
  NODE_STATUS_COLORS,
} from '../../Map/_utils/getActiveNodeStatus'
import { getNodeNetworkType } from '../../utils/getNodeNetworkType'
import { SnNodeLog } from './log/SnNodeLog'
import { DnNodeLog } from './log/DnNodeLog'
import { LnNodeLog } from './log/LnNodeLog'
import { useTimezoneForSelectedProperty } from '../../../Map/PanelDetails/_utils/useTimezoneForSelectedProperty'
import { colors } from '../../../../settings/colors'
import { IconErrorCircle } from 'components/icons/IconErrorCircle'
import { getEquipmentTypeLabel } from '../../Map/_utils/equipmentTypes'
import { getNodeEquipmentType } from '../../Map/_utils/getNodeEquipmentType'
import { DirectionButton } from '../DirectionButton/DirectionButton'
import { openActionMenu } from '../../ActionMenu/ActionMenu'
import { getNodeActions } from '../../utils/getNodeActions'
import { getServiceIssueTitle } from '../../utils/getServiceIssueTitle'
import { getNodeTypeLabel } from '../../utils/getNodeTypeLabel'
import { openNodeMaintenanceModal } from '../../NodeMaintenanceModal/NodeMaintenanceModal'
import { IconRightArrowCircle } from '../../../../components/icons/IconRightArrowCircle'
import { FailedRequestError } from '../FailedRequestError/FailedRequestError'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { Authorization } from '../../../../components/Authorization/Authorization'
import { useScreenSize } from 'utils/useScreenSize'
import { layersNavigationMenuStore } from 'stores/layersNavigationMenuStore'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'EDIT_SSC_INSTALL_PLANNED' })

interface ActiveNodeDetailProps {
  node: TActiveNode | TActiveGateway
  onClose: () => void
}

export const ActiveNodeDetail: React.FC<ActiveNodeDetailProps> = ({ node, onClose }) => {
  const theme = useMantineTheme()
  const latLng = useMemo(() => transformCoordsToLatLng(JSON.parse(node.location) as Point), [node.location])
  const hasPlannedDevices = useMemo(() => !!getPlannedDevices(node.devices).length, [node])
  const timezone = useTimezoneForSelectedProperty()
  const allFailedRequests = apiRequestQueueStore.useSelector(apiRequestQueueStore.selectors.getFailedRequests)
  const lastLog = serviceCenterStore.useSelector(serviceCenterStore.selectors.getSelectedEquipmentLastLog)
  const { isWideScreen } = useScreenSize()
  const menuCollapsed = layersNavigationMenuStore.useSelector((s) => s.menuCollapsed)

  const serviceIssues = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentServiceIssues,
  )

  const nodeFailedRequests = useMemo(
    () =>
      allFailedRequests.filter((req) => {
        if ('nodeIdentifier' in req.meta) {
          return req.meta.nodeIdentifier === getIdentifier(node)
        } else {
          return false
        }
      }),
    [allFailedRequests, node],
  )

  const unsyncedNodeIdentifiers = apiRequestQueueStore.useSelector(
    apiRequestQueueStore.selectors.getUnsyncedNodeIdentifiers,
  )

  const isUnsynced = useMemo(
    () => unsyncedNodeIdentifiers.includes(getIdentifier(node)),
    [unsyncedNodeIdentifiers, node],
  )

  const handleStartInstallation = () => {
    installationWorkflowStore.actions.start()

    openNodeInstallationOverviewModal()
  }

  const lastLogText = lastLog
    ? translate.dates.format(moment.tz(lastLog.stamp, 'UTC').tz(timezone), 'MMM D, YYYY h:mm a (z)')
    : translate.phrases.placeholder('No log received')

  let nodeStatus = getActiveNodeStatus(lastLog)

  if (serviceIssues.length) nodeStatus = TNodeStatus.ERROR

  const nodeStatusText = getNodeStatusLabel(nodeStatus)
  const nodeStatusColor = NODE_STATUS_COLORS[nodeStatus]
  const networkType = getNodeNetworkType(node)
  const nodeType = (node as TActiveNode).nodeType || (node as TActiveGateway).gatewayType
  const nodeTypeLabel = getNodeTypeLabel(nodeType)
  const equipmentType = !!node && getNodeEquipmentType(nodeType)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  let title = node.name!

  if (!node.name) {
    title = translate.phrases.placeholder('{{equipmentType}} - {{nodeId}}', {
      equipmentType: equipmentType
        ? getEquipmentTypeLabel(equipmentType)
        : translate.phrases.placeholder('Node'),
      nodeId: (node as TActiveNode).nodeIdentifier || (node as TActiveGateway).gatewayIdentifier,
    })
  }

  const handleOpenActionMenu = () => {
    const actions = getNodeActions(node)

    openActionMenu(actions)
  }

  const handleServiceIssueClick = () => {
    if (serviceIssues.length === 1) {
      openNodeMaintenanceModal(node as TActiveNode, serviceIssues[0].serviceIssueId)
    } else {
      openNodeMaintenanceModal(node as TActiveNode)
    }
  }

  return (
    <MapCard
      title={title}
      onClose={onClose}
      latLng={latLng}
      cardCSS={menuCollapsed && !isWideScreen ? { zIndex: 10000 } : undefined}
      footer={
        <div css={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
            }}
          >
            <Button variant="tertiary" size="lg" css={{ flex: '1 1 50%' }} onClick={handleOpenActionMenu}>
              {translate.phrases.placeholder('More Options')}
            </Button>

            {hasPlannedDevices && (
              <Button
                variant="primary"
                disabled={!checkPermission()}
                css={{ marginLeft: 10, flex: '1 1 50%' }}
                onClick={() => handleStartInstallation()}
              >
                {translate.phrases.placeholder('Install')}
              </Button>
            )}
          </div>
          <DirectionButton latLng={latLng} />
        </div>
      }
    >
      <div>
        {!!serviceIssues.length && (
          <Alert
            icon={
              <span css={{ color: colors.red }}>
                <IconErrorCircle />
              </span>
            }
            variant="light"
            styles={{
              root: {
                backgroundColor: theme.colors.gray[0],
                boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
                cursor: 'pointer',
              },
              wrapper: { alignItems: 'center' },
              message: {
                fontSize: 16,
              },
            }}
          >
            <Button
              variant="link"
              fullWidth={true}
              rightIcon={
                <div css={{ fontSize: '31px', color: colors.primary, height: 32, lineHeight: '32px' }}>
                  <IconRightArrowCircle />
                </div>
              }
              styles={{
                root: {
                  padding: 0,
                },
                inner: {
                  justifyContent: 'space-between',
                  flex: '1 1 auto',
                },
                label: {
                  flex: '1 1 auto',
                },
              }}
              onClick={handleServiceIssueClick}
            >
              <div>
                {serviceIssues.length === 1
                  ? getServiceIssueTitle(node as TActiveNode, serviceIssues[0])
                  : translate.phrases.placeholder('{{equipmentType}} - Multiple issues ({{count}})', {
                      equipmentType: nodeTypeLabel,
                      count: serviceIssues.length.toString(),
                    })}
              </div>
            </Button>
          </Alert>
        )}

        {isUnsynced && (
          <Alert
            icon={
              <span css={{ color: theme.colors.yellow[0] }}>
                <IconWarning />
              </span>
            }
            variant="light"
            styles={{
              root: {
                backgroundColor: 'rgb(255, 249, 219)',
                boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
              },
              wrapper: { alignItems: 'center' },
              message: { fontSize: 16 },
            }}
          >
            {translate.phrases.placeholder('This node has unsynced changes')}
          </Alert>
        )}

        {nodeFailedRequests?.map((request) => (
          <FailedRequestError key={request.id} request={request} />
        ))}

        <div css={{ padding: 20 }}>
          <div
            css={{
              margin: '0 0 10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Authorization requires={{ permission: 'VIEW_SSC_NODE_LAST_LOG', entity: 'FOR_ANY_ENTITY' }}>
              <span css={{ color: theme.colors.grey[3], fontSize: 12 }}>{lastLogText}</span>
            </Authorization>
            <Badge
              css={{
                color: theme.colors.midnight,
                backgroundColor: nodeStatusColor,
                fontSize: '12px',
                fontWeight: 500,
                textTransform: 'none',
              }}
            >
              {nodeStatusText}
            </Badge>
          </div>

          <Authorization requires={{ permission: 'VIEW_SSC_NODE_LAST_LOG', entity: 'FOR_ANY_ENTITY' }}>
            <>
              {!!lastLog && networkType === 'SN' && (
                <SnNodeLog
                  log={lastLog as TSnNodeLog}
                  nodeType={(node as TActiveNode).nodeType || (node as TActiveGateway).gatewayType}
                />
              )}

              {!!lastLog && networkType === 'DN' && (
                <DnNodeLog log={lastLog as TDnNodeLog} nodeType={(node as TActiveNode).nodeType} />
              )}

              {!!lastLog && networkType === 'LN' && (
                <LnNodeLog log={lastLog as TLnNodeLog} nodeType={(node as TActiveNode).nodeType} />
              )}
            </>
          </Authorization>

          {!!node.devices && (
            <>
              <h4 css={{ marginBottom: 10, fontWeight: 500 }}>{translate.phrases.placeholder('Devices')}</h4>
              <DeviceList nodeDevices={node.devices} />
            </>
          )}
        </div>
      </div>
    </MapCard>
  )
}
