import React, { useCallback, useEffect, useState } from 'react'
import { ActionIcon, Grid, TextInput, useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { sortByKey } from '@semios/app-platform-common'
import { debounce } from 'lodash'
import { EquipmentTypesAndDevicesMultiSelect } from '../../../EquipmentTypesAndDevicesMultiSelect/EquipmentTypesAndDevicesMultiSelect'
import { PropertySelect } from 'components/PropertySelect/PropertySelect'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { IconSearch } from 'components/icons/IconSearch'
import { useScreenSize } from 'utils/useScreenSize'
import { EquipmentStatusMultiSelect } from '../../../EquipmentStatusMultiSelect/EquipmentStatusMultiSelect'
import { Filters as TFilters } from '../../../store/equipmentStatusPanelStore'

interface FiltersProps {
  selectedPropertyId: number | null
  filters: TFilters
  onFilterChange: (filters: Partial<TFilters>) => void
  onPropertySelect: (propertyId: number) => void
}

export const Filters: React.FC<FiltersProps> = ({
  selectedPropertyId,
  filters,
  onFilterChange,
  onPropertySelect,
}) => {
  const theme = useMantineTheme()
  const { isWideScreen } = useScreenSize()
  const properties = fieldAssetStore.useSelector((s) => s?.properties ?? {})
  // Store input value in local state because of the debounced search
  const [searchInputValue, setSearchInputValue] = useState(filters.searchKeywords)

  const propertiesData = Object.entries(properties)
    .map(([propertyId, property]) => ({
      label: property.propertyName,
      value: propertyId,
    }))
    .sort(sortByKey('label'))

  const debouncedSearch = useCallback(
    debounce((searchKeywords: string) => onFilterChange({ searchKeywords }), 300),
    [onFilterChange],
  )

  // Update local value when filters change
  useEffect(() => {
    setSearchInputValue(filters.searchKeywords)
  }, [filters.searchKeywords])

  // Trigger search when input value changes
  useEffect(() => {
    debouncedSearch(searchInputValue)
  }, [searchInputValue, debouncedSearch])

  return (
    <div css={{ backgroundColor: theme.colors.grey[0], padding: 12, flex: '0 0 auto' }}>
      <Grid>
        <Grid.Col span={isWideScreen ? 8 : 6}>
          <PropertySelect
            clearable={false}
            data={propertiesData}
            onChange={onPropertySelect}
            selectedPropertyId={selectedPropertyId}
          />
        </Grid.Col>
        <Grid.Col span={isWideScreen ? 4 : 6}>
          <TextInput
            placeholder={translate.phrases.placeholder('Search name')}
            value={searchInputValue}
            onChange={(event) => setSearchInputValue(event.currentTarget.value)}
            rightSection={
              <ActionIcon>
                <IconSearch />
              </ActionIcon>
            }
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={isWideScreen ? 8 : 6}>
          <EquipmentTypesAndDevicesMultiSelect
            selectedEquipmentTypes={filters.equipmentTypes}
            selectedEquipmentDevices={filters.deviceTypes}
            onChange={(equipmentTypes, deviceTypes) => onFilterChange({ equipmentTypes, deviceTypes })}
          />
        </Grid.Col>
        <Grid.Col span={isWideScreen ? 4 : 6}>
          <EquipmentStatusMultiSelect
            selectedStatuses={filters.statuses}
            onChange={(statuses) => onFilterChange({ statuses })}
          />
        </Grid.Col>
      </Grid>
    </div>
  )
}
