import { NumberInput } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { AlertsSettings, Rule, unitTempDisplayDD } from '../../../../settings/alertSettings'
import { alertRulesObjectFromArray } from '../../_utils/alertRulesObjectFromArray'
import { AlertsValidators, isNumberOrNumericString } from '../../../../AlertValidators/AlertValidators'
import { validateStatusErrorHelper } from '../../../../../_utils/validateStatusErrorHelper'
import { helpTextErrorHelper } from '../../_utils/helpTextErrorHelper'
import { updateAlertRules } from '../../_utils/updateAlertRules'
import { TranslatePhraseWrapper } from '../../../../components/TranslatePhraseWrapper'
import moment from 'moment-timezone'
import { fadeInOutStylesMaker } from 'utils/fadeInOutStylesMaker'
import { DatePickerInput } from '@mantine/dates'
import { CodlingMothGuide } from './CodlingMothGuide/CodlingMothGuide'
import { InfoPopover } from '../../../../components/InfoPopover'
import { getErrorStyle } from '../../../../_utils/getErrorStyle'
import { ErrorTextWrapper } from '../../../../components/ErrorTextWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from 'settings/colors'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { Radio } from 'components/Radio/Radio'
import { TranslateComponentWrapper } from '../../../../components/TranslateComponentWrapper'

const ROW_GAP = 20

export const DegreeDaysThreshold = ({
  insectId,
  rules,
  setRules,
  setThresholdIsValid,
  triedToSubmit,
}: {
  insectId: number | null
  rules: Rule[]
  setRules: Dispatch<SetStateAction<Rule[]>>
  setThresholdIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  useEffect(() => {
    if (!rules || !rules.length) setRules(AlertsSettings.emptyRules.degreeDays)
  })

  const {
    degreeDays: degreeDaysRule,
    degreeDaysForecast: degreeDaysForecastRule,
    startDate: startDateRule,
  } = alertRulesObjectFromArray(rules)

  let degreeDays = null

  if (degreeDaysRule) degreeDays = degreeDaysRule.value

  if (degreeDaysForecastRule) degreeDays = degreeDaysForecastRule.value

  let startDate: Rule['value'] = 'default'

  if (startDateRule) startDate = startDateRule.value

  const isForecast = !!degreeDaysForecastRule
  const isCustomStartDate = startDate !== 'default'

  let startDateForDatePicker = null

  if (startDateRule && isCustomStartDate && startDate)
    startDateForDatePicker = moment.tz(startDate, 'America/Los_Angeles').toDate()

  // check if user-enterable fields are dirty
  const degreeDaysIsDirty = degreeDays !== null || triedToSubmit
  const customStartDateIsDirty = triedToSubmit
  // validate the user-entered values
  const degreeDaysError = AlertsValidators.degreeDays({ degreeDays })

  const customStartDateError = AlertsValidators.degreeDaysStartDate({
    customStartDate: startDate ? (startDate as string) : null,
    isCustomStartDate,
  })

  // attach a status
  const degreeDaysValidateStatus = validateStatusErrorHelper(!!degreeDaysIsDirty && !!degreeDaysError)

  const customStartDateValidateStatus = validateStatusErrorHelper(
    !!customStartDateIsDirty && !!customStartDateError,
  )

  // attach an error message
  const degreeDaysHelp = helpTextErrorHelper(degreeDaysIsDirty && degreeDaysError)
  const customStartDateHelp = helpTextErrorHelper(customStartDateIsDirty && customStartDateError)

  // update the rules upon changes
  useEffect(() => {
    setThresholdIsValid(!degreeDaysError && !customStartDateError)
  }, [degreeDays, isForecast, isCustomStartDate, startDate])

  const unitTempDD = unitTempDisplayDD()

  const setIsForecast = (truthVal: boolean) => {
    updateAlertRules({
      rules,
      setRules,
      rulesToChange: {
        [truthVal ? 'degreeDaysForecast' : 'degreeDays']: {
          ...(degreeDaysRule || {}),
          ...(degreeDaysForecastRule || {}),
        }, // basically we just want to flip the keys but keep the values
        [truthVal ? 'degreeDays' : 'degreeDaysForecast']: null,
      },
    })
  }

  const setIsCustomStartDate = (truthVal: boolean) => {
    if (truthVal && !isCustomStartDate)
      updateAlertRules({ rules, setRules, rulesToChange: { startDate: { value: null } } })
    else updateAlertRules({ rules, setRules, rulesToChange: { startDate: { value: 'default' } } })
  }

  const errorStyle = getErrorStyle()

  return (
    <div>
      <div css={{ marginBottom: ROW_GAP }}>
        <span>{translate.phrases.banyanApp('Send alert for:')}</span>
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: ROW_GAP,
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 7,
              padding: '15px 15px 15px 0px',
            }}
          >
            <Radio
              checked={!isForecast}
              onClick={() => setIsForecast(false)}
              onChange={() => setIsForecast(false)}
            />
            <span css={{ cursor: 'pointer' }} onClick={() => setIsForecast(false)}>
              {translate.phrases.banyanApp('Current Degree Days')}
            </span>
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 7,
            }}
          >
            <Radio
              checked={isForecast}
              onClick={() => setIsForecast(true)}
              onChange={() => setIsForecast(true)}
            />
            <span css={{ cursor: 'pointer' }} onClick={() => setIsForecast(true)}>
              {translate.phrases.banyanApp('Forecast Degree Days (7-Day Forecast)')}
            </span>
          </div>
        </div>
        <div css={{ marginTop: ROW_GAP }}>
          <TranslatePhraseWrapper>
            <translate.Phrases.banyanApp
              k="Send alert when degree days greater than or equal to <numberInput/> {{unitTempDD}}"
              v={{ unitTempDD }}
              c={{
                numberInput: (
                  <TranslateComponentWrapper>
                    <NumberInput
                      css={{ maxWidth: 80, margin: '0 5px' }}
                      onChange={(value) =>
                        updateAlertRules({
                          rules,
                          setRules,
                          rulesToChange: { [isForecast ? 'degreeDaysForecast' : 'degreeDays']: { value } },
                        })
                      }
                      type="number"
                      value={isNumberOrNumericString(degreeDays) ? Number(degreeDays) : ''}
                      classNames={{ input: degreeDaysValidateStatus ? errorStyle : undefined }}
                      precision={10}
                      removeTrailingZeros={true}
                    />
                    <div />
                  </TranslateComponentWrapper>
                ),
              }}
            />
            {insectId === 1 && <InfoPopover content={<CodlingMothGuide />} width={450} darkMode={false} />}
          </TranslatePhraseWrapper>
          {degreeDaysHelp && <ErrorTextWrapper>{degreeDaysHelp}</ErrorTextWrapper>}
        </div>
      </div>
      <div css={{ marginTop: ROW_GAP }}>
        <span>{translate.phrases.banyanApp('based on degree day accumulation beginning on:')}</span>
        <div css={{ display: 'flex', flexWrap: 'wrap', marginTop: ROW_GAP }}>
          <div css={{ display: 'flex', flexDirection: 'row', gap: 7, padding: '0px 15px 15px 0px' }}>
            <Radio
              checked={!isCustomStartDate}
              onClick={() => setIsCustomStartDate(false)}
              onChange={() => setIsCustomStartDate(false)}
            />
            <span css={{ cursor: 'pointer' }} onClick={() => setIsCustomStartDate(false)}>
              {translate.phrases.banyanApp('Block Biofix Date')}
            </span>
          </div>
          <div>
            <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 7 }}>
              <Radio
                checked={isCustomStartDate}
                onClick={() => setIsCustomStartDate(true)}
                onChange={() => setIsCustomStartDate(true)}
              />
              <span css={{ cursor: 'pointer' }} onClick={() => setIsCustomStartDate(true)}>
                {translate.phrases.banyanApp('My Custom Biofix Date')}
              </span>
            </div>
            <div
              css={{
                alignItems: 'center',
                display: 'flex',
                ...fadeInOutStylesMaker(isCustomStartDate),
              }}
            >
              <DatePickerInput
                onChange={(value) => {
                  if (value) {
                    updateAlertRules({
                      rules,
                      setRules,
                      rulesToChange: {
                        startDate: {
                          value: moment.tz(value, 'America/Los_Angeles').format('YYYY-MM-DD'),
                        },
                      },
                    })
                  }
                }}
                placeholder={translate.phrases.banyanApp('Select biofix date')}
                valueFormat={translate.dates.getMomentFormat('MMM D, YYYY')}
                clearable={false}
                css={{ width: 240, marginTop: ROW_GAP }}
                classNames={{ input: customStartDateValidateStatus ? errorStyle : undefined }}
                value={startDateForDatePicker}
                rightSection={
                  <FontAwesomeIcon css={{ color: colors.grey800, fontSize: 16 }} icon={faCalendar} />
                }
              />
            </div>
          </div>
          {customStartDateHelp && <ErrorTextWrapper>{customStartDateHelp}</ErrorTextWrapper>}
        </div>
      </div>
      <div css={{ marginTop: ROW_GAP }}>
        {isForecast ? (
          <span>
            {translate.phrases.banyanApp(
              'This alert will only send for the respective block(s) when the current degree days have not met the threshold but the forecast degree days are expected to be met within 1 to 7 days',
            )}
          </span>
        ) : (
          <span>
            {translate.phrases.banyanApp(
              'This alert will only send once for the respective block(s) per year, when the current degree days meets or exceed the threshold',
            )}
          </span>
        )}
      </div>
    </div>
  )
}
