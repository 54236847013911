import { Select } from '@mantine/core'
import { TFieldAssetValueTypes } from 'App/Map/types'
import { getIdAndEmitterTypeFromZoneEmitterTypeKey } from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'
import { useTabsContext } from 'components/Tabs/Tabs.context'
import { useMemo } from 'react'
import { makeNameEmitterTypeLabel } from 'utils/makeNameEmitterTypeLabel'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { alphabeticalSort } from 'utils/alphabeticalSort'
import { mantineSelectSearchFilterProp } from 'utils/mantineSelectSearchFilterProp'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { sortByKey } from 'utils/sortByKey'
import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'

type SelectDataItem = {
  value: string
  label: string
  group: string
}

export const DropdownSelectorIrrigationZone = () => {
  const { selectedIrrigationZoneEmitter } = selectedFieldAssetsStore.useSelector((s) => ({
    selectedIrrigationZoneEmitter: s.irrigationZoneEmitter,
  }))

  const ctx = useTabsContext()

  const { properties } = fieldAssetStore.useSelector((s) => ({
    properties: s?.properties ?? [],
  }))

  const data = useMemo<SelectDataItem[]>(() => {
    return Object.values(properties ?? {})
      .sort(sortByKey('propertyName'))
      .flatMap((p: TFieldAssetValueTypes.TProperty) => {
        if (!p.irrigationZoneEmitters) return []

        return Object.entries(p.irrigationZoneEmitters)
          .filter(([, zone]) => {
            const { emitterType } = getIdAndEmitterTypeFromZoneEmitterTypeKey(zone.irrigationZoneEmitterId)

            return !!Object.values(p.points ?? {})?.some(
              (pt) =>
                pt.configuration.irrigationEmitterTypesAvailable?.includes(emitterType) &&
                pt.irrigationEmitterZoneIds?.some((ptz) => {
                  return ptz === zone.irrigationZoneEmitterId
                }),
            )
          })
          .map(([irrigationZoneId, irrigationZoneEmitter]) => ({
            value: irrigationZoneId,
            label: makeNameEmitterTypeLabel({
              name: irrigationZoneEmitter.name,
              emitterType: irrigationZoneEmitter.emitterType,
            }),

            group: p.propertyName,
          }))
          .sort((a, b) => alphabeticalSort(a.label, b.label))
      })
  }, [selectedIrrigationZoneEmitter, properties])

  return (
    // can prevent the section from collapsing by passing stopPropagation
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        clearable={false}
        css={{
          width: ctx.containerWidth < 600 ? 200 : Math.min(280, Math.round(ctx.containerWidth / 3)),
          border: `1px solid ${colors.midnight}`,
          borderRadius: 3,
        }}
        data={data}
        filter={mantineSelectSearchFilterProp}
        onChange={(newSelectedIrrigationZoneEmitter: SharedTypes.TIrrigationZoneEmitterId) => {
          return setSelectedFieldAsset({ irrigationZoneEmitter: newSelectedIrrigationZoneEmitter })
        }}
        searchable
        size="xs"
        spellCheck="false"
        styles={{
          dropdown: { marginTop: -6, fontWeight: 'normal' },
          input: { ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER.input, paddingRight: 28 },
        }}
        value={selectedIrrigationZoneEmitter?.toString()}
        withinPortal
        zIndex={1}
      />
    </div>
  )
}
