import { translate } from 'i18n/i18n'
import { colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'

export const getWindMachineActivityTitleAndColor = (
  windMachineActivity: string | null,
): { color: TRGBAColorWith1AtTheEnd; name: string } => {
  if (windMachineActivity === 'OFF') {
    return {
      color: colors.offBadge,
      name: translate.phrases.banyanApp('Off'),
    }
  }

  if (windMachineActivity === 'ON') {
    return {
      color: colors.fullRunBadge,
      name: translate.phrases.placeholder('On'),
    }
  }

  if (windMachineActivity === 'NO CONNECTION') {
    return {
      color: colors.noConnectionBadge,
      name: translate.phrases.placeholder('No Connection'),
    }
  }

  return {
    color: colors.grey50,
    name: translate.phrases.banyanApp('Unknown'),
  }
}
