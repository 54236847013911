import { translate } from 'i18n/i18n'
import * as Sentry from '@sentry/react'
import { TEquipmentType } from '../../store/serviceCenterStore'
import { IconTrap } from 'components/icons/IconTrap'
import { IconGateway } from 'components/icons/IconGateway'
import { IconDispenser } from 'components/icons/IconDispenser'
import { IconRepeater } from 'components/icons/IconRepeater'
import { IconStation } from 'components/icons/IconStation'
import { IconMoth } from 'components/icons/IconMoth'
import { IconNodeWithDevices } from 'components/icons/IconNodeWithDevices'

type IconComponent = () => JSX.Element

export const EQUIPMENT_TYPES: TEquipmentType[] = ['trap', 'gateway', 'dispenser', 'repeater', 'station']

export const EQUIPMENT_TYPE_ICONS: {
  [K in TEquipmentType]: IconComponent
} = {
  trap: IconTrap,
  gateway: IconGateway,
  dispenser: IconDispenser,
  repeater: IconRepeater,
  station: IconStation,
  external: () => <></>, // TODO: Add external icon
}

export const DEVICEABLE_EQUIPMENTS = ['repeater', 'station']

export const EQUIPMENT_TYPE_INNER_ICONS: {
  [K in TEquipmentType]?: IconComponent
} = {
  trap: IconMoth,
  station: IconNodeWithDevices,
}

export function getEquipmentTypeLabel(equipmentType: TEquipmentType, plural = false): string {
  switch (equipmentType) {
    case 'trap':
      return translate.phrases.placeholder(plural ? 'Traps' : 'Trap')

    case 'gateway':
      return translate.phrases.placeholder(plural ? 'Gateways' : 'Gateway')

    case 'dispenser':
      return translate.phrases.placeholder(plural ? 'Dispensers' : 'Dispenser')

    case 'repeater':
      return translate.phrases.placeholder(plural ? 'Repeaters' : 'Repeater')

    case 'station':
      return translate.phrases.placeholder(plural ? 'Stations' : 'Station')

    default: {
      Sentry.captureException(`Equipment type "${equipmentType}" has not been implemented`)

      return equipmentType
    }
  }
}
