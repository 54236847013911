import { translate } from 'i18n/i18n'
import { Select } from 'components/Select/Select'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { ErrorTextWrapper } from '../../components/ErrorTextWrapper'
import { colors } from 'settings/colors'

export const WEEKLY_UPDATE_REPORT_TYPE = 'Weekly Update'

export const WEEKLY_TRAP_CATCHES_REPORT_TYPE = 'Weekly Trap Catches'

export type TReportType = typeof WEEKLY_UPDATE_REPORT_TYPE | typeof WEEKLY_TRAP_CATCHES_REPORT_TYPE | ''

export const ReportType = ({
  reportId,
  setReportType,
  reportType,
  triedToSubmit,
  setTypeIsValid,
}: {
  reportId: string | null
  setReportType: Dispatch<SetStateAction<TReportType>>
  reportType: TReportType
  triedToSubmit: boolean
  setTypeIsValid: Dispatch<SetStateAction<boolean>>
}) => {
  let helpText = ''
  let validateStatusError = false

  if (!reportType && triedToSubmit) {
    helpText = translate.phrases.banyanApp('Must select a report type')

    validateStatusError = true
  }

  useEffect(() => {
    setTypeIsValid(!validateStatusError)
  }, [reportType])

  return (
    <div css={{ paddingBottom: '25px', borderBottom: `1px solid ${colors.grey200}` }}>
      <h4>{translate.phrases.banyanApp('Type')}</h4>
      <Select
        data={[
          { value: WEEKLY_UPDATE_REPORT_TYPE, label: translate.phrases.banyanApp('Weekly Update') },
          {
            value: WEEKLY_TRAP_CATCHES_REPORT_TYPE,
            label: translate.phrases.banyanApp('Weekly Trap Catches'),
          },
        ]}
        placeholder={translate.phrases.banyanApp('Choose a report type')}
        value={reportType}
        handleOnChange={(newValue) => {
          if (newValue === WEEKLY_UPDATE_REPORT_TYPE || newValue === WEEKLY_TRAP_CATCHES_REPORT_TYPE) {
            setReportType(newValue ?? '')
          }
        }}
        disabled={reportId ? true : false}
      />
      {validateStatusError && <ErrorTextWrapper>{helpText}</ErrorTextWrapper>}
    </div>
  )
}
