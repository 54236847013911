import { css, cx } from '@emotion/css'
import { MAGIC_NUMBER_TO_TAKE_OFF_WIDTH, baseZIndex } from 'components/GridTable/consts'
import { GridTableScrollContext } from 'components/GridTable/context'
import { GridTableColumn } from 'components/GridTable/types'
import { useContext } from 'react'
import { colors } from 'settings/colors'

export const LabelCell = ({
  children,
  height,
  cellCSS,
  width,
}: {
  children: React.ReactNode
  height: number
  cellCSS?: GridTableColumn['headerCellCSS']
  width: number
}) => {
  const { allTheWayLeft } = useContext(GridTableScrollContext)

  return (
    <div
      className={cx([
        css([
          {
            width: width - MAGIC_NUMBER_TO_TAKE_OFF_WIDTH,
            height,
            border: `1px solid ${colors.grey200}`,
            backgroundColor: '#ffffff',
            boxSizing: 'border-box',
            padding: '0 15px',
            display: 'flex',
            alignItems: 'center',
            transition: '200ms ease all',
            zIndex: baseZIndex - 2,
            position: 'sticky',
            top: 0,
            left: 0,
            boxShadow: `-10px 0 25px 25px rgba(180, 179, 187, 0${!allTheWayLeft ? '.7' : ''})`,
            clipPath: 'polygon(0% 0%, 160% 0%, 160% 100%, 0px 100%)',
          },
          cellCSS && cellCSS,
        ]),
      ])}
    >
      {children}
    </div>
  )
}
