import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { TUnitConverterOptionsWithoutUnits, TUnitConverterTypicalReturn } from '../types'

const DEFAULT_DECIMAL_PLACES_DAILY_DSV = 0
const DEFAULT_DECIMAL_PLACES_7_DAY_DSV = 0
const DEFAULT_DECIMAL_PLACES_SPRAY_THRESHOLD_DSV = 0

const alternariaDaily = (
  dsvValue: number | null = null,
  { decimalPlaces = DEFAULT_DECIMAL_PLACES_DAILY_DSV }: TUnitConverterOptionsWithoutUnits = {},
): TUnitConverterTypicalReturn => {
  let convertedValue: number | null

  if (isNil(dsvValue)) {
    convertedValue = null
  } else {
    convertedValue = dsvValue
  }

  const label = translate.phrases.banyanApp('Alternaria')
  const unitSymbol = translate.phrases.banyanApp('Daily DSV')

  const labelAndUnit = translate.phrases.templates('{{labelA}} {{labelB}}', {
    labelA: label,
    labelB: unitSymbol,
  })

  return {
    categoryTitle: () => labelAndUnit,
    categoryTitleWithoutUnit: () => label,
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_DAILY_DSV,
    shortTitle: () => unitSymbol,
    suffix: () => '',
    title: () =>
      translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: labelAndUnit,
        labelB: translate.phrases.banyanApp('Disease Severity Value'),
      }),
    titleWithoutUnit: () => label,
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => {
      return translate.measurements.diseaseSeverityValue.value(convertedValue, decimalPlaces)
    },
    valueWithSuffix: () => {
      return translate.measurements.diseaseSeverityValue.value(convertedValue, decimalPlaces)
    },
    valueWithNoRounding: () => convertedValue,
  }
}

const alternariaCumulativeRolling7Days = (
  dsvCumulativeRolling7DaysValue: number | null = null,
  { decimalPlaces = DEFAULT_DECIMAL_PLACES_7_DAY_DSV }: TUnitConverterOptionsWithoutUnits = {},
): TUnitConverterTypicalReturn => {
  let convertedValue: number | null

  if (isNil(dsvCumulativeRolling7DaysValue)) {
    convertedValue = null
  } else {
    convertedValue = dsvCumulativeRolling7DaysValue
  }

  const label = translate.phrases.banyanApp('Alternaria')
  const unitSymbol = translate.phrases.banyanApp('7 Day DSV')

  const labelAndUnit = translate.phrases.templates('{{labelA}} {{labelB}}', {
    labelA: label,
    labelB: unitSymbol,
  })

  return {
    categoryTitle: () => labelAndUnit,
    categoryTitleWithoutUnit: () => label,
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_7_DAY_DSV,
    shortTitle: () => unitSymbol,
    suffix: () => '',
    title: () =>
      translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: labelAndUnit,
        labelB: translate.phrases.banyanApp('Disease Severity Value'),
      }),
    titleWithoutUnit: () => label,
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => {
      return translate.measurements.diseaseSeverityValue.value(convertedValue, decimalPlaces)
    },
    valueWithSuffix: () => {
      return translate.measurements.diseaseSeverityValue.value(convertedValue, decimalPlaces)
    },
    valueWithNoRounding: () => convertedValue,
  }
}

const alternariaBlockDSVSprayThreshold = (
  blockDSVSprayThresholdValue: number | null = null,
  { decimalPlaces = DEFAULT_DECIMAL_PLACES_SPRAY_THRESHOLD_DSV }: TUnitConverterOptionsWithoutUnits = {},
): TUnitConverterTypicalReturn => {
  let convertedValue: number | null

  if (isNil(blockDSVSprayThresholdValue)) {
    convertedValue = null
  } else {
    convertedValue = blockDSVSprayThresholdValue
  }

  const unitSymbol = translate.measurements.diseaseSeverityValue.unit()

  return {
    categoryTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Alternaria'),
        unitSymbol,
      }),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Alternaria'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_SPRAY_THRESHOLD_DSV,
    shortTitle: () => translate.phrases.banyanApp('7 Day Threshold'),
    suffix: () => unitSymbol,
    title: () =>
      translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
        labelA: translate.phrases.banyanApp('Alternaria'),
        labelB: translate.phrases.banyanApp('7 Day Threshold'),
        unitSymbol,
      }),
    titleWithoutUnit: () =>
      translate.phrases.templates('{{labelA}} ({{labelB}})', {
        labelA: translate.phrases.banyanApp('Alternaria'),
        labelB: translate.phrases.banyanApp('7 Day Threshold'),
      }),
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => {
      return translate.measurements.diseaseSeverityValue.value(convertedValue, decimalPlaces)
    },
    valueWithSuffix: () => {
      return translate.measurements.diseaseSeverityValue.valueWithUnit(convertedValue, decimalPlaces)
    },
    valueWithNoRounding: () => convertedValue,
  }
}

export const alternaria = {
  alternaria: alternariaDaily,
  alternariaCumulativeRolling7Days,
  alternariaBlockDSVSprayThreshold,
}
