import React from 'react'
import { ActiveTabHighlightBorder } from './ActiveTabHighlightBorder/ActiveTabHighlightBorder'

export const Expanded = ({
  children,
  selectedPosition,
}: {
  children: React.ReactNode
  selectedPosition: { width: number; left: number }
}) => {
  const { width, left } = selectedPosition

  return (
    <>
      {children}
      <ActiveTabHighlightBorder width={width} left={left} />
    </>
  )
}
