import { useState } from 'react'
import { css } from '@emotion/css'
import { Button } from '@mantine/core'
import * as Sentry from '@sentry/react'

export const DevUtils = () => {
  const [message, setMessage] = useState('Click me to error!')

  return (
    <Sentry.ErrorBoundary fallback={<div>{'Sorry, an error has occurred.'}</div>}>
      <div className={css({ paddingBottom: 12 })}>
        <code>VITE_USE_SENTRY: {import.meta.env.VITE_USE_SENTRY}</code>
      </div>
      <div className={css({ display: 'flex', gap: 24 })}>
        <Button
          onClick={() => {
            throw new Error('Sentry: Throw error test')
          }}
        >
          {'Sentry: Throw error'}
        </Button>
        <Button
          onClick={() => {
            try {
              throw new Error('Sentry: captureException test')
            } catch (error) {
              Sentry.captureException(error)
            }
          }}
        >
          {'Sentry: captureException'}
        </Button>
        <Button
          onClick={() => {
            Sentry.captureMessage('Sentry: captureMessage test')
          }}
        >
          {'Sentry: captureMessage'}
        </Button>

        <Button
          onClick={() => {
            // @ts-ignore
            setMessage({ beep: 'woo' })
          }}
        >
          {message}
        </Button>
      </div>
    </Sentry.ErrorBoundary>
  )
}
