import './windowObjectAddons'
import './sentry'
import './fullStory'
import './hubspot'
import { initI18n } from 'i18n/i18n'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './ionicStorage'
import { App } from './App/App'
import { loadStores } from 'stores/_utils/loadStores'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { initNetworkStatus } from 'App/ServiceCenter/store/networkStore'
import { initDeviceScreenOrientation } from 'stores/smallStore'

// Note: consider the calling code that uses this containerId if we change it at any point
export const containerId = 'app'

loadStores()
  .then(() => initI18n())
  .then(() => initNetworkStatus())
  .then(() => initDeviceScreenOrientation())
  .finally(() => {
    ReactDOM.createRoot(document.getElementById(containerId) as HTMLElement).render(<App />)
  })

// Call the element loader after the app has been rendered the first time
defineCustomElements(window)
