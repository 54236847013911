import { css } from '@emotion/css'
import { GridTableScrollContext } from 'components/GridTable/context'
import { useContext } from 'react'
import { baseZIndex, MAGIC_NUMBER_TO_TAKE_OFF_WIDTH } from '../../consts'
import { TopLeftCell } from '../../types'
import { colors } from 'settings/colors'

export const Left = ({
  topLeftCell,
  widthForLabelColumn,
}: {
  topLeftCell?: TopLeftCell
  widthForLabelColumn: number
}) => {
  const { cellCSS, title } = topLeftCell ?? {}
  const { allTheWayLeft } = useContext(GridTableScrollContext)

  return (
    <div
      className={css([
        {
          flexBasis: widthForLabelColumn - MAGIC_NUMBER_TO_TAKE_OFF_WIDTH,
          zIndex: baseZIndex - 2,
          position: 'sticky',
          backgroundColor: '#ffffff',
          boxSizing: 'border-box',
          padding: '10px 15px',
          left: '0',
          top: '0',
          flexGrow: 0,
          flexShrink: 0,
          float: 'left',
          boxShadow: `-10px 0 25px 25px rgba(180, 179, 187, 0${!allTheWayLeft ? '.7' : ''})`,
          transition: '200ms ease all',
          clipPath: 'polygon(0% 0%, 160% 0%, 160% 100%, 0px 100%)',
          display: 'flex',
          alignItems: 'flex-end',
          fontSize: 12,
          fontWeight: '400',
          fontStyle: 'italic',
          color: colors.grey500,
          height: 84,
        },
        cellCSS && cellCSS,
      ])}
    >
      {title}
    </div>
  )
}
