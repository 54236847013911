import { MapControlsPanel } from 'components/MapControlsPanel/MapControlsPanel'
import { FC } from 'react'
import { usePreventPanelDetailsFromBeingOpen } from 'utils/usePreventPanelDetailsFromBeingOpen'
import { SharedSettings } from '../../../settings/SharedSettings'
import { layersNavigationMenuStore } from '../../../stores/layersNavigationMenuStore'
import { CSSObject } from '@emotion/css'
import { MapControlItems } from './MapControlItems/MapControlItems'
import { ValueRow } from './ValueRow/ValueRow'
import { useScreenOrientation } from 'stores/smallStore'
import { useScreenSize } from 'utils/useScreenSize'

const { SPACING_WHEN_MENU_COLLAPSED, SPACING_WHEN_MENU_OPEN } =
  SharedSettings.SPACING_FOR_THINGS_THAT_FLOAT_OVER_LEFT_SIDE_OF_MAP

export const MapControls: FC = () => {
  const { isWideScreen } = useScreenSize()
  const screenOrientation = useScreenOrientation()
  const menuCollapsed = layersNavigationMenuStore.useSelector((s) => s.menuCollapsed)
  const preventPanelDetailsFromBeingOpen = usePreventPanelDetailsFromBeingOpen()
  const leftSpacing = menuCollapsed ? SPACING_WHEN_MENU_COLLAPSED : SPACING_WHEN_MENU_OPEN
  const collapsedBottomPanelHeight = 60
  const isCollapsedBottomPanel = screenOrientation === 'landscape' && !isWideScreen

  const wideScreenContainerStyle: CSSObject = {
    left: leftSpacing,
    top: menuCollapsed ? 'calc(132px + env(safe-area-inset-top))' : 'calc(60px + env(safe-area-inset-top))',
  }

  const narrowScreenContainerStyle: CSSObject = {
    left: 10,
    bottom: 10,
    marginBottom: preventPanelDetailsFromBeingOpen || isCollapsedBottomPanel ? 0 : collapsedBottomPanelHeight,
  }

  return (
    <MapControlsPanel
      style={
        screenOrientation === 'landscape' && isWideScreen
          ? wideScreenContainerStyle
          : narrowScreenContainerStyle
      }
      expandedContent={<MapControlItems />}
    >
      <ValueRow />
    </MapControlsPanel>
  )
}
