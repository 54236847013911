import { css } from '@emotion/css'
import { translate } from 'i18n/i18n'
import { FC } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import { Email } from './Email'
import { SmsNumber } from './SmsNumber/SmsNumber'

export const PersonalInfoSection: FC = () => {
  const { email } = userDetailsStore.getState()

  return (
    <div
      className={css`
        margin: 20px 0;
      `}
    >
      <div
        className={css`
          font-weight: 700;
        `}
      >
        {translate.phrases.banyanApp('Personal Info')}
      </div>
      <Email email={email} />

      <SmsNumber />
    </div>
  )
}
