import { REGEXES } from './regexes'
import { isDistributedNode } from './getNodeNetworkType'
import { TNodeType } from '../types'

export function validateNodeIdentifier(nodeType: TNodeType, nodeIdentifier: string): boolean {
  let nodeIdentifierPattern

  if (isDistributedNode(nodeType)) {
    nodeIdentifierPattern = REGEXES.dnNodeId
  } else if (nodeType === 'ln_scd') {
    nodeIdentifierPattern = REGEXES.lnSCDId
  } else if (nodeType === 'ln_r') {
    nodeIdentifierPattern = REGEXES.lnRId
  } else {
    nodeIdentifierPattern = REGEXES.mac
  }

  return nodeIdentifierPattern.test(nodeIdentifier)
}
