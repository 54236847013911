import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { getAwcPoints } from './getAwcPoints'

const {
  zoneBorderLine,
  idealSaturatedBoundary,
  awcZoneSaturated,
  awcZoneIdeal,
  awcZoneDeficitModerate,
  awcZoneDeficitHigh,
  awcZoneDeficitExtreme,
} = colors

export const makeAWCZones = ({
  awcData,
}: {
  awcData: {
    metadata: { MADDates: { startDate: string; soilMoisture: number }[] }
    timeseries: { timestamp: string }[]
  }[]
}) => {
  const {
    mappedSaturatedPoints,
    mappedExtremeDeficitPoints,
    mappedIdealPoints,
    mappedModerateDeficitPoints,
    mappedHighDeficitPoints,
  } = getAwcPoints(awcData)

  const commonProperties = {
    enableMouseTracking: false,
    includeInLegendCheckboxes: false,
    name: '',
    visible: true,
    yAxis: 0,
    isADepthSeries: false,
    rainSeries: false,
    showInLegend: false,
    hideFromTooltip: true,
    states: {
      // don't fade out these series when hovering over the chart
      inactive: {
        opacity: 1,
      },
    },
  }

  const linesAWC = {
    idealSaturated: {
      ...commonProperties,
      color: idealSaturatedBoundary,
      dashStyle: 'solid',
      data: mappedSaturatedPoints,
      lineWidth: 2,
      type: 'line',
      width: 4,
      zIndex: 2,
      zoneAxis: 'x',
      id: 'idealSaturatedLineAWC',
    },
    idealSaturatedHighlight: {
      ...commonProperties,
      color: zoneBorderLine,
      dashStyle: 'solid',
      data: mappedSaturatedPoints,
      lineWidth: 3.25,
      type: 'line',
      width: 4,
      zIndex: 1,
      zoneAxis: 'x',
      id: 'idealSaturatedHighlightLineAWC',
    },
  }

  const zonesAWC = {
    avg: {
      ...commonProperties,
      name: translate.phrases.banyanApp('Avg Selected Depths'),
      color: 'black',
      fillColor: 'black',
      dashStyle: 'solid',
      data: [],
      lineWidth: 0,
      threshold: 0,
      type: 'line',
      width: 4,
      zIndex: 0,
      zoneAxis: 'x',
      id: 'avgZoneAWC',
    },
    saturated: {
      ...commonProperties,
      name: translate.phrases.banyanApp('Saturated'),
      color: zoneBorderLine,
      fillColor: awcZoneSaturated,
      dashStyle: 'solid',
      data: mappedSaturatedPoints,
      lineWidth: 0,
      threshold: Infinity,
      type: 'area',
      width: 4,
      zIndex: 0,
      zoneAxis: 'x',
      id: 'saturatedZoneAWC',
    },
    ideal: {
      ...commonProperties,
      name: translate.phrases.banyanApp('Ideal'),
      color: zoneBorderLine,
      fillColor: awcZoneIdeal,
      data: mappedIdealPoints,
      lineWidth: 0.25,
      type: 'arearange',
      zIndex: 0,
      id: 'idealZoneAWC',
    },
    moderateDeficit: {
      ...commonProperties,
      name: translate.phrases.banyanApp('Moderate Deficit'),
      color: zoneBorderLine,
      fillColor: awcZoneDeficitModerate,
      data: mappedModerateDeficitPoints,
      lineWidth: 0,
      type: 'arearange',
      zIndex: 0,
      id: 'moderateDeficitZoneAWC',
    },
    highDeficit: {
      ...commonProperties,
      name: translate.phrases.banyanApp('High Deficit'),
      color: zoneBorderLine,
      fillColor: awcZoneDeficitHigh,
      data: mappedHighDeficitPoints,
      lineWidth: 0.25,
      type: 'arearange',
      visible: true,
      zIndex: 0,
      id: 'highDeficitZoneAWC',
    },
    extremeDeficit: {
      ...commonProperties,
      name: translate.phrases.banyanApp('Extreme Deficit'),
      color: zoneBorderLine,
      fillColor: awcZoneDeficitExtreme,
      data: mappedExtremeDeficitPoints,
      lineWidth: 0.25,
      threshold: -Infinity,
      type: 'area',
      zIndex: 0,
      zoneAxis: 'x',
      id: 'extremeDeficitZoneAWC',
    },
  }

  const zonesAWCValues = Object.values(zonesAWC)
  const linesAWCValues = Object.values(linesAWC)

  return { zonesAWCValues, linesAWCValues }
}
