import { ExpandableChevronIndicator } from 'components/ExpandableChevronIndicator/ExpandableChevronIndicator'
import { IconAndTitle } from 'components/SideNav/IconAndTitle/IconAndTitle'
import { MouseEventHandler, useCallback } from 'react'
import { layersNavigationMenuStore } from 'stores/layersNavigationMenuStore'
import { generateNavigationMenu } from '../generateNavigationMenu/generateNavigationMenu'
import { SectionLayerItem } from './SectionLayerItem/SectionLayerItem'
import { SelectedLayersCountBadge } from '../SelectedLayersCountBadge/SelectedLayersCountBadge'

export const SectionItem = ({ section }: { section: ReturnType<typeof generateNavigationMenu>[number] }) => {
  const expanded = layersNavigationMenuStore.useSelector((s) => s.openLayersSubsectionKeys[section.key])

  const onChange = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
    event.stopPropagation()

    layersNavigationMenuStore.setState((s) => ({
      ...s,
      openLayersSubsectionKeys: {
        ...s.openLayersSubsectionKeys,
        [section.key]: !s.openLayersSubsectionKeys[section.key],
      },
    }))
  }, [])

  return (
    <div css={{ paddingTop: 8 }}>
      <IconAndTitle
        key={section.key}
        icon={section.icon}
        onClick={onChange}
        right={
          <div css={{ marginRight: 7 }}>
            <ExpandableChevronIndicator
              expanded={expanded}
              onClick={(event) => {
                event.stopPropagation()

                layersNavigationMenuStore.setState((s) => ({
                  ...s,
                  openLayersSubsectionKeys: { ...s.openLayersSubsectionKeys, [section.key]: !expanded },
                }))
              }}
              size={12}
            />
          </div>
        }
        subtitle
        title={
          <>
            {section.label ?? section.translatedTitle}
            <SelectedLayersCountBadge count={section.childrenCheckedCount} />
          </>
        }
      />
      {!!expanded && section.children.map((item) => <SectionLayerItem item={item} key={item.key} />)}
    </div>
  )
}
