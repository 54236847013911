import { init } from '../simple-store'
import { populateStoreMaker } from './_utils/populateStoreMaker'
import { subscribeToStoreForPersistedState } from './_utils/subscribeToStoreForPersistedState'
import { subscribeToStoreForURL } from './_utils/subscribeToStoreForURL'

type TStoreName = `${string}Store`

export const registeredStores: Record<
  TStoreName,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ReturnType<typeof init<any>> & { populateStore: () => void }
> = {}

export const persistentInit = <TPersistentInitState extends Record<string, unknown>>({
  initialState,
  storeName,
  keysToPutInURL,
  keysToPutInPersistentStorage,
}: {
  initialState: TPersistentInitState
  storeName: TStoreName
  keysToPutInURL: Record<keyof TPersistentInitState, boolean>
  keysToPutInPersistentStorage: Record<keyof TPersistentInitState, boolean>
}) => {
  const store = init(initialState)

  subscribeToStoreForURL({ keysToPutInURL, store, storeName })

  subscribeToStoreForPersistedState({ keysToPutInPersistentStorage, store, storeName })

  if (registeredStores[storeName]) {
    throw new Error(`There is already a store with the name ${storeName}`)
  }

  registeredStores[storeName] = {
    ...store,
    populateStore: populateStoreMaker({ initialState, store, storeName }),
  }

  return store
}
