import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'

export type AnyNode =
  | SharedTypes.TPlannedNode
  | SharedTypes.TPlannedGateway
  | SharedTypes.TActiveNode
  | SharedTypes.TActiveGateway

export type TPlannedNode = SharedTypes.TPlannedNode

export type TPlannedGateway = SharedTypes.TPlannedGateway

export type TActiveNode = SharedTypes.TActiveNode

export type TActiveGateway = SharedTypes.TActiveGateway

export type TDevice = SharedTypes.TDevice

export type TNodeDevice = SharedTypes.TNodeDevice

export type TNodeDevices = SharedTypes.TNodeDevices

export type TNodeType = SharedTypes.TNodeType

export type TNodeLog = SharedTypes.TNodeLog

export type TSnNodeLog = SharedTypes.TSnNodeLog

export type TDnNodeLog = SharedTypes.TDnNodeLog

export type TLnNodeLog = SharedTypes.TLnNodeLog

export type TLnSCDLog = SharedTypes.TLnSCDLog

export type TDeviceLog = SharedTypes.TDeviceLog

export enum InstallationStep {
  ACTIVATE_NODE = 'ACTIVATE_NODE',
  CONFIGURE_DEVICE = 'CONFIGURE_DEVICE',
  CHECKLIST = 'CHECKLIST',
  REVIEW = 'REVIEW',
  CONFIRM_LOCATION = 'CONFIRM_LOCATION',
}

export enum HelpGuideSection {
  QA = 'QA',
  ASSEMBLY = 'ASSEMBLY',
  INSTALLATION = 'INSTALLATION',
  MAINTENANCE = 'MAINTENANCE',
}

export type THelpGuides = SharedTypes.THelpGuides

export type THelpGuideStep = SharedTypes.THelpGuideStep

export type TInstallationStepActivateNode = {
  type: InstallationStep.ACTIVATE_NODE
  title: string
  nodeType?: SharedTypes.TNodeType
  enabled: boolean
  completed: boolean
  required: boolean
}

export type TInstallationStepConfigureDevice = {
  type: InstallationStep.CONFIGURE_DEVICE
  title: string
  device: SharedTypes.TNodeDevice
  enabled: boolean
  completed: boolean
  required: boolean
}

export type TInstallationStepConfirmLocation = {
  type: InstallationStep.CONFIRM_LOCATION
  title: string
  nodeType?: SharedTypes.TNodeType
  enabled: boolean
  completed: boolean
  required: boolean
}

export type TInstallationStepChecklist = {
  type: InstallationStep.CHECKLIST
  title: string
  checklist: string[]
  enabled: boolean
  completed: boolean
  required: boolean
}

export type TInstallationStepReview = {
  type: InstallationStep.REVIEW
  title: string
  image: string
  enabled: boolean
  completed: boolean
  required: boolean
}

export type TEquipmentInstallationStep =
  | TInstallationStepActivateNode
  | TInstallationStepConfigureDevice
  | TInstallationStepChecklist
  | TInstallationStepReview
  | TInstallationStepConfirmLocation

export type TInstallationChecklist = SharedTypes.TInstallationChecklist

export enum TNodeStatus {
  PLANNED = 'planned',
  ACTIVE = 'active',
  ERROR = 'error',
  OFFLINE = 'offline',
  PENDING = 'pending',
  REMOVED = 'removed',
}

export type TNodeServiceIssueSummary = SharedTypes.TNodeServiceIssueSummary

export type TServiceIssueResolutionAction = SharedTypes.TResolutionAction
