import { useEffect } from 'react'
import { MOVE_COORDS, MOVE_POINT_STATE, SIZES_SNAPPING } from '../useMoveTracker'
import { snap } from './snap'
import { getMoveDirection } from './getMoveDirection'

export default function handleBlurAndPointerUp(
  setState: React.Dispatch<React.SetStateAction<MOVE_POINT_STATE>>,
  tracking: MOVE_POINT_STATE['tracking'],
  snapEndY1: number | ReadonlyArray<number>,
  snapEndY2: number | ReadonlyArray<number>,
  snapEndX1: number | ReadonlyArray<number>,
  snapEndX2: number | ReadonlyArray<number>,
  sizesSnapping: SIZES_SNAPPING,
  onAfterResize?: (info: MOVE_COORDS) => void,
) {
  useEffect(() => {
    let fn: ((event: FocusEvent | PointerEvent) => void) | null = null

    if (tracking) {
      fn = (event) => {
        // if the user has multiple fingers touching, and they release a finger that is not the primary one (The first touched finger is the primary one), then do nothing.
        if ('isPrimary' in event && !event.isPrimary) {
          return
        }

        setState((s) => {
          const update: MOVE_POINT_STATE = {
            ...s,
            tracking: null,
            pointerType: null,
            downPoint: null,
            point: null,
            scrolledStart: 0, // TODO: really only need to do this if touch and allowDragContentForTouch = true
            x1Start: s.x1,
            x2Start: s.x2,
            y1Start: s.y1,
            y2Start: s.y2,
          }

          if (tracking === 'X1') {
            const snapped = sizesSnapping.x1 ? snap(s.x1, snapEndX1, getMoveDirection(s.x1, s.x1Start)) : s.x1

            update['x1'] = snapped

            update['x1Start'] = snapped
          } else if (tracking === 'X2') {
            const snapped = sizesSnapping.x2 ? snap(s.x2, snapEndX2, getMoveDirection(s.x2, s.x2Start)) : s.x2

            update['x2'] = snapped

            update['x2Start'] = snapped
          } else if (tracking === 'Y1') {
            const snapped = sizesSnapping.y1 ? snap(s.y1, snapEndY1, getMoveDirection(s.y1, s.y1Start)) : s.y1

            update['y1'] = snapped

            update['y1Start'] = snapped
          } else if (tracking === 'Y2') {
            const snapped = sizesSnapping.y2 ? snap(s.y2, snapEndY2, getMoveDirection(s.y2, s.y2Start)) : s.y2

            update['y2'] = snapped

            update['y2Start'] = snapped
          }

          onAfterResize &&
            onAfterResize({
              x1: update.x1,
              x2: update.x2,
              y1: update.y1,
              y2: update.y2,
            })

          return update
        })
      }

      window.addEventListener('blur', fn)

      window.addEventListener('pointerup', fn)
    }

    return () => {
      if (fn) window.removeEventListener('blur', fn)

      if (fn && tracking) window.removeEventListener('pointerup', fn)
    }
  }, [
    tracking,
    snapEndY1,
    snapEndY2,
    snapEndX1,
    snapEndX2,
    onAfterResize,
    sizesSnapping.y1,
    sizesSnapping.y2,
    sizesSnapping.x1,
    sizesSnapping.x2,
  ])
}
