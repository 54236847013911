import { routes } from '@semios/app-platform-banyan-route-definitions'
import { TFieldAssetValueTypes } from 'App/Map/types'

type TBlock = TFieldAssetValueTypes.TBlock

type TPointToReturn = TBlock & { propertyId: number; propertyName: string }

export const getBlocksFromProperties = ({
  properties,
}: {
  properties: routes.UserAppStartup.Response['properties']
}) => {
  const blocksToReturn: TPointToReturn[] = []

  Object.values(properties || {}).forEach((property) => {
    Object.values(property.blocks || {}).forEach((block) => {
      if (!blocksToReturn.find((p) => p.blockId === block.blockId)) {
        blocksToReturn.push({
          ...block,
          propertyId: property.propertyId,
          propertyName: property.propertyName,
        })
      }
    })
  })

  return blocksToReturn
}
