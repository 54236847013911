import { moderateDeficitThreshold } from 'App/Map/_utils/moderateDeficitThreshold'

type Point = {
  x: number
  y: number
  type: string
}

type RangePoint = {
  x: number
  high: number
  low: number
  type: string
}

const fc = 100
const pwp = 0

const sortDates = (a: { startDate: string }, b: { startDate: string }) =>
  +new Date(a.startDate) - +new Date(b.startDate)

const mapPoints = (timeseries: { timestamp: string }[], value: number): Point[] =>
  timeseries.map((data) => ({
    x: +new Date(data.timestamp),
    y: value,
    type: 'area',
  }))

const mapMadPoints = (
  timeseries: { timestamp: string }[],
  madDates: { startDate: string; soilMoisture: number }[],
): Point[] =>
  timeseries.map((data) => {
    const timestamp = +new Date(data.timestamp)
    const madValue = madDates.find((madDate) => +new Date(madDate.startDate) <= timestamp)?.soilMoisture

    return { x: timestamp, y: madValue ? Number(madValue) : 70, type: 'area' }
  })

export const getAwcPoints = (
  awcData: {
    metadata: {
      MADDates: {
        startDate: string
        soilMoisture: number
      }[]
    }
    timeseries: {
      timestamp: string
    }[]
  }[],
) => {
  const {
    metadata: { MADDates },
    timeseries,
  } = awcData[0]

  const sortedMadDates = MADDates?.sort(sortDates) || []
  const mappedSaturatedPoints = mapPoints(timeseries, fc)
  const mappedExtremeDeficitPoints = mapPoints(timeseries, pwp)
  const madPoints = mapMadPoints(timeseries, sortedMadDates)

  const mappedIdealPoints: RangePoint[] = mappedSaturatedPoints.map(({ x, y }, i) => ({
    x,
    high: y,
    low: madPoints[i].y,
    type: 'area',
  }))

  const mappedModerateDeficitPoints: RangePoint[] = madPoints.map(({ x, y }) => {
    const low = y * (1 - moderateDeficitThreshold)

    return {
      x,
      high: y,
      low,
      type: 'area',
    }
  })

  const mappedHighDeficitPoints: RangePoint[] = mappedExtremeDeficitPoints.map(({ x, y }, i) => ({
    x,
    low: y,
    high: mappedModerateDeficitPoints[i].low,
    type: 'area',
  }))

  return {
    mappedSaturatedPoints,
    mappedExtremeDeficitPoints,
    mappedIdealPoints,
    mappedModerateDeficitPoints,
    mappedHighDeficitPoints,
  }
}
