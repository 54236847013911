import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { useIsServiceCenterActive } from 'utils/useIsServiceCenterActive'

// TODO Will get rid of this when we refactor/improve map ui item placement (considering screen size, orientation... etc.)
export const usePreventPanelDetailsFromBeingOpen = () => {
  const someFieldAssetIsSelected = selectedFieldAssetsStore.useSelector((s) =>
    Object.values(s).some((fa) => fa),
  )

  const someValueGroupIsSelected = selectedValueGroupsStore.useSelector((s) =>
    Object.values(s.selectedValueGroups).some((vg) => vg),
  )

  const iServiceCenterActive = useIsServiceCenterActive()

  if (iServiceCenterActive) return false
  else return !someFieldAssetIsSelected || !someValueGroupIsSelected
}
