import { IRRIGATION_SCHEDULER_FIRST_DAY_OF_WEEK } from '@semios/app-platform-common'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import type { TPublishedIrrigationEvent } from 'App/irrigation-scheduler/components/Grid/components/Chart/utils/makeSeries'
import moment, { Moment } from 'moment-timezone'
import { init } from 'stores/_utils/simple-store'

export enum TScheduledEventEnum {
  PREVIEW = 'PREVIEW', // TODO
  DRAFT_CREATE = 'DRAFT_CREATE',
  DRAFT_DELETE = 'DRAFT_DELETE',
  SCHEDULED = 'SCHEDULED',
  STOPPED = 'STOPPED',
  PENDING_DELETE = 'PENDING_DELETE',
}

export type EVENT_ID = string

export type ISOString = string

export type DATE_RANGE = { dateFrom: Moment; dateTo: Moment }

export type EVENT = DATE_RANGE & {
  emitterType: TFieldAssetKeyTypes.TEmitterType
  irrigationZoneId: string
  irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
  externalEventId?: string
}

export type CONTINUOUS_IRRIGATION_EVENT = EVENT & {
  eventId: EVENT_ID
}

export type PULSE_IRRIGATION_EVENT = EVENT & {
  pulseEventId: EVENT_ID
  pulseIrrigationConfig: {
    onDuration: number
    offDuration: number
    numberOfCycles: number
  }
  pulseIrrigationEvents: {
    dateFrom: Moment
    dateTo: Moment
    eventId: string
    externalEventId?: string
    status: TScheduledEventEnum
  }[]
}

// Applied events currently have no concept of pulse/continuous
export type APPLIED_EVENT = EVENT & {
  status: string
}

export type SCHEDULED_PULSE_EVENT = PULSE_IRRIGATION_EVENT & {
  type: TScheduledEventEnum
}

export type SCHEDULED_CONTINUOUS_EVENT = CONTINUOUS_IRRIGATION_EVENT & {
  type: TScheduledEventEnum
}

export type NOTE_ID = string

export type NOTE = DATE_RANGE & {
  emitterType: TFieldAssetKeyTypes.TEmitterType
  id: NOTE_ID
  irrigationZoneId: string
  irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
  note: string
}

export enum TDateViewEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
}

export const NO_3RD_PARTY_IRRIGATION_VENDOR = 'NO_3RD_PARTY_IRRIGATION_VENDOR'

export type TIrrigationEventType = 'CONTINUOUS' | 'PULSE'

export type FormValuesCreate = {
  irrigationEventType: TIrrigationEventType
  irrigationZoneEmitterIds: TFieldAssetKeyTypes.TIrrigationZoneEmitterId[]
  startDate: Date | undefined
  durationHoursContinuous?: number
  durationMinutesContinuous?: number
  durationHoursPulseOn?: number
  durationMinutesPulseOn?: number
  durationHoursPulseOff?: number
  durationMinutesPulseOff?: number
  numberOfPulseIrrigationCycles?: number
  isRepeatSchedule: boolean
  repeatDays: number[] // array of day indexes
  repeatEndDate: Date | undefined
}

export type FormValuesEdit = {
  irrigationEventType: TIrrigationEventType
  irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
  startDate: Date
  durationHoursContinuous?: number
  durationMinutesContinuous?: number
  durationHoursPulseOn?: number
  durationMinutesPulseOn?: number
  durationHoursPulseOff?: number
  durationMinutesPulseOff?: number
  numberOfPulseIrrigationCycles?: number
}

export type TIrrigationSchedulerStoreState = {
  batchDelete: {
    visible: boolean
    events: {
      [eventId: string]:
        | (Omit<SCHEDULED_CONTINUOUS_EVENT, 'type'> & {
            type: TScheduledEventEnum.SCHEDULED | TScheduledEventEnum.DRAFT_CREATE
          })
        | (Omit<SCHEDULED_PULSE_EVENT, 'type'> & {
            type: TScheduledEventEnum.SCHEDULED | TScheduledEventEnum.DRAFT_CREATE
          })
    }
  }
  showPublishScheduleConfirmation: boolean
  showLauncher: boolean
  visible: boolean
  dateView: TDateViewEnum
  dateFrom: ISOString
  showPeakHours: boolean
  selectedIrrigationZoneEmitterIds: TFieldAssetKeyTypes.TIrrigationZoneEmitterId[]
  appliedEventsByIrrigationZoneEmitterId: {
    [irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId]: APPLIED_EVENT[]
  }
  debugPublishedEventsByIrrigationZoneEmitterId: {
    [irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId]: TPublishedIrrigationEvent[]
  }
  debugPublishedPulseIrrigationEventsByIrrigationZoneEmitterId: {
    [irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId]: TPublishedIrrigationEvent[]
  }
  scheduledEvents: Record<EVENT_ID, SCHEDULED_CONTINUOUS_EVENT>
  scheduledPulseIrrigationEvents: Record<EVENT_ID, SCHEDULED_PULSE_EVENT>
  stoppedIrrigationZoneEmitters: Record<string, ISOString>
  notes: Record<NOTE_ID, NOTE>
  noteEdit: null | { id: NOTE_ID }
  noteCreate: null | { irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId }
  eventCreate: null | { irrigationZoneEmitterId?: TFieldAssetKeyTypes.TIrrigationZoneEmitterId }
  eventPanel: null | { eventId: EVENT_ID }
  pulseEventPanel: null | { pulseEventId: EVENT_ID }
  selectedPropertyId: null | number
  mostRecentApiResponseTimestamps: Record<
    | 'irrigation-scheduler-publish'
    | 'irrigation-scheduler-zones-stop'
    | 'irrigation-scheduler-zones-resume'
    | 'irrigation-scheduler-events-stop'
    | 'irrigation-scheduler-events-publish-timeout',
    null | string
  >
  debug: {
    enableMockPeakHours: boolean
    enableMockNotes: boolean
    show3rdPartyPublishedEvents: boolean
    showTimeOnBars: boolean
  }
  hasPendingDeletionEvents: boolean
}

export const getInitialState = (): TIrrigationSchedulerStoreState => ({
  batchDelete: { visible: false, events: {} },
  showPublishScheduleConfirmation: false,
  showLauncher: false,
  visible: false,
  dateView: TDateViewEnum.WEEK,
  dateFrom: moment
    .tz(moment.tz.guess())
    .day(IRRIGATION_SCHEDULER_FIRST_DAY_OF_WEEK)
    .startOf('day')
    .toISOString(),
  showPeakHours: false,
  selectedIrrigationZoneEmitterIds: [],
  appliedEventsByIrrigationZoneEmitterId: {},
  debugPublishedEventsByIrrigationZoneEmitterId: {},
  debugPublishedPulseIrrigationEventsByIrrigationZoneEmitterId: {},
  scheduledEvents: {},
  scheduledPulseIrrigationEvents: {},
  stoppedIrrigationZoneEmitters: {},
  notes: {},
  noteEdit: null,
  noteCreate: null,
  eventCreate: null,
  eventPanel: null,
  pulseEventPanel: null,
  selectedPropertyId: null,
  mostRecentApiResponseTimestamps: {
    'irrigation-scheduler-publish': null,
    'irrigation-scheduler-zones-stop': null,
    'irrigation-scheduler-zones-resume': null,
    'irrigation-scheduler-events-stop': null,
    'irrigation-scheduler-events-publish-timeout': null,
  },
  debug: {
    enableMockPeakHours: false,
    enableMockNotes: false,
    show3rdPartyPublishedEvents: false,
    showTimeOnBars: false,
  },
  hasPendingDeletionEvents: false,
})

export const irrigationSchedulerStore = init<TIrrigationSchedulerStoreState>(getInitialState())
