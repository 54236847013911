import { Contact, Group, userDetailsStore } from 'stores/userDetailsStore'
import { ContactRow } from './ContactRow'
import { IconContact } from 'components/icons/IconContact'
import { IconGroups } from 'components/icons/IconGroups'
import { Dispatch, SetStateAction } from 'react'
import { translate } from 'i18n/i18n'
import { EmailIcon } from './EmailIcon'

type ContactListProps = {
  contacts: Contact[]
  groups: Group[]
  setContacts: Dispatch<SetStateAction<Contact[]>>
  setGroups: Dispatch<SetStateAction<Group[]>>
  notifyOwnerByEmail: boolean
  setNotifyOwnerByEmail: Dispatch<SetStateAction<boolean>>
  contactsIsValid: boolean
}

export const ICON_SIZE = 30

export const ContactList = ({
  contacts,
  groups,
  notifyOwnerByEmail,
  setNotifyOwnerByEmail,
  contactsIsValid,
}: ContactListProps) => {
  const { firstName, email: userEmail, lastName } = userDetailsStore.useSelector((s) => s)

  return (
    <div>
      {/** OWNER */}
      <ContactRow
        key="owner"
        name={`${translate.phrases.banyanApp('Me')} (${firstName} ${lastName})`}
        leftIcon={<IconContact />}
        isError={false}
        email={
          userEmail ? (
            <EmailIcon
              isEnabled={notifyOwnerByEmail}
              handleEmailIconClick={() => {
                setNotifyOwnerByEmail(!notifyOwnerByEmail)
              }}
            />
          ) : null
        }
      />
      {/** CONTACTS */}
      {contacts
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((contact) => {
          const { reportsContactMethods } = contact
          const isEmailEnabled = reportsContactMethods ? reportsContactMethods.includes('email') : false
          const isError = !contactsIsValid && !isEmailEnabled

          if (!isEmailEnabled) {
            return null
          }

          return (
            <ContactRow key={contact.id} name={contact.name} leftIcon={<IconContact />} isError={isError} />
          )
        })}
      {/** GROUPS */}
      {groups
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((group) => {
          return (
            <ContactRow
              key={group.id}
              name={`${group.name} (${group.contacts.length})`}
              leftIcon={<IconGroups />}
              isError={false}
            />
          )
        })}
    </div>
  )
}
