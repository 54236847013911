import { defaultValuesRowHeight } from '../../_utils'

export const getRowHeightForLabelCell = ({ label }: { label: string }) => {
  const charCount = label.length

  if (charCount < 30) return defaultValuesRowHeight

  if (charCount >= 30 && charCount < 40) return 90

  if (charCount >= 40 && charCount < 60) return 110

  if (charCount >= 60 && charCount < 80) return 140

  if (charCount >= 80 && charCount < 90) return 170

  return 200
}
