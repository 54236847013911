import { css } from '@emotion/css'
import { useMantineTheme } from '@mantine/core'
import { FC } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'

export const UserAvatar: FC<{
  name?: string
  backgroundColor?: string
  textColor?: string
  size?: number
  border?: string
}> = ({ name, backgroundColor, textColor, border, size = 36 }) => {
  const { firstName, lastName } = userDetailsStore.getState()
  const theme = useMantineTheme()

  let userInitials = ' '

  const nameToUse = name || `${(firstName || '').trim()} ${(lastName || '').trim()}`

  try {
    userInitials = nameToUse
      .replace(/-/g, ' ') // replace hyphens with spaces
      .replace(/\s\s+/g, ' ') // replace any superfluous spaces
      .split(' ') // separate it into an array for each word
      .map((n) => n[0].toUpperCase()) // take the uppercased first letter of each word
      .join('') // squish it back into a single string
      .slice(0, 3) // only take a maximum of three characters
  } catch (err) {}

  return (
    <div
      className={css`
        font-size: ${userInitials.length > 2 ? '12px' : '14px'};
        letter-spacing: 0.05em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${backgroundColor || 'white'};
        border-radius: 50%;
        width: ${size}px;
        height: ${size}px;
        font-size: ${size < 31 ? 15 : 16}px;
        font-weight: 700;
        color: ${textColor || theme.colors.midnight[0]};
        border: ${border || undefined};
      `}
    >
      {userInitials}
    </div>
  )
}
