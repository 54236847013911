import { TNodeStatus, TNodeType } from '../../types'
import moment from 'moment-timezone'
import { isExternalNode } from '../../utils/getNodeNetworkType'
import { translate } from 'i18n/i18n'
import * as Sentry from '@sentry/react'

// Time since last log for a node to be considered offline (in minutes)
function getTimeToOffline(nodeType: TNodeType): number {
  // TODO: Add different offline delays for each specific external node type
  if (isExternalNode(nodeType)) return 60

  switch (nodeType) {
    case 'dn_trp':
      return 180

    default:
      return 10
  }
}

export function getActiveNodeStatus(
  lastLog: { stamp: string; nodeType: TNodeType } | null | undefined,
): TNodeStatus {
  if (!lastLog) return TNodeStatus.PENDING

  const difference = Math.abs(moment.tz().diff(moment.utc(lastLog.stamp, 'YYYY-MM-DD HH:mm:ss')))
  const duration = moment.duration(difference)

  return duration.asMinutes() > getTimeToOffline(lastLog.nodeType) ? TNodeStatus.OFFLINE : TNodeStatus.ACTIVE
}

export const LOG_REFRESH_DELAY = 1000 * 60 * 5 // 5 minutes

export const NODE_STATUS_COLORS: { [key in TNodeStatus]: string } = {
  [TNodeStatus.ACTIVE]: '#29CD6B',
  [TNodeStatus.ERROR]: '#EB4C4C',
  [TNodeStatus.OFFLINE]: '#B4B3BB',
  [TNodeStatus.PENDING]: '#FFAB00',
  [TNodeStatus.PLANNED]: '#8C4CF6',
  [TNodeStatus.REMOVED]: '#000000',
}

export function getNodeStatusLabel(status: TNodeStatus): string {
  switch (status) {
    case TNodeStatus.ACTIVE:
      return translate.phrases.placeholder('Connected')

    case TNodeStatus.ERROR:
      return translate.phrases.placeholder('Error')

    case TNodeStatus.OFFLINE:
      return translate.phrases.placeholder('Offline')

    case TNodeStatus.PENDING:
      return translate.phrases.placeholder('Pending')

    case TNodeStatus.PLANNED:
      return translate.phrases.placeholder('Planned')

    case TNodeStatus.REMOVED:
      return translate.phrases.placeholder('Removed')

    default: {
      Sentry.captureException(new Error(`Node status: "${status}" has not been implemented`))

      return status
    }
  }
}
