import { useState, useEffect, useMemo } from 'react'
import { ErrorToast } from './ErrorToast'
import { SuccessToast } from './SuccessToast'

export const useToast = ({ durationMillisecond, type }: { durationMillisecond: number; type: string }) => {
  const [isToastShown, setIsToastShown] = useState<boolean>(false)
  const [text, setText] = useState<string>('')

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isToastShown) setIsToastShown(false)
    }, durationMillisecond)

    return () => clearTimeout(timer)
  }, [isToastShown])

  const showToast = (newText: string) => {
    setText(newText)

    setIsToastShown(true)
  }

  const handleClose = () => {
    setIsToastShown(false)
  }

  const getToast = () => {
    switch (type) {
      case 'error':
        return <ErrorToast showErrorNotification={isToastShown} errorText={text} onErrorClose={handleClose} />

      case 'success':
        return (
          <SuccessToast
            showSuccessNotification={isToastShown}
            successText={text}
            onSuccessClose={handleClose}
          />
        )

      default:
        return (
          <SuccessToast
            showSuccessNotification={isToastShown}
            successText={text}
            onSuccessClose={handleClose}
          />
        )
    }
  }

  const Toast = useMemo(() => getToast(), [isToastShown])

  return { showToast, Toast }
}
