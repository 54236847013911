import { ModalsProvider as MantineModalsProvider } from '@mantine/modals'
import { smallStore } from 'stores/smallStore'

export const ModalsProvider = (props: { children: React.ReactNode }) => {
  const showQRScannerModal = smallStore.useSelector((s) => s.showQRScannerModal)

  return (
    <MantineModalsProvider
      modalProps={{
        style: {
          visibility: showQRScannerModal ? 'hidden' : 'visible',
        },
        styles: {
          body: {
            padding:
              'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
          },
        },
      }}
    >
      <div css={{ width: '100%', height: '100%', visibility: showQRScannerModal ? 'hidden' : 'visible' }}>
        {props.children}
      </div>
    </MantineModalsProvider>
  )
}
