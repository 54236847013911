import { TAvailablePreset } from 'App/Map/types'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { colors } from 'settings/colors'
import { DuplicatePresetButton } from '../../DuplicatePresetButton/DuplicatePresetButton'
import { togglePreset } from '../../_utils/togglePreset'
import { DeletePresetButton } from './DeletePresetButton/DeletePresetButton'
import { EditPresetButton } from './EditPresetButton/EditPresetButton'

export const ItemRow = ({
  preset,
  duplicatePreset,
  editPreset,
}: {
  preset: TAvailablePreset
  duplicatePreset: (preset: TAvailablePreset) => void
  editPreset: (preset: TAvailablePreset) => void
}) => {
  const { idHash, ignored, name } = preset

  return (
    <div
      css={{
        width: '100%',
        display: 'flex',
        padding: '12px 4px',
        borderTop: `1px solid ${colors.grey200}`,
        borderBottom: `1px solid ${colors.grey200}`,
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <Checkbox
        checked={!ignored}
        label={<span css={{ fontWeight: 500 }}>{name}</span>}
        onChange={() => togglePreset({ idHash, newIgnored: !ignored, presetType: 'userDefined' })}
        size="md"
      />
      <div>
        <DeletePresetButton idHash={preset.idHash} />
        <DuplicatePresetButton duplicatePreset={duplicatePreset} preset={preset} />
        <EditPresetButton editPreset={editPreset} preset={preset} />
      </div>
    </div>
  )
}
