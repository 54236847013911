import { useEffect } from 'react'
import { MOVE_POINT_STATE } from '../useMoveTracker'
import { getMoveDirection } from './getMoveDirection'

export default function updateMovedPoint(
  setState: React.Dispatch<React.SetStateAction<MOVE_POINT_STATE>>,
  point: MOVE_POINT_STATE['point'],
  x1Start: MOVE_POINT_STATE['x1Start'],
  x2Start: MOVE_POINT_STATE['x2Start'],
  y1Start: MOVE_POINT_STATE['y1Start'],
  y2Start: MOVE_POINT_STATE['y2Start'],
  allowDragContentForTouch: boolean,
) {
  useEffect(() => {
    setState((s) => {
      if (!s.tracking) return s

      let changedPoint = point ?? 0
      let downPoint = s.downPoint ?? 0

      const pointMoved = changedPoint - downPoint
      const isMouse = s.pointerType === 'mouse'

      if (s.tracking === 'X2') {
        const minPoint = Math.max(s.minX2, s.x1 + 10) // TODO the 10. Make it an option like minWidth?
        const maxPoint = s.maxX2
        const oldPoint = s.x2
        const startPoint = x2Start
        const point = getPoint(startPoint + pointMoved, minPoint, maxPoint)

        // The scroll logic below is only applicable to touch, so if pointerType is mouse, we're done!
        if (isMouse || !allowDragContentForTouch) return { ...s, x2: point }

        const { point: newPoint, scrolled } = getPointAndScrolledForY2OrX2(
          s.pointerType,
          point,
          oldPoint,
          minPoint,
          maxPoint,
          s.scrolledStart,
          s.scrolled,
          downPoint,
          startPoint,
          changedPoint,
        )

        return { ...s, x2: newPoint, scrolled }
      } else if (s.tracking === 'X1') {
        const minPoint = s.minX1
        const maxPoint = s.maxX1
        const oldPoint = s.x1
        const startPoint = x1Start
        const point = getPoint(startPoint + pointMoved, minPoint, maxPoint)

        // The scroll logic below is only applicable to touch, so if pointerType is mouse, we're done!
        if (isMouse || !allowDragContentForTouch) return { ...s, x1: point }

        const { point: newPoint, scrolled } = getPointAndScrolledForY1OrX1(
          s.pointerType,
          point,
          oldPoint,
          minPoint,
          maxPoint,
          s.scrolledStart,
          s.scrolled,
          downPoint,
          startPoint,
          changedPoint,
        )

        return { ...s, x1: newPoint, scrolled }
      } else if (s.tracking === 'Y2') {
        const minPoint = Math.max(s.minY2, s.y1 + 10) // TODO the 10. Make it an option like minHeight?
        const maxPoint = s.maxY2
        const oldPoint = s.y2
        const startPoint = y2Start
        const point = getPoint(startPoint + pointMoved, minPoint, maxPoint)

        // The scroll logic below is only applicable to touch, so if pointerType is mouse, we're done!
        if (isMouse || !allowDragContentForTouch) return { ...s, y2: point }

        const { point: newPoint, scrolled } = getPointAndScrolledForY2OrX2(
          s.pointerType,
          point,
          oldPoint,
          minPoint,
          maxPoint,
          s.scrolledStart,
          s.scrolled,
          downPoint,
          startPoint,
          changedPoint,
        )

        return { ...s, y2: newPoint, scrolled }
      } else if (s.tracking === 'Y1') {
        const minPoint = s.minY1
        const maxPoint = Math.min(s.maxY1, s.y2 - 10) // TODO the 10. Make it an option like minHeight?
        const oldPoint = s.y1
        const startPoint = y1Start
        const point = getPoint(startPoint + pointMoved, minPoint, maxPoint)

        // The scroll logic below is only applicable to touch, so if pointerType is mouse, we're done!
        if (isMouse || !allowDragContentForTouch) return { ...s, y1: point }

        const { point: newPoint, scrolled } = getPointAndScrolledForY1OrX1(
          s.pointerType,
          point,
          oldPoint,
          minPoint,
          maxPoint,
          s.scrolledStart,
          s.scrolled,
          downPoint,
          startPoint,
          changedPoint,
        )

        return { ...s, y1: newPoint, scrolled }
      } else {
        return s
      }
    })
  }, [point, x1Start, x2Start, y1Start, y2Start, allowDragContentForTouch])
}

const getPoint = (point: number, minPoint: number, maxPoint: number): number => {
  if (point < minPoint) return minPoint

  if (point > maxPoint) return maxPoint

  return point
}

const getPointAndScrolledForY1OrX1 = (
  pointerType: MOVE_POINT_STATE['pointerType'],
  point: number,
  oldPoint: number,
  minPoint: number,
  maxPoint: number,
  scrolledStart: MOVE_POINT_STATE['scrolledStart'],
  oldScrolled: MOVE_POINT_STATE['scrolled'],
  downPoint: number,
  startPoint: number,
  changedPoint: number,
) => {
  // TODO: Simplify all this...

  let newPoint = point

  const previousMaxPanel = oldPoint === minPoint
  const startedWithScroll = scrolledStart > 0
  const moveDirection = getMoveDirection(point, oldPoint)
  const movingDownOrRightOrNotMoving = moveDirection === 'DOWN_OR_RIGHT' || moveDirection === undefined
  const isScrolled = oldScrolled > 0
  const notScrolled = oldScrolled === 0

  if (previousMaxPanel && startedWithScroll && movingDownOrRightOrNotMoving && isScrolled) {
    newPoint = minPoint
  }

  const pointAwayFromStartPoint = downPoint - startPoint

  let includeScrolledStartInScroll = true

  if (startedWithScroll && notScrolled) {
    includeScrolledStartInScroll = false

    newPoint = point - scrolledStart

    if (changedPoint > maxPoint + pointAwayFromStartPoint) {
      newPoint = Math.min(newPoint + changedPoint - maxPoint - pointAwayFromStartPoint, maxPoint)
    }
  }

  if (startedWithScroll && notScrolled && newPoint < minPoint) {
    includeScrolledStartInScroll = true

    newPoint = minPoint
  }

  const newPointAwayFromChangedPoint = changedPoint - newPoint

  const scrolled = Math.max(
    newPointAwayFromChangedPoint * -1 +
      pointAwayFromStartPoint +
      (includeScrolledStartInScroll ? scrolledStart : 0),
    0,
  )

  return { point: newPoint, scrolled }
}

const getPointAndScrolledForY2OrX2 = (
  pointerType: MOVE_POINT_STATE['pointerType'],
  point: number,
  oldPoint: number,
  minPoint: number,
  maxPoint: number,
  scrolledStart: MOVE_POINT_STATE['scrolledStart'],
  oldScrolled: MOVE_POINT_STATE['scrolled'],
  downPoint: number,
  startPoint: number,
  changedPoint: number,
) => {
  // TODO: Simplify all this...
  let newPoint = point

  const previousMaxPanel = oldPoint === maxPoint
  const startedWithScroll = scrolledStart > 0
  const moveDirection = getMoveDirection(point, oldPoint)
  const movingUpOrLeftOrNotMoving = moveDirection === 'UP_OR_LEFT' || moveDirection === undefined
  const isScrolled = oldScrolled > 0
  const notScrolled = oldScrolled === 0

  if (previousMaxPanel && startedWithScroll && movingUpOrLeftOrNotMoving && isScrolled) {
    newPoint = maxPoint
  }

  const pointAwayFromStartPoint = startPoint - downPoint

  let includeScrolledStartInScroll = true

  if (startedWithScroll && notScrolled) {
    includeScrolledStartInScroll = false

    newPoint = point + scrolledStart

    if (changedPoint < minPoint - pointAwayFromStartPoint) {
      newPoint = Math.max(newPoint + changedPoint - minPoint + pointAwayFromStartPoint, minPoint)
    }
  }

  if (startedWithScroll && notScrolled && newPoint > maxPoint) {
    includeScrolledStartInScroll = true

    newPoint = maxPoint
  }

  const newPointAwayFromChangedPoint = newPoint - changedPoint

  const scrolled = Math.max(
    newPointAwayFromChangedPoint * -1 +
      pointAwayFromStartPoint +
      (includeScrolledStartInScroll ? scrolledStart : 0),
    0,
  )

  return { point: newPoint, scrolled }
}
