import React, { useCallback, useEffect, useMemo } from 'react'
import * as Sentry from '@sentry/react'
import { ExtraProps } from './Panel/Panel'
import { getStuffFromSizes } from './Panel/useMovePoint/useMoveTracker'
import { TSelectedTab, detailsPanelStore } from 'stores/detailsPanelStore'
import { TabSelectionButton } from './TabSelectionButton'
import { useScreenSize } from 'utils/useScreenSize'
import { layersNavigationMenuStore } from 'stores/layersNavigationMenuStore'
import { CollapseButton } from 'components/SideNav/CollapseButton/CollapseButton'
import { smallStore } from '../../stores/smallStore'
import { TabPane } from '../DetailPanel/DetailPanel'

type PanelTabListProps = ExtraProps & {
  placement: 'right' | 'bottom'
  tabs: TabPane[]
}

export const PanelTabList: React.FC<PanelTabListProps> = (props) => {
  const { placement, tabs } = props
  const { isWideScreen } = useScreenSize()
  const { x1Min, x1Max, y1Min, y1Max } = getStuffFromSizes(props.sizes)
  const safeArea = smallStore.useSelector((s) => s.safeArea)

  const { isExpanded, x1ForClick, y1ForClick, top, left, width, height } = useMemo(() => {
    if (placement === 'right') {
      return {
        isExpanded: props.x1 < x1Max,
        x1ForClick: props.x1 < x1Max ? x1Max : x1Min,
        top: 32 + safeArea.insets.top,
        left: isWideScreen ? props.x1 - 100 : props.x1 - 60,
        width: isWideScreen ? 100 : 60,
        height: isWideScreen ? 100 : 60,
      }
    } else if (placement === 'bottom') {
      return {
        isExpanded: props.y1 < y1Max,
        y1ForClick: props.y1 < y1Max ? y1Max : y1Min,
        top: props.y1 - 55,
        left: 28,
        width: 72,
        height: 55,
      }
    } else {
      Sentry.captureException(new Error(`Placement: "${placement}" has not been implemented`))

      return {
        isExpanded: false,
        x1ForClick: 0,
        y1ForClick: 0,
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      }
    }
  }, [placement, isWideScreen, x1Min, x1Max, y1Min, y1Max, props.x1, props.y1])

  const handleOpenClosePanel = useCallback(() => {
    if (placement === 'right') {
      props.move({ x1: x1ForClick })
    } else if (placement === 'bottom') {
      props.move({ y1: y1ForClick })
    }
  }, [props.move, x1ForClick, y1ForClick])

  const currentSelectedTab = detailsPanelStore.useSelector((s) => s.detailsTab)
  const menuCollapsed = layersNavigationMenuStore.useSelector((s) => s.menuCollapsed)

  const handleTabButtonClick = (selection: TSelectedTab) => {
    if (currentSelectedTab === selection) {
      handleOpenClosePanel()
    } else {
      if (!isExpanded) handleOpenClosePanel()

      detailsPanelStore.setState((s) => ({ ...s, detailsTab: selection }))
    }
  }

  // This useEffect will close details panel when we open the layer menu
  useEffect(() => {
    if (!menuCollapsed && isExpanded && !isWideScreen) {
      handleOpenClosePanel()
    }
  }, [menuCollapsed])

  return (
    <>
      {isExpanded && !isWideScreen && (
        <div
          css={{
            position: 'fixed',
            height: placement === 'bottom' ? top : '100%',
            width: placement === 'right' ? left : '100%',
            left: 0,
            top: 0,
            overflow: 'hidden',
          }}
          onClick={handleOpenClosePanel}
        />
      )}
      {isWideScreen && placement === 'right' && (
        <CollapseButton
          menuCollapsed={!isExpanded}
          onToggleExpand={handleOpenClosePanel}
          otherStyleProps={{ top: props.y1 + 20, left: props.x1 - 140 }}
        />
      )}
      <div
        css={{
          position: 'fixed',
          top,
          zIndex: 2,
          left,
          transition: props.transition,
          display: 'flex',
          flexDirection: placement === 'right' ? 'column' : 'row',
        }}
      >
        {tabs.map(({ key, label, icon }) => (
          <TabSelectionButton
            key={key}
            width={width}
            height={height}
            handleClick={handleTabButtonClick}
            label={label}
            value={key}
            icon={icon}
            selected={isExpanded && currentSelectedTab === key}
            placement={placement}
          />
        ))}
      </div>
    </>
  )
}
