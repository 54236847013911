import React from 'react'
import { Text } from '@mantine/core'
import { closeModal, openModal } from '@mantine/modals'
import { translate } from 'i18n/i18n'
import { Button } from 'components/Button/Button'

const MODAL_ID = 'success'

export function showSuccess(title: string, message: React.ReactNode) {
  openModal({
    modalId: MODAL_ID,
    title,
    centered: true,
    children: (
      <div style={{ padding: '0 1rem 1rem' }}>
        <Text size="sm">{message}</Text>
        <Button onClick={() => closeModal(MODAL_ID)} mt="md" css={{ display: 'block', width: '100%' }}>
          {translate.phrases.placeholder('OK')}
        </Button>
      </div>
    ),
  })
}
