import { renderToStaticMarkup } from 'react-dom/server'
import svg64 from 'svg64'
import { getNodeEquipmentType } from './getNodeEquipmentType'
import { EQUIPMENT_TYPE_ICONS, EQUIPMENT_TYPE_INNER_ICONS } from './equipmentTypes'
import { TNodeStatus, TNodeType } from '../../types'
import { NODE_STATUS_COLORS } from './getActiveNodeStatus'
import { colors } from '../../../../settings/colors'
import { TEquipmentType, TNodeDeviceType } from '../../store/serviceCenterStore'
import { DEVICE_TYPE_ICONS } from './deviceTypes'

// Local cache for node markers icons
const NODE_MARKERS_CACHE: { [key: string]: google.maps.Icon } = {}
const EQUIPMENT_ICON_WIDTH = 36
const EQUIPMENT_ICON_HEIGHT = 36
const DEVICE_BANNER_OFFSET = [5, 6]
const DEVICE_ICON_OFFSET = [32, 4]
const DEVICE_ICON_WIDTH = 14
const DEVICE_ICON_SPACING = 3
const STROKE_WIDTH = 2

interface MarkerOptions {
  selected?: boolean
  showBubble?: boolean
  deviceTypes?: TNodeDeviceType[]
}

export function getNodeMapMarker(
  nodeType: TNodeType,
  status: TNodeStatus,
  options: MarkerOptions = {},
): google.maps.Icon {
  const { selected, showBubble, deviceTypes = [] } = options
  const cacheKey = `${nodeType}-${status}-${selected}-${showBubble}-${deviceTypes.join('-')}`

  // Return cached icon if already exists
  if (NODE_MARKERS_CACHE[cacheKey]) return NODE_MARKERS_CACHE[cacheKey]

  // Get corresponding equipment type for node type
  const equipmentType = getNodeEquipmentType(nodeType)

  if (!equipmentType) throw new Error(`Unsupported node type: ${nodeType}!`)

  // Create SVG icon for equipment type (color shape + inner icon)
  const equipmentIcon = buildEquipmentIconSVG(equipmentType, status)
  const strokeColor = selected ? 'yellow' : 'white'

  let bubble
  let bubbleOffset = [0, 0]

  // Custom bubble offset for specific equipment types
  if (equipmentType === 'repeater') {
    bubbleOffset = [-3, 2]
  }

  // Create red bubble SVG element, if needed
  if (showBubble) {
    bubble = `<g transform="translate(${bubbleOffset[0]} ${bubbleOffset[1]})" stroke-width="2">
      <circle cx="26" cy="6" r="6" fill="red" />
    </g>`
  }

  let deviceBanner

  const showDeviceIcons = !!deviceTypes?.length && selected

  // Build device banner SVG string if node has devices
  if (deviceTypes?.length) {
    deviceBanner = buildDeviceBannerSVG(equipmentType, deviceTypes, showDeviceIcons, strokeColor)
  }

  let width = 36
  let height = 36

  if (deviceTypes?.length) {
    // If node has devices, increase marker width to fit the banner
    if (showDeviceIcons) {
      width += deviceTypes.length * (DEVICE_ICON_WIDTH + DEVICE_ICON_SPACING) + 10
    } else {
      width += 20
    }
  }

  const viewBox = `-2 -2 ${width} ${height}`

  const svgString = `
    <svg width="${width}" height="${height}" viewBox="${viewBox}" xmlns="http://www.w3.org/2000/svg">
      ${deviceBanner}
    
      <g stroke="${strokeColor}">
        ${equipmentIcon}
        
        ${bubble}
      </g>
      
      </svg>`

  // Transform SVG to base64 url
  const base64Svg = svg64(svgString)
  const anchor = new google.maps.Point(EQUIPMENT_ICON_WIDTH / 2, EQUIPMENT_ICON_HEIGHT / 2)

  const image: google.maps.Icon = {
    url: base64Svg,
    size: new google.maps.Size(width, height),
    anchor,
  }

  // Cache marker icon
  NODE_MARKERS_CACHE[cacheKey] = image

  return image
}

function buildEquipmentIconSVG(equipmentType: TEquipmentType, status: TNodeStatus) {
  // Extract SVG content from the icon component
  const EquipmentOuterIcon = EQUIPMENT_TYPE_ICONS[equipmentType]
  const outerSvgElement = EquipmentOuterIcon().props.children
  const outerViewBoxString = EquipmentOuterIcon().props.viewBox
  const outerIconWidth = Number(outerViewBoxString.split(' ')[2])
  const outerIconHeight = Number(outerViewBoxString.split(' ')[3])
  const outerSvgContent = renderToStaticMarkup(outerSvgElement)
  const fillColor = NODE_STATUS_COLORS[status]

  let outerIconOffset = [0, 0]

  const outerIconSize = EQUIPMENT_ICON_WIDTH - STROKE_WIDTH * 2

  // Scale outer icon to fit in the marker
  let outerIconScale = outerIconSize / Math.max(outerIconWidth, outerIconHeight)

  // Custom outer icon size for specific equipment types
  if (equipmentType === 'gateway') {
    outerIconScale = 0.85

    outerIconOffset = [0, 4]
  }

  // Create inner icon SVG string
  const innerSvgContent = buildInnerIconSVG(equipmentType)

  return `
    <g color="${fillColor}" stroke-width="${STROKE_WIDTH}" transform="translate(${outerIconOffset[0]} ${outerIconOffset[1]}) scale(${outerIconScale} ${outerIconScale})">
      ${outerSvgContent}
      ${innerSvgContent}
    </g>`
}

function buildInnerIconSVG(equipmentType: TEquipmentType) {
  // Extract SVG content from the icon component
  const EquipmentInnerIcon = EQUIPMENT_TYPE_INNER_ICONS[equipmentType]

  if (!EquipmentInnerIcon) return ''

  let innerIconScale = 0.8

  // Custom icon scale for specific equipment types
  if (equipmentType === 'trap') innerIconScale = 0.5

  const innerSvgElement = EquipmentInnerIcon().props.children
  const innerViewBoxString = EquipmentInnerIcon().props.viewBox
  const innerIconWidth = Number(innerViewBoxString.split(' ')[2]) * innerIconScale
  const innerIconHeight = Number(innerViewBoxString.split(' ')[3]) * innerIconScale

  let translateX = EQUIPMENT_ICON_WIDTH / 2 - innerIconWidth / 2
  let translateY = EQUIPMENT_ICON_HEIGHT / 2 - innerIconHeight / 2

  // Custom icon offset for specific equipment types
  if (equipmentType === 'station') translateY -= 2
  else if (equipmentType === 'trap') {
    translateX += 3

    translateY += 2
  }

  return `
    <g
      color="${colors.midnight}"
      stroke="transparent"
      transform="translate(${translateX} ${translateY}) scale(${innerIconScale} ${innerIconScale})"
    >
      ${renderToStaticMarkup(innerSvgElement)}
    </g>`
}

function buildDeviceBannerSVG(
  equipmentType: TEquipmentType,
  nodeDeviceTypes: TNodeDeviceType[],
  showIcons?: boolean,
  strokeColor = 'white',
) {
  if (!nodeDeviceTypes?.length) return ''

  const bannerHeight = 20
  const bannerBackgroundColor = colors.midnight

  let translateX = DEVICE_BANNER_OFFSET[0]

  const translateY = DEVICE_BANNER_OFFSET[1]

  // Custom banner offset for specific equipment types
  if (equipmentType === 'repeater') translateX -= 4

  if (!showIcons) {
    const bannerWidth = 37

    // Show devices count in the banner
    return `
      <g transform="translate(${translateX} ${translateY})">
        <rect
          x="10" y="0"
          width="${bannerWidth}"
          height="${bannerHeight}"
          rx="${bannerHeight / 2}"
          fill="${bannerBackgroundColor}"
          stroke="${strokeColor}"
          stroke-width="${STROKE_WIDTH}"
         />
        <text
          x="${DEVICE_ICON_OFFSET[0]}" y="15" 
          class="small" 
          fill="white" 
          font-family="Helvetica" 
          font-weight="400" 
          font-size="16"
        >
          ${nodeDeviceTypes.length}
        </text>
      </g>`
  }

  // Retrieve device icons for each device type
  const deviceIcons = nodeDeviceTypes.map((deviceType) => DEVICE_TYPE_ICONS[deviceType])

  // Transform each icon to SVG string and group them together
  const deviceIconsSvg = deviceIcons
    .filter((deviceIcon) => !!deviceIcon)
    .map(transformIconToSVG)
    .join('')

  const bannerWidth = 27 + nodeDeviceTypes.length * (DEVICE_ICON_WIDTH + DEVICE_ICON_SPACING)

  return `
    <g transform="translate(${translateX} ${translateY})">
      <rect
        x="10" y="0"
        width="${bannerWidth}"
        height="${bannerHeight}"
        rx="${bannerHeight / 2}"
        fill="${bannerBackgroundColor}" 
        stroke="${strokeColor}"
        stroke-width="${STROKE_WIDTH}"
      />
      
      ${deviceIconsSvg}
    </g>`
}

function transformIconToSVG(Icon: () => JSX.Element, index: number): string {
  const iconElement = Icon().props.children
  const viewBoxString = Icon().props.viewBox
  const iconWidth = Number(viewBoxString.split(' ')[2])
  const iconHeight = Number(viewBoxString.split(' ')[3])
  // Scale each icon to fit in the banner
  const scale = DEVICE_ICON_WIDTH / Math.max(iconWidth, iconHeight)
  // Offset each icon so they line up horizontally one after the other
  const translateX = DEVICE_ICON_OFFSET[0] + index * (DEVICE_ICON_WIDTH + DEVICE_ICON_SPACING)

  let translateY = DEVICE_ICON_OFFSET[1]

  // Custom icon offset for specific devices
  if (Icon.name === 'IconWindMachine') translateY -= 2
  else if (Icon.name === 'IconLWS') translateY -= 1
  else if (Icon.name === 'IconSloshingWaterInWaterDrop') translateY -= 1
  else if (Icon.name === 'IconDendrometer') translateY += 2

  return `
    <g color="white" transform="translate(${translateX} ${translateY}) scale(${scale} ${scale})">
      ${renderToStaticMarkup(iconElement)}
    </g>`
}
