import React from 'react'
import { useMantineTheme } from '@mantine/core'

export function renderWithDefaultValue<T>(
  value: T,
  defaultValue = '-',
  suffix?: string,
): T | string | JSX.Element {
  if (typeof value === 'number') return value + (suffix ? ' ' + suffix : '')

  if (value) {
    if (typeof value === 'string') return value + (suffix ? ' ' + suffix : '')
    else
      return (
        <>
          {value}
          {suffix ? ' ' + suffix : ''}
        </>
      )
  }

  return defaultValue
}

// Values below the threshold will be shown in red
export function renderWithMinThreshold(
  threshold: number | undefined,
  underColor?: string,
  overColor?: string,
) {
  const theme = useMantineTheme()

  underColor = underColor || theme.colors.red[0]

  overColor = overColor || 'inherit'

  return function renderWithMinThreshold(value: number | undefined): React.ReactNode {
    if (!value) return '-'

    if (!threshold) return value

    return <span css={{ color: value > threshold ? overColor : underColor }}>{value}</span>
  }
}
