import { translate } from 'i18n/i18n'
import { TRGBAChartZoneColor, TRGBAColorWith1AtTheEnd, chartZoneColors, colors } from 'settings/colors'

const PlantWaterStressLegendItem = ({
  color,
  text,
}: {
  color: TRGBAColorWith1AtTheEnd | TRGBAChartZoneColor
  text: string
}) => {
  return (
    <div css={{ display: 'flex', gap: 5 }}>
      <div
        css={{
          width: 12,
          height: 12,
          borderRadius: 1,
          backgroundColor: color,
        }}
      />
      <div css={{ fontSize: 12 }}>{text}</div>
    </div>
  )
}

export const PlantWaterStressLegend = ({ isChart = false }: { isChart?: boolean }) => {
  const getColors = () => {
    let plantStressLow: TRGBAColorWith1AtTheEnd | TRGBAChartZoneColor = colors.plantStressLow
    let plantStressMedium: TRGBAColorWith1AtTheEnd | TRGBAChartZoneColor = colors.plantStressMedium
    let plantStressHigh: TRGBAColorWith1AtTheEnd | TRGBAChartZoneColor = colors.plantStressHigh

    if (isChart) {
      plantStressLow = chartZoneColors.plantStressZoneLow

      plantStressMedium = chartZoneColors.plantStressZoneMedium

      plantStressHigh = chartZoneColors.plantStressZoneHigh
    }

    return { plantStressLow, plantStressMedium, plantStressHigh }
  }

  const { plantStressLow, plantStressMedium, plantStressHigh } = getColors()

  return (
    <div css={{ display: 'flex', gap: 15 }}>
      <PlantWaterStressLegendItem color={plantStressLow} text={translate.phrases.banyanApp('Low')} />
      <PlantWaterStressLegendItem color={plantStressMedium} text={translate.phrases.banyanApp('Medium')} />
      <PlantWaterStressLegendItem color={plantStressHigh} text={translate.phrases.banyanApp('High')} />
    </div>
  )
}
