import { Contact, Group, GroupContact } from 'stores/userDetailsStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { WeeklyReport, WeeklyTrapReport } from '../types'
import { TReportStartEnd } from './formTypesAndStyles'
import { translate } from 'i18n/i18n'
import { defaultDates } from './defaultDates'

export const convertAPIAlertToInitivalValues = ({
  initialValues,
  reportType,
}: {
  reportType: string
  initialValues: WeeklyReport | WeeklyTrapReport | null
}) => {
  const propertiesObject = fieldAssetStore.getState().properties
  // note that blocks and repeatAnnually are not included, as they weren't used at the time of coding this
  const reportTypeId = (initialValues && initialValues.id) || null

  let reportProperties: TProperty[] = []

  if (propertiesObject) {
    Object.values(propertiesObject).map((property) => {
      return reportProperties.push(property)
    })
  }

  const hasSouthernHemisphereProperties = Object.values(reportProperties ?? {}).some(
    (p) => p.isSouthernHemisphere,
  )

  const hasNorthernHemisphereProperties = Object.values(reportProperties ?? {}).some(
    (p) => !p.isSouthernHemisphere,
  )

  const contacts: Contact[] =
    (initialValues && initialValues.contacts && initialValues.contacts.length && initialValues.contacts) || []

  const groups: Group[] =
    (initialValues &&
      initialValues.groups &&
      initialValues.groups.length &&
      initialValues.groups.map((g) => {
        let groupContacts: GroupContact[] = []

        g.contacts.forEach((c) => {
          const contact = groupContacts.find((contact) => contact.id === c.id)

          if (!contact) {
            groupContacts.push(c)
          }
        })

        return { ...g, contacts: groupContacts }
      })) ||
    []

  const dayOfWeek = (initialValues && initialValues?.rules?.dayOfWeek) || null
  const name = (initialValues && initialValues.name) || null
  const notifyOwnerByEmail = initialValues ? initialValues.notifyOwnerByEmail : true // should be true by default
  const rules = (initialValues && Array.isArray(initialValues.rules) && initialValues.rules) || []

  let pests = []
  let pestType = translate.phrases.banyanApp('Include all available pests with traps')

  if (
    initialValues &&
    initialValues.rules &&
    initialValues.rules.pests &&
    Array.isArray(initialValues?.rules?.pests) &&
    initialValues.rules.pests.length > 0 &&
    reportType === 'Weekly Trap Catches'
  ) {
    pestType = translate.phrases.banyanApp('Include the following selected pests')
  }

  let coverageType = 'all'

  if (
    initialValues &&
    initialValues?.rules &&
    initialValues?.rules?.properties &&
    Array.isArray(initialValues?.rules?.properties) &&
    initialValues?.rules?.properties?.length > 0 &&
    reportType === 'Weekly Trap Catches'
  ) {
    coverageType = 'properties'
  }

  if (
    initialValues &&
    initialValues.rules &&
    initialValues.rules.pests &&
    Array.isArray(initialValues.rules.pests)
  ) {
    pests =
      initialValues &&
      Array.isArray(initialValues.rules.pests) &&
      Object.assign(
        {},
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...initialValues.rules.pests.map((item: any) => ({
          [item.insect.id]: {
            insectId: item.insect.id,
            degreeDays: item.degreeDays,
            trapCatches: reportType === 'Weekly Trap Catches' ? true : item.trapCatches,
            endDate: item.endDate,
            startDate: item.startDate,
          },
        })),
      )
  }

  let trapCatchStartEnd =
    hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
      ? ({
          startDate: defaultDates.southernHemisphere.pestStartDate,
          endDate: defaultDates.southernHemisphere.pestEndDate,
        } as TReportStartEnd)
      : ({
          startDate: defaultDates.northernHemisphere.pestStartDate,
          endDate: defaultDates.northernHemisphere.pestEndDate,
        } as TReportStartEnd)

  if (
    initialValues &&
    initialValues?.rules &&
    'startDate' in initialValues.rules &&
    initialValues?.rules?.startDate &&
    initialValues?.rules?.endDate
  ) {
    trapCatchStartEnd = {
      startDate: initialValues?.rules?.startDate,
      endDate: initialValues?.rules?.endDate,
    }
  }

  let selectedProperties: TOptions[] = []
  let weather = false

  let weatherStartEnd =
    hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
      ? ({
          startDate: defaultDates.southernHemisphere.weatherStartDate,
          endDate: defaultDates.southernHemisphere.weatherEndDate,
        } as TReportStartEnd)
      : ({
          startDate: defaultDates.northernHemisphere.weatherStartDate,
          endDate: defaultDates.northernHemisphere.weatherEndDate,
        } as TReportStartEnd)

  if (
    initialValues?.rules &&
    'weather' in initialValues.rules &&
    initialValues?.rules?.weather !== undefined &&
    initialValues?.rules?.weather !== null
  ) {
    weather = true

    weatherStartEnd = {
      startDate: initialValues?.rules?.weather?.startDate,
      endDate: initialValues?.rules?.weather?.endDate,
    }
  }

  let fireBlight = false

  let fireBlightStartEnd =
    hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
      ? ({
          startDate: defaultDates.southernHemisphere.fireBlightStartDate,
          endDate: defaultDates.southernHemisphere.fireBlightEndDate,
        } as TReportStartEnd)
      : ({
          startDate: defaultDates.northernHemisphere.fireBlightStartDate,
          endDate: defaultDates.northernHemisphere.fireBlightEndDate,
        } as TReportStartEnd)

  if (
    initialValues?.rules &&
    'fireBlight' in initialValues.rules &&
    initialValues?.rules?.fireBlight !== undefined &&
    initialValues?.rules?.fireBlight !== null
  ) {
    fireBlight = true

    fireBlightStartEnd = {
      startDate: initialValues?.rules?.fireBlight?.startDate,
      endDate: initialValues?.rules?.fireBlight?.endDate,
    }
  }

  let evapotranspiration = false

  let evapotranspirationStartEnd =
    hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
      ? ({
          startDate: defaultDates.southernHemisphere.evapotranspirationStartDate,
          endDate: defaultDates.southernHemisphere.evapotranspirationEndDate,
        } as TReportStartEnd)
      : ({
          startDate: defaultDates.northernHemisphere.evapotranspirationStartDate,
          endDate: defaultDates.northernHemisphere.evapotranspirationEndDate,
        } as TReportStartEnd)

  if (
    initialValues?.rules &&
    'evapotranspiration' in initialValues.rules &&
    initialValues?.rules?.evapotranspiration !== undefined &&
    initialValues?.rules?.evapotranspiration !== null
  ) {
    evapotranspiration = true

    evapotranspirationStartEnd = {
      startDate: initialValues?.rules?.evapotranspiration?.startDate,
      endDate: initialValues?.rules?.evapotranspiration?.endDate,
    }
  }

  let irrigationActivity = false

  let irrigationActivityStartEnd =
    hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
      ? ({
          startDate: defaultDates.southernHemisphere.irrigationActivityStartDate,
          endDate: defaultDates.southernHemisphere.irrigationActivityEndDate,
        } as TReportStartEnd)
      : ({
          startDate: defaultDates.northernHemisphere.irrigationActivityStartDate,
          endDate: defaultDates.northernHemisphere.irrigationActivityEndDate,
        } as TReportStartEnd)

  if (
    initialValues?.rules &&
    'irrigationActivity' in initialValues.rules &&
    initialValues?.rules?.irrigationActivity !== undefined &&
    initialValues?.rules?.irrigationActivity !== null
  ) {
    irrigationActivity = true

    irrigationActivityStartEnd = {
      startDate: initialValues?.rules?.irrigationActivity?.startDate,
      endDate: initialValues?.rules?.irrigationActivity?.endDate,
    }
  }

  let soilMoisture = false

  let soilMoistureStartEnd =
    hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
      ? ({
          startDate: defaultDates.southernHemisphere.soilMoistureStartDate,
          endDate: defaultDates.southernHemisphere.soilMoistureEndDate,
        } as TReportStartEnd)
      : ({
          startDate: defaultDates.northernHemisphere.soilMoistureStartDate,
          endDate: defaultDates.northernHemisphere.soilMoistureEndDate,
        } as TReportStartEnd)

  if (
    initialValues?.rules &&
    'soilMoisture' in initialValues.rules &&
    initialValues?.rules?.soilMoisture !== undefined &&
    initialValues?.rules?.soilMoisture !== null
  ) {
    soilMoisture = true

    soilMoistureStartEnd = {
      startDate: initialValues?.rules?.soilMoisture?.startDate,
      endDate: initialValues?.rules?.soilMoisture?.endDate,
    }
  }

  let chill = false

  let chillStartEnd =
    hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
      ? ({
          startDate: defaultDates.southernHemisphere.chillStartDate,
          endDate: defaultDates.southernHemisphere.chillEndDate,
        } as TReportStartEnd)
      : ({
          startDate: defaultDates.northernHemisphere.chillStartDate,
          endDate: defaultDates.northernHemisphere.chillEndDate,
        } as TReportStartEnd)

  if (
    initialValues?.rules &&
    'chill' in initialValues.rules &&
    initialValues?.rules?.chill !== undefined &&
    initialValues?.rules?.chill !== null
  ) {
    chill = true

    chillStartEnd = {
      startDate: initialValues?.rules?.chill?.startDate,
      endDate: initialValues?.rules?.chill?.endDate,
    }
  }

  let pollination = false

  let pollinationStartEnd =
    hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
      ? ({
          startDate: defaultDates.southernHemisphere.pollinationStartDate,
          endDate: defaultDates.southernHemisphere.pollinationEndDate,
        } as TReportStartEnd)
      : ({
          startDate: defaultDates.northernHemisphere.pollinationStartDate,
          endDate: defaultDates.northernHemisphere.pollinationEndDate,
        } as TReportStartEnd)

  if (
    initialValues?.rules &&
    'pollination' in initialValues.rules &&
    initialValues?.rules?.pollination !== undefined &&
    initialValues?.rules?.pollination !== null
  ) {
    pollination = true

    pollinationStartEnd = {
      startDate: initialValues?.rules?.pollination?.startDate,
      endDate: initialValues?.rules?.pollination?.endDate,
    }
  }

  let growingDegreeDays = false

  let growingDegreeDaysStartEnd =
    hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
      ? ({
          startDate: defaultDates.southernHemisphere.growingDegreeDaysStartDate,
          endDate: defaultDates.southernHemisphere.growingDegreeDaysEndDate,
        } as TReportStartEnd)
      : ({
          startDate: defaultDates.northernHemisphere.growingDegreeDaysStartDate,
          endDate: defaultDates.northernHemisphere.growingDegreeDaysEndDate,
        } as TReportStartEnd)

  if (
    initialValues?.rules &&
    'growingDegreeDays' in initialValues.rules &&
    initialValues?.rules?.growingDegreeDays !== undefined &&
    initialValues?.rules?.growingDegreeDays !== null
  ) {
    growingDegreeDays = true

    growingDegreeDaysStartEnd = {
      startDate: initialValues?.rules?.growingDegreeDays?.startDate,
      endDate: initialValues?.rules?.growingDegreeDays?.endDate,
    }
  }

  if (initialValues?.rules?.properties && propertiesObject) {
    const targetIds = initialValues?.rules?.properties

    selectedProperties = Object.values(propertiesObject)
      .filter((property) => {
        // Check if the property's id is included in the targetIds array
        return targetIds?.some((targetId: { id: string }) => Number(targetId.id) === property.propertyId)
      })
      .map((property) => {
        return {
          label: property.propertyName,
          value: property.propertyId.toString(),
          id: `${property.propertyId}-PROPERTY`,
        }
      })
  }

  return {
    reportTypeId,
    selectedProperties,
    contacts,
    reportProperties,
    groups,
    name,
    notifyOwnerByEmail,
    rules,
    dayOfWeek,
    weather,
    weatherStartEnd,
    evapotranspiration,
    evapotranspirationStartEnd,
    irrigationActivity,
    irrigationActivityStartEnd,
    soilMoisture,
    soilMoistureStartEnd,
    chill,
    chillStartEnd,
    pollination,
    pollinationStartEnd,
    growingDegreeDays,
    growingDegreeDaysStartEnd,
    pests,
    pestType,
    coverageType,
    trapCatchStartEnd,
    fireBlight,
    fireBlightStartEnd,
  }
}

export default convertAPIAlertToInitivalValues
