import { ImperialXorMetric } from 'stores/fieldAssetStore'

export const getVolumeUnit = ({
  waterDepthUnit,
  rainUnitFromUserSetting,
}: {
  waterDepthUnit: 'INCH' | 'MILLIMETER' | undefined
  rainUnitFromUserSetting: ImperialXorMetric
}) => {
  if (waterDepthUnit) return waterDepthUnit

  return rainUnitFromUserSetting === 'IMPERIAL' ? 'INCH' : 'MILLIMETER'
}
