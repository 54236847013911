import { translate } from 'i18n/i18n'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { Group, Radio as R } from '@mantine/core'
import { ErrorTextWrapper } from '../../components/ErrorTextWrapper'
import { DatePickerInput } from '@mantine/dates'
import { TReportStartEnd } from '../../_utils/formTypesAndStyles'
import moment from 'moment-timezone'
import { colors } from 'settings/colors'
import { Radio } from 'components/Radio/Radio'
import { getDateFromReport } from '../../_utils/getDateFromReport'

export const ReportScheduling = ({
  setDayOfWeek,
  dayOfWeek,
  triedToSubmit,
  setScheduleIsValid,
  pestType,
  trapCatchStartEnd,
  setTrapCatchStartEnd,
  reportType,
}: {
  dayOfWeek: string | null
  setDayOfWeek: Dispatch<SetStateAction<string | null>>
  triedToSubmit: boolean
  setScheduleIsValid: Dispatch<SetStateAction<boolean>>
  pestType: string
  trapCatchStartEnd: TReportStartEnd | null
  setTrapCatchStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  reportType: string
}) => {
  let isValid = false
  let helpText = ''

  if (dayOfWeek) {
    isValid = true
  } else if (!dayOfWeek && triedToSubmit) {
    helpText = translate.phrases.banyanApp('Day of week must be selected.')

    isValid = false
  }

  useEffect(() => {
    setScheduleIsValid(isValid)
  }, [dayOfWeek, triedToSubmit])

  return (
    <div css={{ paddingBottom: '25px', borderBottom: `1px solid ${colors.grey200}` }}>
      <h4>{translate.phrases.banyanApp('Scheduling')}</h4>
      <div css={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
        <R.Group
          name="dayOfWeek"
          value={dayOfWeek ? dayOfWeek : undefined}
          onChange={(day) => setDayOfWeek(day)}
        >
          <p css={{ marginBottom: '20px', marginTop: '0px', fontSize: '12px' }}>
            {reportType === 'Weekly Trap Catches'
              ? translate.phrases.banyanApp(
                  'Report will show trap catches from the last three weeks at 4:15 PM, every',
                )
              : translate.phrases.banyanApp('Report will be sent at 6:15 PM on')}
          </p>
          <Group mt="xs">
            {/* values are intentionally not translated  */}
            {/* future TODO: change order of days */}
            <Radio value="Sunday" label={translate.dates.weekdayShortLabel('SUN')} />
            <Radio value="Monday" label={translate.dates.weekdayShortLabel('MON')} />
            <Radio value="Tuesday" label={translate.dates.weekdayShortLabel('TUE')} />
            <Radio value="Wednesday" label={translate.dates.weekdayShortLabel('WED')} />
            <Radio value="Thursday" label={translate.dates.weekdayShortLabel('THU')} />
            <Radio value="Friday" label={translate.dates.weekdayShortLabel('FRI')} />
            <Radio value="Saturday" label={translate.dates.weekdayShortLabel('SAT')} />
          </Group>
        </R.Group>
      </div>
      {pestType === translate.phrases.banyanApp('Include all available pests with traps') &&
      reportType !== translate.phrases.banyanApp('Weekly Update') ? (
        <div css={{ marginTop: '15px', display: 'flex' }}>
          <DatePickerInput
            valueFormat={translate.dates.getMomentFormat('MMM D')}
            clearable={false}
            placeholder={translate.phrases.banyanApp('Select start date')}
            monthLabelFormat={translate.dates.getMomentFormat('MMM')}
            value={getDateFromReport(trapCatchStartEnd?.startDate)}
            onChange={(e) =>
              setTrapCatchStartEnd((prevState) => ({
                ...prevState,
                startDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
              }))
            }
            css={{ maxWidth: '100%', width: '240px', marginRight: '15px' }}
          />

          <DatePickerInput
            valueFormat={translate.dates.getMomentFormat('MMM D')}
            clearable={false}
            placeholder={translate.phrases.banyanApp('Select end date')}
            value={getDateFromReport(trapCatchStartEnd?.endDate)}
            monthLabelFormat={translate.dates.getMomentFormat('MMM')}
            onChange={(e) =>
              setTrapCatchStartEnd((prevState) => ({
                ...prevState,
                endDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
              }))
            }
            css={{ maxWidth: '100%', width: '240px' }}
          />
        </div>
      ) : null}
      {helpText && <ErrorTextWrapper>{helpText}</ErrorTextWrapper>}
    </div>
  )
}
