import { routes } from '@semios/app-platform-banyan-route-definitions'
import {
  MAP_VISUAL,
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentPropertiesValueTypes,
} from 'stores/mapControlsStore/types'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateUsualStyleAPIArgs } from './_utils/generateUsualStyleAPIArgs'
import { getValueType } from './_utils/getValueType'
import { colors } from 'settings/colors'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { isEmpty, isNil } from '@semios/app-platform-common'
import { getHeatmapCSS } from './_utils/getHeatmapCSS'
import { getAlternariaColorForValueAndThreshold } from 'App/Map/_utils/getAlternariaColorForValueAndThreshold'
import { apiFetch } from 'utils/apiFetch'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { VC } from '@semios/app-platform-value-type-definitions'

const valueGroup = 'alternaria'
const heatmapExtremesForAllPropertiesInVisibleRegions = true

type TValueObjectMinMedMax =
  VC.DomainTypes.Alternaria.TCurrentValuesReturnIgnoringKeying['properties']['alternaria_todayMinMedMax_dsv']

type TValueObject =
  VC.DomainTypes.Alternaria.TCurrentValuesReturnIgnoringKeying['blocks']['alternaria_today_dsv']

const getHeatmapCSSForThreshold = (value: number, threshold: number): ReturnType<typeof getHeatmapCSS> => {
  return {
    color: colors.midnight,
    backgroundColor: getAlternariaColorForValueAndThreshold(value, threshold),
  }
}

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  return generateUsualStyleAPIArgs({
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
    },
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.SCD, valueGroup),
    },
    property: {
      valueType: getValueType(MAP_VISUAL.PROPERTY, valueGroup),
    },
  })
}

const getThresholdsForBlocks = async (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
) => {
  let blockIds = processedCaches.blockValues.itemsWithinView.map((block) => Number(block.id))

  if (isEmpty(blockIds)) {
    const propertyIds = processedCaches.propertyValues.itemsWithinView.map((property) => Number(property.id))
    const properties = fieldAssetStore.getState().properties

    blockIds = propertyIds.reduce<number[]>((acc, pid) => {
      const propertyBlocksObj = properties?.[pid]?.blocks || {}

      acc = acc.concat(Object.keys(propertyBlocksObj).map(Number))

      return acc
    }, [])
  }

  const response = await apiFetch<
    routes.FieldAssetSettingsGet.Request,
    routes.FieldAssetSettingsGet.Response
  >({
    url: routes.FieldAssetSettingsGet.path,
    body: {
      alternariaDSV: { blockIds },
    },
  })

  const { alternariaDSV } = response

  if (!alternariaDSV || 'error' in alternariaDSV) return {}

  const thresholdsByBlockId = alternariaDSV.reduce<Record<string, number>>((acc, { blockId, threshold }) => {
    acc[blockId] = threshold

    return acc
  }, {})

  return thresholdsByBlockId
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const currentValuesPromise = apiFetch<routes.ValuesCurrent.Request, routes.ValuesCurrent.Response>({
    url: routes.ValuesCurrent.path,
    body: args,
  })

  const thresholdsPromise = getThresholdsForBlocks(processedCaches)
  const [response, thresholdsByBlockId] = await Promise.all([currentValuesPromise, thresholdsPromise])
  const returner: TGetCacheUpdatesFromResponseReturn = {}
  const allValues: (number | null)[] = []

  if (!!processedCaches.blockValues.itemsWithinView.length) {
    const valueTypeForBlock = getValueType(MAP_VISUAL.BLOCK, valueGroup) as TValuesCurrentBlocksValueTypes
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.blockValues.itemsWithinView.flatMap((block) => {
      const valueObject = response?.blocks?.[block.id]?.[valueTypeForBlock] as TValueObject

      let value: number | null = null

      const threshold = thresholdsByBlockId[block.id]

      if (valueObject && 'value' in valueObject) {
        value = valueObject.value as number | null
      }

      if (isNil(value)) {
        return []
      }

      itemIdsWithinView.push(block.id)

      return {
        id: String(block.id),
        value: {
          [String(valueTypeForBlock)]: {
            children: unitConverter.alternaria(value, { decimalPlaces: 0 }).valueAsString(),
            onHover: true,
            getContainerCSS: () =>
              isNil(threshold) || isNil(value)
                ? getHeatmapCSS({ value })
                : getHeatmapCSSForThreshold(value, threshold),
          },
        },
      }
    })

    returner.blocks = {
      itemIdsWithinView,
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.blockCacheKey,
    }
  }

  const willShowBlocks = !!returner.blocks?.itemIdsWithinView.length
  const willShowHeatmapPoints = !!returner.heatmapPoints?.itemIdsWithinView.length
  const propertyIdsToValues: Record<TFieldAssetKeyTypes.TPropertyId, number | null> = {}
  const propertyIdsToThresholds: Record<TFieldAssetKeyTypes.TPropertyId, number | null> = {}
  const propertyIds = processedCaches.propertyValues.itemsWithinView.map((s) => Number(s.id))

  const valueTypeForProperty = getValueType(
    MAP_VISUAL.PROPERTY,
    valueGroup,
  ) as TValuesCurrentPropertiesValueTypes

  if (valueTypeForProperty) {
    propertyIds.forEach((propertyId) => {
      const valueObjectForProperty = response?.properties?.[propertyId]?.[valueTypeForProperty] as
        | TValueObject
        | TValueObjectMinMedMax

      let value = null

      if (valueObjectForProperty && 'value' in valueObjectForProperty) {
        value = valueObjectForProperty.value
      }

      if (valueObjectForProperty && 'max_value' in valueObjectForProperty) {
        value = valueObjectForProperty.max_value
      }

      if (typeof value !== 'string' && !Array.isArray(value)) {
        propertyIdsToValues[propertyId] = value
      }

      if (valueObjectForProperty && 'meta' in valueObjectForProperty) {
        const blockId = valueObjectForProperty.meta

        if (blockId) propertyIdsToThresholds[propertyId] = thresholdsByBlockId[blockId]
      } else {
        propertyIdsToThresholds[propertyId] = 7
      }
    })
  }

  const showValuesForPropertyRatherThanName =
    !isEmpty(propertyIdsToValues) && !willShowBlocks && !willShowHeatmapPoints

  const itemsWithinViewThatNowHaveValues = processedCaches.propertyValues.itemsWithinView.flatMap(
    (property) => {
      if (!showValuesForPropertyRatherThanName) {
        return {
          id: String(property.id),
          value: {
            [String(valueTypeForProperty)]: {
              children: property.meta.propertyName,
              onHover: false,
              getContainerCSS: () => ({ backgroundColor: colors.midnight, color: 'white' }),
              baseZIndex: undefined,
            },
          },
        }
      }

      const value = propertyIdsToValues[Number(property.id)]

      allValues.push(value)

      const threshold = propertyIdsToThresholds[Number(property.id)] ?? 7

      return {
        id: String(property.id),
        value: {
          [String(valueTypeForProperty)]: {
            children: unitConverter.alternaria(value, { decimalPlaces: 0 }).valueAsString(),
            onHover: true,
            getContainerCSS: () =>
              isNil(threshold) || isNil(value)
                ? getHeatmapCSS({ value })
                : getHeatmapCSSForThreshold(value, threshold),
            baseZIndex: isNil(value) ? -10 : undefined,
          },
        },
      }
    },
  )

  returner.properties = {
    itemsWithinViewThatNowHaveValues,
    cacheKey: cacheKeys.propertyCacheKey,
    itemIdsWithinView: processedCaches.propertyValues.itemIdsWithinView,
  }

  return returner
}
