import React, { FC, ReactNode } from 'react'
import { Text, useMantineTheme } from '@mantine/core'
import { CloseIcon } from 'components/ModalDrawer/CloseIcon/CloseIcon'
import { colors } from 'settings/colors'
import { useDisclosure } from '@mantine/hooks'
import { CSSObject } from '@emotion/react'

type Props = {
  title: string
  children: ReactNode
  onClose: () => void
  footer: ReactNode
  cardCSS?: CSSObject
}

export const Card: FC<Props> = ({ title, children, footer, onClose, cardCSS = {} }) => {
  const theme = useMantineTheme()
  const [expanded, { toggle: expandedToggle }] = useDisclosure(true)

  return (
    <div
      css={{
        backgroundColor: colors.grey50,
        zIndex: 100,
        borderRadius: 15,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 0,
        position: 'sticky',
        bottom: 0,
        width: '100vw',
        maxHeight: '100vh',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        ...cardCSS,
      }}
    >
      <div css={{ flex: '0 0 auto' }}>
        <button
          css={{
            cursor: 'pointer',
            border: 'none',
            backgroundColor: 'transparent',
            padding: 10,
            display: 'block',
            margin: '0 auto',
          }}
          onClick={expandedToggle}
        >
          <div css={{ backgroundColor: theme.colors.grey[1], borderRadius: 3, width: 30, height: 3 }} />
        </button>
        <div css={{ display: 'flex', flex: '0 0 auto' }}>
          <button
            css={{
              marginLeft: 5,
              width: 30,
              height: 30,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxSizing: 'content-box',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              border: 'none',
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </button>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: '1 1 auto',
              margin: '10px 47px 5px 0',
            }}
          >
            <Text color={colors.midnight} weight={700} size={18}>
              {title}
            </Text>
          </div>
        </div>
      </div>
      <div
        css={{
          overflowY: 'auto',
          display: expanded ? 'block' : 'none',
        }}
      >
        {children}
      </div>
      <div css={{ height: 1, backgroundColor: colors.grey200, margin: '0 10px' }}></div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          padding: 20,
          width: '100%',
          bottom: 0,
          marginBottom: 'env(safe-area-inset-bottom)',
        }}
      >
        {footer}
      </div>
    </div>
  )
}
