import { Point } from '@turf/helpers'
import { transformCoordsToLatLng } from 'App/ServiceCenter/Map/_utils/transformCoordsToLatLng'
import { MapCard } from 'components/MapCard/MapCard'
import React, { useMemo } from 'react'
import { translate } from 'i18n/i18n'
import { Alert, Badge, useMantineTheme } from '@mantine/core'
import { colors } from 'settings/colors'
import { DeviceList } from '../DeviceList/DeviceList'
import { Button } from 'components/Button/Button'
import { installationWorkflowStore } from '../../store/installationWorkflowStore'
import { IconWarning } from 'components/icons/IconWarning'
import { openNodeInstallationOverviewModal } from '../../NodeInstallation/InstallationOverviewModal'
import { TNodeStatus, TPlannedGateway, TPlannedNode } from 'App/ServiceCenter/types'
import { apiRequestQueueStore } from '../../utils/api/queue/apiRequestQueueStore'
import { getNodeStatusLabel, NODE_STATUS_COLORS } from '../../Map/_utils/getActiveNodeStatus'
import { getEquipmentTypeLabel } from '../../Map/_utils/equipmentTypes'
import { getNodeEquipmentType } from '../../Map/_utils/getNodeEquipmentType'
import { DirectionButton } from '../DirectionButton/DirectionButton'
import { openActionMenu } from '../../ActionMenu/ActionMenu'
import { getNodeActions } from '../../utils/getNodeActions'
import { PlannedNodeInstallMeta } from '../../utils/api/serviceCenterPlannedNodeInstall'
import { FailedRequestError } from '../FailedRequestError/FailedRequestError'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'EDIT_SSC_INSTALL_PLANNED' })

interface PlannedNodeDetailProps {
  node: TPlannedNode | TPlannedGateway
  onClose: () => void
}

export const PlannedNodeDetail: React.FC<PlannedNodeDetailProps> = ({ node, onClose }) => {
  const theme = useMantineTheme()
  const latLng = useMemo(() => transformCoordsToLatLng(JSON.parse(node.location) as Point), [node.location])
  const allFailedRequests = apiRequestQueueStore.useSelector(apiRequestQueueStore.selectors.getFailedRequests)

  const nodeFailedRequests = useMemo(
    () => allFailedRequests.filter((req) => (req.meta as PlannedNodeInstallMeta).pseudonodeId === +node.id),
    [allFailedRequests, node],
  )

  const unsyncedNodeIdentifiers = apiRequestQueueStore.useSelector(
    apiRequestQueueStore.selectors.getUnsyncedNodeIdentifiers,
  )

  const isUnsynced = useMemo(() => unsyncedNodeIdentifiers.includes(node.id), [unsyncedNodeIdentifiers, node])

  const handleStartInstallation = () => {
    installationWorkflowStore.actions.start()

    openNodeInstallationOverviewModal()
  }

  const nodeStatusText = getNodeStatusLabel(TNodeStatus.PLANNED)
  const nodeStatusColor = NODE_STATUS_COLORS[TNodeStatus.PLANNED]
  const equipmentType = !!node && getNodeEquipmentType(node.nodeType)

  let title = node.name || ''

  if (!node.name?.length) {
    title = translate.phrases.placeholder('Planned {{equipmentType}}', {
      equipmentType: equipmentType
        ? getEquipmentTypeLabel(equipmentType)
        : translate.phrases.placeholder('Node'),
    })
  }

  const handleOpenActionMenu = () => {
    const actions = getNodeActions(node)

    openActionMenu(actions)
  }

  return (
    <MapCard
      title={title}
      onClose={onClose}
      latLng={latLng}
      footer={
        <div css={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
            }}
          >
            <Button variant="tertiary" size="lg" css={{ flex: '1 1 50%' }} onClick={handleOpenActionMenu}>
              {translate.phrases.placeholder('More Options')}
            </Button>

            <Button
              variant="primary"
              disabled={!checkPermission()}
              css={{ marginLeft: 10, flex: '1 1 50%' }}
              onClick={() => handleStartInstallation()}
            >
              {translate.phrases.placeholder('Install')}
            </Button>
          </div>
          <DirectionButton latLng={latLng} />
        </div>
      }
    >
      {isUnsynced && (
        <Alert
          icon={
            <span css={{ color: colors.yellow }}>
              <IconWarning />
            </span>
          }
          variant="light"
          styles={{ root: { backgroundColor: 'rgb(255, 249, 219)' } }}
        >
          {translate.phrases.placeholder('This node has unsynced changes')}
        </Alert>
      )}

      {nodeFailedRequests?.map((request) => (
        <FailedRequestError key={request.id} request={request} />
      ))}

      <div css={{ padding: 20 }}>
        <div
          css={{
            margin: '0 0 10px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Badge
            css={{
              color: theme.colors.midnight,
              backgroundColor: nodeStatusColor,
              fontSize: '12px',
              fontWeight: 500,
              textTransform: 'none',
            }}
          >
            {nodeStatusText}
          </Badge>
        </div>

        {!!(node as TPlannedNode).devices && (
          <>
            <h4 css={{ marginBottom: 10, fontWeight: 500 }}>{translate.phrases.placeholder('Devices')}</h4>
            <DeviceList nodeDevices={(node as TPlannedNode).devices} />
          </>
        )}
      </div>
    </MapCard>
  )
}
