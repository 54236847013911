import { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { StackedChartSection, TValueTypeObject } from 'components/StackedChart/types'
import { lineChartTooltipFormatter } from 'components/StackedChart/_utils/lineChartTooltipFormatter/lineChartTooltipFormatter'
import { updateTooltipContents } from 'components/StackedChart/_utils/lineChartTooltipFormatter/updateTooltipContents'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { colors } from 'settings/colors'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { generateSeriesForEnvironmentalChart } from '../_utils/generateSeriesForEnvironmentalChart/generateSeriesForEnvironmentalChart'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { plotOptionsToHideInactiveSeriesHarderMaker } from '../_utils/plotOptionsToHideInactiveSeriesHarderMaker'
import { getWindCardinalDirection } from './getWindCardinalDirection'
import { EAggregationInterval } from 'App/Map/types'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { VV } from '@semios/app-platform-value-type-definitions'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_WEATHER_DETAILS' })
const pointCategory: TPointCategory = 'outOfBlockPoint'

const possibleValueTypesToRequest: VV.DomainTypes.Weather.TTimeseriesValueTypeKeysMerged[] = [
  'windDirection',
  'windSpeed',
]

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.wind || !selectedFieldAssets[pointCategory]) return {}

  const preferredAggregationInterval = {
    preferredAggregationInterval: chooseAmongstUnAggHourlyAndDaily(),
  }

  const valuesRequested: Partial<
    Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>
  > = {
    windDirection: preferredAggregationInterval,
    windSpeed: preferredAggregationInterval,
  }

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.windSpeed().categoryTitleWithoutUnit(),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
    id: 'stackem-wind',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const stationLngLat = String(selectedFieldAssetsStore.getState()[pointCategory])

  const series = generateSeriesForEnvironmentalChart({
    hasSensor: true,
    valueTypeObject: data.points?.[stationLngLat]?.values?.windSpeed?.[0] as TValueTypeObject,
    compareSeasonsValueTypeObject: compareSeasonsData.points?.[stationLngLat]?.values
      ?.windSpeed?.[0] as TValueTypeObject,
    unitConverter: unitConverter.windSpeed,
    color: colors.black,
  })

  const isDaily =
    data?.points?.[stationLngLat]?.values?.windSpeed?.[0]?.metadata?.aggregationInterval ===
    EAggregationInterval.DAILY

  const windDirectionDictionary: Record<number, number | null> = {}
  const windDirectionDictionaryCompareSeasons: Record<number, number | null> = {}

  data.points?.[stationLngLat]?.values?.windDirection?.[0]?.timeseries?.forEach((d) => {
    const epoch = +new Date(d.timestamp)
    const value = d.value === null || typeof d.value === 'number' ? d.value : d.value?.average

    windDirectionDictionary[epoch] = value
  })

  compareSeasonsData.points?.[stationLngLat]?.values?.windDirection?.[0]?.timeseries?.forEach((d) => {
    const epoch = +new Date(d.timestamp)
    const value = d.value === null || typeof d.value === 'number' ? d.value : d.value?.average

    windDirectionDictionaryCompareSeasons[epoch] = value
  })

  const firstForecastDate =
    data.points?.[stationLngLat]?.values?.windSpeed?.[0]?.metadata.forecastStartsAt || new Date()

  const firstForecastTimestamp = +new Date(firstForecastDate)

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp,
          },
          chart: {
            type: 'line',
          },
          plotOptions: plotOptionsToHideInactiveSeriesHarderMaker(),
          series,
          tooltip: {
            xDateFormat: getXDateFormat(isDaily),
            formatter: function (tooltip) {
              let tooltipContents = lineChartTooltipFormatter(this, tooltip, firstForecastTimestamp)

              if (isDaily) return tooltipContents

              return updateTooltipContents({
                seriesId: series[0].id,
                tooltipContents,
                fieldsToChange: {
                  valueSuffix: ({ content }) => {
                    const direction = windDirectionDictionary[Number(this.x)]

                    return isNil(content) || isNil(direction)
                      ? ''
                      : translate.phrases.banyanApp('{{windSpeed}} {{windDirection}}', {
                          windSpeed: content,
                          windDirection: getWindCardinalDirection(direction),
                        })
                  },
                  compareSeasonsValueSuffix: ({ content }) => {
                    const direction = windDirectionDictionaryCompareSeasons[Number(this.x)]

                    return isNil(content) || isNil(direction)
                      ? ''
                      : translate.phrases.banyanApp('{{windSpeed}} {{windDirection}}', {
                          windSpeed: content,
                          windDirection: getWindCardinalDirection(direction),
                        })
                  },
                },
              })
            },
          },
        },
      },
    ],
  }
}
