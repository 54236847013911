import { openModal, closeModal } from '@mantine/modals'
import { ErrorBoundary } from '@sentry/react'
import NodePositioningModal from 'App/ServiceCenter/NodePositioningModal'
import React, { useContext, useEffect, useState } from 'react'
import { translate } from 'i18n/i18n'
import { openNodeInstallationOverviewModal } from '../InstallationOverviewModal'
import { installationWorkflowStore } from 'App/ServiceCenter/store/installationWorkflowStore'
import { Button } from 'components/Button/Button'
import { Checkbox } from '@mantine/core'
import { CloseIcon } from 'components/ModalDrawer/CloseIcon/CloseIcon'
import { MapContext } from 'App/Map/MapContext/MapContext'

const MODAL_ID = 'drop-installation-overview'
const DIALOG_ID = 'drop-installation-warning'

export function showDropInstallWarning() {
  openModal({
    modalId: DIALOG_ID,
    fullScreen: false,
    withCloseButton: false,
    centered: true,
    title: null,
    padding: 0,
    children: (
      <ErrorBoundary>
        <div css={{ padding: '24px', position: 'relative' }}>
          <div css={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '16px' }}>
            <span onClick={() => closeModal(DIALOG_ID)}>
              <CloseIcon />
            </span>
            <span css={{ marginLeft: '16px', display: 'inline-block' }}>
              {translate.phrases.placeholder('Install New Equipment')}
            </span>
          </div>
          <div>
            {translate.phrases.placeholder(
              'This install method is reserved for equipment that is not already planned on your property map.',
            )}
            <br />
            <br />
            {translate.phrases.placeholder(
              'If you are trying to install equipment that has already been planned, select the equipment on the map and follow the instructions.',
            )}
          </div>
          <Checkbox
            size={'md'}
            onChange={(event) => {
              event.target.checked
                ? localStorage.setItem('hideDropInstallWarning', 'true')
                : localStorage.removeItem('hideDropInstallWarning')
            }}
            css={{ fontWeight: 'bold', marginTop: '16px' }}
            label={translate.phrases.placeholder('Don’t show this again')}
          />
        </div>
        <div
          css={{
            position: 'relative',
            bottom: 0,
            left: 0,
            backgroundColor: '#F8F8F8',
            borderTop: '2px solid #F8F8F8',
            width: '100%',
            padding: 10,
            paddingBottom: 'max(10px, env(safe-area-inset-bottom))',
            display: 'flex',
            justifyContent: 'space-between',
            zIndex: 10,
          }}
        >
          <Button
            variant="secondary"
            css={{ flex: '0 0 auto' }}
            onClick={() => {
              closeModal(DIALOG_ID)
            }}
          >
            {translate.phrases.placeholder('Cancel')}
          </Button>
          <Button
            variant="primary"
            css={{ marginLeft: 10, flex: '0 0 auto' }}
            onClick={() => {
              closeModal(DIALOG_ID)

              openDropInstallModal()
            }}
          >
            {translate.phrases.placeholder('Continue')}
          </Button>
        </div>
      </ErrorBoundary>
    ),
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

export function openDropInstallModal() {
  openModal({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: (
      <ErrorBoundary>
        <DropInstallModal />
      </ErrorBoundary>
    ),
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

const DropInstallModal: React.FC = () => {
  const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral | null>(null)
  const { map } = useContext(MapContext)
  const mapCenter = map?.getCenter()?.toJSON()
  const mapZoom = map?.getZoom()

  if (!mapCenter || !mapZoom) return null

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { coords } = position
      const { latitude, longitude } = coords

      setCurrentLocation({ lat: latitude, lng: longitude })
    })
  }, [])

  return (
    <NodePositioningModal
      onSubmit={(coordinates: google.maps.LatLngLiteral) => {
        if (!coordinates) return

        installationWorkflowStore.actions.setDropInstallLocation(coordinates)

        installationWorkflowStore.actions.start()

        openNodeInstallationOverviewModal()

        closeModal(MODAL_ID)
      }}
      onClose={() => {
        closeModal(MODAL_ID)
      }}
      mapDefaultCenter={currentLocation || mapCenter}
      mapDefaultZoom={mapZoom}
      headerTitle={translate.phrases.placeholder('Confirm Location')}
      showBanner
    />
  )
}

export default DropInstallModal
