import moment from 'moment-timezone'
import { TPossibleResponseType } from '../pressureSensors/_utils/getChartDataWithPressureAndActivity'
import { EnumIrrigationActivityStatus } from './irrigationActivitySettings'

export const getIntervalForCurrentStamp = ({
  currentTimeStamp,
  activityIntervals,
  timezone,
}: {
  currentTimeStamp: number
  activityIntervals: TPossibleResponseType
  timezone: string
}) => {
  const intervals = activityIntervals?.timeseries || []

  const match = intervals.find((i) => {
    return moment.tz(currentTimeStamp, timezone).isBetween(i.startTime, i.endTime, undefined, '[)')
  })

  const startDate = match?.startTime
  const start = startDate ? moment.tz(startDate, timezone) : null
  const end = match?.endTime ? moment.tz(match?.endTime, timezone) : null

  return { start, end, status: match?.status || EnumIrrigationActivityStatus.noData }
}
