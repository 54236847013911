import { TFieldAssetKeyTypes } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { lngLatStringToLngLatObject } from 'utils/lngLatStringToLngLatObject'
import { getArgsForAnchorCoordinates } from '../getArgsForAnchorCoordinates'

export type TSetByArg = {
  lngLat: TFieldAssetKeyTypes.TLngLat
}

export const setBy = ({ lngLat }: TSetByArg): TSelectedFieldAssetsStoreState | null => {
  const allProperties = fieldAssetStore.getState()?.properties

  const property = Object.values(allProperties ?? {}).find((propertyData) => {
    return !!propertyData?.points?.[lngLat]
  })

  if (!property || !property?.points?.[lngLat]) {
    return null
  }

  const anchorCoordinates = lngLatStringToLngLatObject(lngLat)

  return getArgsForAnchorCoordinates({ anchorCoordinates, property })
}
