import type { TAvailablePreset } from 'App/Map/types'
import moment from 'moment-timezone'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { getIsoWithoutTheZ } from 'utils/getIsoWithoutTheZ'

export const setDateFromAndDateTo = (preset: TAvailablePreset) => {
  detailsPanelStore.setState((s) => ({
    ...s,
    compareSeasonsInterval: 0, // temporary to fix bug in useApiValuesTimeseries
    dateFrom: getIsoWithoutTheZ(
      moment.tz(moment.tz.guess()).subtract(preset.dayOffsetDateFrom, 'days').startOf('day'),
    ),
    dateTo: getIsoWithoutTheZ(moment.tz(moment.tz.guess()).add(preset.dayOffsetDateTo, 'days').endOf('day')),
  }))
}
