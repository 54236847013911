import React from 'react'
import { translate } from 'i18n/i18n'
import { useMantineTheme } from '@mantine/core'
import moment from 'moment-timezone'
import { TDnNodeLog, TNodeType } from '../../../types'
import { renderWithDefaultValue, renderWithMinThreshold } from '../../../utils/renderers'
import { ToggleSymbolValue } from '../../../ToggleSymbolValue/ToggleSymbolValue'
import { SignalStrength } from './SignalStrength'
import { getActiveNodeStatus, NODE_STATUS_COLORS } from '../../../Map/_utils/getActiveNodeStatus'
import { BATTERY_THRESHOLDS } from '../../utils/thresholds'

interface DnNodeLogProps {
  log: TDnNodeLog | undefined
  nodeType: TNodeType
}

export const DnNodeLog: React.FC<DnNodeLogProps> = ({ log, nodeType }) => {
  const theme = useMantineTheme()

  const lastLogTimeAgoText = log ? (
    <ToggleSymbolValue value={moment.tz(log.stamp, moment.tz.guess()).format('lll z')}>
      {moment.tz(log.stamp, 'UTC').fromNow()}
    </ToggleSymbolValue>
  ) : (
    translate.phrases.placeholder('Never')
  )

  const nodeStatus = getActiveNodeStatus(log)
  const nodeStatusColor = NODE_STATUS_COLORS[nodeStatus]

  return (
    <dl
      css={{
        margin: 0,
        dt: {
          display: 'inline-block',
          width: '40%',
          lineHeight: 1.7,
          verticalAlign: 'top',
        },
        dd: {
          marginLeft: 0,
          display: 'inline-block',
          width: '60%',
          lineHeight: 1.7,
        },
      }}
    >
      <div className="row">
        <dt>{translate.phrases.placeholder('Last Log')}</dt>
        <dd css={{ color: nodeStatusColor, fontWeight: 500 }}>
          {renderWithDefaultValue(lastLogTimeAgoText)}
        </dd>
      </div>
      {!!log && (
        <>
          <div className="row">
            <dt>{translate.phrases.placeholder('Signal Strength')}</dt>
            <dd>
              <ToggleSymbolValue value={`${log.rssi} dBm`}>
                <SignalStrength signal={log.rssi} />
              </ToggleSymbolValue>
            </dd>
          </div>
          <div className="row">
            <dt>{translate.phrases.placeholder('Battery')}</dt>
            <dd css={{ fontWeight: 500 }}>
              {renderWithMinThreshold(
                BATTERY_THRESHOLDS[nodeType],
                theme.colors.red[0],
                theme.colors.green[0],
              )(log.battery)}
            </dd>
          </div>
        </>
      )}
    </dl>
  )
}
