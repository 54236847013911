import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'

const { saturatedZone, idealZone, moderateDeficitZone, highDeficitZone, extremeDeficitZone } = colors
const heatmapColors = [extremeDeficitZone, highDeficitZone, moderateDeficitZone, idealZone, saturatedZone]

export const SoilHeatmapRow = () => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div css={{ width: '100%', background: colors.grey50, borderRadius: 3, display: 'flex', padding: 10 }}>
        {heatmapColors.map((item) => (
          <div key={item} css={{ flex: 1, height: 12, background: item }} />
        ))}
      </div>
      <div
        css={{
          marginTop: 6,
          fontSize: 12,
          color: colors.white,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>{translate.phrases.banyanApp('Extreme Deficit')}</div>
        <div>{translate.phrases.banyanApp('Saturated')}</div>
      </div>
    </div>
  )
}
