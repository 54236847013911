import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { getTimezoneForSelectedProperty } from './getTimezoneForSelectedProperty'

export const useTimezoneForSelectedProperty = () => {
  const properties = fieldAssetStore.useSelector((s) => s?.properties)
  const propertyId = selectedFieldAssetsStore.useSelector((s) => s.property)

  return getTimezoneForSelectedProperty({ properties, propertyId })
}
