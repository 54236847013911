import { routes } from '@semios/app-platform-banyan-route-definitions'
import { GalleryPhoto, Photo } from '@capacitor/camera'
import { apiFetch } from 'utils/apiFetch'
import { readPhoto } from '../../../../utils/readPhoto'
import { ApiRequestQueue, ApiRequestType, MetaWithNodeIdentifier } from './queue'

export type PreUploadImageMeta = (Photo | GalleryPhoto) & {
  type: routes.ServiceCenterImagePreUpload.ImageTypes
  linkedRequestId?: string
} & MetaWithNodeIdentifier

export type PreUploadImageResult = { uploadUrl: string; imageUrl: string }

const request = async (meta: PreUploadImageMeta): Promise<PreUploadImageResult> => {
  const { filename, size } = await readPhoto(meta)

  // Get the pre-signed upload url
  const fileUploadUrls = await apiFetch<
    routes.ServiceCenterImagePreUpload.Request,
    routes.ServiceCenterImagePreUpload.Response
  >({
    url: routes.ServiceCenterImagePreUpload.path,
    body: {
      contentType: `image/${meta.format}`,
      files: [
        {
          filename,
          size,
        },
      ],
      type: meta.type,
    },
    params: {
      method: 'POST',
    },
  })

  return fileUploadUrls[0]
}

const callback = async ({ uploadUrl, imageUrl }: PreUploadImageResult, meta: PreUploadImageMeta) => {
  // Queue another request with the pre-signed url (uploadUrl) for the actual upload to S3
  await ApiRequestQueue.createRequest(
    ApiRequestType.UPLOAD_IMAGE,
    {
      ...meta,
      uploadUrl,
      imageUrl,
    },
    { wait: false },
  )
}

export const serviceCenterImagePreUpload = {
  request,
  callback,
}
