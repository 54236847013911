import { translate } from 'i18n/i18n'
import { InfoPopover } from '../../InfoPopover'
import { fieldAssetStore } from 'stores/fieldAssetStore'

export const CoverageColumn = ({ propertyIds }: { propertyIds: number[] }) => {
  const { properties } = fieldAssetStore.useSelector((state) => state)

  // handle when user has all properties
  if (!propertyIds || !propertyIds.length)
    return <div css={{ fontWeight: '700' }}>{translate.phrases.banyanApp('All Properties')}</div>

  // or just one property, show its name
  if (propertyIds.length === 1) {
    let foundProperty = properties && properties[propertyIds[0]] && properties[propertyIds[0]].propertyName

    if (foundProperty) return <div css={{ fontWeight: '700' }}>{foundProperty}</div>

    return (
      <div css={{ fontWeight: '700' }}>
        {translate.phrases.banyanApp('{{count}} Property', { count: propertyIds.length })}
      </div>
    )
  }

  // handle when user has a subset of properties
  return (
    <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div css={{ fontWeight: '700' }}>
        {translate.phrases.banyanApp('{{count}} Properties', { count: propertyIds.length })}
      </div>
      <InfoPopover
        content={
          <>
            <p css={{ margin: 0, fontWeight: 'bold' }}>
              {translate.phrases.banyanApp('{{count}} Property', { count: propertyIds.length })}
            </p>
            <ul css={{ paddingLeft: 25 }}>
              {propertyIds.map((pId, i) => {
                let foundProperty = properties && properties[pId] && properties[pId].propertyName

                if (foundProperty) return <li key={i}>{foundProperty}</li>

                return null
              })}
            </ul>
          </>
        }
        width={250}
      />
    </div>
  )
}
