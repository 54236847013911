import { css } from '@emotion/css'
import { useMantineTheme } from '@mantine/core'
import { FC } from 'react'
import { SettingItemProps } from '../../types'

export const SettingItem: FC<SettingItemProps> = ({ tabKey, setCurrentTab, isSelected, label }) => {
  const theme = useMantineTheme()

  return (
    <div
      className={css`
        cursor: pointer;
        margin: 0 0 0 15px;
        height: 45px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        border-radius: 3px 0 0 3px;
        &:hover {
          background-color: ${theme.colors.grey[0]};
        }
        background-color: ${isSelected ? theme.colors.grey[0] : 'white'};
        color: ${theme.colors.midnight[0]};
        font-size: 16px;
        font-weight: ${isSelected ? '700' : '500'};
      `}
      onClick={() => {
        setCurrentTab(tabKey)
      }}
    >
      {label}
    </div>
  )
}
