import { MapBase } from 'App/Map/MapContext/MapBase'
import { LOG_REFRESH_DELAY } from 'App/ServiceCenter/Map/_utils/getActiveNodeStatus'
import { SelectedEquipmentCard } from 'App/ServiceCenter/SelectedEquipmentCard/SelectedEquipmentCard'
import { loadMapData, loadMapDataInOffline, loadStartupData } from 'App/ServiceCenter/utils/loadStartupData'
import { showError } from 'App/ServiceCenter/utils/showError'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { serviceCenterStore } from '../store/serviceCenterStore'
import { MapContext } from '../../Map/MapContext/MapContext'
import { BlockLayer } from './Layers/BlockLayer'
import { PlannedEquipmentLayer } from './Layers/PlannedEquipment'
import { ActiveEquipmentLayer } from './Layers/ActiveEquipment'
import { TFieldAssetValueTypes } from '@semios/app-platform-banyan-route-definitions/dist/routes/userAppStartup'
import { TNodeStatus } from '../types'
import { selectedFieldAssetsStore } from '../../../stores/selectedFieldAssetsStore'
import { OfflineContentDownloadBanner } from '../OfflineContentDownload/OfflineContentDownloadBanner'
import { networkStore } from '../store/networkStore'

export const ServiceCenterMap = (props: {
  hasAppStartupData: boolean // TODO: Move...
}) => {
  const { map, setLoadingData, loadingData } = useContext(MapContext)
  const refreshTimeout = useRef<ReturnType<typeof setTimeout>>()
  const selectedPropertyIds = selectedFieldAssetsStore.useSelector((s) => (s.property ? [s.property] : []))
  const connectionStatus = networkStore.useSelector(networkStore.selectors.getConnectionStatus)

  useEffect(() => {
    async function fetchData() {
      try {
        // Load Service Center additional data
        await loadStartupData()
      } catch (error) {
        showError(translate.phrases.placeholder('Server Error'), (error as Error).message)
      }
    }

    fetchData()
  }, [])

  const refreshMapData = useCallback(async () => {
    try {
      if (refreshTimeout.current) window.clearTimeout(refreshTimeout.current)

      // Only fetch map data if there are selected properties
      if (selectedPropertyIds.length) {
        if (connectionStatus.connected) {
          await loadMapData(selectedPropertyIds)
        } else {
          await loadMapDataInOffline(selectedPropertyIds)
        }
      }

      // eslint-disable-next-line no-console
      console.log(
        `Next map refresh @ ${moment.tz().add(LOG_REFRESH_DELAY, 'milliseconds').local().format('HH:mm:ss')}`,
      )

      refreshTimeout.current = setTimeout(refreshMapData, LOG_REFRESH_DELAY)
    } catch (error) {
      showError(translate.phrases.placeholder('Server Error'), (error as Error).message)
    }
  }, [selectedPropertyIds])

  useEffect(() => {
    setLoadingData(true)

    refreshMapData().then(() => setLoadingData(false))

    return function cleanup() {
      if (refreshTimeout.current) window.clearTimeout(refreshTimeout.current)

      serviceCenterStore.actions.unselectEquipment()
    }
  }, [selectedPropertyIds])

  const properties = fieldAssetStore.useSelector((s) => s?.properties)

  const mapEquipmentStatuses = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getMapEquipmentStatuses,
  )

  const plannedNodes = serviceCenterStore.useSelector(serviceCenterStore.selectors.getPlannedNodes)
  const activeNodes = serviceCenterStore.useSelector(serviceCenterStore.selectors.getActiveNodes)

  const blocks = useMemo(() => {
    let propertiesBlocks: TFieldAssetValueTypes.TBlock[] = []

    selectedPropertyIds.forEach((propertyId) => {
      const property = properties?.[propertyId]

      if (!property?.blocks) return

      propertiesBlocks = propertiesBlocks.concat(Object.values(property.blocks))
    })

    return propertiesBlocks
  }, [selectedPropertyIds, properties])

  const showPlannedNodes = !mapEquipmentStatuses.length || mapEquipmentStatuses.includes(TNodeStatus.PLANNED)

  return (
    <MapBase
      enableCurrentLocation={true}
      hasAppStartupData={props.hasAppStartupData}
      loadingData={loadingData}
      onInit={undefined}
    >
      {map ? (
        <>
          <BlockLayer blocks={blocks} />
          {showPlannedNodes && <PlannedEquipmentLayer plannedNodes={plannedNodes} />}
          <ActiveEquipmentLayer activeNodes={activeNodes} />
          <SelectedEquipmentCard />
        </>
      ) : null}
      <OfflineContentDownloadBanner />
    </MapBase>
  )
}
