import { ColorType } from 'highcharts'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { helperTagsMaker } from './helperTagsMaker'

const minAverageMaxWrapper = ({
  aggregationTitle,
  hasCompareSeasons,
  value,
  compareValue,
  helperTag,
  helperTagCompareSeasons,
}: {
  aggregationTitle: string
  hasCompareSeasons: boolean
  value?: number | string | null
  compareValue?: number | string | null
  helperTag: { opening: string; closing: string }
  helperTagCompareSeasons: { opening: string; closing: string }
}) => {
  return `
    <div style="text-align:right;flex:1;padding:3px;display:flex;flex-direction:column;justify-content:center;align-items:flex-end;">
      <div style="font-size:0.7em;text-align:left;">${aggregationTitle}</div>
      <div style="font-size:0.9em;text-align:right"><b>${helperTag.opening}${value ?? ''}${
    helperTag.closing
  }</b></div>
      ${
        hasCompareSeasons
          ? `<div style="font-size:0.9em;text-align:right"><b>${helperTagCompareSeasons.opening}${
              compareValue ?? translate.phrases.templates('-')
            }${helperTagCompareSeasons.closing}</b></div>`
          : ''
      }
    </div>
  `
}

const minAverageMaxRow = (o: {
  compareSeasonsYear?: string | null
  hasValueInRegularSeason: boolean
  seriesColor?: ColorType
  seriesName: string
  compareSeasonsSeriesColor?: string
  xYear?: string | null
  minCell?: string
  avgCell?: string
  maxCell?: string
  suffixCell?: string
}) => {
  return `
  <div style="display:flex;align-items:center;">
  ${
    o.compareSeasonsYear
      ? `<div style="font-size:14px;display:flex;flex-direction:column;margin:0 5px;">
          <div style="margin-top:15px;font-weight:bold;color:${o.seriesColor}">
            ${o.xYear}
          </div>
          <div style="font-weight:bold;color:${o.compareSeasonsSeriesColor}">
            ${o.compareSeasonsYear}
          </div>
        </div>
        `
      : ''
  }
    <div style="display:flex;justify-content:center;align-items:center">
      ${o.minCell}
      ${o.avgCell}
      ${o.maxCell}
      ${o.suffixCell}
    </div>
  </div>
  `
}

export const minAverageMaxCellMaker = (o: {
  low?: string | null
  high?: string | null
  value?: string | null
  seriesColor?: ColorType
  seriesName: string
  seriesId: string
  paddingRight?: boolean
  xYear?: string | null
  valueSuffix?: string
  compareSeasons?: {
    year?: string | null
    low?: string | null
    high?: string | null
    value?: string | null
    seriesColor?: string
    valueSuffix?: string
  }
}) => {
  const { seriesId, value, valueSuffix } = o

  const hasHighAndLow =
    (!isNil(o.low) && !isNil(o.high)) || (!isNil(o.compareSeasons?.low) && !isNil(o.compareSeasons?.high))

  // this isn't a particularly important notion globally, so let's just use a somewhat arbitrary breakpoint
  const tooltipIsOnASmallishScreen = window.innerWidth < 500

  let paddingRight = '0'

  if (o.paddingRight) {
    if (!tooltipIsOnASmallishScreen) {
      paddingRight = '40px'
    } else {
      paddingRight = '6px'
    }
  }

  let template = `
  <td>
    <div style="background:${o.seriesColor}; min-width: 14px; min-height: 5px; margin: 0 7px 0 0;"></div>
  </td>
  <td style="text-align: left"><div style="width:max-content">${o.seriesName}:</div></td>
  <td style="text-align: right; padding: 0 ${paddingRight} 0 ${tooltipIsOnASmallishScreen ? 0 : 5}px;">
  `

  if (!hasHighAndLow) {
    const helperTags = helperTagsMaker({ seriesId })

    template += `
      ${
        o.compareSeasons?.year
          ? `
            <div style="display:flex;">
              <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;margin:4px 15px 4px 4px;font-size:14px">
              <div style="color:${o.seriesColor};">
                <b>${o.xYear ? ` ${o.xYear}` : ''}</b>
              </div>
              <div style="color:${o.compareSeasons?.seriesColor};">
                  <b>${o.compareSeasons.year ?? ''}</b>
                </div>
              </div>
              <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;">
              <div style="text-align: left;"width:max-content>
                <b>${helperTags.value.opening}${value ?? translate.phrases.templates('-')}${
              helperTags.value.closing
              // TODO: i18n this value === '-' check
            } ${helperTags.valueSuffix.opening}${isNil(value) || value === '-' ? '' : valueSuffix}${
              helperTags.valueSuffix.closing
            }</b
              </div>
              <div style="text-align: left;width:max-content">
                <b>${helperTags.compareSeasonsValue.opening}${
              o.compareSeasons?.value ?? translate.phrases.templates('-')
            }${helperTags.compareSeasonsValue.closing} ${helperTags.compareSeasonsValueSuffix.opening}${
              // TODO: i18n this value === '-' check
              isNil(o.compareSeasons?.value) || o.compareSeasons?.value === '-'
                ? ''
                : o.compareSeasons?.valueSuffix ?? o.valueSuffix
            }${helperTags.compareSeasonsValueSuffix.closing}</b<
                </div>
              </div>
            </div>
            `
          : `
            <div style="display: flex; justify-content: flex-start;">
              <b>${helperTags.value.opening}${o.value ?? translate.phrases.templates('-')}${
              helperTags.value.closing
            } ${helperTags.valueSuffix.opening}${o.valueSuffix}${helperTags.valueSuffix.closing}</b>
            </div>
            `
      }
      </td>`

    return template
  }

  const helperTags = helperTagsMaker({ seriesId })

  const minCell = minAverageMaxWrapper({
    aggregationTitle: translate.phrases.abbreviations('Minimum'),
    hasCompareSeasons: !!o.compareSeasons?.year,
    value: o.low?.toString(),
    compareValue: o.compareSeasons?.low?.toString(),
    helperTag: helperTags.valueMin,
    helperTagCompareSeasons: helperTags.compareSeasonsValueMin,
  })

  const avgCell = minAverageMaxWrapper({
    aggregationTitle: translate.phrases.abbreviations('Average'),
    hasCompareSeasons: !!o.compareSeasons?.year,
    value: o.value?.toString(),
    compareValue: o.compareSeasons?.value?.toString(),
    helperTag: helperTags.valueAverage,
    helperTagCompareSeasons: helperTags.compareSeasonsValueAverage,
  })

  const maxCell = minAverageMaxWrapper({
    aggregationTitle: translate.phrases.abbreviations('Maximum'),
    hasCompareSeasons: !!o.compareSeasons?.year,
    value: o.high?.toString(),
    compareValue: o.compareSeasons?.high?.toString(),
    helperTag: helperTags.valueMax,
    helperTagCompareSeasons: helperTags.compareSeasonsValueMax,
  })

  const suffixCell = minAverageMaxWrapper({
    aggregationTitle: '&nbsp;',
    compareValue: '',
    hasCompareSeasons: !!o.compareSeasons?.year,
    value: o.valueSuffix ?? '',
    helperTag: { opening: '', closing: '' },
    helperTagCompareSeasons: { opening: '', closing: '' },
  })

  template += `
    <div style="display:flex;align-items:center;">
      ${minAverageMaxRow({
        compareSeasonsYear: o.compareSeasons?.year,
        hasValueInRegularSeason: o.value !== undefined,
        seriesColor: o.seriesColor,
        seriesName: o.seriesName,
        compareSeasonsSeriesColor: o.compareSeasons?.seriesColor,
        xYear: o.xYear,
        minCell,
        avgCell,
        maxCell,
        suffixCell,
      })}
      </div>
  </td>`

  return template
}
