import { TPermission } from '@semios/app-platform-value-type-definitions'
import { isEmpty } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'

type RequiresObject = { permission: TPermission; entity: number | '*' | 'FOR_ANY_ENTITY' }

export type RequiresArg = RequiresObject | RequiresObject[] | boolean

export const checkAuthorization = (
  requires: RequiresArg,
  userPermissions = userDetailsStore.getState().permissions,
) => {
  if (typeof requires === 'boolean') return requires

  const requiresArray: RequiresObject[] = [].concat(requires as [])

  return requiresArray.some(({ permission, entity }) => {
    let passes = false

    const permissionArray: TPermission[] = [].concat(permission as unknown as [])

    try {
      passes = permissionArray.some((p) => {
        if (p === 'MOCK_PERMISSION') return true

        let hasThisPermission = false

        try {
          if (entity === 'FOR_ANY_ENTITY') {
            hasThisPermission = !isEmpty(userPermissions[p])
          } else {
            hasThisPermission = userPermissions[p][entity]
          }
        } catch (err) {}

        return hasThisPermission
      })
    } catch (err) {}

    return passes
  })
}
