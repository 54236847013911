import { css } from '@emotion/css'
import { translate } from 'i18n/i18n'
import { FC } from 'react'
import { EmailProps } from '../../../types'

export const Email: FC<EmailProps> = ({ email }) => {
  return (
    <div
      className={css`
        justify-content: left;
        align-items: center;
        display: flex;
        margin: 20px 0 12px 5px;
      `}
    >
      <div
        className={css`
          font-size: 14px;
          font-weight: 600;
          width: 130px;
        `}
      >
        {translate.phrases.banyanApp('Email')}
      </div>
      <div
        className={css`
          font-size: 14px;
        `}
      >
        {email}
      </div>
    </div>
  )
}
