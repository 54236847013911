import { selectedValueGroupsStore, TValueGroup } from './selectedValueGroupsStore'

export function toggleValueGroups(selectedValueGroups: TValueGroup[], selected: boolean) {
  selectedValueGroupsStore.setState((s) => {
    let updatedSelectedValueGroups = selectedValueGroups.reduce(
      (selection: Partial<Record<TValueGroup, number | false>>, valueGroup) => {
        selection[valueGroup] = selected ? +new Date() : false

        return selection
      },
      { ...s.selectedValueGroups },
    )

    if (selectedValueGroups.includes('equipment_status') && selected) {
      // If `equipment_status` is selected, deselect all other value groups
      updatedSelectedValueGroups = {
        equipment_status: +new Date(),
      }
    } else if (
      !!s.selectedValueGroups.equipment_status &&
      !selectedValueGroups.includes('equipment_status') &&
      selected
    ) {
      // If `equipment_status` was selected and the new selection contains other value groups, deselect `equipment_status`
      updatedSelectedValueGroups['equipment_status'] = false
    }

    return {
      ...s,
      selectedValueGroups: {
        ...updatedSelectedValueGroups,
      },
    }
  })
}
