import { translate } from 'i18n/i18n'
import * as Sentry from '@sentry/react'
import { TNodeDeviceType } from '../../store/serviceCenterStore'
import { IconWindMachine } from 'components/icons/IconWindMachine'
import { IconVP4 } from 'components/icons/IconVP4'
import { IconLWS } from 'components/icons/IconLWS'
import { IconPT } from 'components/icons/IconPT'
import { IconDendrometer } from 'components/icons/IconDendrometer'
import { IconSloshingWaterInWaterDrop } from 'components/icons/IconSloshingWaterInWaterDrop'

type IconComponent = () => JSX.Element

export const DEVICE_TYPES: TNodeDeviceType[] = [
  'frost-fan',
  'weather',
  'leaf-wetness',
  'water-flow',
  'dendrometer',
  'soil',
]

export const DEVICE_TYPE_ICONS: Record<TNodeDeviceType, IconComponent> = {
  'frost-fan': IconWindMachine,
  'weather': IconVP4,
  'leaf-wetness': IconLWS,
  'water-flow': IconPT,
  'dendrometer': IconDendrometer,
  'soil': IconSloshingWaterInWaterDrop,
}

export function getDeviceTypeLabel(deviceType: TNodeDeviceType): string {
  switch (deviceType) {
    case 'frost-fan':
      return translate.phrases.placeholder('Fans')

    case 'weather':
      return translate.phrases.placeholder('Weather Sensors')

    case 'leaf-wetness':
      return translate.phrases.placeholder('LWS')

    case 'water-flow':
      return translate.phrases.placeholder('PTs')

    case 'dendrometer':
      return translate.phrases.placeholder('Dendros')

    case 'soil':
      return translate.phrases.placeholder('Soil Probes')

    default:
      Sentry.captureException(`Device type "${deviceType}" has not been implemented`)

      return deviceType
  }
}
