import { routes } from '@semios/app-platform-banyan-route-definitions'
import { generateDefaultHeatmapDangerColors } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateUsualStyleAPIArgs } from './_utils/generateUsualStyleAPIArgs'
import { getValueType } from './_utils/getValueType'
import { apiFetch } from 'utils/apiFetch'
import { isEmpty, isNil } from '@semios/app-platform-common'
import { VC } from '@semios/app-platform-value-type-definitions'
import { getHeatmapCSS } from './_utils/getHeatmapCSS'
import { TValueType } from '../../caches/BlockScdsValuesCache/BlockScdsValuesCache'
import { almondHullSplit as unitConverter } from 'utils/unitConverter/by-domain/almondHullSplit'
import { TFieldAssetKeyTypes } from '@semios/app-platform-banyan-route-definitions/src/routes/userAppStartup'
import { colors } from 'settings/colors'
import { baseZIndex } from 'components/GridTable/consts'
import { setHeatmapExtremesFromArrayOfValues } from './_utils/setHeatmapExtremesFromArrayOfValues'
import { populateSCDsForBlocks } from './_utils/generateUsualStyleGetCacheUpdatesFromResponse'
import { getPropertyIdsForAllPropertiesInVisibleRegions } from './_utils/getPropertyIdsForAllPropertiesInVisibleRegions'

const heatmapExtremesForAllPropertiesInVisibleRegions = true
const valueGroup = 'almond_hull_split'
const valueType = 'almondHullSplit_date'
const valueTypeForProperty = 'almondHullSplitMedian_date'

type TValueObject =
  VC.DomainTypes.AlmondHullSplit.TCurrentValuesReturnIgnoringKeying['blocks'][typeof valueType]

type TValueObjectProperty =
  VC.DomainTypes.AlmondHullSplit.TCurrentValuesReturnIgnoringKeying['properties'][typeof valueTypeForProperty]

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  return generateUsualStyleAPIArgs({
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
    },
    property: {
      valueType: getValueType(MAP_VISUAL.PROPERTY, valueGroup),
    },
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.POINT, valueGroup),
    },
  })
}

const selectedCultivarId = 72

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches: processedCachesArg,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCachesArg)

  if (isEmpty(args)) return {}

  const response = await apiFetch<routes.ValuesCurrent.Request, routes.ValuesCurrent.Response>({
    url: routes.ValuesCurrent.path,
    body: args,
  })

  const processedCaches = populateSCDsForBlocks(response, processedCachesArg)
  const returner: TGetCacheUpdatesFromResponseReturn = {}
  const allValues: (number | null)[] = []

  if (!!processedCaches.blockValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.blockValues.itemsWithinView.flatMap((block) => {
      const valueObject = response?.blocks?.[block.meta.blockId]?.[valueType] as TValueObject

      if (!valueObject) return []

      const value: string | null = valueObject?.value[selectedCultivarId] ?? null
      //TODO: pass timezone to converter
      const valueForHeatmaps = unitConverter.almondHullSplit(value).value()

      allValues.push(valueForHeatmaps)

      itemIdsWithinView.push(block.id)

      return {
        id: String(block.id),
        value: {
          [valueType]: {
            children: unitConverter.almondHullSplit(value).valueAsString(),
            onHover: true,
            getContainerCSS: () => getHeatmapCSS({ value: valueForHeatmaps }),
          },
        },
      }
    })

    returner.blocks = {
      itemIdsWithinView,
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.blockCacheKey,
    }
  }

  if (!!processedCaches.blockScdsValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.blockScdsValues.itemsWithinView.flatMap(
      (block) => {
        itemIdsWithinView.push(block.id)

        return {
          id: block.meta.blockId,
          value: block.meta.scds.reduce((acc: TValueType, { googleLatLng, lngLat }) => {
            const valueObject = response?.heatmap_points?.[lngLat]?.[valueType] as TValueObject

            if (valueObject?.value[selectedCultivarId]) {
              const value = valueObject?.value[selectedCultivarId]
              const valueForHeatmaps = unitConverter.almondHullSplit(value).value()

              allValues.push(valueForHeatmaps)

              acc[googleLatLng.toUrlValue()] = {
                [valueType]: {
                  children: unitConverter.almondHullSplit(value).valueAsString(),
                  getContainerCSS: () => getHeatmapCSS({ value: valueForHeatmaps }),
                },
              }
            }

            return acc
          }, {}),
        }
      },
    )

    returner.heatmapPoints = {
      itemsWithinViewThatNowHaveValues,
      itemIdsWithinView: [...new Set(itemIdsWithinView)],
      cacheKey: cacheKeys.scdCacheKey,
    }
  }

  if (!!processedCaches.stationValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.stationValues.itemsWithinView.flatMap(
      (station) => {
        itemIdsWithinView.push(station.id)

        const valueObject = response?.points?.[station.meta.lngLat]?.[valueType] as TValueObject

        if (!valueObject) return []

        const value: string | null = valueObject?.value[selectedCultivarId] ?? null
        const heatmapValue = unitConverter.almondHullSplit(value).value()

        allValues.push(heatmapValue)

        return {
          id: String(station.id),
          value: {
            [valueType]: {
              children: unitConverter.almondHullSplit(value).valueAsString(),
              getContainerCSS: () => getHeatmapCSS({ value: heatmapValue }),
              isWeatherStation: true,
            },
          },
        }
      },
    )

    returner.stations = {
      itemIdsWithinView,
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.stationCacheKey,
    }
  }

  const willShowStations = !!returner.stations?.itemsWithinViewThatNowHaveValues.length
  const willShowBlocks = !!returner.blocks?.itemsWithinViewThatNowHaveValues.length
  const willShowHeatmapPoints = !!returner.heatmapPoints?.itemIdsWithinView.length
  const propertyIdsToValues: Record<TFieldAssetKeyTypes.TPropertyId, number | null> = {}
  const propertyIds = processedCaches.propertyValues.itemsWithinView.map((s) => Number(s.id))

  propertyIds.forEach((propertyId) => {
    const valueObjectForProperty = response?.properties?.[propertyId]?.[
      valueTypeForProperty
    ] as TValueObjectProperty

    if (valueObjectForProperty?.value) {
      const value = valueObjectForProperty?.value[selectedCultivarId]
      const heatmapValue = unitConverter.almondHullSplit(value).value()

      allValues.push(heatmapValue)

      propertyIdsToValues[propertyId] = heatmapValue
    }
  })

  const showValuesForPropertyRatherThanName =
    !isEmpty(propertyIdsToValues) && !willShowStations && !willShowBlocks && !willShowHeatmapPoints

  const itemsWithinViewThatNowHaveValues = processedCaches.propertyValues.itemsWithinView.map((property) => {
    if (!showValuesForPropertyRatherThanName) {
      return {
        id: String(property.id),
        value: {
          [valueTypeForProperty]: {
            children: property.meta.propertyName,
            onHover: false,
            getContainerCSS: () => ({ backgroundColor: colors.midnight, color: 'white' }),
            baseZIndex: undefined,
          },
        },
      }
    }

    const value = propertyIdsToValues[Number(property.id)]

    return {
      id: String(property.id),
      value: {
        [valueTypeForProperty]: {
          children: unitConverter.almondHullSplit(value).valueAsString(),
          onHover: true,
          getContainerCSS: () => getHeatmapCSS({ value }),
          baseZIndex,
        },
      },
    }
  })

  returner.properties = {
    itemsWithinViewThatNowHaveValues,
    cacheKey: cacheKeys.propertyCacheKey,
    itemIdsWithinView: processedCaches.propertyValues.itemIdsWithinView,
  }

  let valuesToUseForExtremes = allValues

  if (heatmapExtremesForAllPropertiesInVisibleRegions) {
    const visiblePropertyIds = processedCaches.propertyValues.itemIdsWithinView.map(Number)
    const allPropertyIdsInVisibleRegions = getPropertyIdsForAllPropertiesInVisibleRegions(visiblePropertyIds)

    if (allPropertyIdsInVisibleRegions.length > 1) {
      const valuesForAllPropertiesInVisibleRegion: number[] = []

      allPropertyIdsInVisibleRegions.forEach((propertyId) => {
        const value = response?.properties?.[propertyId]?.[valueTypeForProperty]?.value[selectedCultivarId]
        const heatmapValue = unitConverter.almondHullSplit(value).value()

        if (!isNil(heatmapValue)) valuesForAllPropertiesInVisibleRegion.push(heatmapValue)
      })

      if (valuesForAllPropertiesInVisibleRegion.length) {
        valuesToUseForExtremes = valuesForAllPropertiesInVisibleRegion
      }
    }
  }

  setHeatmapExtremesFromArrayOfValues({
    values: valuesToUseForExtremes,
    heatmapColoring: generateDefaultHeatmapDangerColors().reverse(),
  })

  return returner
}
