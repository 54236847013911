import { EVENT_ID, irrigationSchedulerStore, TScheduledEventEnum } from '../irrigationSchedulerStore'

export const togglePulseEventForBatchDelete = (pulseEventId: EVENT_ID) => {
  irrigationSchedulerStore.setState((s) => {
    const events = { ...s.batchDelete.events }

    if (events[pulseEventId]) {
      delete events[pulseEventId]
    } else {
      const scheduledEvent = s.scheduledPulseIrrigationEvents[pulseEventId]

      if (scheduledEvent && scheduledEvent.type === TScheduledEventEnum.SCHEDULED) {
        events[pulseEventId] = { ...scheduledEvent, type: TScheduledEventEnum.SCHEDULED }
      } else if (scheduledEvent && scheduledEvent.type === TScheduledEventEnum.DRAFT_CREATE) {
        events[pulseEventId] = { ...scheduledEvent, type: TScheduledEventEnum.DRAFT_CREATE }
      }
    }

    return {
      ...s,
      batchDelete: {
        ...s.batchDelete,
        events: events,
      },
    }
  })
}
