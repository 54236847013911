import { css } from '@emotion/css'
import { useMantineTheme } from '@mantine/core'
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { Expanded } from './Expanded/Expanded'
import { Collapsed } from './Collapsed/Collapsed'
import { useTabsContext } from '../Tabs.context'

export const TabHeaderList = ({
  children,
  isCollapsed = false,
}: {
  children: ReactNode
  isCollapsed?: boolean
}) => {
  const theme = useMantineTheme()
  const ctx = useTabsContext()

  const {
    containerWidth,
    activeTab,
    selectedPosition,
    setSelectedPosition,
    collapsed,
    setCollapsed,
    showTabs,
    setShowTabs,
    styles,
    stickyHeader,
  } = ctx

  const [tabListWidth, setTabListWidth] = useState(containerWidth)
  const tabs = useRef<HTMLDivElement>(null)

  const calculateTabItemsWidth = (elements: HTMLCollection) => {
    let value = 0

    for (let i = 0; i < elements.length; i++) {
      value += elements[i].getBoundingClientRect().width
    }

    setTabListWidth(value)
  }

  useEffect(() => {
    if (tabs.current && tabs.current.children.length > 0) {
      calculateTabItemsWidth(tabs.current.children)

      const selectedTab = tabs.current.children[0] as HTMLDivElement

      setSelectedPosition({
        width: selectedTab.offsetWidth,
        left: selectedTab.offsetLeft,
      })
    }
  }, [])

  useEffect(() => {
    if (containerWidth < tabListWidth || isCollapsed) {
      setCollapsed(true)

      return
    }

    setCollapsed(false)

    if (!tabs.current) return

    for (let i = 0; i < tabs.current.children.length; i++) {
      if (tabs.current.children[i].innerHTML.toLowerCase() !== activeTab.toLowerCase()) continue

      const selectedTab = tabs.current.children[i] as HTMLDivElement

      setSelectedPosition({
        width: selectedTab.offsetWidth,
        left: selectedTab.offsetLeft,
      })
    }
  }, [containerWidth, tabListWidth])

  const getTabsValue = () => {
    let result: string[] = []

    if (children && Array.isArray(children)) {
      children.forEach((child: ReactElement) => {
        const value = child?.props?.value

        if (value) {
          result.push(value)
        }
      })
    }

    return result
  }

  const tabValues = getTabsValue()

  return (
    <div
      className={css`
        position: ${stickyHeader ? 'sticky' : 'relative'};
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: ${collapsed ? 'column' : 'row'};
        justify-content: ${collapsed ? 'center' : 'auto'};
        background-color: ${theme.colors.midnight[0]};
        color: ${theme.colors.white[0]};
        padding: 0 10px;
        z-index: 10;
        user-select: none;
        ${styles && styles.list && styles.list}
      `}
      ref={tabs}
    >
      {!collapsed ? (
        <Expanded selectedPosition={selectedPosition}>{children}</Expanded>
      ) : (
        <Collapsed
          activeTab={activeTab}
          showTabs={showTabs}
          onActiveTabClick={() => setShowTabs((oldValue: boolean) => !oldValue)}
        >
          {children}
        </Collapsed>
      )}
      {collapsed && !!tabValues?.length && (
        <div
          className={css`
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
          `}
        >
          {tabValues?.map((tab) => {
            return (
              <div
                key={tab}
                className={css`
                  display: flex;
                  width: 7px;
                  height: 7px;
                  border-radius: 50%;
                  margin-right: 10px;
                  background-color: ${tab === activeTab ? theme.colors.white[0] : theme.colors.grey[2]};
                `}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
