import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'

export type PlannedNodesRepositionMeta = routes.ServiceCenterPlannedNodesReposition.Request

const request = async (meta: PlannedNodesRepositionMeta): Promise<void> => {
  await apiFetch<
    routes.ServiceCenterPlannedNodesReposition.Request,
    routes.ServiceCenterPlannedNodesReposition.Response
  >({
    url: routes.ServiceCenterPlannedNodesReposition.path,
    body: meta,
    params: {
      method: 'PUT',
    },
  })
}

export const serviceCenterPlannedNodesReposition = {
  request,
}
