import { useEffect } from 'react'
import { MOVE_POINT_STATE } from '../useMoveTracker'

export default function handlePointerMove(
  setState: React.Dispatch<React.SetStateAction<MOVE_POINT_STATE>>,
  tracking: MOVE_POINT_STATE['tracking'],
) {
  useEffect(() => {
    let handlePointerMove: ((event: PointerEvent) => void) | null = null

    if (tracking) {
      handlePointerMove = (event) => {
        // if the user has multiple fingers touching, and this event is for a finger that is not the primary one (The first touched finger is the primary one), then do nothing.
        if (!event.isPrimary) return

        setState((s) => {
          const point = ['X1', 'X2'].includes(tracking) ? event.clientX : event.clientY

          return { ...s, point }
        })
      }
    }

    if (handlePointerMove) document.body.addEventListener('pointermove', handlePointerMove)

    return () => {
      if (handlePointerMove) document.body.removeEventListener('pointermove', handlePointerMove)
    }
  }, [tracking])
}
