import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'

export type PlannedGatewayRepositionMeta = routes.ServiceCenterPlannedGatewayReposition.Request

const request = async (meta: PlannedGatewayRepositionMeta): Promise<void> => {
  await apiFetch<
    routes.ServiceCenterPlannedGatewayReposition.Request,
    routes.ServiceCenterPlannedGatewayReposition.Response
  >({
    url: routes.ServiceCenterPlannedGatewayReposition.path,
    body: meta,
    params: {
      method: 'PUT',
    },
  })
}

export const serviceCenterPlannedGatewayReposition = {
  request,
}
