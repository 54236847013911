import { translate } from 'i18n/i18n'
import { ImperialXorMetric } from 'stores/fieldAssetStore'
import { getIrrigationActivitySettings } from '../_utils/irrigationActivitySettings'

export const PressureSensorsLegend = ({
  showPressure,
  pressureUnitFromUserSetting,
}: {
  showPressure: boolean
  pressureUnitFromUserSetting: ImperialXorMetric
}) => {
  const irrigationActivitySettings = getIrrigationActivitySettings({ pressureUnitFromUserSetting })

  const colorStyles = {
    margin: '0px 4px 0px 10px',
    height: 15,
    width: 15,
    display: 'inline-block',
  }

  const { psi, on, off, noData } = irrigationActivitySettings

  return (
    <div css={{ display: 'flex', padding: '30px', fontSize: 14, fontWeight: 'bold' }}>
      {showPressure && (
        <div css={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
          <span
            css={{
              ...colorStyles,
              background: psi.color,
              height: '2px',
              width: '17px',
            }}
          />
          <span>{translate.phrases.banyanApp('Pressure')}</span>
        </div>
      )}
      <div css={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
        <span css={{ ...colorStyles, background: on.color }} />
        <span>{on.text}</span>
      </div>
      <div css={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
        <span css={{ ...colorStyles, background: off.color }} />
        <span>{off.text}</span>
      </div>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <span css={{ ...colorStyles, background: noData.color }} />
        <span>{noData.text}</span>
      </div>
    </div>
  )
}
