import React from 'react'
import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconChevron = () => (
  <svg {...makeBaseSvgIconProps('0 0 14 8')}>
    <path
      d="M13 1L7 7L1 1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
