import { Drawer, DrawerProps } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { FC, ReactNode } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { useScreenSize } from 'utils/useScreenSize'
import { NarrowFooter } from './NarrowFooter/NarrowFooter'
import { NarrowHeader } from './NarrowHeader/NarrowHeader'
import { WideFooter } from './WideFooter/WideFooter'
import { WideHeader } from './WideHeader/WideHeader'

type ModalDrawerProps = {
  size?: DrawerProps['size']
  fullSize?: DrawerProps['size']
  title?: string
  opened: boolean
  onClose: () => void
  isSecondaryModal?: boolean
  primaryButtonDisabled?: boolean
  primaryButtonText?: string
  primaryButtonOnPress?: () => void
  secondaryButtonDisabled?: boolean
  secondaryButtonText?: string
  secondaryButtonOnPress?: () => void
  customWideHeader?: ReactNode
  customNarrowHeader?: ReactNode
  customWideFooter?: ReactNode
  customNarrowFooter?: ReactNode
  children?: ReactNode
  zIndex?: number
}

export const DEFAULT_MODAL_DRAWER_Z_INDEX = 200

export const ModalDrawer: FC<ModalDrawerProps> = ({
  size = '50%',
  fullSize = '100%',
  title = '',
  opened,
  onClose,
  isSecondaryModal,
  primaryButtonDisabled,
  primaryButtonText,
  primaryButtonOnPress,
  secondaryButtonDisabled,
  secondaryButtonText,
  secondaryButtonOnPress,
  customWideHeader,
  customNarrowHeader,
  customWideFooter,
  customNarrowFooter,
  children,
  zIndex,
}) => {
  const { isWideScreen } = useScreenSize()
  const { height } = useViewportSize()

  return (
    <Drawer
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={onClose}
      opened={opened}
      overlayProps={{ opacity: 0.75 }}
      position="right"
      size={isWideScreen ? size : fullSize}
      transitionProps={{ duration: 300, timingFunction: 'ease' }}
      withCloseButton={false}
      withOverlay={isWideScreen || !isSecondaryModal}
      zIndex={zIndex || DEFAULT_MODAL_DRAWER_Z_INDEX}
      styles={{ body: { padding: 0 } }}
    >
      <div
        css={{
          height,
          overflowY: isWideScreen ? 'auto' : 'unset',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
          [`@media (max-width: ${SharedSettings.MINIMUM_WIDTH_WIDE_SCREEN}px)`]: {
            position: 'fixed',
            height: '100%',
            width: '100%',
          },
        }}
      >
        {(() => {
          if (!isWideScreen) return null

          return (
            customWideHeader || (
              <WideHeader onClose={onClose} title={title} isSecondaryModal={isSecondaryModal} />
            )
          )
        })()}
        {(() => {
          if (isWideScreen) return null

          return (
            <div>
              {customNarrowHeader || (
                <NarrowHeader
                  isSecondaryModal={isSecondaryModal}
                  onClose={onClose}
                  secondaryButtonDisabled={secondaryButtonDisabled}
                  secondaryButtonText={secondaryButtonText}
                  secondaryButtonOnPress={secondaryButtonOnPress}
                  title={title}
                />
              )}
            </div>
          )
        })()}
        <div css={{ overflowY: 'auto' }}>{children}</div>
        {(() => {
          if (!isWideScreen) return null

          return (
            <div>
              {customWideFooter || (
                <WideFooter
                  primaryButtonDisabled={primaryButtonDisabled}
                  primaryButtonText={primaryButtonText}
                  primaryButtonOnPress={primaryButtonOnPress}
                  secondaryButtonDisabled={secondaryButtonDisabled}
                  secondaryButtonText={secondaryButtonText}
                  secondaryButtonOnPress={secondaryButtonOnPress}
                />
              )}
            </div>
          )
        })()}
        {(() => {
          if (isWideScreen) return null

          return (
            <div>
              {customNarrowFooter || (
                <NarrowFooter
                  primaryButtonDisabled={primaryButtonDisabled}
                  primaryButtonText={primaryButtonText}
                  primaryButtonOnPress={primaryButtonOnPress}
                />
              )}
            </div>
          )
        })()}
      </div>
    </Drawer>
  )
}
