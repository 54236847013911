import { apiFetch } from 'utils/apiFetch'
import { routes, SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import { getIdentifier } from '../getIdentifier'
import { cloneDeep, omit } from 'lodash'
import { MetaWithNodeIdentifier } from './queue'

export type NodeDeviceInstallMeta = routes.ServiceCenterNodeDeviceInstall.Request & {
  plannedDevice: SharedTypes.TNodeDevice
} & MetaWithNodeIdentifier

const request = async (meta: NodeDeviceInstallMeta) => {
  await apiFetch<
    routes.ServiceCenterNodeDeviceInstall.Request,
    routes.ServiceCenterNodeDeviceInstall.Response
  >({
    url: routes.ServiceCenterNodeDeviceInstall.path,
    body: omit(meta, 'plannedDevice'),
    params: {
      method: 'POST',
    },
  })
}

const callback = async (_: void, meta: NodeDeviceInstallMeta) => {
  const { nodeIdentifier, port: connector, channel, plannedDevice } = meta
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())
  // Update local state with new device
  const updatedNode = cloneDeep(activeNodes.find((node) => getIdentifier(node) === nodeIdentifier))

  if (updatedNode) {
    if (!updatedNode.devices) updatedNode.devices = {}

    if (!updatedNode.devices[connector]) updatedNode.devices[connector] = {}

    // Remove planned device from node (if different from installed device)
    if (
      plannedDevice.connector &&
      plannedDevice.channel &&
      (plannedDevice.connector !== connector || plannedDevice.channel !== channel) &&
      updatedNode.devices[plannedDevice.connector]?.[plannedDevice.channel]
    ) {
      delete updatedNode.devices[plannedDevice.connector]?.[plannedDevice.channel]
    }

    // Add installed device to node
    if (updatedNode.devices[connector]?.[channel]) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      updatedNode.devices[connector]![channel] = {
        ...omit(plannedDevice, ['connector', 'channel', 'height']),
        ...meta,
        installationStatus: SharedTypes.NodeDeviceInstallationStatus.INSTALLED,
      }
    }

    serviceCenterStore.actions.updateActiveNode(updatedNode)
  }
}

export const serviceCenterNodeDeviceInstall = {
  request,
  callback,
}
