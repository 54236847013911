import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { TUnitConverterOptionsWithoutUnits, TUnitConverterTypicalReturn } from '../types'

const DEFAULT_DECIMAL_PLACES_DAILY_RPV = 0
const DEFAULT_DECIMAL_PLACES_7_DAY_RPV = 0
const DEFAULT_DECIMAL_PLACES_SPRAY_THRESHOLD_RPV = 0

const walnutBlightRPV = (
  rpvValue: number | null = null,
  { decimalPlaces = DEFAULT_DECIMAL_PLACES_DAILY_RPV }: TUnitConverterOptionsWithoutUnits = {},
): TUnitConverterTypicalReturn => {
  let convertedValue: number | null

  if (isNil(rpvValue)) {
    convertedValue = null
  } else {
    convertedValue = rpvValue
  }

  const unitSymbol = translate.measurements.riskPredictionValue.unit()

  return {
    categoryTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Walnut Blight'),
        unitSymbol,
      }),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Walnut Blight'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_DAILY_RPV,
    shortTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Daily'),
        unitSymbol,
      }),
    suffix: () => unitSymbol,
    title: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Walnut Blight'),
        unitSymbol,
      }),
    titleWithoutUnit: () => translate.phrases.banyanApp('Walnut Blight'),
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => translate.measurements.riskPredictionValue.value(convertedValue, decimalPlaces),
    valueWithSuffix: () =>
      translate.measurements.riskPredictionValue.valueWithUnit(convertedValue, decimalPlaces),
    valueWithNoRounding: () => convertedValue,
  }
}

const walnutBlightRPVCumulativeRolling7Days = (
  rpvCumulativeRolling7DaysValue: number | null = null,
  { decimalPlaces = DEFAULT_DECIMAL_PLACES_7_DAY_RPV }: TUnitConverterOptionsWithoutUnits = {},
): TUnitConverterTypicalReturn => {
  let convertedValue: number | null

  if (isNil(rpvCumulativeRolling7DaysValue)) {
    convertedValue = null
  } else {
    convertedValue = rpvCumulativeRolling7DaysValue
  }

  const unitSymbol = translate.measurements.riskPredictionValue.unit()

  return {
    categoryTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Walnut Blight'),
        unitSymbol,
      }),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Walnut Blight'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_7_DAY_RPV,
    shortTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('7 Day'),
        unitSymbol,
      }),
    suffix: () => unitSymbol,
    title: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Walnut Blight 7 Day'),
        unitSymbol,
      }),
    titleWithoutUnit: () => translate.phrases.banyanApp('Walnut Blight 7 Day'),
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => translate.measurements.riskPredictionValue.value(convertedValue, decimalPlaces),
    valueWithSuffix: () =>
      translate.measurements.riskPredictionValue.valueWithUnit(convertedValue, decimalPlaces),
    valueWithNoRounding: () => convertedValue,
  }
}

const walnutBlightBlockRPVSprayThreshold = (
  blockRPVSprayThresholdValue: number | null = null,
  { decimalPlaces = DEFAULT_DECIMAL_PLACES_SPRAY_THRESHOLD_RPV }: TUnitConverterOptionsWithoutUnits = {},
): TUnitConverterTypicalReturn => {
  let convertedValue: number | null

  if (isNil(blockRPVSprayThresholdValue)) {
    convertedValue = null
  } else {
    convertedValue = blockRPVSprayThresholdValue
  }

  const unitSymbol = translate.measurements.riskPredictionValue.unit()

  return {
    categoryTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Walnut Blight'),
        unitSymbol,
      }),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Walnut Blight'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_SPRAY_THRESHOLD_RPV,
    shortTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('7 Day Threshold'),
        unitSymbol,
      }),
    suffix: () => unitSymbol,
    title: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Walnut Blight 7 Day Threshold'),
        unitSymbol,
      }),
    titleWithoutUnit: () => translate.phrases.banyanApp('Walnut Blight 7 Day Threshold'),
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => translate.measurements.riskPredictionValue.value(convertedValue, decimalPlaces),
    valueWithSuffix: () =>
      translate.measurements.riskPredictionValue.valueWithUnit(convertedValue, decimalPlaces),
    valueWithNoRounding: () => convertedValue,
  }
}

export const walnutBlight = {
  walnutBlightRPV,
  walnutBlightRPVCumulativeRolling7Days,
  walnutBlightBlockRPVSprayThreshold,
}
