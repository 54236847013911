import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import { OfflineRequestError } from 'utils/errorCodes'
import { serviceCenterNodeDropInstall } from './api/serviceCenterNodeDropInstall'
import { serviceCenterStore } from '../store/serviceCenterStore'
import { TActiveNode, TNodeType } from '../types'
import { ManagementTypes } from '@semios/app-platform-banyan-route-definitions/src/shared-types'

export async function dropInstall(params: {
  nodeIdentifier: string
  nodeType: TNodeType
  location: string
  propertyId: number
  managementType: ManagementTypes
  maintenanceOwnerId: number
}): Promise<TActiveNode> {
  let unsynced = true

  // Install planned node
  const { nodeIdentifier, nodeType, location, propertyId, managementType, maintenanceOwnerId } = params

  const payload = {
    nodeIdentifier,
    nodeType,
    location,
    propertyId,
    managementType,
    maintenanceOwnerId,
  }

  try {
    const { status } = await ApiRequestQueue.createRequest(ApiRequestType.DROP_INSTALL_NODE, payload)

    if (status === ApiRequestStatus.COMPLETED) unsynced = false
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) throw error
  }

  if (unsynced) {
    serviceCenterNodeDropInstall.callback(payload)
  }

  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())

  const [newActiveNode] = activeNodes.filter(
    (node) => (node as TActiveNode).nodeIdentifier === nodeIdentifier,
  )

  // Select newly installed active node
  serviceCenterStore.actions.selectEquipment(nodeIdentifier, 'active')

  return newActiveNode as TActiveNode
}
