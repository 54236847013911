import React from 'react'
import { css } from '@emotion/css'

export const ActiveTab = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => {
  return (
    <div
      className={css`
        height: 100%;
        transition: all 5s ease-out 0s;
        cursor: pointer;
      `}
    >
      <div
        className={css`
          white-space: nowrap;
          text-align: center;
          padding: 15px 20px;
          cursor: pointer;
          text-transform: capitalize;
        `}
        onClick={onClick}
      >
        {children}
      </div>
    </div>
  )
}
