import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'

export const serviceCenterNodeServiceIssuesGet = async (propertyIds: number[], statuses?: string[]) => {
  const serviceIssues = await apiFetch<
    routes.ServiceCenterNodeServiceIssuesGet.Request,
    routes.ServiceCenterNodeServiceIssuesGet.Response
  >({
    url: routes.ServiceCenterNodeServiceIssuesGet.path,
    body: {
      propertyIds,
      statuses,
    },
    params: {
      method: 'POST',
    },
  })

  return serviceIssues
}
