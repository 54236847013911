import { ItemHeader } from '../../WideScreen/ItemHeader/ItemHeader'
import { SettingsCreateNewButton } from '../../components/SettingsCreateNewButton/SettingsCreateNewButton'
import { useScreenSize } from 'utils/useScreenSize'
import { useState } from 'react'
import { Report, userDetailsStore } from 'stores/userDetailsStore'
import { translate } from 'i18n/i18n'
import { SettingsFooterContainer } from '../../components/SettingsFooterContainer/SettingsFooterContainer'
import { Button } from 'components/Button/Button'
import { colors } from 'settings/colors'
import { ModalDrawerCreateOrEditReport } from './Reports/ModalDrawerCreateOrEditReport'
import { ReportCard } from './components/ReportCard/ReportCard'
import { WeeklyReport, WeeklyTrapReport } from './types'
import { TReportType } from './ReportForm/ReportType/ReportType'

export type TModalDrawerForm = 'createReport' | 'editReport' | null

export const ReportsMain = () => {
  const [modalDrawerForm, setModalDrawerForm] = useState<TModalDrawerForm>(null)
  const { isWideScreen } = useScreenSize()
  const [selectedReport, setSelectedReport] = useState<WeeklyReport | WeeklyTrapReport | null>(null)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const reports = userDetailsStore.useSelector((s) => s.reports) as Report
  // State for report type
  const [reportType, setReportType] = useState<TReportType>('')

  return (
    <>
      <ItemHeader>{translate.phrases.banyanApp('Reports')}</ItemHeader>
      <div css={{ margin: '0px 20px', paddingBottom: 60 }}>
        {isWideScreen && (
          <div css={{ textAlign: 'right', marginBottom: 20 }}>
            <SettingsCreateNewButton
              label={translate.phrases.banyanApp('New Report')}
              onClick={() => {
                setModalDrawerForm('createReport')
              }}
            />
          </div>
        )}
        {reports?.weeklyGeneral?.length === 0 && reports?.weeklyTrapCatches?.length === 0 && (
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 20,
              borderTop: `1px solid ${colors.grey200}`,
              padding: 20,
              marginBottom: 5,
            }}
          >
            {translate.phrases.banyanApp('No reports found.')}
            <Button
              variant="link"
              onClick={() => {
                setModalDrawerForm('createReport')
              }}
            >
              {translate.phrases.banyanApp('Create one now')}
            </Button>
          </div>
        )}
        {reports?.weeklyTrapCatches?.map((report: WeeklyTrapReport) => (
          <ReportCard
            key={report.id}
            report={report}
            setSelectedReport={setSelectedReport}
            setModalDrawerForm={setModalDrawerForm}
            reportType={'Weekly Trap Catches'}
            setReportType={setReportType}
            setIsDuplicate={setIsDuplicate}
          />
        ))}
        {reports?.weeklyGeneral?.map((report: WeeklyReport) => (
          <ReportCard
            key={report.id}
            report={report}
            setSelectedReport={setSelectedReport}
            setModalDrawerForm={setModalDrawerForm}
            reportType={'Weekly Update'}
            setReportType={setReportType}
            setIsDuplicate={setIsDuplicate}
          />
        ))}
      </div>
      {!isWideScreen && (
        <SettingsFooterContainer>
          <SettingsCreateNewButton
            label={translate.phrases.banyanApp('New Report')}
            onClick={() => {
              setModalDrawerForm('createReport')
            }}
            css={{ width: '100%' }}
          />
        </SettingsFooterContainer>
      )}
      <ModalDrawerCreateOrEditReport
        opened={modalDrawerForm !== null}
        onClose={() => {
          setModalDrawerForm(null)

          setSelectedReport(null)
        }}
        reportId={selectedReport ? selectedReport.id : null}
        reportType={reportType}
        setReportType={setReportType}
        selectedReport={selectedReport}
        isDuplicate={isDuplicate}
        setIsDuplicate={setIsDuplicate}
      />
    </>
  )
}
