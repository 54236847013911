import { GridTableProps } from '../types'

export const getTableLayout = ({
  columns,
  containerWidth,
  content,
  topLeftCell,
}: {
  columns: GridTableProps['columns']
  containerWidth: number
  content: GridTableProps['content']
  topLeftCell: GridTableProps['topLeftCell']
}) => {
  const widthForLabelColumn = Math.max(
    topLeftCell?.minWidth ?? 0,
    ...content.flatMap((section) => section?.items?.map((item) => item.labelMinWidth) ?? 0),
  )

  const widthForValueColumns = Math.max(
    ...columns.map((column) => column.minWidth),
    ...content.flatMap((section) => section?.items?.map((item) => item.valueMinWidth) ?? 0),
  )

  const tableWidth = Math.max(containerWidth, widthForLabelColumn + widthForValueColumns * columns.length)

  return { tableWidth, widthForLabelColumn, widthForValueColumns }
}
