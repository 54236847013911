import { ReactNode } from 'react'
import { colors } from 'settings/colors'

export const ErrorTextWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div css={{ color: colors.red, fontWeight: 500, fontSize: 14, margin: '15px 0', fontStyle: 'italic' }}>
      {children}
    </div>
  )
}
