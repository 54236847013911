import { notifications } from '@mantine/notifications'
import NodePositioningModal from 'App/ServiceCenter/NodePositioningModal'
import { installationWorkflowStore } from 'App/ServiceCenter/store/installationWorkflowStore'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { InstallationStep, TPlannedNode } from 'App/ServiceCenter/types'
import { repositionPlannedGateway } from 'App/ServiceCenter/utils/repositionPlannedGateway'
import { repositionPlannedNodes } from 'App/ServiceCenter/utils/repositionPlannedNodes'
import React from 'react'
import { GeoJSONPosition, stringify } from 'wellknown'

const ConfirmLocation: React.FC<{ onClose: () => void }> = ({ onClose: handleClose }) => {
  const selectedPnode = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentNode,
  ) as TPlannedNode

  const handleSubmit = async (location: google.maps.LatLngLiteral) => {
    if (!location) return

    const { lng, lat } = location
    const coordinates = [lng, lat] as GeoJSONPosition

    const stringLocation = stringify({
      type: 'Point',
      coordinates,
    })

    try {
      if (selectedPnode.pseudogatewayId) {
        const params = { pseudogatewayId: selectedPnode.pseudogatewayId, location: stringLocation }

        await repositionPlannedGateway(params)
      } else {
        const params = { [selectedPnode.id]: stringLocation }

        await repositionPlannedNodes(params)
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to reposition node',
        color: 'red',
      })
    }

    serviceCenterStore.actions.repositionPseudoNodes({ [selectedPnode.id]: stringLocation })

    const confirmLocationStep = installationWorkflowStore
      .getState()
      .steps.find((s) => s.type === InstallationStep.CONFIRM_LOCATION)

    // complete confirm location step
    if (confirmLocationStep) {
      installationWorkflowStore.actions.updateStep({ ...confirmLocationStep, completed: true })
    }

    // enable activate node step
    const activateNodeStep = installationWorkflowStore
      .getState()
      .steps.find((s) => s.type === InstallationStep.ACTIVATE_NODE)

    if (activateNodeStep) {
      installationWorkflowStore.actions.updateStep({ ...activateNodeStep, enabled: true })
    }

    handleClose()
  }

  const selectedNode = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentNode,
  ) as TPlannedNode

  const selectedNodeLocation = JSON.parse(selectedNode.location)

  const selectedNodeOriginalCoordinates = {
    lat: selectedNodeLocation.coordinates[1],
    lng: selectedNodeLocation.coordinates[0],
  }

  return (
    <NodePositioningModal
      headerTitle={'Confirm Location'}
      showDefaultLocationMarker={true}
      onSubmit={handleSubmit}
      onClose={handleClose}
      mapDefaultCenter={selectedNodeOriginalCoordinates}
      showBanner
    />
  )
}

export default ConfirmLocation
