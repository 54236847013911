import { translate } from 'i18n/i18n'
import { useRef, useEffect, useState } from 'react'
import CloseCircleDark from './assets/svg/closeCircleDark.svg'
import { SearchBox } from './SearchBox'
import { MultiSelectContextProvider } from './MultiSelect.context'
import { OutsideClickAlerter } from './OutsideClickAlerter'
import { List } from './Options/List/List'
import { matchValuesForMultiSelect } from 'utils/matchValuesForMultiSelect/matchValuesForMultiSelect'
import type { IMultiSelectProps, TGroupedOptions, TOptions } from './MultiSelect.types'

export const MultiSelect = ({
  bottomSectionLabel,
  childrenUpper,
  childrenUpperList,
  closeIcon = CloseCircleDark,
  closeOnSelect = true,
  containerCss = {},
  customArrow = undefined,
  customCloseIcon,
  customSelectListBottomSection,
  data = [], // All filtering should be handled outside of this component. Pass filtered options to data
  disabled = false,
  dropdownPosition = 'bottom',
  error,
  groupOptions = false,
  header,
  hideClearAll = false,
  hideSelectAll = false,
  hideSelectedList = true,
  id = '',
  clearSearchOnSelect = false,
  clearSearchOnClose = true,
  loading = false,
  loadingMessage = translate.phrases.banyanApp('Loading...'),
  notFoundContent = <>{translate.phrases.banyanApp('No Options Available')}</>,
  onChange,
  onRemove = () => {
    return
  },
  onSearchChange,
  onSelect = () => {
    return
  },
  optionsLabel,
  optionValueDecorator = (v) => v,
  placeholder = translate.phrases.banyanApp('Select'),
  searchable = true,
  searchInDropdown = false,
  selectedData = [],
  selectedValueDecorator = (v) => v,
  selectionLimit = -1,
  topSectionItems = [],
  topSectionLabel,
}: IMultiSelectProps<TOptions>) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<TOptions[]>(data)
  const [selectedValues, setSelectedValues] = useState<TOptions[]>([...selectedData])
  const [highlightOption, setHighlightOption] = useState(0)
  const [groupedObject, setGroupedObject] = useState<TGroupedOptions>({})
  const [groupValues, setGroupValues] = useState<string[]>([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const searchBox = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setOptions(data)

    setInputValue('')
  }, [data])

  useEffect(() => {
    setSelectedValues(selectedData)
  }, [selectedData])

  useEffect(() => {
    if (groupOptions) {
      groupByOptions(options)
    }
  }, [options, selectedValues])

  useEffect(() => {
    if (inputValue !== '' && !dropdownOpen) setDropdownOpen(true)

    const filterFunc = (o: TOptions) => matchValuesForMultiSelect(o, inputValue)
    const results = data.filter(filterFunc)

    setOptions(results)

    onSearchChange?.(results, filterFunc)
  }, [inputValue])

  const groupByOptions = (optionsList: TOptions[]) => {
    const grouped = optionsList.reduce<Record<string, TOptions[]>>((acc, option) => {
      const key = option.group || 'Others'

      if (!acc[key]) {
        acc[key] = []
      }

      acc[key].push(option)

      return acc
    }, {})

    setGroupValues(Object.keys(grouped))

    setGroupedObject(grouped)
  }

  const onArrowKeyNavigation = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!options.length) return

    switch (event.key) {
      case 'ArrowUp':
        setHighlightOption((oldState) => (oldState > 0 ? oldState - 1 : options.length - 1))

        break

      case 'ArrowDown':
        setHighlightOption((oldState) => (oldState < options.length - 1 ? oldState + 1 : 0))

        break

      case 'Enter':
        if (dropdownOpen && highlightOption !== -1) {
          onSelectItem(options[highlightOption])
        }

        break

      default:
        break
    }
  }

  const updateValues = (updatedList: TOptions[]) => {
    setSelectedValues(updatedList)

    onChange(updatedList)
  }

  const closeDropdownAndFocusSearch = () => {
    if (!closeOnSelect && searchBox && searchBox.current) {
      searchBox.current.focus()
    }
  }

  const onRemoveSelectedItem = (item: TOptions) => {
    if (!selectedValues.some((i) => i.value === item.value)) return

    const selectedValuesList = selectedValues.filter((o) => o.value !== item.value)

    onRemove(selectedValuesList, item)

    updateValues(selectedValuesList)

    closeDropdownAndFocusSearch()
  }

  const onSelectItem = (item: TOptions) => {
    if (clearSearchOnSelect) {
      setInputValue('')
    }

    if (isSelectedValue(item)) {
      onRemoveSelectedItem(item)

      return
    }

    if (selectionLimit !== -1 && selectedValues.length >= selectionLimit) {
      return
    }

    const selectedValuesList = [...selectedValues, item]

    onSelect(selectedValuesList, item)

    updateValues(selectedValuesList)

    closeDropdownAndFocusSearch()
  }

  const isSelectedValue = (item: TOptions): boolean =>
    selectedValues.some((selectedItem: TOptions) => selectedItem.value === item.value)

  const toggleOptionList = () => {
    setDropdownOpen((oldState) => !oldState)

    setHighlightOption(0)

    clearSearchOnClose && setInputValue('')
  }

  const onCloseOptionList = () => {
    setDropdownOpen(false)

    setHighlightOption(0)

    clearSearchOnClose && setInputValue('')
  }

  const onFocus = () => {
    toggleOptionList()
  }

  const renderMultiselectContainer = () => {
    return (
      <div
        css={{
          position: 'relative',
          textAlign: 'left',
          width: '100%',
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? '0.5' : 'auto',
          ...containerCss,
        }}
        id={id}
      >
        <SearchBox ref={searchBox} />
        <List ref={searchBox} />
      </div>
    )
  }

  return (
    <MultiSelectContextProvider
      value={{
        bottomSectionLabel,
        childrenUpper,
        childrenUpperList,
        closeIcon,
        customArrow,
        customCloseIcon,
        customSelectListBottomSection,
        data,
        disabled,
        dropdownPosition,
        optionsLabel,
        error,
        groupOptions,
        groupedObject,
        groupValues,
        header,
        hideClearAll,
        hideSelectAll,
        hideSelectedList,
        highlightOption,
        inputValue,
        isDropdownOpen: dropdownOpen,
        isSelectedValue,
        loading,
        loadingMessage,
        notFoundContent,
        onArrowKeyNavigation,
        onChange,
        onFocus,
        onRemoveSelectedItem,
        onSelectItem,
        options,
        optionValueDecorator,
        placeholder,
        searchable,
        searchInDropdown,
        selectedData,
        selectedValueDecorator,
        selectedValues,
        setInputValue,
        setSelectedValues,
        setDropdownOpen,
        toggleOptionList,
        topSectionItems,
        topSectionLabel,
      }}
    >
      <OutsideClickAlerter outsideClick={onCloseOptionList}>
        {renderMultiselectContainer()}
      </OutsideClickAlerter>
    </MultiSelectContextProvider>
  )
}
