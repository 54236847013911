import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { TPointCategory } from 'stores/selectedFieldAssetsStore'
import { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'

export const getMapControlsPointValueTypeAndPointCategory = (o: {
  mapControlsStoreState: ReturnType<typeof mapControlsStore.getState>
  valueGroup: TValueGroup | null
  isOutOfBlock: boolean
}) => {
  const valueGroupProps = !o.valueGroup
    ? null
    : o.mapControlsStoreState.mapVisualValueGroup[MAP_VISUAL.POINT]?.[o.valueGroup]

  let valueType

  if (o.isOutOfBlock) {
    valueType = valueGroupProps?.outOfBlockValueType || valueGroupProps?.valueType || null
  } else {
    valueType = valueGroupProps?.valueType || null
  }

  let pointCategory: TPointCategory | 'lngLat' = 'lngLat'

  if (valueGroupProps) {
    if (valueGroupProps.mItem === 'M_OutOfBlockPoint') {
      pointCategory = 'outOfBlockPoint'
    } else {
      pointCategory = valueGroupProps.pointCategory || 'lngLat'
    }
  }

  return {
    valueType,
    pointCategory,
  }
}
