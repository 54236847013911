import React from 'react'
import { AnyNode, TActiveNode } from 'App/ServiceCenter/types'
import { DEVICEABLE_NODE_TYPES } from 'App/ServiceCenter/utils/constants/nodeType'
import { IconPlusInCircle } from 'components/icons/IconPlusInCircle'
import { translate } from 'i18n/i18n'
import { openDeviceFormModal } from '../../steps/ConfigureDevice'
import { Button } from 'components/Button/Button'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'

export const AddDeviceButton: React.FC<{ nodeType: string; selectedNode: AnyNode }> = ({
  nodeType,
  selectedNode,
}) => {
  const configuredDevices = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentNodeDevicesAsArray,
  )

  if (
    !nodeType ||
    !DEVICEABLE_NODE_TYPES.includes(nodeType) ||
    // only show this button for active nodes
    !(selectedNode as TActiveNode)?.nodeIdentifier
  )
    return null

  if (nodeType === 'RPT' && configuredDevices.length) {
    // RPT can have only one device, so it's already something attached, hide add device button
    return null
  }

  return (
    <span
      css={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'relative',
        top: -5,
        right: -18,
      }}
    >
      <Button
        size="lg"
        variant="link"
        leftIcon={<IconPlusInCircle />}
        onClick={() => openDeviceFormModal(selectedNode as TActiveNode)}
      >
        {translate.phrases.placeholder('Add Device')}
      </Button>
      <div>
        <div
          css={{
            height: '1px',
            background: '#0E4DA4',
            width: '99%',
            position: 'relative',
            top: '-3px',
            left: '3px',
          }}
        ></div>
      </div>
    </span>
  )
}
