import { IconContact } from 'components/icons/IconContact'
import { ContactsAndGroupsRow } from '../components/ContactsAndGroupsRow'
import { Dispatch, SetStateAction } from 'react'
import { Contact, userDetailsStore } from 'stores/userDetailsStore'
import { useSearchGroupsAndContacts } from '../components/useSearchGroupsAndContacts'
import { contactDeleteQuery } from '../_utils/queries'
import { fetchApiCustomer } from 'utils/fetchApiCustomer'
import { showNotification } from 'utils/showNotification'
import { showConfirmSettingModal } from '../../_utils/showConfirmSettingModal'
import { NotificationType } from '../_utils/useRelativeNotification'
import { useScreenSize } from 'utils/useScreenSize'
import { translate } from 'i18n/i18n'

type DeleteContactResponse = {
  data: {
    deleteContact: boolean
  }
}

export const Contacts = ({
  onEdit,
  setSelectedContact,
  showRelativeNotification,
}: {
  onEdit: () => void
  setSelectedContact: Dispatch<SetStateAction<Contact | null>>
  showRelativeNotification: ({ type, message }: { type: NotificationType; message: string }) => void
}) => {
  const { isWideScreen } = useScreenSize()
  const notificationFunctionToUse = isWideScreen ? showRelativeNotification : showNotification

  const { allGroups, allContacts } = userDetailsStore.useSelector((s) => ({
    allGroups: s.groups,
    allContacts: s.contacts,
  }))

  const { SearchInputComponent, filteredAndSortedList } = useSearchGroupsAndContacts({
    initialList: allContacts,
    placeholder: translate.phrases.banyanApp('Search Contacts'),
  })

  const onDelete = (contactId: string, contactName: string) => {
    showConfirmSettingModal({
      title: translate.phrases.banyanApp('Delete Contact', { contactName }),
      content: (
        <div>
          <p>
            {translate.phrases.banyanApp('Are you sure you want to delete {{contactName}} as a contact?', {
              contactName,
            })}
          </p>
        </div>
      ),
      onConfirm: () => handleDeleteContact(contactId),
      confirmButtonText: translate.phrases.banyanApp('Delete Contact'),
    })
  }

  const handleDeleteContact = async (contactId: string) => {
    try {
      const response: DeleteContactResponse = await fetchApiCustomer({
        body: {
          query: contactDeleteQuery,
          variables: {
            id: contactId,
          },
        },
      })

      const success = response?.data?.deleteContact

      if (success) {
        notificationFunctionToUse({
          type: 'success',
          message: translate.phrases.banyanApp('Contact successfully deleted'),
        })

        const newContacts = allContacts.filter((contact) => contact.id !== contactId)

        const newGroups = allGroups.map((group) => {
          return {
            ...group,
            contacts: group.contacts.filter((contact) => contact.id !== contactId),
          }
        })

        userDetailsStore.setState((s) => {
          return {
            ...s,
            contacts: newContacts,
            groups: newGroups,
          }
        })
      } else {
        notificationFunctionToUse({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to delete contact'),
        })
      }
    } catch (error) {
      notificationFunctionToUse({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to delete contact'),
      })
    }
  }

  return (
    <>
      {SearchInputComponent}
      {(filteredAndSortedList as Contact[]).map((contact) => {
        return (
          <ContactsAndGroupsRow
            id={contact.id}
            key={contact.id}
            icon={<IconContact />}
            name={contact.name}
            onEdit={() => {
              onEdit()

              setSelectedContact(contact)
            }}
            onDelete={onDelete}
          />
        )
      })}
    </>
  )
}
