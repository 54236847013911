import {
  SCHEDULED_CONTINUOUS_EVENT,
  SCHEDULED_PULSE_EVENT,
  TIrrigationSchedulerStoreState,
} from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { getDateRange } from 'App/irrigation-scheduler/utils/store/selectors/getDateRange'
import { getVisibleIrrigationZoneEmitters } from 'App/irrigation-scheduler/utils/store/selectors/getVisibleIrrigationZoneEmitters'
import { getEventActions } from 'App/irrigation-scheduler/utils/getEventActions'
import { TFieldAssetValueTypes } from 'App/Map/types'
import { TFieldAssetStoreState } from 'stores/fieldAssetStore'
import { TUserDetailsStoreState } from 'stores/userDetailsStore'

export const getDeletableEvents = (
  fass: TFieldAssetStoreState,
  isss: TIrrigationSchedulerStoreState,
  udss: TUserDetailsStoreState,
) => {
  const visibleIrrigationZoneEmitters = getVisibleIrrigationZoneEmitters(fass, isss)

  const visibleIrrigationZoneEmittersDictionary = visibleIrrigationZoneEmitters.reduce<
    Record<string, TFieldAssetValueTypes.TIrrigationZoneEmitter>
  >((a, b) => ({ ...a, [b.irrigationZoneEmitterId]: b }), {})

  const scheduledEventValues = Object.values(isss.scheduledEvents)
  const scheduledPulseEventValues = Object.values(isss.scheduledPulseIrrigationEvents)
  const deletableEvents: SCHEDULED_CONTINUOUS_EVENT[] = []
  const deletablePulseEvents: SCHEDULED_PULSE_EVENT[] = []
  const dateRange = getDateRange(fass, isss)

  scheduledEventValues.forEach((scheduledEvent) => {
    const selectedIrrigationZoneEmitter =
      visibleIrrigationZoneEmittersDictionary[scheduledEvent.irrigationZoneEmitterId]

    if (selectedIrrigationZoneEmitter) {
      const eventIs3rdPartyControlled = !!selectedIrrigationZoneEmitter?.deviceVendors?.length

      // Note for moment.isBetween: The '[]' in the 4th arg means inclusive start and end
      if (scheduledEvent.dateFrom.isBetween(dateRange.mDateFrom, dateRange.mDateTo, 'seconds', '[]')) {
        if (getEventActions(fass, isss, udss, scheduledEvent, eventIs3rdPartyControlled).canDelete) {
          deletableEvents.push(scheduledEvent)
        }
      }
    }
  })

  scheduledPulseEventValues.forEach((scheduledPulseEvent) => {
    const selectedIrrigationZoneEmitter =
      visibleIrrigationZoneEmittersDictionary[scheduledPulseEvent.irrigationZoneEmitterId]

    if (selectedIrrigationZoneEmitter) {
      const eventIs3rdPartyControlled = !!selectedIrrigationZoneEmitter?.deviceVendors?.length

      // Note for moment.isBetween: The '[]' in the 4th arg means inclusive start and end
      if (scheduledPulseEvent.dateFrom.isBetween(dateRange.mDateFrom, dateRange.mDateTo, 'seconds', '[]')) {
        if (getEventActions(fass, isss, udss, scheduledPulseEvent, eventIs3rdPartyControlled).canDelete) {
          deletablePulseEvents.push(scheduledPulseEvent)
        }
      }
    }
  })

  return { deletableEvents, deletablePulseEvents }
}
