import { CSSObject } from '@emotion/css'

export const fadeInOutStylesMaker = (
  visible: boolean,
  transition: CSSObject['transition'] = '0.3s ease all',
): CSSObject => ({
  opacity: visible ? 1 : 0,
  visibility: visible ? 'visible' : 'hidden',
  transition,
})
