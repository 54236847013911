import { AxisTickPositionsArray } from 'highcharts'
import { translate } from 'i18n/i18n'

const spreadTickPositions = (tickPositions: number[], width: number): Record<number, { label: string }> => {
  const labels: string[] = [
    translate.phrases.banyanApp('Extreme Deficit'),
    translate.phrases.banyanApp('High Deficit'),
    translate.phrases.banyanApp('Moderate Deficit'),
    translate.phrases.banyanApp('Ideal'),
    translate.phrases.banyanApp('Saturated'),
  ]

  const step = (tickPositions.length - 1) / (labels.length - 1)

  return labels.reduce((result, label, i) => {
    const index = Math.round(step * i)
    // if the width of the color axis is too small, the labels will overlap, so we need to add a line break.
    const labelWithLineBreaks = label.split(' ').join('<br>')

    result[tickPositions[index]] = { label: width > 400 ? label : labelWithLineBreaks }

    return result
  }, {} as Record<number, { label: string }>)
}

export const awcHeatmapColorAxisFormatter = (step: {
  value: number
  axis: { tickPositions: AxisTickPositionsArray; width: number }
}) => {
  const tickPositionLabels = spreadTickPositions(step.axis.tickPositions, step.axis.width)

  return tickPositionLabels[step.value]?.label || ''
}
