import React, { useState } from 'react'
import { closeModal, openModal } from '@mantine/modals'
import { translate } from 'i18n/i18n'
import { TActiveNode } from '../types'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { getNodeTypeLabel } from '../utils/getNodeTypeLabel'
import { ServiceIssueList } from './List/List'
import { getServiceIssueTitle } from '../utils/getServiceIssueTitle'
import { ServiceIssueResolution } from './Resolution/Resolution'
import { serviceCenterStore } from '../store/serviceCenterStore'

const MODAL_ID = 'node-maintenance'

export function openNodeMaintenanceModal(node: TActiveNode, serviceIssueId?: number) {
  openModal({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: <NodeMaintenanceModal node={node} serviceIssueId={serviceIssueId} />,
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

interface NodeMaintenanceModalProps {
  node: TActiveNode
  serviceIssueId?: number
}

export const NodeMaintenanceModal: React.FC<NodeMaintenanceModalProps> = ({
  node,
  serviceIssueId: initialSelectedServiceIssueId = null,
}) => {
  const equipmentTypeLabel = getNodeTypeLabel(node.nodeType)

  const [selectedServiceIssueId, setSelectedServiceIssueId] = useState<number | null>(
    initialSelectedServiceIssueId,
  )

  const allServiceIssues = serviceCenterStore.useSelector(serviceCenterStore.selectors.getServiceIssues)
  const nodeServiceIssues = allServiceIssues[node.nodeIdentifier] || []

  const selectedServiceIssue = nodeServiceIssues.find(
    (issue) => issue.serviceIssueId === selectedServiceIssueId,
  )

  const handleOnClose = () => {
    if (selectedServiceIssue) {
      return setSelectedServiceIssueId(null)
    } else {
      closeModal(MODAL_ID)
    }
  }

  let title = translate.phrases.placeholder('{{equipmentType}} Maintenance', {
    equipmentType: equipmentTypeLabel,
  })

  if (selectedServiceIssue) title = getServiceIssueTitle(node, selectedServiceIssue)

  return (
    <>
      <WideHeader title={title} onClose={handleOnClose} isSecondaryModal={!!selectedServiceIssue} />

      {selectedServiceIssue ? (
        <ServiceIssueResolution serviceIssue={selectedServiceIssue} />
      ) : (
        <ServiceIssueList
          node={node}
          serviceIssues={nodeServiceIssues}
          onSelectIssue={setSelectedServiceIssueId}
        />
      )}
    </>
  )
}
