import { translate } from 'i18n/i18n'
import { TUnitConverterOptionsWithoutUnits, TUnitConverterTypicalReturn } from '../types'
import moment from 'moment-timezone'
import { TMomentFormat } from '@semios/app-platform-i18n-core'

const converter = (
  value?: string | number | null,
  options?: TUnitConverterOptionsWithoutUnits & { timezone?: string; shortenDate?: boolean },
): TUnitConverterTypicalReturn => {
  const timezoneToUse = options?.timezone ?? moment.tz.guess()
  const title = translate.phrases.banyanApp('Almond Hull Split')

  let momentValue: moment.Moment | null = null

  if (typeof value === 'string') {
    momentValue = value ? moment.tz(value, timezoneToUse).startOf('day') : null
  }

  if (typeof value === 'number') {
    momentValue = value ? moment.tz(timezoneToUse).set('dayOfYear', value).startOf('day') : null
  }

  let dateFormat: TMomentFormat = 'MMMM D, YYYY'

  if (options?.shortenDate) {
    dateFormat = 'MMM D, YYYY'
  }

  return {
    categoryTitle: () => title,
    categoryTitleWithoutUnit: () => title,
    defaultNumberOfDecimalPlaces: () => 0,
    suffix: () => '',
    shortTitle: () => title,
    title: () => title,
    titleWithoutUnit: () => title,
    value: () => {
      return momentValue ? momentValue.dayOfYear() : null
    },
    valueAsString: () => {
      if (momentValue === null) return translate.phrases.templates('-')

      return translate.dates.format(momentValue, 'M/DD')
    },
    valueWithNoRounding: () => {
      return momentValue ? momentValue.dayOfYear() : null
    },
    valueWithSuffix: () => {
      if (momentValue === null) return translate.phrases.templates('-')

      return translate.dates.format(momentValue, dateFormat)
    },
  }
}

export const almondHullSplit = { almondHullSplit: converter }
