import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import {
  MAP_VISUAL,
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
} from 'stores/mapControlsStore/types'

const updateMapVisuals = (newValueType: TValuesCurrentBlocksValueTypes & TValuesCurrentPointsValueTypes) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.BLOCK]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.BLOCK],
        bee_hours: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.BLOCK].bee_hours ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.POINT]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.POINT],
        bee_hours: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.POINT].bee_hours ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.PROPERTY]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.PROPERTY],
        bee_hours: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.PROPERTY].bee_hours ?? { mItem: 'M_Property' }),
          valueType: newValueType.replace('Sum_', 'SumMinMedianMax_') as TValuesCurrentPropertiesValueTypes,
        },
      },
    },
  }))
}

export const BeeHoursSecondaryDropdown = () => {
  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.BLOCK]?.bee_hours?.valueType,
  )

  const properties = fieldAssetStore.useSelector((s) => s.properties)

  const data = useMemo(() => {
    const returner: { value: TValuesCurrentBlocksValueTypes; label: string }[] = [
      {
        value: 'beeHours_last24hoursSum_hours',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Bee Hours'),
          labelB: translate.phrases.banyanApp('Last {{count}} Hours', { count: 24 }),
        }),
      },
      {
        value: 'beeHours_last7daysSum_hours',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Bee Hours'),
          labelB: translate.phrases.banyanApp('Last {{count}} Days', { count: 7 }),
        }),
      },
      {
        value: 'beeHours_last30daysSum_hours',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Bee Hours'),
          labelB: translate.phrases.banyanApp('Last {{count}} Days', { count: 30 }),
        }),
      },
      {
        value: 'beeHours_next7daysSum_hours',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Bee Hours'),
          labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 7 }),
        }),
      },
      {
        value: 'beeHours_next14daysSum_hours',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Bee Hours'),
          labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 14 }),
        }),
      },
    ]

    return returner
  }, [properties, value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
