import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { LoadingOverlay } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'
import { Filters } from './Filters/Filters'
import { NodeList } from './NodeList/NodeList'
import { getNodeEquipmentType } from '../../Map/_utils/getNodeEquipmentType'
import { TActiveGateway, TActiveNode, TNodeStatus, TPlannedGateway, TPlannedNode } from '../../types'
import { getIdentifier } from '../../utils/getIdentifier'
import { getNodeDeviceTypes } from '../../Map/_utils/getNodeDeviceTypes'
import { equipmentStatusPanelStore } from '../../store/equipmentStatusPanelStore'
import { getActiveNodeStatus } from '../../Map/_utils/getActiveNodeStatus'
import { selectedFieldAssetsStore } from '../../../../stores/selectedFieldAssetsStore'
import { serviceCenterActiveNodesGet } from '../../utils/api/serviceCenterActiveNodesGet'
import { serviceCenterNodeServiceIssuesGet } from '../../utils/api/serviceCenterNodeServiceIssuesGet'
import { serviceCenterNodeLogsGet } from '../../utils/api/serviceCenterNodeLogsGet'
import { showNotification } from '../../../../utils/showNotification'
import { EQUIPMENT_TYPES } from '../../Map/_utils/equipmentTypes'
import { DEVICE_TYPES } from '../../Map/_utils/deviceTypes'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import { serviceCenterPlannedNodesGet } from '../../utils/api/serviceCenterPlannedNodesGet'
import { isPlannedNode } from '../../utils/isPlannedNode'
import { MapContext } from '../../../Map/MapContext/MapContext'

export type NodeWithStatus = (TActiveNode | TActiveGateway | TPlannedNode | TPlannedGateway) & {
  status: TNodeStatus
}

export const EquipmentStatus = () => {
  const [loading, setLoading] = useState(false)
  const { loadingData: loadingMapData } = useContext(MapContext)
  const selectedMapPropertyId = selectedFieldAssetsStore.useSelector((s) => s.property)

  const selectedPropertyId = equipmentStatusPanelStore.useSelector(
    equipmentStatusPanelStore.selectors.getPropertyId,
  )

  const fetchPropertyData = useCallback(
    async (propertyId: number) => {
      try {
        setLoading(true)

        const [activeNodes, plannedNodes, nodeLogs, serviceIssues] = await Promise.all([
          serviceCenterActiveNodesGet([propertyId]),
          serviceCenterPlannedNodesGet([propertyId]),
          serviceCenterNodeLogsGet([propertyId]),
          serviceCenterNodeServiceIssuesGet([propertyId]),
        ])

        equipmentStatusPanelStore.actions.setNodes([...activeNodes, ...plannedNodes])

        equipmentStatusPanelStore.actions.setNodeLogs(nodeLogs)

        equipmentStatusPanelStore.actions.setServiceIssues(serviceIssues)
      } catch (error) {
        Sentry.captureException(error)

        showNotification({
          type: 'error',
          message: translate.phrases.placeholder('Error: could not load data for the selected property!'),
        })
      } finally {
        setLoading(false)
      }
    },
    [setLoading],
  )

  useEffect(() => {
    if (!selectedPropertyId) {
      // If no property is selected in the panel, use the selected property on the map
      if (selectedMapPropertyId) {
        equipmentStatusPanelStore.actions.setPropertyId(selectedMapPropertyId)
      }
    } else if (selectedPropertyId !== selectedMapPropertyId) {
      equipmentStatusPanelStore.actions.setNodes([])

      equipmentStatusPanelStore.actions.setNodeLogs({})

      equipmentStatusPanelStore.actions.setServiceIssues({})

      // If the selected property in the panel is different than the selected property on the map, load the data for the selected property
      fetchPropertyData(selectedPropertyId)
    }

    // Reset filters when the selected property changes or the panel unmounts
    return function cleanup() {
      equipmentStatusPanelStore.actions.resetFilters()
    }
  }, [selectedPropertyId])

  const filters = equipmentStatusPanelStore.useSelector(equipmentStatusPanelStore.selectors.getFilters)

  const nodes =
    selectedMapPropertyId === selectedPropertyId
      ? serviceCenterStore.useSelector(serviceCenterStore.selectors.getPlannedAndActiveNodes)
      : equipmentStatusPanelStore.useSelector(equipmentStatusPanelStore.selectors.getNodes)

  const nodeLogs =
    selectedMapPropertyId === selectedPropertyId
      ? serviceCenterStore.useSelector(serviceCenterStore.selectors.getNodeLogs)
      : equipmentStatusPanelStore.useSelector(equipmentStatusPanelStore.selectors.getNodeLogs)

  const serviceIssues =
    selectedMapPropertyId === selectedPropertyId
      ? serviceCenterStore.useSelector(serviceCenterStore.selectors.getServiceIssues)
      : equipmentStatusPanelStore.useSelector(equipmentStatusPanelStore.selectors.getServiceIssues)

  const shapedNodes = useMemo(
    () =>
      nodes.map((node) => {
        let status

        if (isPlannedNode(node)) {
          status = TNodeStatus.PLANNED
        } else {
          const nodeId = getIdentifier(node as TActiveNode)
          const log = nodeLogs[nodeId]
          const nodeServiceIssues = serviceIssues[nodeId]

          status = getActiveNodeStatus(log)

          if (nodeServiceIssues?.length) status = TNodeStatus.ERROR
        }

        return {
          ...node,
          status,
        }
      }) as NodeWithStatus[],
    [nodes, nodeLogs, serviceIssues],
  )

  const filteredNodes = useMemo(() => {
    let formattedSearchKeywords: string[] = []

    if (filters.searchKeywords.length) {
      formattedSearchKeywords = filters.searchKeywords.split(',').map((k) => k.trim().toLowerCase())
    }

    let filterByEquipment = false

    // Only filter by equipment type if the user has selected some equipment, but not all
    if (filters.equipmentTypes.length > 0 && filters.equipmentTypes.length !== EQUIPMENT_TYPES.length) {
      filterByEquipment = true
    }

    let filterByDevices = false

    // Only filter by devices if the user has selected some devices, but not all
    if (filters.deviceTypes.length > 0 && filters.deviceTypes.length !== DEVICE_TYPES.length) {
      filterByDevices = true
    }

    const filteredNodes = shapedNodes.filter((node) => {
      if (filters.statuses.length && !filters.statuses.includes(node.status)) return false

      const equipmentType = getNodeEquipmentType(
        (node as TActiveNode).nodeType || (node as TActiveGateway).gatewayType,
      )

      if (filterByEquipment) {
        if (!filters.equipmentTypes.includes(equipmentType)) return false
      }

      if (filterByDevices) {
        const nodeDeviceTypes = getNodeDeviceTypes((node as TActiveNode).devices)

        if (!filters.deviceTypes.some((deviceType) => nodeDeviceTypes.includes(deviceType))) return false
      }

      if (formattedSearchKeywords.length) {
        const nodeId = getIdentifier(node as TActiveNode) || ''

        if (
          !formattedSearchKeywords.some(
            (keyword) => node.name?.toLowerCase().includes(keyword) || nodeId.toLowerCase() === keyword,
          )
        ) {
          return false
        }
      }

      return true
    })

    return filteredNodes.sort((nodeA, nodeB) => {
      // Sort by status (ERROR first, then by most recent service issue)
      if (nodeA.status === TNodeStatus.ERROR && nodeB.status !== TNodeStatus.ERROR) return -1
      else if (nodeB.status === TNodeStatus.ERROR && nodeA.status !== TNodeStatus.ERROR) return 1
      else if (nodeA.status === TNodeStatus.ERROR && nodeB.status === TNodeStatus.ERROR) {
        const nodeAId = !isPlannedNode(nodeA) && getIdentifier(nodeA as TActiveNode)
        const nodeBId = !isPlannedNode(nodeB) && getIdentifier(nodeB as TActiveNode)
        const nodeAServiceIssues = nodeAId ? serviceIssues[nodeAId] : []
        const nodeBServiceIssues = nodeBId ? serviceIssues[nodeBId] : []

        if (!nodeAServiceIssues?.length && nodeBServiceIssues?.length) return 0

        const mostRecentServiceIssueForNodeA = nodeAServiceIssues?.sort(
          (a, b) => new Date(b.reportedOn).getTime() - new Date(a.reportedOn).getTime(),
        )[0]

        const mostRecentServiceIssueForNodeB = nodeBServiceIssues?.sort(
          (a, b) => new Date(b.reportedOn).getTime() - new Date(a.reportedOn).getTime(),
        )[0]

        return (
          new Date(mostRecentServiceIssueForNodeB.reportedOn).getTime() -
          new Date(mostRecentServiceIssueForNodeA.reportedOn).getTime()
        )
      } else {
        return 0
      }
    })
  }, [shapedNodes, filters])

  return (
    <div css={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Filters
        selectedPropertyId={selectedPropertyId}
        filters={filters}
        onFilterChange={equipmentStatusPanelStore.actions.setFilters}
        onPropertySelect={equipmentStatusPanelStore.actions.setPropertyId}
      />

      <LoadingOverlay visible={loading || loadingMapData} />

      {!loading && <NodeList nodes={filteredNodes} nodeLogs={nodeLogs} totalNodes={shapedNodes.length} />}
    </div>
  )
}
