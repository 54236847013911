import { routes } from '@semios/app-platform-banyan-route-definitions'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import { sortByKey } from 'utils/sortByKey'
import { TSearchResultsItem } from '../types'
import { administrativeAreaLevel1T } from './administrativeAreaLevel1T'

export const getSearchResultsAdministrativeAreaLevel1 = ({
  properties,
  searchText,
}: {
  properties: NonNullable<routes.UserAppStartup.Response['properties']>
  searchText: string
}): TSearchResultsItem[] => {
  const administrativeAreaLevel1Possibilities = Object.values(properties)
    .sort(sortByKey('propertyName'))
    .reduce((a: Record<string, TFieldAssetKeyTypes.TPropertyId[]>, b) => {
      const translatedLevel1 = administrativeAreaLevel1T({
        country: b.country,
        gAdministrativeAreaLevel1: b.gAdministrativeAreaLevel1 ?? '',
      })

      a[translatedLevel1] = a[translatedLevel1] ?? []

      a[translatedLevel1].push(b.propertyId)

      return a
    }, {})

  const administrativeAreaLevel1Results: {
    administrativeAreaLevel1: string
    propertyIds: TFieldAssetKeyTypes.TPropertyId[]
  }[] = []

  Object.entries(administrativeAreaLevel1Possibilities).forEach(([administrativeAreaLevel1, propertyIds]) => {
    if (searchStringFromStringWithSpaces(searchText, administrativeAreaLevel1)) {
      administrativeAreaLevel1Results.push({ administrativeAreaLevel1, propertyIds })
    }
  })

  return administrativeAreaLevel1Results.sort(sortByKey('administrativeAreaLevel1')).map((o) => ({
    type: 'ADMINISTRATIVE_AREA_LEVEL_1',
    label: o.administrativeAreaLevel1,
    propertyIds: o.propertyIds,
    value: `ADMINISTRATIVE_AREA_LEVEL_1-${o.administrativeAreaLevel1}`,
    group: translate.phrases.templates('{{label}} ({{count}})', {
      label: translate.phrases.banyanApp('State/Province'),
      count: administrativeAreaLevel1Results.length,
    }),
  }))
}
