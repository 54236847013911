import { createSafeContext } from '@mantine/utils'
import { Dispatch, SetStateAction } from 'react'
import type { IMultiSelectProps, TGroupedOptions, TOptions } from './MultiSelect.types'

type IMultiSelectContext<T> = IMultiSelectProps<T> & {
  optionsLabel?: string
  groupedObject: TGroupedOptions
  groupValues: string[]
  highlightOption: number
  inputValue: string
  isDropdownOpen: boolean
  isSelectedValue: (item: T) => boolean
  onArrowKeyNavigation: React.KeyboardEventHandler<HTMLInputElement>
  onFocus: () => void
  onRemoveSelectedItem: (item: T) => void
  onSelectItem: (item: T) => void
  options: T[]
  selectedValues: T[]
  setInputValue: Dispatch<SetStateAction<string>>
  setSelectedValues: Dispatch<SetStateAction<T[]>>
  setDropdownOpen: Dispatch<SetStateAction<boolean>>
  toggleOptionList: () => void
}

export const [MultiSelectContextProvider, useMultiSelectContext] = createSafeContext<
  IMultiSelectContext<TOptions>
>('MultiSelect component was not found in the tree')
