import { Select, SelectProps } from '@mantine/core'
import { forwardRef } from 'react'
import { colors } from 'settings/colors'

interface ItemProps extends React.ComponentPropsWithoutRef<'input'> {
  label: string
  value: string
  selectedItem: string | null
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(function MyInput(
  { label, value, selectedItem, ...others }: ItemProps,
  ref,
) {
  return (
    <div
      ref={ref}
      {...others}
      css={{ backgroundColor: `${selectedItem === value ? colors.primary : undefined} !important` }}
    >
      <div>{label}</div>
    </div>
  )
})

export const SelectDropdown = (props: SelectProps) => {
  return (
    <Select
      {...props}
      itemComponent={(item) => (
        <SelectItem label={item.label} value={item.value} selectedItem={props.value} {...item} />
      )}
    />
  )
}
