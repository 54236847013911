import { roundToDecimalPlaces } from '@semios/app-platform-common'
import { TKcDateRange } from 'App/Map/UserSettingsMenu/Shared/EvapotranspirationSettings/types'
import { isNil } from 'lodash'
import moment from 'moment-timezone'

export const getKcForDay = ({
  date,
  kcRangesData,
  timezone,
  kcMultiplier,
}: {
  date: moment.Moment
  kcRangesData: TKcDateRange[]
  timezone: string
  kcMultiplier: number
}) => {
  if (!date || !kcRangesData?.length) return null

  const foundKcConfigurationForDay = kcRangesData.find(({ dateRangeStart, dateRangeEnd }) => {
    const startMoment = moment.tz(dateRangeStart, timezone)
    const endMoment = moment.tz(dateRangeEnd, timezone)

    return date.isBetween(startMoment, endMoment, 'day', '[]')
  })

  if (
    !foundKcConfigurationForDay ||
    isNil(foundKcConfigurationForDay.kcStart) ||
    isNil(foundKcConfigurationForDay.kcEnd)
  )
    return null

  const { kcStart, kcEnd, linearInterpolation } = foundKcConfigurationForDay
  const modifiedKcStart = kcStart * kcMultiplier

  if (!linearInterpolation) {
    return roundToDecimalPlaces(modifiedKcStart, 2)
  }

  const modifiedKcEnd = kcEnd * kcMultiplier
  const startMoment = moment.tz(foundKcConfigurationForDay.dateRangeStart, timezone)
  const endMoment = moment.tz(foundKcConfigurationForDay.dateRangeEnd, timezone)
  const durationOfDateRange = endMoment.diff(startMoment, 'days')
  const currentDateDifferenceFromStart = date.diff(startMoment, 'days')

  if (durationOfDateRange === 0) {
    return roundToDecimalPlaces(modifiedKcStart, 2)
  }

  const interpolatedKc =
    ((modifiedKcEnd - modifiedKcStart) / durationOfDateRange) * currentDateDifferenceFromStart +
    modifiedKcStart

  return roundToDecimalPlaces(interpolatedKc, 2)
}
