import { Tabs } from 'components/Tabs'
import { TabHeading } from 'components/Tabs/Tabs.types'

export const TabsExamples = () => {
  const tabHeadings: TabHeading[] = [
    {
      label: 'Dashboard',
      value: 'Dashboard',
    },
    {
      label: 'Summary',
      value: 'Summary',
    },
    {
      label: 'Graphs',
      value: 'Graphs',
    },
    {
      label: 'Manual Traps',
      value: 'Manual Traps',
    },
    {
      label: 'Scout Field Notes',
      value: 'Scout Field Notes',
    },
  ]

  return (
    <Tabs containerWidth={900} defaultTab={tabHeadings[0].value}>
      <Tabs.TabHeaderList>
        {tabHeadings.map(({ label, value }, index) => {
          return (
            <Tabs.TabHeader
              value={value}
              key={index}
              onClick={() => {
                // eslint-disable-next-line no-console
                console.log('clicked')
              }}
            >
              {label}
            </Tabs.TabHeader>
          )
        })}
      </Tabs.TabHeaderList>
      <Tabs.TabPanelList>
        {tabHeadings.map((tabHeading, index) => {
          return (
            <Tabs.TabPanel value={tabHeading.value} key={index}>
              <h3>{tabHeading.label}</h3>
              <div>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                been the industry standard dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book. It has survived not only five
                centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It
                was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                passages, and more recently with desktop publishing software like Aldus PageMaker including
                versions of Lorem Ipsum.
              </div>
            </Tabs.TabPanel>
          )
        })}
      </Tabs.TabPanelList>
    </Tabs>
  )
}
