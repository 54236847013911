import { routes } from '@semios/app-platform-banyan-route-definitions'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { DropdownSelectorBlock } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorBlock/DropdownSelectorBlock'
import { getXDateFormat } from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { EAggregationInterval } from 'App/Map/types'
import { StackedChartSection, TChartSeries } from 'components/StackedChart/types'
import { XAxisPlotBandsOptions } from 'highcharts'
import { translate } from 'i18n/i18n'
import { alphaColorReplacer, colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { unitConverter } from 'utils/unitConverter/unitConverter'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_BEE_HOURS_DETAILS' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.HOURLY }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  beeHours: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.bee_hours || !selectedFieldAssets.block) return {}

  if (
    !doesSelectedBlockHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
    })
  )
    return {}

  return {
    blocks: {
      blockIds: [selectedFieldAssets.block],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.beeHours().categoryTitleWithoutUnit(),
    titleChildren: (
      <DropdownSelectorBlock
        valuesTimeseriesToFilterOn={Object.keys(valuesRequested)}
        showAssociatedWeatherStationName
      />
    ),
    id: 'stackem-bee-hours',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const blockId = String(selectedFieldAssetsStore.getState().block)
  const { compareSeasonsInterval, hasExtraRightPadding } = detailsPanelStore.getState()
  const beeHoursData = data?.blocks?.[blockId]?.values?.beeHours?.[0]
  const aggregationInterval = beeHoursData?.metadata.aggregationInterval
  const isDaily = aggregationInterval === EAggregationInterval.DAILY
  const hasActivityPlotBands = aggregationInterval === EAggregationInterval.HOURLY && !compareSeasonsInterval

  if (hasExtraRightPadding !== isDaily) {
    detailsPanelStore.setState((prev) => ({
      ...prev,
      hasExtraRightPadding: isDaily,
    }))
  }

  const latestNonForecastDate = beeHoursData?.metadata.forecastStartsAt
    ? +new Date(beeHoursData?.metadata.forecastStartsAt)
    : +new Date()

  const series: TChartSeries[] = []
  const beeHoursCumulativeHoursData: [number, number | null][] = []
  const beeHoursDailyHoursData: [number, number | null][] = []

  // Dummy first element added to prevent lodash merge from overwriting it
  const beeHoursActivityPlotBands: XAxisPlotBandsOptions[] = [
    {
      id: 'plotBand-forecast',
    },
  ]

  const oneHourInMs = 3600000

  ;(beeHoursData?.timeseries ?? []).forEach((d) => {
    const timestamp = +new Date(d.timestamp)

    beeHoursCumulativeHoursData.push([timestamp, unitConverter.beeHours(d.value?.cumulative).value()])

    if (isDaily) beeHoursDailyHoursData.push([timestamp, unitConverter.beeHours(d.value?.current).value()])

    if (d.value?.current && hasActivityPlotBands) {
      beeHoursActivityPlotBands.push({
        color: alphaColorReplacer(colors.forestGreen, 0.1),
        from: timestamp,
        to: timestamp + oneHourInMs,
        id: 'beeHoursActivity',
      })
    }
  })

  const beeHoursCumulativeHours: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.blue600,
    name: translate.phrases.templates('{{labelA}} ({{labelB}})', {
      labelA: translate.phrases.banyanApp('Bee Hours'),
      labelB: translate.phrases.banyanApp('Cumulative'),
    }),
    tooltip: {
      valueDecimals: unitConverter.beeHours().defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${unitConverter.beeHours().suffix()}`,
    },
    yAxis: 0,
    data: beeHoursCumulativeHoursData,
    type: 'line',
    id: 'beeHoursCumulative',
  }

  const beeHoursDailyHours: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.pink,
    name: translate.phrases.templates('{{labelA}} ({{labelB}})', {
      labelA: translate.phrases.banyanApp('Bee Hours'),
      labelB: translate.phrases.banyanApp('Daily'),
    }),
    tooltip: {
      valueDecimals: unitConverter.beeHours().defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${unitConverter.beeHours().suffix()}`,
    },
    yAxis: 1,
    data: beeHoursDailyHoursData,
    type: 'column',
    id: 'beeHoursDaily',
    clip: false,
  }

  const beeHoursActivityLegendSeries: TChartSeries = {
    id: 'activity',
    color: alphaColorReplacer(colors.forestGreen, 0.1),
    data: [],
    name: translate.phrases.banyanApp('Period of Bee Activity'),
    type: 'area',
    hideFromTooltip: true,
    events: {
      legendItemClick: function (event) {
        const visible = !event.target.visible

        if (!visible) {
          try {
            // @ts-ignore
            this.chart.xAxis?.[0]?.plotLinesAndBands.forEach((pl) => {
              if (pl?.id.startsWith('beeHoursActivity')) {
                this.chart.xAxis[0].removePlotLine(pl.id)
              }
            })
          } catch (err) {
            // TODO: probably not errorLogger, but maybe something here
          }
        } else {
          beeHoursActivityPlotBands.forEach((pl) => {
            this.chart.xAxis[0].addPlotBand(pl)
          })
        }
      },
    },
  }

  series.push(beeHoursCumulativeHours)

  if (isDaily) series.push(beeHoursDailyHours)

  if (hasActivityPlotBands) series.push(beeHoursActivityLegendSeries)

  if (compareSeasonsInterval) {
    const beeHoursCompareSeasonsData = compareSeasonsData?.blocks?.[blockId]?.values?.beeHours?.[0]

    if (isDaily) {
      series.push(
        makeCompareSeasonsSeriesFromRegularSeries(beeHoursDailyHours, {
          data: (beeHoursCompareSeasonsData?.timeseries ?? []).map((d) => [
            +new Date(d.timestamp),
            unitConverter.beeHours(d.value?.current).value(),
          ]),
        }),
      )
    }

    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(beeHoursCumulativeHours, {
        data: (beeHoursCompareSeasonsData?.timeseries ?? []).map((d) => [
          +new Date(d.timestamp),
          unitConverter.beeHours(d.value?.cumulative).value(),
        ]),
      }),
    )
  }

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp: latestNonForecastDate,
          },
          chart: {
            type: 'line',
          },
          series,
          tooltip: { xDateFormat: getXDateFormat(isDaily) },
          xAxis: {
            plotBands: beeHoursActivityPlotBands,
          },
          yAxis: [
            {
              labels: {
                style: {
                  fontSize: '14px',
                },
              },
              title: {
                text: translate.phrases.banyanApp('Cumulative'),
                offset: -30,
                rotation: 0,
                y: -10,
                align: 'high',
              },
              opposite: false,
            },
            {
              labels: {
                style: {
                  fontSize: '14px',
                },
              },
              title: {
                text: translate.phrases.banyanApp('Daily'),
                offset: 17,
                rotation: 0,
                y: -10,
                align: 'high',
              },
              opposite: true,
              softMax: 20,
            },
          ],
        },
      },
    ],
  }
}
