import { getFixedValueOrUnknown } from './getFixedValueOrUnknown'
import { alphaColorReplacer } from 'settings/colors'
import { groupTooltipRowsIntoColumns } from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/soil/_utils/groupTooltipRowsIntoColumns'
import { TTooltipFormatterContextObject } from '../../types'
import { minAverageMaxCellMaker } from './minAverageMaxCellMaker'

export const buildTooltipDualColumnValueRowsForTooltip = (points: TTooltipFormatterContextObject[]) => {
  const groupedRows = groupTooltipRowsIntoColumns(points)

  let template = ''

  Object.values(groupedRows).forEach(([val1, val2]) => {
    const val1ValueDecimals = val1?.series.userOptions.tooltip?.valueDecimals ?? 0
    const val2ValueDecimals = val2?.series.userOptions.tooltip?.valueDecimals ?? 0

    const leftColumnValueCell =
      val1 && val1.series.userOptions.hideFromTooltip !== true
        ? minAverageMaxCellMaker({
            seriesId: String(val2?.series.userOptions.id),
            seriesColor: val1.series.color as string,
            seriesName: val1.series.name,
            low:
              val1.point.low !== undefined ? getFixedValueOrUnknown(val1.point.low, val1ValueDecimals) : null,
            high:
              val1.point.low !== undefined
                ? getFixedValueOrUnknown(val1.point.high, val1ValueDecimals)
                : null,
            value: getFixedValueOrUnknown(val1.point.y, val1ValueDecimals),
            paddingRight: true,
            xYear: `${new Date(val1.point.x).getFullYear()}`,
            valueSuffix: val1.series.userOptions.tooltip?.valueSuffix ?? '',
            compareSeasons: val1.point.compareSeasons?.year
              ? {
                  year: val1.point.compareSeasons?.year,
                  value: getFixedValueOrUnknown(val1.point.compareSeasons.y, val1ValueDecimals),
                  low:
                    val1.point.compareSeasons?.point?.low !== undefined
                      ? getFixedValueOrUnknown(val1.point.compareSeasons.point.low, val1ValueDecimals)
                      : null,
                  high:
                    val1.point.compareSeasons?.point?.high !== undefined
                      ? getFixedValueOrUnknown(val1.point.compareSeasons.point.high, val1ValueDecimals)
                      : null,
                  seriesColor:
                    // @ts-ignore
                    val1.series.userOptions.compareSeasonsColor || alphaColorReplacer(val1.series.color, 0.5),
                }
              : undefined,
          })
        : ''

    const rightColumnValueCell =
      val2 && val2.series.userOptions.hideFromTooltip !== true
        ? minAverageMaxCellMaker({
            seriesId: String(val2?.series.userOptions.id),
            seriesColor: val2.series.color as string,
            seriesName: val2.series.name,
            value: getFixedValueOrUnknown(val2.point.y, val2ValueDecimals),
            low:
              val2.point.low !== undefined ? getFixedValueOrUnknown(val2.point.low, val2ValueDecimals) : null,
            high:
              val2.point.low !== undefined
                ? getFixedValueOrUnknown(val2.point.high, val2ValueDecimals)
                : null,
            xYear: `${new Date(val2.point.x).getFullYear()}`,
            valueSuffix: val2.series.userOptions.tooltip?.valueSuffix ?? '',
            compareSeasons: val2.point.compareSeasons?.year
              ? {
                  year: val2.point.compareSeasons?.year,
                  value: getFixedValueOrUnknown(val2.point.compareSeasons.y, val2ValueDecimals),
                  low:
                    val2.point.compareSeasons?.point?.low !== undefined
                      ? getFixedValueOrUnknown(val2.point.compareSeasons.point.low, val2ValueDecimals)
                      : null,
                  high:
                    val2.point.compareSeasons?.point?.high !== undefined
                      ? getFixedValueOrUnknown(val2.point.compareSeasons.point.high, val2ValueDecimals)
                      : null,
                  seriesColor:
                    // @ts-ignore
                    val2.series.userOptions.compareSeasonsColor || alphaColorReplacer(val2.series.color, 0.5),
                }
              : undefined,
          })
        : ''

    template += `
<tr>
${leftColumnValueCell}
${rightColumnValueCell}
</tr>`
  })

  return template
}
