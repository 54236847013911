import { NumberInput } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { AlertsSettings, Rule } from '../../../../settings/alertSettings'
import { alertRulesObjectFromArray } from '../../_utils/alertRulesObjectFromArray'
import { AlertsValidators, isNumberOrNumericString } from '../../../../AlertValidators/AlertValidators'
import { validateStatusErrorHelper } from '../../../../../_utils/validateStatusErrorHelper'
import { helpTextErrorHelper } from '../../_utils/helpTextErrorHelper'
import { updateAlertRules } from '../../_utils/updateAlertRules'
import { TranslatePhraseWrapper } from '../../../../components/TranslatePhraseWrapper'
import { getErrorStyle } from '../../../../_utils/getErrorStyle'
import { ErrorTextWrapper } from '../../../../components/ErrorTextWrapper'
import { TranslateComponentWrapper } from '../../../../components/TranslateComponentWrapper'

export const AlternariaThreshold = ({
  rules,
  setRules,
  setThresholdIsValid,
  triedToSubmit,
}: {
  rules: Rule[]
  setRules: Dispatch<SetStateAction<Rule[]>>
  setThresholdIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  useEffect(() => {
    if (!rules || !rules.length) setRules(AlertsSettings.emptyRules.alternaria)
  })

  const { alternaria: alternariaRule, numberOfDays: numberOfDaysRule } = alertRulesObjectFromArray(rules)
  const alternaria = alternariaRule ? alternariaRule.value : null
  const numberOfDays = numberOfDaysRule ? numberOfDaysRule.value : null
  // check if user-enterable fields are dirty
  const alternariaIsDirty = alternaria !== null || triedToSubmit
  const numberOfDaysIsDirty = numberOfDays !== null || triedToSubmit
  // validate the user-entered values
  const alternariaError = AlertsValidators.alternaria({ alternaria })
  const numberOfDaysError = AlertsValidators.numberOfDays({ numberOfDays })
  // attach a status
  const alternariaValidateStatus = validateStatusErrorHelper(alternariaIsDirty && !!alternariaError)
  const numberOfDaysValidateStatus = validateStatusErrorHelper(numberOfDaysIsDirty && !!numberOfDaysError)
  // attach an error message
  const alternariaHelp = helpTextErrorHelper(alternariaIsDirty && alternariaError)
  const numberOfDaysHelp = helpTextErrorHelper(numberOfDaysIsDirty && numberOfDaysError)
  const errorStyle = getErrorStyle()

  // update the rules upon changes
  useEffect(() => {
    setThresholdIsValid(!alternariaError && !numberOfDaysError)
  }, [alternaria, numberOfDays])

  return (
    <>
      <TranslatePhraseWrapper>
        <translate.Phrases.banyanApp
          k="Send alert when Alternaria Cumulative Disease Severity Value (DSV) is greater than or equal to <numberInput1/> during the last <numberInput2/> days"
          c={{
            numberInput1: (
              <TranslateComponentWrapper>
                <NumberInput
                  css={{ width: 80, margin: '0 5px' }}
                  onChange={(value) =>
                    updateAlertRules({ rules, setRules, rulesToChange: { alternaria: { value } } })
                  }
                  min={0}
                  placeholder={translate.phrases.banyanApp('e.g. {{example}}', { example: 8 })}
                  type="number"
                  classNames={{ input: alternariaValidateStatus ? errorStyle : undefined }}
                  value={isNumberOrNumericString(alternaria) ? Number(alternaria) : ''}
                />
                <div />
              </TranslateComponentWrapper>
            ),
            numberInput2: (
              <TranslateComponentWrapper>
                <NumberInput
                  css={{ width: 80, margin: '0 5px' }}
                  onChange={(value) =>
                    updateAlertRules({ rules, setRules, rulesToChange: { numberOfDays: { value } } })
                  }
                  precision={0}
                  placeholder={translate.phrases.banyanApp('e.g. {{example}}', { example: 7 })}
                  type="number"
                  classNames={{ input: numberOfDaysValidateStatus ? errorStyle : undefined }}
                  value={isNumberOrNumericString(numberOfDays) ? Number(numberOfDays) : ''}
                />
                <div />
              </TranslateComponentWrapper>
            ),
          }}
        />
      </TranslatePhraseWrapper>
      <div>
        {alternariaHelp && <ErrorTextWrapper>{alternariaHelp}</ErrorTextWrapper>}
        {numberOfDaysHelp && <ErrorTextWrapper>{numberOfDaysHelp}</ErrorTextWrapper>}
      </div>
    </>
  )
}
