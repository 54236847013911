import { css } from '@emotion/css'
import {
  Anchor,
  Center,
  createStyles,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { Button } from 'components/Button/Button'
import { SemiosLogoColor } from 'components/SemiosLogo/SemiosLogoColor'
import { FC, ReactNode } from 'react'
import { colors } from 'settings/colors'
import { z } from 'zod'
import { useKeyboardVisible } from 'utils/useKeyboardVisible'

export type InputPropsType = {
  placeholder: string
  key: string
  initialValue: string
  isPasswordInput?: boolean
}

type AuthFormProps = {
  inputProps: InputPropsType[]
  validationSchema: z.Schema
  buttonText: string
  buttonRightIcon?: ReactNode
  anchorText?: string
  onAnchorPress?: () => void
  onSubmit: (values: { [k: string]: string }) => void
  submitLoading?: boolean
  header?: ReactNode
}

const useStyles = createStyles((theme) => ({
  invalid: {
    borderColor: theme.colors.red[0],
  },
}))

export const AuthenticationForm: FC<AuthFormProps> = ({
  inputProps,
  validationSchema,
  buttonText,
  buttonRightIcon,
  anchorText,
  onAnchorPress,
  onSubmit,
  submitLoading = false,
  header,
}) => {
  const { classes } = useStyles()
  const { isKeyboardVisible } = useKeyboardVisible()
  const keyboardAvoiding = isKeyboardVisible ? { marginTop: '-12vh' } : {}

  const form = useForm({
    initialValues: Object.fromEntries(
      inputProps.map((input) => {
        return [input.key, input.initialValue]
      }),
    ),

    validate: zodResolver(validationSchema),

    validateInputOnBlur: true,
  })

  return (
    <div
      css={{
        padding:
          'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        ...keyboardAvoiding,
      }}
    >
      <div
        className={css`
          padding-left: 30px;
          margin-top: 33px;
          height: 91px;
        `}
      >
        <SemiosLogoColor width={272} />
      </div>
      <div
        className={css`
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 65vh;
        `}
      >
        <LoadingOverlay visible={submitLoading} />

        <Paper
          radius="md"
          p="xl"
          className={css`
            width: 420px;
          `}
        >
          {header}
          <form
            onSubmit={form.onSubmit((values) => onSubmit(values))}
            className={css`
              margin-top: 20px;
            `}
          >
            <Stack>
              {inputProps.map((input) => {
                const { placeholder, key, isPasswordInput } = input
                const formInputProps = form.getInputProps(key)

                return !isPasswordInput ? (
                  <TextInput
                    key={key}
                    placeholder={placeholder}
                    className={css`
                      margin-top: 10px;
                      margin-bottom: 10px;
                    `}
                    classNames={{ input: formInputProps.error ? classes.invalid : undefined }}
                    {...formInputProps}
                  />
                ) : (
                  <PasswordInput
                    key={key}
                    placeholder={placeholder}
                    className={css`
                      margin-top: 10px;
                      margin-bottom: 10px;
                    `}
                    classNames={{ input: formInputProps.error ? classes.invalid : undefined }}
                    {...formInputProps}
                  />
                )
              })}
            </Stack>

            <Center
              className={css`
                margin-top: 30px;
              `}
            >
              <Button
                fullWidth
                variant="primary"
                type="submit"
                rightIcon={buttonRightIcon}
                disabled={form.isTouched() && !form.isValid()}
              >
                {buttonText}
              </Button>
            </Center>

            {anchorText && (
              <Center
                className={css`
                  margin-top: 40px;
                `}
              >
                <Anchor onClick={onAnchorPress}>
                  <Text color={colors.primary} underline={true} weight={600} size={14}>
                    {anchorText}
                  </Text>
                </Anchor>
              </Center>
            )}
          </form>
        </Paper>
      </div>
    </div>
  )
}
