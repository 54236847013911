import { createSafeContext } from '@mantine/utils'
import React, { Dispatch, SetStateAction } from 'react'
import { TABS_ERRORS } from './Tabs.error'
import { SelectedPosition } from './Tabs.types'

interface ITabContext {
  collapsed: boolean
  setCollapsed: Dispatch<SetStateAction<boolean>>
  containerWidth: number
  activeTab: string
  setActiveTab: Dispatch<SetStateAction<string>>
  showTabs: boolean
  setShowTabs: Dispatch<SetStateAction<boolean>>
  selectedPosition: SelectedPosition
  setSelectedPosition: Dispatch<SetStateAction<SelectedPosition>>
  children: React.ReactNode
  styles?: { list?: Record<string, string | number> }
  stickyHeader?: boolean
}

export const [TabsContextProvider, useTabsContext] = createSafeContext<ITabContext>(TABS_ERRORS.context)
