import { useState } from 'react'
import { DeleteEmitterGroupModal } from './DeleteEmitterGroupModal'
import { EditEmitterGroupModal } from './EditEmitterGroupModal'
import { PropertySection } from './PropertySection'
import type { TFieldAssetValueTypes } from 'App/Map/types'
import { sortByKey } from 'utils/sortByKey'
import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'

export const GroupsTab = ({
  filterPropertiesText,
  properties,
}: {
  filterPropertiesText: string
  properties: SharedTypes.TProperty[]
}) => {
  const [selectedZoneGroupEdit, setSelectedZoneGroupEdit] = useState<
    (TFieldAssetValueTypes.TIrrigationZoneEmitterGroup & { propertyId: number }) | null
  >(null)

  const [selectedZoneGroupDelete, setSelectedZoneGroupDelete] = useState<
    (TFieldAssetValueTypes.TIrrigationZoneEmitterGroup & { propertyId: number }) | null
  >(null)

  return (
    <>
      {properties.map((property) => (
        <PropertySection
          key={property.propertyId}
          propertyId={property.propertyId}
          propertyName={property.propertyName}
          zoneGroups={Object.values(property.irrigationZoneEmitterGroups ?? {}).sort(sortByKey('groupName'))}
          filterPropertiesText={filterPropertiesText}
          setSelectedZoneGroupEdit={setSelectedZoneGroupEdit}
          setSelectedZoneGroupDelete={setSelectedZoneGroupDelete}
        />
      ))}
      <EditEmitterGroupModal
        selectedZoneGroupEdit={selectedZoneGroupEdit}
        setSelectedZoneGroupEdit={setSelectedZoneGroupEdit}
      />
      <DeleteEmitterGroupModal
        selectedZoneGroupDelete={selectedZoneGroupDelete}
        setSelectedZoneGroupDelete={setSelectedZoneGroupDelete}
      />
    </>
  )
}
