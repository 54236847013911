import { routes } from '@semios/app-platform-banyan-route-definitions'
import { irrigationSchedulerStore } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { TFieldAssetKeyTypes, TFieldAssetValueTypes } from 'App/Map/types'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { sortByKey } from 'utils/sortByKey'
import { getIrrigationProperties } from '../selectors/getIrrigationProperties'

export const launch = (response: routes.IrrigationSchedulerStartup.Response) => {
  // merge the device vendors from the irrigation scheduler startup response into the field asset store
  // Q: should this be done on user app startup instead? If we do that, irrigation scheduler will load
  // much faster and we can avoid doing this API call altogether.
  fieldAssetStore.setState((s) => {
    const propertiesWithUpdatedEmittersAndPublishPermission = Object.entries(s.properties ?? {}).reduce<
      Record<number, TFieldAssetValueTypes.TProperty>
    >((acc, [propertyIdStr, property]) => {
      const propertyId = Number(propertyIdStr)
      const deviceVendors = response.properties[propertyId]?.deviceVendors ?? {}

      const irrigationZoneEmittersWithDeviceVendors = Object.entries(
        property.irrigationZoneEmitters ?? {},
      ).reduce<
        Record<TFieldAssetKeyTypes.TIrrigationZoneEmitterId, TFieldAssetValueTypes.TIrrigationZoneEmitter>
      >((accInner, [irrigationZoneEmitterId, irrigationZoneEmitter]) => {
        return {
          ...accInner,
          [irrigationZoneEmitterId]: {
            ...irrigationZoneEmitter,
            deviceVendors:
              deviceVendors[irrigationZoneEmitterId as TFieldAssetKeyTypes.TIrrigationZoneEmitterId] ?? [],
          },
        }
      }, {})

      return {
        ...acc,
        [propertyId]: {
          ...property,
          publishPermission: response.properties[propertyId]?.publishPermission,
          irrigationZoneEmitters: irrigationZoneEmittersWithDeviceVendors,
        },
      }
    }, {})

    return {
      ...s,
      properties: propertiesWithUpdatedEmittersAndPublishPermission,
    }
  })

  const selectedFieldAssetsStorePropertyId = selectedFieldAssetsStore.getState().property

  const timezoneForSelectedProperty = selectedFieldAssetsStorePropertyId
    ? fieldAssetStore.getState().properties?.[selectedFieldAssetsStorePropertyId]?.timezone
    : null

  irrigationSchedulerStore.setState((isss) => {
    // Set the selected property to the selected property in the selectedFieldAssetsStore if we have it
    // for the irrigation scheduler...
    if (selectedFieldAssetsStorePropertyId && response.properties[selectedFieldAssetsStorePropertyId]) {
      const dateFrom =
        timezoneForSelectedProperty && moment.tz(timezoneForSelectedProperty).startOf('week').toISOString()

      return {
        ...isss,
        ...(dateFrom ? { dateFrom } : {}),
        selectedPropertyId: selectedFieldAssetsStorePropertyId,
      }
    }
    // ...otherwise set the selected property to the first one in the list
    else {
      const firstValidProperty = getIrrigationProperties(fieldAssetStore.getState()).sort(
        sortByKey('propertyName'),
      )[0]

      if (firstValidProperty) {
        return {
          ...isss,
          dateFrom: moment.tz(firstValidProperty.timezone).startOf('week').toISOString(),
          selectedPropertyId: firstValidProperty.propertyId,
        }
      }
    }

    return isss
  })
}
