import { RefObject, useContext } from 'react'
import { GridTableScrollContext } from '../context'

export const RightSideShadow = ({ gridTableRef }: { gridTableRef: RefObject<HTMLDivElement> }) => {
  const { allTheWayRight } = useContext(GridTableScrollContext)
  const topHeight = Number(gridTableRef?.current?.children?.[0]?.children?.[1]?.clientHeight) - 3
  const bottomHeight = Number(gridTableRef?.current?.children?.[1]?.clientHeight) + 3

  return (
    <div
      css={{
        height: Number(topHeight) * 2 + Number(bottomHeight),
        maxHeight: Number(topHeight) * 2 + Number(bottomHeight),
        width: 0,
        zIndex: 5,
        boxShadow: `10px ${topHeight}px 25px 25px rgba(180, 179, 187, 0${!allTheWayRight ? '.7' : ''})`,
        clipPath: `polygon(-120px ${topHeight}px, 0 ${topHeight}px, 0 100%, -120px 100%)`,
        transition: '200ms ease all',
      }}
    />
  )
}
