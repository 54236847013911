import moment from 'moment-timezone'
import { TOrganizationKcDateRange } from '../../../../types'

export const getIsDateExcluded = ({
  date,
  timezone,
  index,
  dateRanges,
}: {
  date: Date
  timezone: string
  index: number
  dateRanges: TOrganizationKcDateRange[]
}) => {
  return dateRanges.some((range) => {
    // return true if date is within any of the ranges except for the current range
    if (range === dateRanges[index]) {
      return false
    }

    const dateRangeStart = moment.tz(range.dateRangeStart, timezone)
    const dateRangeEnd = moment.tz(range.dateRangeEnd, timezone)

    return moment.tz(date, timezone).isBetween(dateRangeStart, dateRangeEnd, 'day', '[]')
  })
}
