import { IconChevron } from 'components/icons/IconChevron'
import { IconChevronCircle } from 'components/icons/IconChevronCircle'
import { isNil } from 'lodash'
import { MouseEventHandler } from 'react'

const rotationMapper = {
  'expand-downwards': { expanded: 180, collapsed: 360 },
  'expand-leftwards': { expanded: 270, collapsed: 450 },
  'expand-upwards': { expanded: 0, collapsed: 180 },
  'expand-rightwards': { expanded: 90, collapsed: 270 },
}

export const ExpandableChevronIndicator = ({
  expanded,
  expandDirection = 'expand-downwards',
  onClick,
  size,
  stroke,
}: {
  expanded: boolean
  expandDirection?: keyof typeof rotationMapper
  onClick?: MouseEventHandler<HTMLDivElement>
  size?: number
  stroke?: boolean
}) => {
  return (
    <div
      css={{
        display: 'flex',
        fontSize: isNil(size) ? 'unset' : size,
        transform: `rotate(${
          expanded ? rotationMapper[expandDirection].expanded : rotationMapper[expandDirection].collapsed
        }deg)`,
        transition: '200ms ease all',
        cursor: 'pointer',
        padding: 12,
        margin: -12,
      }}
      onClick={onClick}
    >
      {!!stroke ? <IconChevronCircle /> : <IconChevron />}
    </div>
  )
}
