import { routes } from '@semios/app-platform-banyan-route-definitions'
import {
  SprayConditionsTitleChildren,
  TSprayConditionsRulesForARegion,
} from 'App/Map/PanelDetails/SectionTitleBars/SprayConditionsTitleChildren/SprayConditionsTitleChildren'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval, ESprayRecommendation } from 'App/Map/types'
import { getSprayConditionsTitleAndColor } from 'App/Map/_utils/getSprayConditionsTitleAndColor'
import { GridTableContentSection, GridTableContentSectionItem } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { sortByKey } from 'utils/sortByKey'
import { BadgeCell } from '../../../BadgeCell/BadgeCell'
import { defaultValuesRowHeight, timestampToValuesDictionaryMaker } from '../_utils'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_SPRAY_FORECAST' })

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  const blocksForProperty =
    fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.blocks

  if (!selectedValueGroups.spray_conditions || isEmpty(blocksForProperty)) {
    return {}
  }

  const blockIds = Object.keys(blocksForProperty).map(Number)

  const preferredAggregationInterval = {
    preferredAggregationInterval: EAggregationInterval.DAILY,
  }

  const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
    sprayConditions: preferredAggregationInterval,
  }

  if (!doesSelectedBlockHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })) return {}

  return {
    blocks: {
      blockIds,
      valuesRequested,
    },
  }
}

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: translate.phrases.banyanApp('Spray Conditions'),
    titleChildren: (
      <SprayConditionsTitleChildren
        propertiesXorBlocks="PROPERTIES"
        valuesTimeseriesToFilterOn={['sprayConditions']}
      />
    ),
    id: 'summary-grid-spray-conditions',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { property } = selectedFieldAssetsStore.getState()
  const blocksForProperty = fieldAssetStore.getState()?.properties?.[Number(property)]?.blocks ?? {}

  /**
   * every block should have the same sprayConditionsRules,
   * since they're all from the same property, so when we're
   * looping over them, let's pick the first rules we find
   */
  let sprayConditionsRules: TSprayConditionsRulesForARegion | undefined = undefined

  const items: GridTableContentSectionItem[] = Object.values(blocksForProperty)
    .sort(sortByKey('name'))
    .map((block) => {
      const { metadata, timeseries } = data?.blocks?.[block.blockId]?.values?.sprayConditions?.[0] ?? {}

      if (!sprayConditionsRules && metadata?.sprayConditionsRules) {
        sprayConditionsRules = metadata?.sprayConditionsRules
      }

      return {
        id: String(block.blockId),
        height: defaultValuesRowHeight,
        labelMinWidth: 190,
        valueMinWidth: 160,
        label: block.name ?? translate.phrases.banyanApp('Unnamed Block'),
        valueCellCSS: {
          '& > *': {
            height: 'max-content',
          },
        },
        render: (dataPoint?: { value: ESprayRecommendation }) => {
          if (dataPoint?.value === undefined) return translate.phrases.templates('-')

          const { color, name } = getSprayConditionsTitleAndColor(dataPoint?.value ?? null)

          return <BadgeCell backgroundColor={color}>{name}</BadgeCell>
        },
        // TODO: because timeseries isn't typed, this next call doesn't throw ts errors, but value-definitions should fix this
        values: timeseries ? timestampToValuesDictionaryMaker(timeseries) : {},
      }
    })

  return {
    ...commonReturnItems,
    items,
    titleChildren: (
      <SprayConditionsTitleChildren
        propertiesXorBlocks="PROPERTIES"
        sprayConditionsRules={sprayConditionsRules}
        valuesTimeseriesToFilterOn={['sprayConditions']}
      />
    ),
  }
}
