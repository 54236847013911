import { Select } from '@mantine/core'
import { TFieldAssetKeyTypes, TValuesTimeseries } from 'App/Map/types'
import { Authorization } from 'components/Authorization/Authorization'
import { Button } from 'components/Button/Button'
import { useTabsContext } from 'components/Tabs/Tabs.context'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TPointCategory } from 'stores/selectedFieldAssetsStore'
import { smallStore } from 'stores/smallStore'
import { alphabeticalSort } from 'utils/alphabeticalSort'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { mantineSelectSearchFilterProp } from 'utils/mantineSelectSearchFilterProp'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { sortByKey } from 'utils/sortByKey'

type SelectDataItem = { value: TFieldAssetKeyTypes.TLngLat; label: string; group: string }

export type TDropdownSelectorPointProps = {
  valuesTimeseriesToFilterOn?: TValuesTimeseries[]
  pointCategory?: TPointCategory
}

const publishFrostFanPermission = 'PUBLISH_FROST_FAN_OPERATIONAL_DEVICE_SETTINGS'

export const DropdownSelectorWindMachine = ({
  valuesTimeseriesToFilterOn,
  pointCategory,
}: TDropdownSelectorPointProps) => {
  const { selectedPoint } = selectedFieldAssetsStore.useSelector((s) => ({
    selectedPoint: pointCategory ? s[pointCategory] : s.lngLat,
  }))

  const keyToSelectOnChange = pointCategory ?? 'lngLat'
  const ctx = useTabsContext()

  const { properties } = fieldAssetStore.useSelector((s) => ({
    properties: s?.properties ?? [],
  }))

  const data = useMemo<SelectDataItem[]>(() => {
    return Object.values(properties ?? {})
      .sort(sortByKey('propertyName'))
      .flatMap((p) => {
        if (!p.points) return []

        let filteredPoints = Object.values(p.points)

        if (valuesTimeseriesToFilterOn) {
          filteredPoints = filterFieldAssetsByValueTypes({
            fieldAssets: Object.values(p.points),
            valuesTimeseries: valuesTimeseriesToFilterOn,
          })
        }

        return filteredPoints
          .sort((a, b) => alphabeticalSort(a.name, b.name))
          .map((point) => ({
            value: point.lngLat as TFieldAssetKeyTypes.TLngLat,
            label: point.name,
            group: p.propertyName,
          }))
      })
  }, [selectedPoint, properties])

  const selectedProperty = selectedFieldAssetsStore.useSelector((s) => s.property)

  return (
    // can prevent the section from collapsing by passing stopPropagation

    <>
      <div onClick={(e) => e.stopPropagation()}>
        <Select
          css={{
            width: ctx.containerWidth < 600 ? 200 : Math.min(280, Math.round(ctx.containerWidth / 3)),
            border: `1px solid ${colors.grey500}`,
            color: colors.grey800,
            borderRadius: 3,
          }}
          clearable={false}
          data={data}
          filter={mantineSelectSearchFilterProp}
          onChange={(newPoint: TFieldAssetKeyTypes.TLngLat) =>
            setSelectedFieldAsset({ [keyToSelectOnChange]: newPoint })
          }
          placeholder={translate.phrases.banyanApp('Select a Station')}
          searchable
          size="xs"
          spellCheck="false"
          styles={{
            ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER,
            ...SharedSettings.MANTINE_SELECT_UNDERLINE_SEPARATOR_STYLES,
            dropdown: { marginTop: -6, fontWeight: 'normal' },
            input: { ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER.input, paddingRight: 28 },
          }}
          value={selectedPoint}
          withinPortal
          zIndex={1}
        />
      </div>

      {selectedProperty && (
        <Authorization requires={{ permission: publishFrostFanPermission, entity: selectedProperty }}>
          <Button
            variant="tertiary"
            type="button"
            onClick={() =>
              smallStore.setState((s) => ({
                ...s,
                showViewDetails: true,
              }))
            }
          >
            {translate.phrases.placeholder('Controls')}
          </Button>
        </Authorization>
      )}
    </>
  )
}
