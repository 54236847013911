import type { CSSObject } from '@emotion/css'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { MapItemsCache } from 'components/GoogleMap/MapItemsCache'
import {
  makeReactComponentOverlayView,
  ReactComponentOverlayView,
} from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { getPrimaryValueGroup } from 'stores/selectedValueGroupsStore/getPrimaryValueGroup'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { BlockValueBadgeComponent } from './BlockValueBadgeComponent/BlockValueBadgeComponent'

export type BLOCK_VALUE_META = {
  blockId: TFieldAssetKeyTypes.TBlockId
  blockName: string
  bounds: google.maps.LatLngBounds
  latLngs: google.maps.LatLng[]
  latLng: google.maps.LatLng
  propertyId: TFieldAssetKeyTypes.TPropertyId
}

// TODO: will eventually come from current values type
export type TBlocksValueTypesToPropsDictionary = Record<
  string,
  { children: React.ReactNode; getContainerCSS: () => CSSObject }
>

export class BlockValuesCache extends MapItemsCache<
  BLOCK_VALUE_META,
  TBlocksValueTypesToPropsDictionary,
  {
    badge: ReactComponentOverlayView<{
      meta: BLOCK_VALUE_META
      valueTypesToPropsDictionary: TBlocksValueTypesToPropsDictionary
    }>
    polygon: google.maps.Polygon
  }
> {
  constructor(options: { maps: typeof google.maps; map: google.maps.Map }) {
    super({
      createOverlay: (o) => {
        const polygon = new o.maps.Polygon({ paths: o.meta.latLngs, map: o.map })

        // TODO: use util for new block select
        polygon.addListener('click', () => {
          setSelectedFieldAsset({ block: o.meta.blockId })
        })

        return {
          polygon,
          badge: makeReactComponentOverlayView({
            component: BlockValueBadgeComponent,
            latLng: o.meta.latLng,
            maps: o.maps,
            map: o.map,
          }),
        }
      },
      renderOverlay: (o) => {
        const isSelected = selectedFieldAssetsStore.getState().block === o.meta.blockId
        const primaryValueGroup = getPrimaryValueGroup()
        const { mapVisualValueGroup } = mapControlsStore.getState()

        const valueType = !primaryValueGroup
          ? null
          : mapVisualValueGroup[MAP_VISUAL.BLOCK]?.[primaryValueGroup]?.valueType

        o.overlay.polygon.setOptions({
          map: o.map,
          strokeColor: isSelected ? 'yellow' : 'white',
          strokeWeight: isSelected ? 4 : 2,
          strokePosition: o.maps.StrokePosition.CENTER,
          fillColor: valueType
            ? String(o.value?.[valueType]?.getContainerCSS?.()?.['backgroundColor'])
            : null,
          fillOpacity: 0.6,
        })

        o.overlay.badge.render({ ...o, valueTypesToPropsDictionary: o.value })
      },
      hideOverlay: (overlay) => {
        overlay.polygon.setMap(null)

        overlay.badge.setMap(null)
      },
      maps: options.maps,
      map: options.map,
    })
  }
}
