import { routes } from '@semios/app-platform-banyan-route-definitions'
import {
  IRRIGATION_ACTIVITY_VALUE_TYPE_PREFIX,
  getValueTypeFromEmitterType,
} from '@semios/app-platform-common'
import { VV } from '@semios/app-platform-value-type-definitions'
import { getIdAndEmitterTypeFromZoneEmitterTypeKey } from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { getPointLngLatsForIrrigationEmitterZoneId } from 'utils/getPointLngLatsForIrrigationEmitterZoneId'

type IrrigationIntervalType =
  VV.DomainTypes.Irrigation.TValuesReturnWithMetaIgnoringKeying['points']['irrigationActivityIntervalsSemios_emitterBuriedDrip1']

export const getActivityIntervalsFromData = ({
  data,
  selectedFieldAssets,
}: {
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}) => {
  const propertyId = selectedFieldAssets.property
  const irrigationEmitterZoneId = selectedFieldAssets.irrigationZoneEmitter

  if (!propertyId || !irrigationEmitterZoneId) return {}

  const points = getPointLngLatsForIrrigationEmitterZoneId({ propertyId, irrigationEmitterZoneId })
  const { emitterType } = getIdAndEmitterTypeFromZoneEmitterTypeKey(irrigationEmitterZoneId)

  const valueType = getValueTypeFromEmitterType(
    IRRIGATION_ACTIVITY_VALUE_TYPE_PREFIX,
    emitterType,
  ) as VV.DomainTypes.Irrigation.TTimeseriesValueTypeKeysMerged

  return points.reduce((result, point) => {
    const dataForPoint = data?.points?.[point]?.values?.[valueType] as IrrigationIntervalType

    if (dataForPoint) {
      result[point] = dataForPoint
    }

    return result
  }, {} as Record<string, IrrigationIntervalType>)
}
