import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from '@semios/app-platform-common'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { DropdownSelectorPropertyWindMachine } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorWindMachine/DropdownSelectorPropertyWindMachine'
// import { makeFullWidthItemsWhenNoData } from 'App/Map/PanelDetails/_utils/makeFullWidthItemsWhenNoData/makeFullWidthItemsWhenNoData'
import { EAggregationInterval, EWindMachineStatus, TFieldAssetKeyTypes } from 'App/Map/types'
import {
  GridTableContentSection,
  GridTableContentSectionItem,
  PossibleValuesRecord,
} from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { BadgeCell } from '../../../BadgeCell/BadgeCell'
import { StatusTile } from './StatusTile/statusTile'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_FROST_FAN_DETAILS' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  windMachineRunTime: preferredAggregationInterval,
  windMachineCycles: preferredAggregationInterval,
  windMachineTemperature: preferredAggregationInterval,
  windMachineFuel: preferredAggregationInterval,
  windMachineSettings: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  const points = fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.points

  if (!points) return {}

  const windMachines = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(points),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  if (!selectedValueGroups.wind_machine || isEmpty(windMachines)) {
    return {}
  }

  const lnglatsForwindMachine = windMachines
    .map((windMachine) => windMachine.lngLat)
    // this is done in a weird way to keep TypeScript happy
    .filter(Boolean) as [TFieldAssetKeyTypes.TLngLat, ...TFieldAssetKeyTypes.TLngLat[]]

  if (!lnglatsForwindMachine.length) return {}

  return {
    points: {
      lngLats: lnglatsForwindMachine,
      valuesRequested,
    },
  }
}

const msToTime = (duration: number) => {
  let seconds = Math.floor(duration / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  minutes = minutes % 60

  return `${hours} ${translate.phrases.placeholder('hrs')} ${minutes} ${translate.phrases.placeholder(
    'mins',
  )}`
}

const generateItem = ({
  id,
  values,
  probeName,
  stationName,
}: {
  id: string
  values: GridTableContentSectionItem['values']
  probeName: React.ReactNode
  stationName: string
}): {
  height: GridTableContentSectionItem['height']
  id: string
  label: React.ReactNode
  sortingLabel: string
  labelMinWidth: GridTableContentSectionItem['labelMinWidth']
  render: GridTableContentSectionItem['render']
  valueMinWidth: GridTableContentSectionItem['valueMinWidth']
  values: GridTableContentSectionItem['values']
} => ({
  id,
  label: probeName,
  sortingLabel: stationName,
  height: 240,
  labelMinWidth: 190,
  valueMinWidth: 120,
  render: (dataPoint: {
    cycle: number
    fuelLevel: string
    minTemp: number
    runTime: number
    startTemp: number
    stopTemp: number
  }) => {
    if (dataPoint === undefined || dataPoint === null) {
      return translate.phrases.placeholder('-')
    }

    let fuelLevel = '-'

    if (dataPoint?.fuelLevel === '') {
      fuelLevel = '-'
    } else if (dataPoint?.fuelLevel === 'Not Connected') {
      fuelLevel = dataPoint?.fuelLevel
    } else {
      fuelLevel = dataPoint?.fuelLevel + ' %'
    }

    return (
      <BadgeCell backgroundColor={'transparent'}>
        <div css={{ marginTop: '25px' }}>
          <p
            css={{
              width: '100%',
              margin: '0 0 10px 0',
              fontSize: '14px',
            }}
          >
            {msToTime(dataPoint.runTime)}
          </p>
          <p
            css={{
              width: '100%',
              margin: '0 0 10px 0',
              fontSize: '14px',
            }}
          >
            {dataPoint.cycle}
          </p>
          <p
            css={{
              width: '100%',
              margin: '0 0 10px 0',
              fontSize: '14px',
            }}
          >
            {unitConverter.windMachineTemperature(dataPoint.minTemp).valueWithSuffix()}
          </p>
          <p
            css={{
              width: '100%',
              margin: '0 0 10px 0',
              fontSize: '14px',
            }}
          >
            {unitConverter.windMachineTemperature(dataPoint.startTemp).valueWithSuffix()}
          </p>
          <p
            css={{
              width: '100%',
              margin: '0 0 10px 0',
              fontSize: '14px',
            }}
          >
            {unitConverter.windMachineTemperature(dataPoint.stopTemp).valueWithSuffix()}
          </p>
          <p
            css={{
              width: '100%',
              margin: '0 0 10px 0',
              fontSize: '14px',
            }}
          >
            {fuelLevel}
          </p>
        </div>
      </BadgeCell>
    )
  },
  values,
})

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const title = translate.phrases.placeholder('Wind Machine Activity')

  const commonReturnItems = {
    title,
    titleChildren: <DropdownSelectorPropertyWindMachine />,
    id: 'summary-grid-wind-machine-activity',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { property } = selectedFieldAssetsStore.getState()
  const points = fieldAssetStore.getState()?.properties?.[Number(property)]?.points ?? {}

  const windMachinesForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(points),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  const gridItems = Object.values(windMachinesForProperty)
    .map((windMachine) => {
      const windMachineData = data?.points?.[windMachine.lngLat]?.values
      const targetObject: Record<string, PossibleValuesRecord> = {}

      // Assuming that all timeseries arrays have the same length
      const timeseriesLength =
        data?.points?.[windMachine.lngLat]?.values?.windMachineCycles?.[0]?.timeseries?.length ?? 0

      for (let i = 0; i < timeseriesLength; i++) {
        const wmTimeseries = data?.['points']?.[windMachine.lngLat]?.values?.['windMachineTemperature']?.[0]
          ?.timeseries
          ? (data?.['points']?.[windMachine.lngLat]?.values?.['windMachineTemperature']?.[0]?.timeseries as {
              timestamp: string
              value: { min: number | null; median: number | null; max: number | null }
            }[])
          : []

        const timestamp = windMachineData?.windMachineCycles?.[0]?.timeseries[i]?.timestamp ?? ''

        if (timestamp !== undefined) {
          const runTime = windMachineData?.windMachineRunTime?.[0]?.timeseries[i]?.value
          const cycle = windMachineData?.windMachineCycles?.[0]?.timeseries[i]?.value
          const minTemp = wmTimeseries?.[i]?.value?.min
          const startTemp = windMachineData?.windMachineSettings?.[0]?.timeseries[i]?.value?.tempStart
          const fuelLevel = windMachineData?.windMachineFuel?.[0]?.timeseries[i]?.value
          const stopTemp = windMachineData?.windMachineSettings?.[0]?.timeseries[i]?.value?.tempStop

          // Build the object for the current timestamp
          const values: PossibleValuesRecord = {
            timestamp,
            ...(runTime !== undefined && { runTime }),
            ...(cycle !== undefined && { cycle }),
            ...(minTemp !== undefined && { minTemp }),
            ...(startTemp !== undefined && { startTemp }),
            ...(stopTemp !== undefined && { stopTemp }),
            ...(fuelLevel !== undefined && { fuelLevel }),
          }

          targetObject[timestamp] = values
        }
      }

      let currentStatus: EWindMachineStatus = EWindMachineStatus['-']

      const currentStatusData = windMachineData?.windMachineCycles?.[0]?.metadata?.currentStatus

      if (!isNil(currentStatusData)) {
        const isRunning = currentStatusData.equipmentStatus.running

        if (isRunning === 1) currentStatus = EWindMachineStatus.ON
        else if (isRunning === 0) currentStatus = EWindMachineStatus.OFF
      }

      const windMachineProbeName = (
        <div>
          <div
            css={{
              width: '100%',
              margin: '0 0 10px 0',
              display: 'flex',
              fontSize: '14px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p
              css={{
                margin: '0px',
                fontWeight: 'bold',
                paddingRight: '20px',
              }}
            >
              {windMachine.name}
            </p>{' '}
            <StatusTile status={currentStatus} />
          </div>
          <div
            css={{
              paddingLeft: '10px',
            }}
          >
            <p
              css={{
                width: '100%',
                margin: '0 0 10px 0',
                fontSize: '14px',
              }}
            >
              {translate.phrases.placeholder('Run Time')}
            </p>
            <p
              css={{
                width: '100%',
                margin: '0 0 10px 0',
                fontSize: '14px',
              }}
            >
              {translate.phrases.placeholder('Cycles')}
            </p>
            <p
              css={{
                width: '100%',
                margin: '0 0 10px 0',
                fontSize: '14px',
              }}
            >
              {translate.phrases.placeholder('Min Air Temperature')}
            </p>
            <p
              css={{
                width: '100%',
                margin: '0 0 10px 0',
                fontSize: '14px',
              }}
            >
              {translate.phrases.placeholder('Start Temperature')}
            </p>
            <p
              css={{
                width: '100%',
                margin: '0 0 10px 0',
                fontSize: '14px',
              }}
            >
              {translate.phrases.placeholder('Stop Temperature')}
            </p>
            <p
              css={{
                width: '100%',
                margin: '0 0 10px 0',
                fontSize: '14px',
              }}
            >
              {translate.phrases.placeholder('Fuel Level')}
            </p>
          </div>
        </div>
      )

      return generateItem({
        id: windMachine.lngLat,
        probeName: windMachineProbeName ?? translate.phrases.banyanApp('Unnamed Station'),
        values: targetObject,
        stationName: windMachine.name,
      })
    })
    .filter(Boolean)
    .sort(sortByKey('sortingLabel'))

  return {
    ...commonReturnItems,
    items: gridItems,
  }
}
