import { NetworkStatusNotification, networkStore } from '../networkStore'

export const actions = {
  showNetworkStatusNotification: (notification: NetworkStatusNotification) => {
    networkStore.setState((s) => ({ ...s, networkStatusNotification: notification }))
  },
  hideNetworkStatusNotification: () => {
    networkStore.setState((s) => ({ ...s, networkStatusNotification: null }))
  },
}
