import { routes } from '@semios/app-platform-banyan-route-definitions'
import { useDisclosure } from '@mantine/hooks'
import {
  TKcOverrideType,
  TSelectedKcConfigurationMap,
} from 'App/Map/UserSettingsMenu/Shared/EvapotranspirationSettings/types'
import { Button } from 'components/Button/Button'
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { apiFetch } from 'utils/apiFetch'
import { KcConfigurationTable } from './KcConfigurationTable/KcConfigurationTable'
import { refreshTheDetailsPanelData } from './_utils/refreshTheDetailsPanelData'

export const KcBlockSettingsModalButton = () => {
  const [modalIsOpen, { close: closeModal, open: openModal }] = useDisclosure(false)

  const [confirmationModalIsOpen, { close: closeConfirmationModal, open: openConfirmationModal }] =
    useDisclosure(false)

  const [dirtyBlockIds, setDirtyBlockIds] = useState<number[]>([])
  const [initialBlockSettings, setInitialBlockSettings] = useState<TSelectedKcConfigurationMap>([])
  const [selectedValueMap, setSelectedValueMap] = useState<TSelectedKcConfigurationMap>([])

  const handleSave = async () => {
    const selectedValuesThatHaveChanged = Object.keys(selectedValueMap)
      .map(Number)
      .filter((blockId) => {
        const selected = selectedValueMap[blockId]
        const defaults = initialBlockSettings[blockId]
        const hasKcModelIdChanged = selected?.modelId !== defaults?.modelId
        const hasKcMultiplierChanged = selected?.kcMultiplier !== defaults?.kcMultiplier

        return hasKcModelIdChanged || hasKcMultiplierChanged
      })

    type TValuesToQuery = {
      blockId: number
      blockKcOverrideType: TKcOverrideType
      kcModelId: string | null
      organizationKcModelId: string | null
      kcMultiplier: number | null
    }

    const queryBody = {
      kcModelBlockUpdate: selectedValuesThatHaveChanged
        .map((blockId) => {
          const blockData = selectedValueMap?.[blockId]

          if (!blockData) return null

          const isOrganizationKcModel = blockData.modelType === 'ORGANIZATION_KC_MODEL'

          return {
            blockId,
            blockKcOverrideType: blockData.modelType,
            kcModelId: isOrganizationKcModel ? null : blockData.modelId,
            organizationKcModelId: isOrganizationKcModel ? blockData.modelId : null,
            kcMultiplier: isOrganizationKcModel ? null : blockData.kcMultiplier,
          }
        })
        .filter((item): item is TValuesToQuery => item !== null),
    }

    const queryParams = {
      url: routes.FieldAssetSettingsSet.path,
      body: queryBody,
    }

    if (!selectedValuesThatHaveChanged.length) {
      setDirtyBlockIds([])

      closeModal()

      return
    }

    const response = await apiFetch<
      routes.FieldAssetSettingsSet.Request,
      routes.FieldAssetSettingsSet.Response
    >(queryParams)

    if (response) {
      refreshTheDetailsPanelData()

      setDirtyBlockIds([])

      closeModal()
    }
  }

  return (
    <>
      <Button variant={'link'} onClick={openModal}>
        {translate.phrases.banyanApp('Select Kc Models')}
      </Button>
      <ModalDrawer
        title={translate.phrases.banyanApp('Edit Kc Coefficient Settings')}
        opened={modalIsOpen}
        onClose={dirtyBlockIds.length ? openConfirmationModal : closeModal}
        primaryButtonText={translate.phrases.banyanApp('Save Changes')}
        primaryButtonOnPress={handleSave}
        primaryButtonDisabled={dirtyBlockIds.length === 0}
        secondaryButtonText={translate.phrases.banyanApp('Cancel')}
        secondaryButtonOnPress={dirtyBlockIds.length ? openConfirmationModal : closeModal}
      >
        {modalIsOpen && (
          <KcConfigurationTable
            confirmationModalIsOpen={confirmationModalIsOpen}
            closeConfirmationModal={closeConfirmationModal}
            closeModal={closeModal}
            dirtyBlockIds={dirtyBlockIds}
            setDirtyBlockIds={setDirtyBlockIds}
            selectedValueMap={selectedValueMap}
            setSelectedValueMap={setSelectedValueMap}
            setInitialBlockSettings={setInitialBlockSettings}
          />
        )}
      </ModalDrawer>
      <ConfirmationModal
        title={translate.phrases.banyanApp('Unsaved Changes')}
        content={
          <div>
            {translate.phrases.banyanApp("Any changes you have made won't be saved.")}
            <br />
            {translate.phrases.banyanApp('Select an option below.')}
          </div>
        }
        modalWindowSize={350}
        buttonContent={
          <div
            css={{
              color: colors.midnight,
              display: 'grid',
              gridTemplateColumns: '50% 50%',
              width: '100%',
              justifyContent: 'space-evenly',
            }}
          >
            <Button
              variant={'negative'}
              css={{ gridColumn: 1, gridRow: 1, marginRight: 2, width: 160, color: colors.midnight }}
              onClick={() => {
                setDirtyBlockIds([])

                closeConfirmationModal()

                closeModal()
              }}
            >
              {translate.phrases.banyanApp('Discard Changes')}
            </Button>
            <Button
              variant={'secondary'}
              css={{
                'gridColumn': 2,
                'gridRow': 1,
                'width': 160,
                'justifySelf': 'end',
                'borderColor': colors.primary,
                'color': colors.primary,
                '&:not([disabled],[data-loading="true"]):hover': {
                  color: colors.primary,
                  background: colors.white,
                  shadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', // Matches mantine style
                },
              }}
              onClick={closeConfirmationModal}
            >
              {translate.phrases.banyanApp('Keep Editing')}
            </Button>
            <Button
              variant={'primary'}
              css={{
                gridArea: '2 / 1 / 2 / 3',
                marginTop: 10,
              }}
              onClick={() => {
                handleSave()

                closeConfirmationModal()
              }}
            >
              {translate.phrases.banyanApp('Save Changes')}
            </Button>
          </div>
        }
        modalOpened={confirmationModalIsOpen}
        closeModal={closeConfirmationModal}
      />
    </>
  )
}
