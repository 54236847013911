import { translate } from 'i18n/i18n'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { NumberInput, Select, Switch } from '@mantine/core'
import { colors } from 'settings/colors'
import { AlertTypeId, AlertsSettings } from '../../../settings/alertSettings'
import { AlertSection } from '../../../components/AlertSection'
import { SleepUnitsType } from '../../ModalDrawerCreateOrEditAlert'
import { fadeInOutStylesMaker } from 'utils/fadeInOutStylesMaker'
import { TranslatePhraseWrapper } from '../../../components/TranslatePhraseWrapper'
import { InfoPopover } from '../../../components/InfoPopover'
import { getErrorStyle } from '../../../_utils/getErrorStyle'
import { ErrorTextWrapper } from '../../../components/ErrorTextWrapper'
import { isNumberOrNumericString } from '../../../AlertValidators/AlertValidators'
import { TranslateComponentWrapper } from '../../../components/TranslateComponentWrapper'

const sleepNumberToDisplayMaker = ({
  sleepMinutes,
  sleepUnits,
}: {
  sleepMinutes: number | null
  sleepUnits: SleepUnitsType
}) => {
  let numberToDisplay = sleepMinutes

  if (sleepMinutes && sleepUnits === 'hours') numberToDisplay = sleepMinutes / 60

  if (sleepMinutes && sleepUnits === 'days') numberToDisplay = sleepMinutes / 1440

  return numberToDisplay
}

export const Sleep = ({
  alertTypeId,
  setSleep,
  setSleepMinutes,
  sleep,
  sleepMinutes,
  sleepUnits,
  setSleepUnits,
  triedToSubmit,
  setSleepIsValid,
}: {
  alertTypeId: AlertTypeId
  setSleep: Dispatch<SetStateAction<boolean>>
  setSleepMinutes: Dispatch<SetStateAction<number | null>>
  sleep: boolean
  sleepMinutes: number | null
  sleepUnits: SleepUnitsType
  setSleepUnits: Dispatch<SetStateAction<SleepUnitsType>>
  triedToSubmit: boolean
  setSleepIsValid: Dispatch<SetStateAction<boolean>>
}) => {
  const selectOptions = [
    { value: 'minutes', label: translate.phrases.banyanApp('Minutes') },
    { value: 'hours', label: translate.phrases.banyanApp('Hours') },
    { value: 'days', label: translate.phrases.banyanApp('Days') },
  ]

  const allowSleep = AlertsSettings.allowSleep[alertTypeId]

  let sleepNumberToDisplay = sleepNumberToDisplayMaker({ sleepMinutes, sleepUnits })

  const onSleepChange = (newNumber: number) => {
    let numberToSend = newNumber

    if (sleepUnits === 'hours') numberToSend = newNumber * 60

    if (sleepUnits === 'days') numberToSend = newNumber * 1440

    setSleepMinutes(numberToSend)
  }

  const onSleepUnitsChange = (newUnit: SleepUnitsType) => {
    setSleepUnits(newUnit)

    // handle cases when sizing up the units but maintaining a minimum of 1 and an integer for those new units
    const newSleepNumberToDisplay = sleepNumberToDisplayMaker({ sleepMinutes, sleepUnits: newUnit })

    if (newSleepNumberToDisplay) {
      const cleanedSleepDuration = Math.ceil(newSleepNumberToDisplay)

      let newSleepMinutes = cleanedSleepDuration

      if (newUnit === 'hours') newSleepMinutes = cleanedSleepDuration * 60

      if (newUnit === 'days') newSleepMinutes = cleanedSleepDuration * 1440

      setSleepMinutes(newSleepMinutes)
    }
  }

  let helpText = ''
  let validateStatusError = false

  const sleepIsDirty = sleepMinutes !== null || triedToSubmit

  if (sleep && allowSleep && sleepIsDirty) {
    if (sleepNumberToDisplay === null || sleepNumberToDisplay <= 0 || sleepNumberToDisplay % 1 !== 0) {
      helpText = translate.phrases.banyanApp('The sleep duration must be a positive whole number')

      validateStatusError = true
    }
  }

  useEffect(() => {
    if (!allowSleep) setSleep(false)

    setSleepIsValid(!validateStatusError)
  }, [allowSleep, sleepMinutes, sleep])

  if (!allowSleep) {
    return null
  }

  const errorStyle = getErrorStyle()

  return (
    <AlertSection title={translate.phrases.banyanApp('Sleep')}>
      <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
        <Switch
          checked={sleep}
          onChange={() => setSleep(!sleep)}
          styles={{
            track: {
              cursor: 'pointer',
              backgroundColor: sleep ? `${colors.green} !important` : undefined,
              borderColor: sleep ? `${colors.green} !important` : undefined,
            },
          }}
        />
        <span
          css={{ fontWeight: '500', fontSize: 16, color: colors.midnight, cursor: 'pointer' }}
          onClick={() => setSleep(!sleep)}
        >
          &nbsp;{translate.phrases.banyanApp('Enable sleep')}&nbsp;
        </span>
      </div>
      <div
        css={{
          flexDirection: 'row',
          alignItems: 'center',
          display: 'flex',
          ...fadeInOutStylesMaker(sleep),
        }}
      >
        <TranslatePhraseWrapper>
          <translate.Phrases.banyanApp
            k="Set to sleep for <numberInput/> <select/> after first notice."
            c={{
              numberInput: (
                <TranslateComponentWrapper>
                  <NumberInput
                    css={{ maxWidth: 80, marginLeft: 5, marginRight: 5 }}
                    onChange={onSleepChange}
                    type="number"
                    value={isNumberOrNumericString(sleepMinutes) ? Number(sleepNumberToDisplay) : ''}
                    classNames={{ input: validateStatusError ? errorStyle : undefined }}
                  />
                  <div />
                </TranslateComponentWrapper>
              ),
              select: (
                <TranslateComponentWrapper>
                  <Select
                    data={selectOptions}
                    value={sleepUnits}
                    css={{ maxWidth: 110, marginRight: 5 }}
                    onChange={(unit: SleepUnitsType) => {
                      onSleepUnitsChange(unit)
                    }}
                  />
                  <div />
                </TranslateComponentWrapper>
              ),
            }}
          />
          <InfoPopover
            content={
              <div>
                {translate.phrases.banyanApp(
                  'When you select the sleep option, it will group and apply to all the stations that fall under this alert. If you have a station that monitors a particularly risky area, it would be best to create an alert unique to that station.',
                )}
              </div>
            }
          />
        </TranslatePhraseWrapper>
      </div>
      {helpText && <ErrorTextWrapper>{helpText}</ErrorTextWrapper>}
    </AlertSection>
  )
}
