import moment, { Moment } from 'moment-timezone'
import { AlertTypeId, Rule } from '../../settings/alertSettings'
import { Contact, Group } from 'stores/userDetailsStore'

export type TVariables = {
  alertTypeId?: AlertTypeId
  blocks: string[] | null
  contacts: { contactId: string; contactMethods: string[] }[]
  endDate: string | null
  frequencyMinutes: number | null
  groups: { groupId: string; contactMethods: string[] }[]
  insectId?: number | null
  name: string
  notifyOwnerByEmail: boolean
  notifyOwnerBySMS: boolean
  properties: string[] | null
  repeatAnnually: boolean
  rules: Rule[]
  sleepMinutes: number | null
  startDate: string | null
  stations: string[] | null
  alertId?: string
}

export const getAlertVariablesForQuery = ({
  alertId, // <-- the presence of 'alertId' will determine if this call will create a new alert xor modify an existing one
  alertTypeId,
  contacts,
  coverageBlocks,
  coverageProperties,
  coverageStations,
  coverageType,
  endDate,
  frequencyMinutes,
  groups,
  insectId,
  name,
  notifyOwnerByEmail,
  notifyOwnerBySMS,
  rules,
  sleepMinutes,
  startDate,
}: {
  alertId?: string
  alertTypeId: AlertTypeId
  contacts: Contact[]
  coverageBlocks: string[]
  coverageProperties: string[]
  coverageStations: string[]
  coverageType: string
  endDate: Moment | null
  frequencyMinutes: number | null
  groups: Group[]
  insectId: number | null
  name: string
  notifyOwnerByEmail: boolean
  notifyOwnerBySMS: boolean
  rules: Rule[]
  sleepMinutes: number | null
  startDate: Moment | null
}) => {
  let variables: TVariables = {
    alertTypeId,
    blocks: null,
    contacts: contacts.map((c) => ({ contactId: c.id, contactMethods: c.alertsContactMethods ?? [] })),
    groups: groups.map((g) => ({ groupId: g.id, contactMethods: g.alertsContactMethods ?? [] })),
    endDate: null,
    frequencyMinutes,
    insectId: insectId ? +insectId : null,
    name,
    notifyOwnerByEmail,
    notifyOwnerBySMS,
    properties: null,
    repeatAnnually: true,
    rules,
    sleepMinutes,
    startDate: null,
    stations: null,
  }

  if (startDate && endDate) {
    // for legacy reasons, the alert lambda codebase will necessitate this being here
    // This should become unnecessary when the lambda-alerts system is refactored
    variables.startDate = moment
      .tz(startDate, 'America/Los_Angeles')
      .startOf('day')
      .subtract(1, 'year')
      .toISOString()

    variables.endDate = moment
      .tz(endDate, 'America/Los_Angeles')
      .endOf('day')
      .subtract(1, 'year')
      .toISOString()
  }

  if (coverageType === 'properties' && coverageProperties && coverageProperties.length) {
    variables.properties = coverageProperties
  }

  if (
    (coverageType === 'stationsOrBlocks' && coverageStations && coverageStations.length) ||
    (coverageBlocks && coverageBlocks.length)
  ) {
    let propertiesAndStations = coverageStations.map((v) => v.split('&')) // will return something like [50617, 0101000020E6100000B98C9B1A68085EC05DA9674128754240]
    let propertiesAndBlocks = coverageBlocks.map((v) => v.split('&')) // will return something like [50930, 16672]

    variables.stations = [...new Set(propertiesAndStations.map(([, stationId]) => stationId))]

    variables.blocks = [...new Set(propertiesAndBlocks.map(([, blockId]) => blockId))]

    variables.properties = [
      ...new Set(propertiesAndStations.concat(propertiesAndBlocks).map(([propertyId]) => propertyId)),
    ]
  }

  // when an alertId is present, we're assuming we're updating an alert… we can't change the alert type or insect type in those cases
  if (alertId) {
    delete variables.alertTypeId

    delete variables.insectId

    variables.alertId = alertId
  }

  return variables
}
