import { ApiRequestQueue, ApiRequestType } from './api/queue'
import { OfflineRequestError } from 'utils/errorCodes'
import { NodeAttachDeviceMeta, serviceCenterNodeAttachDevices } from './api/serviceCenterNodeAttachDevices'
import { TNodeDevices } from '../types'

export async function attachNodeDevices(params: {
  nodeIdentifier: string
  devices: TNodeDevices
}): Promise<void> {
  let unsynced = true

  // Install planned node
  try {
    // Request may or may not have been completed by the time the promise resolves
    const { status } = await ApiRequestQueue.createRequest(
      ApiRequestType.ATTACH_NODE_DEVICES,
      params as NodeAttachDeviceMeta,
    )

    if (status === 'COMPLETED') unsynced = false
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) throw error
  }

  if (unsynced) {
    serviceCenterNodeAttachDevices.callback(params as NodeAttachDeviceMeta)
  }
}
