import md5 from 'md5'
import { Network } from '@capacitor/network'
import { NetworkStatusNotification, networkStore } from '../../../store/networkStore'
import { requestHandlers } from '../index'
import { colorLog } from 'utils/colorLog'
import { ApiResponseError, FailedRequestReason, OfflineRequestError } from 'utils/errorCodes'
import { waitForRequestCompletion } from './waitForRequestCompletion'
import { apiRequestQueueStore } from './apiRequestQueueStore'
import { ApiRequestStatus, ApiRequestType, TApiRequest } from './types'
import { v4 as uuid } from 'uuid'

const OLD_REQUESTS_CLEANUP_INTERVAL = 1000 * 60 * 60 * 12 // 12 hours

let ApiRequestQueue: ApiRequestQueueSingleton

interface ProcessingOptions {
  requestId?: string
  force?: boolean
}

class ApiRequestQueueSingleton {
  // Ordered stack of queue processing requests
  private internalProcessingStack: ProcessingOptions[] = []
  private isProcessing = false

  constructor() {
    // There should only be one instance of ApiRequestQueueSingleton
    if (ApiRequestQueue) throw new Error('ApiRequestQueueSingleton already exists')
  }

  // Trigger processing of the API requests queue
  process(options: ProcessingOptions = {}) {
    if (options?.force) {
      // Prioritize forced executions
      this.internalProcessingStack.unshift(options)
    } else {
      // Add to the end of the stack
      this.internalProcessingStack.push(options)
    }

    this.next()
  }

  async createRequest<R extends TApiRequest>(
    type: ApiRequestType,
    meta: R['meta'],
    options: { wait?: boolean } = {},
  ): Promise<{ id: string; status: ApiRequestStatus }> {
    const { wait = true } = options

    const request: TApiRequest = {
      id: uuid(),
      hash: md5(JSON.stringify(meta)),
      type,
      meta,
      status: ApiRequestStatus.PENDING,
      createdAt: new Date().toISOString(),
      retryCount: 0,
    }

    // Skip if already queued & not completed
    const existingRequest = apiRequestQueueStore
      .getState()
      .queuedRequests.find(
        (r) =>
          r.hash === request.hash &&
          (r.status === ApiRequestStatus.PENDING || r.status === ApiRequestStatus.PROCESSING),
      )

    if (existingRequest) return { id: existingRequest.id, status: existingRequest.status }

    // Check if request is ready
    if (requestHandlers[request.type].preRequest) {
      // Validate request (eg. check if all images have been uploaded)
      const isReady = requestHandlers[request.type].preRequest?.(meta)

      if (isReady === false || isReady instanceof Error) {
        request.status = ApiRequestStatus.PARTIAL
      }
    }

    colorLog('lightblue', 'creating request', request)

    apiRequestQueueStore.actions.queueRequest(request)

    if (!wait) return { id: request.id, status: request.status }

    // Wait 100ms to allow state to update and request to be processed
    // If request is completed (success/error), return completion status
    // If request is processing, wait until it's finished (network is online)
    // If request is still pending, return status (network is probably offline)
    return new Promise((resolve, reject) =>
      setTimeout(async () => {
        const queuedRequest = this.getRequest(request.id)

        if (!queuedRequest) return reject(new Error(`Request not found (${request.id})`))

        // If request was removed from queue, it's already been processed
        if (queuedRequest.status === ApiRequestStatus.COMPLETED) {
          return resolve({ id: request.id, status: ApiRequestStatus.COMPLETED })
        }

        if (queuedRequest.status === ApiRequestStatus.ERROR) return reject(queuedRequest.error)

        // If request is processing, wait until it's finished (network is online)
        if (queuedRequest.status === ApiRequestStatus.PROCESSING) {
          try {
            const completionStatus = await waitForRequestCompletion(request.id)

            resolve({ id: request.id, status: completionStatus })
          } catch (error) {
            reject(error)
          }
        }

        // If request is pending, network is probably offline
        resolve({ id: request.id, status: queuedRequest.status })
      }, 100),
    )
  }

  cancelRequest(id: string): void {
    apiRequestQueueStore.actions.unqueueRequest(id)
  }

  getRequest(id: string): TApiRequest | undefined {
    return apiRequestQueueStore.selectors
      .getRequests(apiRequestQueueStore.getState())
      .find((r) => r.id === id)
  }

  updateRequestMeta<R extends TApiRequest>(id: string, meta: Partial<R['meta']>): void {
    apiRequestQueueStore.actions.updateRequestMeta(id, meta)
  }

  // Execute next processing call
  private next() {
    // If already processing, do nothing
    if (this.isProcessing) return

    if (this.internalProcessingStack.length > 0) {
      // Retrieve next processing call
      const processingOptions = this.internalProcessingStack.shift()

      if (processingOptions) this.doProcessQueue(processingOptions)
    }
  }

  // Process the API requests queue sequentially
  private async doProcessQueue({ requestId, force }: ProcessingOptions) {
    // Lock queue processor to prevent multiple executions at the same time
    this.isProcessing = true

    const queue = apiRequestQueueStore.selectors.getRequests(apiRequestQueueStore.getState())

    let pendingRequests: TApiRequest[]

    if (requestId) {
      // Process a specific request
      pendingRequests = queue.filter((r) => r.id === requestId)
    } else {
      // Process partial & pending requests
      pendingRequests = queue.filter(
        (r) => r.status === ApiRequestStatus.PARTIAL || r.status === ApiRequestStatus.PENDING,
      )
    }

    for (const request of pendingRequests) {
      await this.sendRequest(request, force)
    }

    this.cleanupOldRequests()

    // Unlock queue processor
    this.isProcessing = false

    // Execute next execution call
    this.next()
  }

  private async sendRequest(request: TApiRequest, force = false) {
    const connected = networkStore.getState().connectionStatus.connected

    // If not connected to network, skip request
    if (!connected) return

    if (!requestHandlers[request.type]) throw new Error(`Unknown request type ${request.type}`)

    // If request has been retried before, wait until the retry delay has passed to retry the request
    if (!force && request.nextRetryAt && new Date(request.nextRetryAt) > new Date()) return

    // If request has a pre-request check, validate it
    if (request.status === ApiRequestStatus.PARTIAL && requestHandlers[request.type].preRequest) {
      // Validate request before being sent (eg. wait until all images are uploaded)
      const isValid = requestHandlers[request.type].preRequest?.(request.meta)

      if (isValid instanceof Error) {
        // Pre-request check failed with a custom error message
        apiRequestQueueStore.actions.setPreRequestError(request.id, isValid.message)

        colorLog('orange', `pre-request check failed: ${isValid.message}`, {
          id: request.id,
          type: request.type,
        })

        return
      } else if (!isValid) {
        // Pre-request check failed
        apiRequestQueueStore.actions.setPreRequestError(request.id, true)

        colorLog('orange', 'pre-request check failed', {
          id: request.id,
          type: request.type,
        })

        return
      }
    }

    colorLog('blue', 'processing pending request', {
      id: request.id,
      type: request.type,
    })

    // Mark request as currently processing
    apiRequestQueueStore.actions.setRequestStatus(request.id, ApiRequestStatus.PROCESSING)

    try {
      // Actual request to api endpoint
      const result = await requestHandlers[request.type].request(request.meta)

      colorLog('darkblue', 'executing request callback', {
        id: request.id,
        type: request.type,
      })

      // Callback with result from api endpoint
      if (requestHandlers[request.type].callback) {
        await requestHandlers[request.type].callback?.(result, request.meta)
      }

      apiRequestQueueStore.actions.setRequestStatus(request.id, ApiRequestStatus.COMPLETED)

      colorLog('green', 'request completed', {
        id: request.id,
        type: request.type,
      })
    } catch (error) {
      let requestError: Error
      // Retry request if it's retryable
      let retryable = true

      if (error instanceof Error && error.message === 'Failed to fetch') {
        requestError = new OfflineRequestError(FailedRequestReason.OFFLINE)
      } else if (error instanceof Error && error.name === 'AbortError') {
        requestError = new OfflineRequestError(FailedRequestReason.TIMEOUT)
      } else if (error instanceof ApiResponseError) {
        // Some errors are not retryable (eg. 400 Bad Request)
        if (!error.retryable) retryable = false

        requestError = error
      } else {
        requestError = error as Error

        // Unknown errors are not retryable
        retryable = false
      }

      colorLog('red', 'request error', {
        id: request.id,
        type: request.type,
        error: (requestError as Error).message,
        retryable,
      })

      if (retryable) {
        const retryDelay = 1000 * 2 ** (request.retryCount || 0) // 1s, 2s, 4s, 8s, 16s, 32s...

        // Mark request as PENDING and set the next retry time
        apiRequestQueueStore.actions.setRequestStatus(request.id, ApiRequestStatus.PENDING, {
          error: requestError,
          retryCount: (request.retryCount || 0) + 1,
          lastRetryAt: new Date().toISOString(),
          nextRetryAt: new Date(Date.now() + retryDelay).toISOString(),
        })

        colorLog('orange', `retrying request in ${retryDelay / 1000} seconds`, {
          id: request.id,
          type: request.type,
        })
      } else {
        // Mark request as ERROR if it cannot be retried
        apiRequestQueueStore.actions.setRequestStatus(request.id, ApiRequestStatus.ERROR, {
          error: requestError,
          retryCount: (request.retryCount || 0) + 1,
          lastRetryAt: new Date().toISOString(),
          nextRetryAt: null,
        })
      }
    }
  }

  private cleanupOldRequests() {
    const completedRequests = apiRequestQueueStore.selectors
      .getRequests(apiRequestQueueStore.getState())
      .filter((r) => r.status === ApiRequestStatus.COMPLETED || r.status === ApiRequestStatus.ERROR)

    // Remove old requests from queue
    const oldRequests = completedRequests.filter((r) => {
      const createdAt = new Date(r.createdAt)
      const now = new Date()
      const diff = now.getTime() - createdAt.getTime()

      return diff > OLD_REQUESTS_CLEANUP_INTERVAL
    })

    for (const request of oldRequests) {
      apiRequestQueueStore.actions.unqueueRequest(request.id)
    }
  }
}

ApiRequestQueue = new ApiRequestQueueSingleton()

Network.addListener('networkStatusChange', async (status) => {
  if (status.connected) {
    const uncompletedRequests = apiRequestQueueStore.selectors.getUncompletedRequests(
      apiRequestQueueStore.getState(),
    )

    if (uncompletedRequests.length > 0) {
      // Show notification when coming back online
      networkStore.actions.showNetworkStatusNotification(NetworkStatusNotification.UPLOADING)

      ApiRequestQueue.process()
    }
  }
})

// Process pending requests each time the queue changes
apiRequestQueueStore.subscribeToChanges(
  (s) => s.queuedRequests,
  () => {
    const networkStatusNotification = networkStore.selectors.getNetworkStatusNotification(
      networkStore.getState(),
    )

    const uncompletedRequests = apiRequestQueueStore.selectors.getUncompletedRequests(
      apiRequestQueueStore.getState(),
    )

    const failedRequests = apiRequestQueueStore.selectors.getFailedRequests(apiRequestQueueStore.getState())

    // Show notification when all requests are completed (after coming back online)
    if (networkStatusNotification === NetworkStatusNotification.UPLOADING && !uncompletedRequests.length) {
      if (failedRequests.length > 0) {
        networkStore.actions.showNetworkStatusNotification(NetworkStatusNotification.SYNC_ERROR)
      } else {
        networkStore.actions.showNetworkStatusNotification(NetworkStatusNotification.SYNC_SUCCESS)
      }

      setTimeout(() => {
        networkStore.actions.hideNetworkStatusNotification()
      }, 5000)
    }

    ApiRequestQueue.process()
  },
)

// Process pending requests every 5 seconds
setInterval(() => ApiRequestQueue.process(), 5000) // 5 seconds

export { ApiRequestQueue }

export * from './types'
