import { installationWorkflowStore } from '../installationWorkflowStore'
import { getInstallationSteps } from '../../utils/getInstallationSteps'
import { TEquipmentInstallationStep } from '../../types'

export const actions = {
  setCurrentStep: (stepNumber: number | null) => {
    installationWorkflowStore.setState((s) => ({
      ...s,
      currentStepNumber: stepNumber,
    }))
  },
  showPreviousStep: () => {
    installationWorkflowStore.setState((s) => {
      if (s.currentStepNumber === null || 0) return s

      return {
        ...s,
        currentStepNumber: s.currentStepNumber - 1,
      }
    })
  },
  showNextStep: () => {
    installationWorkflowStore.setState((s) => {
      if (s.currentStepNumber === null || s.currentStepNumber === s.steps.length - 1) return s

      return {
        ...s,
        currentStepNumber: s.currentStepNumber + 1,
      }
    })
  },
  reset: () => {
    installationWorkflowStore.setState((s) => ({
      ...s,
      currentStepNumber: null,
      steps: [],
      dropInstallLocation: null,
    }))
  },
  start: () => {
    const steps: TEquipmentInstallationStep[] = getInstallationSteps()

    installationWorkflowStore.setState((s) => ({
      ...s,
      currentStepNumber: 0,
      steps,
    }))
  },
  refreshSteps: () => {
    const steps = getInstallationSteps()

    installationWorkflowStore.setState((s) => {
      return {
        ...s,
        steps,
      }
    })
  },
  updateStep: (step: TEquipmentInstallationStep) => {
    const steps = installationWorkflowStore.getState().steps
    const index = steps.findIndex((s) => s.type === step.type)

    if (index !== -1) {
      steps[index] = step
    }

    installationWorkflowStore.setState((s) => ({
      ...s,
      steps,
    }))
  },
  setDropInstallLocation: (location: google.maps.LatLngLiteral | 'INSTALL_COMPLETED') => {
    installationWorkflowStore.setState((s) => ({
      ...s,
      dropInstallLocation: location,
    }))
  },
}
