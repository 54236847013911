import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty, sortByKey } from '@semios/app-platform-common'
import { AlmondHullSplitSettings } from 'App/Map/PanelDetails/_utils/by-domain/almondHullSplit/almondHullSplitSettings/AlmondHullSplitSettings'
import { getTimezoneForSelectedProperty } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedProperty'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { EAggregationInterval } from 'App/Map/types'
import { GridTableContentSection } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { DateRangeDoesntApply } from './components/DateRangeDoesntApply'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_ALMOND_HULL_SPLIT_SUMMARY' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.UNAGGREGATED }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  almondHullSplit: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  const blocks = fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.blocks

  if (!blocks) return {}

  const relevantBlocksForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(blocks),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  if (!selectedValueGroups.almond_hull_split || isEmpty(relevantBlocksForProperty)) return {}

  const blockIds = relevantBlocksForProperty.map((block) => block.blockId)

  return {
    blocks: {
      blockIds,
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  isWideScreen,
}: {
  data: routes.Values.Response
  isWideScreen: boolean
}): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.almondHullSplit().categoryTitleWithoutUnit(),
    titleChildren: (
      <>
        <DropdownSelectorProperty valuesTimeseriesToFilterOn={Object.keys(valuesRequested)} />
        <div>
          <AlmondHullSplitSettings />
        </div>
      </>
    ),
    id: 'summary-grid-almond-hull-split',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const propertyId = selectedFieldAssetsStore.getState().property
  const blocks = fieldAssetStore.getState()?.properties?.[Number(propertyId)]?.blocks ?? {}

  const relevantBlocksForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(blocks),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  const cultivars = fieldAssetStore.getState()?.crops?.[2]?.cultivars ?? {}
  const timezone = getTimezoneForSelectedProperty()

  const blockItems = relevantBlocksForProperty
    .map((block) => {
      const dataForBlock = data?.blocks?.[block.blockId]?.values?.almondHullSplit ?? []

      return {
        blockName: block.name || translate.phrases.banyanApp('Unnamed Block'),
        cultivars: dataForBlock
          .map((cultivar) => {
            const { metadata, timeseries } = cultivar
            const { cultivarId } = metadata

            return {
              name: cultivars[cultivarId], // do we translate them all??
              bloomDate: unitConverter
                .almondHullSplit(cultivar.metadata.fullBloomDate, { timezone, shortenDate: !isWideScreen })
                .valueWithSuffix(),
              hullSplitDate: timeseries[0]?.value
                ? unitConverter
                    .almondHullSplit(timeseries[0].value, { timezone, shortenDate: !isWideScreen })
                    .valueWithSuffix()
                : null,
            }
          })
          .sort(sortByKey('name')),
      }
    })
    .sort(sortByKey('blockName'))

  if (isEmpty(blockItems)) return { ...commonReturnItems, fullWidthItems: [] }

  const allBloomDates = blockItems.flatMap((blockItem) =>
    blockItem.cultivars?.map((cultivar) => cultivar.bloomDate),
  )

  const allHullSplitDates = blockItems.flatMap((blockItem) =>
    blockItem.cultivars?.map((cultivar) => cultivar.hullSplitDate),
  )

  const maxLabelCharacters = Math.max(
    ...blockItems.map((blockItem) => blockItem.blockName.length),
    ...(blockItems[0]?.cultivars?.map((cultivar) => cultivar.name.length) ?? []),
  )

  const maxBloomDateCharacters = isEmpty(allBloomDates)
    ? 9
    : Math.max(...allBloomDates.map((date) => date.length))

  const maxHullSplitDateCharacters = isEmpty(allHullSplitDates.filter(Boolean))
    ? 9
    : Math.max(...allHullSplitDates.map((date) => String(date).length))

  const labelWidth = maxLabelCharacters * 10 < 156 && isWideScreen ? 156 : maxLabelCharacters * 10
  const bloomCellWidth = maxBloomDateCharacters * 10
  const hullSplitCellWidth = maxHullSplitDateCharacters * 10
  const lineHeight = 26
  const border = `2px solid ${colors.grey200}`
  const padding = '5px 2px'
  const infoBoxHeight = isWideScreen ? 50 : 100
  const headerHeight = lineHeight * 3 + (isWideScreen ? 0 : 15)

  return {
    ...commonReturnItems,
    fullWidthItems: [
      {
        height:
          lineHeight * 1.5 * blockItems.length +
          lineHeight * blockItems[0].cultivars.length * blockItems.length +
          infoBoxHeight +
          headerHeight,
        content: (
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: -10,
              marginLeft: -20,
              minWidth: '100%',
            }}
          >
            <div>
              <DateRangeDoesntApply />
            </div>
            <div
              css={{
                width: labelWidth + bloomCellWidth + hullSplitCellWidth,
                fontSize: 14,
                marginTop: 10,
              }}
            >
              {/* header */}
              <div>
                <div
                  css={{
                    'display': 'flex',
                    'alignItems': 'flex-end',
                    'borderBottom': border,
                    '> *': { padding },
                    'background': colors.grey50,
                  }}
                >
                  <div css={{ width: labelWidth, marginLeft: 5 }} />
                  <div css={{ width: bloomCellWidth, marginLeft: 10 }}>
                    {translate.phrases.banyanApp('Full Bloom')}
                  </div>
                  <div css={{ width: hullSplitCellWidth }}>
                    {translate.phrases.banyanApp('Hull Split Prediction')}
                  </div>
                </div>
              </div>
              {/*  body */}
              {blockItems.map((blockItem) => (
                <div
                  key={blockItem.blockName}
                  css={{ 'display': 'flex', 'flexDirection': 'row', '> *': { padding } }}
                >
                  {/* label column */}
                  <div
                    css={{
                      'display': 'flex',
                      'flexDirection': 'column',
                      'width': labelWidth,
                      'borderRight': border,
                      'borderBottom': border,
                      '> *': { padding },
                      'overflowX': 'hidden',
                      'maxWidth': isWideScreen ? 'unset' : labelWidth,
                    }}
                  >
                    <span css={{ fontWeight: 500, marginLeft: 5 }}>{blockItem.blockName}</span>
                    {blockItem.cultivars.map((cultivar) => (
                      <span key={cultivar.name} css={{ marginLeft: 10 }}>
                        {cultivar.name}
                      </span>
                    ))}
                  </div>
                  {/* bloom column */}
                  <div
                    css={{
                      'display': 'flex',
                      'flexDirection': 'column',
                      'justifyContent': 'flex-end',
                      'alignItems': 'center',
                      'width': bloomCellWidth,
                      'borderRight': border,
                      'borderBottom': border,
                      '> *': { padding },
                    }}
                  >
                    {isEmpty(blockItem.cultivars) && <span>{translate.phrases.templates('-')}</span>}
                    {blockItem.cultivars.map((cultivar) => (
                      <span key={cultivar.name + 'bloom'}>{cultivar.bloomDate}</span>
                    ))}
                  </div>
                  {/* hull split column */}
                  <div
                    css={{
                      'display': 'flex',
                      'flexDirection': 'column',
                      'justifyContent': 'flex-end',
                      'alignItems': 'center',
                      'width': hullSplitCellWidth,
                      'borderBottom': border,
                      '> *': { padding },
                    }}
                  >
                    {isEmpty(blockItem.cultivars) && <span>{translate.phrases.templates('-')}</span>}
                    {blockItem.cultivars.map((cultivar) => (
                      <span key={cultivar.name + 'hull split'}>
                        {cultivar.hullSplitDate ?? translate.phrases.abbreviations('Not Applicable')}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ),
      },
    ],
  }
}
