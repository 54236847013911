import { IRRIGATION_SCHEDULER_FIRST_DAY_OF_WEEK } from '@semios/app-platform-common'
import moment from 'moment-timezone'
import {
  TIrrigationSchedulerStoreState,
  irrigationSchedulerStore,
  TDateViewEnum,
} from '../irrigationSchedulerStore'

export const setSelectedPropertyIdAndClearSelectedIrrigationZoneEmitterIds = (
  selectedPropertyId: TIrrigationSchedulerStoreState['selectedPropertyId'],
  timezone: string,
) => {
  const mNow = moment.tz(timezone)

  irrigationSchedulerStore.setState((isss) => {
    // Reset dateFrom to the start of day/week using the new timezone
    const dateFrom =
      isss.dateView === TDateViewEnum.DAY
        ? moment.tz(mNow, timezone).startOf('day').toISOString()
        : moment.tz(mNow, timezone).day(IRRIGATION_SCHEDULER_FIRST_DAY_OF_WEEK).startOf('day').toISOString()

    return {
      ...isss,
      selectedPropertyId,
      dateFrom,
      selectedIrrigationZoneEmitterIds: [],
    }
  })
}
