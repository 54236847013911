import { css } from '@emotion/css'
import { faArrowAltCircleRight, faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'components/Button/Button'
import CssType from 'csstype'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React, { CSSProperties } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { MantineDateRangePickerWithFooter } from './MantineDateRangePickerWithFooter/MantineDateRangePickerWithFooter'
import { constructDateWithTimeZone } from './_utils/constructDateWithTimeZone'
import { DateValue, DatesRangeValue } from './MantineDateRangePickerWithFooter/types'
import { useViewportSize } from '@mantine/hooks'

export const DateRangePicker = (props: {
  handleApply: (props: {
    handleValueChange: (range: DatesRangeValue) => void
    setDropdownOpened: React.Dispatch<React.SetStateAction<boolean>>
    value: DatesRangeValue | null
  }) => void
  dateFrom?: moment.Moment
  dateTo?: moment.Moment
  width?: CssType.Property.Width<string | number>
  height: number
  minDate?: moment.Moment
  maxDate?: moment.Moment
  timezone: string
}) => {
  const timezone = moment.tz.guess()
  // TODO: use the new hook once merged in
  const { width: screenWidth } = useViewportSize()

  const reconstructedDateFrom = props.dateFrom
    ? constructDateWithTimeZone(props.dateFrom.toString(), timezone)
    : null

  const reconstructedDateTo = props.dateTo
    ? constructDateWithTimeZone(props.dateTo.toString(), timezone)
    : null

  const maxDate = constructDateWithTimeZone(
    props.maxDate?.toString() || moment.tz(timezone).add(14, 'days').toString(),
    timezone,
  )

  const minDate = constructDateWithTimeZone(
    props.minDate?.toString() ||
      moment.tz(SharedSettings.FIRST_DAY_OF_DATA_FOR_MANY_THINGS, timezone).toString(),
    timezone,
  )

  return (
    <MantineDateRangePickerWithFooter
      clearable={false}
      closeOnChange={false}
      defaultValue={[reconstructedDateFrom, reconstructedDateTo]}
      icon={<FontAwesomeIcon className={css({ color: colors.grey800, fontSize: 16 })} icon={faCalendar} />}
      minDate={minDate}
      maxDate={maxDate}
      placeholder={translate.phrases.banyanApp('Select Date Range')}
      timezone={timezone}
      valueFormat={translate.dates.getMomentFormat('MMM D, YYYY')}
      renderDayWithValue={(day: DateValue, value: DatesRangeValue) => {
        const isToday = moment.tz(timezone).isSame(day, 'day')
        const isInRange = value[0] && value[1] && moment.tz(day, timezone).isBetween(value[0], value[1])

        const isSelected =
          (value[0] && !value[1] && moment.tz(day, timezone).isSame(value[0], 'day')) ||
          (value[1] && !value[0] && moment.tz(day, timezone).isSame(value[1], 'day'))

        const isFirstInRange = value[0] && value[1] && moment.tz(day, timezone).isSame(value[0], 'day')
        const isLastInRange = value[0] && value[1] && moment.tz(day, timezone).isSame(value[1], 'day')

        let style: CSSProperties = {
          borderRadius: '0',
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }

        if (isToday) {
          style = {
            ...style,
            border: `2px solid ${colors.primary}`,
            borderRadius: isInRange || isSelected ? '0' : '3px',
            color: colors.primary,
            fontWeight: 'bold',
          }
        }

        if (isSelected || isFirstInRange || isLastInRange) {
          let selectionStyle: CSSProperties = {
            backgroundColor: colors.midnight,
            color: 'white',
            ...(isToday ? { fontWeight: 'normal' } : {}),
          }

          style = {
            ...style,
            ...selectionStyle,
            borderRadius: '3px',
          }
        } else if (isInRange) {
          style = {
            ...style,
            backgroundColor: colors.grey500,
          }
        }

        return <div style={style}>{translate.dates.format(moment.tz(day, timezone), 'D')}</div>
      }}
      Footer={(footerProps: {
        handleValueChange: (range: DatesRangeValue) => void
        setDropdownOpened: React.Dispatch<React.SetStateAction<boolean>>
        value: DatesRangeValue | null
      }) => (
        <div
          style={{
            display: 'flex',
            gap: 6,
            maxWidth: 266, // TODO: magic number from mantine
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 10,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <div
            className={css`
              display: flex;
              width: 100%;
              flex-wrap: wrap;
              justify-content: space-around;
            `}
          >
            <Button
              className={css`
                width: 48%;
              `}
              onClick={() =>
                footerProps.handleValueChange([
                  constructDateWithTimeZone(
                    moment.tz(timezone).subtract(7, 'days').startOf('day').toString(),
                    timezone,
                  ),
                  constructDateWithTimeZone(
                    moment.tz(timezone).add(14, 'days').endOf('day').toString(),
                    timezone,
                  ),
                ])
              }
              variant="tertiary"
            >
              {translate.phrases.banyanApp('Last {{count}} Days', { count: 7 })}
            </Button>
            <Button
              className={css`
                width: 48%;
              `}
              onClick={() =>
                footerProps.handleValueChange([
                  constructDateWithTimeZone(
                    moment.tz(timezone).subtract(14, 'days').startOf('day').toString(),
                    timezone,
                  ),
                  constructDateWithTimeZone(
                    moment.tz(timezone).add(14, 'days').endOf('day').toString(),
                    timezone,
                  ),
                ])
              }
              variant="tertiary"
            >
              {translate.phrases.banyanApp('Last {{count}} Days', { count: 14 })}
            </Button>
          </div>
          <div
            className={css`
              display: flex;
              width: 100%;
              flex-wrap: wrap;
              justify-content: space-around;
            `}
          >
            <Button
              className={css`
                width: 48%;
              `}
              onClick={() =>
                footerProps.handleValueChange([
                  constructDateWithTimeZone(
                    moment.tz(timezone).subtract(30, 'days').startOf('day').toString(),
                    timezone,
                  ),
                  constructDateWithTimeZone(
                    moment.tz(timezone).add(14, 'days').endOf('day').toString(),
                    timezone,
                  ),
                ])
              }
              variant="tertiary"
            >
              {translate.phrases.banyanApp('Last {{count}} Days', { count: 30 })}
            </Button>
            <Button
              className={css`
                width: 48%;
              `}
              onClick={() =>
                footerProps.handleValueChange([
                  constructDateWithTimeZone(moment.tz(timezone).startOf('day').toString(), timezone),
                  constructDateWithTimeZone(
                    moment.tz(timezone).add(14, 'days').endOf('day').toString(),
                    timezone,
                  ),
                ])
              }
              variant="tertiary"
            >
              {translate.phrases.banyanApp('Next {{count}} Days', { count: 14 })}
            </Button>
          </div>
          <Button
            fullWidth={true}
            disabled={!(footerProps.value && footerProps.value.every((v) => v !== null))}
            onMouseDown={(event) => {
              event.preventDefault()

              event.stopPropagation()

              if (footerProps.value && footerProps.value.every((v: DateValue) => v !== null)) {
                footerProps.setDropdownOpened(false)

                props.handleApply(footerProps)
              }
            }}
            rightIcon={<FontAwesomeIcon icon={faArrowAltCircleRight} />}
          >
            {translate.phrases.banyanApp('Apply')}
          </Button>
        </div>
      )}
      styles={() => {
        return {
          /**
           * narrower screens can see a date picker that doesn't fit quite right.
           * "month" is the table element, and tables can't get maxWidth, but a
           * width of 100% is pretty ugly
           */
          month: (screenWidth || window.innerWidth) < 369 ? { width: '100%' } : {},
          icon: {
            // by default, mantine sticks it on the left, but our designs like the right side
            left: 'auto',
            right: 0,
          },
          input: {
            border: `1px solid ${colors.grey800}`,
            padding: '0px 12px !important',
            height: props.height,
          },
          day: {
            marginTop: 2,
            marginBottom: 2,
            marginRight: -1,
          },
          color: colors.grey800,
          root: {
            width: props.width,
            height: 30,
            marginBottom: 18,
          },
          pickerControl: {
            'border': `2px solid ${colors.grey800}`,
            'backgroundColor': colors.white,
            'borderRadius': '16px',
            'margin': 2,
            'height': 32,
            'maxWidth': 80,
            '&[disabled]': {
              'border': '2px solid transparent',
              'color': colors.grey500,
              'backgroundColor': colors.white,
              '&:hover': { backgroundColor: colors.white },
            },
          },
        }
      }}
    />
  )
}
