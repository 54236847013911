import { FC, useEffect } from 'react'
import { Capacitor } from '@capacitor/core'
import { Nfc, NfcTagScannedEvent, ScanSessionErrorEvent, NfcUtils } from '@semios/capacitor-nfc'
import { translate } from 'i18n/i18n'

const utils = new NfcUtils()

const startScan = async (callback: (result: ScanSessionErrorEvent | NfcTagScannedEvent | null) => void) => {
  await Nfc.addListener('nfcTagScanned', (result: NfcTagScannedEvent) => {
    callback(result)
  })

  await Nfc.addListener('scanSessionCanceled', () => {
    callback(null)
  })

  await Nfc.addListener('scanSessionError', (result: ScanSessionErrorEvent) => {
    callback(result)
  })

  await Nfc.startScanSession()
}

const stopScan = async () => {
  await Nfc.removeAllListeners()

  await Nfc.stopScanSession()
}

const scanSingleNfcTag = async () => {
  const res = await new Promise((resolve) => {
    startScan((result) => {
      resolve(result)
    })
  })

  await stopScan()

  //Canceled
  if (res === null) return {}

  try {
    const payload = (res as NfcTagScannedEvent).nfcTag.message?.records[0].payload

    if (!payload) {
      return {
        value: undefined,
        error: translate.phrases.placeholder('No Nfc payload'),
      }
    }

    const formattedText = utils.convertBytesToString({ bytes: payload }).text.slice(3) //first 2 characters are the language code
    //Ref: https://stackoverflow.com/questions/59515271/why-android-nfc-reader-adds-en-before-the-message

    return {
      value: formattedText,
      error: (res as ScanSessionErrorEvent).message,
    }
  } catch (e) {
    return {
      value: undefined,
      error: (res as ScanSessionErrorEvent).message,
    }
  }
}

export const isNfcSupported = async () => {
  const { isSupported } = await Nfc.isSupported()

  return isSupported
}

const openSettings = async () => {
  await Nfc.openSettings()
}

const checkPermissions = async () => {
  const { nfc } = await Nfc.checkPermissions()

  return nfc
}

const requestPermissions = async () => {
  const { nfc } = await Nfc.requestPermissions()

  return nfc
}

export const NfcScanner: FC<{
  onScan: (args: { value?: string; error?: string }) => void
}> = ({ onScan }) => {
  const onDismiss = () => {
    stopScan()
  }

  const onError = (reason: string) => {
    onDismiss()

    onScan({ error: reason })
  }

  useEffect(() => {
    const tryToScanSingleNfcTag = async () => {
      if (Capacitor.getPlatform() === 'web') {
        onError(translate.phrases.placeholder('Not supported yet on web platform'))

        return
      }

      if (!(await isNfcSupported())) {
        onError(translate.phrases.placeholder('Not supported'))

        return
      }

      if ((await checkPermissions()) !== 'granted') {
        if ((await requestPermissions()) !== 'granted') {
          const openSetting = confirm(
            translate.phrases.placeholder('Nfc read requires nfc permission. Open settings?'),
          )

          if (openSetting) {
            openSettings()
          }

          onError(translate.phrases.placeholder('Permission not granted'))

          return
        }
      }

      const result = await scanSingleNfcTag()

      onScan(result)
    }

    tryToScanSingleNfcTag()

    return () => {
      stopScan()
    }
  }, [])

  return <></>
}
