import { ReactNode } from 'react'
import { colors } from 'settings/colors'

export const AlertSection = ({
  title,
  children,
  isLastSection = false,
}: {
  title?: string
  isLastSection?: boolean
  children: ReactNode
}) => {
  return (
    <div css={{ padding: 24, borderBottom: !isLastSection ? `1px solid ${colors.grey200}` : 0 }}>
      {title && (
        <div css={{ fontSize: 16, fontWeight: 500, color: colors.midnight, marginBottom: 20 }}>{title}</div>
      )}
      {children}
    </div>
  )
}
