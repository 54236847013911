import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { TUnitConverterOptionsWithoutUnits, TUnitConverterTypicalReturn } from '../types'

const DEFAULT_DECIMAL_PLACES = 1

type TCanopy = 'ABOVE' | 'IN' | 'BELOW'

const humidityFunctionMaker =
  (canopy: TCanopy) =>
  (
    relativeHumidityPercentWhereWeWouldUse90InsteadOfPoint9: number | null = null,
    { decimalPlaces = DEFAULT_DECIMAL_PLACES }: TUnitConverterOptionsWithoutUnits = {},
  ) => {
    let convertedValue: number | null

    if (isNil(relativeHumidityPercentWhereWeWouldUse90InsteadOfPoint9)) {
      convertedValue = null
    } else {
      convertedValue = relativeHumidityPercentWhereWeWouldUse90InsteadOfPoint9
    }

    const unitSymbol = translate.measurements.percentage.unit()

    return {
      categoryTitle: () =>
        translate.phrases.templates('{{label}} ({{unitSymbol}})', {
          label: translate.phrases.banyanApp('Humidity'),
          unitSymbol,
        }),
      categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Humidity'),
      defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES,
      suffix: () => unitSymbol,
      shortTitle: () => {
        if (canopy === 'ABOVE') {
          return translate.phrases.banyanApp('Above Canopy')
        }

        if (canopy === 'IN') {
          return translate.phrases.banyanApp('In Canopy')
        }

        return translate.phrases.banyanApp('Below Canopy')
      },
      title: () => {
        if (canopy === 'ABOVE') {
          return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
            labelA: translate.phrases.banyanApp('Humidity'),
            labelB: translate.phrases.banyanApp('Above Canopy'),
            unitSymbol,
          })
        }

        if (canopy === 'IN') {
          return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
            labelA: translate.phrases.banyanApp('Humidity'),
            labelB: translate.phrases.banyanApp('In Canopy'),
            unitSymbol,
          })
        }

        return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
          labelA: translate.phrases.banyanApp('Humidity'),
          labelB: translate.phrases.banyanApp('Below Canopy'),
          unitSymbol,
        })
      },
      titleWithoutUnit: () => {
        if (canopy === 'ABOVE') {
          return translate.phrases.templates('{{labelA}} ({{labelB}})', {
            labelA: translate.phrases.banyanApp('Humidity'),
            labelB: translate.phrases.banyanApp('Above Canopy'),
          })
        }

        if (canopy === 'IN') {
          return translate.phrases.templates('{{labelA}} ({{labelB}})', {
            labelA: translate.phrases.banyanApp('Humidity'),
            labelB: translate.phrases.banyanApp('In Canopy'),
          })
        }

        return translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Humidity'),
          labelB: translate.phrases.banyanApp('Below Canopy'),
        })
      },
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlaces)
      },
      valueAsString: () => {
        return translate.measurements.percentage.value(convertedValue, decimalPlaces)
      },
      valueWithSuffix: () => {
        return translate.measurements.percentage.valueWithUnit(convertedValue, decimalPlaces)
      },
      valueWithNoRounding: () => convertedValue,
    }
  }

type THumidityOptions = 'humidityAboveCanopy' | 'humidityInCanopy' | 'humidityBelowCanopy'

export const humidity: Record<
  THumidityOptions,
  (
    relativeHumidityPercentWhereWeWouldUse90InsteadOfPoint9?: number | null,
    options?: TUnitConverterOptionsWithoutUnits,
  ) => TUnitConverterTypicalReturn
> = {
  humidityAboveCanopy: humidityFunctionMaker('ABOVE'),
  humidityInCanopy: humidityFunctionMaker('IN'),
  humidityBelowCanopy: humidityFunctionMaker('BELOW'),
}
