import { BarcodeScannedEvent, BarcodeScanner, ScanErrorEvent } from '@capacitor-mlkit/barcode-scanning'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { Modal, Switch } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'
import { IconTorchOff } from 'components/icons/IconTorchOff'
import { IconTorchOn } from 'components/icons/IconTorchOn'
import { NarrowHeader } from 'components/ModalDrawer/NarrowHeader/NarrowHeader'
import { translate } from 'i18n/i18n'
import { CSSProperties, FC, useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { smallStore } from 'stores/smallStore'

const Crosshairs = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 220 239')}>
      <path d="M218 62.7101V2H161.763" stroke="currentColor" strokeWidth="4" />
      <path d="M2 63.3492V2.63916H58.2367" stroke="currentColor" strokeWidth="4" />
      <path d="M218 175.822L218 236.532L161.763 236.532" stroke="currentColor" strokeWidth="4" />
      <path d="M1.99999 175.822L2 236.532L58.2367 236.532" stroke="currentColor" strokeWidth="4" />
    </svg>
  )
}

const TorchSwitch = (props: {
  style: CSSProperties
  checked: boolean
  onChecked: (check: boolean) => void
}) => {
  return (
    <Switch
      size="xl"
      color="green.0"
      style={props.style}
      styles={{
        track: {
          boxShadow: '0px 5px 4px rgba(74, 74, 104, 0.2)',
        },
      }}
      checked={props.checked}
      onChange={(event) => props.onChecked(event.currentTarget.checked)}
      onLabel={
        <div css={{ color: 'white', fontSize: 20 }}>
          <IconTorchOn />
        </div>
      }
      offLabel={
        <div css={{ color: '#0B1424', fontSize: 20 }}>
          <IconTorchOff />
        </div>
      }
    />
  )
}

const startScan = async (callback: (result: ScanErrorEvent | BarcodeScannedEvent) => void) => {
  smallStore.setState((s) => ({ ...s, showQRScannerModal: true }))

  await BarcodeScanner.addListener('barcodeScanned', (result: BarcodeScannedEvent) => {
    callback(result)
  })

  await BarcodeScanner.addListener('scanError', (result: ScanErrorEvent) => {
    callback(result)
  })

  const options = {
    // formats: [BarcodeFormat.QrCode, BarcodeFormat.DataMatrix] //performance improvement
  }

  await BarcodeScanner.startScan(options)
}

const stopScan = async () => {
  smallStore.setState((s) => ({ ...s, showQRScannerModal: false }))

  await BarcodeScanner.removeAllListeners()

  await BarcodeScanner.stopScan()
}

const scanSingleBarcode = async () => {
  const res = await new Promise((resolve) => {
    startScan((result) => {
      resolve(result)
    })
  })

  await stopScan()

  return {
    value: (res as BarcodeScannedEvent).barcode.displayValue,
    error: (res as ScanErrorEvent).message,
  }
}

export const isQrSupported = async () => {
  const { supported } = await BarcodeScanner.isSupported()

  return supported
}

const isTorchAvailable = async () => {
  const { available } = await BarcodeScanner.isTorchAvailable()

  return available
}

const isTorchEnabled = async () => {
  const { enabled } = await BarcodeScanner.isTorchEnabled()

  return enabled
}

const openSettings = async () => {
  await BarcodeScanner.openSettings()
}

const checkPermissions = async () => {
  const { camera } = await BarcodeScanner.checkPermissions()

  return camera
}

const requestPermissions = async () => {
  const { camera } = await BarcodeScanner.requestPermissions()

  return camera
}

export const QrScanner: FC<{
  onScan: (args: { value?: string; error?: string }) => void
}> = ({ onScan }) => {
  const [modalIsOpen, { close: closeModal, open: openModal }] = useDisclosure(false)
  const [torchAvailable, setTorchAvailable] = useState<boolean>(false)
  const [torchEnabled, setTorchEnabled] = useState<boolean>(false)

  const onDismiss = () => {
    stopScan()

    closeModal()
  }

  const onError = (reason: string) => {
    onDismiss()

    onScan({ error: reason })
  }

  const onCancel = () => {
    onDismiss()

    onScan({})
  }

  const toggleTorch = async () => {
    await BarcodeScanner.toggleTorch()

    setTorchEnabled(await isTorchEnabled())
  }

  useEffect(() => {
    App.addListener('backButton', () => {
      App.removeAllListeners()

      onCancel()
    })

    return () => {
      App.removeAllListeners()
    }
  }, [])

  useEffect(() => {
    const tryToScanSingleBarcode = async () => {
      if (Capacitor.getPlatform() === 'web') {
        onError(translate.phrases.placeholder('Not supported yet on web platform'))

        return
      }

      if (!(await isQrSupported())) {
        onError(translate.phrases.placeholder('Not supported'))

        return
      }

      if ((await checkPermissions()) !== 'granted') {
        if ((await requestPermissions()) !== 'granted') {
          const openSetting = confirm(
            translate.phrases.placeholder('QR Scanner requires camera permission. Open settings?'),
          )

          if (openSetting) {
            openSettings()
          }

          onError(translate.phrases.placeholder('Permission not granted'))

          return
        }
      }

      setTorchAvailable(await isTorchAvailable())

      setTorchEnabled(await isTorchEnabled())

      openModal()

      const result = await scanSingleBarcode()

      onScan(result)
    }

    tryToScanSingleBarcode()
  }, [])

  //We didn't use openModal to open the modal so that it won't be affected by showQRScannerModal state, thus it would still be visible even if the state is true
  return (
    <Modal
      styles={{
        overlay: {
          backgroundColor: 'transparent',
        },
        content: {
          backgroundColor: 'transparent',
        },
        body: {
          height: '100%',
        },
      }}
      padding={0}
      fullScreen={true}
      opened={modalIsOpen}
      withCloseButton={false}
      onClose={onCancel}
    >
      <NarrowHeader
        css={{
          height: 60,
          backgroundColor: colors.grey50,
        }}
        title={translate.phrases.placeholder('Scan QR Code')}
        onClose={onCancel}
      />
      <div
        css={{
          position: 'relative',
          height: 'calc(100% - 60px)', // 60px is the height of the header
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {torchAvailable && (
          <TorchSwitch
            style={{
              position: 'absolute',
              width: '72px',
              top: '1em',
              right: '1em',
            }}
            checked={torchEnabled}
            onChecked={() => {
              toggleTorch()
            }}
          />
        )}
        <div
          css={{
            position: 'absolute',
            fontSize: 239,
            color: 'white',
          }}
        >
          <Crosshairs />
        </div>
      </div>
    </Modal>
  )
}
