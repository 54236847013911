import { sortByKey } from '@semios/app-platform-common'
import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput'
import { useState } from 'react'
import { Contact, Group } from 'stores/userDetailsStore'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'

export const useSearchGroupsAndContacts = ({
  initialList,
  placeholder,
}: {
  initialList: Array<Group | Contact>
  placeholder: string
}) => {
  const [searchText, setSearchText] = useState('')

  const filteredList = initialList.filter((item: Group | Contact) => {
    return searchStringFromStringWithSpaces(searchText, item.name)
  })

  const filteredAndSortedList = filteredList.sort(sortByKey('name'))

  return {
    SearchInputComponent: (
      <div>
        <SearchTextInput placeholder={placeholder} searchText={searchText} setSearchText={setSearchText} />
      </div>
    ),
    filteredAndSortedList,
  }
}
