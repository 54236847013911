import { TFieldAssetKeyTypes, TFieldAssetValueTypes } from 'App/Map/types'
import polylabel from 'polylabel'
import { sortByClosestPoints } from 'utils/sortByClosestPoints'
import { lngLatStringToLngLatObject } from './lngLatStringToLngLatObject'

export const getClosestBlockInPropertyForAPoint = ({
  anchorCoordinates,
  property,
}: {
  anchorCoordinates: { lng: number; lat: number }
  property: TFieldAssetValueTypes.TProperty
}): TFieldAssetKeyTypes.TBlockId | null => {
  const point = Object.values(property.points || {}).find((p) => {
    const { lng, lat } = lngLatStringToLngLatObject(p.lngLat)
    const pointHasBlocks = p?.blockIds?.length

    return pointHasBlocks && lng === anchorCoordinates.lng && lat === anchorCoordinates.lat
  })

  const [closest] = Object.values(property.blocks ?? {})
    .filter((block) => {
      if (point) {
        return point.blockIds.includes(block.blockId)
      } else {
        return true
      }
    })
    .map((f) => {
      const { coordinates } = JSON.parse(f.geometry)
      const [lng, lat] = polylabel([coordinates[0]], 1.0)

      return { lng, lat, blockId: f.blockId }
    })
    .sort(sortByClosestPoints(anchorCoordinates))

  if (closest) return closest.blockId

  return null
}
