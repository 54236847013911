import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import { OfflineRequestError } from 'utils/errorCodes'
import { NodeDeviceInstallMeta, serviceCenterNodeDeviceInstall } from './api/serviceCenterNodeDeviceInstall'

export interface NodeDeviceMeta {
  height: string
  connector: keyof SharedTypes.TNodeDevices
  channel: number
}

export async function installNodeDevice(
  nodeIdentifier: string,
  plannedDevice: SharedTypes.TNodeDevice,
  meta: NodeDeviceMeta,
) {
  const { source } = plannedDevice

  let { height, connector, channel } = meta
  let unsynced = true

  connector = connector.toLowerCase() as keyof SharedTypes.TNodeDevices

  channel = Number(channel)

  const payload = {
    nodeIdentifier,
    port: connector,
    channel,
    plannedChannel: plannedDevice.channel,
    source,
    height,
    plannedDevice,
  } as NodeDeviceInstallMeta

  // Attach device to node
  try {
    // Request may or may not have been completed by the time the promise resolves
    const { status } = await ApiRequestQueue.createRequest(ApiRequestType.INSTALL_NODE_DEVICE, payload)

    if (status === ApiRequestStatus.COMPLETED) unsynced = false
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) {
      // TODO: Return a user-friendly error message
      throw error
    }
  }

  if (unsynced) {
    serviceCenterNodeDeviceInstall.callback(undefined, payload)
  }
}
