import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty, sortByKey } from '@semios/app-platform-common'
import { AlmondBloomSettings } from 'App/Map/PanelDetails/_utils/by-domain/almondBloom/AlmondBloomSettings/AlmondBloomSettings'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { EAggregationInterval } from 'App/Map/types'
import { GridTableContentSection } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { defaultValuesRowHeight, timestampToValuesDictionaryMaker } from '../_utils'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_BLOOM_PREDICTION' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  almondBloom: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  const blocks = fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.blocks

  if (!blocks) return {}

  const relevantBlocksForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(blocks),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  if (!selectedValueGroups.almond_bloom || isEmpty(relevantBlocksForProperty)) return {}

  const blockIds = relevantBlocksForProperty.map((block) => block.blockId)

  return {
    blocks: {
      blockIds,
      valuesRequested,
    },
  }
}

const generateItem = ({
  block,
  label,
  values,
}: {
  block: string
  label: string
  values: ReturnType<typeof timestampToValuesDictionaryMaker>
}) => ({
  id: 'almond_bloom' + block,
  label,
  height: defaultValuesRowHeight,
  labelMinWidth: 90,
  valueMinWidth: 120,
  render: (dataPoint: { value: number | null }) => {
    if (typeof dataPoint?.value !== 'number') {
      return translate.phrases.templates('-')
    }

    return unitConverter.almondBloom(dataPoint?.value).valueWithSuffix() // TODO: add cultivar name
  },
  values,
})

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.almondBloom().categoryTitleWithoutUnit(),
    titleChildren: (
      <>
        <DropdownSelectorProperty valuesTimeseriesToFilterOn={Object.keys(valuesRequested)} />
        <div>
          <AlmondBloomSettings />
        </div>
      </>
    ),
    id: 'summary-grid-almond-bloom',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { property } = selectedFieldAssetsStore.getState()
  const blocks = fieldAssetStore.getState()?.properties?.[Number(property)]?.blocks ?? {}

  const relevantBlocksForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(blocks),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  const items = relevantBlocksForProperty
    .map((block) => {
      const values = timestampToValuesDictionaryMaker(
        data?.blocks?.[block.blockId]?.values?.almondBloom?.[0]?.timeseries ?? [],
      )

      const label = block.name || translate.phrases.banyanApp('Unnamed Block')

      return generateItem({ block: block.blockId.toString(), label, values })
    })
    .filter(Boolean)
    .sort(sortByKey('label'))

  return {
    ...commonReturnItems,
    items,
  }
}
