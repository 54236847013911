import { TChartSeries, TTimeseriesData, TValueTypeObject } from 'components/StackedChart/types'
import { alphaColorReplacer, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { TUnitConverterFunction } from 'utils/unitConverter/unitConverter'
import { environmentalLineChartSeriesMaker } from './environmentalLineChartSeriesMaker'

export const generateSeriesForEnvironmentalChart = ({
  color,
  compareSeasonsValueTypeObject,
  hasSensor,
  unitConverter,
  valueTypeObject,
}: {
  color: TRGBAColorWith1AtTheEnd
  compareSeasonsValueTypeObject?: TValueTypeObject
  hasSensor: boolean
  unitConverter: TUnitConverterFunction
  valueTypeObject?: TValueTypeObject
}): TChartSeries[] => {
  const returnedSeries: TChartSeries[] = []

  if (!hasSensor) return []

  const commonEnvironmentalLineChartSeriesMakerParams = {
    aggregationInterval: valueTypeObject?.metadata?.aggregationInterval,
    /**
     * note that we should use the same name for regular and compare seasons
     * series, but that we can't rely on the name of a series for debugging,
     * and we should use the series' id when debugging instead
     */
    name: unitConverter().shortTitle(),
    tooltip: {
      valueSuffix: ` ${unitConverter().suffix()}`,
      valueDecimals: unitConverter().defaultNumberOfDecimalPlaces(),
    },
  }

  const commonValuesMapper = (ts: TTimeseriesData) => ({
    x: ts.timestamp,
    y: unitConverter(typeof ts.value === 'object' ? ts.value?.average : ts.value).value(),
    low: unitConverter(typeof ts.value === 'object' ? ts.value?.min : null).value(),
    high: unitConverter(typeof ts.value === 'object' ? ts.value?.max : null).value(),
    isDeviceReported: ts.isDeviceReported,
  })

  const sensorSeries = environmentalLineChartSeriesMaker({
    ...commonEnvironmentalLineChartSeriesMakerParams,
    color,
    values: valueTypeObject?.timeseries?.map(commonValuesMapper) ?? [],
  })

  returnedSeries.push(...sensorSeries)

  const includeCompareSeasonsSeries = !!detailsPanelStore.getState().compareSeasonsInterval

  if (includeCompareSeasonsSeries) {
    const compareSeasonsSeries = environmentalLineChartSeriesMaker({
      ...commonEnvironmentalLineChartSeriesMakerParams,
      // IMPORTANT: keep color in sync with makeCompareSeasonsSeriesFromRegularSeries
      color: alphaColorReplacer(color, 0.4),
      values: compareSeasonsValueTypeObject?.timeseries?.map(commonValuesMapper) ?? [],
    }).map((series, i) => ({
      ...series,
      // IMPORTANT: keep these in sync with makeCompareSeasonsSeriesFromRegularSeries
      id: `${sensorSeries[i].id}_compareSeasons`,
      fillOpacity: 0.3,
      hideFromTooltip: true,
      linkedTo: sensorSeries[0].id,
      showInLegend: false,
      zones: [],
    }))

    returnedSeries.push(...compareSeasonsSeries)
  }

  return returnedSeries
}
