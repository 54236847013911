import { getRSSILevel, SIGNAL_STRENGTH_COLORS } from '../../utils/getRSSILevel'
import { Tooltip, useMantineTheme } from '@mantine/core'
import React from 'react'
import { translate } from 'i18n/i18n'

interface SignalStrengthProps {
  signal: number
}

export const SignalStrength: React.FC<SignalStrengthProps> = ({ signal }) => {
  const theme = useMantineTheme()
  const signalStrength = signal ? getRSSILevel(signal) : 1
  const barColor = SIGNAL_STRENGTH_COLORS[signalStrength]

  return (
    <Tooltip label={translate.phrases.placeholder('Signal: {{signal}} dBm', { signal: signal.toString() })}>
      <div
        className="SignalStrength"
        css={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '1em',
          position: 'relative',
          top: '2px',
        }}
      >
        {[1, 2, 3, 4, 5].map((i) => (
          <div
            key={i}
            css={{
              width: 5,
              height: 15,
              borderRadius: 2,
              marginRight: 5,
              backgroundColor: i <= signalStrength ? barColor : theme.colors.grey[2],
            }}
          />
        ))}
      </div>
    </Tooltip>
  )
}
