import { TIrrigationSchedulerStoreState } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { TFieldAssetStoreState } from 'stores/fieldAssetStore'
import { TUserDetailsStoreState } from 'stores/userDetailsStore'

export const getPermissionForSelectedProperty = (
  fass: TFieldAssetStoreState,
  isss: TIrrigationSchedulerStoreState,
  udss: TUserDetailsStoreState,
) => {
  const properties = fass.properties
  const selectedPropertyId = isss.selectedPropertyId
  const permissions = udss.permissions

  if (!properties || !selectedPropertyId || !properties[Number(selectedPropertyId)]) {
    return { view: false, edit: false, publish: false }
  }

  const hasPermission = (permission: string, propertyId: number) => {
    return permissions[permission] && permissions[permission][propertyId] === true
  }

  return {
    view: hasPermission('VIEW_IRRIGATION_PLANNER', selectedPropertyId),
    edit: hasPermission('EDIT_IRRIGATION_PLANNER', selectedPropertyId),
    publish: hasPermission(properties[Number(selectedPropertyId)].publishPermission, selectedPropertyId),
  }
}
