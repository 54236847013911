import { translate } from 'i18n/i18n'
import type { TChartSeries } from 'components/StackedChart/types'
import type { XAxisPlotLinesOptions } from 'highcharts'
import type { TXAxisPlotLinesOptionsWithTooltipDisplay } from 'components/StackedChart/_utils/lineChartTooltipFormatter/buildTooltipPlotLinesIfAny'
import moment from 'moment-timezone'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { getScreenSize } from 'utils/getScreenSize'
import { colors } from 'settings/colors'

type TSimpleSprayRecord = {
  applicationStartTime: string
  blockIds: TFieldAssetKeyTypes.TBlockId[]
}

export const generateSpraysPlotLines = ({
  sprays,
  dateFrom,
  dateTo,
  timezone,
  intervalForCompareSeasonsOffset,
}: {
  sprays: {
    baseSeason: TSimpleSprayRecord[]
    comparisonSeason: TSimpleSprayRecord[]
  }
  dateFrom: string
  dateTo: string
  timezone: string
  intervalForCompareSeasonsOffset: number | null
}): {
  sprayPlotLines?: (TXAxisPlotLinesOptionsWithTooltipDisplay | XAxisPlotLinesOptions)[]
  spraySeriesForAddingToggle?: TChartSeries
} => {
  const dateFromMoment = moment.tz(dateFrom, timezone)
  const dateToMoment = moment.tz(dateTo, timezone)

  const someBaseSeasonSpraysInView = sprays?.baseSeason?.some((spray) => {
    const sprayStartMoment = moment.tz(spray.applicationStartTime, timezone).startOf('day')

    if (dateFromMoment <= sprayStartMoment && dateToMoment >= sprayStartMoment) return true

    return false
  })

  const someCompareSeasonSpraysInView = sprays?.comparisonSeason?.some((spray) => {
    const sprayStartMoment = moment
      .tz(spray.applicationStartTime, timezone)
      .add(intervalForCompareSeasonsOffset, 'years')
      .startOf('day')

    if (dateFromMoment <= sprayStartMoment && dateToMoment >= sprayStartMoment) return true

    return false
  })

  if (!someBaseSeasonSpraysInView && !someCompareSeasonSpraysInView) return {}

  const property =
    fieldAssetStore.getState().properties?.[Number(selectedFieldAssetsStore.getState().property)]

  const blocks = property?.blocks
  const selectedBlock = selectedFieldAssetsStore.getState().block

  if (!blocks) return {}

  const makeTooltipContent = (year: string, spray: TSimpleSprayRecord) => {
    const blockNamesArray = spray.blockIds
      .map((bid) => (blocks[bid] ? blocks[bid].name : null))
      .filter(Boolean) as string[]

    const averageTextLength = Math.floor(
      blockNamesArray.reduce((acc, curr) => acc + curr.length, 0) / blockNamesArray.length,
    )

    let blockNames = ''

    if (blockNamesArray.length === 1) {
      blockNames = blocks[Number(selectedBlock)].name ?? ''
    } else if (!getScreenSize().isWideScreen) {
      blockNames = translate.phrases.banyanApp('{{numberOfBlocks}} Blocks', {
        numberOfBlocks: String(blockNamesArray.length),
      })
    } else if (averageTextLength > 7) {
      blockNames = `${blockNamesArray[0]}, ${translate.phrases.banyanApp('{{numberOfBlocks}} Blocks', {
        numberOfBlocks: String(blockNamesArray.length - 1),
      })}`
    } else if (blockNamesArray.length > 3) {
      blockNames = `${blockNamesArray[0]}, ${blockNamesArray[1]}, ${translate.phrases.banyanApp(
        '{{numberOfBlocks}} Blocks',
        {
          numberOfBlocks: String(blockNamesArray.length - 2),
        },
      )}`
    } else {
      blockNames = blockNamesArray.join(', ')
    }

    let title = translate.phrases.banyanApp('Spray')

    if (intervalForCompareSeasonsOffset) {
      title = translate.phrases.banyanApp('Spray {{year}}', {
        year,
      })
    }

    return `<div style="display: flex; justify-content: space-between;"><span style="margin-right: 0.5rem">${title}</span><span>${blockNames}</span></div>`
  }

  const generatePlotLines = (): {
    plotLines: (TXAxisPlotLinesOptionsWithTooltipDisplay | XAxisPlotLinesOptions)[]
  } => {
    const plotLines: (TXAxisPlotLinesOptionsWithTooltipDisplay | XAxisPlotLinesOptions)[] = []

    sprays.baseSeason.forEach((spray) => {
      const startTime = +moment.tz(spray.applicationStartTime, timezone).startOf('day')

      const startingPlotLine: XAxisPlotLinesOptions = {
        color: colors.sprayRecordGreen,
        value: startTime,
        width: 2,
        id: `SPRAY_APPLICATION_PLOTLINE_${startTime}`, // TODO: make unique id
        dashStyle: 'ShortDot',
      }

      plotLines.push({
        ...startingPlotLine,
        tooltipDisplay: (ctx) => {
          const year = moment.tz(ctx.x, timezone).year()

          return makeTooltipContent(`${year}`, spray)
        },
      })
    })

    sprays.comparisonSeason.forEach((spray) => {
      const startTime = +moment.tz(spray.applicationStartTime, timezone).startOf('day')

      const startingPlotLine: XAxisPlotLinesOptions = {
        color: colors.sprayRecordGreen,
        value: startTime,
        width: 2,
        id: `SPRAY_APPLICATION_PLOTLINE_${startTime}_${intervalForCompareSeasonsOffset}`,
        dashStyle: 'ShortDot',
      }

      plotLines.push({
        ...startingPlotLine,
        tooltipDisplay: (ctx) => {
          const year = moment.tz(ctx.x, timezone).subtract(intervalForCompareSeasonsOffset, 'years').year()

          return makeTooltipContent(`${year}`, spray)
        },
      })
    })

    return { plotLines }
  }

  const series: TChartSeries = {
    id: 'sprays',
    color: colors.sprayRecordGreen,
    dashStyle: 'ShortDot',
    data: [],
    name: translate.phrases.banyanApp('Spray Applications'),
    type: 'line',
    hideFromTooltip: true,
    events: {
      legendItemClick: function (event) {
        const visible = !event.target.visible

        if (!visible) {
          try {
            // @ts-ignore
            this.chart.xAxis?.[0]?.plotLinesAndBands.forEach((pl) => {
              if (pl.id.includes('SPRAY_APPLICATION_PLOTLINE')) {
                this.chart.xAxis[0].removePlotLine(pl.id)
              }
            })
          } catch (err) {}
        } else {
          const { plotLines } = generatePlotLines()

          plotLines.forEach((pl) => {
            this.chart.xAxis[0].addPlotLine(pl)
          })
        }
      },
    },
  }

  const { plotLines } = generatePlotLines()

  return {
    sprayPlotLines: plotLines,
    spraySeriesForAddingToggle: series,
  }
}
