import { apiFetch } from 'utils/apiFetch'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { parse as parseWKT } from 'wellknown'
import { MetaWithNodeIdentifier } from './queue'

export type PlannedNodeInstallMeta = routes.ServiceCenterPlannedNodeInstall.Request & MetaWithNodeIdentifier

export type PlannedNodeInstallResult = SharedTypes.TActiveNode

const request = async (meta: PlannedNodeInstallMeta): Promise<PlannedNodeInstallResult> => {
  const installedNode = await apiFetch<
    routes.ServiceCenterPlannedNodeInstall.Request,
    routes.ServiceCenterPlannedNodeInstall.Response
  >({
    url: routes.ServiceCenterPlannedNodeInstall.path,
    body: meta,
    params: {
      method: 'POST',
    },
  })

  return installedNode
}

const callback = async (activeNode: SharedTypes.TActiveNode, meta: PlannedNodeInstallMeta) => {
  const shapedActiveNode = {
    ...activeNode,
    // Transform string WKT to serialized GeoJSON
    location: JSON.stringify(parseWKT(activeNode.location as string)),
  }

  serviceCenterStore.actions.addActiveNode(shapedActiveNode)

  // Remove installed planned node from list if it's still there
  serviceCenterStore.actions.removePlannedNodes([meta.pseudonodeId.toString()])
}

export const serviceCenterPlannedNodeInstall = {
  request,
  callback,
}
