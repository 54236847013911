import { useForm, zodResolver } from '@mantine/form'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { TextInput, Flex, LoadingOverlay } from '@mantine/core'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { AlertContactMethod, Contact, userDetailsStore } from 'stores/userDetailsStore'
import { getMultiSelectOptions } from '../_utils/getMultiSelectOptions'
import { fetchApiCustomer } from 'utils/fetchApiCustomer'
import { showNotification } from 'utils/showNotification'
import { contactCreateQuery, contactUpdateQuery } from '../_utils/queries'
import { useScreenSize } from 'utils/useScreenSize'
import { InfoMessageBox } from 'components/InfoMessageBox/InfoMessageBox'
import { isEqual } from 'lodash'
import { contactValidationSchema } from '../_utils/validationSchemas'
import { ContactAndGroupSubmitButton } from '../components/ContactAndGroupSubmitButton'
import {
  ContactFormType,
  CreateContactResponse,
  EditContactResponse,
  useStyles,
} from '../_utils/formTypesAndStyles'
import { NotificationType } from '../_utils/useRelativeNotification'
import { translate } from 'i18n/i18n'
import { sortByKey } from '@semios/app-platform-common'
import { loadAppStartupApiCustomer } from 'utils/loadAppStartupApiCustomer'

export const ModalDrawerCreateOrEditContact = ({
  opened,
  onClose,
  isEdit,
  selectedContact,
  setSelectedContact,
  showRelativeNotification,
}: {
  opened: boolean
  onClose: () => void
  isEdit: boolean
  selectedContact: Contact | null
  setSelectedContact: Dispatch<SetStateAction<Contact | null>>
  showRelativeNotification: ({ type, message }: { type: NotificationType; message: string }) => void
}) => {
  const { classes } = useStyles()
  const { isWideScreen } = useScreenSize()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const notificationFunctionToUse = isWideScreen ? showRelativeNotification : showNotification

  const { language, allGroups, allContacts } = userDetailsStore.useSelector((s) => ({
    language: s.language,
    allGroups: s.groups,
    allContacts: s.contacts,
  }))

  const groupOptions = getMultiSelectOptions(
    allGroups.map((group) => ({
      id: group.id,
      name: group.name,
    })),
  )

  const getDefaultFormState = () => ({
    name: selectedContact?.name || '',
    email: selectedContact?.email || '',
    sms: selectedContact?.sms || '',
    groups: selectedContact?.groups
      ? getMultiSelectOptions(
          selectedContact.groups.map((group) => ({
            id: group.id,
            name: group.name,
          })),
        )
      : [],
  })

  const form = useForm<ContactFormType>({
    initialValues: {
      name: '',
      email: '',
      sms: '',
      groups: [],
    },

    validate: zodResolver(contactValidationSchema(allContacts, selectedContact?.id)),
    validateInputOnBlur: true,
  })

  useEffect(() => {
    form.setValues(getDefaultFormState())
  }, [selectedContact, opened])

  const handleOnClose = () => {
    onClose()

    form.reset()

    setSelectedContact(null)
  }

  const handleCreateContact = async () => {
    const values = form.values
    const { name, email, sms, groups } = values
    const defaultContactMethod: AlertContactMethod[] = []

    if (email) defaultContactMethod.push('email')

    if (sms) defaultContactMethod.push('sms')

    setIsSubmitting(true)

    try {
      const response: CreateContactResponse = await fetchApiCustomer({
        body: {
          query: contactCreateQuery,
          variables: {
            name,
            email,
            sms,
            language,
            alertsContactMethods: defaultContactMethod,
            scoutContactMethods: defaultContactMethod,
            reportsContactMethods: defaultContactMethod,
            irrigationPlannerContactMethods: defaultContactMethod,
            groupIds: groups.map((group) => group.value),
          },
        },
      })

      const createContactResponse = response?.data?.createContact
      const contactId = createContactResponse?.id

      if (contactId) {
        notificationFunctionToUse({
          type: 'success',
          message: translate.phrases.banyanApp('Contact successfully created'),
        })

        await loadAppStartupApiCustomer()

        handleOnClose()
      } else {
        notificationFunctionToUse({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to create a new contact'),
        })
      }
    } catch (error) {
      notificationFunctionToUse({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to create a new contact'),
      })
    }

    setIsSubmitting(false)
  }

  const handleEditContact = async () => {
    const values = form.values
    const { name, email, sms, groups } = values
    const defaultContactMethod: AlertContactMethod[] = []

    if (email) defaultContactMethod.push('email')

    if (sms) defaultContactMethod.push('sms')

    setIsSubmitting(true)

    try {
      const response: EditContactResponse = await fetchApiCustomer({
        body: {
          query: contactUpdateQuery,
          variables: {
            id: selectedContact?.id,
            name,
            email,
            sms,
            groupIds: groups.map((group) => group.value),
            language,
            alertsContactMethods: defaultContactMethod,
            scoutContactMethods: defaultContactMethod,
            reportsContactMethods: defaultContactMethod,
            irrigationPlannerContactMethods: defaultContactMethod,
          },
        },
      })

      const editedContactResponse = response?.data?.updateContact
      const contactId = editedContactResponse?.id

      if (contactId) {
        notificationFunctionToUse({
          type: 'success',
          message: translate.phrases.banyanApp('Contact successfully updated'),
        })

        await loadAppStartupApiCustomer()

        handleOnClose()
      } else {
        notificationFunctionToUse({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to update contact'),
        })
      }
    } catch (error) {
      notificationFunctionToUse({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to update contact'),
      })
    }

    setIsSubmitting(false)
  }

  const horizontalGap = isWideScreen ? 50 : 10
  const isFormDirty = !isEqual(form.values, getDefaultFormState())

  return (
    <ModalDrawer
      title={
        isEdit
          ? translate.phrases.banyanApp('Contact Details')
          : translate.phrases.banyanApp('Create Contact')
      }
      opened={opened}
      onClose={handleOnClose}
      zIndex={500}
      size="lg"
    >
      <LoadingOverlay visible={isSubmitting} />

      <div css={{ paddingBottom: 90 }}>
        {!isEdit && (
          <div
            css={{
              margin: `15px ${horizontalGap}px`,
            }}
          >
            <InfoMessageBox>
              {translate.phrases.banyanApp('At least one contact method is required')}
            </InfoMessageBox>
          </div>
        )}
        <form onSubmit={form.onSubmit(() => (isEdit ? handleEditContact() : handleCreateContact()))}>
          <div css={{ padding: `15px ${horizontalGap}px` }}>
            <TextInput
              label={translate.phrases.banyanApp('Full Name')}
              {...form.getInputProps('name')}
              classNames={{
                input: form.errors.name ? classes.invalid : undefined,
              }}
              name="searchHack" // this is a hack to make an icon stop appearing on safari
              autoComplete="off"
            />
            <TextInput
              mt="lg"
              label={translate.phrases.banyanApp('Email')}
              {...form.getInputProps('email')}
              classNames={{
                input: form.errors.email || form.errors.noContactMethod ? classes.invalid : undefined,
              }}
              onBlur={(event) => {
                if (event.target.value !== '') form.clearFieldError('noContactMethod')
              }}
              name="searchHack" // this is a hack to make an icon stop appearing on safari
              autoComplete="off"
            />
            {/* TODO: mantine 7 has <NumberInput type="tel" /> */}
            <TextInput
              mt="lg"
              label={translate.phrases.banyanApp('Phone Number')}
              {...form.getInputProps('sms')}
              classNames={{
                input: form.errors.sms || form.errors.noContactMethod ? classes.invalid : undefined,
              }}
              onBlur={(event) => {
                if (event.target.value !== '') form.clearFieldError('noContactMethod')
              }}
              name="searchHack" // this is a hack to make an icon stop appearing on safari
              autoComplete="off"
            />
            <div>
              <Flex mt="lg" mb="3px" direction="row" css={{ fontSize: 16, fontWeight: 500 }}>
                <div>{translate.phrases.banyanApp('Add Group(s)')}</div>
                <div css={{ marginLeft: 5, fontWeight: 400, fontStyle: 'italic' }}>
                  {translate.phrases.banyanApp('(optional)')}
                </div>
              </Flex>
              <MultiSelect
                data={groupOptions}
                selectedData={form.getInputProps('groups').value.sort(sortByKey('label'))}
                placeholder=""
                {...form.getInputProps('groups')}
              />
            </div>
          </div>
          <ContactAndGroupSubmitButton
            label={
              isEdit
                ? translate.phrases.banyanApp('Update Contact')
                : translate.phrases.banyanApp('Create Contact')
            }
            disabled={isEdit && !isFormDirty}
          />
        </form>
      </div>
    </ModalDrawer>
  )
}
