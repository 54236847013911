import { populateMapFromUserAppStartup } from 'App/Map/_utils/populateMapFromUserAppStartup'
import { SelectedMapCard } from 'App/Map/CurrentValuesMap/components/SelectedMapCard/SelectedMapCard'
import { MapBase } from 'App/Map/MapContext/MapBase'
import { useCallback } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { BlockScdsValuesCache } from './caches/BlockScdsValuesCache/BlockScdsValuesCache'
import { BlocksDefaultPolygonsCache } from './caches/BlocksDefaultPolygonsCache/BlocksDefaultPolygonsCache'
import { BlockValuesCache } from './caches/BlockValuesCache/BlockValuesCache'
import { PropertyValuesCache } from './caches/PropertyValuesCache/PropertyValuesCache'
import { StationValuesCache } from './caches/StationValuesCache/StationValuesCache'
import { TrapValuesCache } from './caches/TrapValuesCache/TrapValuesCache'
import { updateMapWithAnyNeededCurrentValues } from './_utils/updateMapWithAnyNeededCurrentValues'

export const CurrentValuesMap = (props: {
  hasAppStartupData: boolean // TODO: Move...
}) => {
  const onInit = useCallback((map: google.maps.Map, maps: typeof google.maps) => {
    const blocksDefaultPolygonsCache = new BlocksDefaultPolygonsCache({ maps, map })
    const blockScdsValuesCache = new BlockScdsValuesCache({ maps, map })
    const blockValuesCache = new BlockValuesCache({ maps, map })
    const propertyValuesCache = new PropertyValuesCache({ maps, map })
    const stationValuesCache = new StationValuesCache({ maps, map })
    const trapValuesCache = new TrapValuesCache({ maps, map })
    const { properties } = fieldAssetStore.getState()

    // TODO: proper try catching... but really this is all going to be moved soon anyways.
    populateMapFromUserAppStartup({
      map,
      maps,
      blocksDefaultPolygonsCache,
      blockScdsValuesCache,
      blockValuesCache,
      properties,
      propertyValuesCache,
      stationValuesCache,
      trapValuesCache,
    })

    const refreshMap = async () => {
      if (mapControlsStore.getState().loadingCurrentValues) return

      mapControlsStore.setState((s) => ({ ...s, loadingCurrentValues: true }))

      await updateMapWithAnyNeededCurrentValues({
        blocksDefaultPolygonsCache,
        blockScdsValuesCache,
        blockValuesCache,
        propertyValuesCache,
        stationValuesCache,
        trapValuesCache,
      }).finally(() => {
        mapControlsStore.setState((s) => ({ ...s, loadingCurrentValues: false }))
      })
    }

    const mapIdleEventListener = map.addListener('idle', () => {
      refreshMap()
    })

    const selectedValueGroupsListener = selectedValueGroupsStore.subscribeToChanges(
      (s) => [s.primaryValueGroup, s.selectedValueGroups],
      () => {
        refreshMap()
      },
    )

    const smallListener = smallStore.subscribeToChanges(
      (s) => [s.appliedIrrigationActivityDisplay, s.showDataSource],
      () => {
        refreshMap()
      },
    )

    const mapControlsListener = mapControlsStore.subscribeToChanges(
      ({ heatmapExtremes, loadingCurrentValues, showMapBadgeNames, ...rest }) => rest,
      () => {
        refreshMap()
      },
    )

    const userDetailsStoreListener = userDetailsStore.subscribeToChanges(
      (s) => [s.depth, s.rain, s.speed, s.temperature],
      () => {
        refreshMap()
      },
    )

    const selectedBlockListener = selectedFieldAssetsStore.subscribeToChanges(
      (s) => s.block,
      (selectedBlock, oldSelectedBlock) => {
        const selectedBlockPolygon = selectedBlock
          ? blocksDefaultPolygonsCache.items[selectedBlock]?.overlay
          : undefined

        const unSelectedBlockPolygon = oldSelectedBlock
          ? blocksDefaultPolygonsCache.items[oldSelectedBlock]?.overlay
          : undefined

        selectedBlockPolygon?.set('strokeColor', 'yellow')

        selectedBlockPolygon?.set('strokeWeight', 4)

        unSelectedBlockPolygon?.set('strokeColor', 'white')

        unSelectedBlockPolygon?.set('strokeWeight', 2)

        const selectedPolygon = selectedBlock
          ? blockValuesCache.items[selectedBlock].overlay?.polygon
          : undefined

        const unSelectedPolygon = oldSelectedBlock
          ? blockValuesCache.items[oldSelectedBlock].overlay?.polygon
          : undefined

        selectedPolygon?.set('strokeColor', 'yellow')

        selectedPolygon?.set('strokeWeight', 4)

        unSelectedPolygon?.set('strokeColor', 'white')

        unSelectedPolygon?.set('strokeWeight', 2)
      },
    )

    return () => {
      mapIdleEventListener.remove()

      selectedBlockListener() // unsubscribes

      selectedValueGroupsListener() // unsubscribes

      smallListener() // unsubscribes

      userDetailsStoreListener() // unsubscribes

      mapControlsListener() // unsubscribes
    }
  }, [])

  return (
    <MapBase
      enableCurrentLocation={false}
      hasAppStartupData={props.hasAppStartupData}
      loadingData={false}
      onInit={onInit}
    >
      <SelectedMapCard />
    </MapBase>
  )
}
