import { Input } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'

export const AlmondHullSplitHeatmapRow = () => {
  const heatmapColoring = mapControlsStore.useSelector((s) => s.heatmapColoring)
  const heatmapExtremes = mapControlsStore.useSelector((s) => s.heatmapExtremes)

  if (!heatmapColoring.length) return null

  return (
    <div
      css={{
        width: '100%',
        background: 'transparent',
        borderRadius: 3,
        display: 'flex',
      }}
    >
      <div>
        <Input
          value={unitConverter.almondHullSplit(heatmapExtremes.min).valueAsString()}
          css={{ maxWidth: 44 }}
          styles={{ input: { padding: 0, textAlign: 'center' } }}
          readOnly
        />
      </div>
      <div
        css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            marginTop: 4,
            width: '100%',
            display: 'flex',
            background: colors.grey50,
            padding: '8px 6px',
          }}
        >
          {heatmapColoring.map((color, i) => (
            <div css={{ flex: 1, background: color, height: 12 }} key={i} />
          ))}
        </div>
        <div css={{ color: colors.white, fontSize: 12, paddingTop: 5 }}>
          {translate.phrases.templates('{{labelA}} ({{labelB}})', {
            labelA: translate.phrases.banyanApp('Almond Hull Split'),
            labelB: translate.dates.getMomentFormat('M/DD'),
          })}
        </div>
      </div>
      <div>
        <Input
          value={unitConverter.almondHullSplit(heatmapExtremes.max).valueAsString()}
          css={{ maxWidth: 44 }}
          styles={{ input: { padding: 0, textAlign: 'center' } }}
          readOnly
        />
      </div>
    </div>
  )
}
