import { ActionIcon, ActionIconProps } from '@mantine/core'
import { PolymorphicComponentProps } from '@mantine/utils'
import { FC } from 'react'
import { colors } from 'settings/colors'
import { StatusType } from './CurrentLocation'

interface ControlButtonProps extends ActionIconProps {
  status: StatusType
}

const CurrentLocationIcon = (props: { status: StatusType }) => {
  let arrowFill = colors.grey50
  let arrowStroke = colors.grey50

  switch (props.status) {
    case 'inactive':
      arrowFill = colors.grey50

      arrowStroke = colors.grey200

      break

    case 'watching':
      arrowFill = colors.grey50

      arrowStroke = colors.primary

      break

    case 'following':
      arrowFill = colors.primary

      arrowStroke = colors.primary

      break

    default:
  }

  return (
    <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3504_56241)">
        <circle cx="27.5" cy="27.5" r="27" fill={colors.grey50} />
        <circle cx="27.5" cy="27.5" r="27" stroke={colors.grey500} />
      </g>
      <path
        d="M37.6971 18.1407L16.5814 26.975L16.5814 28.1377L24.597 31.2407L27.7001 39.2564L28.8627 39.2564L37.6971 18.1407Z"
        fill={arrowFill}
        stroke={arrowStroke}
        strokeWidth="2"
      />
      <defs>
        <filter
          id="filter0_d_3504_56241"
          x="0"
          y="0"
          width="55"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3504_56241" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3504_56241" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export const ControlButton: FC<PolymorphicComponentProps<'button', ControlButtonProps>> = (props) => (
  <ActionIcon size="xl" radius="xl" variant="outline" {...props}>
    <CurrentLocationIcon status={props.status} />
  </ActionIcon>
)
