import React from 'react'
import { closeModal, openModal } from '@mantine/modals'
import { Text } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { Button } from 'components/Button/Button'
import { IconWarning } from 'components/icons/IconWarning'
import { colors } from 'settings/colors'

const MODAL_ID = 'error'

export function showError(title: string, message: string) {
  // TODO: Display error modal in a way that does not destroy current modal
  openModal({
    modalId: MODAL_ID,
    title: (
      <>
        <div css={{ marginRight: 10, color: colors.red }}>
          <IconWarning />
        </div>{' '}
        {title}
      </>
    ),
    centered: true,
    children: (
      <>
        <Text size="sm">{message}</Text>
        <Button onClick={() => closeModal(MODAL_ID)} mt="md" css={{ display: 'block', width: '100%' }}>
          {translate.phrases.placeholder('OK')}
        </Button>
      </>
    ),
    styles: { title: { display: 'flex', alignItems: 'flex-start' } },
  })
}
