import { translate } from 'i18n/i18n'
import { Contact, Group } from 'stores/userDetailsStore'
import { z } from 'zod'

const phoneRegex = new RegExp(/^\+\d{11,}$/)

export const contactValidationSchema = (allContacts: Contact[], selectedContactId?: string) =>
  z
    .object({
      name: z.string().min(1, { message: translate.phrases.banyanApp('Name is required') }),
      email: z
        .string()
        .email({ message: translate.phrases.banyanApp('Invalid email') })
        .optional()
        .or(z.literal('')),
      sms: z
        .string()
        .regex(
          phoneRegex,
          translate.phrases.banyanApp('Phone number must be in international format (e.g. +18885551234)'),
        )
        .optional()
        .or(z.literal('')),
      groups: z.array(z.object({ label: z.string(), value: z.string() })),
    })
    .partial()
    .refine((data) => data.email || data.sms, {
      message: translate.phrases.banyanApp('At least one contact method is required'),
      path: ['noContactMethod'],
    })
    .refine(
      (data) =>
        !allContacts.some(
          (contact) =>
            contact?.name?.trim().toLowerCase() === data?.name?.trim().toLowerCase() &&
            contact?.id !== selectedContactId,
        ),
      {
        message: translate.phrases.banyanApp('This contact name already exists'),
        path: ['name'],
      },
    )

export const groupValidationSchema = (allGroups: Group[], selectedGroupId?: string) =>
  z
    .object({
      name: z.string().min(1, { message: translate.phrases.banyanApp('Name is required') }),
      contacts: z
        .array(z.object({ label: z.string(), value: z.string() }))
        .min(1, { message: translate.phrases.banyanApp('Please select group members') }),
    })
    .refine(
      (data) =>
        !allGroups.some(
          (group) =>
            group?.name?.trim().toLowerCase() === data?.name?.trim().toLowerCase() &&
            group?.id !== selectedGroupId,
        ),
      {
        message: translate.phrases.banyanApp('This group name already exists'),
        path: ['name'],
      },
    )
