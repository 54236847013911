import { ReactNode } from 'react'
import { colors } from 'settings/colors'
import { ICON_SIZE } from './ContactList'

type ContactRowProps = {
  leftIcon: ReactNode
  name: string
  email?: ReactNode
  isError: boolean
}

export const ContactRow = ({ leftIcon, name, email, isError }: ContactRowProps) => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        borderBottom: `1px solid ${colors.grey200}`,
        backgroundColor: isError ? 'rgba(255, 109, 109, 0.1)' : colors.grey50,
        padding: 10,
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          alignItems: 'center',
        }}
      >
        <div css={{ fontSize: ICON_SIZE, alignItems: 'center', display: 'flex' }}>{leftIcon}</div>
        <div css={{ color: colors.midnight, fontSize: 14, fontWeight: 700 }}>{name}</div>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 80,
          fontSize: ICON_SIZE,
        }}
      >
        {email ? email : null}
      </div>
    </div>
  )
}
