import { Options } from 'highcharts'
import { translate } from 'i18n/i18n'

// Default options for all chart types
export const baseChartOptions = (timezone: string): Options => {
  return {
    accessibility: { enabled: false }, // Enable this if we bring in highcharts/modules/accessibility
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    chart: {
      events: {
        redraw: function () {
          const maxPoints = Math.max(...(this.xAxis?.[0]?.series?.map((s) => s?.data?.length) ?? 0))

          if (maxPoints < 7) {
            this.update(
              {
                chart: {
                  zooming: {
                    type: undefined,
                  },
                },
              },
              false,
            )
          } else {
            this.update(
              {
                chart: {
                  zooming: {
                    type: 'x',
                  },
                },
              },
              false,
            )
          }
        },
      },
      animation: false,
      height: 300,
      // Keep all charts left aligned
      marginLeft: 50,
      marginRight: 10,
      marginTop: 25,
      spacingBottom: 15,
      spacingTop: 15,
      style: {
        fontFamily: 'Roboto, "Helvetica Neue", "Helvetica", "Arial", sans-serif',
        fontSize: '14px',
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      // This just hides highchart's built in export hamburger button. The export module is still available via the chart instance.
      chartOptions: {
        legend: {
          enabled: true,
        },
        navigator: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
      },
      enabled: false,
      fallbackToExportServer: false,
    },
    lang: {
      noData: translate.phrases.banyanApp('No data found.'),
    },
    legend: {
      enabled: true,
    },
    noData: {
      useHTML: true,
    },
    title: { text: '' },
    time: { timezone },
    tooltip: {
      borderRadius: 8,
      borderWidth: 0,
      hideDelay: 0,
      padding: 12,
      split: false,
      shared: true,
      useHTML: true,
      shadow: true,
      style: {
        fontSize: '14px',
        fontFamily: 'Roboto, "Helvetica Neue", "Helvetica", "Arial", sans-serif',
        display: 'block',
        opacity: 0.98,
      },
      dateTimeLabelFormats: {
        minute: '%Z',
        hour: '%Z',
      },
      backgroundColor: 'rgba(255, 255, 255, 1)',
      headerFormat: '<strong>{point.key}</strong><table style="margin-top: 5px; width: 100%;">',
    },
    xAxis: {
      crosshair: {
        // we want it above most of the chart features but under the tooltip
        snap: true,
        zIndex: 3,
      },
      ordinal: false,
    },
    yAxis: {
      opposite: false,
      showLastLabel: true,
      labels: {
        enabled: true,
        reserveSpace: true,
      },
    },
  }
}
