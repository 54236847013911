import { FC } from 'react'
import { colors } from 'settings/colors'

export const SuccessIcon: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill={colors.green} />
      <path
        d="M7.81818 13.1194L4.95455 10.2985L4 11.2388L7.81818 15L16 6.9403L15.0455 6L7.81818 13.1194Z"
        fill="white"
      />
    </svg>
  )
}
