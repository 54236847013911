import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextInput, useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { FC, useCallback } from 'react'
import { SearchTextInputProps } from '../../App/Map/UserSettingsMenu/types'
import { blurOnEnterKeyUp } from 'utils/blurOnEnterKeyUp'

export const SearchTextInput: FC<SearchTextInputProps> = ({ searchText, setSearchText, placeholder }) => {
  const theme = useMantineTheme()

  const searchInputCallbackRef = useCallback((searchInput: HTMLInputElement) => {
    // TODO: This is a workaround to prevent input focus on load, but would be nice to prevent autofocus in a simpler way
    if (searchInput) setTimeout(() => searchInput.blur(), 50)
  }, [])

  return (
    <TextInput
      css={{
        '.mantine-TextInput-input:focus-within': {
          borderColor: theme.colors.grey[1],
        },
      }}
      placeholder={placeholder ? placeholder : translate.phrases.banyanApp('Search')}
      rightSection={<FontAwesomeIcon css={{ fontSize: 16, color: theme.colors.grey[3] }} icon={faSearch} />}
      defaultValue={searchText}
      onChange={(event) => setSearchText(event.target.value)}
      onKeyUp={blurOnEnterKeyUp}
      ref={searchInputCallbackRef}
      spellCheck="false"
    />
  )
}
