import type {
  TAvailablePreset,
  TFieldAssetKeyTypes,
  TFieldAssetType,
  TFieldAssetValueTypes,
  TPresetId,
  TValuesCurrent,
  TValuesTimeseries,
} from '@semios/app-platform-banyan-route-definitions/src/routes/userAppStartup'
import { VV, EAggregationInterval } from '@semios/app-platform-value-type-definitions'

export type TFieldAssetWithLngLat = Exclude<TFieldAssetType, 'blocks' | 'irrigationZones'>

export type PortsizeType = {
  height: number
  width: number
}

export type ESprayRecommendation = VV.DomainTypes.SprayConditions.ESprayRecommendation

export enum EWindmachineActivity {
  'OFF' = 'OFF',
  'ON' = 'ON',
  'NO CONNECTION' = 'NO CONNECTION',
}

export enum EWindMachineStatus {
  '-' = '-',
  'OFF' = 'OFF',
  'ON' = 'ON',
  'WARM UP' = 'WARM UP',
  'ERROR' = 'ERROR',
  'WARNING' = 'WARNING',
}

export {
  EAggregationInterval,
  TAvailablePreset,
  TFieldAssetKeyTypes,
  TFieldAssetType,
  TFieldAssetValueTypes,
  TPresetId,
  TValuesCurrent,
  TValuesTimeseries,
}
