import { routes } from '@semios/app-platform-banyan-route-definitions'
import { StackedChartSection } from 'components/StackedChart/types'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { pressureSensorsChart } from './pressureSensors/pressureSensorsChart'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { getPointLngLatsForIrrigationEmitterZoneId } from 'utils/getPointLngLatsForIrrigationEmitterZoneId'
import { getIdAndEmitterTypeFromZoneEmitterTypeKey } from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'
import {
  IRRIGATION_ACTIVITY_VALUE_TYPE_PREFIX,
  IRRIGATION_PRESSURE_VALUE_TYPE_PREFIX,
  getValueTypeFromEmitterType,
} from '@semios/app-platform-common'

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!selectedValueGroups.irrigation_activity || !selectedFieldAssets.irrigationZoneEmitter) return {}

  const intervalToRequest = chooseAmongstUnAggHourlyAndDaily()

  const preferredAggregationInterval = {
    preferredAggregationInterval: intervalToRequest,
  }

  const { emitterType } = getIdAndEmitterTypeFromZoneEmitterTypeKey(selectedFieldAssets.irrigationZoneEmitter)

  const valueTypeForIntervals = getValueTypeFromEmitterType(
    IRRIGATION_ACTIVITY_VALUE_TYPE_PREFIX,
    emitterType,
  )

  const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
    [valueTypeForIntervals]: preferredAggregationInterval,
  }

  if (intervalToRequest !== 'DAILY') {
    const valueTypeForPressure = getValueTypeFromEmitterType(
      IRRIGATION_PRESSURE_VALUE_TYPE_PREFIX,
      emitterType,
    )

    valuesRequested[valueTypeForPressure] = preferredAggregationInterval
  }

  const lngLatsForZone = getPointLngLatsForIrrigationEmitterZoneId({
    propertyId: Number(selectedFieldAssets.property),
    irrigationEmitterZoneId: selectedFieldAssets.irrigationZoneEmitter,
  })

  if (lngLatsForZone.length === 0) return {}

  return {
    points: {
      lngLats: lngLatsForZone,
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  selectedFieldAssets,
}: {
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): StackedChartSection[] => {
  const irrigationActivityCharts: StackedChartSection[] = []
  const pressureSensorChart = pressureSensorsChart({ data, selectedFieldAssets })

  if (pressureSensorChart) irrigationActivityCharts.push(pressureSensorChart)

  return irrigationActivityCharts
}
