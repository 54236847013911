import React, { useEffect } from 'react'
import { Notification, useMantineTheme } from '@mantine/core'
import { IconNetworkOffline } from 'components/icons/IconNetworkOffline'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { App, AppState } from '@capacitor/app'
import { Contents } from './Contents'
import { translate } from 'i18n/i18n'
import { networkStore } from '../store/networkStore'

export const OfflineContentDownloadBanner = () => {
  const theme = useMantineTheme()

  const { lastTimeDownloaded, downloadStatus, isBannerOpened } = serviceCenterStore.useSelector(
    (s) => s.offlineContents,
  )

  const isDownloadedContentsOutdated = () => {
    if (!lastTimeDownloaded) return true

    const lastDownloaded = new Date(lastTimeDownloaded)
    const now = new Date()
    const isOneweekAgo = now.getDate() - lastDownloaded.getDate() > 7

    return isOneweekAgo
  }

  useEffect(() => {
    if (downloadStatus === 'success') {
      setTimeout(() => {
        handleClose()
      }, 3000)
    }
  }, [downloadStatus])

  useEffect(() => {
    const handleAppStateChange = (state: AppState) => {
      // Open banner when opening app
      if (state.isActive && isDownloadedContentsOutdated()) {
        const connectionStatus = networkStore.useSelector(
          networkStore.selectors.getConnectionStatus,
        ).connected

        if (!connectionStatus) return

        serviceCenterStore.actions.setIsOfflineContentBannerOpened(true)
      }
    }

    const listener = App.addListener('appStateChange', handleAppStateChange)

    return () => {
      listener.remove()
    }
  }, [])

  const handleClose = () => {
    serviceCenterStore.actions.setIsOfflineContentBannerOpened(false)

    serviceCenterStore.actions.setOfflineContentsDownloadStatus('default')
  }

  if (!isBannerOpened) return null

  return (
    <Notification
      onClose={handleClose}
      icon={<IconNetworkOffline />}
      title={
        <span style={{ fontSize: '18px', display: 'flex', alignItems: 'center' }}>
          <span style={{ fontSize: '24px', display: 'inline-block', marginRight: '8px' }}>
            <IconNetworkOffline />
          </span>{' '}
          {translate.phrases.placeholder('Offline Functionality')}
        </span>
      }
      css={{
        position: 'absolute',
        paddingTop: 'calc(10px + env(safe-area-inset-top))',
        paddingBottom: 'calc(10px + env(safe-area-inset-bottom))',
        paddingRight: '10px',
        paddingLeft: '10px',
        top: 0,
        zIndex: 10000,
        width: '100%',
        border: 'none',
        borderRadius: 0,
      }}
      styles={{ icon: { backgroundColor: theme.colors.white[0] } }}
    >
      <Contents />
    </Notification>
  )
}
