import { TTooltipFormatterContextObject } from 'components/StackedChart/types'

export const groupTooltipRowsIntoColumns = (points: TTooltipFormatterContextObject[]) => {
  const numberOfRows = points.length

  return points.reduce(
    (
      acc: {
        [key: number]: TTooltipFormatterContextObject[]
      },
      point: TTooltipFormatterContextObject,
      i: number,
    ) => {
      const rowsForTwoColumns = Math.floor((numberOfRows - 1) / 2)
      const rowOfSecondColumn = Math.floor(i - numberOfRows / 2)
      const groupIndex = i > rowsForTwoColumns ? rowOfSecondColumn : i || 0

      if (!acc[groupIndex]) {
        acc[groupIndex] = []
      }

      acc[groupIndex].push(point)

      return acc
    },
    {},
  )
}
