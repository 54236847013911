import React, { useMemo, useState } from 'react'
import { translate } from 'i18n/i18n'
import { Button } from 'components/Button/Button'
import { Footer } from '../components/Footer/Footer'
import { ImageWithPlaceholder } from '../../ImageWithPlaceholder/ImageWithPlaceholder'
import { Camera, CameraResultType, CameraSource, GalleryPhoto, Photo } from '@capacitor/camera'
import { showError } from '../../utils/showError'
import { Group } from '@mantine/core'
import { CapacitorException } from '@capacitor/core'
import * as Sentry from '@sentry/react'
import { installationWorkflowStore } from 'App/ServiceCenter/store/installationWorkflowStore'
import { uploadInstallationImage } from 'App/ServiceCenter/utils/uploadInstallationImage'
import { closeModal } from '@mantine/modals'
import { INSTALLATION_OVERVIEW_MODAL_ID } from '../InstallationOverviewModal'
import { showSuccess } from 'App/ServiceCenter/utils/showSuccess'
import { InstallationStep, TActiveNode } from 'App/ServiceCenter/types'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'

interface ReviewProps {
  onClose: () => void
}

function handlePermissionsError(error: unknown) {
  if (error instanceof CapacitorException) {
    if (error.message.match('denied')) {
      //permission denied
      showError(translate.phrases.placeholder('Permission required'), error.message)

      return
    }

    if (error.message.match('cancelled')) {
      //cancelled
      return
    }
  }

  Sentry.captureException(error)

  throw error
}

const checkSendImagePermission = (propertyId: number) =>
  selectedPropertyHasPermission({ permission: 'CREATE_SSC_NODE_IMAGE', propertyId })

export const Review: React.FC<ReviewProps> = ({ onClose }) => {
  const [image, setImage] = useState<Photo | GalleryPhoto | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const dropLocation = installationWorkflowStore.getState().dropInstallLocation as google.maps.LatLngLiteral

  const selectedNode = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentNode,
  ) as TActiveNode

  const installationSteps = installationWorkflowStore.useSelector(
    installationWorkflowStore.selectors.getSteps,
  )

  const handleTakePhoto = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.DataUrl,
      })

      setImage(image)
    } catch (error) {
      handlePermissionsError(error)
    }
  }

  const handleSelectPhoto = async () => {
    try {
      const { photos } = await Camera.pickImages({ limit: 1 })

      setImage(photos[0])
    } catch (error) {
      handlePermissionsError(error)
    }
  }

  const handleFinishInstallation = async () => {
    setIsSaving(true)

    try {
      if (!image)
        throw new Error(translate.phrases.placeholder('Please upload a photo of the installed equipment.'))

      // Upload image
      const action = dropLocation ? 'EQUIP_INSTALL_DROP' : 'EQUIP_INSTALL_PSEUDO'

      await uploadInstallationImage(selectedNode.nodeIdentifier, image, action)

      closeModal(INSTALLATION_OVERVIEW_MODAL_ID)

      onClose()

      installationWorkflowStore.actions.reset()

      showSuccess(
        translate.phrases.placeholder('Installation Completed'),
        <>
          {translate.phrases.placeholder(
            'Now that the installation is completed your station will appear as active equipment.',
          )}
          <br />
          <br />
          {translate.phrases.placeholder('The station status should be updated within 10-15 minutes.')}
        </>,
      )
    } catch (error) {
      setIsSaving(false)

      let errorMessage: string = error as string

      if (error instanceof Error) {
        errorMessage = error.message
      }

      showError(translate.phrases.placeholder('Installation Error'), errorMessage)
    }
  }

  const handleBack = () => {
    installationWorkflowStore.actions.showPreviousStep()
  }

  const canGoBack = useMemo(
    () => installationSteps.some(({ type }) => type === InstallationStep.CHECKLIST),
    [installationSteps],
  )

  return (
    <div css={{ padding: 10 }}>
      <h3 css={{ margin: 0 }}>{translate.phrases.placeholder('Review')}</h3>

      <p css={{ lineHeight: '24px', marginTop: 10, marginBottom: 20 }}>
        {translate.phrases.placeholder('Double check that the equipment is installed properly.')}
        <br />
        <br />
        {translate.phrases.placeholder('Upload a photo and finalize the install.')}
      </p>

      <ImageWithPlaceholder image={image} />

      <Group spacing="xs" css={{ margin: '10px auto', justifyContent: 'center' }}>
        <Button
          disabled={!checkSendImagePermission(selectedNode.propertyId)}
          variant="primary"
          onClick={() => handleTakePhoto()}
        >
          {translate.phrases.placeholder('Use Camera')}
        </Button>

        <Button
          disabled={!checkSendImagePermission(selectedNode.propertyId)}
          variant="secondary"
          onClick={() => handleSelectPhoto()}
        >
          {translate.phrases.placeholder('Select Photo')}
        </Button>
      </Group>

      <Footer
        onPrevious={handleBack}
        onNext={handleFinishInstallation}
        nextButtonLabel={translate.phrases.placeholder('Finish')}
        loading={isSaving}
        showPreviousButton={canGoBack}
        disableNextButton={!image}
      />
    </div>
  )
}
